import React from "react";
import { loadEmbedDomains } from "../../redux/action/embedDomains.action";
import {
  API_URL,
  EMBED_PAGES_PER_PAGE,
  DEFAULT_USER_IMG_URL,
  MAIN_WEBSITE_URL,
} from "../../constants/Globals";
import { Table, Modal, Form, Button, Tabs, Tab } from "react-bootstrap";
import axios from "axios";
import { connect } from "react-redux";
import ReactPaginate from "react-paginate";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCog } from "@fortawesome/free-solid-svg-icons";
import { withTranslation } from "react-i18next";

var moment = require("moment-timezone");
var momentDurationFormatSetup = require("moment-duration-format");
momentDurationFormatSetup(moment);

const DEPLOY_MODAL_TAB_CATEGORY = "category";
const DEPLOY_MODAL_TAB_PLACEMENT = "placement";

export class DeployDebate extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      successMsg: "",
      debateDat: null,
      showDebDeployModal: false,
      showRemoveDebModal: false,
      showEditDebLocModal: false,
      domainId: null,
      pageNumber: 1,
      pageSearch: "",
      embedPagesPerPage: EMBED_PAGES_PER_PAGE,
      embedPlacements: [],
      embedPlacementsLoaded: false,
      embedDomainPages: [],
      debateDeployType: "",
      selDebDeployPageIds: [],
      debDeployPageIds: [],
      debDeployPlacementIds: [],
      debDeployPagesText: "",
      embedPlacementError: "",
      embedDomainPageId: null,
      customToggleActive: null,
      embedDebateUrl: null,
      selDebDeployPageUrls: [],
      debDeployPageUrls: [],
      searchCategories: "",
      selectedCategories: [],
      availableCategories: [],
      deployModalTab: DEPLOY_MODAL_TAB_CATEGORY,
      replaceExistingPolls: true,
      pageTypeFilter:
        this.props.actionFromParent === "active-pages"
          ? "DEBATE_DEPLOYED"
          : "ALL",
      exclusions: [],
    };
  }

  domainChangeHandler = async (e) => {
    await this.setState({
      [e.target.name]: e.target.value,
    });
    await this.setState({
      pageNumber: 1,
    });
    this.getEmbedDomainPages();
  };

  searchPageChangeHandler = (event) => {
    this.setState({
      pageSearch: event.target.value,
    });
  };
  handleSearchSubmit = async (e) => {
    await this.setState({
      pageNumber: 1,
    });
    this.getEmbedDomainPages();
    e.preventDefault();
  };

  deployToSelectedHandler() {
    if (this.state.selDebDeployPageIds.length > 0) {
      this.setState({
        debDeployPageIds: this.state.selDebDeployPageIds,
        debDeployPageUrls: this.state.selDebDeployPageUrls,
        debateDeployType: "debDeployToPageIds",
        deployModalTab: DEPLOY_MODAL_TAB_PLACEMENT,
      });
      this.showDebDeployModal();
    }
  }

  deployDebate = async (debDeployPageIds, debDeployPageUrls) => {
    await this.setState({
      debDeployPageIds: debDeployPageIds,
      debDeployPageUrls: debDeployPageUrls,
      debateDeployType: "debDeployToPageIds",
    });
    this.showDebDeployModal();
  };

  deployDebateToAllPagesHandler() {
    this.setState({
      debateDeployType: "debDeployToAllPages",
      deployModalTab: DEPLOY_MODAL_TAB_PLACEMENT,
    });
    this.showDebDeployModal();
  }

  removeDeployDebateFromAllPagesHandler = async () => {
    this.setState({
      loading: true,
    });
    const body = {
      debateId: this.props.debateIdFromParent,
      embedDomainId: this.state.domainId,
    };
    axios
      .post(
        API_URL +
          "/admin/embedPlacement/removeDebateFromAllPages?clientId=" +
          this.props.clientIdFromParent,
        body,
      )
      .then((res) => {
        this.setState({
          loading: false,
          selDebDeployPageIds: [],
        });
        this.messageNotification(
          "success",
          "Poll removed from all pages successfully!",
        );
        this.getEmbedDomains(true);
        this.getEmbedDomainPages();
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  showDebDeployModal = () => {
    var debDeployPagesText = "";

    if (this.state.debateDeployType === "debDeployToAllPages") {
      debDeployPagesText += "All Pages";
    } else {
      let debDeployPageIds = this.state.debDeployPageIds;
      debDeployPagesText = debDeployPageIds.length;
      if (debDeployPageIds.length === 1) {
        debDeployPagesText += " Page";
      } else {
        debDeployPagesText += " Pages";
      }
    }

    this.getEmbedPlacements();
    this.setState({
      showDebDeployModal: true,
      debDeployPagesText: debDeployPagesText,
    });
  };

  hideDebDeployModal = (e) => {
    this.setState({
      showDebDeployModal: false,
      debDeployPlacementIds: [],
      debDeployPageIds: [],
    });
  };

  submitDeployDebateHandler = () => {
    if (this.state.debDeployPlacementIds.length > 0) {
      this.setState({
        embedPlacementError: "",
      });
      if (this.state.debateDeployType === "debDeployToAllPages") {
        this.deployDebateToAllPagesFunc();
      } else {
        this.deployDebateToPageIdsFunc();
      }
    } else {
      this.setState({
        embedPlacementError: this.props.t(
          "Please select location where poll appears.",
        ),
      });
    }
  };

  deployDebateToPageIdsFunc = () => {
    if (
      this.props.debateIdFromParent &&
      this.state.debDeployPageIds.length > 0
    ) {
      this.setState({
        loading: true,
      });
      const body = {
        debateId: this.props.debateIdFromParent,
        embedDomainPageIds: this.state.debDeployPageIds,
        embedPlacementIds: this.state.debDeployPlacementIds,
        embedDomainId: this.state.domainId,
        embedDomainCategories: this.state.selectedCategories,
        replaceExistingPolls: this.state.replaceExistingPolls,
      };
      axios
        .post(
          API_URL +
            "/admin/embedPlacement/deployDebate?clientId=" +
            this.props.clientIdFromParent,
          body,
        )
        .then((res) => {
          this.setState({
            loading: false,
            selDebDeployPageIds: [],
            selectedCategories: [],
            deployModalTab: DEPLOY_MODAL_TAB_CATEGORY,
            isCurrDeployed: false,
          });
          this.messageNotification(
            "success",
            this.props.t("Poll deployed successfully!"),
          );
          this.getEmbedDomainPages();
          this.hideDebDeployModal();
        })
        .catch((err) => {
          console.log("err", err);
        });
    }
  };

  deployDebateToAllPagesFunc = () => {
    if (this.props.debateIdFromParent && this.state.domainId) {
      this.setState({
        loading: true,
      });
      const body = {
        debateId: this.props.debateIdFromParent,
        embedDomainId: this.state.domainId,
        embedPlacementIds: this.state.debDeployPlacementIds,
        embedDomainCategories: this.state.selectedCategories,
        replaceExistingPolls: this.state.replaceExistingPolls,
      };
      axios
        .post(
          API_URL +
            "/admin/embedPlacement/deployDebateToAllPages?clientId=" +
            this.props.clientIdFromParent,
          body,
        )
        .then((res) => {
          this.setState({
            loading: false,
            selDebDeployPageIds: [],
            selectedCategories: [],
            deployModalTab: DEPLOY_MODAL_TAB_CATEGORY,
            isCurrDeployed: false,
          });
          this.messageNotification(
            "success",
            "Poll Deployed to All Pages successfully!",
          );
          this.getEmbedDomainPages();
          this.getEmbedDomains(true);
          this.hideDebDeployModal();
        })
        .catch((err) => {
          console.log("err", err);
        });
    }
  };

  handleSelectAllEmbedDomainPages = async (e, embedDomainPageId) => {
    var selDebDeployPageIds = this.state.selDebDeployPageIds;
    var selDebDeployPageUrls = this.state.selDebDeployPageUrls;
    var embedDomainPages = this.state.embedDomainPages;
    if (e.target.checked) {
      selDebDeployPageIds = embedDomainPages.map(({ id }) => id);
      selDebDeployPageUrls = embedDomainPages.map(({ pageUrl }) => pageUrl);
      this.setState({ isCurrDeployed: true });
    } else {
      selDebDeployPageIds = [];
      selDebDeployPageUrls = [];
      this.setState({ isCurrDeployed: false });
    }
    await this.setState({
      selDebDeployPageIds: selDebDeployPageIds,
      selDebDeployPageUrls: selDebDeployPageUrls,
    });
    //console.log('this.state.selDebDeployPageIds',this.state.selDebDeployPageIds);
    //console.log('this.state.selDebDeployPageUrls',this.state.selDebDeployPageUrls);
  };

  handleSelectEmbedDomainPage = async (
    e,
    embedDomainPageId,
    embedDomainPageUrl,
    deployStatus,
  ) => {
    console.log(deployStatus);
    this.setState({
      isCurrDeployed: e.target.checked ? deployStatus : false,
    });
    var selDebDeployPageIds = this.state.selDebDeployPageIds;
    var selDebDeployPageUrls = this.state.selDebDeployPageUrls;
    if (e.target.checked) {
      selDebDeployPageIds.push(embedDomainPageId);
      selDebDeployPageUrls.push(embedDomainPageUrl);
    } else {
      selDebDeployPageIds = selDebDeployPageIds.filter(
        (selDebDeployPageId) => selDebDeployPageId !== embedDomainPageId,
      );
      selDebDeployPageUrls = selDebDeployPageUrls.filter(
        (selDebDeployPageUrl) => selDebDeployPageUrl !== embedDomainPageUrl,
      );
    }
    await this.setState({
      selDebDeployPageIds: selDebDeployPageIds,
      selDebDeployPageUrls: selDebDeployPageUrls,
    });
    //console.log('this.state.selDebDeployPageIds',this.state.selDebDeployPageIds);
    //console.log('this.state.selDebDeployPageUrls',this.state.selDebDeployPageUrls);
  };

  handleSelectEmbedPlacement(embedPlacementId) {
    const { debDeployPlacementIds } = this.state;

    if (debDeployPlacementIds.indexOf(embedPlacementId) === -1) {
      debDeployPlacementIds.push(embedPlacementId);
    } else {
      debDeployPlacementIds.splice(
        debDeployPlacementIds.indexOf(embedPlacementId),
        1,
      );
    }

    this.setState({ debDeployPlacementIds });
  }

  removeFromSelectedHandler = async () => {
    if (this.state.selDebDeployPageIds.length > 0) {
      await this.setState({
        debDeployPageIds: this.state.selDebDeployPageIds,
        debDeployPageUrls: this.state.selDebDeployPageUrls,
        isCurrDeployed: false,
      });
      this.showRemoveDebModal();
    }
  };

  removeDebateFromPage = async (debDeployPageIds, debDeployPageUrls) => {
    await this.setState({
      debDeployPageIds: debDeployPageIds,
      debDeployPageUrls: debDeployPageUrls,
      isCurrDeployed: false,
    });
    this.showRemoveDebModal();
  };

  showRemoveDebModal = () => {
    if (this.state.debDeployPageIds.length > 0) {
      //console.log('this.state.debDeployPageIds',this.state.debDeployPageIds);
      this.setState({
        showRemoveDebModal: true,
      });
    }
  };
  hideRemoveDebModal = (e) => {
    this.setState({
      showRemoveDebModal: false,
      debDeployPageIds: [],
    });
  };

  submitRemoveDebateHandler = (e) => {
    e.preventDefault();
    if (
      this.props.debateIdFromParent &&
      this.state.debDeployPageIds.length > 0
    ) {
      this.setState({
        loading: true,
      });
      const body = {
        debateId: this.props.debateIdFromParent,
        embedDomainPageIds: this.state.debDeployPageIds,
      };
      axios
        .post(
          API_URL +
            "/admin/embedPlacement/removeDebateFromPage?clientId=" +
            this.props.clientIdFromParent,
          body,
        )
        .then((res) => {
          this.setState({
            loading: false,
            selDebDeployPageIds: [],
          });
          this.messageNotification(
            "success",
            "Poll removed successfully from page!",
          );
          this.getEmbedDomainPages();
          this.hideRemoveDebModal();
        })
        .catch((err) => {
          console.log("err", err);
        });
    }
  };

  editDebateLocationHandler = async (embedDomainPage) => {
    const debDeployPlacementIds = embedDomainPage.embedDebatePlacements.map(
      ({ placementId }) => placementId,
    );
    const rows = embedDomainPage.embedDebatePlacements.map(({ categories }) =>
      categories.split(","),
    );
    const selectedCategories = [];

    rows.forEach((categories) => {
      categories.forEach((category) => {
        if (selectedCategories.indexOf(category) === -1) {
          selectedCategories.push(category);
        }
      });
    });

    await this.setState({
      debDeployPageIds: [embedDomainPage.id],
      debDeployPlacementIds: debDeployPlacementIds,
      deployModalTab: DEPLOY_MODAL_TAB_PLACEMENT,
      showDebDeployModal: true,
      selectedCategories,
    });
    this.getEmbedPlacements();
    //this.showEditDebLocModal()
  };

  showEditDebLocModal = () => {
    this.getEmbedPlacements();
    if (this.state.debDeployPageIds.length > 0) {
      //console.log('this.state.debDeployPageIds',this.state.debDeployPageIds);
      this.setState({
        showEditDebLocModal: true,
      });
    }
  };

  hideEditDebLocModal = (e) => {
    this.setState({
      showEditDebLocModal: false,
      debDeployPlacementIds: [],
      debDeployPageIds: [],
    });
  };

  submitEditDebLocHandler = (e) => {
    e.preventDefault();
    if (this.state.debDeployPlacementIds.length > 0) {
      this.setState({
        embedPlacementError: "",
      });
      this.editDebLocFunc();
    } else {
      this.setState({
        embedPlacementError: this.props.t(
          "Please select location where poll appears.",
        ),
      });
    }
  };

  editDebLocFunc = () => {
    if (
      this.props.debateIdFromParent &&
      this.state.debDeployPageIds.length > 0
    ) {
      this.setState({
        loading: true,
      });
      const body = {
        debateId: this.props.debateIdFromParent,
        embedDomainPageId: this.state.debDeployPageIds[0],
        embedPlacementIds: this.state.debDeployPlacementIds,
        embedDomainId: this.state.domainId,
      };
      axios
        .post(
          API_URL +
            "/admin/embedPlacement/updateDebateLocation?clientId=" +
            this.props.clientIdFromParent,
          body,
        )
        .then((res) => {
          //console.log(res.data.data);
          this.setState({
            loading: false,
            selDebDeployPageIds: [],
          });
          this.messageNotification(
            "success",
            "Poll Location updated successfully!",
          );
          this.getEmbedDomainPages();
          this.hideEditDebLocModal();
        })
        .catch((err) => {
          console.log("err", err);
        });
    }
  };

  messageNotification = async (type, message) => {
    this.setState({ successMsg: message });

    setTimeout(() => this.setState({ successMsg: "" }), 5000);
  };

  getEmbedPlacements = () => {
    if (
      this.props.clientIdFromParent != null &&
      !this.state.embedPlacementsLoaded
    ) {
      this.setState({
        loading: true,
      });
      axios
        .get(
          API_URL +
            "/admin/embedPlacement/getEmbedPlacements?clientId=" +
            this.props.clientIdFromParent,
        )
        .then((res) => {
          //				console.log(res.data.data);
          (async () => {
            await this.setState({
              embedPlacements: res.data.data,
              embedPlacementsLoaded: true,
              loading: false,
            });
          })();
        })
        .catch((err) => {
          console.log("err", err);
        });
    }
  };

  getEmbedDomains = async (forceLoad) => {
    if (!this.props.embedDomainsLoaded || forceLoad) {
      await this.props.loadEmbedDomains(this.props.clientIdFromParent);
    }
  };
  setEmbedDomain = async () => {
    if (this.props.embedDomainsLoaded) {
      let embedDomains = this.props.embedDomains;
      if (embedDomains && embedDomains.length && this.state.domainId === null) {
        await this.setState({
          domainId: embedDomains[0].id,
        });
      }
      this.getEmbedDomainPages();
    }
  };

  getEmbedDomainPages = async () => {
    if (
      this.state.domainId &&
      this.props.clientIdFromParent &&
      this.state.pageTypeFilter
    ) {
      this.setState({
        loading: true,
      });

      let queryParams =
        "?debateId=" +
        this.props.debateIdFromParent +
        "&clientId=" +
        this.props.clientIdFromParent +
        "&domainId=" +
        this.state.domainId +
        "&filter=" +
        this.state.pageTypeFilter;

      queryParams +=
        "&perPage=" +
        this.state.embedPagesPerPage +
        "&pageNumber=" +
        this.state.pageNumber;
      if (this.state.pageSearch.trim() !== "") {
        queryParams += "&searchText=" + this.state.pageSearch.trim();
      }
      axios
        .get(API_URL + "/admin/embedSource/getPages" + queryParams)
        .then((res) =>
          this.setState({
            embedDomainPages: res.data.data.rows,
            embedDomainPagesCount: res.data.data.count,
            availableCategories: res.data.categories,
            exclusions: res.data.exclusions || [],
            loading: false,
          }),
        )
        .catch((err) => console.log("err", err));
    }
  };

  handlePageClick = async (data) => {
    let selected = data.selected + 1;
    await this.setState({
      pageNumber: selected,
    });
    this.getEmbedDomainPages();
  };

  componentDidMount = () => {
    this.getEmbedDomains(false);
    this.setEmbedDomain();
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.clientIdFromParent !== this.props.clientIdFromParent ||
      prevState.pageTypeFilter !== this.state.pageTypeFilter
    ) {
      this.getEmbedDomains(true);
      this.setEmbedDomain();
    }
    if (prevProps.embedDomainsLoaded !== this.props.embedDomainsLoaded) {
      this.setEmbedDomain();
    }
  }

  primaryPageOnClick = async (e) => {
    var action =
      this.state.embedDomainPageId === e.target.value ? "remove" : "add";
    await this.setState({
      loading: true,
      [e.target.name]: e.target.value,
    });
    var hitData = {
      clientId: this.props.clientIdFromParent,
      debateId: this.props.debateIdFromParent,
      embedDomainPageId: this.state.embedDomainPageId,
      action: action,
    };
    axios
      .post(API_URL + "/admin/embedSource/setEmailDebateEmbedLink", hitData)
      .then((res) => {
        (async () => {
          this.getEmbedDomains(true);
          this.setEmbedDomain();
          await this.setState({
            embedDomainPageId:
              action === "remove" ? null : this.state.embedDomainPageId,
          });
        })();
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  customSlideToggle = async (e) => {
    await this.setState({
      customToggleActive:
        e.target.dataset.index === this.state.customToggleActive
          ? ""
          : e.target.dataset.index,
    });
  };

  copyEmailEmbed = async (e, embedDebateUrl) => {
    if (embedDebateUrl === "") {
      var alertMsg = this.props.t(
        "Publish poll to this page to embed in email",
      );
      alert(alertMsg);
      return false;
    }
    await this.setState({ embedDebateUrl: embedDebateUrl });

    if (this.textAreaEmailEmbed.value !== "") {
      this.textAreaEmailEmbed.select();
      await document.execCommand("copy");
      e.target.focus();
      this.messageNotification(
        "success",
        "Email Embed code copied to clipboard!",
      );
    } else {
      this.messageNotification(
        "fail",
        "There is some problem in copying email embed code. Please reload the page and try again!",
      );
    }
  };

  handleSelect = async (key) => {
    if (key === "embed-settings-all") {
      await this.setState({
        pageTypeFilter: "ALL",
      });
    } else if (key === "active-pages") {
      await this.setState({
        pageTypeFilter: "DEBATE_DEPLOYED",
      });
    } else {
    }
    this.getEmbedDomainPages();
    if (window.history.pushState) {
      var newurl =
        window.location.protocol +
        "//" +
        window.location.host +
        window.location.pathname +
        "?action=" +
        key;
      window.history.pushState({ path: newurl }, "", newurl);
    }
  };

  render() {
    const action = this.props.actionFromParent;
    const kTrans = this.props.t ? this.props.t : "";
    const debateId = this.props.debateIdFromParent;
    const {
      embedDomains,
      currentDebateData,
      loggedInUser,
      clientIdFromParent,
      isQuiz,
    } = this.props;
    const {
      embedDomainPages,
      embedDomainPagesCount,
      selDebDeployPageIds,
      embedPlacements,
      debDeployPlacementIds,
      embedPlacementError,
      embedPagesPerPage,
      pageNumber,
      loading,
      embedDebateUrl,
      searchCategories,
      selectedCategories,
      availableCategories,
      showDebDeployModal,
      debDeployPagesText,
      debateDeployType,
      debDeployPageUrls,
      selDebDeployPageUrls,
      deployModalTab,
      replaceExistingPolls,
      exclusions,
    } = this.state;

    const isSuperAdmin = loggedInUser && loggedInUser.roles.length > 0;
    const deployingToCategory = deployModalTab === DEPLOY_MODAL_TAB_CATEGORY;
    const deployingToPlacement =
      deployModalTab === DEPLOY_MODAL_TAB_PLACEMENT || !isSuperAdmin;
    const allCategoriesChecked =
      selectedCategories.length === 1 && selectedCategories[0] === "all";
    const noOfPages = Math.ceil(embedDomainPagesCount / embedPagesPerPage);

    var selDomainData =
      embedDomains &&
      this.state.domainId &&
      embedDomains.find((item) => item.id === parseInt(this.state.domainId))
        ? embedDomains.find((item) => item.id === parseInt(this.state.domainId))
            .embedPlacementDebates
        : null;
    var selDebateData =
      selDomainData && selDomainData.length > 0
        ? selDomainData.find((item) => item.debateId === parseInt(debateId))
        : null;
    let selDebateDataArr =
      selDomainData && selDomainData.length > 0
        ? selDomainData.filter((item) => item.debateId === parseInt(debateId))
        : [];
    var isDeployed = selDebateData ? true : false;

    const deployedToCategories =
      selDebateData && selDebateData.embedDomainCategories
        ? selDebateData.embedDomainCategories.split(",")
        : [];
    const deployedToCategoriesPlacements = selDebateDataArr
      .map(({ embedPlacementId }) => embedPlacementId)
      .filter((x, i, a) => a.indexOf(x) === i);

    /* email embed body start */
    var emailEmbedSides = "";
    var sidesLink = currentDebateData
      ? "https://" +
        currentDebateData.siteDomain +
        "/debate/" +
        currentDebateData.id +
        "/log-vote/"
      : "";
    var redirectLink = embedDebateUrl ? "&redirectLink=" + embedDebateUrl : "";
    if (currentDebateData.isClosed) {
      if (currentDebateData.sides && currentDebateData.sides.length) {
        for (let i = 0; i < currentDebateData.sides.length; i++) {
          var voteResult = !isNaN(
            (
              (currentDebateData.sides[i].votes * 100) /
              currentDebateData.votes
            ).toFixed(2),
          )
            ? (
                (currentDebateData.sides[i].votes * 100) /
                currentDebateData.votes
              ).toFixed(2) + "%"
            : "";
          emailEmbedSides =
            emailEmbedSides +
            '<tr><td style="padding-bottom:9px" colspan="2"><a href="' +
            embedDebateUrl +
            '" style="position:relative;color:#000;text-decoration:none;border-radius: 3px;display:block;font-size:14px;padding:7px;text-align:left;font-weight:600;line-height:18px; background: ' +
            currentDebateData.sides[i].sideColor +
            "10; border-left: 5px solid " +
            currentDebateData.sides[i].sideColor +
            '"><span style="position:relative; z-index: 1;">' +
            currentDebateData.sides[i].text +
            '<span style="float: right;">' +
            voteResult +
            '</span></span><span style="position: absolute;width: ' +
            voteResult +
            ";top: 0;bottom: 0;left: 0;background: " +
            currentDebateData.sides[i].sideColor +
            '26;"></span></a></td></tr>';
        }
      }
    } else {
      if (currentDebateData.sides && currentDebateData.sides.length) {
        for (let i = 0; i < currentDebateData.sides.length; i++) {
          emailEmbedSides =
            emailEmbedSides +
            '<tr><td style="padding-bottom:9px" colspan="2"><a href="' +
            sidesLink +
            "?sid=" +
            currentDebateData.sides[i].id +
            redirectLink +
            '" style="color:#000;text-decoration:none;display:block;font-size:14px;border:1px solid #60a4e9;border-radius:10px;padding:7px;text-align:center;font-weight:600;line-height:18px">' +
            currentDebateData.sides[i].text +
            "</a></td></tr>";
        }
      }
    }

    var userImage =
      currentDebateData.user && currentDebateData.user.avatarObject
        ? currentDebateData.user.avatarObject.thumb.location
        : DEFAULT_USER_IMG_URL;
    var endedOrEnds = currentDebateData.isClosed
      ? kTrans("Poll ended")
      : kTrans("Poll ends");
    var endedAtForMailEmbed = currentDebateData.endedAt
      ? endedOrEnds +
        " " +
        moment(currentDebateData.endedAt).format("MMM DD, YYYY")
      : "";
    var emailEmbedCode = currentDebateData
      ? "<table style=\"color:#4a4a4a;font-family:-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;font-size:16px;margin:18px;width: 94%;\"><tr><td><table><tr>    <td width=\"38px\" style=\"padding-right: 10px\"><img width=\"38px\" height=\"38px\" src=\"" +
        userImage +
        '" alt="' +
        currentDebateData.user.name +
        '" style="vertical-align:middle;border:2px solid ' +
        currentDebateData.user.statusColor +
        ';border-radius:50%;display:block;height:38px;min-width:38px;object-fit:cover;padding:0;width:38px;outline:0;text-decoration:none" class="CToWUd"></td>    <td><a rel="noreferrer" href="' +
        MAIN_WEBSITE_URL +
        "/" +
        currentDebateData.user.username +
        '" style="color:inherit;text-decoration-line:none;display:block" target="_blank"><h6 style="line-height:19px;margin:0;font-size:14px;padding:0;font-weight:500;display:block">' +
        currentDebateData.user.name +
        '</h6></a> <a rel="noreferrer" style="color:#9b9b9b;display:block;font-size:11px;line-height:15px">Posted ' +
        moment(currentDebateData.startedAt).format("MMM DD, YYYY") +
        '</a></td>  </tr></table></td></tr>		<tr><td colspan="2" style="padding-top:18px"><h5 style="margin:0px;line-height:19px;font-size:16px;color:#000;font-weight:500;display:block">' +
        currentDebateData.thesis +
        '</h5></td></tr>	<tr><td colspan="2" style="padding:12px 0"><span style="color:#9b9b9b;font-size:13px;line-height:16px;display:block">' +
        endedAtForMailEmbed +
        "</span></td></tr>" +
        emailEmbedSides +
        "</table>"
      : null;
    /* Email embed body end */

    return (
      <div data-debid={debateId}>
        <div className="mt-4 mb-4 pb-2">
          <label className="customLabel mb-2 d-block">
            <b>{kTrans("Deploying to this URL")}</b>
          </label>
          <div>
            <select
              className="selectfield w-50"
              name="domainId"
              onChange={(e) => {
                this.domainChangeHandler(e);
              }}
            >
              {embedDomains && embedDomains.length ? (
                embedDomains.map((embedDomain) => (
                  <option key={embedDomain.id} value={embedDomain.id}>
                    {embedDomain.domain}
                  </option>
                ))
              ) : (
                <option key="0">{kTrans("No Domains found")}!</option>
              )}
            </select>
          </div>
        </div>
        <Tabs
          defaultActiveKey={
            action === "active-pages" ? "active-pages" : "embed-settings-all"
          }
          id="uncontrolled-tab-example"
          className="mt-2 mb-3 CreateDebateTabs sm"
          onSelect={this.handleSelect}
        >
          <Tab
            eventKey="embed-settings-all"
            title={kTrans("Available Pages")}
          ></Tab>
          <Tab eventKey="active-pages" title={kTrans("Active Pages")}></Tab>
        </Tabs>

        {this.state.successMsg ? (
          <div className="sucessMsg">
            {this.state.successMsg
              .replace("Poll", isQuiz ? "Quiz" : "Poll")
              .replace("Poll", isQuiz ? "quiz" : "poll")}
          </div>
        ) : null}

        <div className={loading ? "loading" : null}>
          <textarea
            className="hiddenElement"
            ref={(textarea) => (this.textAreaEmailEmbed = textarea)}
            value={emailEmbedCode}
          />

          {this.state.pageTypeFilter === "DEBATE_DEPLOYED" ? (
            <span className="lightColorSpan">
              {kTrans(
                `These are the pages on your website where this ${isQuiz ? "quiz" : "poll"} is currently showing`,
              )}
              .
            </span>
          ) : (
            <span className="lightColorSpan">
              {kTrans(
                `These are the pages on your website that ${isQuiz ? "quizzes" : "polls"} can be deployed to`,
              )}
              .
            </span>
          )}

          <div className="row mb-2">
            <div className="col-sm-12">
              <label className="customLabel mb-2 mt-4 d-block">
                <b>{kTrans("Bulk Actions")}:</b>
              </label>
              <div className="deployActions">
                {isDeployed ? (
                  <span
                    className="linkActive btn btn-sm btn-outline-primary me-1"
                    onClick={() => {
                      if (
                        window.confirm(
                          kTrans(
                            `Are you sure you want to remove ${isQuiz ? "quiz" : "poll"} from all pages?`,
                          ),
                        )
                      ) {
                        this.removeDeployDebateFromAllPagesHandler();
                      }
                    }}
                    title={kTrans("Remove from All Pages")}
                  >
                    {kTrans("Remove from All Pages")}
                  </span>
                ) : null}
                {!isDeployed &&
                this.state.pageTypeFilter !== "DEBATE_DEPLOYED" ? (
                  <span
                    className="linkActive btn btn-sm btn-outline-primary me-1"
                    onClick={() => this.deployDebateToAllPagesHandler()}
                    title={kTrans("Deploy to All Pages")}
                  >
                    {kTrans("Deploy to All Pages")}
                  </span>
                ) : null}
                {isSuperAdmin ? (
                  <span
                    className="linkActive btn btn-sm btn-outline-primary me-1"
                    onClick={() =>
                      this.setState({
                        showDebDeployModal: true,
                        debateDeployType: "debDeployToAllPages",
                        deployModalTab: DEPLOY_MODAL_TAB_CATEGORY,
                        selectedCategories: deployedToCategories,
                        debDeployPlacementIds: Array.isArray(
                          deployedToCategoriesPlacements,
                        )
                          ? deployedToCategoriesPlacements
                          : [deployedToCategoriesPlacements],
                      })
                    }
                    title={
                      deployedToCategories.length
                        ? `Deployed to ${deployedToCategories.join(", ")} categor${deployedToCategories.length === 1 ? "y" : "ies"}`
                        : kTrans("Deploy to Category")
                    }
                  >
                    {deployedToCategories.length
                      ? `Deployed to ${deployedToCategories.join(", ")} categor${deployedToCategories.length === 1 ? "y" : "ies"}`
                      : kTrans("Deploy to Category")}
                  </span>
                ) : null}
                {this.state.pageTypeFilter !== "DEBATE_DEPLOYED" ? (
                  <span
                    className={
                      !selDebDeployPageIds.length
                        ? "linkInactive btn btn-sm btn-outline-primary me-1"
                        : "linkActive btn btn-sm btn-outline-primary me-1"
                    }
                    onClick={() => this.deployToSelectedHandler()}
                    title={kTrans("Deploy to Selected")}
                  >
                    {kTrans("Deploy to Selected")}
                  </span>
                ) : null}
                <span
                  className={
                    selDebDeployPageIds.length > 1 || this.state.isCurrDeployed
                      ? "linkActive btn btn-sm btn-outline-primary"
                      : "linkInactive btn btn-sm btn-outline-primary"
                  }
                  onClick={() => this.removeFromSelectedHandler()}
                  title={kTrans("Remove Selected")}
                >
                  {kTrans("Remove Selected")}
                </span>
              </div>
              <form
                id="search"
                autoComplete="off"
                className="searchForm1 mt-4"
                onSubmit={(e) => this.handleSearchSubmit(e)}
              >
                <input
                  onChange={this.searchPageChangeHandler}
                  type="text"
                  className="form-control"
                  value={this.state.pageSearch}
                  name="pageSearch"
                  placeholder={kTrans("Search for a page")}
                />
                <button type="submit">
                  <i className="fa fa-search"></i>
                </button>
              </form>
            </div>
          </div>
          {embedDomainPages && embedDomainPages.length ? (
            <div className="table-wrapper">
              <Table className="tableCommon deployeDebateTable">
                <thead>
                  <tr>
                    <th className="text-center">
                      <input
                        type="checkbox"
                        className="checkbox form-check-input"
                        checked={
                          selDebDeployPageIds.length === embedDomainPages.length
                        }
                        onChange={this.handleSelectAllEmbedDomainPages}
                      />
                    </th>
                    <th className="text-start ps-0 title-td w-75">
                      {kTrans("PAGE NAME")}
                    </th>
                    <th>{kTrans("EMAIL EMBED")}</th>
                    <th>{kTrans("ACTION")}</th>
                  </tr>
                </thead>
                <tbody>
                  {embedDomainPages.map((embedDomainPage, index) => (
                    <>
                      <tr key={embedDomainPage.id}>
                        <td className="text-center">
                          {exclusions &&
                          exclusions.indexOf(embedDomainPage.id) === -1 &&
                          ((embedDomainPage.embedDebatePlacements.length > 0 &&
                            embedDomainPage.embedDebatePlacements.find(
                              (item) => item.debateId === debateId,
                            )) ||
                            isDeployed) ? (
                            <input
                              type="checkbox"
                              className="checkbox form-check-input"
                              value={embedDomainPage.id}
                              checked={selDebDeployPageIds.includes(
                                embedDomainPage.id,
                              )}
                              onChange={(e) => {
                                this.handleSelectEmbedDomainPage(
                                  e,
                                  embedDomainPage.id,
                                  embedDomainPage.pageUrl,
                                  true,
                                );
                              }}
                            />
                          ) : (
                            <input
                              type="checkbox"
                              className="checkbox form-check-input"
                              value={embedDomainPage.id}
                              checked={selDebDeployPageIds.includes(
                                embedDomainPage.id,
                              )}
                              onChange={(e) => {
                                this.handleSelectEmbedDomainPage(
                                  e,
                                  embedDomainPage.id,
                                  embedDomainPage.pageUrl,
                                  false,
                                );
                              }}
                            />
                          )}
                        </td>
                        <td className="text-start ps-0 title-td">
                          <div>
                            <b>{embedDomainPage.pageTitle}</b>
                          </div>
                          <div className="">
                            <a
                              rel="noreferrer"
                              className="lightColorSpan align-items-center"
                              href={embedDomainPage.pageUrl}
                              target="_blank"
                            >
                              <svg
                                className="me-1"
                                width="12"
                                height="12"
                                viewBox="0 0 12 12"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M11 1.33333V4.33333C11 4.51758 10.8509 4.66667 10.6667 4.66667C10.4824 4.66667 10.3333 4.51758 10.3333 4.33333V2.138L5.569 6.90233C5.50392 6.96742 5.41858 7 5.33333 7C5.24808 7 5.16275 6.96742 5.09767 6.90233C4.96742 6.77208 4.96742 6.56117 5.09767 6.431L9.862 1.66667H7.66667C7.48242 1.66667 7.33333 1.51758 7.33333 1.33333C7.33333 1.14908 7.48242 1 7.66667 1H10.6667C10.8509 1 11 1.14908 11 1.33333ZM9.66667 10V6C9.66667 5.81575 9.51758 5.66667 9.33333 5.66667C9.14908 5.66667 9 5.81575 9 6V10C9 10.1839 8.85058 10.3333 8.66667 10.3333H2C1.81608 10.3333 1.66667 10.1839 1.66667 10V3.33333C1.66667 3.14942 1.81608 3 2 3H6C6.18425 3 6.33333 2.85092 6.33333 2.66667C6.33333 2.48242 6.18425 2.33333 6 2.33333H2C1.44858 2.33333 1 2.78192 1 3.33333V10C1 10.5514 1.44858 11 2 11H8.66667C9.21808 11 9.66667 10.5514 9.66667 10Z"
                                  fill="#BDBDBD"
                                  stroke="#BDBDBD"
                                  strokeWidth="0.5"
                                />
                              </svg>
                              {embedDomainPage.pageUrl}
                            </a>
                          </div>
                        </td>
                        <td>
                          <span
                            onClick={(e) =>
                              this.copyEmailEmbed(e, embedDomainPage.pageUrl)
                            }
                            className="btn btn-outline-primary btn-sm"
                          >
                            {kTrans("Copy Code")}
                          </span>
                        </td>
                        <td>
                          {exclusions &&
                          exclusions.indexOf(embedDomainPage.id) === -1 &&
                          ((embedDomainPage.embedDebatePlacements.length > 0 &&
                            embedDomainPage.embedDebatePlacements.find(
                              (item) => item.debateId === debateId,
                            )) ||
                            isDeployed) ? (
                            <>
                              {this.state.pageTypeFilter ===
                              "DEBATE_DEPLOYED" ? (
                                <Button
                                  onClick={() =>
                                    this.removeDebateFromPage(
                                      [embedDomainPage.id],
                                      [embedDomainPage.pageUrl],
                                    )
                                  }
                                  variant="secondary"
                                  className="w-100"
                                  size="sm"
                                  type="button"
                                >
                                  {kTrans("Remove")}
                                </Button>
                              ) : (
                                <Button
                                  variant="outline-primary"
                                  className="light w-100"
                                  size="sm"
                                  type="button"
                                >
                                  {kTrans("Deployed")}
                                </Button>
                              )}
                            </>
                          ) : (
                            <Button
                              variant="outline-primary"
                              className="light w-100"
                              size="sm"
                              type="button"
                              onClick={() => {
                                if (
                                  exclusions.indexOf(embedDomainPage.id) !== -1
                                ) {
                                  this.setState({ loading: true });
                                  axios
                                    .post(
                                      `${API_URL}/admin/embedPlacement/deployDebate?clientId=${clientIdFromParent}`,
                                      {
                                        embedDomainId: -1,
                                        embedPlacementIds: [-1],
                                        debateId: parseInt(debateId),
                                        embedDomainPageIds: [
                                          embedDomainPage.id,
                                        ],
                                      },
                                    )
                                    .then(() => {
                                      this.setState({
                                        loading: false,
                                        selDebDeployPageIds: [],
                                      });
                                      this.messageNotification(
                                        "success",
                                        this.props.t(
                                          "Poll deployed successfully!",
                                        ),
                                      );
                                      this.getEmbedDomainPages();
                                    })
                                    .catch((err) => console.log("err", err));
                                } else {
                                  this.deployDebate(
                                    [embedDomainPage.id],
                                    [embedDomainPage.pageUrl],
                                  );
                                }
                              }}
                            >
                              {kTrans("Deploy")}
                            </Button>
                          )}
                        </td>
                        {this.state.pageTypeFilter === "DEBATE_DEPLOYED" ? (
                          <td>
                            <FontAwesomeIcon
                              size="lg"
                              onClick={() =>
                                this.editDebateLocationHandler(embedDomainPage)
                              }
                              role="button"
                              icon={faCog}
                              className="fa-secondary"
                            />
                          </td>
                        ) : null}
                      </tr>
                      {(embedDomainPage.embedDebatePlacements &&
                        embedDomainPage.embedDebatePlacements.length) ||
                      embedDomainPage.pageTags ? (
                        <tr>
                          <td></td>
                          <td className="p-0" colSpan="7">
                            <div
                              className={`debStat text-start ${parseInt(this.state.customToggleActive) === parseInt(embedDomainPage.id) ? "" : "collapsed"}`}
                            >
                              <div className="d-flex align-item-top">
                                {embedDomainPage.embedDebatePlacements &&
                                embedDomainPage.embedDebatePlacements.length ? (
                                  <span
                                    onClick={this.customSlideToggle}
                                    className="me-2 text-start customAnchor"
                                    data-index={embedDomainPage.id}
                                  >
                                    {kTrans("View page details")}
                                    {parseInt(this.state.customToggleActive) ===
                                    parseInt(embedDomainPage.id) ? (
                                      <svg
                                        onClick={this.customSlideToggle}
                                        data-index={embedDomainPage.id}
                                        className="ms-2 mt-1"
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="8"
                                        height="5"
                                        viewBox="0 0 8 5"
                                        fill="none"
                                      >
                                        <path
                                          d="M0.94 4.94629L4 1.88629L7.06 4.94629L8 3.99962L4 -0.000377655L0 3.99962L0.94 4.94629Z"
                                          fill="#8FA2AC"
                                        />
                                      </svg>
                                    ) : (
                                      <svg
                                        onClick={this.customSlideToggle}
                                        data-index={embedDomainPage.id}
                                        className="ms-2 mt-1"
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="8"
                                        height="5"
                                        viewBox="0 0 8 5"
                                        fill="none"
                                      >
                                        <path
                                          d="M0.94 0L4 3.06L7.06 0L8 0.946667L4 4.94667L0 0.946667L0.94 0Z"
                                          fill="#8FA2AC"
                                        />
                                      </svg>
                                    )}
                                  </span>
                                ) : null}
                                <div className="embedDomainPageTags">
                                  {embedDomainPage.pageTags
                                    ? embedDomainPage.pageTags
                                        .split(",")
                                        .map((embedDomainPageTag, index) => (
                                          <span
                                            className="me-1 btn btn-secondary-light btn-sm"
                                            key={index}
                                          >
                                            {embedDomainPageTag}
                                          </span>
                                        ))
                                    : null}
                                </div>
                              </div>
                              {embedDomainPage.embedDebatePlacements &&
                              embedDomainPage.embedDebatePlacements.length ? (
                                <div className="customSlideToggle">
                                  <Table className="tableCommon">
                                    <thead>
                                      <tr>
                                        <th className="text-start ps-0">
                                          {kTrans(
                                            `${isQuiz ? "Quizzes" : "Polls"} ON THIS PAGE`,
                                          )}
                                        </th>
                                        <th>{kTrans("PUBLISHED")}</th>
                                        <th>{kTrans("CLOSE DATE")}</th>
                                        <th>{kTrans("PLACEMENT")}</th>
                                        <th>{kTrans("STATUS")}</th>
                                        <th>
                                          {kTrans(isQuiz ? "Quiz" : "POLL")}
                                        </th>
                                      </tr>
                                      {embedDomainPage.embedDebatePlacements.map(
                                        (embedDebatePlacement, index) => (
                                          <tr className="" key={index}>
                                            <td className="text-start">
                                              {embedDebatePlacement.debateName}
                                            </td>
                                            <td>
                                              {moment(
                                                embedDebatePlacement.debateStartedAt,
                                              ).format("MM/DD/YY")}
                                            </td>
                                            <td>
                                              {embedDebatePlacement.debateEndedAt
                                                ? moment(
                                                    embedDebatePlacement.debateEndedAt,
                                                  ).format("MM/DD/YY")
                                                : null}
                                            </td>
                                            <td>
                                              {
                                                embedDebatePlacement.placementName
                                              }
                                            </td>
                                            <td>
                                              {embedDebatePlacement.debateStatus ===
                                              "active"
                                                ? kTrans("Active")
                                                : null}
                                              {embedDebatePlacement.debateStatus ===
                                              "closed"
                                                ? kTrans("Closed")
                                                : null}
                                              {embedDebatePlacement.debateStatus ===
                                              "scheduled"
                                                ? "Scheduled"
                                                : null}
                                            </td>
                                            <td>
                                              <a
                                                rel="noreferrer"
                                                target="_blank"
                                                href={embedDomainPage.pageUrl}
                                                className="customAnchor"
                                              >
                                                {kTrans("View")}
                                              </a>
                                            </td>
                                          </tr>
                                        ),
                                      )}
                                    </thead>
                                  </Table>
                                </div>
                              ) : null}
                            </div>
                          </td>
                        </tr>
                      ) : null}
                    </>
                  ))}
                </tbody>
              </Table>
            </div>
          ) : (
            <>
              {isDeployed ? (
                <h4>{kTrans("Published to all pages")}.</h4>
              ) : (
                <h4>{kTrans("No Page Found")}.</h4>
              )}
            </>
          )}

          {noOfPages > 1 ? (
            <ReactPaginate
              className="pagination"
              previousLabel="&laquo;"
              nextLabel="&raquo;"
              breakLabel="..."
              breakClassName="break-me"
              pageCount={noOfPages}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={this.handlePageClick}
              containerClassName="pagination"
              activeClassName="active"
              forcePage={pageNumber - 1}
            />
          ) : (
            ""
          )}

          <Modal className="debDeployModal" size="lg" show={showDebDeployModal}>
            <Modal.Body className="sided-modal-body">
              <div className="mb-3 d-flex justify-content-between">
                <h3 className="fw-bold">
                  {deployingToPlacement
                    ? kTrans("Select Placement")
                    : kTrans("Publish to Category")}
                </h3>
                <h3
                  role="button"
                  onClick={() => this.setState({ showDebDeployModal: false })}
                >
                  <i className="fa fa-close"></i>
                </h3>
              </div>

              <p className="mb-3">
                {deployingToPlacement
                  ? kTrans(
                      `Select the location where this ${isQuiz ? "quiz" : "poll"} will show`,
                    )
                  : kTrans(
                      `Choose the categories you would like to publish this ${isQuiz ? "quiz" : "poll"} to`,
                    )}
              </p>

              {isSuperAdmin ? (
                <div className="modal-tabs">
                  <div
                    className={`modal-tab ${deployingToCategory ? "active" : ""}`}
                    onClick={() =>
                      this.setState({
                        deployModalTab: DEPLOY_MODAL_TAB_CATEGORY,
                      })
                    }
                  >
                    {kTrans("Category")}
                  </div>
                  <div
                    className={`modal-tab ${deployingToPlacement ? "active" : ""}`}
                    onClick={() => {
                      this.setState({
                        deployModalTab: DEPLOY_MODAL_TAB_PLACEMENT,
                      });
                      if (!embedPlacements.length) {
                        this.getEmbedPlacements();
                      }
                    }}
                  >
                    {kTrans("Placement")}
                  </div>
                </div>
              ) : null}

              {deployingToPlacement ? (
                <>
                  {debateDeployType !== "debDeployToAllPages" ? (
                    <Form className="DeployDebateForm common-form">
                      <div className="form-group">
                        <b className="pb-3 d-block">
                          {kTrans(
                            `Please confirm you want to publish this ${isQuiz ? "quiz" : "poll"} to the following`,
                          )}{" "}
                          {debDeployPagesText}?
                        </b>
                        <p className="depToPagesList">
                          {debDeployPageUrls.length === 1 ? (
                            <span className="d-block">{debDeployPageUrls}</span>
                          ) : (
                            <>
                              {selDebDeployPageUrls.map(
                                (selDebDeployPageUrl, index) => (
                                  <span className="d-block" key={index}>
                                    {selDebDeployPageUrl}
                                  </span>
                                ),
                              )}
                            </>
                          )}
                        </p>
                      </div>
                    </Form>
                  ) : null}

                  <div className="network-list">
                    <div className="network-list-row">
                      <label>
                        <input
                          type="checkbox"
                          className="sided-checkbox"
                          checked={
                            debDeployPlacementIds.length ===
                            embedPlacements.length
                          }
                          onChange={() =>
                            this.setState({
                              debDeployPlacementIds:
                                debDeployPlacementIds.length !==
                                embedPlacements.length
                                  ? embedPlacements.map(({ id }) => id)
                                  : [],
                            })
                          }
                        />
                        {kTrans("Available placements")} (
                        {embedPlacements ? embedPlacements.length : 0})
                      </label>
                      {isSuperAdmin ? (
                        <label>
                          <input
                            type="checkbox"
                            className="sided-checkbox"
                            checked={replaceExistingPolls}
                            onChange={() =>
                              this.setState({
                                replaceExistingPolls: !replaceExistingPolls,
                              })
                            }
                          />
                          {kTrans(
                            `Replace existing ${isQuiz ? "quizzes" : "polls"}`,
                          )}
                        </label>
                      ) : null}
                    </div>
                    {(embedPlacements || []).map((embedPlacement) => (
                      <div key={embedPlacement.id} className="network-list-row">
                        <label className="network-list-item">
                          <input
                            type="checkbox"
                            className="sided-checkbox"
                            checked={
                              debDeployPlacementIds &&
                              debDeployPlacementIds.indexOf(
                                embedPlacement.id,
                              ) !== -1
                            }
                            onChange={() =>
                              this.handleSelectEmbedPlacement(embedPlacement.id)
                            }
                          />
                          {embedPlacement.placementName}
                        </label>
                      </div>
                    ))}
                  </div>

                  {embedPlacementError ? (
                    <div className="validation-error">
                      {embedPlacementError}
                    </div>
                  ) : null}
                </>
              ) : (
                <>
                  <form className="sided-modal-search">
                    <input
                      type="text"
                      value={searchCategories}
                      placeholder={kTrans("Search categories")}
                      onChange={({ target }) =>
                        this.setState({ searchCategories: target.value })
                      }
                    />
                  </form>

                  <div className="network-list">
                    <div className="network-list-row">
                      <label>
                        <input
                          type="checkbox"
                          className="sided-checkbox"
                          checked={allCategoriesChecked}
                          onChange={() =>
                            this.setState({
                              selectedCategories:
                                selectedCategories.indexOf("all") !== -1
                                  ? []
                                  : ["all"],
                            })
                          }
                        />
                        {kTrans("Available categories")} (
                        {availableCategories.length})
                      </label>
                    </div>
                    {availableCategories
                      .filter(
                        (tag) =>
                          searchCategories === "" ||
                          tag
                            .toLowerCase()
                            .indexOf(searchCategories.toLowerCase()) !== -1,
                      )
                      .map((category) => (
                        <div key={category} className="network-list-row">
                          <label className="network-list-item">
                            <input
                              type="checkbox"
                              className="sided-checkbox"
                              checked={
                                allCategoriesChecked ||
                                selectedCategories.indexOf(category) !== -1
                              }
                              onChange={() => {
                                const newSelection = [].concat(
                                  selectedCategories,
                                );
                                const currentIndex =
                                  newSelection.indexOf(category);
                                const allIndex = newSelection.indexOf("all");

                                if (allIndex !== -1) {
                                  newSelection.splice(allIndex, 1);
                                }

                                if (currentIndex !== -1) {
                                  newSelection.splice(currentIndex, 1);
                                } else {
                                  newSelection.push(category);
                                }

                                this.setState({
                                  selectedCategories: newSelection,
                                });
                              }}
                            />
                            {category}
                          </label>
                        </div>
                      ))}
                  </div>
                </>
              )}

              <div className="text-center">
                <Button
                  onClick={this.hideDebDeployModal}
                  variant="secondary"
                  className="mx-1"
                >
                  {deployingToPlacement ? kTrans("Back") : kTrans("Cancel")}
                </Button>
                {deployingToPlacement ? (
                  <Button
                    onClick={this.submitDeployDebateHandler}
                    variant="primary"
                    className="mx-1"
                  >
                    {kTrans(`Publish ${isQuiz ? "Quiz" : "Poll"}`)}
                  </Button>
                ) : (
                  <Button
                    onClick={() => {
                      this.setState({
                        deployModalTab: DEPLOY_MODAL_TAB_PLACEMENT,
                      });
                      if (!embedPlacements.length) {
                        this.getEmbedPlacements();
                      }
                    }}
                    variant="primary"
                    className="mx-1"
                  >
                    {kTrans("Select Placement")}
                  </Button>
                )}
              </div>
            </Modal.Body>
          </Modal>

          <Modal
            className="removeDebModal"
            size="lg"
            show={this.state.showRemoveDebModal}
          >
            <Modal.Header>
              <Modal.Title>
                {kTrans(`Remove ${isQuiz ? "quiz" : "poll"} from`)}{" "}
                {this.state.debDeployPageIds
                  ? this.state.debDeployPageIds.length + " "
                  : ""}
                {this.state.debDeployPageIds.length === 1
                  ? kTrans("page")
                  : kTrans("pages")}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="modalContentWrapper">
                <Form
                  className="RemoveDebateForm common-form"
                  onSubmit={this.submitRemoveDebateHandler}
                >
                  <div className="form-group">
                    {this.state.debateDeployType !== "debDeployToAllPages" ? (
                      <>
                        <b className="pb-3 d-block">
                          {kTrans(
                            `Please confirm you want to unpublish this ${isQuiz ? "quiz" : "poll"} from the following`,
                          )}{" "}
                          {this.state.debDeployPageIds
                            ? this.state.debDeployPageIds.length + " "
                            : ""}
                          {this.state.debDeployPageIds.length === 1
                            ? kTrans("page")
                            : kTrans("pages")}
                          ?
                        </b>
                        <p className="depToPagesList">
                          {this.state.debDeployPageUrls.length === 1 ? (
                            <span className="d-block">
                              {this.state.debDeployPageUrls}
                            </span>
                          ) : (
                            <>
                              {this.state.selDebDeployPageUrls.map(
                                (selDebDeployPageUrl, index) => (
                                  <span className="d-block" key={index}>
                                    {selDebDeployPageUrl}
                                  </span>
                                ),
                              )}
                            </>
                          )}
                        </p>
                      </>
                    ) : null}
                  </div>
                  <div className="buttons text-center">
                    <Button
                      onClick={this.hideRemoveDebModal}
                      variant="secondary"
                    >
                      {kTrans("Cancel")}
                    </Button>
                    <Button variant="primary" type="submit">
                      {kTrans("Unpublish")}
                    </Button>
                  </div>
                </Form>
              </div>
            </Modal.Body>
          </Modal>

          <Modal
            className="editDebLocModal"
            size="lg"
            show={this.state.showEditDebLocModal}
          >
            <Modal.Header>
              <Modal.Title>{kTrans("Edit Poll Location")}</Modal.Title>
              <span>
                <b onClick={this.hideEditDebLocModal}>&#x2715;</b>
              </span>
            </Modal.Header>
            <Modal.Body>
              <div className="modalContentWrapper">
                <Form
                  className="DeployDebateForm common-form"
                  onSubmit={this.submitEditDebLocHandler}
                >
                  <div className="form-group">
                    <b>{kTrans("Select location where this poll appears")}:</b>
                  </div>
                  {embedPlacements && embedPlacements.length ? (
                    <div className="form-group">
                      {embedPlacements.map((embedPlacement, index) => (
                        <Form.Check
                          className="d-flex align-item-center"
                          key={index}
                          type="checkbox"
                          id={embedPlacement.placementName + embedPlacement.id}
                          label={embedPlacement.placementName}
                          checked={debDeployPlacementIds.includes(
                            embedPlacement.id,
                          )}
                          onClick={() =>
                            this.handleSelectEmbedPlacement(embedPlacement.id)
                          }
                        />
                      ))}
                    </div>
                  ) : null}
                  {embedPlacementError ? (
                    <div className="validation-error">
                      {embedPlacementError}
                    </div>
                  ) : null}
                  <div className="buttons text-center">
                    <Button
                      onClick={this.hideEditDebLocModal}
                      variant="secondary"
                    >
                      {kTrans("Cancel")}
                    </Button>
                    <Button variant="primary" type="submit">
                      {kTrans("Publish to Site")}
                    </Button>
                  </div>
                </Form>
              </div>
            </Modal.Body>
          </Modal>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  loggedInUser: state.login.user,
  embedDomains: state.embedDomains.embedDomains,
  embedDomainsLoaded: state.embedDomains.isLoaded,
});

const Extended = withTranslation()(DeployDebate);
Extended.static = DeployDebate.static;

export default connect(mapStateToProps, { loadEmbedDomains })(Extended);
