import React from "react";
import { Table, Modal, Form, Button } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
import axios from "axios";
import { API_URL } from "../../constants/Globals";
import { saveSelectedGroup } from "../../redux/action/networks.action";
import { withTranslation } from "react-i18next";

class Groups extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      searchText: "",
      order: "",
      orderBy: "title",
      showAddGroupModal: false,
      allGroups: "",
      groupFormData: {
        name: "",
      },
      currentEditingGroupId: "",
      currentEditingGroupName: "",
    };
  }

  fetchAllGroups = async (e) => {
    await this.setState({
      loading: true,
    });
    await axios
      .get(
        API_URL + "/admin/groups/getGroups?searchText=" + this.state.searchText,
      )
      .then((res) => {
        this.setState({
          allGroups: res.data.data,
          loading: false,
        });
        this.hideEditGroupNameField();
      })
      .catch((err) => {
        alert("Failed. Please try again!");
        console.log(err);
        this.setState({
          loading: false,
        });
      });
  };

  componentDidMount = () => {
    this.fetchAllGroups();
  };

  handleSearchSubmit = async (e) => {
    this.fetchAllGroups();
    e.preventDefault();
  };
  handleQueryChange = async (e) => {
    this.setState({ searchText: e.target.value });
  };

  showAddGroupModal = async () => {
    this.setState({
      showAddGroupModal: true,
    });
  };

  hideAddGroupModal = async (e) => {
    await this.setState({
      showAddGroupModal: false,
    });

    var groupFormData = { ...this.state.groupFormData };
    groupFormData["name"] = "";
    await this.setState({ groupFormData });
  };
  groupFormChangeHandler = async (e) => {
    var groupFormData = { ...this.state.groupFormData };
    groupFormData[e.target.name] = e.target.value.trimLeft();
    await this.setState({ groupFormData });
  };

  submitAddGroupHandler = async (e) => {
    e.preventDefault();
    await this.setState({
      loading: true,
    });
    await axios
      .post(API_URL + "/admin/groups/create", this.state.groupFormData)
      .then((res) => {
        this.fetchAllGroups();
        this.messageNotification(
          "success",
          this.props.t("Network group created successfully!"),
        );
        this.hideAddGroupModal();
      })
      .catch((err) => {
        alert(err.response.data.message[0].name);
        console.log(err.response.data);
        this.setState({ loading: false });
      });
  };

  messageNotification = async (type, message) => {
    this.setState({
      successMsg: message,
    });

    setTimeout(() => {
      this.setState({
        successMsg: "",
      });
    }, 5000);
  };

  showEditGroupNameField = async (e, groupId, groupName) => {
    this.setState({
      currentEditingGroupId: groupId,
      currentEditingGroupName: groupName,
    });
  };

  hideEditGroupNameField = async (e) => {
    this.setState({ currentEditingGroupId: "", currentEditedGroupName: "" });
  };

  groupNameChangeHandler = async (e) => {
    this.setState({
      currentEditingGroupName: e.target.value,
    });
  };
  saveEditGroupNameField = async (e, groupId) => {
    e.preventDefault();
    await this.setState({
      loading: true,
    });
    await axios
      .post(API_URL + "/admin/groups/updateGroup/" + groupId, {
        groupName: this.state.currentEditingGroupName,
      })
      .then((res) => {
        this.fetchAllGroups();
        this.messageNotification(
          "success",
          this.props.t("Group name updated successfully!"),
        );
      })
      .catch((err) => {
        alert(err.response.data.message[0].name);
        console.log(err.response.data);
        this.setState({ loading: false });
      });
  };

  handleGroupChange = async (e, groupSlug) => {
    await this.props.saveSelectedGroup(groupSlug);
  };

  render() {
    const loggedInUser = this.props.loggedInUser;
    const {
      loading,
      allGroups,
      currentEditingGroupId,
      currentEditingGroupName,
    } = this.state;
    const { name } = this.state.groupFormData;
    const kTrans = this.props.t ? this.props.t : "";

    return (
      <section className={loading === true ? "loading" : ""}>
        {this.state.successMsg ? (
          <div className="sucessMsg">{this.state.successMsg}</div>
        ) : null}
        <div className="inline-content top title-section mb-2">
          <h2>{kTrans("Network Groups")}</h2>
          {loggedInUser &&
          loggedInUser.roles &&
          loggedInUser.roles.length > 0 &&
          loggedInUser.roles.find((role) => role.name === "SUPERADMIN") ? (
            <span onClick={this.showAddGroupModal} className="btn btn-primary">
              + {kTrans("Add Group")}
            </span>
          ) : null}
        </div>
        <h3 className="mb-4">{kTrans("All Groups")}</h3>
        <div className="mb-3">
          <form
            id="search"
            autoComplete="off"
            className="navbar-form navbar-left w-25"
            method="get"
            onSubmit={this.handleSearchSubmit}
          >
            <input
              onChange={this.handleQueryChange}
              type="text"
              className="form-control"
              value={this.state.searchText}
              name="searchText"
              placeholder={kTrans("Search")}
            />
            <button type="submit">
              <i className="fa fa-search"></i>
            </button>
          </form>
        </div>

        <p>
          <small>
            <strong>
              {kTrans("Result")}
              {allGroups.length === 0 || allGroups.length > 1 ? "s" : ""}:{" "}
              {allGroups.length}
            </strong>
          </small>
        </p>
        <div className="table-wrapper">
          <Table className="networks tableCommon groupNetworksTable">
            <thead>
              <tr>
                <th className="w-50">{kTrans("GROUP NAME")}</th>
                <th>{kTrans("NETWORKS")}</th>
                <th>{kTrans("GROUP USERS")}</th>
              </tr>
            </thead>
            <tbody>
              {allGroups && allGroups.length ? (
                allGroups.map((el, i) => (
                  <tr id={el.groupId} key={el.groupId}>
                    <td className="d-flex align-items-baseline">
                      {currentEditingGroupId &&
                      currentEditingGroupId === el.groupId ? (
                        <Form.Group
                          controlId="groupName"
                          className="currentEditedGroupName pt-0 position-relative"
                        >
                          <Form.Control
                            type="text"
                            onChange={(e) => {
                              this.groupNameChangeHandler(e);
                            }}
                            name="currentEditedGroupName"
                            value={currentEditingGroupName.trimLeft()}
                            placeholder={kTrans("Enter the group name")}
                          />
                          <div className="editGroupNameBtns">
                            <span
                              className="cancel me-3 customAnchor light"
                              onClick={this.hideEditGroupNameField}
                            >
                              {kTrans("Cancel")}
                            </span>
                            <span
                              className="save customAnchor"
                              onClick={(e) =>
                                this.saveEditGroupNameField(e, el.groupId)
                              }
                            >
                              {kTrans("Save")}
                            </span>
                          </div>
                        </Form.Group>
                      ) : (
                        <>
                          <strong>{el.groupName}</strong>
                          {el.groupName !== "Sided" ? (
                            <svg
                              onClick={(e) =>
                                this.showEditGroupNameField(
                                  e,
                                  el.groupId,
                                  el.groupName,
                                )
                              }
                              className="ms-2"
                              width="40"
                              height="14"
                              viewBox="0 0 14 14"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M1.4 2.8H0V12.6C0 13.377 0.63 14 1.4 14H11.2V12.6H1.4V2.8ZM11.69 3.745L10.99 4.445L9.555 3.01L10.255 2.31C10.402 2.156 10.647 2.156 10.794 2.31L11.69 3.206C11.844 3.353 11.844 3.598 11.69 3.745ZM4.9 7.658L9.142 3.416L10.584 4.858L6.342 9.1H4.9V7.658ZM12.6 1.4V9.8H4.2V1.4H12.6ZM12.6 0H4.2C3.43 0 2.8 0.63 2.8 1.4V9.8C2.8 10.57 3.43 11.2 4.2 11.2H12.6C13.37 11.2 14 10.57 14 9.8V1.4C14 0.63 13.37 0 12.6 0Z"
                                fill="#43A5F5"
                              />
                            </svg>
                          ) : null}
                        </>
                      )}
                    </td>
                    <td>
                      {el.networkCount}{" "}
                      <NavLink
                        onClick={(e) => this.handleGroupChange(e, el.groupSlug)}
                        className="customAnchor d-inline-block"
                        to={`groups/${el.groupSlug}/networks`}
                      >
                        &nbsp;{kTrans("View")}
                      </NavLink>
                    </td>
                    <td>
                      {el.groupUsersCount}{" "}
                      <NavLink
                        onClick={(e) => this.handleGroupChange(e, el.groupSlug)}
                        className="customAnchor d-inline-block"
                        to={`/groups/${el.groupSlug}/users`}
                      >
                        &nbsp;{kTrans("View")}
                      </NavLink>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="5">
                    <h3 className="text-center">{kTrans("No Group Found")}</h3>
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </div>

        <Modal
          className="addGroupModal"
          size="lg"
          show={this.state.showAddGroupModal}
        >
          <Modal.Header>
            <Modal.Title>{kTrans("Add Group")}</Modal.Title>
            <span className="modal-close-btn">
              <b className="" onClick={this.hideAddGroupModal}>
                &#x2715;
              </b>
            </span>
          </Modal.Header>
          <Modal.Body className={loading === true ? "loading" : ""}>
            <div className="modalContentWrapper">
              <Form
                className="AddGroupForm common-form pe-md-5 ps-md-5"
                onSubmit={this.submitAddGroupHandler}
              >
                <Form.Group controlId="groupName" className="pt-0">
                  <Form.Label>{kTrans("GROUP NAME")}</Form.Label>
                  <Form.Control
                    type="text"
                    onChange={(e) => {
                      this.groupFormChangeHandler(e);
                    }}
                    name="name"
                    value={name}
                    placeholder={kTrans("Enter the group name")}
                  />
                </Form.Group>
                <div className="buttons text-center">
                  <Button
                    className="mt-2"
                    onClick={this.hideAddGroupModal}
                    variant="secondary"
                  >
                    {kTrans("Cancel")}
                  </Button>
                  <Button
                    className={`mt-2 ${name === "" ? "disabled" : ""}`}
                    variant="primary"
                    type="submit"
                  >
                    {kTrans("Create Group")}
                  </Button>
                </div>
              </Form>
            </div>
          </Modal.Body>
        </Modal>
      </section>
    );
  }
}

const mapStateToProps = (state) => ({
  loggedInUser: state.login.user,
});

const Extended = withTranslation()(Groups);
Extended.static = Groups.static;

export default connect(mapStateToProps, { saveSelectedGroup })(Extended);
