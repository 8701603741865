/* eslint-disable */
export const ES = {
  'Your Polls': 'Tus encuestas',
  'CREATE NEW POLL':'Crear una nueva encuesta',
  'Poll Details':'Detalles de la encuesta',
  'Deploy Poll':'Implementar la encuesta',
  'Poll Question or Statement':'Pregunta o declaración de la encuesta',
  'Type question or statement here...':'Escriba la pregunta o la declaración aquí...',
  'Your Sides':'Tus lados',
  'Type your side here':'Escriba su lado aquí',
  'Side':'Lado',
  'Add Side':'Agregar el lado',
  'Include Poll Image':'Incluir imagen de la encuesta',
  'Optional':'Opcional',
  'Additional Info':'Información adicional',
  'Custom CTA':'CTA personalizado',
  'Recommended 270 x 134 px':'Recomendado 270 x 134 PX',
  'Add more info about your poll':'Agregue más información sobre su encuesta',
  'Customize CTA shown with results':'Personalice la CTA mostrada con los resultados',
  'Add Image':'Añadir imagen',
  'Add Info':'Agregar información',
  'Add CTA':'Agregar CTA',
  'Share any information that might help people choose a side':'Comparta cualquier información que pueda ayudar a las personas a elegir un lado',
  'Cancel':'Cancelar',
  'Save':'Ahorrar',
  'Enter affiliate link here':'Ingrese el enlace de afiliado aquí',
  'Remove':'Eliminar',
  'Change':'Cambiar',
  'Save Draft':'Guardar borrador',
  'Publish':'Publicar',
  'Start Date':'Fecha de inicio',
  'End Date':'Fecha final',
  'No end date':'Sin fecha de finalización',
  'Poll Author':'Autor de la encuesta',
  'Poll Category':'Categoría de encuesta',
  'Select Category':'selecciona una categoría',
  'Select Author':'Autor seleccionado',
  'Poll Preview':'Vista previa de la encuesta',
  'Poll ends in':'La encuesta termina en',
  'Vote below':'Votar a continuación',
  'Votes':'Votos',
  'Share':'Compartir',
  'Embed Poll':'Encuesta de incrustación',
  'The Statement or Question field is required.':'Se requiere la declaración o el campo de la pregunta.',
  'The side field is required.':'Se requiere el campo lateral.',
  'Syndication':'Sindicación',
  'Make available to XX network':'Poner a disposición de la red xx',
  'Edit networks':'Editar redes',
  'View on Sided':'Vista sobre el costado',
  'Notify':'Notificar',
  'Deploy this poll to your website by selecting page(s) below and clicking "Deploy to Selected" then selecting the appropriate "Placement". You can also "Deploy to All Pages" or "Deploy to Category"':'Implemente esta encuesta en su sitio web seleccionando las páginas a continuación y haciendo clic en "Implementar en seleccionado" y luego seleccionando la "ubicación" apropiada. También puede "implementar en todas las páginas" o "implementar en categoría"',
  'Deploying to this URL':'Desplegando a esta URL',
  'Available Pages':'Páginas disponibles',
  'Active Pages':'Páginas activas',
  'These are the pages on your website that polls can be deployed to':'Estas son las páginas de su sitio web a las que se pueden implementar encuestas para',
  'These are the pages on your website where this poll is currently showing':'Estas son las páginas de su sitio web donde se muestra actualmente esta encuesta.',
  'Bulk Actions':'Acciones masivas',
  'Search for a page':'Buscar una página',
  'PAGE NAME':'NOMBRE DE LA PÁGINA',
  'EMAIL EMBED':'Incruta de correo electrónico',
  'Copy Code':'Copiar código',
  'Deploy':'Desplegar',
  'Deployed':'Desplegada',
  'View page details':'Ver detalles de la página',
  'POLLS ON THIS PAGE':'Encuestas en esta página',
  'PUBLISHED':'PUBLICADO',
  'CLOSE DATE':'Fecha de cierre',
  'PLACEMENT':'COLOCACIÓN',
  'STATUS':'ESTADO',
  'POLL':'ENCUESTA',
  'View':'Vista',
  'Active':'Activo',
  'Closed':'Cerrado',
  'Deploy to All Pages':'Desplegar en todas las páginas',
  'Deploy to Category':'Desplegar en categoría',
  'Deploy to Selected':'Desplegar en seleccionado',
  'Remove Selected':'Eliminar selección',
  'Remove from All Pages':'Retirar de todas las páginas',
  'Deployed to XX category ':'Desplegado en categoría XX',
  'Publish to Category':'Publicar en categoría',
  'Choose the categories you would like to publish this poll to':'Elija las categorías a las que le gustaría publicar esta encuesta',
  'Category':'Categoría',
  'Placement':'Colocación',
  'Search categories':'Categorías de búsqueda',
  'Available placements':'Ubicaciones disponibles',
  'Replace existing polls':'Reemplazar las encuestas existentes',
  'Back':'Atrás',
  'Select Placement':'Seleccionar colocación',
  'Publish Poll':'Publicar encuesta',
  'Polls':'Centro',
  'Top Polls':'Encuestas',
  'Search for a poll or page':'Buscar una encuesta o página',
  'Author':'Autor',
  'Draft Saved!':'¡Borrador guardado!',
  'Published':'Publicado',
  'View Poll':'Ver la encuesta',
  'Edit Poll':'Editar encuesta',
  'EDIT POLL':'EDITAR ENCUESTA',
  'Delete Poll':'Eliminar encuesta',
  'GROUP':'GRUPO',
  'Analytics':'Analítica',
  'Poll Analytics':'Análisis de la encuesta',
  'Poll Impressions':'Impresiones de la encuesta',
  'Audience':'Audiencia',
  'Impressions':'Impresiones',
  'IMPRESSIONS':'IMPRESIONES',
  'Revenue':'Ganancia',
  'eCPM':'ECPM',
  'Overall':'En general',
  'Mobile':'Móvil',
  'Desktop':'Escritorio',
  'Clicks':'Hacer clics',
  'XX Users':'Xx usuarios',
  'Search by name, username or email':'Buscar por nombre, nombre de usuario o correo electrónico',
  'ADD USER':'AGREGAR USUARIO',
  'INVITE USER':'Invitar al usuario',
  'Invite User':'Invitar al usuario',
  'Filter users by role':'Filtrar usuarios por rol',
  'All':'Todo',
  'Verified':'Verificado',
  'Superadmin':'Superadmin',
  'Pending invites':'Invitaciones pendientes',
  'USER':'USUARIO',
  'EMAIL':'CORREO ELECTRÓNICO',
  'POLLS':'CENTRO',
  'ARGUMENTS':'Argumentos',
  'COMMENTS':'Comentario',
  'POINTS':'PUNTOS',
  'VERIFIED':'Verificado',
  'ADMIN':'ADMINISTRACIÓN',
  'SETTINGS':'AJUSTES',
  'LAST LOGIN':'ÚLTIMO ACCESO',
  'Settings':'Ajustes',
  'Network Settings':'Configuración de la red',
  'Categories':'Categorías',
  'Admin Settings':'Configuración de administrador',
  'GENERAL INFO':'INFORMACIÓN GENERAL',
  'NETWORK NAME':'NOMBRE DE RED',
  'Network Name':'Nombre de red',
  'SUBDOMAIN':'Subdominio',
  'CUSTOM DOMAIN':'DOMINIO PERSONALIZADO',
  'PRIMARY DOMAIN':'Dominio primario',
  'APPROVED SUBDOMAINS':'Subdominios aprobados',
  'Example':'Ejemplo',
  'Confirm':'Confirmar',
  'REQUIRE EMAIL TO SEE POLL RESULTS':'Requiere correo electrónico para ver los resultados de la encuesta',
  'Yes':'Sí',
  'No':'No',
  'POINTS & LEADERBOARD':'Puntos y tabla de clasificación',
  'COMMENTS ON SIDED':'Comentarios en la costumbre',
  'DEFAULT POLL DURATION':'Duración de la encuesta predeterminada',
  'MAX POLL DURATION':'Duración de la encuesta máxima',
  'days':'días',
  'META':'META',
  'TITLE':'TÍTULO',
  'LOGO':'LOGO',
  'FAVICON':'Favicón',
  'EMAIL LOGO':'Logotipo de correo electrónico',
  'AFFILIATE LINK':'ENLACE DE AFILIADO',
  'LINK':'ENLACE',
  'BUTTON TEXT':'BOTÓN DE TEXTO',
  'EXTRA':'EXTRA',
  'GOOGLE ANALYTICS ID':'IDENTIFICACIÓN DE GOOGLE ANALYTICS',
  'GAM ID':'ID de gam',
  'FACEBOOK PIXEL ID':'ID de píxel de Facebook',
  'Facebook Pixel ID':'ID de píxel de Facebook',
  'SUPPORT NAME':'Nombre de apoyo',
  'SUPPORT EMAIL':'Correo electrónico de soporte',
  'BRAND ACCOUNT':'Cuenta de marca',
  'Create a brand account':'Crear una cuenta de marca',
  'Integration Settings':'Configuración de integración',
  'API KEY':'CLAVE API',
  'Generate API Key':'Generar la tecla API',
  'Deactivate API key':'Desactivar la tecla API',
  'Generate new API key':'Generar nueva clave API',
  'Copy API Key':'Copiar la clave API',
  'SSO Access Token':'Token de acceso SSO',
  'Generate SSO Access Token':'Generar token de acceso SSO',
  'Generating':'Generador',
  'Deactivating':'Desactivado',
  'Deactivate SSO Access Token':'Desactivar el token de acceso SSO',
  'Generate new SSO Access Token':'Generar un nuevo token de acceso SSO',
  'Copy SSO Access Token':'Copiar token de acceso SSO',
  'HEADER CODE':'Código de encabezado',
  'Add this code to your &lt;HEAD&gt; tag on your website':'Agregue este código a su etiqueta &lt;Head&gt; en su sitio web',
  'Copy header code':'Copiar código de encabezado',
  'POLL FEED CODE':'Código de alimentación de la encuesta',
  'Poll Feed Code':'Código de alimentación de la encuesta',
  'Header code':'Código de encabezado',
  'Add this code to any page to display a feed of polls':'Agregue este código a cualquier página para mostrar una alimentación de encuestas',
  'Copy poll feed code':'Copiar código de alimentación de la encuesta',
  'EMBED PLACEMENTS':'Colocaciones de incrustación',
  'Define where your embeds show on your website. Use the default "in-article" placement, or create a new one and add the iframe code(s) below to your website template':'Defina dónde se muestran sus incrustaciones en su sitio web. Use la colocación predeterminada de "Artículo", o cree una nueva y agregue los código (s) de iframe a continuación a la plantilla de su sitio web',
  'Add Placement':'Agregar colocación',
  'Enter new Placement':'Ingrese una nueva colocación',
  'This field can not be blank':'Este campo no puede estar vacío',
  'PLACEMENT NAME':'Nombre de colocación',
  'ADS':'Anuncios',
  'TOP COMMENT':'Comentario',
  'DEFAULT':'POR DEFECTO',
  'EMBED':'EMPOTRAR',
  'Placement Settings':'Configuración de colocación',
  'Placement Name':'Nombre de colocación',
  'Comments':'Comentario',
  'On':'En',
  'Off':'Apagado',
  'Require email to see poll results':'Requiere correo electrónico para ver los resultados de la encuesta',
  'Copy embed code':'Copie el código insertado',
  'Embed code':'Código de inserción',
  'Placement Preview':'Vista previa de colocación',
  'Your Name':'Su nombre',
  'Your poll title':'El título de tu encuesta',
  'Posted':'Al corriente',
  'Top comments':'Top Comentarios',
  'Placement default':'Colocación predeterminado',
  'Show Ads':'Mostrar anuncios',
  'Ad Position':'Posición publicitaria',
  'Display':'Mostrar',
  'Ad Code':'Código publicitario',
  'Side panel only':'Solo panel lateral',
  'Left':'Izquierda',
  'Bottom':'Abajo',
  'Right':'Bien',
  'Top':'Arriba',
  'CATEGORIES':'Categorías',
  'Add Category':'añadir categoría',
  'Enter new category':'Ingrese nueva categoría',
  'Featured':'Presentado',
  'Admin':'Administración',
  'Archive':'Archivo',
  'Category image size should not be greater than 100kb':'El tamaño de la imagen de la categoría no debe ser mayor de 100kb',
  'Category name is required.':'Se requiere el nombre de la categoría.',
  'ADVERTISING SOURCE':'Fuente publicitaria',
  'ALLOWED DOMAINS':'Dominios permitidos',
  'ADVERTISING':'PUBLICIDAD',
  'PRIVATE NETWORK':'RED PRIVADA',
  'DEFAULT NETWORK':'Red predeterminada',
  'API ACCESS':'Acceso a la API',
  'Archive network':'Red de archivos',
  'Create brand account':'Crear cuenta de marca',
  'Add Brand Account':'Agregar cuenta de marca',
  'NAME':'NOMBRE',
  'USERNAME':'NOMBRE DE USUARIO',
  'BIO':'Biografía',
  'WEBSITE':'SITIO WEB',
  'PROFILE IMAGE':'IMAGEN DE PERFIL',
  'BACKGROUND IMAGE':'IMAGEN DE FONDO',
  'Generate password':'Generar contraseña',
  'Create Brand Account':'Crear cuenta de marca',
  'The name field is required.':'Se requiere el campo de nombre.',
  'The username field is required.':'Se requiere el campo de nombre de usuario.',
  'This field is required.':'Este campo es obligatorio.',
  'NETWORK':'RED',
  'Filter networks':'Redes de filtro',
  'ADD NETWORK':'AGREGAR RED',
  'Add Network':'Agregar Red',
  'Active networks':'Redes activas',
  'Archived networks':'Redes archivadas',
  'USERS':'Usuarios',
  'Enter your network name':'Ingrese su nombre de red',
  'Use only letter, number and _.':'Use solo letra, número y _.',
  'Type domain name':'Tipo de nombre de dominio',
  'Example: domain.com':'Ejemplo: Domain.com',
  'The Domain Url is invalid.':'La URL del dominio no es válida.',
  'Enter title':'Ingrese el título',
  'Upload logo':'Subir logotipo',
  'Upload Icon':'Icono de carga',
  'Large':'Grande',
  'Square':'Cuadrado',
  'Medium':'Medio',
  'Enter affiliate link':'Ingrese el enlace de afiliado',
  'Enter button text':'Ingrese el texto del botón',
  'Enter Support Name':'Ingrese el nombre de soporte',
  'Enter email address':'Introducir la dirección de correo electrónico',
  'The email must be a valid email address.':'El correo electrónico debe ser una dirección de correo electrónico válida.',
  'Network Groups':'Grupos de red',
  'Add Group':'Añadir grupo',
  'All Groups':'Todos los grupos',
  'GROUP NAME':'NOMBRE DEL GRUPO',
  'NETWORKS':'Redes',
  'GROUP USERS':'Usuarios de grupos',
  'Enter the group name':'Ingrese el nombre del grupo',
  'Create Group':'Crea un grupo',
  'Users':'Usuarios',
  'Your Group Users':'Los usuarios de su grupo',
  'Search':'Buscar',
  'All Users':'Todos los usuarios',
  'Pending Invites':'Invitaciones pendientes',
  'EDITOR':'EDITOR',
  'MODERATOR':'MODERADOR',
  'Editor':'Editor',
  'Moderator':'Moderador',
  'ADVANCED SETTINGS':'AJUSTES AVANZADOS',
  'Advanced User Settings':'Configuración avanzada del usuario',
  'Add to Group':'Añadir al grupo',
  'GROUP(S)':'Grupo (s)',
  'NETWORK(S)':'Red (s)',
  'Invite User(s)':'Invitar a los usuarios',
  'Invite people to Sided':'Invitar a las personas a lado',
  'Invite people to join this group':'Invite a la gente a unirse a este grupo',
  'To':'A',
  'please enter comma-separated values':'Ingrese los valores separados por comas',
  'Invite as':'Invitar a',
  'Group Admin':'Administrador del grupo',
  'Group Editor':'Editor de grupo',
  'Group Moderator':'Moderador de grupo',
  'Send':'Enviar',
  'Please wait...':'Espere por favor...',
  'Are you sure you want to remove this user from group?':'¿Estás seguro de que quieres eliminar a este usuario del grupo?',
  'View Profile':'Ver perfil',
  'Edit My Profile':'Editar mi perfil',
  'Help':'Ayuda',
  'Logout':'Cerrar sesión',
  'PASSWORD':'CONTRASEÑA',
  'OLD PASSWORD':'CONTRASEÑA ANTERIOR',
  'NEW PASSWORD':'NUEVA CONTRASEÑA',
  'CONFIRM NEW PASSWORD':'CONFIRMAR NUEVA CONTRASEÑA',
  'Update':'Actualizar',
  'Delete Account':'Borrar cuenta',
  'The bio field is required':'Se requiere el campo bio',
  'Image is invalid. Please upload only PNG or JPG image.': 'La imagen no es válida. Cargue solo una imagen PNG o JPG.',
  'Pages':'Páginas',
  'page' : 'página',
  'Sided Networks':'Sided redes',
  'Delete this poll':'Eliminar esta encuesta',
  'Are you sure you want to delete this poll?':'¿Estás seguro de que quieres eliminar esta encuesta?',
  'YES, DELETE':'Si, eliminar',
  'Not Deployed on any pages':'No se despliega en ninguna página',
  'Published on':'Publicado en',
  'No Domains found':'No se encontraron dominios',
  'Poll Embed Code':'Código de inserción de la encuesta',
  'Are you sure you want to remove poll from all pages?':'¿Estás seguro de que quieres eliminar la encuesta de todas las páginas?',
  'Select the location where this poll will show':'Seleccione la ubicación donde mostrará esta encuesta',
  'Please confirm you want to publish this poll to the following':'Confirme que desea publicar esta encuesta a lo siguiente',
  'Available categories':'Categorías disponibles',
  'Remove poll from':'Eliminar la encuesta de',

  'Please confirm you want to unpublish this poll from the following':'Confirme que desee no publicar esta encuesta de lo siguiente',
  'Edit Poll Location':'Editar ubicación de la encuesta',
  'Select location where this poll appears':'Seleccione la ubicación donde aparece esta encuesta',
  'Publish to Site':'Publicar en el sitio',
  'Sent':'Enviado',
  'Choose the networks you would like to make your poll available to, you are not responsible for any polls you create that other users utilize':'Elija las redes a las que desea que su encuesta esté a disposición, no es responsable de las encuestas que cree que otros usuarios utilizan',
  'Available networks':'Redes disponibles',
  'Search networks':'Redes de búsqueda',
  'No Page Found':'No se encontró la página',
  'Last 2 weeks':'Últimas 2 semanas',
  'Last 30 Days':'Últimos 30 días',
  'This Month':'Este mes',
  'Last Month':'El mes pasado',
  'Last 90 Days':'Últimos 90 días',
  'This year':'Este año',
  'Last year':'El año pasado',
  'No Network':'Sin red',
  'NETWORK BREAKDOWN':'Desglose de red',
  'Manage Networks':'Administrar redes',
  'Manage Group Users': 'Administrar usuarios de grupo',
  'Manage':'Administrar',
  'You have just added the following networ':'Acabas de agregar la siguiente red',
  'Success':'Éxito',
  'Select from available networks or select "Add New Network" to add a new network':'Seleccione de las redes disponibles o seleccione "Agregar nueva red" para agregar una nueva red',
  'You have just added the following network':'Acabas de agregar la siguiente red',
  'All Networks':'Todas las redes',
  'Add to Network':'Agregar a la red',
  'User removed from network successfully!':'¡Usuario eliminado de la red correctamente!',
  'Are you sure you want to remove this user from network?':'¿Estás seguro de que quieres eliminar a este usuario de la red?',
  'Are you sure you want to remove this network from group?':'¿Estás seguro de que quieres eliminar esta red del grupo?',
  'No User Found':'Usuario no encontrado',
  'Resend':'Revender',
  'Add User to Group':'Agregar usuario al grupo',
  'Select any of the groups below':'Seleccione cualquiera de los grupos a continuación',
  'Available Groups':'Grupos disponibles',
  'Add':'Agregar',
  'You have just added the following user to group':'Acaba de agregar el siguiente usuario para agrupar',
  'Select a Group':'Seleccione un grupo',
  'No group available!':'¡No hay grupo disponible!',
  'No suggested poll found':'No se encontró una encuesta sugerida',
  'No top poll found':'No se encontró ninguna encuesta superior',
  "You don't currently have access to any domains":'Actualmente no tiene acceso a ningún dominio',
  'Contact':'Contacto',
  'if you believe this is an error':'Si crees que este es un error',
  'Close':'Cerca',
  'Advertising':'Publicidad',
  'Networks':'Redes',
  'Network Users':'Usuarios de la red',
  'NETWORK USERS':'USUARIOS DE LA RED',
  'Create A Poll':'Crear una encuesta',
  'Results':'Resultados',
  'Result':'Resultado',
  'Suggested Polls':'Encuestas sugeridas',
  'VOTES':'VOTOS',
  'Engagements':'Compromisos',
  'ACTION':'ACCIÓN',
  'No Poll':'Sin encuesta',
  "Are you sure you want to delete this user? This action is permanent and can't be reversed.":'¿Estás seguro de que quieres eliminar a este usuario? Esta acción es permanente y no se puede revertir.',
  'No network found!':'¡No se encontró red!',
  'PHONE':'TELÉFONO',
  'Enter the user’s email':'Ingrese el correo electrónico del usuario',
  'Enter the user’s phone':'Ingrese el teléfono del usuario',
  'Enter the user’s name':'Ingrese el nombre del usuario',
  'Choose a username':'Elige un nombre de usuario',
  'Enter a password':'Ingrese una contraseña',
  'Create User':'Crear usuario',
  'Invite people to join':'Invite a la gente a unirse',
  'Please make sure you enter valid comma-separated email addresses':'Asegúrese de ingresar direcciones de correo electrónico separadas por comisión válidas',
  'Congratulations!':'¡Felicidades!',
  'Your invites have successfully been sent for the following':'Tus invitaciones se han enviado con éxito para lo siguiente',
  "What's next?":'¿Que sigue?',
  'Add more people to this':'Agregue más personas a esto',
  'group':'grupo',
  'Group':'Grupo',
  'domain':'dominio',
  'Once your recipients accept their invites, they’ll have access to your':'Una vez que sus destinatarios acepten sus invitaciones, tendrán acceso a su',
  'You can check their status on the':'Puede verificar su estado en el',
  "Are you sure you want to remove this domain? This action is permanent and can't be reversed.":'¿Estás seguro de que quieres eliminar este dominio? Esta acción es permanente y no se puede revertir.',
  'CONTRIBUTORS ONLY':'Solo contribuyentes',
  'Image is invalid. Please upload only PNG, JPG or GIF image.':'La imagen no es válida. Sube solo la imagen PNG, JPG o GIF.',
  'Image is invalid. Please upload only PNG, JPG, GIF or ICO image.':'La imagen no es válida. Sube solo la imagen PNG, JPG, GIF o ICO.',
  'SOCIAL LOGIN':'Inicio de sesión social',
  'FACEBOOK CLIENT ID':'ID de cliente de Facebook',
  'FACEBOOK CLIENT SECRET':'Secreto del cliente de Facebook',
  'Facebook Client ID':'ID de cliente de Facebook',
  'Facebook Client Secret':'Secreto del cliente de Facebook',
  'TWITTER CLIENT ID':'ID de cliente de Twitter',
  'Twitter Client ID':'ID de cliente de Twitter',
  'TWITTER CLIENT SECRET':'Secreto del cliente de Twitter',
  'Twitter Client Secret':'Secreto del cliente de Twitter',
  'SOCIAL LINKS':'VÍNCULOS SOCIALES',
  'FACEBOOK':'FACEBOOK',
  'INSTAGRAM':'Instagram',
  'Do you really want to':'¿De verdad quieres',
  'archive':'archivo',
  'unarchive':'desinfectante',
  'this network?':'esta red?',
  'API Access':'Acceso a la API',
  'Upload icon':'Icono de carga',
  'Google Analytic ID':'ID de Google Analytic',
  'The Statement or Question field is required.':'Se requiere la declaración o el campo de la pregunta.',
  'Please do not enter more than 140 characters.':'No ingrese más de 140 caracteres.',
  'The side field is required.':'Se requiere el campo lateral.',
  'The maximum number of characters is 140.':'El número máximo de caracteres es 140.',
  'Poll published successfully.':'Encuesta publicada con éxito.',
  'Debate updated successfully.':'Debate actualizado con éxito.',
  'Email Embed code copied to clipboard!':'¡Código de incrustación de correo electrónico copiado al portapapeles!',
  'Embed code copied to clipboard!':'¡Inserte el código copiado en el portapapeles!',
  'Please select location where poll appears.':'Seleccione la ubicación donde aparezca la encuesta.',
  'Poll deployed successfully!':'Encuesta desplegada con éxito!',
  'Publish poll to this page to embed in email':'Publicar encuesta en esta página para incrustar en el correo electrónico',
  'Unpublish':'Inútil',
  'Poll has been deleted successfully!':'¡La encuesta se ha eliminado con éxito!',
  'Audience ':'Audiencia',
  'Add New User':'Añadir nuevo usuario',
  'Copy password':'Copiar contraseña',
  'Password copied to clipboard!':'Contraseña copiada al portapapeles!',
  'The password field is empty.':'El campo de contraseña está vacío.',
  'User updated successfully!':'¡Usuario actualizado con éxito!',
  'Please enter valid Phone Number.':'Ingrese el número de teléfono válido.',
  'Please enter a valid email address.':'Por favor, introduce una dirección de correo electrónico válida.',
  'Use only letter, number and _.':'Use solo letra, número y _.',
  'Username must be between 3 to 20 characters.':'El nombre de usuario debe estar entre 3 y 20 caracteres.',
  'Password must be at least 6 characters long.':'La contraseña debe contener 6 caracteres como mínimo.',
  'Name must be max 50 characters.':'El nombre debe ser máximo de 50 caracteres.',
  'View brand account':'Ver cuenta de marca',
  'Edit brand account':'Editar cuenta de marca',
  'Settings saved!':'¡Ajustes guardados!',
  'Group name updated successfully!':'¡Nombre del grupo actualizado con éxito!',
  'Network group created successfully!':'¡Grupo de red creado con éxito!',
  'Select a Network':'Seleccione una red',
  'Invite expired':'Invitación expiró',
  'Search for a network':'Buscar una red',
  'Count':'Contar',
  'Add New Network':'Agregar nueva red',
  'There is some problem in copying embed code. Please reload the page and try again!':'Hay algún problema en la copia del código de inserción. ¡Recargue la página y vuelva a intentarlo!',
  'Please enter valid URL.':'Ingrese URL válida.',
  'This poll is not published yet.':'Esta encuesta aún no se ha publicado.',
  'Publisher':'Editor',
  'Adsense':'Adsense',
  'Publisher Desk':'Escritorio de editor',
  'Your poll side':'Tu lado de la encuesta',
  'Exclude from leaderboard Exclude from leaderboard':'Excluir de la tabla de clasificación Excluir de la tabla de clasificación',
  'Exclude from suggestions Exclude from suggestions':'Excluir de las sugerencias excluir de las sugerencias',
  'Embed ads Embed ads':'Incrustar anuncios de incrustación',
  'Poll only embeds Poll only embeds':'La encuesta solo incorpora la encuesta solo incrusta',
  'Brand Account Brand Account':'Cuenta de marca Cuenta de marca',
  'Active Networks Active Networks':'Redes activas redes activas',
  'Your Networks':'Tus redes',
  'No network available!':'¡No hay red disponible!',
  'No category available':'No hay categoría disponible',
  'No author available':'No hay autor disponible',
  'Poll image size should not be greater than 10mb':'El tamaño de la imagen de la encuesta no debe ser mayor de 10 MB',
  'Contributer':'Contribuyente',
  'Exclude from leaderboard':'Excluir de la tabla de clasificación',
  'Exclude from suggestions':'Excluir de las sugerencias',
  'Embed ads':'Incrustar anuncios',
  'Poll only embeds':'Encuesta solo incrusta',
  'Brand Account':'Cuenta de marca',
  'Active Networks':'Redes activas',
  'Delete User':'Borrar usuario',
  'Add network':'Agregar red',
  'Remove':'Eliminar',
  ' copied to clipboard.':' Copiado al portapapeles.',
  'Deactivating...':'Desactivando...',
  'Generating...':'Generando...',
  'New placement added successfully!':'¡Nueva ubicación agregada con éxito!',
  'Embed Placement updated successfully!':'¡La colocación de incrustaciones actualizada con éxito!',
  'BUSINESS NAME':'NOMBRE DEL NEGOCIO',
  'Name':'Nombre',
  'BUSINESS WEBSITE':'Sitio web de negocios',
  'SIDED SUBDOMAIN':'Subdominio del lado',
  'yoursubdomain':'suministro suyo',
  'Suspend':'Suspender',
  'LOCATION':'UBICACIÓN',
  'The email field is required.':'Se requiere el campo de correo electrónico.',
  'Image size should not be greater than 20mb':'El tamaño de la imagen no debe ser mayor de 20 MB',
  "Enter the user's name":'Ingrese el nombre del usuario',
  'Device Breakdown':'Desglose del dispositivo',
  'Add User to Network':'Agregar usuario a la red',
  'Available Networks':'Redes disponibles',
  'Copy Poll Email Embed Code':'Copiar código de inserción de correo electrónico de la encuesta',
  'Poll updated successfully.':'Encuesta actualizada con éxito.',
  'Privacy Policy':'política de privacidad',
  'Terms of Service':'Términos de servicio',
  'SPANISH':'ESPAÑOL',
  'User added to network successfully':'Usuario agregado a la red correctamente',
  'Poll unpublished successfully!':'¡Encuesta inédita con éxito!'
}
