import React from "react";
import logo from "../logo.png";

function Loader({ transparent = false, absolute = false }) {
  const classNames = ["loader-top"];

  if (transparent) {
    classNames.push("transparent");
  }

  if (absolute) {
    classNames.push("absolute");
  }

  return (
    <div className={classNames.join(" ")} style={{ display: "block" }}>
      <div className="cssload-container">
        <img alt="Pre-Loader" src={logo} className="logo-loader" />
        <div className="cssload-loading">
          <i></i>
          <i></i>
          <i></i>
          <i></i>
        </div>
      </div>
    </div>
  );
}

export default Loader;
