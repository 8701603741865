const init = {
  isLoading: false,
  networksList: null,
  networks: null,
  selNetworkId: null,
  groupsList: null,
  selGroupSlug: null,
  selLanguage: "en",
};

export const networksReducer = (state = init, action) => {
  switch (action.type) {
    case "NETWORKS_LOADING":
      return {
        ...state,
        isLoading: true,
      };
    case "NETWORKS_LIST_LOADED":
      var selNetworkId = null;
      var networksList = action.payload.data;
      if (networksList && networksList.length > 0) {
        let selNetworkIdLocal = localStorage.getItem("selNetworkId");
        if (selNetworkIdLocal) {
          if (
            networksList.find(
              (network) => parseInt(network.id) === parseInt(selNetworkIdLocal),
            )
          ) {
            selNetworkId = selNetworkIdLocal;
          }
        }
        if (!selNetworkId) {
          /*if( action.userRoles.length > 0 && action.userRoles.find(role => role.name === 'SUPERADMIN') ) {
            selNetworkId = '1';
          } else {
            selNetworkId = networksList[0].id;
            //selNetworkId = 1;
          }*/

          if (
            networksList.length > 0 &&
            networksList.find((item) => item.id === 1)
          ) {
            selNetworkId = "1";
          } else {
            selNetworkId = networksList[0].id;
          }
        }
      }
      return {
        ...state,
        isLoading: false,
        networksList: networksList,
        selNetworkId: selNetworkId,
      };
    case "NETWORKS_LOADED":
      return {
        ...state,
        isLoading: false,
        networks: action.payload.data,
      };
    case "SAVE_CURRENT_NETWORK":
      localStorage.setItem("selNetworkId", action.payload.id);
      localStorage.setItem(
        "selLanguage",
        action.payload.isSpanish && action.payload.isSpanish === true
          ? "es"
          : "en",
      );
      return {
        ...state,
        isLoading: false,
        selNetworkId: action.payload.id,
        selLanguage:
          action.payload.isSpanish && action.payload.isSpanish === true
            ? "es"
            : "en",
      };

    case "SAVE_CURRENT_GROUP":
      localStorage.setItem("selGroupSlug", action.payload);
      return {
        ...state,
        isLoading: false,
        selGroupSlug: action.payload,
      };

    case "GROUPS_LIST_LOADED":
      var groupsList = action.payload.data;
      var selGroupSlug = null;
      let selGroupSlugLocal = localStorage.getItem("selGroupSlug");
      if (selGroupSlugLocal) {
        if (groupsList.find((group) => group.groupSlug === selGroupSlugLocal)) {
          selGroupSlug = selGroupSlugLocal;
        }
      }
      if (!selGroupSlug) {
        selGroupSlug = groupsList[0].groupSlug;
      }
      return {
        ...state,
        isLoading: false,
        groupsList: groupsList,
        selGroupSlug: selGroupSlug,
      };
    default:
      return state;
  }
};
