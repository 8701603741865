import React from "react";
import { connect } from "react-redux";
import { login } from "../../redux/action/login.action";
import { NavLink } from "react-router-dom";
import { SIDED_FAVICON, SIDED_LOGO } from "../../constants/Globals";
import { ClientJS } from "clientjs";
const client = new ClientJS();
const fingerprint = client.getFingerprint();

class Login extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      field: "",
      password: "",
      deviceId: fingerprint,
      loaded: false,
    };
  }

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.login(this.state);
  };

  componentDidMount() {
    this.setState({
      loaded: true,
    });
  }
  render() {
    const { field, password } = this.state;
    const { pathname } = this.props.location;
    if (
      pathname === "/forgot-password" ||
      pathname === "/onboarding" ||
      pathname.indexOf("/join/") !== -1
    ) {
      return null;
    }
    return (
      <div className={`login-div text-center loaded-${this.state.loaded}`}>
        <div className="login-container">
          <div className="logo-section">
            <img className="authTopFav" src={SIDED_FAVICON} alt="Sided" />
            <img className="authTopLogo" src={SIDED_LOGO} alt="Sided" />
          </div>
          <form onSubmit={this.handleSubmit}>
            <fieldset>
              <input
                type="text"
                placeholder="Email or Username"
                name="field"
                required
                onChange={this.handleChange}
                value={field}
                className="form-control"
              />
              <i className="fa fa-user"></i>
            </fieldset>
            <fieldset>
              <input
                type="password"
                placeholder="Password"
                name="password"
                required
                onChange={this.handleChange}
                value={password}
                className="form-control"
              />
              <i className="fa fa-lock"></i>
            </fieldset>
            <div className="alt-option mw360">
              <NavLink className="text-button" exact to="/forgot-password">
                Forgot Password?
              </NavLink>
            </div>
            <button
              className="btn btn-primary btn-block"
              onSubmit={this.handleSubmit}
            >
              LOGIN
            </button>
            <div className="validation-error">{this.props.loginError}</div>

            <a
              className="mt-4"
              href="mailto:support@sided.co?subject=Sided support request"
            >
              Need Help?
            </a>
          </form>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  loginError: state.login.loginError,
});

const mapDispatchToProps = (dispatch) => ({
  login: (isLogin) => dispatch(login(isLogin)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
