import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import axios from "axios";
import {
  API_URL,
  DEFAULT_USER_IMG_URL,
  MAIN_WEBSITE_URL,
} from "../../../constants/Globals";
var moment = require("moment-timezone");

class ReportedComment extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      readMore: false,
      reportedContentData: {},
    };
  }

  fetchReportedContentData = async (e) => {
    var api_name =
      this.props.reportableType === "App\\Comment" ? "/comment/" : "/argument/";
    await axios
      .get(API_URL + api_name + this.props.reportableId + "?showDeleted=1")
      .then((res) => {
        this.setState({ reportedContentData: res.data.data });
      })
      .catch((err) => {
        console.log("Axios Error for " + api_name);
        console.log(err);
      });
  };

  componentDidMount = () => {
    this.fetchReportedContentData();
  };

  render() {
    const { reportedContentData } = this.state;

    return (
      <div className="d-flex reportedComment">
        {reportedContentData && reportedContentData.user ? (
          <>
            <div className="me-2">
              <div className="author">
                <a
                  target="_blank"
                  rel="noreferrer"
                  className="small-dp user-color-green"
                  href={`${MAIN_WEBSITE_URL}/${reportedContentData.user.username}`}
                  tabindex="0"
                >
                  <img
                    src={
                      reportedContentData.user.avatarObject
                        ? reportedContentData.user.avatarObject.thumb.location
                        : null
                    }
                    alt="Super Admin"
                    className="sidedLazyLoad img-circle avatar"
                    style={{
                      borderColor: reportedContentData.user.statusColor,
                    }}
                    onError={(e) => {
                      e.target.src = DEFAULT_USER_IMG_URL;
                    }}
                  />
                  {reportedContentData.user.roles.length > 0 &&
                  reportedContentData.user.roles.find(
                    (role) => role.name === "VERIFIED",
                  ) ? (
                    <FontAwesomeIcon
                      style={{ color: reportedContentData.user.checkmarkColor }}
                      icon={faCheckCircle}
                      className="procheckmark"
                    />
                  ) : null}
                </a>
              </div>
            </div>
            <div className="ms-1 w-100">
              <h6>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={`${MAIN_WEBSITE_URL}/${reportedContentData.user.username}`}
                  className="customAnchor d-inline"
                >
                  {reportedContentData.user.name}
                </a>{" "}
                posted this
                {this.props.reportableType === "App\\Comment" ? (
                  <a
                    rel="noreferrer"
                    target="_blank"
                    href={
                      reportedContentData.debate
                        ? `${MAIN_WEBSITE_URL}/${reportedContentData.debate.user.username}/${reportedContentData.debate.slug}?gotoArg=argument-177948&scrollto=comment-24092`
                        : ""
                    }
                    className="customAnchor d-inline"
                  >
                    {" "}
                    comment
                  </a>
                ) : (
                  <a
                    rel="noreferrer"
                    target="_blank"
                    href={
                      reportedContentData.debate
                        ? `${MAIN_WEBSITE_URL}/${reportedContentData.debate.user.username}/${reportedContentData.debate.slug}?gotoArg=argument-${reportedContentData.id}`
                        : ""
                    }
                    className="customAnchor d-inline"
                  >
                    {" "}
                    argument
                  </a>
                )}
                <small>
                  {moment(reportedContentData.createdAt).format(
                    "MM/DD/YY @ hh:mm A",
                  )}
                </small>
              </h6>
              <div className="report-text">
                {reportedContentData.text &&
                reportedContentData.text.length > 250 ? (
                  <div>
                    {this.state.readMore ? (
                      <>
                        {reportedContentData.text}
                        <span
                          onClick={(e) => {
                            this.setState({ readMore: false });
                          }}
                          className="customAnchor d-inline"
                        >
                          {" "}
                          read less
                        </span>
                      </>
                    ) : (
                      <>
                        {reportedContentData.text.substring(0, 250)} ...
                        <span
                          onClick={(e) => {
                            this.setState({ readMore: true });
                          }}
                          className="customAnchor d-inline"
                        >
                          read more
                        </span>
                      </>
                    )}
                  </div>
                ) : (
                  reportedContentData.text
                )}
              </div>
            </div>
          </>
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  networks: state.networks.networks,
  loggedInUser: state.login.user,
  selNetworkId: state.networks.selNetworkId,
});

const Extended = withTranslation()(ReportedComment);
Extended.static = ReportedComment.static;

export default connect(mapStateToProps, {})(Extended);
