import React from "react";
import {
  Row,
  Col,
  Button,
  Form,
  Tabs,
  Tab,
  Modal,
  DropdownButton,
  Dropdown,
} from "react-bootstrap";
import axios from "axios";
import {
  API_URL,
  DEFAULT_USER_IMG_URL,
  SIDES_COLORS,
} from "../../constants/Globals";
import { isValidHttpUrl } from "../../helpers/String";
import { NavLink } from "react-router-dom";
import DateRangePicker from "react-bootstrap-daterangepicker";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faLink,
  faTag,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import logo from "../../logo.png";
import { ClientJS } from "clientjs";
import { withTranslation } from "react-i18next";
const client = new ClientJS();
const fingerprint = client.getFingerprint();

var moment = require("moment-timezone");
var momentDurationFormatSetup = require("moment-duration-format");
momentDurationFormatSetup(moment);

var defaultStartDate = moment()["_d"];
var defaultEndDate = moment()["_d"];

export class StartDebate extends React.Component {
  constructor(props) {
    super(props);
    const query = new URLSearchParams(this.props.location.search);
    this.state = {
      removeSideLink: false,
      resetEndDate: false,
      networksList: null,
      categoriesList: null,
      authorsList: null,
      defaultDebateDuration: null,
      maxDebateDuration: null,
      networkDomain: null,
      validationErrors: {},
      loading: false,
      formData: {
        id: query ? query.get("deb-id") : "",
        clientId: "",
        userId: this.props.loggedInUser.id,
        thesis: "",
        sides: [
          {
            text: "",
            isCorrect: false,
          },
          {
            text: "",
            isCorrect: false,
          },
        ],
        categoryId: "",
        background: "",
        backgroundImage: "",
        backgroundTempData: "",
        startedAt: defaultStartDate,
        endedAt: defaultEndDate,
        type: "create",
        relatedDebateId: query ? query.get("deb-id") : "",
        affiliateLink: "",
        affiliateButtonText: "",
        noEndDate: 0,
        backgroundTemp: "",
        isAiSuggested: query && !!query.get('source'),
        sourcePageUrl: query && !!query.get('source') ? query.get("source") : "",
        sourcePageTitle: "",
      },
      showAdditionaInfoModal: false,
      toggleSideLinkField: {},
      toggleSideTagField: {},
      linkTempVar: null,
      tagTempVar: "",
      SPC_keyword: "",
      SPC_data: "", // change this array if hitting API parallelly
      showSPCSection: false,
      SPCLoading: "",
      brandAccount: null,
      isQuiz: query && query.get("type") === "quiz",
      categoryFilter: "",
    };
  }

  componentDidMount() {
    this.networksList();
    if (this.state.formData.id !== "") {
      this.fetchCurreDebate();
    }
    if (this.props.selNetworkId) {
      this.fetchBrandAccountDetail();
    }
  }

  fetchCurreDebate = async () => {
    if (this.state.formData.id === null) {
      return false;
    }
    await this.setState({
      loading: true,
    });

    await axios
      .get(
        API_URL +
          "/debate/" +
          this.state.formData.id +
          "?deviceId=" +
          fingerprint,
      )
      .then((res) => {
        var debateData = res.data.data;

        this.setState({ currentDebateData: debateData });
        var formData = { ...this.state.formData };
        formData["thesis"] =
          debateData.thesis !== undefined ? debateData.thesis : "";
        formData["sides"] =
          debateData.sides !== undefined ? debateData.sides : "";
        if (debateData.sides.length > 2) {
          this.setState({ removeSideLink: true });
        }

        formData["categoryId"] =
          debateData.categories !== undefined &&
          debateData.categories.length > 0
            ? debateData.categories[0]["categoryDebate"]["categoryId"]
            : "";
        formData["background"] =
          debateData.background !== undefined ? debateData.background : "";
        //formData['backgroundImage'] = debateData.backgroundImage ? debateData.backgroundImage : '';
        formData["backgroundImage"] =
          debateData.backgroundImageObject !== null
            ? debateData.backgroundImageObject["small"]["location"]
            : "";

        //formData['startedAt'] = debateData.startedAt !== undefined ? debateData.startedAt : '';
        //formData['endedAt'] = debateData.endedAt !== undefined ? debateData.endedAt : '';

        this.setState({ formData });
        this.setState({
          loading: false,
        });

        if (this.selectedInputElement !== undefined) {
          this.selectedInputElement.click();
        }
      })
      .catch((err) => {
        alert("Failed. Please try again!");
        console.log(err);
      });
  };

  networksList = async (e) => {
    await axios
      .get(
        API_URL +
          "/admin/client/getAllClients?perPage=9999999&pageNumber=1&order=asc&orderBy=title",
      )
      .then((res) => {
        this.setState({
          networksList: res.data.data,
        });
        if (
          res.data.data.length > 0 &&
          this.selectedInputElement !== undefined
        ) {
          this.selectedInputElement.click();
        }
        this.setState({ loading: false });
      })
      .catch((err) => {
        console.log(err.response.data.message);
      });
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.selNetworkId !== this.props.selNetworkId) {
      this.setState({ loading: true });
      this.networksList();
      if (this.state.formData.id !== "") {
        this.fetchCurreDebate();
      }

      this.fetchBrandAccountDetail();
    }
  }

  fetchCats = async (
    e,
    defaultDebateDuration,
    maxDebateDuration,
    networkDomain,
  ) => {
    this.fetchAuthors(e);

    var formData = { ...this.state.formData };
    formData[e.target.name] = e.target.value;
    formData["endedAt"] = moment().add(parseInt(defaultDebateDuration), "days");

    await this.setState({
      defaultDebateDuration: defaultDebateDuration,
      maxDebateDuration: maxDebateDuration,
      networkDomain: networkDomain,
    });
    await this.setState({ resetEndDate: true });
    await this.setState({ resetEndDate: false });

    await this.setState({ formData });
    this.formValidation(e);

    await axios
      .get(API_URL + "/categories?type=START_DEBATE&clientId=" + e.target.value)
      .then((res) => {
        this.setState({
          categoriesList: res.data.data.sort((a, b) =>
            a.text > b.text ? 1 : -1,
          ),
          loading: false,
        });
        /*if(this.state.formData.relatedDebateId !== ''){
				var formData = {...this.state.formData}
	        	formData['categoryId'] = res.data.data[0].id;
	        	this.setState({formData});
	        }*/
      })
      .catch((err) => {
        console.log(err.response.data.message);
      });
  };

  fetchAuthors = async (e) => {
    if (e === undefined) {
      return false;
    }

    await axios
      .get(
        API_URL +
          "/user/list?type=DEBATE_AUTHORS&clientId=" +
          parseInt(e.target.value),
      )
      .then((res) => {
        this.setState({
          authorsList: res.data.data,
        });

        var authorsList = res.data.data;

        if (
          authorsList &&
          authorsList.length &&
          authorsList[0].roles.find((role) => role.name === "BRANDACCOUNT")
        ) {
          var formData = { ...this.state.formData };
          formData["userId"] = authorsList[0].id;
          this.setState({ formData });
        }
      })
      .catch((err) => {
        console.log(err.response.data.message);
      });
  };

  addNewSideHandle = async (e) => {
    var formData = { ...this.state.formData };
    var sidesArray = formData.sides;
    var sidesObj = {
      text: "",
      isCorrect: false,
    };
    sidesArray.push(sidesObj);
    formData.sides = sidesArray;
    await this.setState({ formData });
    if (this.state.formData.sides.length > 2) {
      this.setState({
        removeSideLink: true,
      });
    }
  };

  removeSideLinkHandle = async (currEl) => {
    var formData = { ...this.state.formData };
    var sidesArr = formData.sides;
    sidesArr.splice(currEl, 1);
    formData.sides = sidesArr;
    await this.setState({ formData });

    if (this.state.formData.sides.length < 3) {
      this.setState({
        removeSideLink: false,
      });
    }
  };

  /*formValidation = async (field) => { console.log('*********************'); console.log(field);
	 	var validationErrors = {...this.state.validationErrors}
	 	if(field.target.dataset.required !== undefined && field.target.dataset.required === 'true' && field.target.value === ''){
 			validationErrors[field.target.name] = true;
 			await this.setState({validationErrors : validationErrors})
 		} else if(validationErrors[field.target.name] !== undefined) {
 			delete validationErrors[field.target.name]; 
 			await this.setState({validationErrors : validationErrors})
 		}
	 	console.log(Object.keys(this.state.validationErrors).length);
	 }*/

  formValidation = async (e = "", action = "") => {
    var validationErrors = { ...this.state.validationErrors };
    if (e.target.name === "clientId" || action === "onSubmit") {
      if (this.state.formData.clientId === "") {
        validationErrors["clientId"] = this.props.t("This field is required.");
        await this.setState({ validationErrors: validationErrors });
      } else {
        delete validationErrors["clientId"];
        await this.setState({ validationErrors: validationErrors });
      }
    }
    if (e.target.name === "thesis" || action === "onSubmit") {
      if (this.state.formData.thesis === "") {
        validationErrors["thesis"] = this.props.t(
          "The Statement or Question field is required.",
        );
        await this.setState({ validationErrors: validationErrors });
      } else if (this.state.formData.thesis.length > 140) {
        validationErrors["thesis"] = this.props.t(
          "Please do not enter more than 140 characters.",
        );
        await this.setState({ validationErrors: validationErrors });
      } else {
        delete validationErrors["thesis"];
        await this.setState({ validationErrors: validationErrors });
      }
    }
    if (e.target.name === "side-1" || action === "onSubmit") {
      if (this.state.formData.sides[0].text === "") {
        validationErrors["side-1"] = this.props.t(
          "The side field is required.",
        );
        await this.setState({ validationErrors: validationErrors });
      } else if (this.state.formData.sides[0].text.length > 140) {
        validationErrors["side-1"] = this.props.t(
          "The maximum number of characters is 140.",
        );
        await this.setState({ validationErrors: validationErrors });
      } else {
        delete validationErrors["side-1"];
        await this.setState({ validationErrors: validationErrors });
      }
    }
    if (e.target.name === "side-2" || action === "onSubmit") {
      if (this.state.formData.sides[1].text === "") {
        validationErrors["side-2"] = this.props.t(
          "The side field is required.",
        );
        await this.setState({ validationErrors: validationErrors });
      } else if (this.state.formData.sides[1].text.length > 140) {
        validationErrors["side-2"] = this.props.t(
          "The maximum number of characters is 140.",
        );
        await this.setState({ validationErrors: validationErrors });
      } else {
        delete validationErrors["side-2"];
        await this.setState({ validationErrors: validationErrors });
      }
    }

    if (e.target.name === "sourcePageUrl" || action === "onSubmit") {
      if (this.state.formData.sourcePageUrl.trim() !== "" && !isValidHttpUrl(this.state.formData.sourcePageUrl)) {
        validationErrors["sourcePageUrl"] = "Please enter valid page URL";
        await this.setState({ validationErrors: validationErrors });
      } else {
        delete validationErrors["sourcePageUrl"];
        await this.setState({ validationErrors: validationErrors });
      }
    }

    // if(e.target.name === 'affiliateLink' || action === 'onSubmit'){
    // 	if((this.state.formData.affiliateLink !== null && this.state.formData.affiliateLink !== "") && !(/^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-zA-Z0-9]+([-\.]{1}[a-zA-Z0-9]+)*\.[a-zA-Z]{2,5}(:[0-9]{1,5})?(\/.*)?$/i.test(this.state.formData.affiliateLink))){
    //  		validationErrors['affiliateLink'] = this.props.t('The affiliate link is invalid.');
    // 		await this.setState({validationErrors : validationErrors})
    //  	} else {
    //  		delete validationErrors['affiliateLink'];
    // 		await this.setState({validationErrors : validationErrors})
    //  	}
    // }

    if (
      Object.keys(this.state.validationErrors).length === 0 &&
      action === "onSubmit"
    ) {
      await this.setState({
        loading: true,
      });
      this.uploadDebateImages();
    }
  };

  changeHandler = async (e) => {
    var formData = { ...this.state.formData };
    formData[e.target.name] = e.target.value;
    await this.setState({ formData });
    this.formValidation(e);
  };
  sidesChangeHandler = async (e) => {
    var sideIndex = e.target.dataset.index;
    var formData = { ...this.state.formData };
    var sidesArray = formData.sides;
    sidesArray[sideIndex - 1].text = e.target.value;
    formData.sides = sidesArray;
    await this.setState({ formData });
    this.formValidation(e);
  };

  datePickerCallback = async (e, name) => {
    var formData = { ...this.state.formData };
    formData[name] = e._d;
    await this.setState({ formData });
    if (
      moment(this.state.formData.startedAt).isAfter(this.state.formData.endedAt)
    ) {
      await this.setState({ resetEndDate: true });
      await this.setState({ resetEndDate: false });
    } else {
      await this.setState({ resetEndDate: false });
    }
  };

  fileChangeHandler = async (e) => {
    if (e.target.type === "file" && e.target.files[0]) {
      // const dataAccept = e.target.getAttribute('data-accept') ? e.target.getAttribute('data-accept') : 'jpg|jpeg|gif|png';
      // const dataAcceptMsg = e.target.getAttribute('data-accept_msg') ? e.target.getAttribute('data-accept_msg') : this.props.t('Image is invalid. Please upload only PNG, JPG or GIF image.');
      // var regex = new RegExp("(.*?)\.("+dataAccept+")$");
      // 	if(!regex.test(e.target.files[0].type)){
      // 		alert(dataAcceptMsg);
      // 		return false;
      // 	}
      if (
        e.target.name === "backgroundImage" &&
        e.target.files[0].size > 1024 * 10 * 1024
      ) {
        var alertMessage = this.props.t(
          "The image size should not be greater than 10mb",
        );
        alert(alertMessage);
        return false;
      }
      var file = e.target.files[0];
      var thisEl = e;
      var reader = new FileReader();
      reader.onloadend = function (e) {
        var formData = { ...this.state.formData };
        var bgImageObj = {
          type: thisEl.target.dataset.imgtype,
          backgroundImage: thisEl.target.files[0],
        };

        formData.backgroundTempData = bgImageObj;
        formData[thisEl.target.name] = e.target.result;
        this.setState({ formData });
      }.bind(this);
      reader.readAsDataURL(file);
    }
  };

  fileRemoveHandler = async (name, index) => {
    var formData = { ...this.state.formData };
    var bgImageObj = {
      type: "",
      backgroundImage: "",
    };

    formData.backgroundTempData = bgImageObj;
    formData[name] = "";
    await this.setState({ formData });
  };

  uploadDebateImages = async (e) => {
    let backgroundTempData = this.state.formData.backgroundTempData;
    var failure = false;

    if (backgroundTempData !== "") {
      var image_type = "DEBATES";
      const formData = new FormData();
      formData.append(
        "backgroundImage",
        backgroundTempData.backgroundImage,
        backgroundTempData.backgroundImage.name,
      );

      await axios
        .post(
          API_URL +
            "/debate/imageUpload?type=" +
            image_type +
            "&clientId=" +
            this.state.formData.clientId,
          formData,
        )
        .then((res) => {
          var formData = { ...this.state.formData };
          formData.backgroundImage = res.data.data;
          this.setState({ formData });

          console.log("Image uplaoded successfully!");
        })
        .catch((err) => {
          alert("Image upload for " + image_type + " Failed!");
          failure = true;
        });
    }

    if (failure === false) {
      //alert('ready to submit');

      if (
        this.state.formData.relatedDebateId !== "" &&
        this.state.currentDebateData &&
        this.state.currentDebateData.backgroundImageObject !== null
      ) {
        this.imageCopy();
      } else {
        this.formDataHandle();
      }
    }
  };

  imageCopy = async (e) => {
    var failure = false;
    const body = {
      backgroundImageObject: this.state.currentDebateData.backgroundImageObject,
    };
    await axios
      .post(
        API_URL +
          "/debate/imageCopy?type=DEBATES&clientId=" +
          this.state.formData.clientId,
        body,
      )
      .then((res) => {
        var formData = { ...this.state.formData };
        formData.backgroundImage = res.data.data;
        this.setState({ formData });
      })
      .catch((err) => {
        failure = true;
        console.log("err", err);
      });

    if (failure === false) {
      this.formDataHandle();
    }
  };

  formDataHandle = async (e) => {
    const { formData, isQuiz } = this.state;
    const { selNetworkId } = this.props;

    await axios
      .post(API_URL + "/debate/create", formData)
      .then(async (res) => {
        var tabQueryString =
          this.state.formData.relatedDebateId !== ""
            ? "?action=embed-settings"
            : "";
        const debId = res.data.data.id;
        if (formData.type === "create") {
          this.messageNotification(
            "success",
            this.props.t(`${isQuiz ? "Quiz" : "Poll"} published successfully.`),
          );

          if (formData.isAiSuggested || formData.sourcePageUrl) {
            await axios.put(
              `${API_URL}/admin/debate/publishAndDeploy/${debId}?clientId=${selNetworkId}`,
            );
          }

          setTimeout(() => {
            window.location.replace(
              window.location.href.replace(
                "/debates/start-debate",
                `/debates/debate/${debId}${tabQueryString}`,
              ),
            );
          }, 1000);
        } else {
          setTimeout(() => {
            this.messageNotification("success", this.props.t("Draft saved!"));
            window.location.replace(
              window.location.href.replace(
                "/debates/start-debate",
                `/debates/draft/${debId}`,
              ),
            );
          }, 1000);
        }
      })
      .catch((err) => {
        alert(err.response.data.message);
        this.setState({ loading: false });
      });
  };

  submitDetateDataHandle = async (e) => {
    this.formValidation(e, "onSubmit");
    e.preventDefault();
  };

  messageNotification = async (type, message) => {
    this.setState({
      successMsg: message,
    });

    setTimeout(() => {
      this.setState({
        successMsg: "",
      });
    }, 5000);
  };

  saveDraft = async (e) => {
    var formData = { ...this.state.formData };
    formData["type"] = "draft";
    await this.setState({ formData });
    this.submitDetateDataHandle(e);
  };

  showAdditionaInfoModal = async (e) => {
    this.setState({ showAdditionaInfoModal: true });
  };
  hideAdditionaInfoModal = async (e) => {
    this.setState({ showAdditionaInfoModal: false });
    var formData = { ...this.state.formData };
    formData["backgroundTemp"] = "";
    await this.setState({ formData });
  };
  commitAdditionalInfo = async () => {
    this.setState({ showAdditionaInfoModal: false });
    var formData = { ...this.state.formData };
    formData["background"] = formData["backgroundTemp"];
    formData["backgroundTemp"] = "";
    await this.setState({ formData });
  };
  removeBackgroundText = async () => {
    var formData = { ...this.state.formData };
    formData["background"] = "";
    await this.setState({ formData });
  };
  removeCustomCTA = async () => {
    var formData = { ...this.state.formData };
    formData["affiliateLink"] = "";
    formData["affiliateButtonText"] = "";
    await this.setState({ formData, CTAClicked: false });
  };

  toggleSideElField = async (e = "", target = "", sideIndex = "") => {
    await this.setState({ linkTempVar: null, tagTempVar: "" });

    var toggleSideLinkField = { ...this.state.toggleSideLinkField };
    var toggleSideTagField = { ...this.state.toggleSideTagField };
    toggleSideLinkField = {
      [sideIndex]:
        target === "link" &&
        (toggleSideLinkField[sideIndex] === undefined ||
          toggleSideLinkField[sideIndex] === false)
          ? true
          : false,
    };
    toggleSideTagField = {
      [sideIndex]:
        target === "tag" &&
        (toggleSideTagField[sideIndex] === undefined ||
          toggleSideTagField[sideIndex] === false)
          ? true
          : false,
    };
    await this.setState({ toggleSideLinkField, toggleSideTagField });
  };

  handleSideElFieldChange = async (e, target) => {
    if (target === "link") {
      await this.setState({ linkTempVar: e.target.value });
    } else if (target === "tag") {
      await this.setState({ tagTempVar: e.target.value });
    } else {
    }
  };

  sidesLinkCommitHandler = async (e) => {
    var sideIndex = e.target.dataset.index;

    /*var validationErrors = {...this.state.validationErrors}
		if(this.state.linkTempVar !== "" && !(/^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-zA-Z0-9]+([\-\.]{1}[a-zA-Z0-9]+)*\.[a-zA-Z]{2,5}(:[0-9]{1,5})?(\/.*)?$/i.test(this.state.linkTempVar))){
			validationErrors['sideLink'+(sideIndex - 1)] = 'Please enter valid URL.'; 
			await this.setState({validationErrors : validationErrors})
			return false
		} else{
			delete validationErrors['sideLink'+(sideIndex - 1)]; 
	 			await this.setState({validationErrors : validationErrors})
		}*/

    // if(this.state.linkTempVar.trim() !== "" && !(/^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-zA-Z0-9]+([\-\.]{1}[a-zA-Z0-9]+)*\.[a-zA-Z]{2,5}(:[0-9]{1,5})?(\/.*)?$/i.test(this.state.linkTempVar.trim()))){
    // 	var alertMessage = this.props.t('Please enter valid URL.')
    // 	alert(alertMessage);
    // 	return false
    // }

    var formData = { ...this.state.formData };
    var sidesArray = formData.sides;
    sidesArray[sideIndex - 1] = {
      ...sidesArray[sideIndex - 1],
      link: this.state.linkTempVar.trim(),
    };
    formData.sides = sidesArray;
    await this.setState({ formData });
    this.toggleSideElField(e, "link");
    console.log(this.state);
  };

  sidesTagCommitHandler = async (e) => {
    var sideIndex = e.target.dataset.index;

    if (this.state.tagTempVar.trim() === "") {
      var alertMessage = this.props.t("This field is required.");
      alert(alertMessage);
      return false;
    }

    var formData = { ...this.state.formData };
    var sidesArray = formData.sides;
    if (sidesArray[sideIndex - 1].tags === undefined) {
      sidesArray[sideIndex - 1] = {
        ...sidesArray[sideIndex - 1],
        tags: [],
      };
    }

    var tagsObj = {
      text: this.state.tagTempVar.trim(),
    };
    sidesArray[sideIndex - 1].tags.push(tagsObj);

    formData.sides = sidesArray;
    await this.setState({ formData, tagTempVar: "" });
  };

  removeSideElField = async (
    e = "",
    target = "",
    sideIndex = "",
    tagIndex = "",
  ) => {
    var formData = { ...this.state.formData };
    console.log(sideIndex);
    console.log(formData);
    if (target === "link") {
      delete formData.sides[sideIndex].link;
      await await this.setState({ formData });
    }
    if (target === "tag") {
      delete formData.sides[sideIndex].tags.splice(tagIndex, 1);
      await await this.setState({ formData });
    }
    console.log(this.state);
  };

  triggerImageInputClick = async (e) => {
    if (this.hiddenImageInput !== undefined) {
      this.hiddenImageInput.click();
    }
  };

  handleCustomDropdownChange = async (e, authorId) => {
    e.preventDefault();
    var formData = { ...this.state.formData };
    formData[e.target.name] = authorId;
    await this.setState({ formData });
    this.formValidation(e);
  };

  generateSmartPolls = async (e) => {
    if (!isValidHttpUrl(this.state.SPC_keyword)) {
      alert("Please enter valid page URL");
    } else {
      this.setState({
        showSPCSection: true,
        SPCLoading: "Generating polls ... please keep this page open",
      });
      this.getSmartPolls();
    }
  };

  getSmartPolls = async () => {
    const { selNetworkId } = this.props;

    await axios
      .get(
        `${API_URL}/admin/debate/generateDebates?clientId=${selNetworkId}&url=${this.state.SPC_keyword}&count=4`,
      )
      .then((res) => {
        this.setState({
          SPC_data: res.data.data,
        });
      })
      .catch((err) => {
        console.log(err.response);
      });
    if (this.state.SPC_data.length === 4) {
      this.setState({ SPCLoading: "" });
    }
  };
  ResetGenerateSmartPolls = async (e) => {
    await this.setState({
      SPCLoading: "",
      SPC_data: "",
      showSPCSection: false,
      SPC_keyword: "",
      selectedPoll: "",
    });
  };

  selectPoll = async (pollIndex) => {
    this.setState({ selectedPoll: "poll-" + pollIndex });
    var formData = { ...this.state.formData };
    formData["thesis"] = this.state.SPC_data[pollIndex].thesis;
    formData.isAiSuggested = true
    formData.sourcePageUrl = this.state.SPC_keyword

    var sidesArray = [];
    for (var i = 0; i < this.state.SPC_data[pollIndex].sides.length; i++) {
      var sidesObj = {
        text: this.state.SPC_data[pollIndex].sides[i],
      };
      sidesArray.push(sidesObj);
    }

    if (this.state.SPC_data[pollIndex].sides.length > 2) {
      this.setState({ removeSideLink: true });
    } else {
      this.setState({ removeSideLink: false });
    }
    formData.sides = sidesArray;
    await this.setState({ formData });
  };

  fetchBrandAccountDetail = async (clientIdAttr) => {
    await this.setState({
      loading: true,
    });

    await axios
      .get(API_URL + "/admin/brandAccount/" + this.props.selNetworkId)
      .then((res) => {
        if (res.data.data) {
          this.setState({ brandAccount: res.data.data });
        } else {
          this.setState({ brandAccount: this.props.loggedInUser });
        }
      });
  };

  render() {
    const kTrans = this.props.t ? this.props.t : "";
    const { isQuiz, formData, categoryFilter } = this.state;

    if (
      this.state.networksList !== null &&
      this.state.networksList.length < 1
    ) {
      return (
        <section>
          <NavLink
            exact
            className="mb-3 customAnchor d-inline-block"
            to={`/${isQuiz ? "quizzes" : ""}`}
          >
            <i className="fa fa-angle-left"></i>{" "}
            {kTrans(`Your ${isQuiz ? "Quizzes" : "Polls"}`)}
          </NavLink>
          <h3>You do not have create permission.</h3>
        </section>
      );
    }

    const {
      userId,
      thesis,
      sides,
      categoryId,
      background,
      backgroundImage,
      startedAt,
      endedAt,
      affiliateLink,
      affiliateButtonText,
      noEndDate,
      backgroundTemp,
      sourcePageUrl
    } = formData;
    const {
      removeSideLink,
      networksList,
      authorsList,
      categoriesList,
      validationErrors,
      defaultDebateDuration,
      loading,
      toggleSideLinkField,
      toggleSideTagField,
      linkTempVar,
      tagTempVar,
      SPC_keyword,
      SPC_data,
      showSPCSection,
    } = this.state;
    var endedAtMinDate = moment(startedAt).add(5, "minutes");
    var endedAtDefDate = defaultDebateDuration
      ? moment(startedAt).add(parseInt(defaultDebateDuration), "days")
      : endedAtMinDate;
    var brandAccount = this.state.brandAccount;

    /* Difference in startedAt and endedAt to display in preview section */
    var finalDifference = null;
    if (startedAt !== null && endedAt !== null) {
      var currDate = moment();
      var endedAtDate = moment(endedAt);
      var differenceInDates = endedAtDate.diff(currDate, "minutes");
      var numdays = Math.floor(differenceInDates / 1440);
      var numhours = Math.floor((differenceInDates % 1440) / 60);
      var numminutes = Math.floor((differenceInDates % 1440) % 60);
      finalDifference =
        numdays > 0
          ? numdays + " day(s) "
          : numhours > 0
            ? numhours + " hour(s) "
            : numminutes > 0
              ? numminutes + " minute(s)"
              : "< 1 minute";
    }

    return (
      <section>
        {this.state.successMsg ? (
          <div className="sucessMsg">{this.state.successMsg}</div>
        ) : null}
        {loading === false ? (
          <NavLink
            exact
            className="mb-3 customAnchor d-inline-block"
            to={`/${isQuiz ? "quizzes" : ""}`}
          >
            <i className="fa fa-angle-left"></i>
            &nbsp;{kTrans(`Your ${isQuiz ? "Quizzes" : "Polls"}`)}
          </NavLink>
        ) : (
          <div className="loadingBarWrap">
            <div className="loadingBar"></div>
          </div>
        )}
        <h3 className="mb-3">
          {kTrans(`CREATE NEW ${isQuiz ? "QUIZ" : "POLL"}`)}
        </h3>
        <Tabs
          defaultActiveKey="poll-detail"
          id="uncontrolled-tab-example"
          className="mt-4 mb-3 CreateDebateTabs"
          onSelect={this.handleSelect}
        >
          <Tab
            eventKey="poll-detail"
            title={kTrans(`${isQuiz ? "Quiz" : "Poll"} Details`)}
          >
            <div
              className={
                loading === true
                  ? "loading justify-content-center"
                  : "justify-content-center"
              }
            >
              <Form
                className="startDebate common-form row"
                onSubmit={this.submitDetateDataHandle}
              >
                <Col md={7} className="pe-3">
                  <Form.Group
                    controlId="clientId"
                    className="pt-0 pb-2 hiddenElement"
                  >
                    <Form.Label>Network</Form.Label>
                    <div className="message joinedNetworksWrap">
                      {networksList && networksList.length
                        ? networksList.map((el, i) =>
                            el.id === parseInt(this.props.selNetworkId) ? (
                              <label key={i} className="sub-label">
                                <input
                                  ref={(input) =>
                                    (this.selectedInputElement = input)
                                  }
                                  data-required="true"
                                  onChange={(e) => {
                                    this.fetchCats(
                                      e,
                                      el.extra
                                        ? el.extra.defaultDebateDuration
                                        : null,
                                      el.extra
                                        ? el.extra.maxDebateDuration
                                        : null,
                                      el.siteDomain,
                                    );
                                  }}
                                  type="radio"
                                  name="clientId"
                                  id="clientId"
                                  value={el.id}
                                />
                                <span
                                  key={i}
                                  className=" joinedNetworks"
                                  data-clientid={`client-${el.id}`}
                                >
                                  <img
                                    src={el.header.favicon}
                                    alt={el.name}
                                    onError={(e) => {
                                      e.target.src = DEFAULT_USER_IMG_URL;
                                    }}
                                  />
                                  {el.header.title}
                                </span>
                              </label>
                            ) : null,
                          )
                        : null}
                      {networksList && networksList.length
                        ? networksList.map((el, i) =>
                            el.id !== parseInt(this.props.selNetworkId) ? (
                              <label key={i} className="sub-label">
                                <input
                                  data-required="true"
                                  onChange={(e) => {
                                    this.fetchCats(
                                      e,
                                      el.extra
                                        ? el.extra.defaultDebateDuration
                                        : null,
                                      el.extra
                                        ? el.extra.maxDebateDuration
                                        : null,
                                      el.siteDomain,
                                    );
                                  }}
                                  type="radio"
                                  name="clientId"
                                  id="clientId"
                                  value={el.id}
                                />
                                <span
                                  key={i}
                                  className=" joinedNetworks"
                                  data-clientid={`client-${el.id}`}
                                >
                                  <img
                                    src={el.header.favicon}
                                    alt={el.name}
                                    onError={(e) => {
                                      e.target.src = DEFAULT_USER_IMG_URL;
                                    }}
                                  />
                                  {el.header.title}
                                </span>
                              </label>
                            ) : null,
                          )
                        : null}
                    </div>
                    {validationErrors &&
                    validationErrors.clientId !== undefined ? (
                      <span className="validation-error">
                        {validationErrors.clientId}
                      </span>
                    ) : null}
                  </Form.Group>

                  {isQuiz ? null : (
                    <div className="smartPollWrapper">
                      <Form.Label>AI Poll Generator</Form.Label>
                      <p className="lightColorSpan">
                        Paste a url below and our system will generate 4 polls
                        to choose from.
                      </p>
                      <Form.Group
                        controlId="thesis"
                        className="inline-content pt-0 mb-2"
                      >
                        <Form.Control
                          onChange={(e) =>
                            this.setState({ SPC_keyword: e.target.value })
                          }
                          data-required="true"
                          type="text"
                          name="SPC_keyword"
                          value={SPC_keyword}
                          placeholder="Type or copy paste page url "
                        />
                        {SPC_data && SPC_data.length ? (
                          <Button
                            onClick={this.ResetGenerateSmartPolls}
                            className="customBtn ms-2 p-2 ps-3 pe-3"
                            variant="outline-primary"
                          >
                            Reset
                          </Button>
                        ) : (
                          <Button
                            disabled={
                              this.state.SPCLoading !== "" ? "disabled" : ""
                            }
                            onClick={this.generateSmartPolls}
                            className="customBtn ms-2 p-2 ps-3 pe-3"
                            variant="outline-primary"
                          >
                            Generate Polls
                          </Button>
                        )}
                      </Form.Group>

                      {showSPCSection ? (
                        <div className="smartPollSection p-0">
                          {this.state.SPCLoading ? (
                            <div className="mb-2">{this.state.SPCLoading}</div>
                          ) : null}

                          {SPC_data && SPC_data.length ? (
                            <div className="smartPollContent pb-3 position-relative">
                              {SPC_data.map((el, i) => (
                                <div key={i} className="debatePreviewInner">
                                  <div className="debatePreviewHeader">
                                    <div className="d-flex">
                                      {brandAccount ? (
                                        <div className="author">
                                          <a
                                            target="_blank"
                                            rel="noreferrer"
                                            className="small-dp user-color-green"
                                            href={"/" + brandAccount.username}
                                          >
                                            <img
                                              style={{
                                                borderColor:
                                                  brandAccount.statusColor,
                                              }}
                                              data-src=""
                                              src={
                                                brandAccount.avatarObject
                                                  ? brandAccount.avatarObject
                                                      .small.location
                                                  : DEFAULT_USER_IMG_URL
                                              }
                                              alt={brandAccount.name}
                                              className="sidedLazyLoad img-circle avatar"
                                              onError={(e) => {
                                                e.target.src =
                                                  DEFAULT_USER_IMG_URL;
                                              }}
                                            />
                                            {brandAccount.roles ? (
                                              <>
                                                {brandAccount.roles.length >
                                                  0 &&
                                                brandAccount.roles.find(
                                                  (role) =>
                                                    role.name === "VERIFIED",
                                                ) ? (
                                                  <FontAwesomeIcon
                                                    style={{
                                                      color:
                                                        brandAccount.checkmarkColor,
                                                    }}
                                                    icon={faCheckCircle}
                                                    className="procheckmark"
                                                  />
                                                ) : null}
                                              </>
                                            ) : (
                                              <FontAwesomeIcon
                                                style={{
                                                  color:
                                                    brandAccount.checkmarkColor,
                                                }}
                                                icon={faCheckCircle}
                                                className="procheckmark"
                                              />
                                            )}
                                          </a>
                                          <div className="copy-wrap">
                                            <a
                                              target="_blank"
                                              rel="noreferrer"
                                              className="authorname"
                                              href={"/" + brandAccount.username}
                                            >
                                              <h6>{brandAccount.name}</h6>
                                            </a>
                                            <a
                                              rel="noreferrer"
                                              target="_blank"
                                              href={"/" + brandAccount.username}
                                              className="authorhandle"
                                            >
                                              <span>
                                                {brandAccount.username}
                                              </span>
                                            </a>
                                          </div>
                                        </div>
                                      ) : null}

                                      {this.state.selectedPoll ===
                                      "poll-" + i ? (
                                        <Button
                                          variant="outline-primary"
                                          className="selectPollBtn selected disabled"
                                        >
                                          Selected
                                        </Button>
                                      ) : (
                                        <Button
                                          onClick={(e) => this.selectPoll(i)}
                                          variant="primary"
                                          className="selectPollBtn"
                                        >
                                          Select Poll
                                        </Button>
                                      )}
                                    </div>
                                  </div>

                                  <div className="debatePreviewTitle mt-3">
                                    <h6>{el.thesis}</h6>
                                  </div>

                                  <div className="debatePreviewSides mt-2">
                                    {el.sides && el.sides.length ? (
                                      el.sides.map((sidesEl, i) => (
                                        <div key={i}>
                                          <label>{sidesEl}</label>
                                        </div>
                                      ))
                                    ) : (
                                      <span className="validation-error">
                                        No side generated!
                                      </span>
                                    )}
                                  </div>
                                </div>
                              ))}
                            </div>
                          ) : null}

                          {this.state.SPCLoading ? (
                            <div className="cssload-container mt-4">
                              <div className="cssload-loading">
                                <i></i>
                                <i></i>
                                <i></i>
                                <i></i>
                              </div>
                            </div>
                          ) : null}
                        </div>
                      ) : null}
                    </div>
                  )}

                  <Form.Group controlId="thesis" className="pt-0">
                    <Form.Label>
                      {kTrans(
                        isQuiz ? "Quiz Question" : "Poll Question or Statement",
                      )}
                    </Form.Label>
                    <Form.Control
                      data-required="true"
                      type="text"
                      onChange={this.changeHandler}
                      name="thesis"
                      value={thesis}
                      placeholder={kTrans("Type question or statement here...")}
                      tabIndex={1}
                    />
                    {validationErrors &&
                    validationErrors.thesis !== undefined ? (
                      <span className="validation-error">
                        {validationErrors.thesis}
                      </span>
                    ) : null}
                  </Form.Group>

                  <Form.Group className="pt-0">
                    <Form.Label>
                      {kTrans(
                        isQuiz
                          ? "Type your answers and select the correct option(s) using the checkbox(s) to the left"
                          : "Your Sides",
                      )}
                    </Form.Label>
                    {sides && sides.length
                      ? sides.map((el, i) => (
                          <div className="side" key={i}>
                            <div className="message">
                              <div className="row-1 d-flex align-items-center">
                                <div
                                  className={`position-relative w-100 ${isQuiz ? "quiz-side" : ""}`}
                                >
                                  {isQuiz ? (
                                    <input
                                      type="checkbox"
                                      checked={el.isCorrect}
                                      onChange={({ target }) => {
                                        formData.sides[i].isCorrect =
                                          target.checked;
                                        this.setState({ formData });
                                      }}
                                    />
                                  ) : null}
                                  <Form.Control
                                    style={{
                                      borderColor:
                                        SIDES_COLORS[`side-${i + 1}`],
                                    }}
                                    data-required={i < 2 ? "true" : "false"}
                                    data-index={i + 1}
                                    type="text"
                                    onChange={(e) => this.sidesChangeHandler(e)}
                                    placeholder={kTrans("Type your side here")}
                                    name={`side-${i + 1}`}
                                    value={el.text}
                                    tabIndex={2}
                                    className={
                                      removeSideLink === true
                                        ? "removeBtnOn"
                                        : null
                                    }
                                  />
                                  <div className="side-inline-items d-flex align-items-center">
                                    <span className="lightColorSpan">{`${kTrans("Side")} ${i + 1}`}</span>
                                    {removeSideLink === true ? (
                                      <Button
                                        size="sm"
                                        variant="secondary-light"
                                        onClick={(e) => {
                                          this.removeSideLinkHandle(i);
                                        }}
                                        className="delete-button ms-2"
                                      >
                                        Remove
                                      </Button>
                                    ) : null}
                                  </div>
                                </div>
                                <Button
                                  disabled={el.link ? true : false}
                                  onClick={(e) =>
                                    this.toggleSideElField(e, "link", i)
                                  }
                                  size="sm"
                                  variant="secondary-light"
                                  className="side-link ms-2"
                                >
                                  <FontAwesomeIcon
                                    icon={faLink}
                                    className="procheckmark"
                                  />
                                </Button>
                                <Button
                                  onClick={(e) =>
                                    this.toggleSideElField(e, "tag", i)
                                  }
                                  size="sm"
                                  variant="secondary-light"
                                  className="side-tag ms-2"
                                >
                                  <FontAwesomeIcon
                                    icon={faTag}
                                    className="procheckmark flip"
                                  />
                                  +
                                </Button>
                              </div>
                              {isQuiz && el.isCorrect ? (
                                <div className="answer-label">
                                  Correct answer
                                </div>
                              ) : null}
                              {el.link || el.tags ? (
                                <div className="row-2 mt-2">
                                  {el.link ? (
                                    <span className="sideLink">
                                      <FontAwesomeIcon
                                        icon={faLink}
                                        className="procheckmark"
                                      />
                                      <a
                                        className="d-inline"
                                        rel="noreferrer"
                                        href={
                                          el.link.includes("http")
                                            ? el.link
                                            : "//" + el.link
                                        }
                                        target="_blank"
                                      >
                                        {el.link}
                                      </a>
                                      <FontAwesomeIcon
                                        onClick={(e) =>
                                          this.removeSideElField(e, "link", i)
                                        }
                                        role="button"
                                        icon={faTimesCircle}
                                        className="procheckmark flip"
                                      />
                                    </span>
                                  ) : (
                                    ""
                                  )}
                                  {el.tags && el.tags.length
                                    ? el.tags.map((elt, tagIndex) => (
                                        <span
                                          key={tagIndex}
                                          className="sideTags"
                                        >
                                          <FontAwesomeIcon
                                            icon={faTag}
                                            className="procheckmark flip"
                                          />
                                          {elt.text}
                                          <FontAwesomeIcon
                                            onClick={(e) =>
                                              this.removeSideElField(
                                                e,
                                                "tag",
                                                i,
                                                tagIndex,
                                              )
                                            }
                                            role="button"
                                            icon={faTimesCircle}
                                            className="procheckmark flip"
                                          />
                                        </span>
                                      ))
                                    : null}
                                </div>
                              ) : null}
                              {toggleSideLinkField[i] ||
                              toggleSideTagField[i] ? (
                                <div className="row-3 mt-2" test={i}>
                                  {toggleSideLinkField[i] ? (
                                    <div className="add-link-tag-wrapper position-relative">
                                      <Form.Control
                                        value={linkTempVar ? linkTempVar : ""}
                                        onChange={(e) =>
                                          this.handleSideElFieldChange(
                                            e,
                                            "link",
                                          )
                                        }
                                        type="text"
                                        name=""
                                      />
                                      <div className="sideElementsButtons">
                                        <Button
                                          onClick={(e) =>
                                            this.toggleSideElField(e, "link", i)
                                          }
                                          className="me-1"
                                          size="sm"
                                          variant="secondary-light"
                                        >
                                          {kTrans("Cancel")}
                                        </Button>
                                        <Button
                                          data-index={i + 1}
                                          onClick={this.sidesLinkCommitHandler}
                                          className=""
                                          size="sm"
                                          variant="primary"
                                        >
                                          {kTrans("Add")}
                                        </Button>
                                      </div>
                                      {linkTempVar ? null : (
                                        <FontAwesomeIcon
                                          icon={faLink}
                                          className="procheckmark"
                                        />
                                      )}
                                    </div>
                                  ) : null}

                                  {toggleSideTagField[i] ? (
                                    <div className="add-link-tag-wrapper position-relative">
                                      <Form.Control
                                        value={tagTempVar}
                                        onChange={(e) =>
                                          this.handleSideElFieldChange(e, "tag")
                                        }
                                        type="text"
                                        name=""
                                      />
                                      <div className="sideElementsButtons">
                                        <Button
                                          onClick={(e) =>
                                            this.toggleSideElField(e, "tag", i)
                                          }
                                          className="me-1"
                                          size="sm"
                                          variant="secondary-light"
                                        >
                                          {kTrans("Cancel")}
                                        </Button>
                                        <Button
                                          data-index={i + 1}
                                          onClick={this.sidesTagCommitHandler}
                                          className=""
                                          size="sm"
                                          variant="primary"
                                        >
                                          {kTrans("Add")}
                                        </Button>
                                      </div>
                                      {tagTempVar ? null : (
                                        <FontAwesomeIcon
                                          icon={faTag}
                                          className="flip procheckmark"
                                        />
                                      )}
                                    </div>
                                  ) : null}
                                </div>
                              ) : null}
                            </div>
                            {validationErrors &&
                            validationErrors[`side-${i + 1}`] !== undefined ? (
                              <span className="validation-error">
                                {validationErrors[`side-${i + 1}`]}
                              </span>
                            ) : null}
                          </div>
                        ))
                      : null}
                    <Button
                      className="mt-1 customBtn p-2 ps-4 pe-4"
                      variant="outline-primary"
                      onClick={this.addNewSideHandle}
                    >
                      + {kTrans("Add Side")}
                    </Button>
                  </Form.Group>

                  <Form.Group controlId="sourcePageUrl" className="mt-md-4">
                    <Form.Label>Source Page</Form.Label>
                    <Form.Control
                      data-required="true"
                      type="text"
                      onChange={this.changeHandler}
                      name="sourcePageUrl"
                      value={sourcePageUrl}
                      placeholder="Enter the url of the page this poll matches"
                      tabIndex={1}
                    />
                    {validationErrors &&
                    validationErrors.sourcePageUrl !== undefined ? (
                      <span className="validation-error">
                        {validationErrors.sourcePageUrl}
                      </span>
                    ) : null}
                  </Form.Group>

                  <Row className="mt-md-4">
                    <Col md={4} className="">
                      <Form.Group controlId="">
                        <Form.Label>
                          {kTrans(`Include ${isQuiz ? "Quiz" : "Poll"} Image`)}{" "}
                          <span className="lightColorSpan">
                            ({kTrans("Optional")})
                          </span>
                        </Form.Label>
                        <span className="lightColorSpan d-block mb-2 small">
                          {kTrans("Recommended 270 x 134 px")}
                        </span>
                        <div className="img-field-wrap">
                          {backgroundImage !== "" ? (
                            <div className="field-backgroundImage">
                              <img
                                alt="backgroundImage"
                                src={backgroundImage}
                              />
                            </div>
                          ) : (
                            <Button
                              onClick={this.triggerImageInputClick}
                              className="position-absolute customBtn p-2 ps-4 pe-4"
                              variant="outline-primary"
                            >
                              {kTrans("Add Image")}
                            </Button>
                          )}
                          <Form.Control
                            ref={(input) => (this.hiddenImageInput = input)}
                            className="d-none h-50"
                            data-index="0"
                            data-imgtype="DEBATES"
                            type="file"
                            name="backgroundImage"
                            onChange={this.fileChangeHandler}
                            data-accept="jpg|jpeg|png"
                            data-accept_msg={kTrans(
                              "Image is invalid. Please upload only PNG or JPG image.",
                            )}
                          />
                        </div>
                        {backgroundImage !== "" ? (
                          <div className="d-flex mt-2">
                            <Button
                              onClick={this.triggerImageInputClick}
                              className="w-50 me-1"
                              size="sm"
                              variant="outline-primary"
                            >
                              ({kTrans("Change")})
                            </Button>
                            <Button
                              onClick={() =>
                                this.fileRemoveHandler("backgroundImage", "0")
                              }
                              className="w-50"
                              size="sm"
                              variant="outline-primary"
                            >
                              {kTrans("Remove")}
                            </Button>
                          </div>
                        ) : null}
                      </Form.Group>
                    </Col>
                    <Col md={4}>
                      <Form.Group>
                        <Form.Label>
                          {kTrans("Additional Info")}{" "}
                          <span className="lightColorSpan">
                            ({kTrans("Optional")})
                          </span>
                        </Form.Label>
                        <span className="lightColorSpan d-block mb-2 small">
                          Add more info about your {isQuiz ? "quiz" : "poll"}.
                        </span>
                        {background ? (
                          <>
                            <textarea
                              rows="4"
                              className="form-control"
                              disabled
                              value={background}
                            ></textarea>
                            <div className="d-flex mt-2">
                              <Button
                                onClick={this.showAdditionaInfoModal}
                                className="w-50 me-1"
                                size="sm"
                                variant="outline-primary"
                              >
                                {kTrans("Change")}
                              </Button>
                              <Button
                                onClick={this.removeBackgroundText}
                                className="w-50"
                                size="sm"
                                variant="outline-primary"
                              >
                                {kTrans("Remove")}
                              </Button>
                            </div>
                          </>
                        ) : (
                          <Button
                            onClick={this.showAdditionaInfoModal}
                            className="customBtn p-2 ps-4 pe-4"
                            variant="outline-primary"
                          >
                            {kTrans("Add Info")}
                          </Button>
                        )}
                      </Form.Group>
                    </Col>
                    <Col md={4} className="">
                      <Form.Group controlId="" className="">
                        <Form.Label>
                          {kTrans("Custom CTA")}{" "}
                          <span className="lightColorSpan">
                            ({kTrans("Optional")})
                          </span>
                        </Form.Label>
                        <span className="lightColorSpan d-block mb-2 small">
                          {kTrans("Customize CTA shown with results")}.
                        </span>
                        {this.state.CTAClicked ? (
                          <Form.Group controlId="affiliate" className="pt-0">
                            <Form.Control
                              className=""
                              data-required="true"
                              type="text"
                              onChange={this.changeHandler}
                              name="affiliateLink"
                              value={affiliateLink}
                              placeholder={kTrans("Enter affiliate link here")}
                            />
                            {validationErrors &&
                            validationErrors.affiliateLink !== undefined ? (
                              <span className="validation-error">
                                {validationErrors.affiliateLink}
                              </span>
                            ) : null}
                            <Form.Control
                              className="mt-2"
                              data-required="true"
                              type="text"
                              onChange={this.changeHandler}
                              name="affiliateButtonText"
                              value={affiliateButtonText}
                              placeholder={kTrans('"Enter button text"')}
                            />
                            <div className="txtCenter mt-2">
                              <Button
                                onClick={this.removeCustomCTA}
                                className="w-50"
                                size="sm"
                                variant="outline-primary"
                              >
                                {kTrans("Remove")}
                              </Button>
                            </div>
                          </Form.Group>
                        ) : (
                          <Button
                            className="customBtn p-2 ps-4 pe-4"
                            variant="outline-primary"
                            onClick={() => this.setState({ CTAClicked: true })}
                          >
                            {kTrans("Add CTA")}
                          </Button>
                        )}
                      </Form.Group>
                    </Col>
                  </Row>
                </Col>
                <Col md={5} className="ps-md-5">
                  <div className="buttons mb-4 d-none d-md-flex justify-content-end">
                    <span
                      onClick={(e) => {
                        this.saveDraft(e);
                      }}
                      className="btn btn-secondary mt-0 ms-0"
                    >
                      {kTrans("Save Draft")}
                    </span>
                    <Button
                      variant="primary"
                      className="mt-0 me-0"
                      type="submit"
                    >
                      {kTrans("Publish")}
                    </Button>
                  </div>
                  <div className="inline-content top">
                    <Form.Group controlId="startedAt" className="me-3">
                      <Form.Label>{kTrans("Start Date")}</Form.Label>
                      <div className="datePickerWrapper">
                        <DateRangePicker
                          onCallback={(e) =>
                            this.datePickerCallback(e, "startedAt")
                          }
                          initialSettings={{
                            singleDatePicker: true,
                            minDate: moment(),
                            timePicker: true,
                            locale: {
                              format: "MM/DD/YY @ hh:mmA",
                            },
                          }}
                        >
                          <input
                            type="text"
                            name="startedAt"
                            className="form-control datepickerField"
                          />
                        </DateRangePicker>
                        <i className="fa fa-calendar"></i>
                      </div>
                    </Form.Group>

                    {this.state.resetEndDate !== true ? (
                      <Form.Group controlId="endedAt" className="">
                        <Form.Label>{kTrans("End Date")}</Form.Label>
                        <div className="datePickerWrapper">
                          <DateRangePicker
                            onCallback={(e) =>
                              this.datePickerCallback(e, "endedAt")
                            }
                            initialSettings={{
                              singleDatePicker: true,
                              minDate: endedAtMinDate,
                              startDate: endedAtDefDate,
                              /*maxDate: endedAtMaxDate,*/
                              timePicker: true,
                              locale: {
                                format: "MM/DD/YY @ hh:mmA",
                              },
                            }}
                          >
                            <input
                              type="text"
                              name="endedAt"
                              className={`form-control datepickerField ${parseInt(noEndDate) === 1 ? "disabledInput" : ""}`}
                            />
                          </DateRangePicker>
                          <i className="fa fa-calendar"></i>
                        </div>

                        <div className="noEndDate">
                          <Form.Check.Label className="mb-0 mt-2">
                            <Form.Check.Input
                              className="m-0 me-2 "
                              checked={parseInt(noEndDate)}
                              onChange={this.changeHandler}
                              name="noEndDate"
                              value={parseInt(noEndDate) === 1 ? 0 : 1}
                            />
                            {kTrans("No end date")}
                          </Form.Check.Label>
                        </div>
                      </Form.Group>
                    ) : null}
                  </div>

                  <div className="row">
                    <Form.Group
                      controlId="PollAuthor"
                      className="col-md-6 pe-2"
                    >
                      <Form.Label>
                        {kTrans(`${isQuiz ? "Quiz" : "Poll"} Author`)}
                      </Form.Label>
                      {/*<Form.Control className="userId" as="select" data-required="true" onChange={this.changeHandler } name="userId" value={userId}>
												<option value="" data-id="">-- Select --</option>
												{
													authorsList && authorsList.length ? 
														authorsList.map((el, i) => (
								                  				<option key={i} data-index={i} value={el.id} data-id={el.id}>{el.name}</option>              
														))
														: <option value="">No author available</option>
												}
											</Form.Control>*/}

                      <DropdownButton
                        className="customDropdown"
                        id="dropdown-item-button"
                        title={
                          authorsList &&
                          authorsList.find((item) => item.id === userId)
                            ? authorsList.find((item) => item.id === userId)
                                .name
                            : kTrans("Select Author")
                        }
                      >
                        {authorsList && authorsList.length ? (
                          authorsList.map((el, i) => (
                            <Dropdown.Item
                              key={i}
                              name="userId"
                              onClick={(e) =>
                                this.handleCustomDropdownChange(e, el.id)
                              }
                              as="button"
                            >
                              {el.name}
                            </Dropdown.Item>
                          ))
                        ) : (
                          <Dropdown.Item>
                            {kTrans("No author available")}
                          </Dropdown.Item>
                        )}
                      </DropdownButton>
                    </Form.Group>

                    <Form.Group controlId="PollCat" className="col-md-6 ps-2">
                      <Form.Label>
                        {kTrans(`${isQuiz ? "Quiz" : "Poll"} Category`)} (
                        {kTrans("Optional")})
                      </Form.Label>
                      <DropdownButton
                        className="standard-dropdown"
                        onClick={({ target }) =>
                          setTimeout(() => {
                            if (
                              target.nextElementSibling &&
                              target.nextElementSibling.firstElementChild &&
                              target.nextElementSibling.firstElementChild
                                .firstElementChild
                            ) {
                              target.nextElementSibling.firstElementChild.firstElementChild.focus();
                            }
                          }, 200)
                        }
                        title={
                          categoriesList &&
                          categoriesList.find((item) => item.id === categoryId)
                            ? categoriesList.find(
                                (item) => item.id === categoryId,
                              ).text
                            : kTrans("Select Category")
                        }
                      >
                        <Form.Group>
                          <Form.Control
                            value={categoryFilter}
                            placeholder="Enter category name"
                            onChange={(e) =>
                              this.setState({ categoryFilter: e.target.value })
                            }
                          />
                        </Form.Group>
                        {categoryFilter ? (
                          <>
                            {(categoriesList || [])
                              .filter(
                                (item) =>
                                  item.text
                                    .toUpperCase()
                                    .indexOf(categoryFilter.toUpperCase()) > -1,
                              )
                              .map((item) => (
                                <Dropdown.Item
                                  key={item.id}
                                  name="categoryId"
                                  onClick={(e) =>
                                    this.handleCustomDropdownChange(e, item.id)
                                  }
                                  as="button"
                                >
                                  {item.text}
                                </Dropdown.Item>
                              ))}
                          </>
                        ) : (
                          <>
                            {(categoriesList || []).map((item) => (
                              <Dropdown.Item
                                key={item.id}
                                name="categoryId"
                                onClick={(e) =>
                                  this.handleCustomDropdownChange(e, item.id)
                                }
                                as="button"
                              >
                                {item.text}
                              </Dropdown.Item>
                            ))}
                          </>
                        )}
                        {!(categoriesList || []).length ? (
                          <Dropdown.Item>
                            {kTrans("No category available")}
                          </Dropdown.Item>
                        ) : null}
                      </DropdownButton>
                    </Form.Group>
                  </div>

                  <Form.Group className="pb-2">
                    <div className="inline-content middle">
                      <Form.Label>
                        {kTrans(`${isQuiz ? "Quiz" : "Poll"} Preview`)}
                      </Form.Label>
                    </div>
                  </Form.Group>
                  <div className="debatePreviewSection">
                    <div className="debatePreviewInner">
                      <div className="debatePreviewHeader">
                        <div className="author">
                          {brandAccount ? (
                            <div className="author">
                              <a
                                target="_blank"
                                rel="noreferrer"
                                className="small-dp user-color-green"
                                href={"/" + brandAccount.username}
                              >
                                <img
                                  style={{
                                    borderColor: brandAccount.statusColor,
                                  }}
                                  data-src=""
                                  src={
                                    brandAccount.avatarObject
                                      ? brandAccount.avatarObject.small.location
                                      : DEFAULT_USER_IMG_URL
                                  }
                                  alt={brandAccount.name}
                                  className="sidedLazyLoad img-circle avatar"
                                  onError={(e) => {
                                    e.target.src = DEFAULT_USER_IMG_URL;
                                  }}
                                />
                                {brandAccount.roles ? (
                                  <>
                                    {brandAccount.roles.length > 0 &&
                                    brandAccount.roles.find(
                                      (role) => role.name === "VERIFIED",
                                    ) ? (
                                      <FontAwesomeIcon
                                        style={{
                                          color: brandAccount.checkmarkColor,
                                        }}
                                        icon={faCheckCircle}
                                        className="procheckmark"
                                      />
                                    ) : null}
                                  </>
                                ) : (
                                  <FontAwesomeIcon
                                    style={{
                                      color: brandAccount.checkmarkColor,
                                    }}
                                    icon={faCheckCircle}
                                    className="procheckmark"
                                  />
                                )}
                              </a>
                              <div className="copy-wrap">
                                <a
                                  target="_blank"
                                  rel="noreferrer"
                                  className="authorname"
                                  href={"/" + brandAccount.username}
                                >
                                  <h6>{brandAccount.name}</h6>
                                </a>
                                <span className="authorhandle">
                                  <span className="handler">
                                    Posted{" "}
                                    {moment(startedAt).format("MMM DD, YYYY")}
                                  </span>
                                </span>
                              </div>
                            </div>
                          ) : null}
                        </div>
                      </div>
                      {backgroundImage !== "" ? (
                        <div className="debatePreviewBgImg mt-3">
                          <img alt="backgroundImage" src={backgroundImage} />
                        </div>
                      ) : null}
                      <div className="debatePreviewTitle mt-3">
                        {thesis !== "" ? (
                          <h5>{thesis}</h5>
                        ) : (
                          <>
                            <p></p>
                            <p></p>
                            <p></p>
                          </>
                        )}
                      </div>
                      {!parseInt(noEndDate) ? (
                        <span className="lightColorSpan mt-2 d-block">{`${kTrans(`${isQuiz ? "Quiz" : "Poll"} ends in`)} ${finalDifference} • ${kTrans("Vote below")}`}</span>
                      ) : (
                        <span className="lightColorSpan mt-2 d-block">
                          Vote below
                        </span>
                      )}

                      <div className="debatePreviewSides mt-2">
                        {sides && sides.length
                          ? sides.map((el, i) => (
                              <div className="" key={i}>
                                <label>
                                  {el.text !== ""
                                    ? el.text
                                    : `${kTrans("Side")} ${i + 1}`}
                                </label>
                              </div>
                            ))
                          : null}
                      </div>

                      {affiliateLink ? (
                        <div className="debateAffiliateBtn mt-2">
                          <a
                            target="_blank"
                            rel="noreferrer"
                            className="btn btn-outline-primary btn-sm w-100"
                            href={
                              affiliateLink.includes("http")
                                ? affiliateLink
                                : "//" + affiliateLink
                            }
                          >
                            {affiliateButtonText}
                          </a>
                        </div>
                      ) : null}

                      <div className="inline-content middle mt-3">
                        <span className="lightColorSpan d-block">
                          0 {kTrans("Votes")} • 0 {kTrans("Comments")}{" "}
                        </span>
                        <span className="customAnchor">{kTrans("Share")}</span>
                      </div>
                      <div className="debatePreviewFooter mt-3">
                        <span className="customAnchor">
                          {kTrans(`Embed ${isQuiz ? "Quiz" : "Poll"}`)}
                        </span>
                        <img alt="Logo" src={logo} className="logo" />
                      </div>
                    </div>
                  </div>
                  <div className="buttons mt-5 mb-4 d-md-none d-flex justify-content-end">
                    <span
                      onClick={(e) => {
                        this.saveDraft(e);
                      }}
                      className="btn btn-secondary mt-0 ms-0"
                    >
                      {kTrans("Save Draft")}
                    </span>
                    <Button
                      variant="primary"
                      className="mt-0 me-0"
                      type="submit"
                    >
                      {kTrans("Publish")}
                    </Button>
                  </div>
                </Col>
              </Form>
            </div>
            <Modal
              className="debateAdditionalInfo"
              size="md"
              show={this.state.showAdditionaInfoModal}
            >
              <Modal.Body className="m-0 p-4 common-form">
                <h3>
                  <b>{kTrans("Additional Info")}</b>
                </h3>
                <span className="lightColorSpan">
                  {kTrans(
                    `Add more info about your ${isQuiz ? "quiz" : "poll"}`,
                  )}
                  . ({kTrans("Optional")})
                </span>
                <div className="modalContentWrapper">
                  <Form.Group className="startDebate">
                    <textarea
                      rows="3"
                      className="mt-5 mb-5 form-control"
                      onChange={(e) => {
                        this.changeHandler(e);
                      }}
                      name="backgroundTemp"
                      value={backgroundTemp ? backgroundTemp : background}
                      placeholder={kTrans(
                        "Share any information that might help people choose a side",
                      )}
                    ></textarea>
                  </Form.Group>
                  <div className="buttons text-center">
                    <Button
                      className="mt-2"
                      onClick={this.hideAdditionaInfoModal}
                      variant="secondary"
                    >
                      {kTrans("Cancel")}
                    </Button>
                    <Button
                      onClick={this.commitAdditionalInfo}
                      className={`mt-2 ${backgroundTemp === "" ? "disabled" : ""}`}
                      variant={backgroundTemp === "" ? "secondary" : "primary"}
                      type="submit"
                    >
                      {kTrans("Save")}
                    </Button>
                  </div>
                </div>
              </Modal.Body>
            </Modal>
          </Tab>
          <Tab
            eventKey="embed-settings"
            title={kTrans(`Deploy ${isQuiz ? "Quiz" : "Poll"}`)}
            disabled="true"
          ></Tab>
        </Tabs>
      </section>
    );
  }
}

const mapStateToProps = (state) => ({
  loggedInUser: state.login.user,
  selNetworkId: state.networks.selNetworkId,
});

const Extended = withTranslation()(StartDebate);
Extended.static = StartDebate.static;

export default connect(mapStateToProps, {})(Extended);
