export const API_URL =
  window.location.host === "admin.sided.co"
    ? "https://api2.sided.co"
    : "https://api.sidedstage.com";
export const ADMIN_WEBSITE_URL =
  process.env.REACT_APP_ENV === "local"
    ? "http://localhost:3000"
    : process.env.REACT_APP_ENV === "stage"
      ? "https://admin.sidedstage.com"
      : "https://admin.sided.co";
export const MAIN_WEBSITE_URL =
  process.env.REACT_APP_ENV === "local"
    ? "https://app.sided.co"
    : process.env.REACT_APP_ENV === "stage"
      ? "https://sidedstage.com"
      : "https://app.sided.co";
export const BASENAME = "";
export const USERS_PER_PAGE = 25;
export const DEBATES_PER_PAGE = 25;
export const MODERATION_PER_PAGE = 10;
export const TOP_DEBATES_PER_PAGE = 9999999;
export const SUGGESTED_DEBATES_PER_PAGE = 12;
export const EMBED_PAGES_PER_PAGE = 25;
export const NETWORKS_PER_PAGE = 9999999;
export const DEFAULT_DEBATE_BG_URL =
  "https://cdn.sided.co/prod/sided/users/default-avatar.png";
export const DEFAULT_USER_IMG_URL =
  "https://cdn.sided.co/prod/sided/users/default-avatar.png";
export const DEFAULT_USER_COVER_URL =
  "https://cdn.sided.co/prod/sided/covers/default-cover.png";
export const MAIN_NETWORK_NAME = "sided";
export const SIDED_SUPPORT_EMAIL = "support@sided.co";
export const DEFAULT_DEBATE_DURATION = 7;
export const MAX_DEBATE_DURATION = 180;
export const SIDED_FAVICON =
  "https://cdn.sided.co/prod/sided/wl/sided/images/c7bcd500-0852-11eb-8f00-0f8ed472b657.png";
export const SIDED_LOGO =
  "https://cdn.sided.co/prod/sided/wl/sided/images/c7ac0980-0852-11eb-b673-1dad531e23be.png";
export const SIDES_COLORS = {
  "side-1": "#67bb69",
  "side-2": "#a940bb",
  "side-3": "#43a5f5",
  "side-4": "#ed3f7a",
  "side-5": "#00BCD3",
  "side-6": "#ffbe68",
};
