import React from "react";
import axios from "axios";
import {
  Row,
  Table,
  Modal,
  Form,
  Button,
  Dropdown,
  Tabs,
  Tab,
} from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { connect } from "react-redux";
import {
  DEBATES_PER_PAGE,
  API_URL,
  DEFAULT_USER_IMG_URL,
  MAIN_WEBSITE_URL,
} from "../../constants/Globals";
import {
  clearDebates,
  loadDebates,
  loadTopDebates,
} from "../../redux/action/debates.action";
import ReactPaginate from "react-paginate";
import fireIcon from "../../fire-icon.png";
import SuggestedDebates from "./SuggestedDebates";
import { withTranslation } from "react-i18next";

var moment = require("moment-timezone");

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <span
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
    <i className="fa fa-ellipsis-h"></i>
  </span>
));

class Debates extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      pageNumber: 1,
      debateSearch: "",
      debatesPerPage: DEBATES_PER_PAGE,
      topDebatePageNumber: 1,
      embedCode: null,
      delDebate: null,
      showDeleteDebateModal: false,
      isQuiz: props.type === "quiz",
    };
  }

  searchDebateChangeHandler = (event) => {
    this.setState({
      debateSearch: event.target.value,
    });
  };

  handleSearchSubmit = async (e) => {
    await this.setState({
      pageNumber: 1,
    });
    this.getDebates();
    e.preventDefault();
  };

  getDebates = async () => {
    const { pageNumber, debateSearch, isQuiz } = this.state;

    await this.props.loadDebates(
      this.props.selNetworkId,
      pageNumber,
      debateSearch,
      isQuiz,
    );
  };

  getTopDebates = async () => {
    const { isQuiz } = this.state;

    if (!isQuiz) {
      await this.props.loadTopDebates(
        this.props.selNetworkId,
        this.state.topDebatePageNumber,
      );
    }
  };

  handlePageClick = async (data) => {
    let selected = data.selected + 1;
    await this.setState({
      pageNumber: selected,
    });
    this.getDebates();
  };

  deleteDebateHandler = async (debate) => {
    await this.setState({
      delDebate: debate,
    });
    this.showDeleteDebateModal();
  };

  showDeleteDebateModal = () => {
    if (this.state.delDebate) {
      this.setState({
        showDeleteDebateModal: true,
      });
    }
  };
  hideDeleteDebateModal = (e) => {
    this.setState({
      showDeleteDebateModal: false,
      delDebate: null,
    });
  };

  submitDeleteDebateHandler = (e) => {
    const { isQuiz } = this.state;

    e.preventDefault();
    if (this.state.delDebate) {
      this.setState({
        loading: true,
      });
      axios
        .delete(API_URL + "/debate/destroy?debateId=" + this.state.delDebate.id)
        .then((res) => {
          this.setState({
            loading: false,
            delDebate: null,
          });
          this.messageNotification(
            "success",
            this.props.t(
              `${isQuiz ? "Quiz" : "Poll"} has been deleted successfully!`,
            ),
          );
          this.getDebates();
          this.getTopDebates();
          this.hideDeleteDebateModal();
        })
        .catch((err) => {
          console.log("err", err);
        });
    }
  };

  componentDidMount = () => {
    this.props.clearDebates();
    this.getDebates();
    this.getTopDebates();
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.selNetworkId !== this.props.selNetworkId) {
      this.getDebates();
      this.getTopDebates();
    }
  }

  messageNotification = async (type, message) => {
    this.setState({
      successMsg: message,
    });

    setTimeout(() => {
      this.setState({
        successMsg: "",
      });
    }, 5000);
  };

  copyToClipboard = async (e, debId) => {
    this.setState({ embedCode: null });

    await axios
      .get(API_URL + "/debate/getEmbed/" + debId)
      .then((res) => {
        this.setState({ embedCode: res.data.data.html });
      })
      .catch((err) => {
        console.log(err.response.data.message);
      });

    if (this.textArea.value !== "") {
      this.textArea.select();
      await document.execCommand("copy");
      e.target.focus();
      this.messageNotification(
        "success",
        this.props.t("Embed code copied to clipboard!"),
      );
    } else {
      this.messageNotification(
        "fail",
        "There is some problem in copying embed code. Please reload the page and try again!",
      );
    }
  };

  deployedStatus(debate) {
    const allPagesMatch = debate.embedPlacementDebates.find(
      (item) => item.embedDomainPageId === parseInt(0),
    );

    if (allPagesMatch) {
      if (allPagesMatch.embedDomainCategories) {
        return `Deployed to ${allPagesMatch.embedDomainCategories} categor${allPagesMatch.embedDomainCategories.indexOf(",") !== -1 ? "ies" : "y"}`;
      }

      return this.props.t("Deployed to all pages");
    }

    return `Deployed to ${debate.embedPlacementDebates.length} page${debate.embedPlacementDebates.length === 1 ? "" : "s"}`;
  }

  render() {
    const { debates, debatesCount, topDebates, selNetworkId } = this.props;
    const { debatesPerPage, pageNumber, embedCode, isQuiz } = this.state;
    const noOfPages = Math.ceil(debatesCount / debatesPerPage);
    const loggedInUser = this.props.loggedInUser;
    const kTrans = this.props.t ? this.props.t : "";

    var is_editor = false;
    if (
      loggedInUser.roles.find(
        (role) =>
          role.name === "ADMIN" &&
          role.roleUser.clientId === parseInt(selNetworkId),
      )
    ) {
    } else {
      if (
        loggedInUser.roles.find(
          (role) =>
            role.name === "EDITOR" &&
            role.roleUser.clientId === parseInt(selNetworkId),
        )
      ) {
        is_editor = true;
      }
    }

    return (
      <section>
        {this.state.successMsg ? (
          <div className="sucessMsg">{this.state.successMsg}</div>
        ) : null}

        <div className="inline-content middle">
          <h2>{kTrans(isQuiz ? "Quizzes" : "Polls")}</h2>
          <NavLink
            className="btn btn-primary"
            to={`/debates/start-debate${isQuiz ? "?type=quiz" : ""}`}
          >
            {kTrans(`Create A ${isQuiz ? "Quiz" : "Poll"}`)}{" "}
            <FontAwesomeIcon
              className="ms-1 fa-plus-circle-blue"
              icon={faPlusCircle}
            />
          </NavLink>
        </div>

        <p className="lightColorSpan mt-3">
          {kTrans("Results")}: {debatesCount}
        </p>
        {isQuiz ? null : (
          <Tabs
            defaultActiveKey="top-debates"
            id="uncontrolled-tab-example"
            className="mb-0 mb-md-2 networkSettingsTabs"
          >
            <Tab eventKey="top-debates" title={kTrans("Top Polls")}>
              <div>
                {topDebates && topDebates.length ? (
                  <div className="topDebatesSection mb-5 mt-2">
                    <div className="topDebatesTitle">
                      <div className="d-flex align-items-center">
                        <img
                          alt="fireIcon"
                          src={fireIcon}
                          className="fireIcon"
                        />
                      </div>
                    </div>
                    <div className="topDebatesContent mt-3">
                      {topDebates && topDebates.length ? (
                        topDebates.map((el, i) => (
                          <div className="topDebate" key={el.id}>
                            <div className="debatePreviewSection1">
                              <div className="debatePreviewInner">
                                <div className="debatePreviewHeader">
                                  <div className="author">
                                    <a
                                      target="_blank"
                                      rel="noreferrer"
                                      className="small-dp user-color-green"
                                      href={`${MAIN_WEBSITE_URL}/${el.user.username}`}
                                      tabIndex="0"
                                    >
                                      <img
                                        style={{
                                          borderColor: el.user.statusColor,
                                        }}
                                        data-src=""
                                        src={
                                          el.user.avatarObject
                                            ? el.user.avatarObject.small
                                                .location
                                            : DEFAULT_USER_IMG_URL
                                        }
                                        alt={el.user.name}
                                        className="sidedLazyLoad img-circle avatar"
                                        onError={(e) => {
                                          e.target.src = DEFAULT_USER_IMG_URL;
                                        }}
                                      />

                                      {el.user.roles.length > 0 &&
                                      el.user.roles.find(
                                        (role) => role.name === "VERIFIED",
                                      ) ? (
                                        <FontAwesomeIcon
                                          style={{
                                            color: el.user.checkmarkColor,
                                          }}
                                          icon={faCheckCircle}
                                          className="procheckmark"
                                        />
                                      ) : null}
                                    </a>
                                    <div className="copy-wrap">
                                      <a
                                        target="_blank"
                                        rel="noreferrer"
                                        className="authorname"
                                        href={`${MAIN_WEBSITE_URL}/${el.user.username}`}
                                        tabIndex="0"
                                      >
                                        <h6>{el.user.name}</h6>
                                      </a>
                                      <span className="authorhandle">
                                        <span className="handler">
                                          {kTrans("Posted")}{" "}
                                          {moment(el.startedAt).format(
                                            "MMM DD, YYYY",
                                          )}
                                        </span>
                                      </span>
                                    </div>
                                  </div>
                                </div>
                                <div className="debatePreviewTitle mt-3">
                                  {el.thesis !== "" ? (
                                    <h5>
                                      {el.isClosed ? (
                                        <>{el.thesis}</>
                                      ) : (
                                        <NavLink
                                          exact
                                          className="arrow"
                                          to={`/debates/debate/${el.id}`}
                                        >
                                          {el.thesis}
                                        </NavLink>
                                      )}
                                    </h5>
                                  ) : (
                                    <>
                                      <p></p>
                                      <p></p>
                                      <p></p>
                                    </>
                                  )}
                                </div>
                                {el.endedAt === null ? null : (
                                  <span className="lightColorSpan mt-2 d-block">
                                    {kTrans("Poll ends in")}{" "}
                                    {(
                                      Math.floor(
                                        moment(el.endedAt).diff(
                                          moment(),
                                          "minutes",
                                        ) / 1440,
                                      ) > 0
                                        ? Math.floor(
                                            moment(el.endedAt).diff(
                                              moment(),
                                              "minutes",
                                            ) / 1440,
                                          ) + " day(s) "
                                        : Math.floor(
                                              (moment(el.endedAt).diff(
                                                moment(),
                                                "minutes",
                                              ) %
                                                1440) /
                                                60,
                                            ) > 0
                                          ? Math.floor(
                                              (moment(el.endedAt).diff(
                                                moment(),
                                                "minutes",
                                              ) %
                                                1440) /
                                                60,
                                            ) + " hour(s) "
                                          : Math.floor(
                                                (moment(el.endedAt).diff(
                                                  moment(),
                                                  "minutes",
                                                ) %
                                                  1440) %
                                                  60,
                                              ) > 0
                                            ? Math.floor(
                                                (moment(el.endedAt).diff(
                                                  moment(),
                                                  "minutes",
                                                ) %
                                                  1440) %
                                                  60,
                                              ) + " minute(s)"
                                            : "< 1 minute"
                                    )
                                      ? Math.floor(
                                          moment(el.endedAt).diff(
                                            moment(),
                                            "minutes",
                                          ) / 1440,
                                        ) > 0
                                        ? Math.floor(
                                            moment(el.endedAt).diff(
                                              moment(),
                                              "minutes",
                                            ) / 1440,
                                          ) + " day(s) "
                                        : Math.floor(
                                              (moment(el.endedAt).diff(
                                                moment(),
                                                "minutes",
                                              ) %
                                                1440) /
                                                60,
                                            ) > 0
                                          ? Math.floor(
                                              (moment(el.endedAt).diff(
                                                moment(),
                                                "minutes",
                                              ) %
                                                1440) /
                                                60,
                                            ) + " hour(s) "
                                          : Math.floor(
                                                (moment(el.endedAt).diff(
                                                  moment(),
                                                  "minutes",
                                                ) %
                                                  1440) %
                                                  60,
                                              ) > 0
                                            ? Math.floor(
                                                (moment(el.endedAt).diff(
                                                  moment(),
                                                  "minutes",
                                                ) %
                                                  1440) %
                                                  60,
                                              ) + " minute(s)"
                                            : "< 1 minute"
                                      : ""}
                                  </span>
                                )}
                                <div className="debatePreviewSides mt-2">
                                  {el.sides && el.sides.length
                                    ? el.sides.map((elSides, i) => (
                                        <div className="side" key={elSides.id}>
                                          <div className="message position-relative">
                                            {!isNaN(
                                              (
                                                (elSides.votes * 100) /
                                                el.votes
                                              ).toFixed(2),
                                            ) ? (
                                              <div
                                                className="percentageBar"
                                                style={{
                                                  width:
                                                    (elSides.votes * 100) /
                                                      el.votes +
                                                    "%",
                                                  background:
                                                    elSides.sideColor + "26",
                                                }}
                                              ></div>
                                            ) : null}
                                            <div
                                              className="topDebateSides"
                                              style={{
                                                borderColor: elSides.sideColor,
                                                background:
                                                  elSides.sideColor + "10",
                                              }}
                                            >
                                              {elSides.text}
                                            </div>
                                            {!isNaN(
                                              (
                                                (elSides.votes * 100) /
                                                el.votes
                                              ).toFixed(2),
                                            ) ? (
                                              <span className="votesPercentage">
                                                {(
                                                  (elSides.votes * 100) /
                                                  el.votes
                                                ).toFixed(2)}
                                                %
                                              </span>
                                            ) : null}
                                          </div>
                                        </div>
                                      ))
                                    : null}
                                </div>

                                <span className="lightColorSpan mt-2 d-block">
                                  <span>
                                    {el.votes} Vote{el.votes !== 1 ? "s" : ""}
                                    &nbsp;&bull;&nbsp;
                                    {parseInt(el.comments) +
                                      parseInt(el.arguments)}{" "}
                                    Comment
                                    {parseInt(el.comments) +
                                      parseInt(el.arguments) !==
                                    1
                                      ? "s"
                                      : ""}
                                  </span>
                                  <span
                                    data-id={el.id}
                                    className="customAnchor d-inline pull-right"
                                    onClick={(e) =>
                                      this.copyToClipboard(e, el.id)
                                    }
                                  >
                                    <i className="fa fa-code"></i>
                                  </span>
                                </span>
                              </div>
                            </div>
                          </div>
                        ))
                      ) : (
                        <span>{kTrans("No top poll found")}</span>
                      )}
                    </div>
                    {embedCode !== null ? (
                      <textarea
                        className="hiddenElement"
                        ref={(textarea) => (this.textArea = textarea)}
                        value={embedCode}
                      />
                    ) : null}
                  </div>
                ) : (
                  <div className="topDebatesSection mb-5 mt-2">
                    {kTrans("No top poll found")}
                  </div>
                )}
              </div>
            </Tab>
            <Tab
              eventKey="suggested-debates-ai"
              title={kTrans("Polls based on your content")}
            >
              <Row>
                <SuggestedDebates type="ai" />
              </Row>
            </Tab>
            <Tab
              eventKey="suggested-debates"
              title={kTrans("Polls from Sided")}
            >
              <Row>
                <SuggestedDebates />
              </Row>
            </Tab>
          </Tabs>
        )}

        <div className="row mb-3">
          <div className="col-sm-4">
            <form
              id="search"
              autoComplete="off"
              className="searchForm1"
              onSubmit={(e) => this.handleSearchSubmit(e)}
            >
              <input
                onChange={this.searchDebateChangeHandler}
                type="text"
                className="form-control"
                value={this.state.debateSearch}
                name="debateSearch"
                placeholder={kTrans(
                  `Search for a ${isQuiz ? "quiz" : " poll or page"}`,
                )}
              />
              <button type="submit">
                <i className="fa fa-search"></i>
              </button>
            </form>
          </div>
        </div>

        <div className="table-wrapper">
          <Table className="debates tableCommon">
            <thead>
              <tr>
                <th>{kTrans(isQuiz ? "Quizzes" : "POLLS")}</th>
                <th>{kTrans("STATUS")}</th>
                <th>{kTrans("VOTES")}</th>
                <th>{kTrans("Comments")}</th>
                {/* 
					  <th>% Engaged</th>
				      <th>New Users</th>
*/}
                <th>{kTrans("Author")}</th>
                <th>{kTrans("Posted by")}</th>
                <th>{kTrans("ACTION")}</th>
              </tr>
            </thead>
            <tbody>
              {debates && debates.length ? (
                debates.map((debate) => (
                  <tr key={debate.id}>
                    <td>
                      <div className="d-flex align-items-center justify-content-between">
                        <div className="debate-info">
                          <div>
                            <b>
                              <NavLink
                                exact
                                className=""
                                to={
                                  debate.isDraft === 1
                                    ? `/debates/draft/${debate.id}`
                                    : `/debates/debate/${debate.id}`
                                }
                              >
                                {debate.thesis}
                              </NavLink>
                            </b>
                          </div>
                          <div className="debStat1 mt-1">
                            {debate.isDraft === 1 ? null : (
                              <>
                                {debate.isActive === true ? (
                                  <span className="badge badge-success me-2">
                                    {kTrans("Active")}
                                  </span>
                                ) : (
                                  <>
                                    {debate.isSchedule === true ? null : (
                                      <>
                                        {debate.isClosed === true ? (
                                          <span className="badge badge-danger me-2">
                                            {kTrans("Closed")}
                                          </span>
                                        ) : null}
                                      </>
                                    )}
                                  </>
                                )}
                              </>
                            )}
                            {debate.isDraft === 1 ? null : (
                              <>
                                <a
                                  className="customAnchor listItem7"
                                  href={debate.debateUrl}
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  {kTrans(`View ${isQuiz ? "Quiz" : "Poll"}`)}
                                </a>
                              </>
                            )}
                            <NavLink
                              exact
                              className="customAnchor listItem7"
                              to={
                                debate.isDraft === 1
                                  ? `/debates/draft/${debate.id}`
                                  : `/debates/debate/${debate.id}`
                              }
                            >
                              {kTrans(`Edit ${isQuiz ? "Quiz" : "Poll"}`)}
                            </NavLink>
                            {debate.isDraft === 1 ||
                            debate.isSchedule === true ? null : (
                              <>
                                {debate.embedPlacementDebates.length > 0 ? (
                                  <NavLink
                                    className="customAnchor listItem7"
                                    to={`/debates/debate/${debate.id}?action=active-pages`}
                                  >
                                    <span>{this.deployedStatus(debate)}</span>
                                  </NavLink>
                                ) : (
                                  <NavLink
                                    className="customAnchor listItem7"
                                    to={`/debates/debate/${debate.id}?action=embed-settings`}
                                  >
                                    <span>
                                      {kTrans("Not Deployed on any pages")}
                                    </span>
                                  </NavLink>
                                )}
                              </>
                            )}
                          </div>
                        </div>
                        {debate.backgroundImageObject ? (
                          <div className="debate-image">
                            <img
                              src={debate.backgroundImageObject.thumb.location}
                              alt="Debate graphic"
                            />
                          </div>
                        ) : null}
                      </div>
                    </td>
                    <td>
                      {debate.isDraft === 1 ? (
                        <span className="">{kTrans("Draft Saved")}</span>
                      ) : (
                        <>
                          {debate.isActive === true ||
                          debate.isClosed === true ? (
                            <span className="">{kTrans("Published")}</span>
                          ) : (
                            <>
                              {debate.isSchedule === true ? (
                                <span className="">{kTrans("Scheduled")}</span>
                              ) : null}
                            </>
                          )}
                        </>
                      )}
                      <br />
                      {debate.isDraft === 1 ? (
                        <span>{moment(debate.createdAt).format("M/D/YY")}</span>
                      ) : (
                        <span>{moment(debate.startedAt).format("M/D/YY")}</span>
                      )}
                    </td>
                    <td>{debate.votes}</td>
                    <td>
                      {parseInt(debate.comments) + parseInt(debate.arguments)}
                    </td>
                    <td>{debate.user.name}</td>
                    <td>
                      {debate.postedByUser ? debate.postedByUser.name : "-"}
                    </td>
                    <td>
                      <Dropdown className="dropdown9">
                        <Dropdown.Toggle as={CustomToggle} />
                        <Dropdown.Menu size="sm" title="">
                          {debate.isDraft === 1 ? null : (
                            <>
                              <Dropdown.Item
                                href={debate.debateUrl}
                                target="_blank"
                              >
                                {kTrans(`View ${isQuiz ? "Quiz" : "Poll"}`)}
                              </Dropdown.Item>
                            </>
                          )}
                          <NavLink
                            className="dropdown-item"
                            to={
                              debate.isDraft === 1
                                ? `/debates/draft/${debate.id}`
                                : `/debates/debate/${debate.id}`
                            }
                          >
                            {kTrans(`Edit ${isQuiz ? "Quiz" : "Poll"}`)}
                          </NavLink>
                          {is_editor &&
                          loggedInUser.id !== debate.user.id ? null : (
                            <Dropdown.Item
                              onClick={() => this.deleteDebateHandler(debate)}
                            >
                              {kTrans(`Delete ${isQuiz ? "Quiz" : "Poll"}`)}
                            </Dropdown.Item>
                          )}
                        </Dropdown.Menu>
                      </Dropdown>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="6">
                    <h3 className="text-center">
                      {kTrans(`No ${isQuiz ? "Quiz" : "Poll"}`)}
                    </h3>
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </div>
        {noOfPages > 1 ? (
          <ReactPaginate
            className="pagination mt-4"
            previousLabel="&laquo;"
            nextLabel="&raquo;"
            breakLabel="..."
            breakClassName="break-me"
            pageCount={noOfPages}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={this.handlePageClick}
            containerClassName="pagination"
            activeClassName="active"
            forcePage={pageNumber - 1}
          />
        ) : (
          ""
        )}

        <Modal
          className="deleteDebateModal"
          size="lg"
          show={this.state.showDeleteDebateModal}
        >
          <Modal.Header>
            <Modal.Title>
              {kTrans(`Delete this ${isQuiz ? "quiz" : "poll"}`)}?
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="modalContentWrapper">
              <Form
                className="DeleteDebateForm common-form"
                onSubmit={this.submitDeleteDebateHandler}
              >
                <div className="txtCenter">
                  <span>
                    {kTrans(
                      `Are you sure you want to delete this ${isQuiz ? "quiz" : "poll"}?`,
                    )}
                  </span>
                </div>
                <div className="buttons text-center">
                  <Button
                    onClick={this.hideDeleteDebateModal}
                    variant="secondary"
                  >
                    {kTrans("Cancel")}
                  </Button>
                  <Button variant="primary" type="submit">
                    {kTrans("YES, DELETE")}
                  </Button>
                </div>
              </Form>
            </div>
          </Modal.Body>
        </Modal>
      </section>
    );
  }
}

const mapStateToProps = (state) => ({
  selNetworkId: state.networks.selNetworkId,
  debatesCount: state.debates.debatesCount,
  debates: state.debates.debates,
  loggedInUser: state.login.user,
  topDebates: state.debates.topDebates,
});

const Extended = withTranslation()(Debates);
Extended.static = Debates.static;

export default connect(mapStateToProps, {
  clearDebates,
  loadDebates,
  loadTopDebates,
})(Extended);
