import React from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import ModerationOpenCases from "./ModerationOpenCases";
import ModerationClosedCases from "./ModerationClosedCases";

class Moderation extends React.Component {
  constructor(props) {
    super(props);

    const search = this.props.location.search;

    this.state = {
      searchTextTmp: "",
      searchText: "",
      caseType: new URLSearchParams(search).get("case")
        ? new URLSearchParams(search).get("case")
        : "open",
    };
  }

  handleSearchSubmit = async (e) => {
    /*if(!this.state.searchTextTmp){
			alert('Please enter a keyword.');
		}*/
    this.setState({ searchText: this.state.searchTextTmp });
    e.preventDefault();
  };

  handleQueryChange = async (e) => {
    this.setState({ searchTextTmp: e.target.value });
  };

  changeTab = async (e, type) => {
    this.setState({ caseType: type });
    this.setState({ searchTextTmp: "", searchText: "" });
  };

  render() {
    const { caseType, searchTextTmp } = this.state;
    const kTrans = this.props.t ? this.props.t : "";

    return (
      <section>
        <div className="title-section mb-4">
          <h2>{kTrans("Moderation")}</h2>
          <div className="inline-content top mb-4 title-section ">
            <form
              id="search"
              autoComplete="off"
              className="navbar-form navbar-left w-25"
              method="get"
              onSubmit={this.handleSearchSubmit}
            >
              <input
                onChange={this.handleQueryChange}
                type="text"
                className="form-control"
                value={searchTextTmp}
                name="searchTextTmp"
                placeholder={kTrans("Search for a user or poll")}
              />
              <button
                type="submit"
                disabled={
                  searchTextTmp.length > 0 && searchTextTmp.length < 3
                    ? true
                    : false
                }
              >
                <i className="fa fa-search"></i>
              </button>
            </form>
            <div className="moderationTabs">
              <span
                className={caseType === "open" ? "active" : ""}
                onClick={(e) => this.changeTab(e, "open")}
              >
                Open Cases
              </span>
              <span
                className={caseType === "closed" ? "active" : ""}
                onClick={(e) => this.changeTab(e, "closed")}
              >
                Closed Cases
              </span>
            </div>
          </div>
          <hr />
        </div>
        {caseType === "closed" ? (
          <ModerationClosedCases searchText={this.state.searchText} />
        ) : (
          <ModerationOpenCases searchText={this.state.searchText} />
        )}
      </section>
    );
  }
}

const mapStateToProps = (state) => ({
  loggedInUser: state.login.user,
});

const Extended = withTranslation()(Moderation);
Extended.static = Moderation.static;

export default connect(mapStateToProps, {})(Extended);
