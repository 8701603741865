import React from "react";
import {
  Row,
  Col,
  Button,
  Form,
  Tabs,
  Tab,
  Modal,
  DropdownButton,
  Dropdown,
} from "react-bootstrap";
import axios from "axios";
import {
  API_URL,
  DEFAULT_USER_IMG_URL,
  BASENAME,
  SIDES_COLORS,
} from "../../constants/Globals";
import { NavLink } from "react-router-dom";
import DateRangePicker from "react-bootstrap-daterangepicker";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faLink,
  faTag,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import logo from "../../logo.png";

import { ClientJS } from "clientjs";
const client = new ClientJS();
const fingerprint = client.getFingerprint();

var moment = require("moment-timezone");
var momentDurationFormatSetup = require("moment-duration-format");
momentDurationFormatSetup(moment);

var defaultStartDate = null;
var defaultEndDate = null;

export class DebateDetail extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isQuiz: false,
      addSideLink: true,
      removeSideLink: false,
      resetEndDate: false,
      networksList: null,
      categoriesList: null,
      authorsList: null,
      defaultDebateDuration: null,
      maxDebateDuration: null,
      networkDomain: null,
      embedCode: null,
      validationErrors: {},
      loading: false,
      formData: {
        id: this.props.match.params.id,
        clientId: "",
        userId: "",
        thesis: "",
        sides: "",
        categoryId: "",
        oldCategoryId: "",
        background: "",
        backgroundImage: "",
        backgroundTempData: "",
        startedAt: defaultStartDate,
        endedAt: defaultEndDate,
        type: "create",
        affiliateLink: "",
        affiliateButtonText: "",
        sourcePageUrl: ""
      },
      currentDebateData: "",
      showAdditionaInfoModal: false,
      toggleSideLinkField: {},
      toggleSideTagField: {},
      linkTempVar: null,
      tagTempVar: null,
    };
  }

  componentDidMount() {
    this.networksList();
    this.fetchCurreDebate();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.selNetworkId !== this.props.selNetworkId &&
      this.state.formData.clientId !== "" &&
      this.state.formData.clientId !== this.props.selNetworkId
    ) {
      window.location.href = BASENAME + "/";
    }
  }

  fetchCurreDebate = async () => {
    await this.setState({
      loading: true,
    });

    await axios
      .get(
        API_URL +
          "/debate/" +
          this.state.formData.id +
          "?deviceId=" +
          fingerprint,
      )
      .then((res) => {
        var debateData = res.data.data;

        this.setState({ currentDebateData: debateData });
        var formData = { ...this.state.formData };

        formData["clientId"] =
          debateData.clientId !== undefined ? debateData.clientId : "";
        formData["thesis"] =
          debateData.thesis !== undefined ? debateData.thesis : "";
        formData["userId"] =
          debateData.userId !== undefined ? debateData.userId : "";
        formData["sides"] =
          debateData.sides !== undefined ? debateData.sides : "";
        formData["oldCategoryId"] =
          debateData.categories !== undefined &&
          debateData.categories.length > 0
            ? debateData.categories[0]["categoryDebate"]["categoryId"]
            : "";
        formData["categoryId"] =
          debateData.categories !== undefined &&
          debateData.categories.length > 0
            ? debateData.categories[0]["categoryDebate"]["categoryId"]
            : "";
        formData["background"] =
          debateData.background !== undefined ? debateData.background : "";
        formData["backgroundImage"] = debateData.backgroundImage
          ? debateData.backgroundImage
          : "";
        this.setState({
          backgroundImageDisplay:
            debateData.backgroundImageObject !== null
              ? debateData.backgroundImageObject["small"]["location"]
              : "",
        });

        formData["affiliateButtonText"] =
          debateData.affiliateButtonText !== undefined
            ? debateData.affiliateButtonText
            : "";
        formData["affiliateLink"] =
          debateData.affiliateLink !== undefined
            ? debateData.affiliateLink
            : "";

        formData["startedAt"] =
          debateData.startedAt !== undefined ? debateData.startedAt : "";
        formData["endedAt"] =
          debateData.endedAt !== undefined ? debateData.endedAt : "";

        formData["noEndDate"] = debateData.endedAt === null ? 1 : 0;

        if (moment().isAfter(formData["startedAt"])) {
          formData["startedAt"] = moment();
        }
        if (moment().isAfter(formData["endedAt"])) {
          formData["endedAt"] = moment().add(5, "minutes");
        }

        if (debateData.clientArticleSource && debateData.clientArticleSource.url) {
          formData["sourcePageUrl"] = debateData.clientArticleSource.url
        }

        this.setState({
          formData,
          isQuiz: debateData.isQuiz,
          loading: false,
        });

        this.copyEmbedCode();

        if (this.selectedInputElement !== undefined) {
          this.selectedInputElement.click();
        }

        if (this.state.formData.sides.length > 2) {
          this.setState({
            removeSideLink: true,
          });
        }
        if (this.state.formData.sides.length === 6) {
          this.setState({
            addSideLink: false,
          });
        }
      })
      .catch((err) => {
        alert("Failed. Please try again!");
        console.log(err);
      });
  };

  networksList = async (e) => {
    await axios
      .get(
        API_URL +
          "/admin/client/getAllClients?perPage=9999999&pageNumber=1&order=asc&orderBy=title",
      )
      .then((res) => {
        this.setState({
          networksList: res.data.data,
        });
        this.setState({ loading: false });
      });
  };

  fetchCats = async (
    e,
    defaultDebateDuration = "",
    maxDebateDuration = "",
    networkDomain = "",
  ) => {
    this.fetchAuthors(e);

    var formData = { ...this.state.formData };
    formData[e.target.name] = e.target.value;

    await this.setState({
      defaultDebateDuration: defaultDebateDuration,
      maxDebateDuration: maxDebateDuration,
      networkDomain: networkDomain,
    });
    await this.setState({ resetEndDate: true });
    await this.setState({ resetEndDate: false });

    await this.setState({ formData });
    this.formValidation(e);

    await axios
      .get(API_URL + "/categories?type=START_DEBATE&clientId=" + e.target.value)
      .then((res) => {
        this.setState({
          categoriesList: res.data.data.sort((a, b) =>
            a.text > b.text ? 1 : -1,
          ),
        });
      });
  };

  fetchAuthors = async (e) => {
    await axios
      .get(
        API_URL +
          "/user/list?type=DEBATE_AUTHORS&clientId=" +
          parseInt(e.target.value),
      )
      .then((res) => {
        this.setState({
          authorsList: res.data.data,
        });
      });
  };

  addNewSideHandle = async (e) => {
    var formData = { ...this.state.formData };
    var sidesArray = formData.sides;
    var sidesObj = {
      text: "",
    };
    sidesArray.push(sidesObj);
    formData.sides = sidesArray;
    await this.setState({ formData });
    console.log(this.state);
    if (this.state.formData.sides.length > 2) {
      this.setState({
        removeSideLink: true,
      });
    }
    if (this.state.formData.sides.length === 6) {
      this.setState({
        addSideLink: false,
      });
    }
  };

  removeSideLinkHandle = async (currEl) => {
    var formData = { ...this.state.formData };
    var sidesArr = formData.sides;
    sidesArr.splice(currEl, 1);
    formData.sides = sidesArr;
    await this.setState({ formData });

    if (this.state.formData.sides.length < 3) {
      this.setState({
        removeSideLink: false,
      });
    }

    if (this.state.formData.sides.length !== 6) {
      this.setState({
        addSideLink: true,
      });
    }
  };

  formValidation = async (e = "", action = "") => {
    var validationErrors = { ...this.state.validationErrors };
    if (e.target.name === "clientId" || action === "onSubmit") {
      if (this.state.formData.clientId === "") {
        validationErrors["clientId"] = "This field is required.";
        await this.setState({ validationErrors: validationErrors });
      } else {
        delete validationErrors["clientId"];
        await this.setState({ validationErrors: validationErrors });
      }
    }
    if (e.target.name === "thesis" || action === "onSubmit") {
      if (this.state.formData.thesis === "") {
        validationErrors["thesis"] =
          "The Statement or Question field is required.";
        await this.setState({ validationErrors: validationErrors });
      } else if (this.state.formData.thesis.length > 140) {
        validationErrors["thesis"] =
          "Please do not enter more than 140 characters.";
        await this.setState({ validationErrors: validationErrors });
      } else {
        delete validationErrors["thesis"];
        await this.setState({ validationErrors: validationErrors });
      }
    }
    if (e.target.name === "side-1" || action === "onSubmit") {
      if (this.state.formData.sides[0].text === "") {
        validationErrors["side-1"] = "The side field is required.";
        await this.setState({ validationErrors: validationErrors });
      } else if (this.state.formData.sides[0].text.length > 140) {
        validationErrors["side-1"] = "The maximum number of characters is 140.";
        await this.setState({ validationErrors: validationErrors });
      } else {
        delete validationErrors["side-1"];
        await this.setState({ validationErrors: validationErrors });
      }
    }
    if (e.target.name === "side-2" || action === "onSubmit") {
      if (this.state.formData.sides[1].text === "") {
        validationErrors["side-2"] = "The side field is required.";
        await this.setState({ validationErrors: validationErrors });
      } else if (this.state.formData.sides[1].text.length > 140) {
        validationErrors["side-2"] = "The maximum number of characters is 140.";
        await this.setState({ validationErrors: validationErrors });
      } else {
        delete validationErrors["side-2"];
        await this.setState({ validationErrors: validationErrors });
      }
    }

    if (e.target.name === "affiliateLink" || action === "onSubmit") {
      if (
        this.state.formData.affiliateLink !== null &&
        this.state.formData.affiliateLink !== "" &&
        !/^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-zA-Z0-9]+([-.]{1}[a-zA-Z0-9]+)*\.[a-zA-Z]{2,5}(:[0-9]{1,5})?(\/.*)?$/i.test(
          this.state.formData.affiliateLink,
        )
      ) {
        validationErrors["affiliateLink"] = "The affiliate link is invalid.";
        await this.setState({ validationErrors: validationErrors });
      } else {
        delete validationErrors["affiliateLink"];
        await this.setState({ validationErrors: validationErrors });
      }
    }

    if (
      Object.keys(this.state.validationErrors).length === 0 &&
      action === "onSubmit"
    ) {
      this.uploadDebateImages();
    }
  };

  changeHandler = async (e) => {
    var formData = { ...this.state.formData };
    formData[e.target.name] = e.target.value;
    await this.setState({ formData });
    console.log(this.state);
    this.formValidation(e);
  };

  sidesChangeHandler = async (e, sideId) => {
    var sideIndex = e.target.dataset.index;
    var formData = { ...this.state.formData };
    var sidesArray = formData.sides;
    sidesArray[sideIndex - 1] = {
      id: sideId,
      text: e.target.value,
    };
    formData.sides = sidesArray;
    await this.setState({ formData });
    this.formValidation(e);
  };

  datePickerCallback = async (e, name) => {
    var formData = { ...this.state.formData };
    formData[name] = e._d;
    await this.setState({ formData });
    if (
      moment(this.state.formData.startedAt).isAfter(this.state.formData.endedAt)
    ) {
      await this.setState({ resetEndDate: true });
      await this.setState({ resetEndDate: false });
    } else {
      await this.setState({ resetEndDate: false });
    }
  };

  fileChangeHandler = async (e) => {
    if (e.target.type === "file" && e.target.files[0]) {
      const dataAccept = e.target.getAttribute("data-accept")
        ? e.target.getAttribute("data-accept")
        : "jpg|jpeg|gif|png";
      const dataAcceptMsg = e.target.getAttribute("data-accept_msg")
        ? e.target.getAttribute("data-accept_msg")
        : "Image is invalid. Please upload only PNG, JPG or GIF image.";
      var regex = new RegExp("(.*?).(" + dataAccept + ")$");
      if (!regex.test(e.target.files[0].type)) {
        alert(dataAcceptMsg);
        return false;
      }
      if (
        e.target.name === "backgroundImage" &&
        e.target.files[0].size > 1024 * 10 * 1024
      ) {
        alert("The image size should not be greater than 10mb");
        return false;
      }
      var file = e.target.files[0];
      var thisEl = e;
      var reader = new FileReader();
      reader.onloadend = function (e) {
        var formData = { ...this.state.formData };
        var bgImageObj = {
          type: thisEl.target.dataset.imgtype,
          backgroundImage: thisEl.target.files[0],
        };

        formData.backgroundTempData = bgImageObj;
        formData[thisEl.target.name] = e.target.result;
        this.setState({ backgroundImageDisplay: e.target.result });
        this.setState({ formData });
      }.bind(this);
      reader.readAsDataURL(file);
    }
  };

  fileRemoveHandler = async (name, index) => {
    var formData = { ...this.state.formData };

    formData.backgroundTempData = "";
    formData[name] = "";
    this.setState({ backgroundImageDisplay: "" });
    await this.setState({ formData });
    console.log(this.state);
  };

  uploadDebateImages = async (e) => {
    let backgroundTempData = this.state.formData.backgroundTempData;
    var failure = false;

    if (backgroundTempData !== "") {
      var image_type = "DEBATES";
      const formData = new FormData();
      formData.append(
        "backgroundImage",
        backgroundTempData.backgroundImage,
        backgroundTempData.backgroundImage.name,
      );

      await axios
        .post(
          API_URL +
            "/debate/imageUpload?type=" +
            image_type +
            "&clientId=" +
            this.state.formData.clientId,
          formData,
        )
        .then((res) => {
          var formData = { ...this.state.formData };
          formData.backgroundImage = res.data.data;
          this.setState({ formData });

          console.log("Image uplaoded successfully!");
          console.log(this.state);
        })
        .catch((err) => {
          alert("Image upload for " + image_type + " Failed!");
          failure = true;
        });
    }

    if (failure === false) {
      this.formDataHandle();
    }
  };

  formDataHandle = async (e) => {
    const { formData, isQuiz } = this.state;
    var formDataBody = { ...formData };
    formDataBody["endedAt"] =
      formDataBody["endedAt"] == null
        ? moment().add(5, "minutes")._d
        : formDataBody["endedAt"];

    await axios
      .put(API_URL + "/debate/update/" + formData.id, formDataBody)
      .then((res) => {
        const debId = formData.id;
        if (formData.type === "create") {
          this.messageNotification(
            "success",
            `${isQuiz ? "Quiz" : "Poll"} published successfully!`,
          );
          setTimeout(() => {
            window.location.replace(BASENAME + "/debates/debate/" + debId);
          }, 1000);
        } else {
          this.networksList();
          this.fetchCurreDebate();
          setTimeout(() => {
            this.messageNotification("success", "Draft saved successfully!");
          }, 1000);
        }
      })
      .catch((err) => {
        if (err.response !== undefined) {
          alert("Error occurred.");
          console.log(err.response.data.message[0]);
        }
        this.setState({
          loading: false,
        });
      });
  };

  submitDetateDataHandle = async (e) => {
    var formData = { ...this.state.formData };
    formData["type"] = "create";
    await this.setState({ formData });
    await this.setState({
      loading: true,
    });
    this.formValidation(e, "onSubmit");
    e.preventDefault();
  };

  saveDraft = async (e) => {
    var formData = { ...this.state.formData };
    formData["type"] = "draft";
    await this.setState({ formData });
    await this.setState({
      loading: true,
    });
    this.formValidation(e, "onSubmit");
    e.preventDefault();
  };

  messageNotification = async (type, message) => {
    this.setState({
      successMsg: message,
    });

    setTimeout(() => {
      this.setState({
        successMsg: "",
      });
    }, 5000);
  };

  copyEmbedCode = async () => {
    await axios
      .get(API_URL + "/debate/getEmbed/" + this.props.match.params.id)
      .then((res) => {
        this.setState({ embedCode: res.data.data.html });
        console.log(res.data.data);
      })
      .catch((err) => {
        console.log(err.response.data.message);
      });
  };

  showAdditionaInfoModal = async (e) => {
    this.setState({ showAdditionaInfoModal: true });
  };

  hideAdditionaInfoModal = async (e) => {
    this.setState({ showAdditionaInfoModal: false });
    var formData = { ...this.state.formData };
    formData["backgroundTemp"] = "";
    await this.setState({ formData });
  };

  commitAdditionalInfo = async () => {
    this.setState({ showAdditionaInfoModal: false });
    var formData = { ...this.state.formData };
    formData["background"] = formData["backgroundTemp"];
    formData["backgroundTemp"] = "";
    await this.setState({ formData });
  };

  removeBackgroundText = async () => {
    var formData = { ...this.state.formData };
    formData["background"] = "";
    await this.setState({ formData });
  };

  removeCustomCTA = async () => {
    var formData = { ...this.state.formData };
    formData["affiliateLink"] = "";
    formData["affiliateButtonText"] = "";
    await this.setState({ formData, CTAClicked: false });
  };

  toggleSideElField = async (e = "", target = "", sideIndex = "") => {
    await this.setState({ linkTempVar: null, tagTempVar: null });

    var toggleSideLinkField = { ...this.state.toggleSideLinkField };
    var toggleSideTagField = { ...this.state.toggleSideTagField };
    toggleSideLinkField = {
      [sideIndex]:
        target === "link" &&
        (toggleSideLinkField[sideIndex] === undefined ||
          toggleSideLinkField[sideIndex] === false)
          ? true
          : false,
    };
    toggleSideTagField = {
      [sideIndex]:
        target === "tag" &&
        (toggleSideTagField[sideIndex] === undefined ||
          toggleSideTagField[sideIndex] === false)
          ? true
          : false,
    };
    await this.setState({ toggleSideLinkField, toggleSideTagField });
  };

  handleSideElFieldChange = async (e, target) => {
    if (target === "link") {
      await this.setState({ linkTempVar: e.target.value });
    } else if (target === "tag") {
      await this.setState({ tagTempVar: e.target.value });
    } else {
    }
    console.log(this.state);
  };

  sidesLinkCommitHandler = async (e) => {
    var sideIndex = e.target.dataset.index;

    if (
      this.state.linkTempVar.trim() !== "" &&
      !/^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-zA-Z0-9]+([-.]{1}[a-zA-Z0-9]+)*\.[a-zA-Z]{2,5}(:[0-9]{1,5})?(\/.*)?$/i.test(
        this.state.linkTempVar.trim(),
      )
    ) {
      alert("Please enter valid URL.");
      return false;
    }

    var formData = { ...this.state.formData };
    var sidesArray = formData.sides;
    sidesArray[sideIndex - 1] = {
      ...sidesArray[sideIndex - 1],
      link: this.state.linkTempVar.trim(),
    };
    formData.sides = sidesArray;
    await this.setState({ formData });
    this.toggleSideElField(e, "link");
  };

  sidesTagCommitHandler = async (e) => {
    var sideIndex = e.target.dataset.index;

    if (
      this.state.tagTempVar.trim() === null ||
      this.state.tagTempVar.trim() === ""
    ) {
      alert("This field is required.");
      return false;
    }

    var formData = { ...this.state.formData };
    var sidesArray = formData.sides;
    if (sidesArray[sideIndex - 1].tags === undefined) {
      sidesArray[sideIndex - 1] = {
        ...sidesArray[sideIndex - 1],
        tags: [],
      };
    }

    var tagsObj = {
      text: this.state.tagTempVar.trim(),
    };
    sidesArray[sideIndex - 1].tags.push(tagsObj);

    formData.sides = sidesArray;
    await this.setState({ formData, tagTempVar: null });
  };

  removeSideElField = async (
    e = "",
    target = "",
    sideIndex = "",
    tagIndex = "",
  ) => {
    var formData = { ...this.state.formData };

    if (target === "link") {
      delete formData.sides[sideIndex].link;
      await await this.setState({ formData });
    }
    if (target === "tag") {
      delete formData.sides[sideIndex].tags.splice(tagIndex, 1);
      await await this.setState({ formData });
    }
  };

  triggerImageInputClick = async (e) => {
    if (this.hiddenImageInput !== undefined) {
      this.hiddenImageInput.click();
    }
  };

  handleCustomDropdownChange = async (e, authorId) => {
    e.preventDefault();
    var formData = { ...this.state.formData };
    formData[e.target.name] = authorId;
    await this.setState({ formData });
    this.formValidation(e);
  };

  render() {
    const {
      formData,
      isQuiz,
      addSideLink,
      removeSideLink,
      currentDebateData,
      networksList,
      authorsList,
      categoriesList,
      validationErrors,
      loading,
      backgroundImageDisplay,
      toggleSideLinkField,
      toggleSideTagField,
      linkTempVar,
      tagTempVar,
      sourcePageUrl
    } = this.state;

    if (currentDebateData.isDraft !== 1) {
      return (
        <section>
          <NavLink
            exact
            className="mb-3 customAnchor d-inline-block"
            to={`/${isQuiz ? "quizzes" : ""}`}
          >
            &nbsp;<i className="fa fa-angle-left"></i> Your{" "}
            {isQuiz ? "Quizzes" : "Polls"}
          </NavLink>
          <h3 className="text-center">
            This is not a draft or does not exists.
          </h3>
        </section>
      );
    }

    const {
      thesis,
      userId,
      sides,
      categoryId,
      background,
      startedAt,
      endedAt,
      clientId,
      affiliateLink,
      affiliateButtonText,
      noEndDate,
      backgroundTemp,
    } = formData;
    var endedAtMinDate = moment(startedAt).add(5, "minutes");
    var finalDifference = null;

    return (
      <section className="">
        {this.state.successMsg ? (
          <div className="sucessMsg">{this.state.successMsg}</div>
        ) : null}

        {loading === false ? (
          <NavLink
            exact
            className="mb-3 customAnchor d-inline-block"
            to={`/${isQuiz ? "quizzes" : ""}`}
          >
            <i className="fa fa-angle-left"></i>
            &nbsp;Your {isQuiz ? "Quizzes" : "Polls"}
          </NavLink>
        ) : (
          <div className="loadingBarWrap">
            <div className="loadingBar"></div>
          </div>
        )}

        <h3 className="mb-3">EDIT DRAFT</h3>
        <Tabs
          defaultActiveKey="poll-detail"
          id="uncontrolled-tab-example"
          className="mt-4 mb-3 CreateDebateTabs"
          onSelect={this.handleSelect}
        >
          <Tab
            eventKey="poll-detail"
            title={`${isQuiz ? "Quiz" : "Poll"} Details`}
          >
            <div
              className={
                loading === true
                  ? "loading justify-content-center"
                  : "justify-content-center"
              }
            >
              <Form
                className="startDebate common-form row"
                onSubmit={this.submitDetateDataHandle}
              >
                <Col md={7} className="pe-3">
                  <Form.Group
                    controlId="clientId"
                    className="pt-0 hiddenElement"
                  >
                    <Form.Label>Network</Form.Label>
                    <div className="message joinedNetworksWrap">
                      {networksList && networksList.length
                        ? networksList.map((el, i) =>
                            el.id === parseInt(clientId) ? (
                              <label key={i} className="sub-label">
                                <input
                                  ref={(input) =>
                                    (this.selectedInputElement = input)
                                  }
                                  data-required="true"
                                  onChange={(e) => {
                                    this.fetchCats(
                                      e,
                                      el.extra
                                        ? el.extra.defaultDebateDuration
                                        : null,
                                      el.extra
                                        ? el.extra.maxDebateDuration
                                        : null,
                                      el.siteDomain,
                                    );
                                  }}
                                  type="radio"
                                  name="clientId"
                                  id="clientId"
                                  value={el.id}
                                />
                                <span
                                  key={i}
                                  className=" joinedNetworks"
                                  data-clientid={`client-${el.id}`}
                                >
                                  <img
                                    src={el.header.favicon}
                                    alt={el.name}
                                    onError={(e) => {
                                      e.target.src = DEFAULT_USER_IMG_URL;
                                    }}
                                  />
                                  {el.header.title}
                                </span>
                              </label>
                            ) : null,
                          )
                        : null}

                      {networksList && networksList.length
                        ? networksList.map((el, i) =>
                            el.id !== parseInt(clientId) ? (
                              <label key={i} className="sub-label">
                                <input
                                  disabled
                                  data-required="true"
                                  onChange={(e) => {
                                    this.fetchCats(
                                      e,
                                      el.extra.defaultDebateDuration,
                                      el.extra.maxDebateDuration,
                                      el.siteDomain,
                                    );
                                  }}
                                  type="radio"
                                  name="clientId"
                                  id="clientId"
                                  value={el.id}
                                />
                                <span
                                  key={i}
                                  className=" joinedNetworks"
                                  data-clientid={`client-${el.id}`}
                                >
                                  <img
                                    src={el.header.favicon}
                                    alt={el.name}
                                    onError={(e) => {
                                      e.target.src = DEFAULT_USER_IMG_URL;
                                    }}
                                  />
                                  {el.header.title}
                                </span>
                              </label>
                            ) : null,
                          )
                        : null}
                    </div>
                    {validationErrors &&
                    validationErrors.clientId !== undefined ? (
                      <span className="validation-error">
                        {validationErrors.clientId}
                      </span>
                    ) : null}
                  </Form.Group>

                  <Form.Group controlId="thesis" className="pt-0">
                    <Form.Label>
                      {isQuiz ? "Quiz Question" : "Poll Question or Statement"}
                    </Form.Label>
                    <Form.Control
                      data-required="true"
                      type="text"
                      onChange={this.changeHandler}
                      name="thesis"
                      value={thesis}
                      placeholder="Type statement or questions here..."
                      tabindex={1}
                    />
                    {validationErrors &&
                    validationErrors.thesis !== undefined ? (
                      <span className="validation-error">
                        {validationErrors.thesis}
                      </span>
                    ) : null}
                  </Form.Group>
                  <Form.Group className="pt-0">
                    <Form.Label>
                      {isQuiz
                        ? "Type your answers and select the correct option(s) using the checkbox(s) to the left"
                        : "Your Sides"}
                    </Form.Label>
                    {sides && sides.length
                      ? sides.map((el, i) => (
                          <div className="side" key={i}>
                            <div className="message">
                              <div className="row-1 d-flex align-items-center">
                                <div className="position-relative w-100">
                                  <Form.Control
                                    style={{
                                      borderColor:
                                        SIDES_COLORS[`side-${i + 1}`],
                                    }}
                                    data-required={i < 2 ? "true" : "false"}
                                    data-index={i + 1}
                                    type="text"
                                    onChange={(e) => this.sidesChangeHandler(e)}
                                    placeholder="Type your side here"
                                    name={`side-${i + 1}`}
                                    value={el.text}
                                    className={
                                      removeSideLink === true
                                        ? "removeBtnOn"
                                        : null
                                    }
                                    tabindex={2}
                                  />
                                  <div className="side-inline-items d-flex align-items-center">
                                    <span className="lightColorSpan">{`Side ${i + 1}`}</span>
                                    {removeSideLink === true ? (
                                      <Button
                                        size="sm"
                                        variant="secondary-light"
                                        onClick={(e) => {
                                          this.removeSideLinkHandle(i);
                                        }}
                                        className="delete-button ms-2"
                                      >
                                        Remove
                                      </Button>
                                    ) : null}
                                  </div>
                                </div>
                                <Button
                                  disabled={el.link ? true : false}
                                  onClick={(e) =>
                                    this.toggleSideElField(e, "link", i)
                                  }
                                  size="sm"
                                  variant="secondary-light"
                                  className="side-link ms-2"
                                >
                                  <FontAwesomeIcon
                                    icon={faLink}
                                    className="procheckmark"
                                  />
                                </Button>
                                <Button
                                  onClick={(e) =>
                                    this.toggleSideElField(e, "tag", i)
                                  }
                                  size="sm"
                                  variant="secondary-light"
                                  className="side-tag ms-2"
                                >
                                  <FontAwesomeIcon
                                    icon={faTag}
                                    className="procheckmark flip"
                                  />
                                  +
                                </Button>
                              </div>
                              {el.link || el.tags ? (
                                <div className="row-2 mt-2">
                                  {el.link ? (
                                    <span className="sideLink">
                                      <FontAwesomeIcon
                                        icon={faLink}
                                        className="procheckmark"
                                      />
                                      <a
                                        className="d-inline"
                                        href={
                                          el.link.includes("http")
                                            ? el.link
                                            : "//" + el.link
                                        }
                                        target="_blank"
                                        rel="noreferrer"
                                      >
                                        {el.link}
                                      </a>
                                      <FontAwesomeIcon
                                        onClick={(e) =>
                                          this.removeSideElField(e, "link", i)
                                        }
                                        role="button"
                                        icon={faTimesCircle}
                                        className="procheckmark flip"
                                      />
                                    </span>
                                  ) : (
                                    ""
                                  )}
                                  {el.tags && el.tags.length
                                    ? el.tags.map((elt, tagIndex) => (
                                        <span
                                          key={tagIndex}
                                          className="sideTags"
                                        >
                                          <FontAwesomeIcon
                                            icon={faTag}
                                            className="procheckmark flip"
                                          />
                                          {elt.text}
                                          <FontAwesomeIcon
                                            onClick={(e) =>
                                              this.removeSideElField(
                                                e,
                                                "tag",
                                                i,
                                                tagIndex,
                                              )
                                            }
                                            role="button"
                                            icon={faTimesCircle}
                                            className="procheckmark flip"
                                          />
                                        </span>
                                      ))
                                    : null}
                                </div>
                              ) : null}
                              {toggleSideLinkField[i] ||
                              toggleSideTagField[i] ? (
                                <div className="row-3 mt-2" test={i}>
                                  {toggleSideLinkField[i] ? (
                                    <div className="add-link-tag-wrapper position-relative">
                                      <Form.Control
                                        value={linkTempVar ? linkTempVar : ""}
                                        onChange={(e) =>
                                          this.handleSideElFieldChange(
                                            e,
                                            "link",
                                          )
                                        }
                                        type="text"
                                        name=""
                                      />
                                      <div className="sideElementsButtons">
                                        <Button
                                          onClick={(e) =>
                                            this.toggleSideElField(e, "link", i)
                                          }
                                          className="me-1"
                                          size="sm"
                                          variant="secondary-light"
                                        >
                                          Cancel
                                        </Button>
                                        <Button
                                          data-index={i + 1}
                                          onClick={this.sidesLinkCommitHandler}
                                          className=""
                                          size="sm"
                                          variant="primary"
                                        >
                                          Add
                                        </Button>
                                      </div>
                                      {linkTempVar ? null : (
                                        <FontAwesomeIcon
                                          icon={faLink}
                                          className="procheckmark"
                                        />
                                      )}
                                    </div>
                                  ) : null}

                                  {toggleSideTagField[i] ? (
                                    <div className="add-link-tag-wrapper position-relative">
                                      <Form.Control
                                        value={tagTempVar ? tagTempVar : ""}
                                        onChange={(e) =>
                                          this.handleSideElFieldChange(e, "tag")
                                        }
                                        type="text"
                                        name=""
                                      />
                                      <div className="sideElementsButtons">
                                        <Button
                                          onClick={(e) =>
                                            this.toggleSideElField(e, "tag", i)
                                          }
                                          className="me-1"
                                          size="sm"
                                          variant="secondary-light"
                                        >
                                          Cancel
                                        </Button>
                                        <Button
                                          data-index={i + 1}
                                          onClick={this.sidesTagCommitHandler}
                                          className=""
                                          size="sm"
                                          variant="primary"
                                        >
                                          Add
                                        </Button>
                                      </div>
                                      {tagTempVar ? null : (
                                        <FontAwesomeIcon
                                          icon={faTag}
                                          className="flip procheckmark"
                                        />
                                      )}
                                    </div>
                                  ) : null}
                                </div>
                              ) : null}
                            </div>
                            {validationErrors &&
                            validationErrors[`side-${i + 1}`] !== undefined ? (
                              <span className="validation-error">
                                {validationErrors[`side-${i + 1}`]}
                              </span>
                            ) : null}
                          </div>
                        ))
                      : null}
                    {addSideLink === true ? (
                      <Button
                        className="mt-1 customBtn p-2 ps-4 pe-4"
                        variant="outline-primary"
                        onClick={this.addNewSideHandle}
                      >
                        + Add Side
                      </Button>
                    ) : null}
                  </Form.Group>

                  <Form.Group controlId="sourcePageUrl" className="mt-md-4">
                    <Form.Label>Source Page</Form.Label>
                    <Form.Control
                      data-required="true"
                      type="text"
                      onChange={this.changeHandler}
                      name="sourcePageUrl"
                      value={sourcePageUrl}
                      placeholder="Enter the url of the page this poll matches"
                      tabIndex={1}
                    />
                    {validationErrors &&
                    validationErrors.sourcePageUrl !== undefined ? (
                      <span className="validation-error">
                        {validationErrors.sourcePageUrl}
                      </span>
                    ) : null}
                  </Form.Group>

                  <Row className="mt-4">
                    <Col md={4} className="">
                      <Form.Group controlId="" className="">
                        <Form.Label>
                          Include {isQuiz ? "Quiz" : "Poll"} Image{" "}
                          <span className="lightColorSpan">(Optional)</span>
                        </Form.Label>
                        <span className="lightColorSpan d-block mb-2 small">
                          Recommended 270 x 134 px
                        </span>
                        <div className="img-field-wrap">
                          {backgroundImageDisplay !== "" ? (
                            <div className="field-backgroundImage">
                              <img
                                alt="backgroundImage"
                                src={backgroundImageDisplay}
                              />
                            </div>
                          ) : (
                            <Button
                              onClick={this.triggerImageInputClick}
                              className="position-absolute customBtn p-2 ps-4 pe-4"
                              variant="outline-primary"
                            >
                              Add Image
                            </Button>
                          )}
                          <Form.Control
                            ref={(input) => (this.hiddenImageInput = input)}
                            className="d-none h-50"
                            data-index="0"
                            data-imgtype="DEBATES"
                            type="file"
                            name="backgroundImage"
                            onChange={this.fileChangeHandler}
                            data-accept="jpg|jpeg|png"
                            data-accept_msg="Image is invalid. Please upload only PNG or JPG image."
                          />
                        </div>
                        {backgroundImageDisplay !== "" ? (
                          <div className="d-flex mt-2">
                            <Button
                              onClick={this.triggerImageInputClick}
                              className="w-50 me-1"
                              size="sm"
                              variant="outline-primary"
                            >
                              Change
                            </Button>
                            <Button
                              onClick={() =>
                                this.fileRemoveHandler("backgroundImage", "0")
                              }
                              className="w-50"
                              size="sm"
                              variant="outline-primary"
                            >
                              Remove
                            </Button>
                          </div>
                        ) : null}
                      </Form.Group>
                    </Col>
                    <Col md={4} className="">
                      <Form.Group controlId="" className="">
                        <Form.Label>
                          Additional Info{" "}
                          <span className="lightColorSpan">(Optional)</span>
                        </Form.Label>
                        <span className="lightColorSpan d-block mb-2 small">
                          Add more info about your {isQuiz ? "quiz" : "poll"}.
                        </span>
                        {background ? (
                          <>
                            <textarea
                              rows="4"
                              className="form-control"
                              disabled
                              value={background}
                            ></textarea>
                            <div className="d-flex mt-2">
                              <Button
                                onClick={this.showAdditionaInfoModal}
                                className="w-50 me-1"
                                size="sm"
                                variant="outline-primary"
                              >
                                Change
                              </Button>
                              <Button
                                onClick={this.removeBackgroundText}
                                className="w-50"
                                size="sm"
                                variant="outline-primary"
                              >
                                Remove
                              </Button>
                            </div>
                          </>
                        ) : (
                          <Button
                            onClick={this.showAdditionaInfoModal}
                            className="customBtn p-2 ps-4 pe-4"
                            variant="outline-primary"
                          >
                            Add Info
                          </Button>
                        )}
                      </Form.Group>
                    </Col>
                    <Col md={4} className="">
                      <Form.Group controlId="" className="">
                        <Form.Label>
                          Custom CTA{" "}
                          <span className="lightColorSpan">(Optional)</span>
                        </Form.Label>
                        <span className="lightColorSpan d-block mb-2 small">
                          Customize CTA shown with results.
                        </span>
                        {this.state.CTAClicked || affiliateLink ? (
                          <Form.Group controlId="affiliate" className="pt-0">
                            <Form.Control
                              className=""
                              data-required="true"
                              type="text"
                              onChange={this.changeHandler}
                              name="affiliateLink"
                              value={affiliateLink}
                              placeholder="Enter affiliate link here"
                            />
                            {validationErrors &&
                            validationErrors.affiliateLink !== undefined ? (
                              <span className="validation-error">
                                {validationErrors.affiliateLink}
                              </span>
                            ) : null}
                            <Form.Control
                              className="mt-2"
                              data-required="true"
                              type="text"
                              onChange={this.changeHandler}
                              name="affiliateButtonText"
                              value={affiliateButtonText}
                              placeholder="Enter button text"
                            />
                            <div className="txtCenter mt-2">
                              <Button
                                onClick={this.removeCustomCTA}
                                className="w-50"
                                size="sm"
                                variant="outline-primary"
                              >
                                Remove
                              </Button>
                            </div>
                          </Form.Group>
                        ) : (
                          <Button
                            className="customBtn p-2 ps-4 pe-4"
                            variant="outline-primary"
                            onClick={() => this.setState({ CTAClicked: true })}
                          >
                            Add CTA
                          </Button>
                        )}
                      </Form.Group>
                    </Col>
                  </Row>
                </Col>
                <Col md={5} className="ps-md-5">
                  <div className="buttons mb-4 mt-5 d-none d-md-flex justify-content-end">
                    {currentDebateData.isDraft === 1 ? (
                      <span
                        onClick={(e) => this.saveDraft(e)}
                        className="btn btn-secondary mt-0 ms-0"
                      >
                        Save Draft
                      </span>
                    ) : (
                      <NavLink
                        className="btn btn-secondary mt-0 ms-0"
                        exact
                        activeClassName="active"
                        to="/"
                      >
                        Cancel
                      </NavLink>
                    )}
                    <Button
                      variant="primary"
                      className="mt-0 me-0"
                      type="submit"
                    >
                      Publish
                    </Button>
                  </div>

                  {currentDebateData.isDraft === 1 ? (
                    <Form.Label className="pt-0">
                      Draft saved on{" "}
                      <span className="lightColorSpan">
                        {currentDebateData.createdAt
                          ? moment(currentDebateData.createdAt).format(
                              "M/DD/YY @ hh:mm A",
                            )
                          : null}
                      </span>
                    </Form.Label>
                  ) : (
                    <Form.Label className="pt-0">
                      Published on{" "}
                      <span className="lightColorSpan">
                        {startedAt
                          ? moment(startedAt).format("M/DD/YY @ hh:mm A")
                          : null}
                      </span>
                    </Form.Label>
                  )}
                  <div className="inline-content top">
                    <Form.Group controlId="startedAt" className="me-3">
                      <Form.Label>Start Date </Form.Label>
                      <div className="datePickerWrapper">
                        {startedAt !== null ? (
                          <>
                            <DateRangePicker
                              onCallback={(e) =>
                                this.datePickerCallback(e, "startedAt")
                              }
                              initialSettings={{
                                singleDatePicker: true,
                                minDate: moment(),
                                startDate: moment(startedAt)["_d"],
                                timePicker: true,
                                locale: {
                                  format: "MM/DD/YY @ hh:mmA",
                                },
                              }}
                            >
                              <input
                                type="text"
                                name="startedAt"
                                className="form-control datepickerField"
                              />
                            </DateRangePicker>
                            <i className="fa fa-calendar"></i>
                          </>
                        ) : null}
                      </div>
                    </Form.Group>

                    {this.state.resetEndDate !== true ? (
                      <Form.Group controlId="endedAt" className="">
                        <Form.Label>End Date</Form.Label>
                        <div className="datePickerWrapper">
                          {startedAt !== null ? (
                            <>
                              <DateRangePicker
                                onCallback={(e) =>
                                  this.datePickerCallback(e, "endedAt")
                                }
                                initialSettings={{
                                  singleDatePicker: true,
                                  minDate: endedAtMinDate,
                                  startDate:
                                    endedAt !== null
                                      ? moment(endedAt)["_d"]
                                      : moment().add(5, "minutes"),
                                  /*maxDate: endedAtMaxDate,*/
                                  timePicker: true,
                                  locale: {
                                    format: "MM/DD/YY @ hh:mmA",
                                  },
                                }}
                              >
                                <input
                                  type="text"
                                  name="endedAt"
                                  className={`form-control datepickerField ${parseInt(noEndDate) === 1 ? "disabledInput" : ""}`}
                                />
                              </DateRangePicker>
                              <i className="fa fa-calendar"></i>
                            </>
                          ) : null}
                        </div>

                        <div className="noEndDate">
                          <Form.Check.Label className="mb-0 mt-2">
                            <Form.Check.Input
                              className="m-0 me-2 "
                              checked={parseInt(noEndDate)}
                              onChange={this.changeHandler}
                              name="noEndDate"
                              value={parseInt(noEndDate) === 1 ? 0 : 1}
                            />
                            No end date
                          </Form.Check.Label>
                        </div>
                      </Form.Group>
                    ) : null}
                  </div>

                  <div className="row">
                    <Form.Group
                      controlId="PollAuthor"
                      className="col-md-6 pe-2"
                    >
                      <Form.Label>{isQuiz ? "Quiz" : "Poll"} Author</Form.Label>
                      {/*<Form.Control className="userId" as="select" data-required="true" onChange={this.changeHandler } name="userId" value={userId}>
												<option value="" data-id="">-- Select --</option>
												{
													authorsList && authorsList.length ? 
														authorsList.map((el, i) => (
								                  				<option key={i} data-index={i} value={el.id} data-id={el.id}>{el.name}</option>              
														))
														: <option value="">No author available</option>
												}
											</Form.Control>*/}

                      <DropdownButton
                        className="customDropdown"
                        id="dropdown-item-button"
                        title={
                          authorsList &&
                          authorsList.find((item) => item.id === userId)
                            ? authorsList.find((item) => item.id === userId)
                                .name
                            : currentDebateData
                              ? currentDebateData.user.name
                              : "Select Author"
                        }
                      >
                        {authorsList && authorsList.length ? (
                          authorsList.map((el, i) => (
                            <Dropdown.Item
                              key={i}
                              name="userId"
                              onClick={(e) =>
                                this.handleCustomDropdownChange(e, el.id)
                              }
                              as="button"
                            >
                              {el.name}
                            </Dropdown.Item>
                          ))
                        ) : (
                          <Dropdown.Item>No author available</Dropdown.Item>
                        )}
                      </DropdownButton>
                    </Form.Group>

                    <Form.Group controlId="PollCat" className="col-md-6 ps-2">
                      <Form.Label>
                        {isQuiz ? "Quiz" : "Poll"} Category (Optional)
                      </Form.Label>
                      {/*<Form.Control className="categoryId" as="select" data-required="true" onChange={this.changeHandler} name="categoryId" value={categoryId}>
												<option value="" data-id="">-- Select --</option>
												{
													categoriesList && categoriesList.length ? 
														categoriesList.map((el, i) => (
								                  				<option key={i} value={el.id} data-id={el.id}>{el.text}</option>              
														))
														: 
														<option value="">Select a Network first</option>
												}
											</Form.Control>*/}

                      <DropdownButton
                        className="customDropdown"
                        id="dropdown-item-button"
                        title={
                          categoriesList &&
                          categoriesList.find((item) => item.id === categoryId)
                            ? categoriesList.find(
                                (item) => item.id === categoryId,
                              ).text
                            : "Select Category"
                        }
                      >
                        {categoriesList && categoriesList.length ? (
                          categoriesList.map((el, i) => (
                            <Dropdown.Item
                              key={i}
                              name="categoryId"
                              onClick={(e) =>
                                this.handleCustomDropdownChange(e, el.id)
                              }
                              as="button"
                            >
                              {el.text}
                            </Dropdown.Item>
                          ))
                        ) : (
                          <Dropdown.Item>No category available</Dropdown.Item>
                        )}
                      </DropdownButton>
                    </Form.Group>
                  </div>

                  <Form.Group controlId="" className="pb-2">
                    <div className="inline-content middle">
                      <Form.Label className="m-0">
                        {isQuiz ? "Quiz" : "Poll"} Preview
                      </Form.Label>
                      {currentDebateData ? (
                        <a
                          className="customAnchor"
                          href={currentDebateData.debateUrl}
                          target="_blank"
                          rel="noreferrer"
                        >
                          View on Sided
                        </a>
                      ) : null}
                    </div>
                  </Form.Group>
                  <div className="debatePreviewSection">
                    <div className="debatePreviewInner">
                      <div className="debatePreviewHeader">
                        {currentDebateData ? (
                          <div className="author">
                            <a
                              target="_blank"
                              rel="noreferrer"
                              className="small-dp user-color-green"
                              href={"/" + currentDebateData.user.username}
                            >
                              <img
                                style={{
                                  borderColor:
                                    currentDebateData.user.statusColor,
                                }}
                                data-src=""
                                src={
                                  currentDebateData.user.avatarObject
                                    ? currentDebateData.user.avatarObject.small
                                        .location
                                    : DEFAULT_USER_IMG_URL
                                }
                                alt={currentDebateData.user.name}
                                className="sidedLazyLoad img-circle avatar"
                                onError={(e) => {
                                  e.target.src = DEFAULT_USER_IMG_URL;
                                }}
                              />

                              {currentDebateData.user.roles.length > 0 &&
                              currentDebateData.user.roles.find(
                                (role) => role.name === "VERIFIED",
                              ) ? (
                                <FontAwesomeIcon
                                  style={{
                                    color:
                                      currentDebateData.user.checkmarkColor,
                                  }}
                                  icon={faCheckCircle}
                                  className="procheckmark"
                                />
                              ) : null}
                            </a>
                            <div className="copy-wrap">
                              <a
                                target="_blank"
                                rel="noreferrer"
                                className="authorname"
                                href={"/" + currentDebateData.user.username}
                              >
                                <h6>{currentDebateData.user.name}</h6>
                              </a>
                              <span className="authorhandle">
                                <span className="handler">
                                  Posted{" "}
                                  {moment(startedAt).format("MMM DD, YYYY")}
                                </span>
                              </span>
                            </div>
                          </div>
                        ) : null}
                      </div>

                      {backgroundImageDisplay !== "" ? (
                        <div className="debatePreviewBgImg mt-3">
                          <img
                            alt="backgroundImage"
                            src={backgroundImageDisplay}
                          />
                        </div>
                      ) : null}

                      <div className="debatePreviewTitle mt-3">
                        {thesis !== "" ? (
                          <h5>{thesis}</h5>
                        ) : (
                          <>
                            <p></p>
                            <p></p>
                            <p></p>
                          </>
                        )}
                      </div>

                      {!parseInt(noEndDate) ? (
                        <>
                          {this.state.currentDebateData.isClosed ? (
                            <span className="lightColorSpan mt-2 d-block">{`${isQuiz ? "Quiz" : "Poll"} ended ${moment(endedAt).format("MMM DD, YYYY")}`}</span>
                          ) : (
                            <span className="lightColorSpan mt-2 d-block">{`${isQuiz ? "Quiz" : "Poll"} ends in ${finalDifference ? finalDifference : ""} • Vote below`}</span>
                          )}
                        </>
                      ) : (
                        <span className="lightColorSpan mt-2 d-block">
                          Vote below
                        </span>
                      )}

                      <div className="debatePreviewSides mt-2">
                        {sides && sides.length
                          ? sides.map((elSides, i) => (
                              <div className="side pb-1" key={elSides.id}>
                                <div className="message1 position-relative">
                                  {!isNaN(
                                    (
                                      (elSides.votes * 100) /
                                      currentDebateData.votes
                                    ).toFixed(2),
                                  ) ? (
                                    <div
                                      className="percentageBar"
                                      style={{
                                        width:
                                          (elSides.votes * 100) /
                                            currentDebateData.votes +
                                          "%",
                                        background: elSides.sideColor + "26",
                                      }}
                                    ></div>
                                  ) : null}
                                  <div
                                    className="topDebateSides"
                                    style={{
                                      borderColor: elSides.sideColor,
                                      background: elSides.sideColor + "10",
                                    }}
                                  >
                                    {elSides.text}
                                  </div>
                                  {!isNaN(
                                    (
                                      (elSides.votes * 100) /
                                      currentDebateData.votes
                                    ).toFixed(2),
                                  ) ? (
                                    <span className="votesPercentage">
                                      {(
                                        (elSides.votes * 100) /
                                        currentDebateData.votes
                                      ).toFixed(2)}
                                      %
                                      {elSides.link ? (
                                        <a
                                          target="_blank"
                                          rel="noreferrer"
                                          href={
                                            elSides.link.includes("http")
                                              ? elSides.link
                                              : "//" + elSides.link
                                          }
                                        >
                                          <svg
                                            className="ms-1"
                                            width="15"
                                            height="15"
                                            viewBox="0 0 15 15"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <path
                                              d="M15 7.5C15 9.48912 14.2098 11.3968 12.8033 12.8033C11.3968 14.2098 9.48912 15 7.5 15C6.51509 15 5.53982 14.806 4.62987 14.4291C3.71993 14.0522 2.89314 13.4997 2.1967 12.8033C0.790176 11.3968 0 9.48912 0 7.5C0 5.51088 0.790176 3.60322 2.1967 2.1967C3.60322 0.790176 5.51088 0 7.5 0C8.48491 0 9.46018 0.193993 10.3701 0.570904C11.2801 0.947814 12.1069 1.50026 12.8033 2.1967C13.4997 2.89314 14.0522 3.71993 14.4291 4.62987C14.806 5.53982 15 6.51509 15 7.5ZM3 8.25H9L6.375 10.875L7.44 11.94L11.88 7.5L7.44 3.06L6.375 4.125L9 6.75H3V8.25Z"
                                              fill="#2F80ED"
                                            />
                                          </svg>
                                        </a>
                                      ) : null}
                                    </span>
                                  ) : (
                                    <span className="votesPercentage">
                                      {elSides.link ? (
                                        <a
                                          rel="noreferrer"
                                          target="_blank"
                                          href={
                                            elSides.link.includes("http")
                                              ? elSides.link
                                              : "//" + elSides.link
                                          }
                                        >
                                          <svg
                                            className="ms-1"
                                            width="15"
                                            height="15"
                                            viewBox="0 0 15 15"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <path
                                              d="M15 7.5C15 9.48912 14.2098 11.3968 12.8033 12.8033C11.3968 14.2098 9.48912 15 7.5 15C6.51509 15 5.53982 14.806 4.62987 14.4291C3.71993 14.0522 2.89314 13.4997 2.1967 12.8033C0.790176 11.3968 0 9.48912 0 7.5C0 5.51088 0.790176 3.60322 2.1967 2.1967C3.60322 0.790176 5.51088 0 7.5 0C8.48491 0 9.46018 0.193993 10.3701 0.570904C11.2801 0.947814 12.1069 1.50026 12.8033 2.1967C13.4997 2.89314 14.0522 3.71993 14.4291 4.62987C14.806 5.53982 15 6.51509 15 7.5ZM3 8.25H9L6.375 10.875L7.44 11.94L11.88 7.5L7.44 3.06L6.375 4.125L9 6.75H3V8.25Z"
                                              fill="#2F80ED"
                                            />
                                          </svg>
                                        </a>
                                      ) : null}
                                    </span>
                                  )}
                                </div>
                              </div>
                            ))
                          : null}
                      </div>

                      {affiliateLink ? (
                        <div className="debateAffiliateBtn mt-2">
                          <a
                            target="_blank"
                            rel="noreferrer"
                            className="btn btn-outline-primary btn-sm w-100"
                            href={
                              affiliateLink.includes("http")
                                ? affiliateLink
                                : "//" + affiliateLink
                            }
                          >
                            {affiliateButtonText}
                          </a>
                        </div>
                      ) : null}

                      <div class="inline-content middle mt-3">
                        <span className="lightColorSpan d-block">
                          {currentDebateData.votes} Vote
                          {currentDebateData.votes !== 1 ? "s" : ""} •{" "}
                          {currentDebateData.comments} Comment
                          {currentDebateData.comments !== 1 ? "s" : ""}{" "}
                        </span>
                        <span className="customAnchor">Share</span>
                      </div>
                      <div className="debatePreviewFooter mt-3">
                        <span className="customAnchor">
                          Embed {isQuiz ? "Quiz" : "Poll"}
                        </span>
                        <img alt="Logo" src={logo} className="logo" />
                      </div>
                    </div>
                  </div>
                  <div className="buttons mb-4 mt-5 d-md-none d-flex justify-content-end">
                    {currentDebateData.isDraft === 1 ? (
                      <span
                        onClick={(e) => {
                          this.saveDraft(e);
                        }}
                        className="btn btn-secondary mt-0 ms-0"
                      >
                        Save Draft
                      </span>
                    ) : (
                      <NavLink
                        className="btn btn-secondary mt-0 ms-0"
                        exact
                        activeClassName="active"
                        to="/"
                      >
                        Cancel
                      </NavLink>
                    )}
                    <Button
                      variant="primary"
                      className="mt-0 me-0"
                      type="submit"
                    >
                      Publish
                    </Button>
                  </div>
                </Col>
              </Form>
            </div>
            <Modal
              className="debateAdditionalInfo"
              size="md"
              show={this.state.showAdditionaInfoModal}
            >
              <Modal.Body className="m-0 p-4 common-form">
                <h3>
                  <b>Additional Info</b>
                </h3>
                <span className="lightColorSpan">
                  Add more info about your {isQuiz ? "quiz" : "poll"}.
                  (Optional)
                </span>
                <div className="modalContentWrapper">
                  <Form.Group className="startDebate">
                    <textarea
                      rows="3"
                      className="mt-5 mb-5 form-control"
                      onChange={(e) => {
                        this.changeHandler(e);
                      }}
                      name="backgroundTemp"
                      value={backgroundTemp ? backgroundTemp : background}
                      placeholder="Share any information that might help people choose a side"
                    ></textarea>
                  </Form.Group>
                  <div className="buttons text-center">
                    <Button
                      className="mt-2"
                      onClick={this.hideAdditionaInfoModal}
                      variant="secondary"
                    >
                      Cancel
                    </Button>
                    <Button
                      onClick={this.commitAdditionalInfo}
                      className={`mt-2 ${backgroundTemp === "" ? "disabled" : ""}`}
                      variant={backgroundTemp === "" ? "secondary" : "primary"}
                      type="submit"
                    >
                      Save
                    </Button>
                  </div>
                </div>
              </Modal.Body>
            </Modal>
          </Tab>
          <Tab
            eventKey="embed-settings"
            title={`Deploy ${isQuiz ? "Quiz" : "Poll"}`}
            disabled="true"
          ></Tab>
        </Tabs>
      </section>
    );
  }
}

const mapStateToProps = (state) => ({
  loggedInUser: state.login.user,
  selNetworkId: state.networks.selNetworkId,
});

export default connect(mapStateToProps, {})(DebateDetail);
