import axios from "axios";
import { useState } from "react";
import { connect } from "react-redux";
import { Form, Modal, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperPlane, faCheck } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import { API_URL } from "../constants/Globals";
import Loader from "./Loader";

function SyndicationSelector({
  networksList,
  isQuiz = false,
  isSyndicated = false,
  selection = [],
  setSelection,
  setIsSyndicated,
  onNotify,
  onSave,
  onPublish,
  categoriesList = [],
}) {
  const [search, setSearch] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [isNotified, setIsNotified] = useState(false);
  const [publishings, setPublishings] = useState([]);
  const [notifications, setNotifications] = useState([]);
  const [previousSelection, setPreviousSelection] = useState([]);

  const networks = (networksList || []).filter(
    (network) =>
      search === "" ||
      network.name.toLowerCase().indexOf(search.toLowerCase()) !== -1,
  );
  const allNetworksChecked = selection.length === 1 && selection[0] === "all";
  const count = allNetworksChecked ? networksList.length : selection.length;
  const { t } = useTranslation();
  const kTrans = t ? t : () => {};

  return (
    <>
      <Form.Group>
        <Form.Label>{kTrans("Syndication")}</Form.Label>
        <div className="syndication-init">
          <div>
            <Form.Label className="standard-label">
              {/*
            <input
              type="checkbox"
              className="sided-checkbox"
              checked={isSyndicated}
              onChange={({target})=> setIsSyndicated(target.checked)}
            />
            */}
              <span>
                Make available to{" "}
                <strong>{`${count} network${count === 1 ? "" : "s"}`}</strong>
              </span>
            </Form.Label>
            <span
              className="text-button"
              onClick={(e) => {
                e.preventDefault();
                setShowModal(true);
                setPreviousSelection(selection);
              }}
            >
              {kTrans("Edit networks")}
            </span>
          </div>
          {isNotified ? (
            <Button variant="success" size="md">
              <FontAwesomeIcon icon={faCheck} />
              &nbsp;
              {kTrans("Sent")}!
            </Button>
          ) : (
            <Button
              variant="primary"
              size="md"
              onClick={() => {
                setIsNotified(true);
                onNotify();

                setTimeout(() => setIsNotified(false), 2000);
              }}
            >
              <FontAwesomeIcon icon={faPaperPlane} />
              &nbsp;
              {kTrans("Notify")}
            </Button>
          )}
        </div>
      </Form.Group>

      <Modal className="authorModal" size="lg" show={showModal}>
        <Modal.Body className="sided-modal-body">
          {showLoader ? <Loader /> : null}

          <h3 className="mb-3">{kTrans("Syndication")}</h3>
          <p className="mb-3">
            {kTrans(
              `Choose the networks you would like to make your ${isQuiz ? "quiz" : "poll"} available to, you are not responsible for any ${isQuiz ? "quizzes" : "polls"} you create that other users utilize`,
            )}
            .
          </p>
          <form className="sided-modal-search">
            <input
              type="text"
              value={search}
              placeholder={kTrans("Search networks")}
              onChange={({ target }) => setSearch(target.value)}
            />
          </form>

          <div className="network-list">
            <div className="network-list-row">
              <div className="network-list-item network-list-name">
                <input
                  type="checkbox"
                  className="sided-checkbox"
                  checked={allNetworksChecked}
                  onChange={() =>
                    setSelection(selection.indexOf("all") !== -1 ? [] : ["all"])
                  }
                />
                {kTrans("Available networks")} ({networks.length})
              </div>
              <div className="network-list-count">{kTrans("Users")}</div>
              <div className="network-list-buttons"></div>
            </div>
            {networks.map((network) => (
              <div key={network.id} className="network-list-row">
                <div className="network-list-item network-list-name">
                  <input
                    type="checkbox"
                    className="sided-checkbox"
                    checked={
                      allNetworksChecked || selection.indexOf(network.id) !== -1
                    }
                    onChange={() => {
                      const newSelection = [].concat(selection);
                      const currentIndex = newSelection.indexOf(network.id);
                      const allIndex = newSelection.indexOf("all");

                      if (allIndex !== -1) {
                        newSelection.splice(allIndex, 1);
                      }

                      if (currentIndex !== -1) {
                        newSelection.splice(currentIndex, 1);
                      } else {
                        newSelection.push(network.id);
                      }

                      setSelection(newSelection);
                    }}
                  />
                  <div className="network-list-logo">
                    {network.header.favicon ? (
                      <img
                        src={network.header.favicon.replace(
                          "https://cdn.sided.cohttps://cdn.sided.co",
                          "https://cdn.sided.co",
                        )}
                        alt="Network logo"
                      />
                    ) : null}
                  </div>
                  <div className="network-list-id">
                    {network.name}
                    <br />
                    <small>{network.siteDomain}</small>
                  </div>
                </div>
                <div className="network-list-count">{network.adminsCount}</div>
                <div className="network-list-buttons">
                  {notifications.indexOf(network.id) !== -1 ? (
                    <Button variant="success" size="md">
                      <FontAwesomeIcon icon={faCheck} />
                      &nbsp;
                      {kTrans("Sent")}!
                    </Button>
                  ) : (
                    <Button
                      variant="primary"
                      size="md"
                      onClick={() => {
                        setNotifications(notifications.concat([network.id]));
                        onNotify(network.id);
                      }}
                    >
                      <FontAwesomeIcon icon={faPaperPlane} />
                      &nbsp;
                      {kTrans("Notify")}
                    </Button>
                  )}

                  {publishings.indexOf(network.id) !== -1 ? (
                    <Button variant="success" size="md">
                      {kTrans("Published")}!
                    </Button>
                  ) : (
                    <Button
                      variant="primary"
                      size="md"
                      onClick={async (event) => {
                        const categoryId =
                          event.target.parentNode.querySelector("select")
                            .value * 1;

                        setShowLoader(true);
                        const client = await axios.get(
                          `${API_URL}/admin/client/${network.id}`,
                        );
                        setShowLoader(false);
                        const brandAccount = client.data.brandAccount || {};

                        if (!brandAccount.id) {
                          alert(
                            "Please assign a Brand Account to this network first",
                          );
                        } else {
                          setPublishings(publishings.concat([network.id]));
                          onPublish(network.id, categoryId, brandAccount.id);
                        }
                      }}
                    >
                      {kTrans("Publish")}
                    </Button>
                  )}

                  <select>
                    <option value="0">-</option>
                    {categoriesList
                      .filter(({ clientId }) => {
                        return parseInt(clientId) === network.id;
                      })
                      .map((category) => (
                        <option value={category.id} key={category.id}>
                          {category.text}
                        </option>
                      ))}
                  </select>
                </div>
              </div>
            ))}
          </div>

          <div className="text-center">
            <Button
              variant="secondary"
              className="mx-1"
              onClick={() => {
                setSelection(previousSelection);
                setShowModal(false);
              }}
            >
              {kTrans("Cancel")}
            </Button>
            <Button
              variant="primary"
              className="mx-1"
              onClick={() => {
                onSave();
                setShowModal(false);
              }}
            >
              {kTrans("Save")}
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

const mapStateToProps = (state) => ({
  networksList: state.networks.networksList,
});

export default connect(mapStateToProps)(SyndicationSelector);
