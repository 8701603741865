import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import axios from "axios";
import {
  API_URL,
  DEFAULT_USER_IMG_URL,
  MAIN_WEBSITE_URL,
} from "../../../constants/Globals";
import { ClientJS } from "clientjs";
const client = new ClientJS();
const fingerprint = client.getFingerprint();
var moment = require("moment-timezone");

class ReportedDebate extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      reportedContentData: {},
    };
  }

  fetchReportedContentData = async (e) => {
    await axios
      .get(
        API_URL +
          "/debate/" +
          this.props.reportableId +
          "?showDeleted=1&deviceId=" +
          fingerprint,
      )
      .then((res) => {
        this.setState({ reportedContentData: res.data.data });
      })
      .catch((err) => {
        console.log("Axios Error for debate id " + this.props.reportableId);
        console.log(err);
      });
  };

  componentDidMount = () => {
    this.fetchReportedContentData();
  };

  render() {
    const kTrans = this.props.t ? this.props.t : "";
    const { reportedContentData } = this.state;

    /* Difference in startedAt and endedAt to display in preview section */
    var finalDifference = null;
    if (
      reportedContentData.endedAt !== null &&
      reportedContentData.endedAt !== undefined
    ) {
      var currDate = moment();
      var endedAtDate = moment(reportedContentData.endedAt);
      var differenceInDates = endedAtDate.diff(currDate, "minutes");
      var numdays = Math.floor(differenceInDates / 1440);
      var numhours = Math.floor((differenceInDates % 1440) / 60);
      var numminutes = Math.floor((differenceInDates % 1440) % 60);
      finalDifference =
        numdays > 0
          ? numdays + " day(s) "
          : numhours > 0
            ? numhours + " hour(s) "
            : numminutes > 0
              ? numminutes + " minute(s)"
              : "< 1 minute";
    }

    return (
      <div className="reportedDebate debatePreviewInner">
        {reportedContentData ? (
          <>
            <div className="debatePreviewHeader">
              {reportedContentData.user ? (
                <div className="author">
                  <a
                    target="_blank"
                    rel="noreferrer"
                    className="small-dp user-color-green"
                    href={`${MAIN_WEBSITE_URL}/${reportedContentData.user.username}`}
                    tabindex="0"
                  >
                    <img
                      src={
                        reportedContentData.user.avatarObject
                          ? reportedContentData.user.avatarObject.thumb.location
                          : null
                      }
                      alt="Super Admin"
                      className="sidedLazyLoad img-circle avatar"
                      style={{
                        borderColor: reportedContentData.user.statusColor,
                      }}
                      onError={(e) => {
                        e.target.src = DEFAULT_USER_IMG_URL;
                      }}
                    />
                    {reportedContentData.user.roles.length > 0 &&
                    reportedContentData.user.roles.find(
                      (role) => role.name === "VERIFIED",
                    ) ? (
                      <FontAwesomeIcon
                        style={{
                          color: reportedContentData.user.checkmarkColor,
                        }}
                        icon={faCheckCircle}
                        className="procheckmark"
                      />
                    ) : null}
                  </a>
                  <div className="copy-wrap">
                    <a
                      target="_blank"
                      rel="noreferrer"
                      className="authorname"
                      href={`${MAIN_WEBSITE_URL}/${reportedContentData.user.username}`}
                      tabindex="0"
                    >
                      <h6>{reportedContentData.user.name}</h6>
                    </a>
                    <span className="authorhandle">
                      <span className="handler">
                        Posted{" "}
                        {moment(reportedContentData.createdAt).format(
                          "MMM DD, YYYY",
                        )}
                      </span>
                    </span>
                  </div>
                </div>
              ) : null}
            </div>
            <div className="debatePreviewTitle mt-2 mb-3">
              <h5>
                {reportedContentData.deletedAt === null ? (
                  <a
                    rel="noreferrer"
                    target="_blank"
                    className="arrow"
                    href={reportedContentData.debateUrl}
                  >
                    {reportedContentData.thesis}
                  </a>
                ) : (
                  <>{reportedContentData.thesis}</>
                )}
                <small>
                  {!parseInt(reportedContentData.noEndDate) ? (
                    <>
                      {reportedContentData.isClosed ? (
                        <span className="lightColorSpan mt-2 d-block">
                          {kTrans("Poll ended")}{" "}
                          {moment(reportedContentData.endedAt).format(
                            "MMM DD, YYYY",
                          )}
                        </span>
                      ) : (
                        <span className="lightColorSpan mt-2 d-block">
                          {kTrans("Poll ends in")}{" "}
                          {finalDifference ? finalDifference : ""} •{" "}
                          {kTrans("Vote below")}
                        </span>
                      )}
                    </>
                  ) : (
                    <span className="lightColorSpan mt-2 d-block">
                      Vote below
                    </span>
                  )}
                </small>
              </h5>
            </div>
            <div className="debatePreviewSides mt-2">
              {reportedContentData.sides && reportedContentData.sides.length
                ? reportedContentData.sides.map((el, i) => (
                    <div className="side mb-2" key={i}>
                      <label className="d-block">Side 1</label>
                    </div>
                  ))
                : null}
            </div>
          </>
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  networks: state.networks.networks,
  loggedInUser: state.login.user,
  selNetworkId: state.networks.selNetworkId,
});

const Extended = withTranslation()(ReportedDebate);
Extended.static = ReportedDebate.static;

export default connect(mapStateToProps, {})(Extended);
