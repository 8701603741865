const init = {
  isLoading: false,
  debatesCount: null,
  debates: null,
  topDebates: null,
  suggestedDebates: null,
};

export const debatesReducer = (state = init, action) => {
  switch (action.type) {
    case "DEBATES_LOADING":
      return {
        ...state,
        isLoading: true,
      };
    case "DEBATES_LOADED":
      return {
        ...state,
        isLoading: false,
        debatesCount: action.payload.data.count,
        debates: action.payload.data.rows,
      };

    case "TOP_DEBATES_LOADING":
      return {
        ...state,
        isLoading: true,
      };
    case "TOP_DEBATES_LOADED":
      return {
        ...state,
        isLoading: false,
        topDebates: action.payload.data,
      };

    case "SUGGESTED_DEBATES_LOADING":
      return {
        ...state,
        isLoading: true,
      };
    case "SUGGESTED_DEBATES_LOADED":
      return {
        ...state,
        isLoading: false,
        suggestedDebates: action.payload.data,
      };

    default:
      return state;
  }
};
