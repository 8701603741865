import axios from "axios";
import { API_URL } from "../../constants/Globals";

export const loadEmbedDomains =
  (clientId = null) =>
  (dispatch) => {
    console.log("redux action loadEmbedDomains");
    if (!!clientId) {
      axios
        .get(API_URL + "/admin/embedSource/getDomains?clientId=" + clientId)
        .then((res) =>
          dispatch({
            type: "EMBED_DOMAINS_LOADED",
            payload: res.data,
          }),
        )
        .catch((err) => {
          console.log("err", err);
        });
    }
  };

export const saveSelectedDomain =
  (selDomainId = null) =>
  (dispatch) => {
    console.log("redux action saveSelectedDomain");
    dispatch({
      type: "SAVE_CURRENT_DOMAIN",
      payload: selDomainId,
    });
  };
