import axios from "axios";
import { connect } from "react-redux";
import { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { ReactSortable } from "react-sortablejs";
import DeployModal from "../DeployModal";
import useDebounce from "../../hooks/useDebounce";
import useAlertMessage from "../../hooks/useAlertMessage";
import { capitalizeFirstLetter } from "../../helpers/String";
import { API_URL } from "../../constants/Globals";
import { BarsIcon } from "../../icons";

function NewCollection({ selNetworkId, match }) {
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [items, setItems] = useState([]);
  const [forms, setForms] = useState([]);
  const [collectionName, setCollectionName] = useState("");
  const [collectionItems, setCollectionItems] = useState([]);
  const [embedCode, setEmbedCode] = useState("");
  const [showDeployModal, setShowDeployModal] = useState(false);
  const [setAlertMessage, getAlertMessage] = useAlertMessage();
  const { params } = match;
  const history = useHistory();
  const debouncedSearch = useDebounce(search, 1000);

  useEffect(() => {
    if (params.id === "new") {
      setEmbedCode("");
      setCollectionName("");
      setCollectionItems([]);
    } else {
      axios.get(`${API_URL}/collections/${params.id}`).then(({ data }) => {
        const { name, parts } = data.data;

        setCollectionName(name);
        setCollectionItems(
          parts.map((part) => {
            return {
              ...part,
              type:
                part.type === "form"
                  ? part.type
                  : part.isQuiz
                    ? "quiz"
                    : "poll",
              isExistingItem: true,
            };
          }),
        );
      });

      axios.get(`${API_URL}/debate/getEmbed/100`).then(({ data }) => {
        setEmbedCode(
          data.data.html.replace(
            'debateId="100"',
            `collectionId="${params.id}"`,
          ),
        );
      });
    }
  }, [params]);

  useEffect(() => {
    if (selNetworkId) {
      axios
        .get(`${API_URL}/forms?clientId=${selNetworkId}`)
        .then(({ data }) => setForms(data.data));
    }
  }, [selNetworkId]);

  useEffect(() => {
    if (debouncedSearch === "") {
      setItems([]);
    } else {
      axios
        .get(
          API_URL +
            `/admin/debate/getDebates?isActive=1&perPage=5&pageNumber=1&clientId=${selNetworkId}&searchText=${debouncedSearch.trim()}`,
        )
        .then(({ data }) => setItems(data.data.rows));
    }
  }, [selNetworkId, debouncedSearch]);

  return (
    <section>
      {getAlertMessage()}

      <div className="table-filters">
        <Link to="/collections" className="text-button">
          <svg
            width="9"
            height="14"
            viewBox="0 0 9 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M8.56 11.8135L6.68 13.6668L0 7.00016L6.68 0.333496L8.56 2.18683L3.81333 7.00016L8.56 11.8135Z"
              fill="#2F80ED"
            />
          </svg>
          All Collections
        </Link>
      </div>

      <h1 className="page-title">
        {params.id === "new"
          ? "Add New Collection"
          : `Edit Collection: ${collectionName}`}
      </h1>

      <div className="form-tabs">
        <div className="active">Collection Details</div>
        <div
          className={params.id === "new" || true ? "disabled" : ""}
          onClick={() => {
            if (params.id !== "new" && false) {
              setShowDeployModal(true);
            }
          }}
        >
          Deploy Collection
        </div>
      </div>

      <div className="segment-form after-tabs">
        <div className="segment-form-body padding-s">
          {embedCode ? (
            <div className="segment-form-field mb-l">
              <label className="type-2">Collection Embed Code</label>
              <span
                className="customBtn btn btn-secondary p-2 ps-4 pe-4"
                onClick={({ target }) => {
                  const textArea =
                    target.parentElement.querySelector("textarea");
                  textArea.select();
                  document.execCommand("copy");
                  setAlertMessage("Embed code copied to clipboard!");
                }}
              >
                Copy Code
              </span>
              <textarea
                className="hiddenElement"
                value={embedCode}
                readOnly={true}
              />
            </div>
          ) : null}

          <div className="segment-form-field mb-l">
            <label className="type-2">Collection Name</label>
            <input
              type="text"
              value={collectionName}
              className="full-width"
              placeholder="Enter name here"
              onChange={({ target }) => setCollectionName(target.value)}
            />
          </div>

          <div className="segment-form-field">
            <label className="type-2">Select a poll, quiz, or form</label>
            <div className="inner-dropdown">
              <input
                type="text"
                value={search}
                className="full-width"
                placeholder="Search for a poll, quiz, or form"
                onChange={({ target }) => setSearch(target.value)}
              />

              {search ? (
                <svg
                  onClick={() => {
                    setItems([]);
                    setSearch("");
                  }}
                  className="dropdown-arrow"
                  width="12"
                  height="12"
                  xmlns="http://www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  viewBox="0 0 121.31 122.876"
                  enable-background="new 0 0 121.31 122.876"
                >
                  <g>
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M90.914,5.296c6.927-7.034,18.188-7.065,25.154-0.068 c6.961,6.995,6.991,18.369,0.068,25.397L85.743,61.452l30.425,30.855c6.866,6.978,6.773,18.28-0.208,25.247 c-6.983,6.964-18.21,6.946-25.074-0.031L60.669,86.881L30.395,117.58c-6.927,7.034-18.188,7.065-25.154,0.068 c-6.961-6.995-6.992-18.369-0.068-25.397l30.393-30.827L5.142,30.568c-6.867-6.978-6.773-18.28,0.208-25.247 c6.983-6.963,18.21-6.946,25.074,0.031l30.217,30.643L90.914,5.296L90.914,5.296z"
                      fill="#7f7f7f"
                    />
                  </g>
                </svg>
              ) : null}

              {(items.length || forms.length) && search.trim().length ? (
                <div className="auto-complete">
                  {forms
                    .filter(
                      ({ name }) =>
                        name
                          .trim()
                          .toLowerCase()
                          .indexOf(search.trim().toLowerCase()) !== -1,
                    )
                    .map((form) => (
                      <div key={forms.id}>
                        <div>{form.name} (form)</div>
                        {collectionItems
                          .map(({ id }) => id)
                          .indexOf(form.id) === -1 ? (
                          <div
                            className="text-button"
                            onClick={() => {
                              const newCollectionItems = [].concat(
                                collectionItems,
                              );
                              newCollectionItems.push({
                                ...form,
                                type: "form",
                              });
                              setCollectionItems(newCollectionItems);
                              setItems([]);
                              setSearch("");
                            }}
                          >
                            Add
                          </div>
                        ) : null}
                      </div>
                    ))}
                  {items.map((item) => (
                    <div key={item.id}>
                      <div>
                        {item.thesis} ({item.isQuiz ? "quiz" : "poll"})
                      </div>
                      {collectionItems.map(({ id }) => id).indexOf(item.id) ===
                      -1 ? (
                        <div
                          className="text-button"
                          onClick={() => {
                            const newCollectionItems = [].concat(
                              collectionItems,
                            );
                            newCollectionItems.push({
                              ...item,
                              type: item.isQuiz ? "quiz" : "poll",
                            });
                            setCollectionItems(newCollectionItems);
                            setItems([]);
                            setSearch("");
                          }}
                        >
                          Add
                        </div>
                      ) : null}
                    </div>
                  ))}
                </div>
              ) : null}
            </div>
          </div>

          {collectionItems.length ? (
            <div className="summary-table">
              <div className="summary-table-row summary-table-head">
                <div className="summary-cell summary-cell-w1 visibility-l">
                  Order
                </div>
                <div className="summary-cell summary-cell-fluid visibility-l">
                  Collection items
                </div>
                <div className="summary-cell summary-cell-w2 visibility-l">
                  Type
                </div>
                <div className="summary-cell summary-cell-w2"></div>
                <div className="summary-cell summary-cell-w1"></div>
              </div>

              <ReactSortable
                list={collectionItems}
                setList={setCollectionItems}
                onUpdate={({ oldIndex, newIndex }) => {
                  axios.post(
                    `${API_URL}/collections/${params.id}/partsOrder/${collectionItems[oldIndex].id}/type/${collectionItems[oldIndex].type}`,
                    { order: newIndex + 1 },
                  );
                }}
              >
                {collectionItems.map((item, index) => (
                  <div
                    className="summary-table-row"
                    key={`${index}_${item.id}`}
                  >
                    <div className="summary-cell summary-cell-w1 visibility-l pl-m">
                      {index + 1}
                    </div>
                    <div className="summary-cell summary-cell-fluid">
                      {item.thesis || item.name}
                    </div>
                    <div className="summary-cell summary-cell-w2">
                      {item.type ? capitalizeFirstLetter(item.type) : ""}
                    </div>
                    <div className="summary-cell summary-cell-w2">
                      <div
                        className="text-button"
                        onClick={() => {
                          const newCollectionItems = [].concat(collectionItems);
                          newCollectionItems.splice(index, 1);
                          setCollectionItems(newCollectionItems);

                          if (item.type === "form") {
                            axios.delete(
                              `${API_URL}/collections/${params.id}/parts/${item.id}/type/form`,
                            );
                          } else {
                            axios.delete(
                              `${API_URL}/collections/${params.id}/parts/${item.id}/type/debate`,
                            );
                          }
                        }}
                      >
                        Remove
                      </div>
                    </div>
                    <div className="summary-cell summary-cell-w1 center-x">
                      <BarsIcon />
                    </div>
                  </div>
                ))}
              </ReactSortable>
            </div>
          ) : null}

          <div className="segment-form-buttons align-right">
            <Link to="/collections" className="text-button">
              Cancel
            </Link>
            {loading ? (
              <em>Saving...</em>
            ) : (
              <button
                className="blue-button"
                onClick={() => {
                  setLoading(true);

                  axios({
                    method: params.id === "new" ? "POST" : "PUT",
                    url: `${API_URL}/collections${params.id === "new" ? "" : `/${params.id}`}`,
                    data: {
                      clientId: parseInt(selNetworkId),
                      name: collectionName,
                    },
                  })
                    .then(async ({ data }) => {
                      for (let i in collectionItems) {
                        if (!collectionItems[i].isExistingItem) {
                          if (collectionItems[i].type === "form") {
                            await axios.post(
                              `${API_URL}/collections/${data.data.id}/parts/${collectionItems[i].id}/type/form`,
                            );
                          } else {
                            await axios.post(
                              `${API_URL}/collections/${data.data.id}/parts/${collectionItems[i].id}/type/debate`,
                            );
                          }
                        }
                      }

                      setLoading(false);
                      setAlertMessage(data.message);
                      setTimeout(
                        () =>
                          history.push(
                            `/collections${params.id === "new" ? `/${data.data.id}` : ""}`,
                          ),
                        2000,
                      );
                    })
                    .catch(({ response }) => {
                      const { data } = response;
                      const { message } = data;

                      setLoading(false);
                      setAlertMessage(JSON.stringify(message));
                    });
                }}
                disabled={loading}
              >
                Save Collection
              </button>
            )}
          </div>
        </div>
      </div>

      {showDeployModal ? (
        <DeployModal onClose={() => setShowDeployModal(false)} />
      ) : null}
    </section>
  );
}

const mapStateToProps = (state) => ({
  selNetworkId: state.networks.selNetworkId,
});

export default connect(mapStateToProps)(NewCollection);
