import axios from "axios";
import { API_URL } from "../../constants/Globals";

export const loadUser = () => async (dispatch, getState) => {
  console.log("redux action loadUser");
  dispatch({
    type: "USER_LOADING",
  });
  let userId = getState().login.userId;
  if (userId) {
    axios
      .get(API_URL + "/user/" + userId)
      .then((res) =>
        dispatch({
          type: "USER_LOADED",
          payload: res.data,
        }),
      )
      .catch((err) => {
        dispatch({
          type: "AUTH_ERROR",
        });
      });
  } else {
    dispatch({
      type: "AUTH_ERROR",
    });
  }
};

export const login =
  ({ field, password, deviceId }) =>
  (dispatch) => {
    axios
      .post(API_URL + "/auth/signin", { field, password, deviceId })
      .then((res) => {
        dispatch({
          type: "LOGIN",
          payload: res.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: "LOGIN_FAIL",
          payload:
            err.response !== undefined
              ? err.response.data.message[0].error
              : null,
        });
      });
  };

export const logout = () => (dispatch) => {
  console.log("redux action logout");
  dispatch({
    type: "LOGOUT_SUCCESS",
  });
};

export const forgotPassword =
  ({ field }) =>
  (dispatch) => {
    axios
      .post(API_URL + "/user/forgotPassword", { field })
      .then((res) => {
        dispatch({
          type: "FORGOT_PASSWORD",
          payload: res.data.message,
        });
      })
      .catch((err) => {
        dispatch({
          type: "FORGOT_PASSWORD_FAIL",
          payload:
            err.response !== undefined
              ? err.response.data.message[0].error
              : null,
        });
      });
  };
