import React from "react";
import { Row, Col, Form, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import {
  faMessageLines,
  faLockKeyhole,
} from "@fortawesome/pro-regular-svg-icons";
import { faTimesCircle } from "@fortawesome/pro-light-svg-icons";
import axios from "axios";
import {
  API_URL,
  DEFAULT_USER_IMG_URL,
  MODERATION_PER_PAGE,
  MAIN_WEBSITE_URL,
} from "../../constants/Globals";
import ReportedComment from "./reported_content/ReportedComment";
import ReportedUser from "./reported_content/ReportedUser";
import ReportedDebate from "./reported_content/ReportedDebate";
var moment = require("moment-timezone");

class ModerationClosedCases extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loadMoreTriggered: false,
      pageNumber: 1,
      otherReportPageNumber: 1,
      moderationCases: [],
      searchText: "",
      loading: false,
      decisionData: {},
      suspendUserDropdown: [],
    };
  }
  fetchClosedCases = async (e) => {
    if (!this.props.selNetworkId) {
      setTimeout(() => {
        this.fetchOpenCases();
      }, 1000);
      return false;
    }
    await this.setState({
      loading: true,
    });

    var perPageItems = this.state.searchText ? 9999 : MODERATION_PER_PAGE;
    var searchText = this.state.searchText
      ? "&searchText=" + this.state.searchText
      : "";
    var selNetworkId = localStorage.getItem("selNetworkId")
      ? localStorage.getItem("selNetworkId")
      : this.props.selNetworkId;
    await axios
      .get(
        API_URL +
          "/admin/moderationQueue/getReportedContent?clientId=" +
          selNetworkId +
          "&filter=CLOSED&perPage=" +
          perPageItems +
          "&pageNumber=" +
          this.state.pageNumber +
          searchText,
      )
      .then((res) => {
        if (this.state.searchText) {
          this.setState({ moderationCases: [] });
          console.log(res.data.data);
          var filteredReports = [];
          for (var i = 0; i < res.data.data.length; i++) {
            if (res.data.data[i].reportedContent) {
              if (
                (res.data.data[i].reportedContent.text &&
                  res.data.data[i].reportedContent.text
                    .toUpperCase()
                    .match(this.state.searchText.toUpperCase())) ||
                (res.data.data[i].reportedContent.name &&
                  res.data.data[i].reportedContent.name
                    .toUpperCase()
                    .match(this.state.searchText.toUpperCase())) ||
                (res.data.data[i].reportedContent.thesis &&
                  res.data.data[i].reportedContent.thesis
                    .toUpperCase()
                    .match(this.state.searchText.toUpperCase()))
              ) {
                console.log("found");
                filteredReports.push(res.data.data[i]);
              }
            }
          }
          console.log(filteredReports);
          this.setState({ moderationCases: filteredReports, loading: false });
        } else {
          var moderationCasesArr = [...this.state.moderationCases];
          var moderationCases = moderationCasesArr.concat(res.data.data);
          this.setState({
            moderationCases,
            loading: false,
          });
          if (
            this.state.pageNumber !==
            Math.ceil(res.data.total / MODERATION_PER_PAGE)
          ) {
            this.setState({
              loadMoreTriggered: false,
            });
          }
        }
      })
      .catch((err) => {
        alert("Failed. Please try again!");
        console.log(err);
      });
  };

  suspendUserDropdownOptions = () => {
    var selNetworkName = this.props.networks
      ? this.props.networks.find(
          (item) => item.id === parseInt(this.props.selNetworkId),
        ).header.title
      : "current";
    this.setState({
      suspendUserDropdown: [
        {
          statusCode: 4,
          text: "The " + selNetworkName + " network (7 days)",
        },
        {
          statusCode: 5,
          text: "The " + selNetworkName + " network (indefinitely)",
        },
        {
          statusCode: 6,
          text: "All networks (indefinitely)",
        },
      ],
    });
  };

  componentDidMount = async () => {
    await this.setState({
      loading: true,
    });
    setTimeout(() => {
      this.fetchClosedCases();
      document.addEventListener("scroll", this.trackScrolling);
      this.suspendUserDropdownOptions();
    }, 700);
  };

  trackScrolling = async () => {
    if (this.state.searchText) {
      return false;
    }
    const wrappedElement = document.body;
    if (
      this.state.moderationCases.length > 9 &&
      this.state.loadMoreTriggered === false &&
      window.pageYOffset + window.outerHeight * 2 > wrappedElement.scrollHeight
    ) {
      await this.setState({
        pageNumber: this.state.pageNumber + 1,
        loadMoreTriggered: true,
      });
      this.fetchClosedCases();
    }
  };

  componentDidUpdate(prevProps, prevState) {
    setTimeout(() => {
      if (
        prevProps.selNetworkId !== null &&
        parseInt(this.props.selNetworkId) !== 0 &&
        prevProps.selNetworkId !== this.props.selNetworkId
      ) {
        this.setState({ moderationCases: [] });
        this.fetchClosedCases();
      }
    }, 500);
    if (this.props.searchText !== prevProps.searchText) {
      this.setState({ searchText: this.props.searchText, pageNumber: 1 });
      this.fetchClosedCases();
    }
  }
  reopenCase = async (e) => {
    var selNetworkId = localStorage.getItem("selNetworkId")
      ? localStorage.getItem("selNetworkId")
      : this.props.selNetworkId;

    await axios
      .post(
        API_URL +
          "/admin/moderationQueue/updateAction/" +
          e.target.dataset.reportid +
          "?clientId=" +
          selNetworkId,
      )
      .then((res) => {
        this.messageNotification(
          "success",
          this.props.t("Action taken on Reported Content successfully!"),
        );
        e.target.closest(".reported-cases").style.display = "none";
      })
      .catch((err) => {
        if (err.response !== undefined) {
          console.log(err.response.data);
          this.messageNotification("fail", this.props.t("Error found!"));
        }
        this.setState({
          loading: false,
        });
      });
  };

  messageNotification = async (type, message) => {
    this.setState({
      notificationType: type,
      notificationMessage: message,
    });

    setTimeout(() => {
      this.setState({
        notificationType: "",
        notificationMessage: "",
      });
    }, 5000);
  };

  showOtherReports = async (e) => {
    document.body.classList.add("overflow-hidden");
    var reportId = e.target.dataset.reportid;
    var reportableId = e.target.dataset.reportableid;
    var reportableType = e.target.dataset.reportabletype;
    var type = e.target.dataset.type;
    await this.setState({
      loadingOtherReports: true,
    });
    this.setState({ selReport: reportId });

    var selNetworkId = localStorage.getItem("selNetworkId")
      ? localStorage.getItem("selNetworkId")
      : this.props.selNetworkId;
    await axios
      .get(
        API_URL +
          "/admin/moderationQueue/getRelatedReports?id=" +
          reportId +
          "&clientId=" +
          selNetworkId +
          "&reportableId=" +
          reportableId +
          "&reportableType=" +
          reportableType +
          "&type=" +
          type +
          "&perPage=" +
          MODERATION_PER_PAGE +
          "&pageNumber=" +
          this.state.otherReportPageNumber,
      )
      .then((res) => {
        var otherReportsList = res.data.data;
        this.setState({
          otherReportsList: otherReportsList,
          loadingOtherReports: false,
        });
      })
      .catch((err) => {
        alert("Failed. Please try again!");
        console.log(err);
      });
    console.log(this.state.otherReportsList);
  };
  hideOtherReports = async (e) => {
    this.setState({ selReport: "" });
    document.body.classList.remove("overflow-hidden");
  };

  render() {
    const {
      moderationCases,
      suspendUserDropdown,
      selReport,
      loading,
      otherReportsList,
    } = this.state;
    const kTrans = this.props.t ? this.props.t : "";
    const otherReportsData = selReport
      ? moderationCases.find(
          (report) => parseInt(report.id) === parseInt(selReport),
        )
      : null;
    return (
      <div className={loading ? "loading" : null}>
        {this.state.notificationMessage ? (
          <div className={`sucessMsg ${this.state.notificationType}`}>
            {this.state.notificationMessage}
          </div>
        ) : null}

        {moderationCases && moderationCases.length ? (
          moderationCases.map((el, i) => (
            <div className="reported-cases" key={i}>
              <div className="reported-cases-stats d-flex mb-4">
                <label className="me-4 pe-2">
                  Case # <span className="lightColorSpan">{el.id}</span>
                </label>
                <label className="me-4 pe-3">
                  Type{" "}
                  <span className="lightColorSpan">
                    {el.reportableType === "App\\Comment"
                      ? "Comment"
                      : el.reportableType === "App\\Argument"
                        ? "Argument"
                        : el.reportableType === "App\\User"
                          ? "Profile"
                          : "Poll"}
                  </span>
                </label>
                {el.relatedReportsCount > 0 ? (
                  <span
                    onClick={this.showOtherReports}
                    data-reportid={el.id}
                    data-reportableid={el.reportableId}
                    data-reportabletype={el.reportableType}
                    data-type={el.type}
                    className="customAnchor reportedBy d-flex align-items-center"
                  >
                    <FontAwesomeIcon icon={faMessageLines} flip="horizontal" />
                    Reported by {el.relatedReportsCount} other users
                  </span>
                ) : null}
              </div>
              <Row className="reported-cases-body mb-4">
                <Col md={6}>
                  <div className="d-flex">
                    <div className="me-2 arrowBelowAuthor">
                      <div className="author">
                        {el.user === null ? (
                          <img
                            src={DEFAULT_USER_IMG_URL}
                            className="img-circle avatar"
                            alt="User avatar"
                          />
                        ) : (
                          <a
                            target="_blank"
                            rel="noreferrer"
                            className="small-dp user-color-green"
                            href={`${MAIN_WEBSITE_URL}/${el.user.username}`}
                            tabindex="0"
                          >
                            <img
                              src={
                                el.user.avatarObject
                                  ? el.user.avatarObject.thumb.location
                                  : null
                              }
                              alt={el.user.name}
                              className="sidedLazyLoad img-circle avatar"
                              style={{ borderColor: el.user.statusColor }}
                              onError={(e) => {
                                e.target.src = DEFAULT_USER_IMG_URL;
                              }}
                            />
                            {el.user.isVerified ? (
                              <FontAwesomeIcon
                                style={{ color: el.user.checkmarkColor }}
                                icon={faCheckCircle}
                                className="procheckmark"
                              />
                            ) : null}
                          </a>
                        )}
                      </div>
                    </div>
                    <div className="report-text-wrap ms-1 w-100">
                      <h6>
                        {el.user === null ? (
                          "Guest User"
                        ) : (
                          <a
                            rel="noreferrer"
                            target="_blank"
                            href={`${MAIN_WEBSITE_URL}/${el.user.username}`}
                            className="customAnchor d-inline"
                          >
                            {" "}
                            {el.user.name}
                          </a>
                        )}{" "}
                        reported <span className="reportType">{el.type}</span>
                        <small>
                          {moment(el.createdAt).format("MM/DD/YY @ hh:mm A")}
                        </small>
                      </h6>
                      <div className="report-text">{el.reason}</div>
                    </div>
                  </div>
                  <div className="d-flex">
                    <div className="report-text-wrap ms-1 w-100">
                      <label className="mb-2">REPORTED CONTENT</label>
                      {el.reportableType === "App\\User" ? (
                        <ReportedUser reportableId={el.reportableId} />
                      ) : null}
                      {el.reportableType === "App\\Debate" ? (
                        <ReportedDebate reportableId={el.reportableId} />
                      ) : null}
                      {el.reportableType === "App\\Comment" ||
                      el.reportableType === "App\\Argument" ? (
                        <ReportedComment
                          reportableType={el.reportableType}
                          reportableId={el.reportableId}
                        />
                      ) : null}
                    </div>
                  </div>
                </Col>
                <Col md={6} className="ps-4 pt-3 pt-md-0">
                  <form
                    id="reported-case-action"
                    autoComplete="off"
                    className="common-form"
                    method="get"
                    onSubmit={(e) => e.preventDefault()}
                  >
                    <Form.Group controlId="" className="pt-0">
                      <Form.Label>{kTrans("MAKE A DECISION")}:</Form.Label>
                      <div className="d-flex mt-3 radioAsBtns">
                        <Form.Check
                          label={kTrans("No Action")}
                          type="radio"
                          checked={el.status === 1}
                        />
                        <Form.Check
                          label={kTrans("Remove Content")}
                          type="radio"
                          checked={el.status === 2}
                        />
                        <Form.Check
                          label={kTrans("Hide Content")}
                          type="radio"
                          checked={el.status === 3}
                        />
                        <Form.Check
                          label={kTrans("Remove & Suspend")}
                          type="radio"
                          checked={
                            el.status === 4 ||
                            el.status === 5 ||
                            el.status === 6
                          }
                        />
                      </div>
                    </Form.Group>
                    {el.status === 4 || el.status === 5 || el.status === 6 ? (
                      <Form.Group controlId="" className="pt-0">
                        <Form.Label>{kTrans("SUSPEND USER")}:</Form.Label>
                        <span className="lockedSuspendStatus">
                          {suspendUserDropdown.find(
                            (option) =>
                              parseInt(option.statusCode) ===
                              parseInt(el.status),
                          )
                            ? suspendUserDropdown.find(
                                (option) =>
                                  parseInt(option.statusCode) ===
                                  parseInt(el.status),
                              ).text
                            : null}{" "}
                          <FontAwesomeIcon icon={faLockKeyhole} />
                        </span>
                      </Form.Group>
                    ) : null}
                    {el.reportMessage && el.reportMessage.message ? (
                      <>
                        <Form.Label>
                          {kTrans("REASON FOR DECISION")}:
                        </Form.Label>
                        <div className="report-text m-0 mt-2">
                          {el.reportMessage.message}
                        </div>
                      </>
                    ) : null}
                    {el.reportMessage ? (
                      <span className="lightColorSpan">
                        Moderator: @
                        {el.reportMessage && el.reportMessage.user
                          ? el.reportMessage.user.username
                          : ""}
                      </span>
                    ) : null}
                    <div className="txtRight mt-4">
                      <Button
                        onClick={this.reopenCase}
                        data-reportid={el.id}
                        className=" me-2"
                        size="md"
                        variant="outline-primary"
                      >
                        {kTrans("Reopen")}
                      </Button>
                    </div>
                  </form>
                </Col>
              </Row>
              <hr />
            </div>
          ))
        ) : (
          <h3>{loading === false ? "No Closed Cases found!" : ""}</h3>
        )}
        {loading ? (
          <div className="cssload-container loadMore">
            <div className="cssload-loading">
              <i></i>
              <i></i>
              <i></i>
              <i></i>
            </div>
          </div>
        ) : null}
        <div
          className={`otherReportsWrapper ${otherReportsData ? "show" : ""}`}
        >
          <div className="otherReports report-text-wrap">
            {otherReportsData ? (
              <>
                <div class="otherReportsFixedSection">
                  <div className="otherReportsHeader txtRight pt-3 pe-3">
                    <FontAwesomeIcon
                      onClick={this.hideOtherReports}
                      icon={faTimesCircle}
                    />
                  </div>
                  <hr />
                  <div className="otherReportsBody ps-4 pe-4 pb-4">
                    <label className="mb-2">REPORTED CONTENT</label>
                    {otherReportsData.reportableType === "App\\User" ? (
                      <ReportedUser
                        reportableId={otherReportsData.reportableId}
                      />
                    ) : null}
                    {otherReportsData.reportableType === "App\\Debate" ? (
                      <ReportedDebate
                        reportableId={otherReportsData.reportableId}
                      />
                    ) : null}
                    {otherReportsData.reportableType === "App\\Comment" ||
                    otherReportsData.reportableType === "App\\Argument" ? (
                      <ReportedComment
                        reportableType={otherReportsData.reportableType}
                        reportableId={otherReportsData.reportableId}
                      />
                    ) : null}
                  </div>
                  <div className="otherReportsFooter p-4">
                    <span className="reportedBy d-flex align-items-center ps-2">
                      <FontAwesomeIcon
                        icon={faMessageLines}
                        flip="horizontal"
                      />
                      Reported by {otherReportsData.relatedReportsCount} other
                      users
                    </span>
                  </div>
                </div>
                <div className="otherReportsScroller ps-4 pe-4 pt-1">
                  {otherReportsList && otherReportsList.length
                    ? otherReportsList.map((el, i) => (
                        <>
                          <div className="d-flex">
                            <div className="me-2 arrowBelowAuthor">
                              <div className="author">
                                {el.user === null ? (
                                  <img
                                    src={DEFAULT_USER_IMG_URL}
                                    className="img-circle avatar"
                                    alt="User avatar"
                                  />
                                ) : (
                                  <a
                                    target="_blank"
                                    rel="noreferrer"
                                    className="small-dp user-color-green"
                                    href={`${MAIN_WEBSITE_URL}/${el.user.username}`}
                                    tabindex="0"
                                  >
                                    <img
                                      src={
                                        el.user.avatarObject
                                          ? el.user.avatarObject.thumb.location
                                          : null
                                      }
                                      alt={el.user.name}
                                      className="sidedLazyLoad img-circle avatar"
                                      style={{
                                        borderColor: el.user.statusColor,
                                      }}
                                      onError={(e) => {
                                        e.target.src = DEFAULT_USER_IMG_URL;
                                      }}
                                    />
                                    {el.user.isVerified ? (
                                      <FontAwesomeIcon
                                        style={{
                                          color: el.user.checkmarkColor,
                                        }}
                                        icon={faCheckCircle}
                                        className="procheckmark"
                                      />
                                    ) : null}
                                  </a>
                                )}
                              </div>
                            </div>
                            <div className="report-text-wrap ms-1 w-100">
                              <h6 className="m-0">
                                {el.user === null ? (
                                  "Guest User"
                                ) : (
                                  <a
                                    href={`${MAIN_WEBSITE_URL}/${el.user.username}`}
                                    className="customAnchor d-inline"
                                  >
                                    {" "}
                                    {el.user.name}
                                  </a>
                                )}{" "}
                                reported{" "}
                                <span className="reportType">{el.type}</span>
                                <small>
                                  {moment(el.createdAt).format(
                                    "MM/DD/YY @ hh:mm A",
                                  )}
                                </small>
                              </h6>
                            </div>
                          </div>
                          <div className="d-flex">
                            <div className="me-5"></div>
                            <div className="report-text-wrap ms-1 w-100">
                              <div className="report-text">{el.reason}</div>
                            </div>
                          </div>
                        </>
                      ))
                    : null}
                </div>
              </>
            ) : null}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  networks: state.networks.networksList,
  loggedInUser: state.login.user,
  selNetworkId: state.networks.selNetworkId,
});

const Extended = withTranslation()(ModerationClosedCases);
Extended.static = ModerationClosedCases.static;

export default connect(mapStateToProps, {})(Extended);
