import axios from "axios";
import {
  API_URL,
  DEBATES_PER_PAGE,
  TOP_DEBATES_PER_PAGE,
  SUGGESTED_DEBATES_PER_PAGE,
} from "../../constants/Globals";

export const clearDebates = () => (dispatch) => {
  dispatch({
    type: "DEBATES_LOADED",
    payload: {
      data: {
        count: 0,
        rows: [],
      },
    },
  });
};

export const loadDebates =
  (clientId = null, page = 1, search = "", isQuiz = false) =>
  (dispatch) => {
    if (!!clientId) {
      dispatch({
        type: "DEBATES_LOADING",
      });

      let queryParams = `?perPage=${DEBATES_PER_PAGE}&pageNumber=${page}&isQuiz=${isQuiz ? 1 : 0}`;

      if (!!clientId) {
        queryParams = queryParams + "&clientId=" + clientId;
      }

      if (search.trim() !== "") {
        queryParams = queryParams + "&searchText=" + search.trim();
      }

      axios
        .get(API_URL + "/admin/debate/getDebates" + queryParams)
        .then((res) =>
          dispatch({
            type: "DEBATES_LOADED",
            payload: res.data,
          }),
        )
        .catch((err) => {
          console.log("err", err);
        });
    }
  };

export const loadTopDebates =
  (clientId = null, page = 1) =>
  (dispatch) => {
    console.log("redux action loadTopDebates");
    if (!!clientId) {
      dispatch({
        type: "TOP_DEBATES_LOADING",
      });
      let queryParams =
        "?perPage=" + TOP_DEBATES_PER_PAGE + "&pageNumber=" + page;
      if (!!clientId) {
        queryParams = queryParams + "&clientId=" + clientId;
      }

      axios
        .get(API_URL + "/admin/debate/getPopularDebates" + queryParams)
        .then((res) =>
          dispatch({
            type: "TOP_DEBATES_LOADED",
            payload: res.data,
          }),
        )
        .catch((err) => {
          console.log("err", err);
        });
    }
  };

export const loadSuggestedDebates =
  (clientId = null, categoryId = null, page = 1) =>
  (dispatch) => {
    console.log("redux action loadSuggestedDebates");
    if (!!clientId) {
      dispatch({
        type: "SUGGESTED_DEBATES_LOADING",
      });
      let queryParams =
        "?perPage=" + SUGGESTED_DEBATES_PER_PAGE + "&pageNumber=" + page;
      if (!!clientId) {
        queryParams = queryParams + "&clientId=" + clientId;
      }
      if (!!categoryId) {
        queryParams = queryParams + "&categoryId=" + categoryId;
      }
      //console.log('queryParams',queryParams);
      axios
        .get(API_URL + "/admin/debate/getSuggestedDebates" + queryParams)
        .then((res) =>
          dispatch({
            type: "SUGGESTED_DEBATES_LOADED",
            payload: res.data,
          }),
        )
        .catch((err) => {
          console.log("err", err);
        });
    }
  };
