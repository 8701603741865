import React from "react";
import { Table, Modal, Form, Button, Tab, Nav } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCog } from "@fortawesome/free-solid-svg-icons";
import { connect } from "react-redux";
import {
  BASENAME,
  SIDED_FAVICON,
  USERS_PER_PAGE,
} from "../../constants/Globals";
import axios from "axios";
import { API_URL } from "../../constants/Globals";
import InviteModal from "../../components/InviteModal";
import { withTranslation } from "react-i18next";

class GroupUsers extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      searchText: "",
      order: "",
      orderBy: "title",
      showAddUserToGroupModal: false,
      showAdvanceUserSettingsModal: false,
      groupUsers: "",
      pendingInvites: "",
      advanceUserSettingsFormData: {
        groupsData: [],
        networksData: [],
      },
      addUserToGroupFormData: {
        groupId: "",
        userId: "",
        groupName: "",
      },
      showSuccessMsg: false,
      advanceUserSettingsModalContent: "",
      availableGroups: "",
      groupId: "",
      groupSlug: this.props.match.params.groupSlug
        ? this.props.match.params.groupSlug
        : "",
      groupsAndNetworksByUser: "",
      showInviteModal: false,
      showPendingInvites: false,
      inviteEmail: "",
      inviteRole: "",
    };
  }

  getGroupDetail = async (e) => {
    await axios
      .get(
        API_URL +
          "/admin/groups/groupDetailBySlug?groupSlug=" +
          this.state.groupSlug,
      )
      .then((res) => {
        var addUserToGroupFormData = { ...this.state.addUserToGroupFormData };
        addUserToGroupFormData["groupName"] = res.data.data.name;
        this.setState({ addUserToGroupFormData });
        this.setState({ groupId: res.data.data.id });
      })
      .catch((err) => {
        alert("There is a problem in fetching group detail. Please try again!");
        console.log(err);
      });
  };

  getGroupUsers = async (e) => {
    if (this.state.groupId === "") {
      await this.getGroupDetail();
    }
    await this.setState({
      loading: true,
    });

    const { groupId } = this.state;
    var urlToHit =
      "/admin/groups/getGroupUsers?searchText=" +
      this.state.searchText +
      "&perPage=" +
      USERS_PER_PAGE +
      "&pageNumber=1&groupId=" +
      groupId;

    await axios
      .get(API_URL + urlToHit)
      .then((res) => {
        this.setState({
          groupUsers: res.data.data,
          loading: false,
        });
      })
      .catch((err) => {
        alert("Failed. Please try again!");
        console.log(err);
      });
  };

  componentDidMount = async () => {
    this.getGroupUsers();
    this.fetchPendingInvites();
  };

  componentDidUpdate = async (prevProps, prevState) => {
    if (
      prevProps.selGroupSlug !== null &&
      prevProps.selGroupSlug !== this.props.selGroupSlug
    ) {
      window.history.pushState(
        "",
        "",
        BASENAME + "/groups/" + this.props.selGroupSlug + "/users",
      );
      await this.setState({ groupId: "", groupSlug: this.props.selGroupSlug });
      await this.getGroupUsers();
      await this.fetchPendingInvites();
    }
  };

  handleSearchSubmit = async (e) => {
    if (this.state.showPendingInvites) {
      this.fetchPendingInvites();
    } else {
      this.getGroupUsers();
    }
    e.preventDefault();
  };
  handleQueryChange = async (e) => {
    this.setState({ searchText: e.target.value });
  };

  showAddUserToGroupModal = async () => {
    this.setState({
      showAddUserToGroupModal: true,
      showAdvanceUserSettingsModal: false,
    });
  };

  hideAddUserToGroupModal = async (e) => {
    this.setState({
      showAddUserToGroupModal: false,
      showSuccessMsg: false,
      showAdvanceUserSettingsModal: true,
    });
    var addUserToGroupFormData = { ...this.state.addUserToGroupFormData };
    addUserToGroupFormData["groupId"] = "";
    await this.setState({ addUserToGroupFormData });
  };

  showAdvanceUserSettingsModal = async (e, i, currUserId) => {
    this.getGroupsAndNetworksByUser(currUserId);

    this.getAvailableGroups(currUserId);

    var addUserToGroupFormData = { ...this.state.addUserToGroupFormData };
    addUserToGroupFormData["userId"] = currUserId;
    await this.setState({ addUserToGroupFormData });

    await this.setState({
      advanceUserSettingsModalContent: this.state.groupUsers.rows[i],
    });

    this.setState({
      showAdvanceUserSettingsModal: true,
    });
  };

  hideAdvanceUserSettingsModal = async (e) => {
    this.setState({
      showAdvanceUserSettingsModal: false,
      showSuccessMsg: false,
    });
    var addUserToGroupFormData = { ...this.state.addUserToGroupFormData };
    addUserToGroupFormData["groupId"] = "";
    addUserToGroupFormData["userId"] = "";
    await this.setState({ addUserToGroupFormData });
  };

  groupFormChangeHandler = async (e) => {
    var index = e.nativeEvent.target.selectedIndex;
    var addUserToGroupFormData = { ...this.state.addUserToGroupFormData };
    addUserToGroupFormData[e.target.name] = e.target.value;
    addUserToGroupFormData["selectedGroup"] = e.nativeEvent.target[index].text;
    await this.setState({ addUserToGroupFormData });
  };

  submitAddUserToGroupHandler = async (e) => {
    const { userId } = this.state.addUserToGroupFormData;
    e.preventDefault();
    await this.setState({
      loading: true,
    });

    await axios
      .post(
        API_URL + "/admin/groups/addGroupUser",
        this.state.addUserToGroupFormData,
      )
      .then((res) => {
        this.getGroupUsers();
        this.getGroupsAndNetworksByUser(userId);
        this.getAvailableGroups(userId);
        this.messageNotification(
          "success",
          "User added to group successfully!",
        );
        this.setState({ loading: false, showSuccessMsg: true });
      })
      .catch((err) => {
        alert("There is a problem in adding user to group. Please try again!");
        console.log(err);
        this.setState({ loading: false });
      });
    //this.hideAddUserToGroupModal();
  };

  messageNotification = async (type, message) => {
    this.setState({
      successMsg: message,
    });

    setTimeout(() => {
      this.setState({
        successMsg: "",
      });
    }, 5000);
  };

  removeUserFromGroupNetwork = async (e, userId) => {
    await this.setState({
      loading: true,
    });

    var addUserToGroupFormData = { ...this.state.addUserToGroupFormData };
    addUserToGroupFormData["userId"] = userId;
    addUserToGroupFormData["groupId"] = this.state.groupId;
    await this.setState({ addUserToGroupFormData });

    await axios
      .post(
        API_URL + "/admin/groups/removeGroupUser",
        this.state.addUserToGroupFormData,
      )
      .then((res) => {
        this.setState({ groupUsers: "" });
        this.getGroupUsers();
        this.messageNotification(
          "success",
          "User removed from group successfully!",
        );
        this.setState({ loading: false });

        var addUserToGroupFormData = { ...this.state.addUserToGroupFormData };
        addUserToGroupFormData["groupId"] = "";
        this.setState({ addUserToGroupFormData });
      })
      .catch((err) => {
        alert(
          "There is a problem in removing user from network. Please try again!",
        );
        console.log(err);
        this.setState({ loading: false });
      });
  };

  getAvailableGroups = async (currUserId) => {
    await axios
      .get(
        API_URL +
          "/admin/groups/getAvailableGroups?order=asc&orderBy=title&userId=" +
          currUserId,
      )
      .then((res) => {
        this.setState({ availableGroups: res.data.data });
        console.log(res.data.data);
      })
      .catch((err) => {
        alert(
          "There is a problem in fetching available groups. Please try again!",
        );
        console.log(err);
        this.setState({ loading: false });
      });
  };

  getGroupsAndNetworksByUser = async (currUserId) => {
    await axios
      .get(
        API_URL +
          "/admin/groups/getGroupUsersAdvancedSettings?userId=" +
          currUserId,
      )
      .then((res) => {
        this.setState({ groupsAndNetworksByUser: res.data.data });
      })
      .catch((err) => {
        alert(
          "There is a problem in fetching Groups and Networks. Please try again!",
        );
        console.log(err);
        this.setState({ loading: false });
      });
  };

  AUSForGroupToggleChangeHandle = async (e, groupId) => {
    var advanceUserSettingsFormData = {
      ...this.state.advanceUserSettingsFormData,
    };
    var groupsData = {
      groupId: groupId,
      [e.target.name]: e.target.checked ? 1 : 0,
    };

    if (e.target.name === "roleGroupAdmin") {
      groupsData["roleGroupEditor"] = e.target.checked === false ? 1 : 0;
    }

    advanceUserSettingsFormData["groupsData"].push(groupsData);
    await this.setState({ advanceUserSettingsFormData });
    console.log(this.state.advanceUserSettingsFormData);
  };
  AUSForNetworkToggleChangeHandle = async (e, networkId) => {
    var advanceUserSettingsFormData = {
      ...this.state.advanceUserSettingsFormData,
    };
    var networksData = {
      networkId: networkId,
      [e.target.name]: e.target.checked ? 1 : 0,
    };

    if (e.target.name === "roleNetworkAdmin") {
      networksData["roleNetworkEditor"] = e.target.checked === false ? 1 : 0;
    }

    advanceUserSettingsFormData["networksData"].push(networksData);
    await this.setState({ advanceUserSettingsFormData });
    console.log(this.state.advanceUserSettingsFormData);
  };

  submitAUSFormHandler = async (e) => {
    const { userId } = this.state.addUserToGroupFormData;
    e.preventDefault();
    await this.setState({
      loading: true,
    });

    await axios
      .post(
        API_URL + "/admin/groups/updateUserAdvanceSettings/" + userId,
        this.state.advanceUserSettingsFormData,
      )
      .then((res) => {
        this.setState({
          groupsAndNetworksByUser: "",
          loading: false,
          showSuccessMsg: true,
        });
        this.getGroupUsers();
        this.getGroupsAndNetworksByUser(userId);
        this.getAvailableGroups(userId);
        this.messageNotification(
          "success",
          "Groups and networks level roles are updated for this user successfully!",
        );

        var advanceUserSettingsFormData = {
          ...this.state.advanceUserSettingsFormData,
        };
        advanceUserSettingsFormData["groupsData"] = [];
        advanceUserSettingsFormData["networksData"] = [];
        this.setState({ advanceUserSettingsFormData });
      })
      .catch((err) => {
        alert(
          "There is a problem in updating groups and networks level roles for this user. Please try again!",
        );
        console.log(err);
        this.setState({ loading: false });
      });
    //this.hideAddUserToGroupNetworkModal();
  };

  roleToggleChangeHandle = async (e, userId) => {
    await this.setState({
      loading: true,
    });

    var roleData = {
      groupId: this.state.groupId,
      [e.target.name]: e.target.checked ? 1 : 0,
    };

    if (e.target.name === "roleGroupAdmin") {
      roleData["roleGroupEditor"] = e.target.checked === false ? 1 : 0;
    }

    await axios
      .post(
        API_URL + "/admin/groups/updateUserGroupSettings/" + userId,
        roleData,
      )
      .then((res) => {
        this.setState({ groupUsers: "", loading: false });
        this.getGroupUsers();
        roleData = "";
      })
      .catch((err) => {
        alert("There is a problem in changing user role. Please try again!");
        console.log(err);
        roleData = "";
        this.setState({ loading: false });
      });
  };

  fetchPendingInvites = async (e) => {
    if (this.state.groupId === "") {
      await this.getGroupDetail();
    }
    await this.setState({
      loading: true,
    });

    const { groupId } = this.state;
    var urlToHit =
      "/admin/groups/getPendingInvitesForGroup?searchText=" +
      this.state.searchText +
      "&groupId=" +
      groupId;

    await axios
      .get(API_URL + urlToHit)
      .then((res) => {
        this.setState({
          pendingInvites: res.data.data,
          loading: false,
        });
      })
      .catch((err) => {
        alert("Failed. Please try again!");
        console.log(err);
      });
  };

  resendInvite = async (e, inviteEmail, inviteRole) => {
    await this.setState({
      showInviteModal: true,
      inviteEmail: inviteEmail,
      inviteRole: inviteRole,
    });
  };

  render() {
    const { networks, selNetworkId } = this.props;
    const {
      loading,
      groupUsers,
      showSuccessMsg,
      advanceUserSettingsModalContent,
      availableGroups,
      groupsAndNetworksByUser,
      showInviteModal,
      pendingInvites,
    } = this.state;
    const { groupId, groupName, selectedGroup } =
      this.state.addUserToGroupFormData;
    const { groupsData, networksData } = this.state.advanceUserSettingsFormData;
    const kTrans = this.props.t ? this.props.t : "";

    return (
      <section className={loading === true ? "loading" : ""}>
        {this.state.successMsg ? (
          <div className="sucessMsg">{this.state.successMsg}</div>
        ) : null}
        <div className="inline-content top title-section mb-2">
          <h2>{kTrans("Your Group Users")}</h2>
          <span
            onClick={() => this.setState({ showInviteModal: true })}
            className="btn btn-primary"
          >
            + {kTrans("Invite User(s)")}
          </span>
        </div>
        <h3 className="mb-4">
          <span className="lightColorSpan large">
            <NavLink
              className="d-inline-block"
              exact
              activeClassName="active"
              to="/groups"
            >
              {kTrans("All Groups")}
            </NavLink>
            &nbsp;/&nbsp;
          </span>
          <span>
            {groupName} {kTrans("Users")}
          </span>
        </h3>
        <div className="mb-3">
          <form
            id="search"
            autoComplete="off"
            className="navbar-form navbar-left w-25"
            method="get"
            onSubmit={this.handleSearchSubmit}
          >
            <input
              onChange={this.handleQueryChange}
              type="text"
              className="form-control"
              value={this.state.searchText}
              name="searchText"
              placeholder={kTrans("Search")}
            />
            <button type="submit">
              <i className="fa fa-search"></i>
            </button>
          </form>
        </div>

        <Tab.Container id="left-tabs-example" defaultActiveKey="all-users">
          <Nav
            variant="pills"
            className="anchorLikeTabs d-flex align-item-center mt-4 mb-4"
          >
            <small>
              <strong>
                {groupUsers.count === 0 || groupUsers.count > 1
                  ? kTrans("Results")
                  : kTrans("Result")}
                : {groupUsers.count}
              </strong>
            </small>
            <Nav.Item className="ms-3">
              <Nav.Link eventKey="all-users">{kTrans("All Users")}</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="pending-invites">
                {kTrans("Pending Invites")}
              </Nav.Link>
            </Nav.Item>
          </Nav>
          <Tab.Content>
            <Tab.Pane eventKey="all-users" className="table-wrapper">
              <Table className="groupNetworkUsersTable tableCommon">
                <thead>
                  <tr>
                    <th>{kTrans("GROUP USERS")}</th>
                    <th>{kTrans("ADMIN")}</th>
                    <th>{kTrans("EDITOR")}</th>
                    <th>{kTrans("MODERATOR")}</th>
                    <th>{kTrans("ADVANCED SETTINGS")}</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {groupUsers.rows && groupUsers.rows.length ? (
                    groupUsers.rows.map((el, i) => (
                      <tr key={i}>
                        <td className="">
                          <strong>{el.name}</strong>
                          <span className="lightColorSpan">@{el.username}</span>
                        </td>
                        <td>
                          <Form.Group>
                            <Form.Check.Input
                              name="roleGroupAdmin"
                              className="toggle-checkbox"
                              defaultChecked={
                                el.roles.length > 0 &&
                                el.roles.find(
                                  (role) => role.name === "GROUPADMIN",
                                )
                              }
                              onChange={(e) =>
                                this.roleToggleChangeHandle(e, el.id)
                              }
                            />
                          </Form.Group>
                        </td>
                        <td>
                          <Form.Group>
                            <Form.Check.Input
                              className={`toggle-checkbox ${el.roles.find((role) => role.name === "GROUPADMIN") ? "disabled" : ""}`}
                              name="roleGroupEditor"
                              defaultChecked={
                                el.roles.length > 0 &&
                                (el.roles.find(
                                  (role) => role.name === "EDITOR",
                                ) ||
                                  el.roles.find(
                                    (role) => role.name === "GROUPADMIN",
                                  ))
                              }
                              onChange={(e) =>
                                this.roleToggleChangeHandle(e, el.id)
                              }
                            />
                          </Form.Group>
                        </td>
                        <td>
                          <Form.Group>
                            <Form.Check.Input
                              name="roleGroupModerator"
                              className={`toggle-checkbox ${el.roles.find((role) => role.name === "GROUPADMIN") ? "disabled" : ""}`}
                              defaultChecked={
                                el.roles.length > 0 &&
                                (el.roles.find(
                                  (role) => role.name === "MODERATOR",
                                ) ||
                                  el.roles.find(
                                    (role) => role.name === "GROUPADMIN",
                                  ))
                              }
                              onChange={(e) =>
                                this.roleToggleChangeHandle(e, el.id)
                              }
                            />
                          </Form.Group>
                        </td>

                        <td>
                          <FontAwesomeIcon
                            icon={faCog}
                            className="svg settings"
                            onClick={(e) =>
                              this.showAdvanceUserSettingsModal(e, i, el.id)
                            }
                          />
                        </td>
                        <td>
                          <span
                            className="customAnchor"
                            onClick={(e) => {
                              if (
                                window.confirm(
                                  kTrans(
                                    "Are you sure you want to remove this user from group?",
                                  ),
                                )
                              ) {
                                this.removeUserFromGroupNetwork(e, el.id);
                              }
                            }}
                          >
                            {kTrans("Remove")}
                          </span>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="6">
                        <h3 className="text-center">
                          {kTrans("No User Found")}
                        </h3>
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </Tab.Pane>
            <Tab.Pane eventKey="pending-invites" className="table-wrapper">
              <Table className="groupNetworkUsersTable tableCommon">
                <thead>
                  <tr>
                    <th>{kTrans("EMAIL")}</th>
                    <th>{kTrans("ADMIN")}</th>
                    <th>{kTrans("EDITOR")}</th>
                    <th>{kTrans("MODERATOR")}</th>
                  </tr>
                </thead>
                <tbody>
                  {pendingInvites && pendingInvites.length ? (
                    pendingInvites.map((el, i) => (
                      <tr key={i}>
                        <td className="">
                          <strong>{el.email}</strong>
                          {el.invite_expired ? (
                            <>
                              <span className="lightColorSpan">
                                {kTrans("Invite expired")} |{" "}
                              </span>
                              <span
                                onClick={(e) =>
                                  this.resendInvite(e, el.email, el.invite_as)
                                }
                                className="customAnchor"
                              >
                                {kTrans("Resend")}
                              </span>
                            </>
                          ) : null}
                        </td>
                        <td>
                          <Form.Group>
                            <Form.Check.Input
                              className="toggle-checkbox disabled"
                              defaultChecked={el.invite_as === "admin"}
                            />
                          </Form.Group>
                        </td>
                        <td>
                          <Form.Group>
                            <Form.Check.Input
                              className="toggle-checkbox disabled"
                              defaultChecked={el.invite_as === "editor"}
                            />
                          </Form.Group>
                        </td>
                        <td>
                          <Form.Group>
                            <Form.Check.Input
                              className="toggle-checkbox disabled"
                              defaultChecked={el.invite_as === "moderator"}
                            />
                          </Form.Group>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="6">
                        <h3 className="text-center">
                          {kTrans("No User Found")}
                        </h3>
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
        <Modal
          className="advanceUserSettingsModal"
          size="lg"
          show={this.state.showAdvanceUserSettingsModal}
        >
          <Modal.Header>
            <Modal.Title>{kTrans("Advanced User Settings")}</Modal.Title>
            <span className="modal-close-btn">
              <b className="" onClick={this.hideAdvanceUserSettingsModal}>
                &#x2715;
              </b>
            </span>
          </Modal.Header>
          <Modal.Body className={`mt-3 ${loading === true ? "loading" : ""}`}>
            <div className="modalContentWrapper ms-md-4 me-md-4">
              <div className="inline-content middle mb-4">
                <p className="userNameSection">
                  {advanceUserSettingsModalContent.name}
                  <small>@{advanceUserSettingsModalContent.username}</small>
                </p>
                <div className="rightSection">
                  <span
                    onClick={this.showAddUserToGroupModal}
                    className="btn btn-primary"
                  >
                    + {kTrans("Add to Group")}
                  </span>
                </div>
              </div>
              <Form
                className="updateGroupUserSettingsForm common-form pt-4"
                onSubmit={this.submitAUSFormHandler}
              >
                {groupsAndNetworksByUser.groups &&
                groupsAndNetworksByUser.groups.length ? (
                  <div className="table-wrapper">
                    <Table className="groupNetworkUsersTable tableCommon mb-4">
                      <thead>
                        <tr>
                          <th className="w-25">{kTrans("GROUP(S)")}</th>
                          <th>{kTrans("ADMIN")}</th>
                          <th>{kTrans("EDITOR")}</th>
                          <th>{kTrans("MODERATOR")}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {groupsAndNetworksByUser.groups.map((el, i) => (
                          <tr key={i}>
                            <td className="">{el.GroupName}</td>
                            <td>
                              <Form.Group>
                                <Form.Check.Input
                                  name="roleGroupAdmin"
                                  className="toggle-checkbox"
                                  defaultChecked={
                                    el.roles.length > 0 &&
                                    el.roles.find(
                                      (role) => role.name === "GROUPADMIN",
                                    )
                                  }
                                  onChange={(e) =>
                                    this.AUSForGroupToggleChangeHandle(
                                      e,
                                      el.GroupId,
                                    )
                                  }
                                />
                              </Form.Group>
                            </td>
                            <td>
                              <Form.Group>
                                <Form.Check.Input
                                  name="roleGroupEditor"
                                  className={`toggle-checkbox ${el.roles.find((role) => role.name === "GROUPADMIN") ? "disabled" : ""}`}
                                  defaultChecked={
                                    el.roles.length > 0 &&
                                    (el.roles.find(
                                      (role) => role.name === "EDITOR",
                                    ) ||
                                      el.roles.find(
                                        (role) => role.name === "GROUPADMIN",
                                      ))
                                  }
                                  onChange={(e) =>
                                    this.AUSForGroupToggleChangeHandle(
                                      e,
                                      el.GroupId,
                                    )
                                  }
                                />
                              </Form.Group>
                            </td>
                            <td>
                              <Form.Group>
                                <Form.Check.Input
                                  name="roleGroupModerator"
                                  className={`toggle-checkbox ${el.roles.find((role) => role.name === "GROUPADMIN") ? "disabled" : ""}`}
                                  defaultChecked={
                                    el.roles.length > 0 &&
                                    (el.roles.find(
                                      (role) => role.name === "MODERATOR",
                                    ) ||
                                      el.roles.find(
                                        (role) => role.name === "GROUPADMIN",
                                      ))
                                  }
                                  onChange={(e) =>
                                    this.AUSForGroupToggleChangeHandle(
                                      e,
                                      el.GroupId,
                                    )
                                  }
                                />
                              </Form.Group>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                ) : null}

                {groupsAndNetworksByUser.networks &&
                groupsAndNetworksByUser.networks.length ? (
                  <div className="table-wrapper">
                    <Table className="groupNetworkUsersTable tableCommon">
                      <thead>
                        <tr>
                          <th className="w-25">{kTrans("NETWORK(S)")}</th>
                          <th>{kTrans("ADMIN")}</th>
                          <th>{kTrans("EDITOR")}</th>
                          <th>{kTrans("MODERATOR")}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {groupsAndNetworksByUser.networks.map((el, i) => (
                          <tr key={i}>
                            <td className="">
                              {el.clientTitle}
                              {el.parentGroupName ? (
                                <span className="d-block text-break lightColorSpan">
                                  {kTrans("Group")}: {el.parentGroupName}
                                </span>
                              ) : null}
                            </td>
                            <td>
                              <Form.Group>
                                <Form.Check.Input
                                  name="roleNetworkAdmin"
                                  className="toggle-checkbox"
                                  defaultChecked={
                                    el.roles.length > 0 &&
                                    el.roles.find(
                                      (role) => role.name === "ADMIN",
                                    )
                                  }
                                  onChange={(e) =>
                                    this.AUSForNetworkToggleChangeHandle(
                                      e,
                                      el.clientId,
                                    )
                                  }
                                />
                              </Form.Group>
                            </td>
                            <td>
                              <Form.Group>
                                <Form.Check.Input
                                  className={`toggle-checkbox ${el.roles.find((role) => role.name === "ADMIN") ? "disabled" : ""}`}
                                  name="roleNetworkEditor"
                                  defaultChecked={
                                    el.roles.length > 0 &&
                                    (el.roles.find(
                                      (role) => role.name === "EDITOR",
                                    ) ||
                                      el.roles.find(
                                        (role) => role.name === "ADMIN",
                                      ))
                                  }
                                  onChange={(e) =>
                                    this.AUSForNetworkToggleChangeHandle(
                                      e,
                                      el.clientId,
                                    )
                                  }
                                />
                              </Form.Group>
                            </td>
                            <td>
                              <Form.Group>
                                <Form.Check.Input
                                  name="roleNetworkModerator"
                                  className={`toggle-checkbox ${el.roles.find((role) => role.name === "ADMIN") ? "disabled" : ""}`}
                                  defaultChecked={
                                    el.roles.length > 0 &&
                                    (el.roles.find(
                                      (role) => role.name === "MODERATOR",
                                    ) ||
                                      el.roles.find(
                                        (role) => role.name === "ADMIN",
                                      ))
                                  }
                                  onChange={(e) =>
                                    this.AUSForNetworkToggleChangeHandle(
                                      e,
                                      el.clientId,
                                    )
                                  }
                                />
                              </Form.Group>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                ) : null}
                <div className="buttons text-center">
                  <Button
                    className={`m-0`}
                    onClick={this.hideAdvanceUserSettingsModal}
                    variant="secondary"
                  >
                    {kTrans("Cancel")}
                  </Button>
                  <Button
                    className={`m-0 ms-2 ${groupsData.length > 0 || networksData.length > 0 ? "" : "disabled"}`}
                    type="submit"
                    variant="primary"
                  >
                    {kTrans("Save")}
                  </Button>
                </div>
              </Form>
            </div>
          </Modal.Body>
        </Modal>

        <Modal
          className="addGroupModal"
          size="lg"
          show={this.state.showAddUserToGroupModal}
        >
          <Modal.Body className={loading === true ? "loading" : ""}>
            <div className="modalContentWrapper">
              <img
                className="sidedFavicon"
                src={SIDED_FAVICON}
                alt="Sided favicon"
              />
              <h5 className="txtCenter mb-3">{kTrans("Add User to Group")}</h5>
              <p className="txtCenter">
                {kTrans("Select any of the groups below")}:
              </p>
              <Form
                className="AddUserToGroupForm common-form"
                onSubmit={this.submitAddUserToGroupHandler}
              >
                {showSuccessMsg ? (
                  <div className="txtCenter pt-5 pb-5 mb-2">
                    <p>
                      <strong>{kTrans("Success")}!</strong>
                    </p>
                    <p>
                      {kTrans(
                        "You have just added the following user to group",
                      )}{" "}
                      {selectedGroup}:
                    </p>
                    <h4 className="">{advanceUserSettingsModalContent.name}</h4>
                  </div>
                ) : (
                  <>
                    <Form.Group controlId="groupId" className="p-0">
                      <Form.Label>{kTrans("Available Groups")}</Form.Label>
                      {availableGroups && availableGroups.length > 0 ? (
                        <Form.Control
                          className="networkId"
                          as="select"
                          data-required="true"
                          onChange={this.groupFormChangeHandler}
                          name="groupId"
                          defaultValue={groupId}
                          htmlSize="5"
                        >
                          <option value="">{kTrans("Select a Group")}</option>
                          {availableGroups.map((el, i) => (
                            <option key={el.id} value={el.id} data-id={el.id}>
                              {el.name}
                            </option>
                          ))}
                        </Form.Control>
                      ) : (
                        kTrans("No group available!")
                      )}
                    </Form.Group>
                    <span className="d-block customAnchor mt-4 mb-4"></span>
                  </>
                )}
                <div className="mt-5 pt-5 buttons text-center inline-content">
                  <Button
                    className={`m-0 ${showSuccessMsg ? "disabled" : ""}`}
                    onClick={this.hideAddUserToGroupModal}
                    variant="secondary"
                  >
                    {kTrans("Cancel")}
                  </Button>
                  {showSuccessMsg ? (
                    <span
                      className="m-0 btn btn-primary"
                      onClick={this.hideAddUserToGroupModal}
                    >
                      Done
                    </span>
                  ) : (
                    <Button
                      className={`m-0 ${groupId ? "" : "disabled"}`}
                      variant={groupId ? "primary" : "secondary"}
                      type="submit"
                    >
                      {kTrans("Add")}
                    </Button>
                  )}
                </div>
              </Form>
            </div>
          </Modal.Body>
        </Modal>

        {showInviteModal ? (
          <InviteModal
            networksList={networks}
            selNetworkId={selNetworkId}
            onClose={() =>
              this.setState({
                showInviteModal: false,
                inviteEmail: "",
                inviteRole: "",
              })
            }
            isForGroup={true}
            groupId={this.state.groupId}
            groupName={groupName}
            inviteEmail={this.state.inviteEmail}
            inviteRole={this.state.inviteRole}
          />
        ) : null}
      </section>
    );
  }
}

const mapStateToProps = (state) => ({
  loggedInUser: state.login.user,
  networks: state.networks.networksList,
  selNetworkId: state.networks.selNetworkId,
  selGroupSlug: state.networks.selGroupSlug,
});

const Extended = withTranslation()(GroupUsers);
Extended.static = GroupUsers.static;

export default connect(mapStateToProps, {})(Extended);
