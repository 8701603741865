import React from "react";
import update from "react-addons-update";
import { Row, Col, Button, Form, Modal, Tabs, Tab } from "react-bootstrap";
import axios from "axios";
import { connect } from "react-redux";
import {
  API_URL,
  MAIN_NETWORK_NAME,
  BASENAME,
  DEFAULT_DEBATE_DURATION,
  MAX_DEBATE_DURATION,
} from "../../constants/Globals";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { NavLink } from "react-router-dom";
import {
  saveSelectedNetwork,
  loadNetworksList,
} from "../../redux/action/networks.action";
import IntegrationSettingsTab from "./IntegrationSettingsTab";
import AddBrandAccount from "./AddBrandAccount";
import { withTranslation } from "react-i18next";

class EditNetwork extends React.Component {
  constructor(props) {
    super(props);
    this.settingsNavLink = React.createRef();
    this.state = {
      loading: true,
      clientId: this.props.match.params.id,
      isChecked: false,
      catFieldHidden: true,
      newCatVal: "",
      cats: "",
      archiveToggleModal: false,
      validationErrors: {
        name: "",
        subDomain: "",
        customDomain: "",
        title: "",
        categories: "",
        fbLink: "",
        instagramLink: "",
        twitterLink: "",
        linkedinLink: "",
        supportEmail: "",
        maxDebateDuration: "",
        domain: "",
        affiliateLink: "",
        primaryDomain: "",
      },
      validation: true,
      successMsg: "",

      id: "",
      name: "",
      subDomain: "",
      customDomain: "",
      domain: "",
      type: "publisher",
      isPrivate: 0,
      advertising: 0,
      isPointActive: 0,
      isDefault: 0,
      isFeatured: 0,
      apiAccess: 0,
      isSpanish: 0,
      isAutomated: 0,
      isMultiple: 0,
      gaTracking: 0,
      aiContent: 0,
      sidebarAds: 0,
      redirectSidePanel: 0,
      isAllDebateCreator: 0,
      defaultDebateDuration: "",
      maxDebateDuration: "",
      title: "",
      logo: "",
      logoWidth: "",
      logoHeight: "",
      favicon: "",
      emailLogo: "",
      fbClientId: "",
      fbClientSecret: "",
      twitterClientId: "",
      twitterClientSecret: "",
      fbLink: "",
      twitterLink: "",
      instagramLink: "",
      linkedinLink: "",
      advertisingSource: "",
      webAdvertisingSource: "",
      requireEmail: 0,
      googleAnalyticsId: "",
      gamId: "",
      fbPixelId: "",
      supportName: "",
      supportEmail: "",
      categories: "",
      embedPlacements: "",
      allowedAnalyticDomains: 0,
      revenueShare: 50,
      datablocksSid: "",
      images: [
        {
          image_url: "",
          image_type: "",
        },
        {
          image_url: "",
          image_type: "",
        },
        {
          image_url: "",
          image_type: "",
        },
      ],
      embedCode: null,
      showCopyEmbaedModal: false,
      approvedDomainsList: "",
      affiliateLink: "",
      affiliateButtonText: "",
      archive: 0,
      primaryDomain: "",
      hasPrimaryDomain: false,
    };
  }

  componentDidMount() {
    this.fetchCurreNetwork();
  }

  fetchCurreNetwork = async (clientIdAttr) => {
    await this.setState({
      loading: true,
    });

    await this.setState({
      clientId: clientIdAttr ? clientIdAttr : this.props.match.params.id,
    });

    await this.fetchApprovedDomaine();

    await axios
      .get(API_URL + "/admin/client/" + this.state.clientId)
      .then((res) => {
        if (res.data.brandAccount) {
          this.setState({ brandAccount: res.data.brandAccount });
        }
        var wlData = res.data.data;

        this.setState({
          embedCodeObject:
            wlData.embedCodeObject !== undefined ? wlData.embedCodeObject : "",
          id: wlData.id !== undefined ? wlData.id : "",
          name: wlData.name !== undefined ? wlData.name : "",
          subDomain: wlData.siteDomain !== undefined ? wlData.siteDomain : "",
          customDomain:
            wlData.customDomain !== undefined ? wlData.customDomain : "",
          isPrivate: wlData.isPrivate !== undefined ? wlData.isPrivate : 0,
          advertising:
            wlData.advertising !== undefined ? wlData.advertising : 0,
          isPointActive:
            wlData.isPointActive !== undefined ? wlData.isPointActive : 0,
          isDefault: wlData.isDefault !== undefined ? wlData.isDefault : 0,
          isFeatured: wlData.isFeatured !== undefined ? wlData.isFeatured : 0,
          apiAccess: wlData.apiAccess !== undefined ? wlData.apiAccess : 0,
          isSpanish: wlData.isSpanish !== undefined ? wlData.isSpanish : 0,
          isAutomated:
            wlData.isAutomated !== undefined ? wlData.isAutomated : 0,
          isMultiple: wlData.isMultiple !== undefined ? wlData.isMultiple : 0,
          gaTracking: wlData.gaTracking !== undefined ? wlData.gaTracking : 0,
          aiContent: wlData.aiContent !== undefined ? wlData.aiContent : 0,
          sidebarAds: wlData.sidebarAds !== undefined ? wlData.sidebarAds : 0,
          redirectSidePanel:
            wlData.redirectSidePanel !== undefined
              ? wlData.redirectSidePanel
              : 0,
          isAllDebateCreator:
            wlData.isAllDebateCreator !== undefined
              ? wlData.isAllDebateCreator
              : 0,
          defaultDebateDuration:
            wlData.extra.defaultDebateDuration !== undefined
              ? wlData.extra.defaultDebateDuration
              : "",
          maxDebateDuration:
            (wlData.extra.maxDebateDuration !== undefined) !== undefined
              ? wlData.extra.maxDebateDuration
              : "",
          title: wlData.header.title !== undefined ? wlData.header.title : "",
          logo: wlData.header.logo !== undefined ? wlData.header.logo : "",
          favicon:
            wlData.header.favicon !== undefined ? wlData.header.favicon : "",
          emailLogo:
            wlData.header.emailLogo !== undefined
              ? wlData.header.emailLogo
              : "",
          fbClientId:
            wlData.social.app.facebook !== undefined &&
            wlData.social.app.facebook.client_id !== undefined
              ? wlData.social.app.facebook.client_id
              : "",
          fbClientSecret:
            wlData.social.app.facebook !== undefined &&
            wlData.social.app.facebook.client_secret !== undefined
              ? wlData.social.app.facebook.client_secret
              : "",
          twitterClientId:
            wlData.social.app.twitter !== undefined &&
            wlData.social.app.twitter.client_id !== undefined
              ? wlData.social.app.twitter.client_id
              : "",
          twitterClientSecret:
            wlData.social.app.twitter !== undefined &&
            wlData.social.app.twitter.client_secret !== undefined
              ? wlData.social.app.twitter.client_secret
              : "",
          fbLink:
            wlData.social.links.facebook !== undefined
              ? wlData.social.links.facebook
              : "",
          twitterLink:
            wlData.social.links.twitter !== undefined
              ? wlData.social.links.twitter
              : "",
          instagramLink:
            wlData.social.links.instagram !== undefined
              ? wlData.social.links.instagram
              : "",
          linkedinLink:
            wlData.social.links.linkedin !== undefined
              ? wlData.social.links.linkedin
              : "",
          advertisingSource:
            wlData.advertisingSource !== undefined
              ? wlData.advertisingSource
              : "",
          webAdvertisingSource:
            wlData.webAdvertisingSource !== undefined
              ? wlData.webAdvertisingSource
              : "",
          requireEmail:
            typeof wlData.requireEmail !== "undefined"
              ? wlData.requireEmail
              : 0,
          googleAnalyticsId:
            wlData.analytics.id !== undefined ? wlData.analytics.id : "",
          gamId: wlData.extra.gamId !== undefined ? wlData.extra.gamId : "",
          fbPixelId:
            wlData.extra.fbPixelId !== undefined ? wlData.extra.fbPixelId : "",
          supportName: wlData.extra.name !== undefined ? wlData.extra.name : "",
          supportEmail:
            wlData.extra.support !== undefined ? wlData.extra.support : "",
          categories: "",
          embedPlacements: "",
          allowedAnalyticDomains:
            wlData.allowedAnalyticDomains !== undefined
              ? wlData.allowedAnalyticDomains
              : 0,
          affiliateLink: wlData.affiliateLink || "",
          affiliateButtonText:
            wlData.affiliateButtonText !== undefined
              ? wlData.affiliateButtonText
              : "",
          archive: wlData.archive !== undefined ? wlData.archive : 0,
          revenueShare: wlData.revenueShare || 0,
          datablocksSid: wlData.datablocksSid || "",
        });

        if (wlData.categories) {
          for (let i = 0; i < wlData.categories.length; i++) {
            if (i > 50) {
              break;
            }

            this.setState((prevState) => ({
              categories: [
                ...prevState.categories,
                {
                  id: wlData.categories[i].id,
                  text: wlData.categories[i].text,
                  imageUrl: wlData.categories[i].imageObject,
                  tempPath: wlData.categories[i].imageObject,
                  featured: wlData.categories[i].featured,
                  archive: wlData.categories[i].archive,
                  adminOnly: wlData.categories[i].adminOnly,
                },
              ],
            }));
          }
        }

        if (wlData.embedPlacements) {
          for (let i = 0; i < wlData.embedPlacements.length; i++) {
            this.setState((prevState) => ({
              embedPlacements: [
                ...prevState.embedPlacements,
                {
                  id: wlData.embedPlacements[i].id,
                  placementName: wlData.embedPlacements[i].placementName,
                },
              ],
            }));
          }
        }

        this.setState({
          loading: false,
          formDataLoaded: true,
        });

        if (!this.state.archive) {
          this.props.saveSelectedNetwork(this.state.clientId);
        }
      });
  };

  uploadCatImages = async (e) => {
    let catArray = this.state.categories;
    var image_type = "CATEGORY_IMAGE";
    let failure = false;
    for (let i = 0; i < catArray.length; i++) {
      if (catArray[i].imageUrl !== "" && catArray[i].imageUrl !== null) {
        if (catArray[i].imageUrl.name === undefined) {
          continue;
        }
        const formData = new FormData();
        formData.append(
          "image",
          catArray[i].imageUrl,
          catArray[i].imageUrl.name,
        );

        await axios
          .post(
            API_URL +
              "/admin/client/imageUpload?type=" +
              image_type +
              "&clientName=" +
              this.state.name,
            formData,
          )
          .then((res) => {
            this.setState(
              update(this.state, {
                categories: {
                  [i]: {
                    imageUrl: {
                      $set: res.data.data.id,
                    },
                    tempPath: {
                      $set: "",
                    },
                  },
                },
              }),
            );
          });
      }
    }
    if (failure === false) {
      this.uploadLogoImages();
    }
  };

  uploadLogoImages = async (e) => {
    let imageArray = this.state.images;
    let failure = false;

    for (var i = 0; i < imageArray.length; i++) {
      if (imageArray[i].image_url !== "") {
        var image_type = imageArray[i].image_type;
        const formData = new FormData();
        formData.append(
          "image",
          imageArray[i].image_url,
          imageArray[i].image_url.name,
        );

        var imgUploadUrl =
          API_URL +
          "/admin/client/imageUpload?type=" +
          image_type +
          "&clientName=" +
          this.state.name;
        if (image_type === "FAVICON") {
          imgUploadUrl =
            API_URL +
            "/admin/client/faviconUpload?clientName=" +
            this.state.name;
        }
        const res = await axios.post(imgUploadUrl, formData);
        if (image_type === "LOGO") {
          this.setState({
            logo: res.data.data.id,
            logoWidth: res.data.data.width,
            logoHeight: res.data.data.height,
          });
        } else if (image_type === "FAVICON") {
          this.setState({
            favicon: res.data.data.id,
          });
        } else {
          this.setState({
            emailLogo: res.data.data.id,
          });
        }
      }
    }

    if (failure === false) {
      this.submitFormData();
    }
  };

  submitFormData = async (e) => {
    const { categories, aiContent, clientId } = this.state;

    if (categories) {
      categories.sort(
        (firstItem, secondItem) => firstItem.archive - secondItem.archive,
      );
      await this.setState({
        categories: categories,
      });
    }

    const formData = this.state;
    var failure = false;

    await axios
      .put(API_URL + "/admin/client/update/" + clientId, formData)
      .then((res) => {
        console.log("Settings saved!");
      })
      .catch((err) => {
        console.log("Submit Form Errors");
        console.log(err.response.data.message);
        failure = true;
      });

    if (failure === false) {
      var selLanguage = this.state.isSpanish ? "es" : "en";
      this.props.i18n.changeLanguage(selLanguage);

      this.messageNotification("success", this.props.t("Settings saved!"));

      if (aiContent) {
        await axios.post(
          `${API_URL}/admin/debate/autoGenerateDebates?clientId=${clientId}`,
        );
      }

      await this.fetchCurreNetwork(this.state.clientId);

      this.setState({
        loading: false,
      });
    } else {
      alert("Form submission failed. Please try again.");
    }
  };

  formValidation = (e, action) => {
    if (e.target.name === "subDomain" || action === "onSubmit") {
      var subDomainTrim = this.state.subDomain
        .replace(".sided.co", "")
        .replace(".co", "");
      if (subDomainTrim.trim() === "") {
        this.setState(
          update(this.state, {
            validationErrors: {
              subDomain: {
                $set: "This field is required.",
              },
            },
          }),
        );
      } else if (!/^([a-z0-9-]+)?$/i.test(subDomainTrim)) {
        this.setState(
          update(this.state, {
            validationErrors: {
              subDomain: {
                $set: "Use only letter, number and -.",
              },
            },
          }),
        );
      } else {
        this.setState(
          update(this.state, {
            validationErrors: {
              subDomain: {
                $set: "",
              },
            },
          }),
        );
      }
    }

    if (e.target.name === "customDomain" || action === "onSubmit") {
      if (
        this.state.customDomain !== "" &&
        !/^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-zA-Z0-9]+([-.]{1}[a-zA-Z0-9]+)*\.[a-zA-Z]{2,5}(:[0-9]{1,5})?(\/.*)?$/i.test(
          this.state.customDomain,
        )
      ) {
        this.setState(
          update(this.state, {
            validationErrors: {
              customDomain: {
                $set: "The Domain Url is invalid.",
              },
            },
          }),
        );
      } else {
        this.setState(
          update(this.state, {
            validationErrors: {
              customDomain: {
                $set: "",
              },
            },
          }),
        );
      }
    }

    if (e.target.name === "primaryDomain" || action === "onSubmit") {
      if (this.state.primaryDomain === "") {
        this.setState(
          update(this.state, {
            validationErrors: {
              primaryDomain: {
                $set: "This field is required.",
              },
            },
          }),
        );
        /*} else if(this.state.primaryDomain !== "" && !(/^((?!www|!http)[a-z0-9-]+\.(?:com|co|in|net|org|ca|edu|gov|edu|info|xyz|ly|site|me|mil|int|us|co\.uk|co\.us|com\.ar))(?:\/|$)/gm.test(this.state.primaryDomain))){
				this.setState(update(this.state, {
					validationErrors: {
						primaryDomain: {
							$set: "The Domain Url is invalid."
						}
					}
				}));*/
      } else {
        this.setState(
          update(this.state, {
            validationErrors: {
              primaryDomain: {
                $set: "",
              },
            },
          }),
        );
      }
    }

    // if(e.target.name === 'domain'){
    // 	const regex = new RegExp('^(?!www)[a-z0-9]+([-.]{1}'+(this.state.primaryDomain)+'+)', 'gm');
    // 	console.log(regex);
    // 	if(this.state.domain !== "" && !(regex.test(this.state.domain))){
    // 		this.setState(update(this.state, {
    // 			validationErrors: {
    // 				domain: {
    // 					$set: "The Domain Url is invalid."
    // 				}
    // 			}
    // 		}));
    // 	} else{
    // 		this.setState(update(this.state, {
    // 			validationErrors: {
    // 				domain: {
    // 					$set: ""
    // 				}
    // 			}
    // 		}));
    // 	}
    // }

    if (e.target.name === "title" || action === "onSubmit") {
      if (this.state.title.trim() === "") {
        this.setState(
          update(this.state, {
            validationErrors: {
              title: {
                $set: "This field is required.",
              },
            },
          }),
        );
      } else {
        this.setState(
          update(this.state, {
            validationErrors: {
              title: {
                $set: "",
              },
            },
          }),
        );
      }
    }

    if (e.target.name === "text" || action === "onSubmit") {
      if (
        this.state.categories.length > 0 &&
        this.state.categories.find((element) => element.text.trim() === "")
      ) {
        this.setState(
          update(this.state, {
            validationErrors: {
              categories: {
                $set: "Category name is required.",
              },
            },
          }),
        );
      } else {
        this.setState(
          update(this.state, {
            validationErrors: {
              categories: {
                $set: "",
              },
            },
          }),
        );
      }
    }

    if (
      e.target.name === "fbLink" ||
      e.target.name === "instagramLink" ||
      e.target.name === "twitterLink" ||
      e.target.name === "linkedinLink" ||
      action === "onSubmit"
    ) {
      if (
        e.target.value !== "" &&
        !/^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-zA-Z0-9]+([-.]{1}[a-zA-Z0-9]+)*\.[a-zA-Z]{2,5}(:[0-9]{1,5})?(\/.*)?$/i.test(
          e.target.value,
        )
      ) {
        this.setState(
          update(this.state, {
            validationErrors: {
              [e.target.name]: {
                $set: "Invalid.",
              },
            },
          }),
        );
      } else {
        this.setState(
          update(this.state, {
            validationErrors: {
              [e.target.name]: {
                $set: "",
              },
            },
          }),
        );
      }
    }

    if (e.target.name === "supportEmail") {
      if (
        e.target.value !== "" &&
        !/^(([^<>!#$%^&*={}?/|'`~+()[\]\\.,;:\s@"]+(\.[^<>!#$%^&*={}?/|'`~+()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          e.target.value,
        )
      ) {
        this.setState(
          update(this.state, {
            validationErrors: {
              [e.target.name]: {
                $set: "The email must be a valid email address.",
              },
            },
          }),
        );
      } else {
        this.setState(
          update(this.state, {
            validationErrors: {
              [e.target.name]: {
                $set: "",
              },
            },
          }),
        );
      }
    }

    if (
      e.target.name === "maxDebateDuration" ||
      e.target.name === "defaultDebateDuration"
    ) {
      if (
        e.target.value !== "" &&
        parseInt(this.state.maxDebateDuration) <
          parseInt(this.state.defaultDebateDuration)
      ) {
        this.setState(
          update(this.state, {
            validationErrors: {
              maxDebateDuration: {
                $set: "Max Poll Duration must be greater than or equal to Default Poll Duration.",
              },
            },
          }),
        );
      } else {
        this.setState(
          update(this.state, {
            validationErrors: {
              maxDebateDuration: {
                $set: "",
              },
            },
          }),
        );
      }
    }

    if (
      this.state.validationErrors.subDomain === "" &&
      this.state.validationErrors.customDomain === "" &&
      this.state.validationErrors.primaryDomain === "" &&
      this.state.validationErrors.title === "" &&
      this.state.validationErrors.categories === "" &&
      this.state.validationErrors.fbLink === "" &&
      this.state.validationErrors.instagramLink === "" &&
      this.state.validationErrors.twitterLink === "" &&
      this.state.validationErrors.linkedinLink === "" &&
      this.state.validationErrors.supportEmail === "" &&
      this.state.validationErrors.affiliateLink === "" &&
      this.state.validationErrors.maxDebateDuration === ""
    ) {
      if (action === "onSubmit") {
        this.setState({
          loading: true,
        });
        this.uploadCatImages();
      }
    }
  };

  submitWhiteLabelHandle = (e) => {
    setTimeout(
      function () {
        this.formValidation(e, "onSubmit");
      }.bind(this),
      100,
    );

    e.preventDefault();
  };

  checkIfExist = async (e) => {
    if (e.target.value === "") {
      return false;
    }
    const formData = {
      field: e.target.name,
      value: e.target.value,
    };

    await axios
      .post(API_URL + "/admin/client/exists", formData)
      .then((res) => {
        if (res.data.data === false) {
          this.setState(
            update(this.state, {
              validationErrors: {
                name: {
                  $set: "This client name has already been taken.",
                },
              },
            }),
          );
        } else {
          this.setState(
            update(this.state, {
              validationErrors: {
                name: {
                  $set: "",
                },
              },
            }),
          );
        }
      })
      .catch((err) => {
        console.log("WLName exists");
      });
  };

  changeHandler = (e) => {
    if (e.target.type === "checkbox") {
      if (e.target.name === "isAllDebateCreator") {
        this.setState({
          [e.target.name]: e.target.checked === true ? 0 : 1,
        });
      } else {
        this.setState({
          [e.target.name]: e.target.checked === true ? 1 : 0,
        });
      }
    } else {
      this.setState({
        [e.target.name]: e.target.value,
      });
    }

    setTimeout(
      function () {
        this.formValidation(e);
      }.bind(this),
      100,
    );
  };

  subdomainChangeHandler = (e) => {
    this.setState({
      [e.target.name]:
        e.target.value !== "sided"
          ? e.target.value + ".sided.co"
          : e.target.value + ".co",
    });
    setTimeout(
      function () {
        this.formValidation(e);
      }.bind(this),
      100,
    );
  };

  catChangeHandler = (e) => {
    let categories = [...this.state.categories];
    let category = "";

    if (e.target.type === "checkbox") {
      category = {
        ...categories[e.target.dataset.index],
        [e.target.name]: e.target.checked === true ? 1 : 0,
      };
      categories[e.target.dataset.index] = category;
      this.setState({ categories });
    } else if (e.target.type === "file" && e.target.files[0]) {
      var regex = new RegExp("(.*?).(jpg|jpeg|gif|png)$");
      if (!regex.test(e.target.files[0].type)) {
        alert("Image is invalid. Please upload only PNG, JPG or GIF image.");
        return false;
      }
      if (e.target.files[0].size > 100 * 1024) {
        alert("Category image size should not be greater than 100kb");
        return false;
      }
      var file = e.target.files[0];
      var thisEl = e;
      var reader = new FileReader();
      reader.onloadend = function (e) {
        category = {
          ...categories[thisEl.target.dataset.index],
          [thisEl.target.name]: thisEl.target.files[0],
          tempPath: e.target.result,
        };
        categories[thisEl.target.dataset.index] = category;
        this.setState({ categories });
      }.bind(this);
      reader.readAsDataURL(file);
    } else {
      category = {
        ...categories[e.target.dataset.index],
        [e.target.name]: e.target.value,
      };
      categories[e.target.dataset.index] = category;
      this.setState({ categories });
    }
  };

  catImgRemoveHandler = (name, index) => {
    let categories = [...this.state.categories];
    let category = "";
    category = {
      ...categories[index],
      [name]: "",
      tempPath: "",
    };
    categories[index] = category;
    this.setState({ categories });
    console.log(this.state);
  };

  fileChangeHandler = (e) => {
    if (e.target.type === "file" && e.target.files[0]) {
      const dataAccept = e.target.getAttribute("data-accept")
        ? e.target.getAttribute("data-accept")
        : "jpg|jpeg|gif|png";
      const dataAcceptMsg = e.target.getAttribute("data-accept_msg")
        ? e.target.getAttribute("data-accept_msg")
        : "Image is invalid. Please upload only PNG, JPG or GIF image.";
      var regex = new RegExp("(.*?).(" + dataAccept + ")$");
      if (!regex.test(e.target.files[0].type.toLowerCase())) {
        alert(dataAcceptMsg);
        return false;
      }
      if (e.target.name === "logo" && e.target.files[0].size > 500 * 1024) {
        alert("Logo size should not be greater than 500kb");
        return false;
      } else if (
        e.target.name === "favicon" &&
        e.target.files[0].size > 100 * 1024
      ) {
        alert("Favicon size should not be greater than 100kb");
        return false;
      } else if (
        e.target.name === "emailLogo" &&
        e.target.files[0].size > 250 * 1024
      ) {
        alert("Email Logo size should not be greater than 250kb");
        return false;
      }
      var file = e.target.files[0];
      var thisEl = e;
      var reader = new FileReader();
      reader.onloadend = function (e) {
        this.setState(
          update(this.state, {
            images: {
              [thisEl.target.dataset.index]: {
                image_url: {
                  $set: thisEl.target.files[0],
                },
                image_type: {
                  $set: thisEl.target.dataset.imgtype,
                },
              },
            },
          }),
        );
        this.setState({
          [thisEl.target.name]: e.target.result,
        });
        console.log(this.state);
      }.bind(this);
      reader.readAsDataURL(file);
    }
  };

  fileRemoveHandler = async (name, index) => {
    await this.setState(
      update(this.state, {
        images: {
          [index]: {
            image_url: {
              $set: "",
            },
            image_type: {
              $set: "",
            },
          },
        },
      }),
    );
    await this.setState({
      [name]: "",
    });
    console.log(this.state);
  };

  addPlacementClickHandle = ($) => {
    this.setState({
      placementFieldHidden: false,
    });
  };
  addPlacementFieldChange = (e) => {
    this.setState({
      newPlacementVal: e.target.value,
    });
  };
  submitPlacementField = (e) => {
    if (this.state.newPlacementVal === "") {
      alert("Placement name cannot be blank.");
    } else {
      this.setState((prevState) => ({
        embedPlacements: [
          ...prevState.embedPlacements,
          {
            placementName: this.state.newPlacementVal,
          },
        ],
      }));
      this.setState({
        placementFieldHidden: true,
        newPlacementVal: "",
      });
    }
    e.preventDefault();
  };

  placementChangeHandler = async (e) => {
    let embedPlacements = [...this.state.embedPlacements];
    let embedPlacement = "";
    embedPlacement = {
      ...embedPlacements[e.target.dataset.index],
      [e.target.name]: e.target.value,
    };
    embedPlacements[e.target.dataset.index] = embedPlacement;
    await this.setState({ embedPlacements });
    console.log(this.state);
  };

  addCatClickHandle = ($) => {
    this.setState({
      catFieldHidden: false,
    });
  };
  addCatFieldChange = (e) => {
    this.setState({
      newCatVal: e.target.value,
    });
  };
  submitCatField = (e) => {
    this.setState((prevState) => ({
      categories: [
        {
          text: this.state.newCatVal,
          imageUrl: "",
          tempPath: "",
          featured: 0,
          archive: 0,
          adminOnly: 0,
        },
        ...prevState.categories,
      ],
    }));

    this.setState({
      catFieldHidden: true,
      newCatVal: "",
    });
    e.preventDefault();
  };

  reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  onDragEnd = async (result) => {
    if (!result.destination) {
      return;
    }

    const categories = await this.reorder(
      this.state.categories,
      result.source.index,
      result.destination.index,
    );
    await this.setState({
      categories: categories,
    });
  };

  cancelClickHandle = async (e) => {
    if (this.props.networks && this.props.networks.length > 1) {
      window.location.href = BASENAME + "/networks";
      return false;
    }
    this.setState({
      loading: true,
    });
    await this.fetchCurreNetwork();
    this.setState({
      loading: false,
    });
  };

  messageNotification = async (type, message) => {
    this.setState({
      notificationType: type,
      successMsg: message,
    });

    setTimeout(() => {
      this.setState({
        successMsg: "",
      });
    }, 5000);
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.match.params.id !== this.props.match.params.id) {
      this.fetchCurreNetwork();
    }
    if (
      prevProps.selNetworkId !== null &&
      parseInt(this.props.selNetworkId) !== 0 &&
      prevProps.selNetworkId !== this.props.selNetworkId
    ) {
      this.settingsNavLink.current.click();

      this.fetchCurreNetwork(this.props.selNetworkId);
      window.history.pushState(
        "",
        "",
        BASENAME + "/networks/edit-network/" + this.props.selNetworkId,
      );
    }
  }

  fetchEmbedCode = async (e, embedPlacementId) => {
    this.setState({ embedCode: null });

    await axios
      .get(
        API_URL +
          "/admin/embedPlacement/getEmbed/" +
          embedPlacementId +
          "?clientId=" +
          this.state.clientId,
      )
      .then((res) => {
        this.setState({
          embedCode: res.data.data,
          showCopyEmbaedModal: true,
        });
        console.log(res.data.data);
      })
      .catch((err) => {
        console.log(err.response.data.message);
      });
  };

  hideCopyEmbedModal = async () => {
    this.setState({
      embedCode: null,
      showCopyEmbaedModal: false,
    });
  };

  copyEmbedCodeToClipboard = async (e) => {
    if (this.textArea.value !== "") {
      this.textArea.select();
      await document.execCommand("copy");
      e.target.focus();
      this.messageNotification("success", "Embed code copied to clipboard!");
    } else {
      this.messageNotification(
        "fail",
        "There is some problem in copying embed code. Please reload the page and try again!",
      );
    }
  };

  fetchApprovedDomaine = async () => {
    axios
      .get(
        API_URL +
          "/admin/embedSource/getDomains?type=LIST_ONLY&clientId=" +
          this.state.clientId,
      )
      .then((res) => {
        console.log(res.data.data);
        let approvedDomainsListArr = [];
        var primaryDomain = "";

        res.data.data.forEach(function (el, i) {
          if (el.isPrimary === 0) {
            approvedDomainsListArr[i] = { id: el.id, domain: el.domain };
          } else {
            primaryDomain = el.domain;
          }
        });
        this.setState({
          approvedDomainsList: approvedDomainsListArr,
          primaryDomain: primaryDomain,
          hasPrimaryDomain: primaryDomain !== "" ? true : false,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  addApprovedDomain = async () => {
    if (
      this.state.domain === "" ||
      this.state.validationErrors["domain"] !== ""
    ) {
      return false;
    }

    await this.setState({ loading: true });
    await axios
      .post(
        API_URL +
          "/admin/embedSource/addDomain?clientId=" +
          this.state.clientId,
        { domain: this.state.domain, isPrimary: 0 },
      )
      .then((res) => {
        this.fetchApprovedDomaine();
        setTimeout(
          function () {
            this.setState({ loading: false, domain: "" });
          }.bind(this),
          500,
        );
      })
      .catch((err) => {
        alert(err.response.data.message);
        setTimeout(
          function () {
            this.setState({ loading: false, domain: "" });
          }.bind(this),
          500,
        );
      });
  };

  addPrimaryDomain = async () => {
    if (
      this.state.primaryDomain === "" ||
      this.state.validationErrors["primaryDomain"] !== ""
    ) {
      return false;
    }

    await this.setState({ loading: true });
    await axios
      .post(
        API_URL +
          "/admin/embedSource/addDomain?clientId=" +
          this.state.clientId,
        { domain: this.state.primaryDomain, isPrimary: 1 },
      )
      .then((res) => {
        this.fetchApprovedDomaine();
        setTimeout(
          function () {
            this.setState({ loading: false, domain: "" });
          }.bind(this),
          500,
        );
      })
      .catch((err) => {
        alert(err.response.data.message);
        setTimeout(
          function () {
            this.setState({ loading: false, domain: "" });
          }.bind(this),
          500,
        );
      });
  };

  removeApprovedDomain = async (e, domainIdToBeRemove) => {
    await this.setState({ loading: true });
    await axios
      .delete(
        API_URL +
          "/admin/embedSource/removeDomain?clientId=" +
          this.state.clientId +
          "&embedDomainId=" +
          domainIdToBeRemove,
      )
      .then((res) => {
        this.fetchApprovedDomaine();
        setTimeout(
          function () {
            this.setState({ loading: false });
          }.bind(this),
          500,
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };

  showArchiveToggleModal = async () => {
    this.setState({
      archiveToggleModal: true,
    });
  };
  hideArchiveToggleModal = async () => {
    this.setState({
      archiveToggleModal: false,
    });
  };

  archiveNetworkToggle = async () => {
    await this.setState({ loading: true });
    this.hideArchiveToggleModal();
    await axios
      .post(API_URL + "/admin/client/archive", {
        clientId: this.state.clientId,
      })
      .then((res) => {
        this.props.loadNetworksList();
        if (res.data && res.data.status === "success") {
          this.messageNotification("success", res.data.message);
        }
        this.fetchCurreNetwork();
        setTimeout(
          function () {
            this.setState({ loading: false });
            this.props.history.push({
              pathname: "/networks",
              state: {
                filter: res.data.data === "archived" ? "ARCHIVED" : "ACTIVE",
              },
            });
          }.bind(this),
          500,
        );
      })
      .catch((err) => {
        if (err.response) {
          this.messageNotification("fail", err.response.data.message[0].error);
        }
        setTimeout(
          function () {
            this.setState({ loading: false });
          }.bind(this),
          500,
        );
      });

    return false;
  };

  render(props) {
    const {
      advertising,
      advertisingSource,
      webAdvertisingSource,
      affiliateButtonText,
      affiliateLink,
      allowedAnalyticDomains,
      apiAccess,
      approvedDomainsList,
      archive,
      categories,
      catFieldHidden,
      customDomain,
      defaultDebateDuration,
      domain,
      emailLogo,
      favicon,
      fbClientId,
      fbClientSecret,
      fbLink,
      fbPixelId,
      gamId,
      googleAnalyticsId,
      hasPrimaryDomain,
      instagramLink,
      isDefault,
      isFeatured,
      isSpanish,
      isAutomated,
      isMultiple,
      gaTracking,
      aiContent,
      sidebarAds,
      isPointActive,
      isPrivate,
      linkedinLink,
      logo,
      maxDebateDuration,
      name,
      loading,
      primaryDomain,
      redirectSidePanel,
      requireEmail,
      supportEmail,
      supportName,
      title,
      twitterClientId,
      twitterClientSecret,
      twitterLink,
      isAllDebateCreator,
      revenueShare,
      datablocksSid,
    } = this.state;

    const loggedInUser = this.props.loggedInUser;
    let subDomain = this.state.subDomain;
    subDomain = subDomain.replace(".sided.co", "").replace(".co", "");
    const kTrans = this.props.t ? this.props.t : "";

    return (
      <section>
        <NavLink
          ref={this.settingsNavLink}
          exact
          to={`/networks/edit-network/${this.props.selNetworkId}`}
        ></NavLink>
        {this.state.successMsg ? (
          <div className={`sucessMsg ${this.state.notificationType}`}>
            {this.state.successMsg}
          </div>
        ) : null}
        <h2 className="title-section">{kTrans("Settings")}</h2>
        <Tabs
          defaultActiveKey="network-settings"
          id="uncontrolled-tab-example"
          className="mb-3 mb-md-5 networkSettingsTabs"
        >
          <Tab eventKey="network-settings" title={kTrans("Network Settings")}>
            <Row
              className={
                loading === true
                  ? "loading justify-content-center position-relative"
                  : "justify-content-center position-relative"
              }
            >
              <Col md={6} className="pe-4">
                <Form
                  className="WLSettings common-form row"
                  onSubmit={this.submitWhiteLabelHandle}
                >
                  <h3 className="mb-3">{kTrans("GENERAL INFO")}</h3>
                  <Form.Group controlId="clientName" className="pt-0">
                    <Form.Label>{kTrans("NETWORK NAME")}</Form.Label>
                    <Form.Control
                      readOnly
                      type="text"
                      onChange={(e) => {
                        this.changeHandler(e);
                      }}
                      name="name"
                      value={name}
                      placeholder={kTrans("Enter your network name")}
                    />
                    <span className="validation-error">
                      {this.state.validationErrors["name"]}
                    </span>
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>{kTrans("SUBDOMAIN")}</Form.Label>
                    <div className="inline-content bottom">
                      <Form.Control
                        type="text"
                        onChange={(e) => {
                          this.subdomainChangeHandler(e);
                        }}
                        name="subDomain"
                        value={subDomain}
                        id="subDomain"
                        placeholder={kTrans("Type domain name")}
                      />
                      <span>&nbsp;&nbsp;.sided.co</span>
                    </div>
                    <span className="validation-error">
                      {this.state.validationErrors["subDomain"]}
                    </span>
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>{kTrans("CUSTOM DOMAIN")}</Form.Label>
                    <div className="inline-content middle">
                      <Form.Control
                        type="text"
                        onChange={this.changeHandler}
                        name="customDomain"
                        value={customDomain}
                        id="subDomain"
                        placeholder={kTrans("Type domain name")}
                      />
                    </div>
                    <span className="validation-error">
                      {this.state.validationErrors["customDomain"]}
                    </span>
                  </Form.Group>

                  <Form.Group>
                    <Form.Label>{kTrans("PRIMARY DOMAIN")}</Form.Label>
                    <div className="inline-content middle">
                      {hasPrimaryDomain ? (
                        <Form.Control
                          type="text"
                          readOnly
                          name="primaryDomain"
                          value={primaryDomain}
                          id="primaryDomain"
                          placeholder={kTrans("Example: domain.com")}
                        />
                      ) : (
                        <div className="w-100 position-relative domainsField">
                          <Form.Control
                            type="text"
                            onChange={this.changeHandler}
                            name="primaryDomain"
                            value={primaryDomain}
                            id="primaryDomain"
                            placeholder={kTrans("Example: domain.com")}
                          />
                          {primaryDomain !== "" &&
                          this.state.validationErrors["primaryDomain"] ===
                            "" ? (
                            <span
                              className="customAnchor"
                              onClick={this.addPrimaryDomain}
                            >
                              Confirm
                            </span>
                          ) : (
                            <span className="lightColorSpan">Confirm</span>
                          )}
                        </div>
                      )}
                    </div>
                    <span className="validation-error">
                      {this.state.validationErrors["primaryDomain"]}
                    </span>
                  </Form.Group>
                  {hasPrimaryDomain ||
                  (approvedDomainsList && approvedDomainsList.length) ? (
                    <Form.Group>
                      <Form.Label>{kTrans("APPROVED SUBDOMAINS")}</Form.Label>
                      {hasPrimaryDomain ? (
                        <>
                          <div className="position-relative domainsField">
                            <Form.Control
                              type="text"
                              onChange={this.changeHandler}
                              name="domain"
                              value={domain}
                              id="domain"
                              placeholder={kTrans("Example:  sub.domain.co")}
                            />
                            {domain !== "" &&
                            this.state.validationErrors["domain"] === "" ? (
                              <span
                                className="customAnchor"
                                onClick={this.addApprovedDomain}
                              >
                                {kTrans("Confirm")}
                              </span>
                            ) : (
                              <span className="lightColorSpan">
                                {kTrans("Confirm")}
                              </span>
                            )}
                          </div>
                          <span className="validation-error">
                            {this.state.validationErrors["domain"]}
                          </span>
                        </>
                      ) : (
                        ""
                      )}
                      <div className="approvedDomainsList mt-2 ps-3 pe-3">
                        {approvedDomainsList && approvedDomainsList.length
                          ? approvedDomainsList.map((el, i) => (
                              <div
                                className="inline-content middle pt-1 pb-1"
                                key={i}
                              >
                                <span>{el.domain}</span>
                                <span
                                  className="customAnchor"
                                  onClick={(e) => {
                                    if (
                                      window.confirm(
                                        kTrans(
                                          "Are you sure you want to remove this domain? This action is permanent and can't be reversed.",
                                        ),
                                      )
                                    ) {
                                      this.removeApprovedDomain(e, el.id);
                                    }
                                  }}
                                >
                                  {kTrans("Remove")}
                                </span>
                              </div>
                            ))
                          : null}
                      </div>
                    </Form.Group>
                  ) : null}

                  {/*
                  <Form.Group>
                    <Form.Label>
                      {kTrans("REQUIRE EMAIL TO SEE POLL RESULTS")}
                    </Form.Label>
                    <select
                      defaultValue={requireEmail}
                      name="requireEmail"
                      value={requireEmail}
                      id="requireEmail"
                      onChange={this.changeHandler}
                    >
                      <option value="0">{kTrans("No")}</option>
                      <option value="1">{kTrans("Yes")}</option>
                      <option value="2">
                        {kTrans("Yes, verify with email link")}
                      </option>
                    </select>
                  </Form.Group>
                  */}

                  <div className="inline-toggles  mb-4 mt-4">
                    <Form.Group>
                      <Form.Check.Label className="mb-3">
                        {kTrans("POINTS & LEADERBOARD")}
                      </Form.Check.Label>
                      <Form.Check.Input
                        className="toggle-checkbox"
                        checked={isPointActive}
                        onChange={this.changeHandler}
                        name="isPointActive"
                        value={isPointActive}
                        data-type="POINTS & LEADERBOARD"
                      />
                    </Form.Group>
                    {name !== MAIN_NETWORK_NAME ? (
                      <Form.Group>
                        <Form.Check.Label className="mb-3">
                          {kTrans("CONTRIBUTORS ONLY")}
                        </Form.Check.Label>
                        <Form.Check.Input
                          className="toggle-checkbox"
                          checked={isAllDebateCreator}
                          onChange={this.changeHandler}
                          name="isAllDebateCreator"
                          value={isAllDebateCreator}
                          data-id="CONTRIBUTORS ONLY"
                        />
                      </Form.Group>
                    ) : null}

                    <Form.Group>
                      <Form.Check.Label className="mb-3">
                        {kTrans("COMMENTS ON SIDED")}
                      </Form.Check.Label>
                      <Form.Check.Input
                        className="toggle-checkbox"
                        checked={redirectSidePanel}
                        onChange={this.changeHandler}
                        name="redirectSidePanel"
                        value={redirectSidePanel}
                        data-type="Comments on Sided"
                      />
                    </Form.Group>
                  </div>
                  <div className="inline-content bottom">
                    <Form.Group>
                      <Form.Check.Label>
                        {kTrans("DEFAULT POLL DURATION")}
                      </Form.Check.Label>
                      <div className="d-flex align-items-center">
                        <input
                          onChange={this.changeHandler}
                          id="default-debate-duration"
                          type="number"
                          name="defaultDebateDuration"
                          value={defaultDebateDuration}
                          className="form-control w-50"
                          min="1"
                          placeholder={DEFAULT_DEBATE_DURATION}
                        />
                        &nbsp;&nbsp;{kTrans("days")}
                      </div>
                    </Form.Group>
                    <Form.Group className="ms-2">
                      <Form.Check.Label>
                        {kTrans("MAX POLL DURATION")}
                      </Form.Check.Label>
                      <div className="d-flex align-items-center">
                        <input
                          onChange={this.changeHandler}
                          id="max-debate-duration"
                          type="number"
                          name="maxDebateDuration"
                          value={maxDebateDuration}
                          className="form-control w-50"
                          min="1"
                          placeholder={MAX_DEBATE_DURATION}
                        />
                        &nbsp;&nbsp;{kTrans("days")}
                      </div>
                    </Form.Group>
                  </div>
                  <span className="validation-error">
                    {this.state.validationErrors["maxDebateDuration"]}
                  </span>
                  <h3 className="mt-5 mb-2">{kTrans("META")}</h3>
                  <Form.Group>
                    <Form.Label>{kTrans("TITLE")}</Form.Label>
                    <Form.Control
                      type="text"
                      onChange={(e) => {
                        this.changeHandler(e);
                      }}
                      name="title"
                      placeholder={kTrans("Enter title")}
                      value={title}
                      id="title"
                    />
                    <span className="validation-error">
                      {this.state.validationErrors["title"]}
                    </span>
                  </Form.Group>
                  <Form.Group className="">
                    <Form.Label>{kTrans("LOGO")}</Form.Label>
                    <div className="field-logo img-field-wrap">
                      {logo ? (
                        <>
                          <img alt="Logo" src={logo} />
                          {name !== MAIN_NETWORK_NAME ? (
                            <span
                              className="close"
                              onClick={() =>
                                this.fileRemoveHandler("logo", "0")
                              }
                            >
                              <i className="fa fa-times"></i>
                            </span>
                          ) : null}
                        </>
                      ) : (
                        <div className="img-field-placeholder">
                          <i className="fa fa-upload" aria-hidden="true"></i>
                          <span className="upload-label">
                            {kTrans("Upload logo")}
                          </span>
                          <span>{kTrans("Large")}</span>
                        </div>
                      )}
                      <Form.Control
                        data-index="0"
                        data-imgtype="LOGO"
                        type="file"
                        name="logo"
                        onChange={this.fileChangeHandler}
                        data-accept="jpg|jpeg|gif|png"
                        data-accept_msg={kTrans(
                          "Image is invalid. Please upload only PNG, JPG or GIF image.",
                        )}
                      />
                    </div>
                  </Form.Group>
                  <div className="row pe-0">
                    <Form.Group className="col-4 pe-0">
                      <Form.Label>{kTrans("FAVICON")}</Form.Label>
                      <div className="field-favicon img-field-wrap">
                        {favicon ? (
                          <>
                            <img alt="Favicon" src={favicon} />
                            {name !== MAIN_NETWORK_NAME ? (
                              <span
                                className="close"
                                onClick={() =>
                                  this.fileRemoveHandler("favicon", "1")
                                }
                              >
                                <i className="fa fa-times"></i>
                              </span>
                            ) : null}
                          </>
                        ) : (
                          <div className="img-field-placeholder">
                            <i className="fa fa-upload" aria-hidden="true"></i>
                            <span className="upload-label">Upload icon</span>
                            <span>{kTrans("Square")}</span>
                          </div>
                        )}
                        <Form.Control
                          data-index="1"
                          data-imgtype="FAVICON"
                          type="file"
                          name="favicon"
                          onChange={this.fileChangeHandler}
                          data-accept="jpg|jpeg|gif|png|icon"
                          data-accept_msg={kTrans(
                            "Image is invalid. Please upload only PNG, JPG, GIF or ICO image.",
                          )}
                        />
                      </div>
                    </Form.Group>
                    <Form.Group className="col-8 pe-0">
                      <Form.Label>{kTrans("EMAIL LOGO")}</Form.Label>
                      <div className="field-emaillogo img-field-wrap">
                        {emailLogo ? (
                          <>
                            <img alt="Email Logo" src={emailLogo} />
                            {name !== MAIN_NETWORK_NAME ? (
                              <span
                                className="close"
                                onClick={() =>
                                  this.fileRemoveHandler("emailLogo", "2")
                                }
                              >
                                <i className="fa fa-times"></i>
                              </span>
                            ) : null}
                          </>
                        ) : (
                          <div className="img-field-placeholder">
                            <i className="fa fa-upload" aria-hidden="true"></i>
                            <span className="upload-label">
                              {kTrans("Upload logo")}
                            </span>
                            <span>{kTrans("Medium")}</span>
                          </div>
                        )}
                        <Form.Control
                          data-index="2"
                          data-imgtype="EMAIL_LOGO"
                          type="file"
                          name="emailLogo"
                          onChange={this.fileChangeHandler}
                          data-accept="jpg|jpeg|gif|png"
                          data-accept_msg={kTrans(
                            "Image is invalid. Please upload only PNG, JPG or GIF image.",
                          )}
                        />
                      </div>
                    </Form.Group>
                  </div>
                  {name === MAIN_NETWORK_NAME ? (
                    <>
                      <h3 className="mt-5 mb-2">{kTrans("SOCIAL LOGIN")}</h3>
                      <Form.Group>
                        <Form.Label>{kTrans("FACEBOOK CLIENT ID")}</Form.Label>
                        <Form.Control
                          type="text"
                          onChange={this.changeHandler}
                          name="fbClientId"
                          value={fbClientId}
                          id="fbClientId"
                          placeholder={kTrans("Facebook Client ID")}
                        />
                      </Form.Group>
                      <Form.Group>
                        <Form.Label>
                          {kTrans("FACEBOOK CLIENT SECRET")}
                        </Form.Label>
                        <Form.Control
                          type="text"
                          onChange={this.changeHandler}
                          name="fbClientSecret"
                          value={fbClientSecret}
                          id="fbClientSecret"
                          placeholder={kTrans("Facebook Client Secret")}
                        />
                      </Form.Group>
                      <Form.Group>
                        <Form.Label>{kTrans("TWITTER CLIENT ID")}</Form.Label>
                        <Form.Control
                          type="text"
                          onChange={this.changeHandler}
                          name="twitterClientId"
                          value={twitterClientId}
                          id="twitterClientId"
                          placeholder={kTrans("Twitter Client ID")}
                        />
                      </Form.Group>
                      <Form.Group>
                        <Form.Label>
                          {kTrans("TWITTER CLIENT SECRET")}
                        </Form.Label>
                        <Form.Control
                          type="text"
                          onChange={this.changeHandler}
                          name="twitterClientSecret"
                          value={twitterClientSecret}
                          id="twitterClientSecret"
                          placeholder={kTrans("Twitter Client Secret")}
                        />
                      </Form.Group>
                      <h3>{kTrans("SOCIAL LINKS")}</h3>
                      <Form.Group>
                        <Form.Label>{kTrans("FACEBOOK")}</Form.Label>
                        <Form.Control
                          type="text"
                          name="fbLink"
                          value={fbLink}
                          id="fbLink"
                          placeholder="https://www.facebook.com/"
                          onChange={this.changeHandler}
                        />
                        <span className="validation-error">
                          {this.state.validationErrors["fbLink"]}
                        </span>
                      </Form.Group>
                      <Form.Group>
                        <Form.Label>{kTrans("INSTAGRAM")}</Form.Label>
                        <Form.Control
                          type="text"
                          name="instagramLink"
                          value={instagramLink}
                          id="instagramLink"
                          placeholder="https://www.instagram.com/"
                          onChange={this.changeHandler}
                        />
                        <span className="validation-error">
                          {this.state.validationErrors["instagramLink"]}
                        </span>
                      </Form.Group>
                      <Form.Group>
                        <Form.Label>{kTrans("TWITTER")}</Form.Label>
                        <Form.Control
                          type="text"
                          name="twitterLink"
                          value={twitterLink}
                          id="twitterLink"
                          placeholder="https;//twitter.com/"
                          onChange={this.changeHandler}
                        />
                        <span className="validation-error">
                          {this.state.validationErrors["twitterLink"]}
                        </span>
                      </Form.Group>
                      <Form.Group>
                        <Form.Label>{kTrans("LINKEDIN")}</Form.Label>
                        <Form.Control
                          type="text"
                          name="linkedinLink"
                          value={linkedinLink}
                          id="linkedinLink"
                          placeholder="https;//linkedin.com/company/"
                          onChange={this.changeHandler}
                        />
                        <span className="validation-error">
                          {this.state.validationErrors["linkedinLink"]}
                        </span>
                      </Form.Group>
                    </>
                  ) : null}

                  <h3 className="mt-5 mb-2">{kTrans("AFFILIATE LINK")}</h3>
                  <Form.Group>
                    <Form.Label>{kTrans("LINK")}</Form.Label>
                    <Form.Control
                      type="text"
                      name="affiliateLink"
                      value={affiliateLink}
                      id="affiliateLink"
                      placeholder={kTrans("Enter affiliate link")}
                      onChange={this.changeHandler}
                    />
                    <span className="validation-error">
                      {this.state.validationErrors["affiliateLink"]}
                    </span>
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>{kTrans("BUTTON TEXT")}</Form.Label>
                    <Form.Control
                      type="text"
                      name="affiliateButtonText"
                      value={affiliateButtonText}
                      id="affiliateButtonText"
                      placeholder={kTrans("Enter button text")}
                      onChange={this.changeHandler}
                    />
                  </Form.Group>

                  <h3 className="mt-5 mb-2">{kTrans("EXTRA")}</h3>
                  <Form.Group>
                    <Form.Label>{kTrans("GOOGLE ANALYTICS ID")}</Form.Label>
                    <Form.Control
                      type="text"
                      name="googleAnalyticsId"
                      value={googleAnalyticsId}
                      id="googleAnalyticsId"
                      placeholder={kTrans("Google Analytic ID")}
                      onChange={this.changeHandler}
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>{kTrans("GAM ID")}</Form.Label>
                    <Form.Control
                      type="text"
                      name="gamId"
                      value={gamId}
                      id="gamId"
                      placeholder={kTrans("GAM ID")}
                      onChange={this.changeHandler}
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>{kTrans("FACEBOOK PIXEL ID")}</Form.Label>
                    <Form.Control
                      type="text"
                      name="fbPixelId"
                      value={fbPixelId}
                      id="fbPixelId"
                      placeholder={kTrans("Facebook Pixel ID")}
                      onChange={this.changeHandler}
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>{kTrans("SUPPORT NAME")}</Form.Label>
                    <Form.Control
                      type="text"
                      name="supportName"
                      value={supportName}
                      id="supportName"
                      placeholder={kTrans("Enter Support Name")}
                      onChange={this.changeHandler}
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>{kTrans("SUPPORT EMAIL")}</Form.Label>
                    <Form.Control
                      type="text"
                      name="supportEmail"
                      value={supportEmail}
                      id="supportEmail"
                      placeholder={kTrans("Enter email address")}
                      onChange={this.changeHandler}
                    />
                    <span className="validation-error">
                      {this.state.validationErrors["supportEmail"]}
                    </span>
                  </Form.Group>
                  {loading === true ? null : (
                    <div className="buttons">
                      <Button
                        variant="secondary"
                        onClick={this.cancelClickHandle}
                      >
                        {kTrans("Cancel")}
                      </Button>
                      <Button variant="primary" type="submit">
                        {kTrans("Save")}
                      </Button>
                    </div>
                  )}
                </Form>
              </Col>
              <Col md={4} className="ps-3 WLSettings common-form">
                {/*<div className="placementsSection pb-5">
				               <div className="inline-content bottom">
				                  <h3>EMBED PLACEMENTS</h3>
				                  <span className="customAnchor" role="button" onClick={this.addPlacementClickHandle}>Add Placement +</span>
				               </div>
				               { (placementFieldHidden === false) ?
				               <div className="addPlacementField inline-content middle mt-1">
				                  <input type="text" className="form-control" onChange={this.addPlacementFieldChange} placeholder="Enter new placement" value={this.state.newPlacementVal} />
				                  <button className="btn btn-secondary" onClick={this.submitPlacementField}>Add</button>
				               </div>
				               : null
				               }
				               <hr className="mb-1"/>
				               {
				               embedPlacements && embedPlacements.length ? embedPlacements.map((el, i) => (
				               <div className="placementCols inline-content middle" key={i}>
				                  <input data-index={i} type="text" onChange={e => { this.placementChangeHandler(e);  }} placeholder="Enter new Placement" name="placementName" value={el.placementName} />
				                  <a data-id={el.id} className="customAnchor d-inline pull-right" onClick={e => this.fetchEmbedCode(e , el.id)}><i className="fa fa-code"></i></a>
				               </div>
				               ))
				               : null
				               }
				            </div>
				            <Modal className="removeDebModal" size="lg" show={this.state.showCopyEmbaedModal}>
				               <Modal.Header>
				                  <Modal.Title>Copy embed modal</Modal.Title>
				                  <a><b className="" onClick={this.hideCopyEmbedModal}>&#x2715;</b></a>
				               </Modal.Header>
				               <Modal.Body>
				                  <div className="modalContentWrapper">
				                     {
				                     this.state.embedCode !== null ?
				                     <>
				                     <h4>Embed Code</h4>
				                     <Form.Group controlId="thesis" className="">
				                        <Form.Label>Put below code in your header</Form.Label>
				                        <Form.Control className="mb-2" as="textarea" ref={(textarea) =>
				                        this.textArea = textarea}
				                        value={this.state.embedCode['header']}
				                        />
				                        <a className="btn btn-primary btn-sm pull-right mb-3" role="button">Copy header code</a>
				                     </Form.Group>
				                     <br clear="all"/>
				                     <Form.Group controlId="thesis" className="">
				                        <Form.Label>Put below code where you want to embed debate</Form.Label>
				                        <Form.Control className="mb-2" as="textarea" rows="3"  ref={(textarea) =>
				                        this.textArea = textarea}
				                        value={this.state.embedCode['html']}
				                        />
				                        <a className="btn btn-primary btn-sm pull-right" role="button">Copy</a>
				                     </Form.Group>
				                     <br clear="all"/>
				                     <hr/>
				                     <h4>WordPress shortcode</h4>
				                     <Form.Control className="mb-2" as="textarea" ref={(textarea) =>
				                     this.textArea = textarea}
				                     value={this.state.embedCode['wordpress']}
				                     />
				                     <a className="btn btn-primary btn-sm pull-right" role="button">Copy WordPress Shortcode</a>
				                     <br clear="all"/>
				                     </>
				                     : null
				                     }
				                  </div>
				               </Modal.Body>
				            </Modal>*/}
                {/* Cat code removed from here */}
                {this.state.name && this.state.clientId ? (
                  <AddBrandAccount
                    clientName={this.state.name}
                    clientId={this.state.clientId}
                    brandAccount={this.state.brandAccount}
                  />
                ) : (
                  ""
                )}
              </Col>
              <Col md={2}></Col>
            </Row>
          </Tab>
          <Tab
            eventKey="integration-settings"
            title={kTrans("Integration Settings")}
          >
            {this.state.formDataLoaded !== undefined &&
            this.state.formDataLoaded === true &&
            this.state.embedCodeObject ? (
              <IntegrationSettingsTab
                embedCodeObject={this.state.embedCodeObject}
                clientId={this.state.clientId}
              />
            ) : null}
          </Tab>

          <Tab eventKey="categories" title={kTrans("Categories")}>
            {
              <Row
                className={
                  loading === true
                    ? "position-relative loading justify-content-center"
                    : "position-relative justify-content-center"
                }
              >
                <Form
                  className="WLSettings common-form row"
                  onSubmit={this.submitWhiteLabelHandle}
                >
                  <Col md={6} className="pe-4">
                    <div className="inline-content middle">
                      <h3 className="lightColor">{kTrans("CATEGORIES")} *</h3>
                      <span
                        className="customAnchor"
                        role="button"
                        onClick={this.addCatClickHandle}
                      >
                        {kTrans("Add Category")} +
                      </span>
                    </div>
                    {catFieldHidden === false ? (
                      <div className="addCatField inline-content middle">
                        <input
                          type="text"
                          className="form-control"
                          onChange={this.addCatFieldChange}
                          placeholder={kTrans("Enter new category")}
                          value={this.state.newCatVal}
                        />
                        <button
                          className="btn btn-secondary"
                          onClick={this.submitCatField}
                        >
                          {kTrans("Add")}
                        </button>
                      </div>
                    ) : null}
                    <hr className="mt-2 mb-1" />
                    {categories && categories.length ? (
                      <DragDropContext onDragEnd={this.onDragEnd}>
                        <Droppable droppableId="characters">
                          {(provided) => (
                            <div
                              className="characters"
                              {...provided.droppableProps}
                              ref={provided.innerRef}
                            >
                              {categories.map((el, i) => (
                                <Draggable
                                  key={el.id}
                                  draggableId={`dragCat-${el.id}`}
                                  index={i}
                                >
                                  {(provided) => (
                                    <div
                                      className="catCols inline-content middle"
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                    >
                                      <span className="catImageCol img-field-wrap">
                                        {el.tempPath ? (
                                          <>
                                            <span
                                              className="close"
                                              onClick={() =>
                                                this.catImgRemoveHandler(
                                                  "imageUrl",
                                                  i,
                                                )
                                              }
                                            >
                                              <i className="fa fa-times"></i>
                                            </span>
                                            <img
                                              alt={el.text}
                                              src={el.tempPath}
                                            />
                                          </>
                                        ) : (
                                          <i
                                            className="fa fa-upload"
                                            aria-hidden="true"
                                          ></i>
                                        )}
                                        <Form.Control
                                          data-index={i}
                                          type="file"
                                          onChange={this.catChangeHandler}
                                          name="imageUrl"
                                        />
                                      </span>
                                      <span className="catTextCol">
                                        <input
                                          data-index={i}
                                          type="text"
                                          onChange={(e) => {
                                            this.catChangeHandler(e);
                                          }}
                                          placeholder={kTrans(
                                            "Enter new category",
                                          )}
                                          name="text"
                                          value={el.text}
                                        />
                                      </span>
                                      <Form.Group>
                                        <>
                                          {i === 0 ? (
                                            <span>{kTrans("Featured")}</span>
                                          ) : (
                                            ""
                                          )}
                                          <Form.Check.Input
                                            className="toggle-checkbox small"
                                            data-index={i}
                                            checked={el.featured}
                                            onChange={this.catChangeHandler}
                                            name="featured"
                                            value="1"
                                          />
                                        </>
                                      </Form.Group>
                                      <Form.Group>
                                        <>
                                          {i === 0 ? (
                                            <span>{kTrans("Admin")}</span>
                                          ) : (
                                            ""
                                          )}
                                          <Form.Check.Input
                                            className="toggle-checkbox small"
                                            data-index={i}
                                            checked={el.adminOnly}
                                            onChange={this.catChangeHandler}
                                            name="adminOnly"
                                            value=""
                                          />
                                        </>
                                      </Form.Group>
                                      <Form.Group>
                                        <>
                                          {i === 0 ? (
                                            <span>{kTrans("Archive")}</span>
                                          ) : (
                                            ""
                                          )}
                                          <Form.Check.Input
                                            className="toggle-checkbox small"
                                            data-index={i}
                                            checked={el.archive}
                                            onChange={this.catChangeHandler}
                                            name="archive"
                                            value=""
                                          />
                                        </>
                                      </Form.Group>
                                      <span className="catReorderCol">
                                        <i className="fa fa-bars"></i>
                                      </span>
                                    </div>
                                  )}
                                </Draggable>
                              ))}
                              {provided.placeholder}
                            </div>
                          )}
                        </Droppable>
                      </DragDropContext>
                    ) : null}
                    <span className="validation-error">
                      {this.state.validationErrors["categories"]}
                    </span>

                    {loading === true ? null : (
                      <div className="buttons">
                        <Button
                          variant="secondary"
                          onClick={this.cancelClickHandle}
                        >
                          {kTrans("Cancel")}
                        </Button>
                        <Button variant="primary" type="submit">
                          {kTrans("Save")}
                        </Button>
                      </div>
                    )}
                  </Col>
                </Form>
              </Row>
            }
          </Tab>

          {loggedInUser &&
          loggedInUser.roles.length > 0 &&
          loggedInUser.roles.find((role) => role.name === "SUPERADMIN") ? (
            <Tab eventKey="admin-settings" title={kTrans("Admin Settings")}>
              {
                <Row
                  className={
                    loading === true
                      ? "position-relative loading justify-content-center"
                      : "position-relative justify-content-center"
                  }
                >
                  <Form
                    className="WLSettings common-form row"
                    onSubmit={this.submitWhiteLabelHandle}
                  >
                    <Col md={6} className="pe-4">
                      <Form.Group>
                        <Form.Label>{kTrans("ADVERTISING SOURCE")}</Form.Label>
                        <select
                          defaultValue={advertisingSource}
                          name="advertisingSource"
                          value={advertisingSource}
                          id="advertisingSource"
                          onChange={this.changeHandler}
                        >
                          <option value="publisher">
                            {kTrans("Publisher")}
                          </option>
                          <option value="adsense">{kTrans("Adsense")}</option>
                          <option value="publisher_desk">
                            {kTrans("Publisher Desk")}
                          </option>
                          <option value="publisher_desk_in_network">
                            {kTrans("Publisher Desk (in-network)")}
                          </option>
                          <option value="rev_content">RevContent</option>
                          <option value="outbrain">Outbrain</option>
                          <option value="taboola">Taboola</option>
                          <option value="datablocks">DataBlocks</option>
                        </select>
                      </Form.Group>
                      <Form.Group>
                        <Form.Label>{kTrans("Web Advertising Source").toUpperCase()}</Form.Label>
                        <select
                          defaultValue={webAdvertisingSource}
                          name="webAdvertisingSource"
                          value={webAdvertisingSource}
                          id="webAdvertisingSource"
                          onChange={this.changeHandler}
                        >
                          <option value="">
                            {kTrans("Publisher Desk")}
                          </option>
                          <option value="outbrain">Outbrain</option>
                        </select>
                      </Form.Group>
                      <Form.Group
                        controlId="allowedAnalyticDomains"
                        className="pt-0"
                      >
                        <Form.Label>{kTrans("ALLOWED DOMAINS")}</Form.Label>
                        <Form.Control
                          type="text"
                          onChange={(e) => this.changeHandler(e)}
                          name="allowedAnalyticDomains"
                          value={allowedAnalyticDomains}
                        />
                      </Form.Group>
                      <Form.Group controlId="revenueShare" className="pt-0">
                        <Form.Label>
                          {kTrans("Revenue Share %").toUpperCase()}
                        </Form.Label>
                        <Form.Control
                          type="number"
                          min={0}
                          max={100}
                          onChange={(e) =>
                            this.setState({
                              revenueShare: parseInt(e.target.value),
                            })
                          }
                          name="revenueShare"
                          value={revenueShare}
                        />
                      </Form.Group>
                      <Form.Group controlId="datablocksSid" className="pt-0">
                        <Form.Label>
                          {kTrans("DataBlocks SID").toUpperCase()}
                        </Form.Label>
                        <Form.Control
                          type="text"
                          onChange={(e) =>
                            this.setState({
                              datablocksSid: e.target.value.trim(),
                            })
                          }
                          name="datablocksSid"
                          value={datablocksSid}
                        />
                      </Form.Group>
                      <div className="inline-toggles  mb-4 mt-4">
                        <Form.Group>
                          <Form.Check.Label className="mb-3">
                            {kTrans("ADVERTISING")}
                          </Form.Check.Label>
                          <Form.Check.Input
                            className="toggle-checkbox"
                            checked={advertising}
                            onChange={this.changeHandler}
                            name="advertising"
                            value={advertising}
                            data-type="ADVERTISING"
                          />
                        </Form.Group>
                        <Form.Group>
                          <Form.Check.Label className="mb-3">
                            {kTrans("PRIVATE NETWORK")}
                          </Form.Check.Label>
                          <Form.Check.Input
                            className="toggle-checkbox"
                            checked={isPrivate}
                            onChange={this.changeHandler}
                            name="isPrivate"
                            value={isPrivate}
                            data-type="PRIVATE NETWORK"
                          />
                        </Form.Group>
                        <Form.Group>
                          <Form.Check.Label className="mb-3">
                            {kTrans("DEFAULT NETWORK")}
                          </Form.Check.Label>
                          <Form.Check.Input
                            className="toggle-checkbox"
                            checked={isDefault}
                            onChange={this.changeHandler}
                            name="isDefault"
                            value={isDefault}
                            data-type="DEFAULT NETWORK"
                          />
                        </Form.Group>
                        <Form.Group>
                          <Form.Check.Label className="mb-3">
                            {kTrans("FEATURED NETWORK")}
                          </Form.Check.Label>
                          <Form.Check.Input
                            className="toggle-checkbox"
                            checked={isFeatured}
                            onChange={this.changeHandler}
                            name="isFeatured"
                            value={isFeatured}
                            data-type="FEATURED NETWORK"
                          />
                        </Form.Group>
                        <Form.Group>
                          <Form.Check.Label className="mb-3">
                            {kTrans("API ACCESS")}
                          </Form.Check.Label>
                          <Form.Check.Input
                            className="toggle-checkbox"
                            checked={apiAccess}
                            onChange={this.changeHandler}
                            name="apiAccess"
                            value={apiAccess}
                            data-type="API Access"
                          />
                        </Form.Group>
                        <Form.Group>
                          <Form.Check.Label className="mb-3">
                            {kTrans("SPANISH")}
                          </Form.Check.Label>
                          <Form.Check.Input
                            className="toggle-checkbox"
                            checked={isSpanish}
                            onChange={this.changeHandler}
                            name="isSpanish"
                            value={isSpanish}
                            data-type="Spanish"
                          />
                        </Form.Group>
                        <Form.Group>
                          <Form.Check.Label className="mb-3">
                            {kTrans("AUTOMATION")}
                          </Form.Check.Label>
                          <Form.Check.Input
                            className="toggle-checkbox"
                            checked={isAutomated}
                            onChange={this.changeHandler}
                            name="isAutomated"
                            value={isAutomated}
                            data-id="AUTOMATION"
                          />
                        </Form.Group>
                        <Form.Group>
                          <Form.Check.Label className="mb-3">
                            {kTrans("SHOW MULTIPLE POLLS")}
                          </Form.Check.Label>
                          <Form.Check.Input
                            className="toggle-checkbox"
                            checked={isMultiple}
                            onChange={this.changeHandler}
                            name="isMultiple"
                            value={isMultiple}
                            data-id="SHOW MULTIPLE POLLS"
                          />
                        </Form.Group>
                        <Form.Group>
                          <Form.Check.Label className="mb-3">
                            {kTrans("GA TRACKING")}
                          </Form.Check.Label>
                          <Form.Check.Input
                            className="toggle-checkbox"
                            checked={gaTracking}
                            onChange={this.changeHandler}
                            name="gaTracking"
                            value={gaTracking}
                            data-id="GA TRACKING"
                          />
                        </Form.Group>
                        <Form.Group>
                          <Form.Check.Label className="mb-3">
                            {kTrans("ADS IN SIDE PANEL")}
                          </Form.Check.Label>
                          <Form.Check.Input
                            className="toggle-checkbox"
                            checked={sidebarAds}
                            onChange={this.changeHandler}
                            name="sidebarAds"
                            value={sidebarAds}
                            data-id="ADS IN SIDE PANEL"
                          />
                        </Form.Group>
                        <Form.Group>
                          <Form.Check.Label className="mb-3">
                            {kTrans("AI CONTENT")}
                          </Form.Check.Label>
                          <Form.Check.Input
                            className="toggle-checkbox"
                            checked={aiContent}
                            onChange={this.changeHandler}
                            name="aiContent"
                            value={aiContent}
                            data-id="AI CONTENT"
                          />
                        </Form.Group>
                      </div>
                      <Form.Group
                        controlId="allowedAnalyticDomains"
                        className="pt-0"
                      >
                        <span
                          className="customAnchor"
                          onClick={this.showArchiveToggleModal}
                        >
                          {archive ? kTrans("Unarchive") : kTrans("Archive")}{" "}
                          network
                        </span>
                      </Form.Group>
                      {loading === true ? null : (
                        <div className="buttons">
                          <Button
                            variant="secondary"
                            onClick={this.cancelClickHandle}
                          >
                            {kTrans("Cancel")}
                          </Button>
                          <Button variant="primary" type="submit">
                            {kTrans("Save")}
                          </Button>
                        </div>
                      )}
                    </Col>
                  </Form>
                </Row>
              }
            </Tab>
          ) : null}
        </Tabs>

        <Modal
          className="removeDebModal"
          size="lg"
          show={this.state.archiveToggleModal}
        >
          <Modal.Body>
            <div className="modalContentWrapper">
              <Form className="toggleArchive common-form">
                <div className="form-group txtCenter">
                  <b>
                    {kTrans("Do you really want to")}{" "}
                    {this.state.archive
                      ? kTrans("unarchive")
                      : kTrans("archive")}{" "}
                    {kTrans("this network?")}
                  </b>
                </div>
                <div className="buttons text-center">
                  <span
                    className="btn btn-secondary"
                    onClick={this.hideArchiveToggleModal}
                  >
                    {kTrans("No")}
                  </span>
                  <span
                    className="btn btn-primary"
                    onClick={this.archiveNetworkToggle}
                  >
                    {kTrans("Yes")}
                  </span>
                </div>
              </Form>
            </div>
          </Modal.Body>
        </Modal>
      </section>
    );
  }
}

const mapStateToProps = (state) => ({
  loggedInUser: state.login.user,
  selNetworkId: state.networks.selNetworkId,
  networks: state.networks.networks,
});

const Extended = withTranslation()(EditNetwork);
Extended.static = EditNetwork.static;

export default connect(mapStateToProps, {
  saveSelectedNetwork,
  loadNetworksList,
})(Extended);
