import React, { Component } from "react";
import { connect } from "react-redux";
import axios from "axios";
import moment from "moment";
import { Row, Col, Tab, Tabs } from "react-bootstrap";
import DateRangePicker from "react-bootstrap-daterangepicker";
import { Line } from "react-chartjs-2";
import { API_URL } from "../../constants/Globals";
import { withTranslation } from "react-i18next";

// const DocumentIcon = () => <svg width="13" height="17" viewBox="0 0 13 17" fill="none" xmlns="http://www.w3.org/2000/svg">
// 	<path d="M8.125 0L13 4.875V14.625C13 15.056 12.8288 15.4693 12.524 15.774C12.2193 16.0788 11.806 16.25 11.375 16.25H1.625C1.19402 16.25 0.780698 16.0788 0.475951 15.774C0.171205 15.4693 0 15.056 0 14.625V1.625C0 1.19402 0.171205 0.780698 0.475951 0.475951C0.780698 0.171205 1.19402 0 1.625 0H8.125ZM11.375 14.625V5.6875H7.3125V1.625H1.625V14.625H11.375ZM6.5 13.8125L3.25 10.5625H5.28125V8.125H7.71875V10.5625H9.75L6.5 13.8125Z" fill="#BDBDBD"/>
// </svg>

class Advertising extends Component {
  constructor() {
    super();

    this.state = {
      isManaged: false,
      activeCategory: "impressions",
      activeTab: "overall",
      advertisingStats: {},
      advertisingChartValues: null,
      durStartTime: moment().subtract(14, "days").format("YYYY-MM-DD"),
      durEndTime: moment().subtract(1, "days").format("YYYY-MM-DD"),
    };
  }

  componentDidMount() {
    this.loadData();
    this.loadChartsData();
  }

  componentDidUpdate(prevProps, prevState) {
    const { selNetworkId } = this.props;

    if (prevProps.selNetworkId !== selNetworkId) {
      this.loadData();
      this.loadChartsData();
    }
  }

  loadData = async () => {
    await this.setState({ advertisingStats: [] });

    const { durStartTime, durEndTime } = this.state;
    const { selNetworkId, networks } = this.props;

    if (networks) {
      const network = networks.find(
        (network) => network.id.toString() === selNetworkId.toString(),
      );
      const primaryDomain = network.embedDomains.find(
        (domain) => domain.isPrimary,
      );

      if (primaryDomain) {
        axios
          .get(
            API_URL +
              `/admin/analytics/getAdvertisingStats?domainId=${primaryDomain.id}&durStartTime=${durStartTime}T00:00:00.000Z&durEndTime=${durEndTime}T00:00:00.000Z`,
          )
          .then(({ data }) => {
            this.setState({ advertisingStats: data.data });
          });
      }
    }
  };

  loadChartsData = async () => {
    await this.setState({ advertisingChartValues: null });

    const { activeCategory, durStartTime, durEndTime } = this.state;
    const { selNetworkId, networks } = this.props;

    if (networks) {
      const network = networks.find(
        (network) => network.id.toString() === selNetworkId.toString(),
      );
      const primaryDomain = network.embedDomains.find(
        (domain) => domain.isPrimary,
      );

      if (primaryDomain) {
        axios
          .get(
            API_URL +
              `/admin/analytics/getAdvertisingChartValues?domainId=${primaryDomain.id}&durStartTime=${durStartTime}&durEndTime=${durEndTime}&chartType1=overall&chartType2=${activeCategory === "ecpm" ? "eCPM" : activeCategory === "revenue" ? "netRevenue" : "impressions"}`,
          )
          .then(({ data }) => {
            this.setState({ advertisingChartValues: data.data });
          });
      }
    }
  };

  render() {
    const {
      activeTab,
      activeCategory,
      advertisingStats,
      advertisingChartValues,
    } = this.state;
    const formatter = Intl.NumberFormat("en-US");
    const chartData = {};
    const kTrans = this.props.t ? this.props.t : "";

    if (advertisingChartValues) {
      advertisingChartValues.labels.forEach((label, index) => {
        chartData[label] = advertisingChartValues.values[index];
      });
    }

    return (
      <section>
        <Row className="">
          <Col md={12} className="inline-content top title-section">
            <h2>{kTrans("Advertising")}</h2>
            <div className="d-flex">
              <div className="datepickerSec">
                <i className="fa fa-calendar"></i>
                <DateRangePicker
                  onCallback={async (start, end, label) => {
                    await this.setState({
                      durStartTime: moment(start).format("YYYY-MM-DD"),
                      durEndTime: moment(end).format("YYYY-MM-DD"),
                    });
                    this.loadData();
                    this.loadChartsData();
                  }}
                  initialSettings={{
                    startDate: moment().subtract(14, "days"),
                    endDate: moment().subtract(1, "days"),
                    ranges: {
                      [this.props.t("Yesterday")]: [
                        moment().subtract(1, "days"),
                        moment().subtract(1, "days"),
                      ],
                      [this.props.t("Last 2 weeks")]: [
                        moment().subtract(14, "days"),
                        moment().subtract(1, "days"),
                      ],
                      [this.props.t("Last 30 Days")]: [
                        moment().subtract(30, "days"),
                        moment().subtract(1, "days"),
                      ],
                      [this.props.t("This Month")]: [
                        moment().startOf("month"),
                        moment().subtract(1, "days"),
                      ],
                      [this.props.t("Last Month")]: [
                        moment().subtract(1, "month").startOf("month"),
                        moment().subtract(1, "month").endOf("month"),
                      ],
                      [this.props.t("Last 90 Days")]: [
                        moment().subtract(90, "days"),
                        moment().subtract(1, "days"),
                      ],
                      [this.props.t("This year")]: [
                        moment().startOf("year"),
                        moment().subtract(1, "days"),
                      ],
                      [this.props.t("Last year")]: [
                        moment().subtract(1, "year").startOf("year"),
                        moment().subtract(1, "year").endOf("year"),
                      ],
                    },
                  }}
                >
                  <input type="text" className="datepickerField" />
                </DateRangePicker>
              </div>
            </div>
          </Col>
        </Row>
        {/*
			<Row className="mb-4">
				<Col md={12}>
					<span 
						className={ 'toggle-side ' + (isManaged ? '' : 'active') }
						onClick={() => this.setState({ isManaged: false })}
					>
						Programmatic
					</span>
					<Form.Check.Input 
						className="toggle-checkbox small sided-id" 
						style={{ float: 'none' }}
						checked={isManaged} 
						onChange={() => this.setState({ isManaged: !isManaged })}
						name="featured" 
						value="1" 
					/>
					<span 
						className={ 'toggle-side ' + (isManaged ? 'active' : '') }
						onClick={() => this.setState({ isManaged: true })}
					>
						Managed Campaigns
					</span>
				</Col>
			</Row>
			*/}
        <Row className="mb-5 advertising-stats">
          <Col md={4}>
            <div
              className={
                "card widget-flat main-category " +
                (activeCategory === "impressions" ? "active" : "")
              }
              onClick={async () => {
                await this.setState({ activeCategory: "impressions" });
                this.loadChartsData();
              }}
            >
              <div className="card-body">
                <div className="d-flex align-items-center justify-content-between mb-2">
                  <h1>
                    {advertisingStats.viewableImpressions
                      ? formatter.format(
                          advertisingStats.viewableImpressions.overall,
                        )
                      : 0}
                  </h1>
                </div>
                <div className="d-flex align-items-center justify-content-between">
                  <strong>{kTrans("Impressions")}</strong>
                  {/*
								<div>
									14,231 <small>Previous</small>
								</div>
								*/}
                </div>
              </div>
            </div>
          </Col>
          <Col md={4}>
            <div
              className={
                "card widget-flat main-category " +
                (activeCategory === "revenue" ? "active" : "")
              }
              onClick={async () => {
                await this.setState({ activeCategory: "revenue" });
                this.loadChartsData();
              }}
            >
              <div className="card-body">
                <div className="d-flex align-items-center justify-content-between mb-2">
                  <h1>
                    $
                    {advertisingStats.netRevenue
                      ? formatter.format(
                          Math.floor(advertisingStats.netRevenue.overall),
                        )
                      : 0}
                  </h1>
                </div>
                <div className="d-flex align-items-center justify-content-between">
                  <strong>{kTrans("Revenue")}</strong>
                  {/*
								<div>
									$.065 <small>Previous</small>
								</div>
								*/}
                </div>
              </div>
            </div>
          </Col>
          <Col md={4}>
            <div
              className={
                "card widget-flat main-category " +
                (activeCategory === "ecpm" ? "active" : "")
              }
              onClick={async () => {
                await this.setState({ activeCategory: "ecpm" });
                this.loadChartsData();
              }}
            >
              <div className="card-body">
                <div className="d-flex align-items-center justify-content-between mb-2">
                  <h1>
                    $
                    {advertisingStats.eCPM && advertisingStats.eCPM.overall
                      ? advertisingStats.eCPM.overall.toFixed(2)
                      : 0}
                  </h1>
                </div>
                <div className="d-flex align-items-center justify-content-between">
                  <strong>{kTrans("eCPM")}</strong>
                  {/*
								<div>
									$.065 <small>Previous</small>
								</div>
								*/}
                </div>
              </div>
            </div>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col md={6}>
            <h3>
              {activeCategory === "ecpm"
                ? kTrans("eCPM")
                : activeCategory === "revenue"
                  ? kTrans("Revenue")
                  : kTrans("Impressions")}
            </h3>
          </Col>
          {/*
				<Col md={6} className="text-end">
					<span className="text-button">
						<DocumentIcon /> Download CSV
					</span>
				</Col>
				*/}
        </Row>
        <Row className="mb-5 mb-md-5">
          <Col md={12}>
            {advertisingChartValues ? (
              <Line
                options={{
                  responsive: true,
                  plugins: { legend: { display: false } },
                  scales: {
                    y: {
                      ticks: {
                        callback: (label, index, labels) =>
                          activeCategory === "impressions"
                            ? (label.toString().indexOf("000") === -1 &&
                              label !== 0
                                ? (label / 1000).toFixed(2)
                                : (label / 1000).toFixed(2)) + "k"
                            : "$" + label.toFixed(2),
                      },
                    },
                  },
                }}
                data={{
                  labels: advertisingChartValues.labels,
                  datasets: [
                    {
                      label: "",
                      data: chartData,
                      borderColor: "rgb(53, 162, 235)",
                      backgroundColor: "rgba(53, 162, 235, 0.5)",
                    },
                  ],
                }}
              />
            ) : null}
          </Col>
        </Row>
        <Row className="mb-2">
          <Col className="sided-tabs">
            <Tabs
              variant="pills"
              activeKey={activeTab}
              defaultActiveKey={activeTab}
              onSelect={(activeTab) => this.setState({ activeTab })}
              className="d-flex justify-content-center"
            >
              <Tab eventKey="overall" title={kTrans("Overall")}></Tab>
              <Tab eventKey="mobile" title={kTrans("Mobile")}></Tab>
              <Tab eventKey="desktop" title={kTrans("Desktop")}></Tab>
            </Tabs>
          </Col>
        </Row>
        {/*
			<Row className="mb-2">
				<Col md={12} className="text-end">
					<span className="text-button">
						<DocumentIcon /> Download CSV
					</span>
				</Col>
			</Row>
			*/}
        <Row className="mb-5 mb-md-5 advertising-stats">
          <Col md={3}>
            <div className="card">
              <div className="card-header text-center">
                <strong>{kTrans("Impressions")}</strong>
              </div>
              <div className="card-body text-center">
                <h2>
                  {advertisingStats.viewableImpressions
                    ? formatter.format(
                        advertisingStats.viewableImpressions[activeTab],
                      )
                    : 0}
                </h2>
                {/*<div>Impressions</div>*/}
              </div>
            </div>
          </Col>
          <Col md={3}>
            <div className="card">
              <div className="card-header text-center">
                <strong>{kTrans("Revenue")}</strong>
              </div>
              <div className="card-body text-center">
                <h2>
                  $
                  {advertisingStats.netRevenue
                    ? Math.floor(advertisingStats.netRevenue[activeTab])
                    : 0}
                </h2>
              </div>
            </div>
          </Col>
          <Col md={3}>
            <div className="card">
              <div className="card-header text-center">
                <strong>{kTrans("Clicks")}</strong>
              </div>
              <div className="card-body text-center">
                <h2>
                  {advertisingStats.clicks
                    ? formatter.format(advertisingStats.clicks[activeTab])
                    : 0}
                </h2>
              </div>
            </div>
          </Col>
          <Col md={3}>
            <div className="card">
              <div className="card-header text-center">
                <strong>{kTrans("eCPM")}</strong>
              </div>
              <div className="card-body text-center">
                <h2>
                  $
                  {advertisingStats.eCPM && advertisingStats.eCPM[activeTab]
                    ? advertisingStats.eCPM[activeTab].toFixed(2)
                    : 0}
                </h2>
              </div>
            </div>
          </Col>
        </Row>
        {/*
			<Row>
				<Col className="sided-tabs">
					<Tabs
						variant="pills"
						activeKey={activeTab}
			      defaultActiveKey={activeTab}
			      onSelect={activeTab => this.setState({ activeTab })}
			      className="d-flex justify-content-center"
			    >
			      <Tab eventKey="overall" title="Overall"></Tab>
			      <Tab eventKey="mobile" title="Mobile"></Tab>
			      <Tab eventKey="desktop" title="Desktop"></Tab>
			    </Tabs>
				</Col>
			</Row>
			<Row>
				<Col md={12}>
					<Bar 
						options={{
						  indexAxis: 'y',
						  responsive: true,
						  plugins: { legend: { display: false } },
						  scales: { x: { min: 0, max: 20 } }
						}} 
						data={{
						  labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
						  datasets: [{
						    label: '',
						    data: [3,6,9,12,15,18,18,15,12,9,6,3],
						    borderColor: 'rgb(53, 162, 235)',
						    backgroundColor: 'rgba(53, 162, 235, 0.5)'
						  }]
						}} 
					/>
				</Col>
			</Row>
			*/}
      </section>
    );
  }
}

const mapStateToProps = (state) => ({
  networks: state.networks.networksList,
  selNetworkId: state.networks.selNetworkId,
  selDomainId: state.embedDomains.selDomainId,
});

const Extended = withTranslation()(Advertising);
Extended.static = Advertising.static;

export default connect(mapStateToProps)(Extended);
