import { BASENAME } from "../../constants/Globals";
const init = {
  userId: localStorage.getItem("userId"),
  isLogin: false,
  isLoading: false,
  isVerified: false,
  user: null,
  loginError: "",
  forgotPasswordErr: "",
  forgotPasswordSuc: "",
};

export const loginReducer = (state = init, action) => {
  switch (action.type) {
    case "USER_LOADING":
      return {
        ...state,
        isLoading: true,
      };
    case "USER_LOADED":
      if (window.location.href.indexOf("/onboarding") === -1) {
        const userData = action.payload.data;
        if (!userData.contactVerified) {
          window.location.href = BASENAME + "/onboarding";
        }
      }

      return {
        ...state,
        isLogin: true,
        isLoading: false,
        isVerified: action.payload.data.contactVerified,
        user: action.payload.data,
      };
    case "LOGIN":
      localStorage.removeItem("selNetworkId");
      localStorage.setItem("token", action.payload.token);
      localStorage.setItem("tokenExpireAt", action.payload.tokenExpireAt);
      localStorage.setItem("refreshToken", action.payload.refreshToken);
      localStorage.setItem(
        "refreshTokenExpireAt",
        action.payload.refreshTokenExpireAt,
      );
      localStorage.setItem("userId", action.payload.data.user.id);
      window.location.reload();
      //window.location.href = BASENAME;
      return {
        ...state,
        userId: action.payload.data.user.id,
        isLogin: true,
        isLoading: false,
        user: action.payload.data.user,
      };
    case "AUTH_ERROR":
    case "LOGIN_FAIL":
      return {
        ...state,
        isLogin: false,
        isLoading: false,
        loginError: action.payload,
      };
    case "LOGOUT_SUCCESS":
    case "TOKEN_REFRESH_ERROR":
      localStorage.removeItem("token");
      localStorage.removeItem("tokenExpireAt");
      localStorage.removeItem("refreshToken");
      localStorage.removeItem("refreshTokenExpireAt");
      localStorage.removeItem("userId");
      window.location.href = BASENAME;
      return {
        ...state,
        userId: null,
        isLogin: false,
        isLoading: false,
        user: null,
      };
    case "TOKEN_REFRESHED":
      localStorage.setItem("token", action.payload.token);
      localStorage.setItem("tokenExpireAt", action.payload.tokenExpireAt);
      localStorage.setItem("refreshToken", action.payload.refreshToken);
      localStorage.setItem(
        "refreshTokenExpireAt",
        action.payload.refreshTokenExpireAt,
      );
      return {
        ...state,
      };
    case "APIKEY_LOADED":
      localStorage.setItem("apiKey", action.payload.token);
      localStorage.setItem("apiKeyExpireAt", action.payload.tokenExpireAt);
      return {
        ...state,
      };
    case "APIKEY_LOAD_ERROR":
      localStorage.removeItem("apiKey");
      localStorage.removeItem("apiKeyExpireAt");
      return {
        ...state,
      };
    case "FORGOT_PASSWORD":
      setTimeout(() => {
        window.location.href = BASENAME;
      }, 4000);
      return {
        ...state,
        forgotPasswordErr: "",
        forgotPasswordSuc: action.payload,
      };
    case "FORGOT_PASSWORD_FAIL":
      return {
        ...state,
        forgotPasswordErr: action.payload,
      };
    default:
      return state;
  }
};
