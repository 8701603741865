import axios from "axios";
import moment from "moment";
import { connect } from "react-redux";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import TableSearch from "../TableSearch";
import { API_URL } from "../../constants/Globals";

function Segments({ selNetworkId }) {
  const [state, setState] = useState(1);
  const [search, setSearch] = useState("");
  const [sortBy, setSortBy] = useState("date");
  const [segments, setSegments] = useState([]);
  const [reportData, setReportData] = useState(null);
  const [exportedSegment, setExportedSegment] = useState(0);

  useEffect(() => {
    if (selNetworkId) {
      axios
        .get(`${API_URL}/admin/segments?clientId=${selNetworkId}`)
        .then(({ data }) => setSegments(data.data));
    }
  }, [selNetworkId, state]);

  return (
    <section className="segments">
      <div className="title-bar">
        <h1 className="page-title">Segments</h1>
        <Link to="/audience/segments/new" className="blue-button size-l">
          Create Segment
        </Link>
      </div>

      <div className="table-filters">
        <TableSearch
          value={search}
          placeholder="Search for segment"
          onChange={(value) => setSearch(value)}
        />
      </div>

      <div className="table-filters sorting">
        <div className="table-filters-start">
          <div className="table-sorter">
            <label>Sort by</label>
            <select
              value={sortBy}
              onChange={({ target }) => setSortBy(target.value)}
            >
              <option value="date">Most Recent</option>
              <option value="name">Name</option>
            </select>
          </div>
        </div>

        <div className="table-pagination">{segments.length} Segments</div>
      </div>
      <div className="table-wrapper">
        <table
          className="table-body top-border"
          cellSpacing="0"
          cellPadding="0"
        >
          <tbody>
            {segments
              .sort((a, b) => {
                if (sortBy === "date") {
                  return moment(a.createdAt) < moment(b.createdAt) ? 1 : -1;
                }

                return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1;
              })
              .filter(
                ({ name }) =>
                  !search ||
                  name.toLowerCase().indexOf(search.toLowerCase().trim()) !==
                    -1,
              )
              .map(({ id, name, userCount, createdAt }) => (
                <tr key={id} className="table-row">
                  <td className="table-cell table-cell-fluid">
                    <Link
                      to={`/audience?segment=${id}`}
                      className="text-button inherit-font"
                    >
                      {name}
                    </Link>
                  </td>
                  <td className="table-cell table-cell-labelled" width="120">
                    <div>
                      People
                      <br />
                      <strong>{userCount}</strong>
                    </div>
                  </td>
                  <td className="table-cell table-cell-labelled" width="150">
                    <div>
                      Created
                      <br />
                      <strong>{moment(createdAt).format("MMM DD, Y")}</strong>
                    </div>
                  </td>
                  <td className="table-cell table-action" width="280">
                    <div className="inline-buttons">
                      <Link
                        to={`/audience/segments/${id}`}
                        className="blue-button bordered"
                      >
                        Edit
                      </Link>
                      {userCount > 0 ? (
                        <button
                          className="blue-button bordered"
                          onClick={() => {
                            if (!exportedSegment) {
                              setExportedSegment(id);
                              axios
                                .get(
                                  `${API_URL}/admin/segments/${id}?clientId=${selNetworkId}&csv=1`,
                                )
                                .then(({ data }) => {
                                  const { link } = data.data;
                                  setReportData(link);
                                  setExportedSegment(0);
                                });
                            }
                          }}
                        >
                          {exportedSegment === id
                            ? "Please wait..."
                            : "Export As CSV"}
                        </button>
                      ) : null}
                      <button
                        className="blue-button bordered"
                        onClick={() => {
                          if (window.confirm("Are you sure?")) {
                            axios
                              .delete(
                                `${API_URL}/admin/segments/delete/${id}?clientId=${selNetworkId}`,
                              )
                              .then(() => setState(state * 2));
                          }
                        }}
                      >
                        Delete
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
      {reportData ? (
        <div className="modal-cta">
          <div className="modal-cta-inner">
            <h3>The export was successfully generated</h3>
            <div className="modal-cta-buttons">
              <button
                className="blue-button bordered"
                onClick={() => setReportData(null)}
              >
                Close
              </button>
              <a
                href={reportData}
                className="blue-button"
                onClick={() => setReportData(null)}
              >
                Download
              </a>
            </div>
          </div>
        </div>
      ) : null}
    </section>
  );
}

const mapStateToProps = (state) => ({
  selNetworkId: state.networks.selNetworkId,
});

export default connect(mapStateToProps)(Segments);
