import axios from "axios";
import { API_URL, NETWORKS_PER_PAGE } from "../../constants/Globals";

export const loadNetworksList = () => (dispatch, getState) => {
  console.log("redux action loadNetworksList");
  dispatch({
    type: "NETWORKS_LOADING",
  });
  let userRoles = getState().login.user.roles;
  let queryParams =
    "?perPage=" + NETWORKS_PER_PAGE + "&pageNumber=1&order=asc&orderBy=title";
  axios
    .get(API_URL + "/admin/client/getAllClients" + queryParams)
    .then((res) =>
      dispatch({
        type: "NETWORKS_LIST_LOADED",
        payload: res.data,
        userRoles: userRoles,
      }),
    )
    .catch((err) => {
      console.log("err", err);
    });
};

export const loadNetworks =
  (search = "", page = 1, order = "", orderBy = "", filter = "") =>
  (dispatch) => {
    console.log("redux action loadNetworks");
    dispatch({
      type: "NETWORKS_LOADING",
    });
    let queryParams = "?perPage=" + NETWORKS_PER_PAGE + "&pageNumber=" + page;
    if (search.trim() !== "") {
      queryParams = queryParams + "&searchText=" + search.trim();
    }
    if (order.trim() !== "") {
      queryParams = queryParams + "&order=" + order.trim();
    }
    if (orderBy.trim() !== "") {
      queryParams = queryParams + "&orderBy=" + orderBy.trim();
    }
    if (filter.trim() !== "") {
      queryParams = queryParams + "&filter=" + filter.trim();
    }
    axios
      .get(API_URL + "/admin/client/getAllClients" + queryParams)
      .then((res) =>
        dispatch({
          type: "NETWORKS_LOADED",
          payload: res.data,
        }),
      )
      .catch((err) => {
        console.log("err", err);
      });
  };

export const saveSelectedNetwork =
  (selNetworkId = null, refresh = false) =>
  (dispatch) => {
    console.log("redux action saveSelectedNetwork");

    axios
      .get(API_URL + "/admin/client/" + selNetworkId)
      .then((res) => {
        dispatch({
          type: "SAVE_CURRENT_NETWORK",
          payload: res.data.data,
        });

        if (refresh) {
          window.location.reload();
        }
      })
      .catch((err) => {
        console.log("err", err);
      });

    /*dispatch({
        type: "SAVE_CURRENT_NETWORK",
        payload: selNetworkId
    })*/
  };

export const saveSelectedGroup =
  (selGroupSlug = null) =>
  (dispatch) => {
    console.log("redux action saveSelectedGroup");
    dispatch({
      type: "SAVE_CURRENT_GROUP",
      payload: selGroupSlug,
    });
  };

export const loadGroupsList =
  (search = "") =>
  (dispatch) => {
    console.log("redux action loadGroupsList");
    let queryParams = "";
    if (search.trim() !== "") {
      queryParams = queryParams + "?searchText=" + search.trim();
    }
    axios
      .get(API_URL + "/admin/groups/getGroups" + queryParams)
      .then((res) =>
        dispatch({
          type: "GROUPS_LIST_LOADED",
          payload: res.data,
        }),
      )
      .catch((err) => {
        console.log("err", err);
      });
  };
