import React from "react";
import { Table, Modal, Form, Button, Tab, Nav } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCog } from "@fortawesome/free-solid-svg-icons";
import { connect } from "react-redux";
import { SIDED_FAVICON, USERS_PER_PAGE } from "../../constants/Globals";
import axios from "axios";
import { API_URL } from "../../constants/Globals";
import ReactPaginate from "react-paginate";
import InviteModal from "../../components/InviteModal";
import { withTranslation } from "react-i18next";

class GroupNetworkUsers extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      searchText: "",
      order: "",
      orderBy: "title",
      showAddUserToGroupNetworkModal: false,
      showAdvanceUserSettingsModal: false,
      groupNetworkUsers: "",
      pendingInvites: "",
      advanceUserSettingsFormData: {
        groupsData: [],
        networksData: [],
      },
      addUserToGroupNetworkFormData: {
        clientId: "",
        userId: "",
      },
      showSuccessMsg: false,
      advanceUserSettingsModalContent: "",
      availableNetworks: "",
      clientId: this.props.match.params.clientId
        ? this.props.match.params.clientId
        : "",
      clientName: "",
      groupsAndNetworksByUser: "",
      pageNumber: 1,
      groupSlug: this.props.match.params.groupSlug
        ? this.props.match.params.groupSlug
        : "",
      showInviteModal: false,
      showPendingInvites: false,
      inviteEmail: "",
      inviteRole: "",
    };
  }

  getNetworkDetail = async (e) => {
    await axios
      .get(API_URL + "/admin/client/" + this.state.clientId)
      .then((res) => {
        this.setState({ clientName: res.data.data.header.title });
      })
      .catch((err) => {
        alert(
          "There is a problem in fetching network detail. Please try again!",
        );
        console.log(err);
      });
  };

  getGroupNetworkUsers = async (e) => {
    if (this.state.clientName === "") {
      await this.getNetworkDetail();
    }
    await this.setState({
      loading: true,
    });

    const { clientId, pageNumber } = this.state;
    var urlToHit =
      "/admin/groups/getGroupNetworkUsers?searchText=" +
      this.state.searchText +
      "&perPage=" +
      USERS_PER_PAGE +
      "&pageNumber=" +
      pageNumber +
      "&clientId=" +
      clientId;

    await axios
      .get(API_URL + urlToHit)
      .then((res) => {
        this.setState({
          groupNetworkUsers: res.data.data,
          loading: false,
        });
      })
      .catch((err) => {
        alert("Failed. Please try again!");
        console.log(err);
      });
  };

  componentDidMount = () => {
    this.getGroupNetworkUsers();
    this.fetchPendingInvites();
  };

  handleSearchSubmit = async (e) => {
    await this.setState({
      pageNumber: 1,
    });
    if (this.state.showPendingInvites) {
      this.fetchPendingInvites();
    } else {
      this.getGroupNetworkUsers();
    }
    e.preventDefault();
  };
  handleQueryChange = async (e) => {
    this.setState({ searchText: e.target.value });
  };

  showAddUserToGroupNetworkModal = async () => {
    this.setState({
      showAddUserToGroupNetworkModal: true,
      showAdvanceUserSettingsModal: false,
    });
  };

  hideAddUserToGroupNetworkModal = async (e) => {
    this.setState({
      showAddUserToGroupNetworkModal: false,
      showSuccessMsg: false,
      showAdvanceUserSettingsModal: true,
    });
    /*var addUserToGroupNetworkFormData = {...this.state.addUserToGroupNetworkFormData}
		addUserToGroupNetworkFormData['clientId'] = '';
		addUserToGroupNetworkFormData['userId'] = '';
		await this.setState({addUserToGroupNetworkFormData});*/
  };

  showAdvanceUserSettingsModal = async (e, i, currUserId) => {
    this.getGroupsAndNetworksByUser(currUserId);

    this.getAvailableNetworks(currUserId);

    var addUserToGroupNetworkFormData = {
      ...this.state.addUserToGroupNetworkFormData,
    };
    addUserToGroupNetworkFormData["userId"] = currUserId;
    await this.setState({ addUserToGroupNetworkFormData });

    await this.setState({
      advanceUserSettingsModalContent: this.state.groupNetworkUsers.rows[i],
    });

    this.setState({
      showAdvanceUserSettingsModal: true,
    });
  };

  hideAdvanceUserSettingsModal = async (e) => {
    this.setState({
      showAdvanceUserSettingsModal: false,
      showSuccessMsg: false,
    });
    var addUserToGroupNetworkFormData = {
      ...this.state.addUserToGroupNetworkFormData,
    };
    addUserToGroupNetworkFormData["clientId"] = "";
    await this.setState({ addUserToGroupNetworkFormData });
  };

  networkFormChangeHandler = async (e) => {
    var index = e.nativeEvent.target.selectedIndex;
    var addUserToGroupNetworkFormData = {
      ...this.state.addUserToGroupNetworkFormData,
    };
    addUserToGroupNetworkFormData[e.target.name] = e.target.value;
    addUserToGroupNetworkFormData["selectedNetwork"] =
      e.nativeEvent.target[index].text;
    await this.setState({ addUserToGroupNetworkFormData });
  };

  submitAddUserToGroupNetworkHandler = async (e) => {
    const { userId } = this.state.addUserToGroupNetworkFormData;
    e.preventDefault();
    await this.setState({
      loading: true,
    });

    await axios
      .post(
        API_URL + "/admin/groups/addGroupNetworkUser",
        this.state.addUserToGroupNetworkFormData,
      )
      .then((res) => {
        this.getGroupNetworkUsers();
        this.getGroupsAndNetworksByUser(userId);
        this.getAvailableNetworks(userId);
        this.messageNotification(
          "success",
          this.props.t("User added to network successfully!"),
        );
        this.setState({ loading: false, showSuccessMsg: true });
      })
      .catch((err) => {
        alert(
          "There is a problem in adding user to network. Please try again!",
        );
        console.log(err);
        this.setState({ loading: false });
      });
  };

  messageNotification = async (type, message) => {
    this.setState({
      successMsg: message,
    });

    setTimeout(() => {
      this.setState({
        successMsg: "",
      });
    }, 5000);
  };

  removeUserFromGroupNetwork = async (e, userId) => {
    await this.setState({
      loading: true,
    });

    var addUserToGroupNetworkFormData = {
      ...this.state.addUserToGroupNetworkFormData,
    };
    addUserToGroupNetworkFormData["userId"] = userId;
    addUserToGroupNetworkFormData["clientId"] = this.state.clientId;
    await this.setState({ addUserToGroupNetworkFormData });

    await axios
      .post(
        API_URL + "/admin/groups/removeGroupNetworkUser",
        this.state.addUserToGroupNetworkFormData,
      )
      .then((res) => {
        this.setState({ groupNetworkUsers: "" });
        this.getGroupNetworkUsers();
        this.messageNotification(
          "success",
          this.props.t("User removed from network successfully!"),
        );
        this.setState({ loading: false });

        var addUserToGroupNetworkFormData = {
          ...this.state.addUserToGroupNetworkFormData,
        };
        addUserToGroupNetworkFormData["clientId"] = "";
        this.setState({ addUserToGroupNetworkFormData });
      })
      .catch((err) => {
        alert(
          "There is a problem in removing user from network. Please try again!",
        );
        console.log(err);
        this.setState({ loading: false });
      });
  };

  getAvailableNetworks = async (currUserId) => {
    await axios
      .get(
        API_URL +
          "/admin/groups/getAvailableGroupNetworks?order=asc&orderBy=title&userId=" +
          currUserId,
      )
      .then((res) => {
        this.setState({ availableNetworks: res.data.data });
      })
      .catch((err) => {
        alert(
          "There is a problem in fetching available networks. Please try again!",
        );
        console.log(err);
        this.setState({ loading: false });
      });
  };

  getGroupsAndNetworksByUser = async (currUserId) => {
    await axios
      .get(
        API_URL +
          "/admin/groups/getGroupUsersAdvancedSettings?userId=" +
          currUserId,
      )
      .then((res) => {
        this.setState({ groupsAndNetworksByUser: res.data.data });
      })
      .catch((err) => {
        alert(
          "There is a problem in fetching Groups and Networks. Please try again!",
        );
        console.log(err);
        this.setState({ loading: false });
      });
  };

  AUSForGroupToggleChangeHandle = async (e, groupId) => {
    var advanceUserSettingsFormData = {
      ...this.state.advanceUserSettingsFormData,
    };
    var groupsData = {
      groupId: groupId,
      [e.target.name]: e.target.checked ? 1 : 0,
    };

    if (e.target.name === "roleGroupAdmin") {
      groupsData["roleGroupEditor"] = e.target.checked === false ? 1 : 0;
    }

    advanceUserSettingsFormData["groupsData"].push(groupsData);
    await this.setState({ advanceUserSettingsFormData });
    console.log(this.state.advanceUserSettingsFormData);
  };
  AUSForNetworkToggleChangeHandle = async (e, networkId) => {
    var advanceUserSettingsFormData = {
      ...this.state.advanceUserSettingsFormData,
    };
    var networksData = {
      networkId: networkId,
      [e.target.name]: e.target.checked ? 1 : 0,
    };

    if (e.target.name === "roleNetworkAdmin") {
      networksData["roleNetworkEditor"] = e.target.checked === false ? 1 : 0;
    }

    advanceUserSettingsFormData["networksData"].push(networksData);
    await this.setState({ advanceUserSettingsFormData });
    console.log(this.state.advanceUserSettingsFormData);
  };

  submitAUSFormHandler = async (e) => {
    const { userId } = this.state.addUserToGroupNetworkFormData;
    e.preventDefault();
    await this.setState({
      loading: true,
    });
    await axios
      .post(
        API_URL + "/admin/groups/updateUserAdvanceSettings/" + userId,
        this.state.advanceUserSettingsFormData,
      )
      .then((res) => {
        this.setState({
          groupsAndNetworksByUser: "",
          loading: false,
          showSuccessMsg: true,
        });
        this.getGroupNetworkUsers();
        this.getGroupsAndNetworksByUser(userId);
        this.getAvailableNetworks(userId);
        this.messageNotification(
          "success",
          "Groups and networks level roles are updated for this user successfully!",
        );

        var advanceUserSettingsFormData = {
          ...this.state.advanceUserSettingsFormData,
        };
        advanceUserSettingsFormData["groupsData"] = [];
        advanceUserSettingsFormData["networksData"] = [];
        this.setState({ advanceUserSettingsFormData });
      })
      .catch((err) => {
        alert(
          "There is a problem in updating groups and networks level roles for this user. Please try again!",
        );
        console.log(err);
        this.setState({ loading: false });
      });
  };

  roleToggleChangeHandle = async (e, userId) => {
    await this.setState({
      loading: true,
    });

    var roleData = {
      clientId: this.state.clientId,
      [e.target.name]: e.target.checked ? 1 : 0,
    };

    if (e.target.name === "roleNetworkAdmin") {
      roleData["roleNetworkEditor"] = e.target.checked === false ? 1 : 0;
    }

    await axios
      .post(
        API_URL + "/admin/groups/updateUserNetworkSettings/" + userId,
        roleData,
      )
      .then((res) => {
        this.setState({ groupNetworkUsers: "", loading: false });
        this.getGroupNetworkUsers();
        roleData = "";
      })
      .catch((err) => {
        alert("There is a problem in changing user role. Please try again!");
        console.log(err);
        roleData = "";
        this.setState({ loading: false });
      });
  };

  handlePageClick = async (data) => {
    let selected = data.selected + 1;
    await this.setState({
      pageNumber: selected,
    });
    this.getGroupNetworkUsers();
  };

  goBack = async () => {
    this.props.history.goBack();
  };

  fetchPendingInvites = async (e) => {
    if (this.state.clientName === "") {
      await this.getNetworkDetail();
    }
    await this.setState({
      loading: true,
    });

    const { clientId } = this.state;
    var urlToHit =
      "/admin/groups/getPendingInvitesForGroupNetwork?searchText=" +
      this.state.searchText +
      "&clientId=" +
      clientId;

    await axios
      .get(API_URL + urlToHit)
      .then((res) => {
        this.setState({
          pendingInvites: res.data.data,
          loading: false,
        });
      })
      .catch((err) => {
        alert("Failed. Please try again!");
        console.log(err);
      });
  };

  resendInvite = async (e, inviteEmail, inviteRole) => {
    this.setState({
      showInviteModal: true,
      inviteEmail: inviteEmail,
      inviteRole: inviteRole,
    });
  };

  render() {
    const { networks } = this.props;
    const {
      loading,
      groupNetworkUsers,
      showSuccessMsg,
      advanceUserSettingsModalContent,
      availableNetworks,
      groupsAndNetworksByUser,
      clientName,
      pageNumber,
      showInviteModal,
      pendingInvites,
    } = this.state;
    const { clientId, selectedNetwork } =
      this.state.addUserToGroupNetworkFormData;
    const { groupsData, networksData } = this.state.advanceUserSettingsFormData;
    const usersCount = groupNetworkUsers ? groupNetworkUsers.count : "";
    const noOfPages = Math.ceil(usersCount / USERS_PER_PAGE);
    const kTrans = this.props.t ? this.props.t : "";

    return (
      <section className={loading === true ? "loading" : ""}>
        {this.state.successMsg ? (
          <div className="sucessMsg">{this.state.successMsg}</div>
        ) : null}
        <div className="inline-content top title-section mb-2">
          <h2>{kTrans("Network Users")}</h2>
          <span
            onClick={() => this.setState({ showInviteModal: true })}
            className="btn btn-primary"
          >
            + {kTrans("Invite User(s)")}
          </span>
        </div>
        <h3 className="mb-4">
          <span className="lightColorSpan large">
            <NavLink
              className="d-inline-block"
              exact
              activeClassName="active"
              to={`/groups/${this.state.groupSlug}/networks`}
            >
              {kTrans("All Networks")}
            </NavLink>
            &nbsp;/&nbsp;
          </span>
          {clientName} {kTrans("Users")}
        </h3>
        <div className="mb-3">
          <form
            id="search"
            autoComplete="off"
            className="navbar-form navbar-left w-25"
            method="get"
            onSubmit={this.handleSearchSubmit}
          >
            <input
              onChange={this.handleQueryChange}
              type="text"
              className="form-control"
              value={this.state.searchText}
              name="searchText"
              placeholder={kTrans("Search")}
            />
            <button type="submit">
              <i className="fa fa-search"></i>
            </button>
          </form>
        </div>

        <Tab.Container id="left-tabs-example" defaultActiveKey="all-users">
          <Nav
            variant="pills"
            className="anchorLikeTabs d-flex align-item-center mt-4 mb-4"
          >
            <small>
              <strong>
                {groupNetworkUsers.count === 0 || groupNetworkUsers.count > 1
                  ? kTrans("Results")
                  : kTrans("Result")}
                : {groupNetworkUsers.count}
              </strong>
            </small>
            <Nav.Item className="ms-3">
              <Nav.Link eventKey="all-users">{kTrans("All Users")}</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="pending-invites">
                {kTrans("Pending Invites")}
              </Nav.Link>
            </Nav.Item>
          </Nav>
          <Tab.Content>
            <Tab.Pane eventKey="all-users">
              <div className="table-wrapper">
                <Table className="groupNetworkUsersTable tableCommon">
                  <thead>
                    <tr>
                      <th>{kTrans("Network Users")}</th>
                      <th>{kTrans("ADMIN")}</th>
                      <th>{kTrans("EDITOR")}</th>
                      <th>{kTrans("MODERATOR")}</th>
                      <th>{kTrans("ADVANCED SETTINGS")}</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {groupNetworkUsers.rows && groupNetworkUsers.rows.length ? (
                      groupNetworkUsers.rows.map((el, i) => (
                        <tr key={i}>
                          <td className="">
                            <strong>{el.name}</strong>
                            <span className="lightColorSpan">
                              @{el.username}
                            </span>
                          </td>
                          {
                            <td>
                              <Form.Group>
                                <Form.Check.Input
                                  name="roleNetworkAdmin"
                                  className="toggle-checkbox"
                                  defaultChecked={
                                    el.roles.length > 0 &&
                                    el.roles.find(
                                      (role) => role.name === "ADMIN",
                                    )
                                  }
                                  onChange={(e) =>
                                    this.roleToggleChangeHandle(e, el.id)
                                  }
                                />
                              </Form.Group>
                            </td>
                          }
                          {
                            <td>
                              <Form.Group>
                                <Form.Check.Input
                                  className={`toggle-checkbox ${el.roles.find((role) => role.name === "ADMIN") ? "disabled" : ""}`}
                                  name="roleNetworkEditor"
                                  defaultChecked={
                                    el.roles.length > 0 &&
                                    (el.roles.find(
                                      (role) => role.name === "EDITOR",
                                    ) ||
                                      el.roles.find(
                                        (role) => role.name === "ADMIN",
                                      ))
                                  }
                                  onChange={(e) =>
                                    this.roleToggleChangeHandle(e, el.id)
                                  }
                                />
                              </Form.Group>
                            </td>
                          }
                          {
                            <td>
                              <Form.Group>
                                <Form.Check.Input
                                  name="roleNetworkModerator"
                                  className={`toggle-checkbox ${el.roles.find((role) => role.name === "ADMIN") ? "disabled" : ""}`}
                                  defaultChecked={
                                    el.roles.length > 0 &&
                                    (el.roles.find(
                                      (role) => role.name === "MODERATOR",
                                    ) ||
                                      el.roles.find(
                                        (role) => role.name === "ADMIN",
                                      ))
                                  }
                                  onChange={(e) =>
                                    this.roleToggleChangeHandle(e, el.id)
                                  }
                                />
                              </Form.Group>
                            </td>
                          }
                          <td>
                            <FontAwesomeIcon
                              icon={faCog}
                              className="svg settings"
                              onClick={(e) =>
                                this.showAdvanceUserSettingsModal(e, i, el.id)
                              }
                            />
                          </td>
                          <td>
                            <span
                              className="customAnchor"
                              onClick={(e) => {
                                if (
                                  window.confirm(
                                    kTrans(
                                      "Are you sure you want to remove this user from network?",
                                    ),
                                  )
                                ) {
                                  this.removeUserFromGroupNetwork(e, el.id);
                                }
                              }}
                            >
                              {kTrans("Remove")}
                            </span>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="6">
                          <h3 className="text-center">
                            {kTrans("No User Found")}
                          </h3>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </div>
              {noOfPages > 1 ? (
                <ReactPaginate
                  className="pagination mt-4"
                  previousLabel="&laquo;"
                  nextLabel="&raquo;"
                  breakLabel="..."
                  breakClassName="break-me"
                  pageCount={noOfPages}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={5}
                  onPageChange={this.handlePageClick}
                  containerClassName="pagination"
                  activeClassName="active"
                  forcePage={pageNumber - 1}
                />
              ) : (
                ""
              )}
            </Tab.Pane>
            <Tab.Pane eventKey="pending-invites" className="table-wrapper">
              <div className="table-wrapper">
                <Table className="groupNetworkUsersTable tableCommon">
                  <thead>
                    <tr>
                      <th>{kTrans("EMAIL")}</th>
                      <th>{kTrans("ADMIN")}</th>
                      <th>{kTrans("EDITOR")}</th>
                      <th>{kTrans("MODERATOR")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {pendingInvites && pendingInvites.length ? (
                      pendingInvites.map((el, i) => (
                        <tr key={i}>
                          <td className="">
                            <strong>{el.email}</strong>
                            {el.invite_expired ? (
                              <>
                                <span className="lightColorSpan">
                                  {kTrans("Invite expired")} |{" "}
                                </span>
                                <span
                                  onClick={(e) =>
                                    this.resendInvite(e, el.email, el.invite_as)
                                  }
                                  className="customAnchor"
                                >
                                  {kTrans("Resend")}
                                </span>
                              </>
                            ) : null}
                          </td>
                          <td>
                            <Form.Group>
                              <Form.Check.Input
                                className="toggle-checkbox disabled"
                                defaultChecked={el.invite_as === "admin"}
                              />
                            </Form.Group>
                          </td>
                          <td>
                            <Form.Group>
                              <Form.Check.Input
                                className="toggle-checkbox disabled"
                                defaultChecked={el.invite_as === "editor"}
                              />
                            </Form.Group>
                          </td>
                          <td>
                            <Form.Group>
                              <Form.Check.Input
                                className="toggle-checkbox disabled"
                                defaultChecked={el.invite_as === "moderator"}
                              />
                            </Form.Group>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="6">
                          <h3 className="text-center">
                            {kTrans("No User Found")}
                          </h3>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </div>
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>

        <Modal
          className="advanceUserSettingsModal"
          size="lg"
          show={this.state.showAdvanceUserSettingsModal}
        >
          <Modal.Header>
            <Modal.Title>{kTrans("Advanced User Settings")}</Modal.Title>
            <span className="modal-close-btn">
              <b onClick={this.hideAdvanceUserSettingsModal}>&#x2715;</b>
            </span>
          </Modal.Header>
          <Modal.Body className={`mt-3 ${loading === true ? "loading" : ""}`}>
            <div className="modalContentWrapper ms-md-4 me-md-4">
              <div className="inline-content middle mb-4">
                <p className="userNameSection">
                  {advanceUserSettingsModalContent.name}
                  <small>@{advanceUserSettingsModalContent.username}</small>
                </p>
                <div className="rightSection">
                  <span
                    onClick={this.showAddUserToGroupNetworkModal}
                    className="btn btn-primary"
                  >
                    + {kTrans("Add to Network")}
                  </span>
                </div>
              </div>
              <Form
                className="updateGroupUserSettingsForm common-form pt-4"
                onSubmit={this.submitAUSFormHandler}
              >
                {groupsAndNetworksByUser.groups &&
                groupsAndNetworksByUser.groups.length ? (
                  <div className="table-wrapper">
                    <Table className="groupNetworkUsersTable tableCommon mb-4">
                      <thead>
                        <tr>
                          <th className="w-25">{kTrans("GROUP(S)")}</th>
                          <th>{kTrans("ADMIN")}</th>
                          <th>{kTrans("EDITOR")}</th>
                          <th>{kTrans("MODERATOR")}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {groupsAndNetworksByUser.groups.map((el, i) => (
                          <tr key={i}>
                            <td className="">{el.GroupName}</td>
                            <td>
                              <Form.Group>
                                <Form.Check.Input
                                  name="roleGroupAdmin"
                                  className="toggle-checkbox"
                                  defaultChecked={
                                    el.roles.length > 0 &&
                                    el.roles.find(
                                      (role) => role.name === "GROUPADMIN",
                                    )
                                  }
                                  onChange={(e) =>
                                    this.AUSForGroupToggleChangeHandle(
                                      e,
                                      el.GroupId,
                                    )
                                  }
                                />
                              </Form.Group>
                            </td>
                            <td>
                              <Form.Group>
                                <Form.Check.Input
                                  name="roleGroupEditor"
                                  className={`toggle-checkbox ${el.roles.find((role) => role.name === "GROUPADMIN") ? "disabled" : ""}`}
                                  defaultChecked={
                                    el.roles.length > 0 &&
                                    (el.roles.find(
                                      (role) => role.name === "EDITOR",
                                    ) ||
                                      el.roles.find(
                                        (role) => role.name === "GROUPADMIN",
                                      ))
                                  }
                                  onChange={(e) =>
                                    this.AUSForGroupToggleChangeHandle(
                                      e,
                                      el.GroupId,
                                    )
                                  }
                                />
                              </Form.Group>
                            </td>
                            <td>
                              <Form.Group>
                                <Form.Check.Input
                                  name="roleGroupModerator"
                                  className={`toggle-checkbox ${el.roles.find((role) => role.name === "GROUPADMIN") ? "disabled" : ""}`}
                                  defaultChecked={
                                    el.roles.length > 0 &&
                                    (el.roles.find(
                                      (role) => role.name === "MODERATOR",
                                    ) ||
                                      el.roles.find(
                                        (role) => role.name === "GROUPADMIN",
                                      ))
                                  }
                                  onChange={(e) =>
                                    this.AUSForGroupToggleChangeHandle(
                                      e,
                                      el.GroupId,
                                    )
                                  }
                                />
                              </Form.Group>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                ) : null}

                {groupsAndNetworksByUser.networks &&
                groupsAndNetworksByUser.networks.length ? (
                  <div className="table-wrapper">
                    <Table className="groupNetworkUsersTable tableCommon">
                      <thead>
                        <tr>
                          <th className="w-25">{kTrans("NETWORK(S)")}</th>
                          <th>{kTrans("ADMIN")}</th>
                          <th>{kTrans("EDITOR")}</th>
                          <th>{kTrans("MODERATOR")}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {groupsAndNetworksByUser.networks.map((el, i) => (
                          <tr key={i}>
                            <td className="">
                              {el.clientTitle}
                              {el.parentGroupName ? (
                                <span className="d-block text-break lightColorSpan">
                                  {kTrans("Group")}: {el.parentGroupName}
                                </span>
                              ) : null}
                            </td>
                            <td>
                              <Form.Group>
                                <Form.Check.Input
                                  name="roleNetworkAdmin"
                                  className="toggle-checkbox"
                                  defaultChecked={
                                    el.roles.length > 0 &&
                                    el.roles.find(
                                      (role) => role.name === "ADMIN",
                                    )
                                  }
                                  onChange={(e) =>
                                    this.AUSForNetworkToggleChangeHandle(
                                      e,
                                      el.clientId,
                                    )
                                  }
                                />
                              </Form.Group>
                            </td>
                            <td>
                              <Form.Group>
                                <Form.Check.Input
                                  className={`toggle-checkbox ${el.roles.find((role) => role.name === "ADMIN") ? "disabled" : ""}`}
                                  name="roleNetworkEditor"
                                  defaultChecked={
                                    el.roles.length > 0 &&
                                    (el.roles.find(
                                      (role) => role.name === "EDITOR",
                                    ) ||
                                      el.roles.find(
                                        (role) => role.name === "ADMIN",
                                      ))
                                  }
                                  onChange={(e) =>
                                    this.AUSForNetworkToggleChangeHandle(
                                      e,
                                      el.clientId,
                                    )
                                  }
                                />
                              </Form.Group>
                            </td>
                            <td>
                              <Form.Group>
                                <Form.Check.Input
                                  name="roleNetworkModerator"
                                  className={`toggle-checkbox ${el.roles.find((role) => role.name === "ADMIN") ? "disabled" : ""}`}
                                  defaultChecked={
                                    el.roles.length > 0 &&
                                    (el.roles.find(
                                      (role) => role.name === "MODERATOR",
                                    ) ||
                                      el.roles.find(
                                        (role) => role.name === "ADMIN",
                                      ))
                                  }
                                  onChange={(e) =>
                                    this.AUSForNetworkToggleChangeHandle(
                                      e,
                                      el.clientId,
                                    )
                                  }
                                />
                              </Form.Group>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                ) : null}
                <div className="buttons text-center">
                  <Button
                    className={`m-0`}
                    onClick={this.hideAdvanceUserSettingsModal}
                    variant="secondary"
                  >
                    {kTrans("Cancel")}
                  </Button>
                  <Button
                    className={`m-0 ms-2 ${groupsData.length > 0 || networksData.length > 0 ? "" : "disabled"}`}
                    type="submit"
                    variant="primary"
                  >
                    {kTrans("Save")}
                  </Button>
                </div>
              </Form>
            </div>
          </Modal.Body>
        </Modal>

        <Modal
          className="addGroupModal"
          size="lg"
          show={this.state.showAddUserToGroupNetworkModal}
        >
          <Modal.Body className={loading === true ? "loading" : ""}>
            <div className="modalContentWrapper">
              <img
                className="sidedFavicon"
                src={SIDED_FAVICON}
                alt="Sided Favicon"
              />
              <h5 className="txtCenter mb-3">
                {kTrans("Add User to Network")}
              </h5>
              <p className="txtCenter">Select any of the networks below:</p>
              <Form
                className="AddUserToGroupNetworkForm common-form"
                onSubmit={this.submitAddUserToGroupNetworkHandler}
              >
                {showSuccessMsg ? (
                  <div className="txtCenter pt-5 pb-5 mb-2">
                    <p>
                      <strong>{kTrans("Success")}!</strong>
                    </p>
                    <p>
                      {kTrans(
                        "You have just added the following user to network",
                      )}{" "}
                      {selectedNetwork}:
                    </p>
                    <h4 className="">{advanceUserSettingsModalContent.name}</h4>
                  </div>
                ) : (
                  <>
                    <Form.Group controlId="clientId" className="p-0">
                      <Form.Label>{kTrans("Available Networks")}</Form.Label>
                      {availableNetworks && availableNetworks.length > 0 ? (
                        <Form.Control
                          className="networkId"
                          as="select"
                          data-required="true"
                          onChange={this.networkFormChangeHandler}
                          name="clientId"
                          defaultValue={clientId}
                          htmlSize="5"
                        >
                          <option value="">{kTrans("Select a Network")}</option>
                          {availableNetworks.map((el, i) => (
                            <option key={el.id} value={el.id} data-id={el.id}>
                              {el.header.title}
                            </option>
                          ))}
                        </Form.Control>
                      ) : (
                        kTrans("No network available!")
                      )}
                    </Form.Group>
                  </>
                )}
                <div className="mt-5 pt-5 buttons text-center inline-content">
                  <Button
                    className={`m-0 ${showSuccessMsg ? "disabled" : ""}`}
                    onClick={this.hideAddUserToGroupNetworkModal}
                    variant="secondary"
                  >
                    {kTrans("Cancel")}
                  </Button>
                  {showSuccessMsg ? (
                    <span
                      className="m-0 btn btn-primary"
                      onClick={this.hideAddUserToGroupNetworkModal}
                    >
                      Done
                    </span>
                  ) : (
                    <Button
                      className={`m-0 ${clientId ? "" : "disabled"}`}
                      variant={clientId ? "primary" : "secondary"}
                      type="submit"
                    >
                      {kTrans("Add")}
                    </Button>
                  )}
                </div>
              </Form>
            </div>
          </Modal.Body>
        </Modal>

        {showInviteModal ? (
          <InviteModal
            networksList={networks}
            selNetworkId={this.state.clientId}
            inviteEmail={this.state.inviteEmail}
            inviteRole={this.state.inviteRole}
            onClose={() =>
              this.setState({
                showInviteModal: false,
                inviteEmail: "",
                inviteRole: "",
              })
            }
          />
        ) : null}
      </section>
    );
  }
}

const mapStateToProps = (state) => ({
  loggedInUser: state.login.user,
  networks: state.networks.networksList,
});

const Extended = withTranslation()(GroupNetworkUsers);
Extended.static = GroupNetworkUsers.static;

export default connect(mapStateToProps, {})(Extended);
