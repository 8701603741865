import { useState, useEffect } from "react";
import { useParams, useLocation, Redirect } from "react-router-dom";
import axios from "axios";
import Loader from "../Loader";
import Onboarding from "./Onboarding";
import { API_URL } from "../../constants/Globals";
import { readUserId } from "../../helpers/Session";

export default function JoinNetwork({ isLogin }) {
  const [error, setError] = useState("");
  const [recipient, setRecipient] = useState("");
  const [currentUser, setCurrentUser] = useState(null);
  const [currentNetwork, setCurrentNetwork] = useState(null);
  const [currentGroup, setCurrentGroup] = useState(null);
  const [isExistingUser, setIsExistingUser] = useState(false);
  const [isExpiredInvite, setIsExpiredInvite] = useState(false);
  const [invitationSender, setInvitationSender] = useState(null);
  const { token } = useParams();
  const { search } = useLocation();
  const isForGroup = search.indexOf("?group") !== -1;

  useEffect(() => {
    axios
      .get(
        API_URL +
          `/${isForGroup ? "admin/groups" : "user"}/checkInvite/${token}`,
      )
      .then(async (response) => {
        const data = response.data.data;

        if (!data) {
          setError(response.data.message);
        } else if (data.status && data.status === "EXPIRED") {
          setIsExpiredInvite(true);
          setCurrentNetwork(data.client);
          setInvitationSender(data.invitedByUser);
        } else if (isLogin) {
          const userId = await readUserId();
          axios
            .get(API_URL + "/user/" + userId)
            .then((rsp) => {
              setRecipient(data.email);
              setIsExistingUser(false);
              setCurrentUser(rsp.data.data);
              setInvitationSender(data.invitedByUser);
              setCurrentNetwork(data.client);
              setCurrentGroup(data.group);
            })
            .catch(({ response }) => {
              console.log("user response", response.data);
            });
        } else {
          setRecipient(data.email);
          setInvitationSender(data.invitedByUser);
          setIsExistingUser(
            response.data.message.indexOf("already exists") !== -1,
          );
          setCurrentNetwork(data.client);
          setCurrentGroup(data.group);
        }
      })
      .catch(({ response }) => {
        const { data } = response;

        if (data.message && data.message[0] && data.message[0].error) {
          setError(response.data.message[0].error);
        }
      });
  }, [token, isForGroup, isLogin]);

  if (error) {
    return <Redirect to="/" />;
  }

  return !currentNetwork && !currentGroup ? (
    <Loader />
  ) : (
    <Onboarding
      invitationSender={invitationSender}
      currentNetwork={currentNetwork}
      currentGroup={currentGroup}
      isInvitation={true}
      initialStep={
        isExpiredInvite
          ? "invite_expired"
          : isLogin
            ? !currentUser.contactVerified
              ? "code_verification"
              : "join_acceptance"
            : isExistingUser
              ? "login"
              : "account_info"
      }
      recipient={recipient}
      hideStepInfo={true}
      hideLoginAlt={true}
      token={token}
    />
  );
}
