import React, { useState, useEffect } from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import { connect } from "react-redux";
import Header from "./components/Header";
import Main from "./components/Main";
import Footer from "./components/Footer";
import Login from "./components/auth/Login";
import Onboarding from "./components/pages/Onboarding";
import JoinNetwork from "./components/pages/JoinNetwork";
import ForgotPassword from "./components/auth/ForgotPassword";
import Loader from "./components/Loader";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import "./App.sass";
import "font-awesome/css/font-awesome.min.css";
import "bootstrap-daterangepicker/daterangepicker.css";
import { store } from "./redux/store";
import { loadUser } from "./redux/action/login.action";
import {
  loadNetworksList,
  loadGroupsList,
} from "./redux/action/networks.action";
import axios from "axios";
import { loggedInTokenConfig, checkTokenConfig } from "./helpers/TokenConfig";
import GoToTop from "./components/GoToTop";
import { ADMIN_WEBSITE_URL, BASENAME, API_URL } from "./constants/Globals";

const App = (props) => {
  const [isLoadingComplete, setLoadingComplete] = useState(false);

  if (window.location.origin !== ADMIN_WEBSITE_URL) {
    window.location.href = ADMIN_WEBSITE_URL + BASENAME;
  }

  useEffect(() => {
    setInterval(() => {
      axios
        .get(`${API_URL}/userClients`)
        .then(() => {})
        .catch((error) => {
          if (error.response.status === 401) {
            window.location.reload();
          }
        });
    }, 60000);
  }, []);

  useEffect(() => {
    if (isLoadingComplete) {
      if (props.isLogin) {
        store.dispatch(loadNetworksList());
        store.dispatch(loadGroupsList());
      } else {
        store.dispatch(loadUser());
      }
    } else {
      loadResourcesAsync(setLoadingComplete);
    }
  }, [props.isLogin, props.isVerified, isLoadingComplete]);

  const { isLogin, isVerified, networks, user } = props;
  const { href } = window.location;

  if (!isLoadingComplete || props.isUserLoading) {
    return <Loader />;
  } else {
    return (
      <BrowserRouter>
        <Switch>
          {props.isLogin &&
          props.isVerified &&
          href.indexOf("/join/") === -1 &&
          href.indexOf("/onboarding") === -1 ? (
            <div className="App">
              <GoToTop />
              <Header />
              <Main />
              <Footer />
            </div>
          ) : (
            <div className="App">
              <div className="authContainer">
                <Route
                  exact
                  path="/onboarding"
                  render={(props) => (
                    <Onboarding
                      {...props}
                      isLogin={isLogin}
                      isVerified={isVerified}
                      networks={networks}
                      user={user}
                      refreshClients={() => store.dispatch(loadNetworksList())}
                    />
                  )}
                />
                <Route
                  exact
                  path="/network-users/join/:token"
                  render={(props) => (
                    <JoinNetwork {...props} isLogin={isLogin} />
                  )}
                />
                <Route
                  exact
                  path="/forgot-password"
                  component={ForgotPassword}
                />
                <Route path="/" component={Login} />
              </div>
            </div>
          )}
        </Switch>
      </BrowserRouter>
    );
  }
};

axios.interceptors.request.use(async (config) => {
  return await loggedInTokenConfig(config);
});

async function loadResourcesAsync(setLoadingComplete) {
  await checkTokenConfig();
  setLoadingComplete(true);
}

const mapStateToProps = (state) => ({
  isLogin: state.login.isLogin,
  isVerified: state.login.isVerified,
  isUserLoading: state.login.isLoading,
  isNetworksLoading: state.networks.isLoading,
  isDebatesLoading: state.debates.isLoading,
  isUsersLoading: state.networkUsers.isLoading,
  loginError: state.login.loginError,
  networks: state.networks.networksList,
  user: state.login.user,
  selLanguage: state.networks.selLanguage,
});

export default connect(mapStateToProps)(App);
