import axios from "axios";
import { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Form, Modal, Button } from "react-bootstrap";
import { API_URL } from "../constants/Globals";

const DEPLOY_MODAL_TAB_CATEGORY = "category";
const DEPLOY_MODAL_TAB_PLACEMENT = "placement";

function DeployModal({ onClose, selNetworkId }) {
  const [currentTab, setCurrentTab] = useState(DEPLOY_MODAL_TAB_CATEGORY);
  const [searchCategories, setSearchCategories] = useState("");
  const [availableCategories, setAvailableCategories] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [embedPlacements, setEmbedPlacements] = useState([]);
  const [deployPlacementIds, setDeployPlacementIds] = useState([]);
  const [replaceExisting, setReplaceExisting] = useState(true);

  const entityType = "collection";
  const entityPlural = "collections";
  const isSuperAdmin = true;
  const deployingToCategory = currentTab === DEPLOY_MODAL_TAB_CATEGORY;
  const deployingToPlacement =
    currentTab === DEPLOY_MODAL_TAB_PLACEMENT || !isSuperAdmin;
  const allCategoriesChecked =
    selectedCategories.length === 1 && selectedCategories[0] === "all";

  const embedPlacementError = "";
  const debateDeployType = "deployToAllPages";
  const deployPagesText = "";
  const deployPageUrls = [];
  const deployPageUrlsSelection = [];

  useEffect(() => {
    axios
      .get(`${API_URL}/categories?clientId=${selNetworkId}`)
      .then(({ data }) => {
        setAvailableCategories(data.data.map(({ text }) => text));
      });

    axios
      .get(
        `${API_URL}/admin/embedPlacement/getEmbedPlacements?clientId=${selNetworkId}`,
      )
      .then(({ data }) => {
        setEmbedPlacements(data.data);
      });
  }, [selNetworkId]);

  return (
    <Modal className="debDeployModal" size="lg" show={true}>
      <Modal.Body className="sided-modal-body">
        <div className="mb-3 d-flex justify-content-between">
          <h3 className="fw-bold">
            {deployingToPlacement ? "Select Placement" : "Publish to Category"}
          </h3>
          <h3 role="button" onClick={onClose}>
            <i className="fa fa-close"></i>
          </h3>
        </div>

        <p className="mb-3">
          {deployingToPlacement
            ? `Select the location where this ${entityType} will show`
            : `Choose the categories you would like to publish this ${entityType} to:`}
        </p>

        {isSuperAdmin ? (
          <div className="modal-tabs">
            <div
              className={`modal-tab ${deployingToCategory ? "active" : ""}`}
              onClick={() => setCurrentTab(DEPLOY_MODAL_TAB_CATEGORY)}
            >
              Category
            </div>
            <div
              className={`modal-tab ${deployingToPlacement ? "active" : ""}`}
              onClick={() => setCurrentTab(DEPLOY_MODAL_TAB_PLACEMENT)}
            >
              Placement
            </div>
          </div>
        ) : null}

        {deployingToPlacement ? (
          <>
            {debateDeployType !== "deployToAllPages" ? (
              <Form className="DeployDebateForm common-form">
                <div className="form-group">
                  <b className="pb-3 d-block">
                    Please confirm you want to publish this {entityType} to the
                    following {deployPagesText}?
                  </b>
                  <p className="depToPagesList">
                    {deployPageUrls.length === 1 ? (
                      <span className="d-block">{deployPageUrls}</span>
                    ) : (
                      deployPageUrlsSelection.map((url, index) => (
                        <span className="d-block" key={index}>
                          {url}
                        </span>
                      ))
                    )}
                  </p>
                </div>
              </Form>
            ) : null}

            <div className="network-list">
              <div className="network-list-row">
                <label>
                  <input
                    type="checkbox"
                    className="sided-checkbox"
                    checked={
                      deployPlacementIds.length === embedPlacements.length
                    }
                    onChange={() =>
                      setDeployPlacementIds(
                        deployPlacementIds.length !== embedPlacements.length
                          ? embedPlacements.map(({ id }) => id)
                          : [],
                      )
                    }
                  />
                  Available placements (
                  {embedPlacements ? embedPlacements.length : 0})
                </label>
                {isSuperAdmin ? (
                  <label>
                    <input
                      type="checkbox"
                      className="sided-checkbox"
                      checked={replaceExisting}
                      onChange={() => setReplaceExisting(!replaceExisting)}
                    />
                    Replace existing {entityPlural}
                  </label>
                ) : null}
              </div>
              {(embedPlacements || []).map((embedPlacement) => (
                <div key={embedPlacement.id} className="network-list-row">
                  <label className="network-list-item">
                    <input
                      type="checkbox"
                      className="sided-checkbox"
                      checked={
                        deployPlacementIds &&
                        deployPlacementIds.indexOf(embedPlacement.id) !== -1
                      }
                      onChange={() => {
                        const newSelection = [].concat(deployPlacementIds);

                        if (newSelection.indexOf(embedPlacement.id) === -1) {
                          newSelection.push(embedPlacement.id);
                        } else {
                          newSelection.splice(
                            newSelection.indexOf(embedPlacement.id),
                            1,
                          );
                        }

                        setDeployPlacementIds(newSelection);
                      }}
                    />
                    {embedPlacement.placementName}
                  </label>
                </div>
              ))}
            </div>

            {embedPlacementError ? (
              <div className="validation-error">{embedPlacementError}</div>
            ) : null}
          </>
        ) : (
          <>
            <form className="sided-modal-search">
              <input
                type="text"
                value={searchCategories}
                placeholder="Search categories"
                onChange={({ target }) => setSearchCategories(target.value)}
              />
            </form>

            <div className="network-list">
              <div className="network-list-row">
                <label>
                  <input
                    type="checkbox"
                    className="sided-checkbox"
                    checked={allCategoriesChecked}
                    onChange={() =>
                      setSelectedCategories(
                        selectedCategories.indexOf("all") !== -1 ? [] : ["all"],
                      )
                    }
                  />
                  Available categories ({availableCategories.length})
                </label>
              </div>
              {availableCategories
                .filter(
                  (tag) =>
                    searchCategories === "" ||
                    tag
                      .toLowerCase()
                      .indexOf(searchCategories.toLowerCase()) !== -1,
                )
                .map((category) => (
                  <div key={category} className="network-list-row">
                    <label className="network-list-item">
                      <input
                        type="checkbox"
                        className="sided-checkbox"
                        checked={
                          allCategoriesChecked ||
                          selectedCategories.indexOf(category) !== -1
                        }
                        onChange={() => {
                          const newSelection = [].concat(selectedCategories);
                          const currentIndex = newSelection.indexOf(category);
                          const allIndex = newSelection.indexOf("all");

                          if (allIndex !== -1) {
                            newSelection.splice(allIndex, 1);
                          }

                          if (currentIndex !== -1) {
                            newSelection.splice(currentIndex, 1);
                          } else {
                            newSelection.push(category);
                          }

                          setSelectedCategories(newSelection);
                        }}
                      />
                      {category}
                    </label>
                  </div>
                ))}
            </div>
          </>
        )}

        <div className="text-center">
          <Button onClick={onClose} variant="secondary" className="mx-1">
            {deployingToPlacement ? "Back" : "Cancel"}
          </Button>
          {deployingToPlacement ? (
            <Button
              onClick={() => {
                axios.post(
                  `/admin/embedPlacement/deployCollection?clientId=${selNetworkId}`,
                  {},
                );
              }}
              variant="primary"
              className="mx-1"
            >
              Publish {entityType}
            </Button>
          ) : (
            <Button
              onClick={() => setCurrentTab(DEPLOY_MODAL_TAB_PLACEMENT)}
              variant="primary"
              className="mx-1"
            >
              Select Placement
            </Button>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
}

const mapStateToProps = ({ networks }) => ({
  selNetworkId: networks.selNetworkId,
  networksList: networks.networksList,
});

export default connect(mapStateToProps)(DeployModal);
