import { useState } from "react";
import axios from "axios";
import { Modal, Button } from "react-bootstrap";
import { API_URL } from "../constants/Globals";
import { useTranslation } from "react-i18next";

const NetworkBox = ({ network }) => (
  <div className="network-box">
    <div className="network-box-logo">
      <img src={network.header.favicon} alt="Network logo" />
    </div>
    <div className="network-box-text">
      {network.header.title}
      <br />
      <small>{network.siteDomain}</small>
    </div>
  </div>
);

export default function InviteModal({
  networksList = [],
  selNetworkId = null,
  onClose = null,
  isForGroup = false,
  groupName = "",
  groupId = 0,
  inviteEmail = "",
  inviteRole = "admin",
}) {
  const [loading, setLoading] = useState(false);
  const [inviteForm, setInviteForm] = useState({
    to: inviteEmail,
    role: inviteRole === "" ? "admin" : inviteRole,
  });
  const [inviteModalStep, setInviteModalStep] = useState(1);
  const { t } = useTranslation();
  const kTrans = t ? t : "";
  return (
    <Modal className="authorModal" size="lg" show={true}>
      <Modal.Header>
        <Modal.Title>{kTrans("Invite people to Sided")}</Modal.Title>

        <div className="modal-header-x" onClick={onClose}>
          <svg
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M14 1.41L12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41Z"
              fill="#BDBDBD"
            />
          </svg>
        </div>
      </Modal.Header>
      <Modal.Body className="sided-modal-body">
        {inviteModalStep === 1 ? (
          <>
            <div className="default-formfield">
              {isForGroup ? (
                <>
                  <label>{kTrans("Invite people to join this group")}:</label>
                  <div className="network-box">
                    <div className="network-box-text">{groupName}</div>
                  </div>
                </>
              ) : (
                <>
                  <label>{kTrans("Invite people to join")}:</label>
                  {(networksList || [])
                    .filter(
                      (network) =>
                        parseInt(network.id) === parseInt(selNetworkId),
                    )
                    .map((network) => (
                      <NetworkBox key={network.id} network={network} />
                    ))}
                </>
              )}
            </div>

            <div className="default-formfield">
              <label>{kTrans("To")}:</label>
              <div>
                <em>
                  <small>
                    <small>
                      ({kTrans("please enter comma-separated values")})
                    </small>
                  </small>
                </em>
              </div>
              <textarea
                value={inviteForm.to}
                onChange={({ target }) => {
                  setInviteForm({
                    ...inviteForm,
                    to: target.value,
                  });
                }}
              ></textarea>
            </div>

            <div className="default-formfield">
              <label>{kTrans("Invite as")}</label>
              <select
                value={inviteForm.role}
                onChange={({ target }) => {
                  setInviteForm({
                    ...inviteForm,
                    role: target.value,
                  });
                }}
              >
                {isForGroup ? (
                  <>
                    <option value="admin">{kTrans("Group Admin")}</option>
                    <option value="editor">{kTrans("Group Editor")}</option>
                    <option value="moderator">
                      {kTrans("Group Moderator")}
                    </option>
                  </>
                ) : (
                  <>
                    <option value="admin">{kTrans("Admin")}</option>
                    <option value="editor">{kTrans("Editor")}</option>
                    <option value="moderator">{kTrans("Moderator")}</option>
                  </>
                )}
              </select>
            </div>

            <div className="sided-modal-footer">
              {/*
						<span className="text-button" onClick={() => {
							copyToClipboard(window.location.href)
							this.messageNotification('success' , 'The invite link was successfully copied to your clipboard')
						}}>
							<svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M7.8325 10.6525C8.2425 11.0425 8.2425 11.6825 7.8325 12.0725C7.4425 12.4625 6.8025 12.4625 6.4125 12.0725C4.4625 10.1225 4.4625 6.9525 6.4125 5.0025L9.9525 1.4625C11.9025 -0.4875 15.0725 -0.4875 17.0225 1.4625C18.9725 3.4125 18.9725 6.5825 17.0225 8.5325L15.5325 10.0225C15.5425 9.2025 15.4125 8.3825 15.1325 7.6025L15.6025 7.1225C16.7825 5.9525 16.7825 4.0525 15.6025 2.8825C14.4325 1.7025 12.5325 1.7025 11.3625 2.8825L7.8325 6.4125C6.6525 7.5825 6.6525 9.4825 7.8325 10.6525ZM10.6525 6.4125C11.0425 6.0225 11.6825 6.0225 12.0725 6.4125C14.0225 8.3625 14.0225 11.5325 12.0725 13.4825L8.5325 17.0225C6.5825 18.9725 3.4125 18.9725 1.4625 17.0225C-0.4875 15.0725 -0.4875 11.9025 1.4625 9.9525L2.9525 8.4625C2.9425 9.2825 3.0725 10.1025 3.3525 10.8925L2.8825 11.3625C1.7025 12.5325 1.7025 14.4325 2.8825 15.6025C4.0525 16.7825 5.9525 16.7825 7.1225 15.6025L10.6525 12.0725C11.8325 10.9025 11.8325 9.0025 10.6525 7.8325C10.2425 7.4425 10.2425 6.8025 10.6525 6.4125Z" fill="#2F80ED"/>
							</svg>
							Copy invite link
						</span>
						*/}
              <div>&nbsp;</div>

              {loading ? (
                <span>
                  <em>{kTrans("Please wait...")}</em>
                </span>
              ) : (
                <Button
                  variant="primary"
                  onClick={async () => {
                    const emails = inviteForm.to.replace(/ /g, "").split(",");

                    let hasError = false;

                    emails.forEach((email) => {
                      if (/\S+@\S+\.\S+/.test(email) === false) {
                        hasError = true;
                      }
                    });

                    if (hasError) {
                      var alertMsg = kTrans(
                        "Please make sure you enter valid comma-separated email addresses",
                      );
                      alert(alertMsg);
                    } else {
                      setLoading(true);

                      for (let i in emails) {
                        try {
                          if (isForGroup) {
                            await axios.post(
                              API_URL + "/admin/groups/inviteUser",
                              {
                                groupId: groupId,
                                email: emails[i],
                                inviteAs: inviteForm.role,
                              },
                            );
                          } else {
                            await axios.post(
                              API_URL + "/admin/user/inviteUser",
                              {
                                clientId: selNetworkId,
                                email: emails[i],
                                inviteAs: inviteForm.role,
                              },
                            );
                          }

                          setLoading(false);
                          setInviteModalStep(2);
                        } catch ({ response }) {
                          const { data } = response;
                          const { message } = data;

                          let alertMessage = JSON.stringify(message);

                          if (message[0]) {
                            if (message[0].error) {
                              alertMessage = message[0].error;
                            } else if (message[0].inviteAs) {
                              alertMessage = message[0].inviteAs;
                            }
                          }

                          alert(alertMessage);
                          setLoading(false);
                        }
                      }
                    }
                  }}
                >
                  {kTrans("Send")}
                </Button>
              )}
            </div>
          </>
        ) : null}

        {inviteModalStep === 2 ? (
          <>
            <div className="output-box">
              <h3>
                <svg
                  width="17"
                  height="17"
                  viewBox="0 0 17 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8.5 0C3.825 0 0 3.825 0 8.5C0 13.175 3.825 17 8.5 17C13.175 17 17 13.175 17 8.5C17 3.825 13.175 0 8.5 0ZM8.5 15.3C4.7515 15.3 1.7 12.2485 1.7 8.5C1.7 4.7515 4.7515 1.7 8.5 1.7C12.2485 1.7 15.3 4.7515 15.3 8.5C15.3 12.2485 12.2485 15.3 8.5 15.3ZM12.4015 4.743L6.8 10.3445L4.5985 8.1515L3.4 9.35L6.8 12.75L13.6 5.95L12.4015 4.743Z"
                    fill="#2AA974"
                  />
                </svg>
                {kTrans("Congratulations!")}
              </h3>
              {kTrans(
                "Your invites have successfully been sent for the following",
              )}{" "}
              {isForGroup ? "group" : "domain"}:
            </div>

            {isForGroup ? (
              <div className="network-box">
                <div className="network-box-text">{groupName}</div>
              </div>
            ) : (
              <>
                {(networksList || [])
                  .filter(
                    (network) =>
                      parseInt(network.id) === parseInt(selNetworkId),
                  )
                  .map((network) => (
                    <NetworkBox key={network.id} network={network} />
                  ))}
              </>
            )}

            <div>
              <strong>{kTrans("What's next?")}</strong>
              <br />
              {kTrans(
                "Once your recipients accept their invites, they’ll have access to your",
              )}{" "}
              {isForGroup ? kTrans("group") : kTrans("network")}.{" "}
              {kTrans("You can check their status on the")}{" "}
              {isForGroup ? kTrans("Group") : kTrans("Network")} Users list.
            </div>

            <div className="sided-modal-footer">
              <span
                className="text-button"
                onClick={() => setInviteModalStep(1)}
              >
                {kTrans("Add more people to this")}{" "}
                {isForGroup ? kTrans("group") : kTrans("domain")}
              </span>
            </div>
          </>
        ) : null}
      </Modal.Body>
    </Modal>
  );
}
