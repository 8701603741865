import React from "react";
import { connect } from "react-redux";
import { Table, Modal, Form, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faCog,
  faUserCheck,
} from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import {
  API_URL,
  USERS_PER_PAGE,
  DEFAULT_USER_IMG_URL,
  MAIN_WEBSITE_URL,
} from "../../constants/Globals";
import ReactPaginate from "react-paginate";
import { loadNetworkUsers } from "../../redux/action/networkUsers.action";
import SearchForm from "../SearchForm";
import InviteModal from "../../components/InviteModal";
import { withTranslation } from "react-i18next";

var moment = require("moment-timezone");
var momentDurationFormatSetup = require("moment-duration-format");
momentDurationFormatSetup(moment);

class NetworkUsers extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      modal: false,
      isChecked: false,
      users: [],
      editUserModalContent: null,
      modalSubmitting: false,
      userId: null,
      searchText: "",
      filter: "ROLEADMIN",
      pageNumber: 1,
      usersPerPage: USERS_PER_PAGE,
      invitesCount: 0,
      addNetworkFieldHidden: true,
      addNetworkField: null,
      userNetworksList: null,
      searchResult: null,
      joiningRemoving: false,
      noNetworkFound: false,
      createUserFormData: {
        clientId: "",
        name: "",
        username: "",
        email: "",
        phone: "",
        password: "",
      },
      validationErrors: "",
      showInviteModal: false,
    };
  }

  showEditUserModal = async (userId) => {
    await this.setState({
      modal: true,
      editUserModalContent: this.props.networkUsers.rows[userId],
    });

    userId = this.props.networkUsers.rows[userId].id;

    await this.setState({
      userId: userId,
    });

    this.usersNetworkLIst();
  };

  showCreateUserModal = async () => {
    await this.setState({
      modal: true,
    });

    this.usersNetworkLIst();
  };

  usersNetworkLIst = async () => {
    await axios
      .get(API_URL + "/admin/user/userClients?userId=" + this.state.userId)
      .then((res) => {
        this.setState({
          userNetworksList: res.data.data,
        });
      })
      .catch((err) => {
        console.log(err.response.data.message);
      });
  };

  hideEditUserModal = async (e) => {
    await this.setState({
      modal: false,
      userId: null,
      searchResult: null,
      addNetworkFieldHidden: true,
      addNetworkField: null,
      editUserModalContent: null,
      validationErrors: "",
    });

    var createUserFormData = { ...this.state.createUserFormData };
    createUserFormData["name"] = "";
    createUserFormData["username"] = "";
    createUserFormData["email"] = "";
    createUserFormData["phone"] = "";
    createUserFormData["password"] = "";
    await this.setState({ createUserFormData });
  };

  handlePageClick = async (data) => {
    let selected = data.selected + 1;
    await this.setState({ pageNumber: selected });

    if (this.state.filter === "PENDING_INVITES") {
      this.fetchInvites();
    } else {
      this.fetchUsers();
    }
  };

  submitAuthorSettingsHandle = async (e) => {
    e.preventDefault();
    var modalFormData = {
      clientId: parseInt(this.props.selNetworkId),
      roleVerified:
        e.target.roleVerified !== undefined && e.target.roleVerified.checked
          ? 1
          : 0,
      leaderboardExcluded:
        e.target.leaderboardExcluded !== undefined &&
        e.target.leaderboardExcluded.checked
          ? 1
          : 0,
      suspended:
        e.target.suspended !== undefined && e.target.suspended.checked ? 1 : 0,
      suggestionExcluded:
        e.target.suggestionExcluded !== undefined &&
        e.target.suggestionExcluded.checked
          ? 1
          : 0,
      embedAds:
        e.target.embedAds !== undefined && e.target.embedAds.checked ? 1 : 0,
      embedPoll:
        e.target.embedPoll !== undefined && e.target.embedPoll.checked ? 1 : 0,
      brandAccount:
        e.target.brandAccount !== undefined && e.target.brandAccount.checked
          ? 1
          : 0,
    };

    if (e.target.roleContributor !== undefined) {
      modalFormData["roleContributor"] = e.target.roleContributor.checked
        ? 1
        : 0;
    }
    if (e.target.roleAdmin !== undefined) {
      modalFormData["roleAdmin"] = e.target.roleAdmin.checked ? 1 : 0;
    }
    if (e.target.roleSuperadmin !== undefined) {
      modalFormData["roleSuperadmin"] = e.target.roleSuperadmin.checked ? 1 : 0;
    }

    const userid = e.target.userid.value;
    this.setState({
      modalSubmitting: true,
    });

    axios
      .post(API_URL + "/admin/user/update/" + userid, modalFormData)
      .then((res) => {
        console.log(res.data);
        this.setState({
          modalSubmitting: false,
        });
        this.messageNotification(
          "success",
          this.props.t("User updated successfully!"),
        );
        this.fetchUsers();
        this.hideEditUserModal();
      })
      .catch((err) => {});
  };

  handleSearchSubmit = async (e, item) => {
    await this.setState({
      searchText: item,
      pageNumber: 1,
    });
    this.fetchUsers();
    e.preventDefault();
  };

  handleFilter = async (e) => {
    e.preventDefault();
    const filter = e.target.value;

    await this.setState({
      filter,
      pageNumber: 1,
    });

    if (filter === "PENDING_INVITES") {
      this.fetchInvites();
    } else {
      this.fetchUsers();
    }
  };

  fetchInvites() {
    const { selNetworkId } = this.props;
    const { pageNumber, usersPerPage } = this.state;

    axios
      .get(
        API_URL +
          `/admin/user/getInvitedUsers?clientId=${selNetworkId}&pageNumber=${pageNumber}&perPage=${usersPerPage}`,
      )
      .then(({ data }) => {
        this.setState({
          users: data.data.rows,
          invitesCount: data.data.count,
        });
      })
      .catch(({ response }) => {
        if (response && response.data && response.data.status === "error") {
          alert(Object.values(response.data.message[0]));
        }
      });
  }

  fetchUsers = async (e) => {
    await this.setState({ loading: true });

    var createUserFormData = { ...this.state.createUserFormData };
    createUserFormData["clientId"] = this.props.selNetworkId;
    await this.setState({ createUserFormData });

    if (
      parseInt(this.props.selNetworkId) === 0 &&
      this.state.filter === "ROLEADMIN"
    ) {
      await this.setState({ filter: "" });
    }

    await this.props.loadNetworkUsers(
      this.props.selNetworkId,
      this.state.pageNumber,
      this.state.searchText,
      this.state.filter,
    );
    this.setState({ loading: false });
  };

  toggleChange = () => {
    this.setState({
      isChecked: !this.state.isChecked,
    });
  };

  componentDidMount = () => {
    this.fetchUsers();
    document.addEventListener("keydown", this.escFunction, false);
  };
  escFunction = (event) => {
    if (event.keyCode === 27) {
      if (this.state.modal === true) {
        this.hideEditUserModal();
      }
      console.log("Esc Clicked");
    }
  };

  componentDidUpdate = async (prevProps, prevState) => {
    if (prevProps.selNetworkId !== this.props.selNetworkId) {
      const { filter } = this.state;

      await this.setState({ pageNumber: 1 });

      if (filter === "PENDING_INVITES") {
        this.fetchInvites();
      } else {
        this.fetchUsers();
      }
    }
  };

  deleteUser = (userId) => {
    this.setState({
      modalSubmitting: true,
    });
    axios
      .post(API_URL + "/user/deleteAccount/", { userId: userId })
      .then((res) => {
        console.log(res.data);
        this.setState({
          modalSubmitting: false,
        });
        this.messageNotification(
          "success",
          this.props.t("User deleted successfully!"),
        );
        this.fetchUsers();
        this.hideEditUserModal();
      })
      .catch((err) => {
        this.messageNotification(
          "fail",
          this.props.t(
            "An error occured while deleted user. Please try again later",
          ),
        );
      });
  };

  messageNotification = async (type, message) => {
    this.setState({
      successMsg: message,
      msgType: type,
    });

    setTimeout(() => {
      this.setState({
        successMsg: "",
        msgType: "",
      });
    }, 2500);
  };

  addNewNetworkHandle = async (e) => {
    this.setState({
      addNetworkFieldHidden: this.state.addNetworkFieldHidden ? false : true,
      addNetworkField: this.state.addNetworkFieldHidden
        ? null
        : this.state.addNetworkField,
      searchResult: null,
    });
  };

  addNetworkFieldChange = async (e) => {
    await this.setState({
      [e.target.name]: e.target.value,
    });

    if (this.state.addNetworkField.length > 2) {
      this.searchAllClientsByUserId();
    } else {
      this.setState({
        searchResult: null,
      });
    }
  };

  searchAllClientsByUserId = async () => {
    await axios
      .get(
        API_URL +
          "/admin/user/getAllClients?userId=" +
          this.state.userId +
          "&searchText=" +
          this.state.addNetworkField,
      )
      .then((res) => {
        this.setState({
          searchResult: res.data.data,
        });

        this.setState({
          noNetworkFound: res.data.data.length < 1 ? true : false,
        });
      })
      .catch((err) => {
        console.log(err.response.data.message);
      });
  };

  joinRemoveNetwork = async (e, clientId) => {
    const formData = {
      clientId: clientId,
      userId: parseInt(this.state.userId),
    };

    this.setState({
      joiningRemoving: true,
    });

    await axios
      .post(API_URL + "/admin/user/userClients/add", formData)
      .then((res) => {
        var responseMsg = res.data.message;
        this.messageNotification("success", responseMsg);
        this.searchAllClientsByUserId();
        this.usersNetworkLIst();
        this.setState({
          joiningRemoving: false,
        });
      })
      .catch((err) => {
        var responseMsg = err.response.data.message[0].error;
        this.messageNotification("fail", responseMsg);
        this.setState({
          joiningRemoving: false,
        });
      });
  };

  changeHandler = async (e) => {
    var createUserFormData = { ...this.state.createUserFormData };
    createUserFormData[e.target.name] = e.target.value;
    await this.setState({ createUserFormData });
    this.formValidation(e);
  };

  submitCreateUserHandle = async (e) => {
    e.preventDefault();
    this.formValidation(e, "onSubmit");
  };

  formValidation = async (e = "", action = "") => {
    var validationErrors = { ...this.state.validationErrors };
    if (e.target.name === "email" || action === "onSubmit") {
      const regex = /\S+@\S+\.\S+/;
      if (this.state.createUserFormData.email === "") {
        validationErrors["email"] = this.props.t("This field is required.");
        await this.setState({ validationErrors: validationErrors });
      } else if (regex.test(this.state.createUserFormData.email) === false) {
        validationErrors["email"] = this.props.t(
          "Please enter a valid email address.",
        );
        await this.setState({ validationErrors: validationErrors });
      } else {
        delete validationErrors["email"];
        await this.setState({ validationErrors: validationErrors });
      }
    }
    if (e.target.name === "phone" || action === "onSubmit") {
      const regex = /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im;
      if (
        this.state.createUserFormData.phone.length > 0 &&
        regex.test(this.state.createUserFormData.phone) === false
      ) {
        validationErrors["phone"] = this.props.t(
          "Please enter valid Phone Number.",
        );
        await this.setState({ validationErrors: validationErrors });
      } else {
        delete validationErrors["phone"];
        await this.setState({ validationErrors: validationErrors });
      }
    }
    if (e.target.name === "name" || action === "onSubmit") {
      if (this.state.createUserFormData.name === "") {
        validationErrors["name"] = this.props.t("This field is required.");
        await this.setState({ validationErrors: validationErrors });
      } else if (this.state.createUserFormData.name.trim().length > 50) {
        validationErrors["name"] = this.props.t(
          "Name must be max 50 characters.",
        );
        await this.setState({ validationErrors: validationErrors });
      } else {
        delete validationErrors["name"];
        await this.setState({ validationErrors: validationErrors });
      }
    }
    if (e.target.name === "username" || action === "onSubmit") {
      const regex = /^[a-zA-Z0-9_]+$/;
      if (this.state.createUserFormData.username === "") {
        validationErrors["username"] = this.props.t("This field is required.");
        await this.setState({ validationErrors: validationErrors });
      } else if (regex.test(this.state.createUserFormData.username) === false) {
        validationErrors["username"] = this.props.t(
          "Use only letter, number and _.",
        );
        await this.setState({ validationErrors: validationErrors });
      } else if (
        this.state.createUserFormData.username.length < 3 ||
        this.state.createUserFormData.username.length > 20
      ) {
        validationErrors["username"] = this.props.t(
          "Username must be between 3 to 20 characters.",
        );
        await this.setState({ validationErrors: validationErrors });
      } else {
        delete validationErrors["username"];
        await this.setState({ validationErrors: validationErrors });
      }
    }
    if (e.target.name === "password" || action === "onSubmit") {
      if (this.state.createUserFormData.password === "") {
        validationErrors["password"] = this.props.t("This field is required.");
        await this.setState({ validationErrors: validationErrors });
      } else if (this.state.createUserFormData.password.length < 6) {
        validationErrors["password"] = this.props.t(
          "Password must be at least 6 characters long.",
        );
        await this.setState({ validationErrors: validationErrors });
      } else {
        delete validationErrors["password"];
        await this.setState({ validationErrors: validationErrors });
      }
    }
    if (
      Object.keys(this.state.validationErrors).length === 0 &&
      action === "onSubmit"
    ) {
      this.submitCreateUserFormData();
    }
  };

  submitCreateUserFormData = async (e) => {
    await this.setState({
      modalSubmitting: false,
    });

    axios
      .post(API_URL + "/admin/user/create/", this.state.createUserFormData)
      .then((res) => {
        console.log(res.data);
        this.setState({
          modalSubmitting: false,
        });
        this.messageNotification(
          "success",
          this.props.t("User updated successfully!"),
        );
        this.fetchUsers();
        this.hideEditUserModal();
      })
      .catch((err) => {
        var errorsArr = err.response.data.message;
        var validationErrors = { ...this.state.validationErrors };
        for (let i = 0; i < errorsArr.length; i++) {
          if (errorsArr[i].username !== undefined) {
            validationErrors["username"] = this.props.t(
              "The username has already been taken.",
            );
            this.setState({ validationErrors: validationErrors });
          }
          if (errorsArr[i].email !== undefined) {
            validationErrors["email"] = this.props.t(
              "The email has already been taken.",
            );
            this.setState({ validationErrors: validationErrors });
          }
        }
      });
  };

  generatePassword = async (e) => {
    var length = 16,
      charset =
        "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
      retVal = "";
    for (var i = 0, n = charset.length; i < length; ++i) {
      retVal += charset.charAt(Math.floor(Math.random() * n));
    }
    var pwd = retVal;
    var createUserFormData = { ...this.state.createUserFormData };
    createUserFormData["password"] = pwd;
    this.setState({ createUserFormData });
  };

  copyPassword = async (e) => {
    var passField = document.getElementById("password");
    if (passField.value !== "") {
      passField.select();
      await document.execCommand("copy");
      this.messageNotification(
        "success",
        this.props.t("Password copied to clipboard!"),
      );
    } else {
      this.messageNotification(
        "fail",
        this.props.t("The password field is empty."),
      );
    }
  };

  render() {
    const {
      editUserModalContent,
      usersPerPage,
      pageNumber,
      loading,
      addNetworkFieldHidden,
      addNetworkField,
      userNetworksList,
      searchResult,
      joiningRemoving,
      validationErrors,
      showInviteModal,
      invitesCount,
      filter,
    } = this.state;
    const isPendingInvites = filter === "PENDING_INVITES";
    const { networkUsers, networksList } = this.props;
    const users = isPendingInvites
      ? this.state.users
      : networkUsers
        ? networkUsers.rows
        : [];
    const usersCount = isPendingInvites
      ? invitesCount
      : networkUsers
        ? networkUsers.count
        : "";
    const noOfPages = Math.ceil(usersCount / usersPerPage);
    const selNetworkId = parseInt(this.props.selNetworkId);
    const loggedInUser = this.props.loggedInUser;
    const { name, username, email, phone, password } =
      this.state.createUserFormData;
    const kTrans = this.props.t ? this.props.t : "";

    return (
      <section>
        {this.state.successMsg ? (
          <div className={this.state.msgType}>{this.state.successMsg}</div>
        ) : null}
        <div className="inline-content middle title-section">
          <h2>
            {kTrans("Network Users")}
            <small>
              {usersCount} {kTrans("Users")}
            </small>
          </h2>
          <SearchForm handleSearchSubmit={this.handleSearchSubmit} />
          <Button
            className="mt-3 m-md-0"
            variant="primary"
            onClick={() => this.showCreateUserModal()}
          >
            {kTrans("ADD USER")}
          </Button>
          <Button
            className="ms-3 mt-3 m-md-0"
            variant="primary"
            onClick={() => this.setState({ showInviteModal: true })}
          >
            {kTrans("INVITE USER")}
          </Button>
        </div>
        <div className="pull-right-lg mb-3">
          <span className="me-2">{kTrans("Filter users by role")}: </span>
          <select
            id="role-filter"
            defaultValue={this.state.filter}
            onChange={this.handleFilter}
          >
            <option value="">{kTrans("All")}</option>
            <option value="ROLEVERIFIED">{kTrans("Verified")}</option>
            {parseInt(this.props.selNetworkId) !== 0 ? (
              <option value="ROLEADMIN">{kTrans("Admin")}</option>
            ) : null}
            <option value="ROLESUPERADMIN">{kTrans("Superadmin")}</option>
            <option value="PENDING_INVITES">{kTrans("Pending invites")}</option>
          </select>
        </div>
        <div className="table-wrapper">
          <Table
            className={`network-users tableCommon ${loading === true ? "loading" : null}`}
          >
            <thead>
              <tr>
                {isPendingInvites ? null : (
                  <>
                    <th>{kTrans("USER")}</th>
                  </>
                )}
                <th>{kTrans("EMAIL")}</th>
                {isPendingInvites ? (
                  <th></th>
                ) : (
                  <>
                    <th>{kTrans("POLLS")}</th>
                    <th>{kTrans("ARGUMENTS")}</th>
                    <th>{kTrans("COMMENTS")}</th>
                    <th>{kTrans("POINTS")}</th>
                  </>
                )}
                <th width="80">{kTrans("VERIFIED")}</th>
                <th width="80">{kTrans("ADMIN")}</th>
                {isPendingInvites ? null : (
                  <>
                    <th width="80">{kTrans("SETTINGS")}</th>
                    <th>{kTrans("LAST LOGIN")}</th>
                  </>
                )}
              </tr>
            </thead>
            <tbody>
              {users && users.length ? (
                users.map((user, index) => (
                  <tr key={user.id}>
                    {isPendingInvites ? null : (
                      <td>
                        <div className="author">
                          <a
                            target="_blank"
                            rel="noreferrer"
                            className="small-dp user-color-green"
                            href={`${MAIN_WEBSITE_URL}/${user.username}`}
                            tabIndex="0"
                          >
                            {user.avatarObject ? (
                              <img
                                style={{ borderColor: user.statusColor }}
                                src={user.avatarObject.small.location}
                                alt={user.name}
                                className="sidedLazyLoad img-circle avatar"
                                onError={(e) => {
                                  e.target.src = DEFAULT_USER_IMG_URL;
                                }}
                              />
                            ) : (
                              <img
                                style={{ borderColor: user.statusColor }}
                                src={DEFAULT_USER_IMG_URL}
                                alt="{user.name}"
                                className="sidedLazyLoad img-circle avatar"
                              />
                            )}
                            {user.roles &&
                            user.roles.length > 0 &&
                            user.roles.find(
                              (role) => role.name === "VERIFIED",
                            ) ? (
                              <FontAwesomeIcon
                                style={{ color: user.checkmarkColor }}
                                icon={faCheckCircle}
                                className="procheckmark"
                              />
                            ) : null}
                          </a>
                          <div className="copy-wrap">
                            <a
                              target="_blank"
                              rel="noreferrer"
                              className="authorname"
                              href={`${MAIN_WEBSITE_URL}/${user.username}`}
                              tabIndex="0"
                            >
                              <h6>{user.name}</h6>
                            </a>
                            <a
                              target="_blank"
                              rel="noreferrer"
                              className="authorhandle"
                              href={`${MAIN_WEBSITE_URL}/${user.username}`}
                              tabIndex="0"
                            >
                              <span className="handler">@{user.username}</span>
                            </a>
                          </div>
                        </div>
                      </td>
                    )}
                    <td>
                      <div className="text-break">{user.email}</div>
                      {isPendingInvites ? (
                        <>
                          <span className="status-label">
                            {["PENDING", "EXPIRED"].indexOf(user.status) !== -1
                              ? "Invite " + user.status.toLowerCase()
                              : (user.status || "").toLowerCase()}
                            &nbsp;|
                          </span>
                          <span
                            className="text-button"
                            onClick={() =>
                              axios
                                .post(
                                  API_URL + "/admin/user/inviteUserResend",
                                  {
                                    clientId: user.clientId,
                                    token: user.token,
                                  },
                                )
                                .then(({ data }) => {
                                  alert(data.message);
                                  this.fetchInvites();
                                })
                                .catch(({ response }) =>
                                  alert(response.data.message[0].error),
                                )
                            }
                          >
                            Resend
                          </span>
                        </>
                      ) : null}
                    </td>
                    {isPendingInvites ? (
                      <td></td>
                    ) : (
                      <>
                        <td>{user.debatesCount}</td>
                        <td>{user.argumentsCount}</td>
                        <td>{user.commentsCount}</td>
                        <td>{user.pointsCount}</td>
                      </>
                    )}
                    <td>
                      {user.roles &&
                      user.roles.length > 0 &&
                      user.roles.find((role) => role.name === "VERIFIED") ? (
                        <FontAwesomeIcon
                          icon={faCheckCircle}
                          className="svg verified-green"
                        />
                      ) : (
                        <FontAwesomeIcon icon={faCheckCircle} className="svg" />
                      )}
                    </td>
                    <td>
                      {user.roles &&
                      user.roles.length > 0 &&
                      user.roles.find((role) => role.name === "SUPERADMIN") ? (
                        <FontAwesomeIcon
                          icon={faUserCheck}
                          className="svg usercheck-blue"
                        />
                      ) : user.roles &&
                        user.roles.length > 0 &&
                        user.roles.find((role) => role.name === "ADMIN") ? (
                        <FontAwesomeIcon
                          icon={faUserCheck}
                          className="svg usercheck-green"
                        />
                      ) : (
                        <FontAwesomeIcon icon={faUserCheck} className="svg" />
                      )}
                    </td>
                    {isPendingInvites ? null : (
                      <>
                        <td>
                          <span
                            role="button"
                            onClick={() => this.showEditUserModal(index)}
                          >
                            <FontAwesomeIcon
                              icon={faCog}
                              className="svg settings"
                            />
                          </span>
                        </td>
                        <td>
                          {user.lastLogin
                            ? moment(user.lastLogin).format(
                                "MMM DD, YYYY hh:mm:ss A",
                              )
                            : ""}
                        </td>
                      </>
                    )}
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="8">
                    <h3 className="text-center">No User</h3>
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </div>
        {noOfPages > 1 ? (
          <ReactPaginate
            className="pagination"
            previousLabel="&laquo;"
            nextLabel="&raquo;"
            breakLabel="..."
            breakClassName="break-me"
            pageCount={noOfPages}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={this.handlePageClick}
            containerClassName="pagination"
            activeClassName="active"
            forcePage={pageNumber - 1}
          />
        ) : (
          ""
        )}

        <Modal className="authorModal" size="lg" show={this.state.modal}>
          <Modal.Header>
            <Modal.Title>
              {editUserModalContent !== null
                ? kTrans("Admin Settings")
                : kTrans("Add New User")}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="modalContentWrapper">
              {editUserModalContent !== null ? (
                <div className="editUserContent">
                  <div className="author medium">
                    <a
                      target="_blank"
                      rel="noreferrer"
                      className="small-dp user-color-green"
                      href={`${MAIN_WEBSITE_URL}/${editUserModalContent.username}`}
                      tabIndex="0"
                    >
                      {editUserModalContent.avatarObject ? (
                        <img
                          style={{
                            borderColor: editUserModalContent.statusColor,
                          }}
                          data-src=""
                          src={editUserModalContent.avatarObject.small.location}
                          alt="{editUserModalContent.name}"
                          className="sidedLazyLoad img-circle avatar"
                          onError={(e) => {
                            e.target.src = DEFAULT_USER_IMG_URL;
                          }}
                        />
                      ) : (
                        <img
                          style={{
                            borderColor: editUserModalContent.statusColor,
                          }}
                          src={DEFAULT_USER_IMG_URL}
                          alt="{editUserModalContent.name}"
                          className="sidedLazyLoad img-circle avatar"
                        />
                      )}

                      {editUserModalContent.roles.length > 0 &&
                      editUserModalContent.roles.find(
                        (role) => role.name === "VERIFIED",
                      ) ? (
                        <FontAwesomeIcon
                          style={{ color: editUserModalContent.checkmarkColor }}
                          icon={faCheckCircle}
                          className="procheckmark"
                        />
                      ) : null}
                    </a>
                    <div className="copy-wrap">
                      <a
                        target="_blank"
                        rel="noreferrer"
                        className="authorname"
                        href={`${MAIN_WEBSITE_URL}/${editUserModalContent.username}`}
                        tabIndex="0"
                      >
                        <h6>{editUserModalContent.name}</h6>
                      </a>
                      <a
                        target="_blank"
                        rel="noreferrer"
                        className="authorhandle"
                        href={`${MAIN_WEBSITE_URL}/${editUserModalContent.username}`}
                        tabIndex="0"
                      >
                        <span className="handler">
                          @{editUserModalContent.username}
                        </span>
                      </a>
                    </div>
                  </div>
                  <span
                    onClick={() => {
                      if (
                        window.confirm(
                          kTrans(
                            "Are you sure you want to delete this user? This action is permanent and can't be reversed.",
                          ),
                        )
                      ) {
                        this.deleteUser(editUserModalContent.id);
                      }
                    }}
                    className="deleteUser pull-right mt-2 me-3"
                  >
                    {kTrans("Delete User")}
                  </span>
                  <Form
                    className="AuthorSettings common-form"
                    onSubmit={this.submitAuthorSettingsHandle}
                  >
                    <input
                      type="hidden"
                      name="userid"
                      value={editUserModalContent.id}
                    />
                    {selNetworkId === 1 || selNetworkId === 0 ? null : (
                      <Form.Group controlId="authorContributor">
                        <Form.Check.Label>
                          {kTrans("Contributer")}
                        </Form.Check.Label>
                        <Form.Check.Input
                          className="toggle-checkbox"
                          value="1"
                          defaultChecked={
                            editUserModalContent.roles.length > 0 &&
                            editUserModalContent.roles.find(
                              (role) => role.name === "CONTRIBUTOR",
                            )
                              ? true
                              : false
                          }
                          onChange={this.toggleChange}
                          name="roleContributor"
                          data-type="CONTRIBUTOR"
                        />
                      </Form.Group>
                    )}
                    <Form.Group controlId="authorVerified">
                      <Form.Check.Label>{kTrans("Verified")}</Form.Check.Label>
                      <Form.Check.Input
                        className="toggle-checkbox"
                        value="1"
                        defaultChecked={
                          editUserModalContent.roles.length > 0 &&
                          editUserModalContent.roles.find(
                            (role) => role.name === "VERIFIED",
                          )
                            ? true
                            : false
                        }
                        onChange={this.toggleChange}
                        name="roleVerified"
                        data-type="VERIFIED"
                      />
                    </Form.Group>
                    {selNetworkId === 0 ? null : (
                      <Form.Group controlId="authorAdmin">
                        <Form.Check.Label>{kTrans("Admin")}</Form.Check.Label>
                        <Form.Check.Input
                          className="toggle-checkbox"
                          value="1"
                          defaultChecked={
                            editUserModalContent.roles.length > 0 &&
                            editUserModalContent.roles.find(
                              (role) => role.name === "ADMIN",
                            )
                              ? true
                              : false
                          }
                          onChange={this.toggleChange}
                          name="roleAdmin"
                          data-type="ADMIN"
                        />
                      </Form.Group>
                    )}

                    {selNetworkId === 1 &&
                    loggedInUser &&
                    loggedInUser.roles.length > 0 &&
                    loggedInUser.roles.find(
                      (role) => role.name === "SUPERADMIN",
                    ) ? (
                      <Form.Group controlId="authorSuperAdmin">
                        <Form.Check.Label>
                          {kTrans("Super Admin")}
                        </Form.Check.Label>
                        <Form.Check.Input
                          className="toggle-checkbox"
                          value="1"
                          defaultChecked={
                            editUserModalContent.roles.length > 0 &&
                            editUserModalContent.roles.find(
                              (role) => role.name === "SUPERADMIN",
                            )
                              ? true
                              : false
                          }
                          onChange={this.toggleChange}
                          name="roleSuperadmin"
                          data-type="SUPERADMIN"
                        />
                      </Form.Group>
                    ) : null}
                    <Form.Group controlId="authorSuspend">
                      <Form.Check.Label>{kTrans("Suspend")}</Form.Check.Label>
                      <Form.Check.Input
                        className="toggle-checkbox"
                        value="1"
                        defaultChecked={
                          editUserModalContent.suspended === 1 ? true : false
                        }
                        onChange={this.toggleChange}
                        name="suspended"
                      />
                    </Form.Group>
                    <Form.Group controlId="authorLeaderboardExcluded">
                      <Form.Check.Label>
                        {kTrans("Exclude from leaderboard")}
                      </Form.Check.Label>
                      <Form.Check.Input
                        className="toggle-checkbox"
                        value="1"
                        defaultChecked={
                          editUserModalContent.userSetting &&
                          editUserModalContent.userSetting
                            .leaderboardExcluded === 1
                            ? true
                            : false
                        }
                        onChange={this.toggleChange}
                        name="leaderboardExcluded"
                      />
                    </Form.Group>
                    <Form.Group controlId="authorLeaderboardSuggestions">
                      <Form.Check.Label>
                        {kTrans("Exclude from suggestions")}
                      </Form.Check.Label>
                      <Form.Check.Input
                        className="toggle-checkbox"
                        value="1"
                        defaultChecked={
                          editUserModalContent.suggestionExcluded === 1
                            ? true
                            : false
                        }
                        onChange={this.toggleChange}
                        name="suggestionExcluded"
                      />
                    </Form.Group>
                    <Form.Group controlId="authorEmbedAds">
                      <Form.Check.Label>{kTrans("Embed ads")}</Form.Check.Label>
                      <Form.Check.Input
                        className="toggle-checkbox"
                        value="1"
                        defaultChecked={
                          editUserModalContent.embedAds === 1 ? true : false
                        }
                        onChange={this.toggleChange}
                        name="embedAds"
                      />
                    </Form.Group>
                    <Form.Group controlId="authorembedPoll">
                      <Form.Check.Label>
                        {kTrans("Poll only embeds")}
                      </Form.Check.Label>
                      <Form.Check.Input
                        className="toggle-checkbox"
                        value="1"
                        defaultChecked={
                          editUserModalContent.embedPoll === 1 ? true : false
                        }
                        onChange={this.toggleChange}
                        name="embedPoll"
                      />
                    </Form.Group>

                    <Form.Group controlId="brandAccount">
                      <Form.Check.Label>
                        {kTrans("Brand Account")}
                      </Form.Check.Label>
                      <Form.Check.Input
                        className="toggle-checkbox"
                        value="1"
                        defaultChecked={
                          editUserModalContent.brandAccount === 1 ? true : false
                        }
                        onChange={this.toggleChange}
                        name="brandAccount"
                      />
                    </Form.Group>

                    {loggedInUser &&
                    loggedInUser.roles.length > 0 &&
                    loggedInUser.roles.find(
                      (role) => role.name === "SUPERADMIN",
                    ) ? (
                      <Form.Group
                        className={joiningRemoving === true ? "loading" : null}
                      >
                        <div className="inline-content bottom mb-3">
                          <Form.Check.Label>
                            {kTrans("Active Networks")}:
                          </Form.Check.Label>
                          {addNetworkFieldHidden === true ? (
                            <span
                              className="customAnchor"
                              onClick={this.addNewNetworkHandle}
                            >
                              {kTrans("Add network")}
                            </span>
                          ) : (
                            <span
                              className="customAnchor"
                              onClick={this.addNewNetworkHandle}
                            >
                              {kTrans("Cancel")}
                            </span>
                          )}
                        </div>
                        {addNetworkFieldHidden === false ? (
                          <div className="searchWrapper">
                            <div className="addNetworkField inline-content middle">
                              <input
                                type="text"
                                autoComplete="off"
                                className="form-control"
                                onChange={this.addNetworkFieldChange}
                                placeholder="Search for a network"
                                name="addNetworkField"
                                value={addNetworkField}
                              />
                              <i className="fa fa-search"></i>
                            </div>

                            {searchResult && searchResult.length ? (
                              <div className="searchResultList">
                                {searchResult.map((el, i) =>
                                  el.isFollowed === false ? (
                                    <div
                                      key={el.id}
                                      className="inline-content middle pt-1 pb-1"
                                    >
                                      <span>{el.header.title}</span>
                                      <span
                                        className="customAnchor"
                                        onClick={(e) => {
                                          this.joinRemoveNetwork(e, el.id);
                                        }}
                                      >
                                        {kTrans("Join")}
                                      </span>
                                    </div>
                                  ) : (
                                    <div
                                      key={el.id}
                                      className="inline-content middle pt-1 pb-1"
                                    >
                                      <span>{el.header.title}</span>
                                      <span
                                        className="customAnchor red"
                                        onClick={(e) => {
                                          this.joinRemoveNetwork(e, el.id);
                                        }}
                                      >
                                        {kTrans("Remove")}
                                      </span>
                                    </div>
                                  ),
                                )}
                              </div>
                            ) : this.state.noNetworkFound === true ? (
                              <div className="searchResultList">
                                {" "}
                                <span className="mt-1 mb-1 validation-error">
                                  {kTrans("No network found!")}
                                </span>
                              </div>
                            ) : null}
                          </div>
                        ) : null}
                        <div className="mt-3">
                          {userNetworksList && userNetworksList.length
                            ? userNetworksList.map((el, i) => (
                                <div
                                  key={el.id}
                                  className="inline-content middle pt-1 pb-1"
                                >
                                  <span>{el.header.title}</span>
                                  <span
                                    className="customAnchor red"
                                    onClick={(e) =>
                                      this.joinRemoveNetwork(e, el.id)
                                    }
                                  >
                                    {kTrans("Remove")}
                                  </span>
                                </div>
                              ))
                            : null}
                        </div>
                      </Form.Group>
                    ) : null}
                    <div className="buttons">
                      <Button
                        onClick={this.hideEditUserModal}
                        variant="secondary"
                      >
                        {kTrans("Cancel")}
                      </Button>
                      <Button variant="primary" type="submit">
                        {kTrans("Save")}
                      </Button>
                    </div>
                  </Form>
                </div>
              ) : (
                <div className="createUserContent">
                  <Form
                    className="AuthorSettings common-form mt-0"
                    onSubmit={this.submitCreateUserHandle}
                  >
                    <Form.Group>
                      <Form.Check.Label>{kTrans("EMAIL")}</Form.Check.Label>
                      <Form.Control
                        type="text"
                        name="email"
                        value={email}
                        id="email"
                        placeholder={kTrans("Enter the user’s email")}
                        onChange={this.changeHandler}
                      />
                      {validationErrors &&
                      validationErrors.email !== undefined ? (
                        <span className="validation-error">
                          {validationErrors.email}
                        </span>
                      ) : null}
                    </Form.Group>
                    <Form.Group>
                      <Form.Check.Label>{kTrans("PHONE")}</Form.Check.Label>
                      <Form.Control
                        type="text"
                        name="phone"
                        value={phone}
                        id="PHONE"
                        placeholder={kTrans("Enter the user’s phone")}
                        onChange={this.changeHandler}
                      />
                      {validationErrors &&
                      validationErrors.phone !== undefined ? (
                        <span className="validation-error">
                          {validationErrors.phone}
                        </span>
                      ) : null}
                    </Form.Group>
                    <Form.Group>
                      <Form.Check.Label>{kTrans("NAME")}</Form.Check.Label>
                      <Form.Control
                        type="text"
                        name="name"
                        value={name}
                        id="name"
                        placeholder={kTrans("Enter the user’s name")}
                        onChange={this.changeHandler}
                      />
                      {validationErrors &&
                      validationErrors.name !== undefined ? (
                        <span className="validation-error">
                          {validationErrors.name}
                        </span>
                      ) : null}
                    </Form.Group>
                    <Form.Group>
                      <Form.Check.Label>{kTrans("USERNAME")}</Form.Check.Label>
                      <Form.Control
                        type="text"
                        name="username"
                        value={username}
                        id="username"
                        placeholder={kTrans("Choose a username")}
                        onChange={this.changeHandler}
                      />
                      {validationErrors &&
                      validationErrors.username !== undefined ? (
                        <span className="validation-error">
                          {validationErrors.username}
                        </span>
                      ) : null}
                    </Form.Group>
                    <Form.Group>
                      <Form.Check.Label>{kTrans("PASSWORD")}</Form.Check.Label>
                      <div className="position-relative">
                        <Form.Control
                          type="text"
                          name="password"
                          value={password}
                          id="password"
                          placeholder={kTrans("Enter a password")}
                          onChange={this.changeHandler}
                        />
                        <span
                          onClick={this.generatePassword}
                          className="customAnchor generatePassword"
                        >
                          {kTrans("Generate password")}
                        </span>
                      </div>
                      {validationErrors &&
                      validationErrors.password !== undefined ? (
                        <span className="validation-error">
                          {validationErrors.password}
                        </span>
                      ) : null}
                      {password ? (
                        <span
                          onClick={this.copyPassword}
                          className="customAnchor d-block"
                        >
                          {kTrans("Copy password")}
                        </span>
                      ) : null}
                    </Form.Group>
                    <div className="buttons">
                      <Button
                        onClick={this.hideEditUserModal}
                        variant="secondary"
                      >
                        {kTrans("Cancel")}
                      </Button>
                      <Button variant="primary" type="submit">
                        {kTrans("Create User")}
                      </Button>
                    </div>
                  </Form>
                </div>
              )}
            </div>
          </Modal.Body>
        </Modal>

        {showInviteModal ? (
          <InviteModal
            networksList={networksList}
            selNetworkId={selNetworkId}
            onClose={() => this.setState({ showInviteModal: false })}
          />
        ) : null}
      </section>
    );
  }
}

const mapStateToProps = (state) => ({
  selNetworkId: state.networks.selNetworkId,
  networkUsers: state.networkUsers.networkUsers,
  loggedInUser: state.login.user,
  networksList: state.networks.networksList,
});

const Extended = withTranslation()(NetworkUsers);
Extended.static = NetworkUsers.static;

export default connect(mapStateToProps, { loadNetworkUsers })(Extended);
