import React from "react";
import axios from "axios";
import { connect } from "react-redux";
import { API_URL, DEFAULT_USER_IMG_URL } from "../../constants/Globals";
import { Form, Table, Modal, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faCog } from "@fortawesome/free-solid-svg-icons";
import { ClientJS } from "clientjs";
import logo from "../../logo.png";
import { withTranslation } from "react-i18next";
const client = new ClientJS();
const fingerprint = client.getFingerprint();

var moment = require("moment-timezone");

class IntegrationSettingsTab extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      accessToken: null,
      clientId: this.props.clientId,
      embedCodes: null,
      displayAddPlacementField: false,
      addPlacementName: "",
      SSOAccessToken: null,
      isDefault: null,
      showPlacementSettingModal: false,
      placementSettingModalContentIndex: null,
      latestDebateData: null,
    };
  }

  fetchEmbedCodes = async () => {
    await axios
      .get(
        API_URL +
          "/admin/embedPlacement/getEmbedPlacements?clientId=" +
          this.props.clientId +
          "&type=WITH_EMBED",
      )
      .then((res) => {
        this.setState({
          embedCodes: res.data.data,
          //loading: false
        });
        this.fetchLatestDebate();
      })
      .catch((err) => {
        console.log("error in get embed placements");
      });
  };

  componentDidMount() {
    this.fetchAccessToken();
    this.fetchEmbedCodes();
    this.fetchSSOAccessToken();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.clientId !== this.props.clientId) {
      this.setState({
        loading: true,
      });
      this.fetchAccessToken();
      this.fetchEmbedCodes();
      this.fetchSSOAccessToken();
    }
  }

  generateAccessToken = async () => {
    this.setState({ accessToken: this.props.t("Generating...") });
    await axios
      .post(API_URL + "/accessToken/create")
      .then((res) => {
        this.setState({
          accessToken: res.data.data.token,
        });
      })
      .catch((err) => {
        console.log(err.response.data.message);
      });
  };

  deleteAccessToken = async () => {
    this.setState({ accessToken: this.props.t("Deactivating...") });
    await axios
      .post(API_URL + "/accessToken/delete")
      .then((res) => {
        this.setState({
          accessToken: null,
        });
      })
      .catch((err) => {
        console.log(err.response.data.message);
      });
  };

  fetchAccessToken = async () => {
    await axios
      .get(API_URL + "/accessToken/get")
      .then((res) => {
        this.setState({
          accessToken: res.data.data.token,
          loading: false,
        });
      })
      .catch((err) => {
        console.log(err.response.data.message);
      });
  };

  generateSSOAccessToken = async () => {
    this.setState({ SSOAccessToken: this.props.t("Generating...") });
    await axios
      .post(
        API_URL +
          "/admin/ssoAccessToken/create?clientId=" +
          this.props.clientId,
      )
      .then((res) => {
        this.setState({
          SSOAccessToken: res.data.data.token,
        });
      })
      .catch((err) => {
        console.log(err.response.data.message);
      });
  };

  deleteSSOAccessToken = async () => {
    this.setState({ SSOAccessToken: this.props.t("Deactivating...") });
    await axios
      .post(
        API_URL +
          "/admin/ssoAccessToken/delete?clientId=" +
          this.props.clientId,
      )
      .then((res) => {
        this.setState({
          SSOAccessToken: null,
        });
      })
      .catch((err) => {
        console.log(err.response.data.message);
      });
  };

  fetchSSOAccessToken = async () => {
    await axios
      .get(
        API_URL + "/admin/ssoAccessToken/get?clientId=" + this.props.clientId,
      )
      .then((res) => {
        this.setState({
          SSOAccessToken: res.data.data.token,
          loading: false,
        });
      })
      .catch((err) => {
        console.log(err.response.data.message);
      });
  };

  copyAdjacentCode = async (e, targetElement, copiedItem) => {
    if (this[targetElement].value !== "") {
      this[targetElement].select();
      await document.execCommand("copy");
      e.target.focus();
      this.messageNotification(
        "success",
        copiedItem + this.props.t(" copied to clipboard."),
      );
    } else {
      this.messageNotification(
        "fail",
        "There is some problem in copying " +
          copiedItem +
          ". Please reload the page and try again!",
      );
    }
  };

  messageNotification = async (type, message) => {
    this.setState({
      successMsg: message,
    });

    setTimeout(() => {
      this.setState({
        successMsg: "",
      });
    }, 2000);
  };

  addEmbedPlacement = async (e) => {
    this.setState({
      displayAddPlacementField: true,
      //addPlacementName: ''
    });
  };

  saveEmbedPlacement = async (e) => {
    if (this.state.addPlacementName.trim().length < 1) {
      var alertMsg = this.props.t("This field can not be blank.");
      alert(alertMsg);
      return false;
    }

    await this.setState({ loading: true });
    await axios
      .post(
        API_URL +
          "/admin/embedPlacement/create?clientId=" +
          this.props.clientId,
        { placementName: this.state.addPlacementName.trim() },
      )
      .then((res) => {
        this.fetchEmbedCodes(); //Loading is set to false in this function
        this.setState({
          addPlacementName: null,
          displayAddPlacementField: false,
        });
        this.messageNotification(
          "success",
          this.props.t("New placement added successfully!"),
        );
      })
      .catch((err) => {
        this.setState({ loading: false });
        this.messageNotification("fail", this.props.t("Failed!"));
        console.log(err.response.data.message);
      });
  };

  addPlacementChangeHandler = async (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  /*embedControlChange = async(e , embedPlacementId) => {
		var q_string = '';

		var placementControls = {...this.state['placementId-'+embedPlacementId]}
		placementControls[e.target.name] = e.target.value;
		await this.setState({ ['placementId-'+embedPlacementId] : placementControls });

		var pollQueryString = this.state['placementId-'+embedPlacementId].poll !== undefined ? '&poll='+this.state['placementId-'+embedPlacementId].poll : '';
		var showAdsQueryString = this.state['placementId-'+embedPlacementId].showAds !== undefined ? '&showAds='+this.state['placementId-'+embedPlacementId].showAds : '';
		var adPositionQueryString = this.state['placementId-'+embedPlacementId].adPosition !== undefined ? '&adPosition='+this.state['placementId-'+embedPlacementId].adPosition : '';
		var adDisplayQueryString = this.state['placementId-'+embedPlacementId].adDisplay !== undefined ? '&adDisplay='+this.state['placementId-'+embedPlacementId].adDisplay : '';

		q_string = pollQueryString+showAdsQueryString+adPositionQueryString+adDisplayQueryString;

		await axios.get(API_URL + '/admin/embedPlacement/getEmbed/'+embedPlacementId+'?clientId='+this.props.clientId+q_string)
		.then(res => {
			this.setState({
				['placementCode-'+embedPlacementId]: res.data.data.html
			})
		})
		.catch(err => {
			console.log(err.response.data.message);
		});
	}*/

  embedControlChange = async (e, embedPlacementId) => {
    //console.log(embedPlacementId +'-'+ e.target.name +'-'+ e.target.value);
    await this.updateEmbedControls(
      embedPlacementId,
      e.target.name,
      e.target.value,
    );
  };

  placementAdCodeChangeHandler = async (e) => {
    await this.setState({
      [e.target.name + e.target.dataset.pid]: e.target.value,
    });
  };

  placementAdCodeMobileChangeHandler = async (e) => {
    await this.setState({
      [e.target.name + e.target.dataset.pid]: e.target.value,
    });
  };

  placementAdCodeUpdate = async (e, embedPlacementId) => {
    if (this.state["adCode" + embedPlacementId] !== undefined) {
      await this.updateEmbedControls(
        embedPlacementId,
        "adCode",
        this.state["adCode" + embedPlacementId],
      );
    }
  };

  placementAdCodeMobileUpdate = async (e, embedPlacementId) => {
    if (this.state["adCodeMobile" + embedPlacementId] !== undefined) {
      await this.updateEmbedControls(
        embedPlacementId,
        "adCodeMobile",
        this.state["adCodeMobile" + embedPlacementId],
      );
    }
  };

  updateEmbedControls = async (embedPlacementId, paramName, paramValue) => {
    await this.setState({ loading: true });
    await axios
      .put(
        API_URL +
          "/admin/embedPlacement/update/" +
          embedPlacementId +
          "?clientId=" +
          this.props.clientId,
        { [paramName]: paramValue },
      )
      .then((res) => {
        this.fetchEmbedCodes(); //Loading is set to false in this function
        //this.setState({ loading: false })
        this.messageNotification(
          "success",
          this.props.t("Embed Placement updated successfully!"),
        );
      })
      .catch((err) => {
        this.setState({ loading: false });
        this.messageNotification("fail", this.props.t("Failed!"));
        console.log(err.response.data.message);
      });
  };

  placementNameChangeHandler = async (e) => {
    await this.setState({
      [e.target.name + e.target.dataset.pid]: e.target.value,
    });
  };

  updateEmbedPlacement = async (e, embedPlacementId) => {
    if (this.state["placementName" + embedPlacementId] !== undefined) {
      if (this.state["placementName" + embedPlacementId].trim().length < 1) {
        var alertMsg = this.props.t("This field can not be blank.");
        alert(alertMsg);
        return false;
      }

      var thisStateArr = { ...this.state };

      await this.setState({ loading: true });
      await axios
        .put(
          API_URL +
            "/admin/embedPlacement/update/" +
            embedPlacementId +
            "?clientId=" +
            this.props.clientId,
          {
            placementName:
              this.state["placementName" + embedPlacementId].trim(),
          },
        )
        .then((res) => {
          this.fetchEmbedCodes(); //Loading is set to false in this function
          delete thisStateArr["placementName" + embedPlacementId];
          this.setState({
            thisStateArr,
          });
          this.messageNotification(
            "success",
            this.props.t("Embed Placement updated successfully!"),
          );
        })
        .catch((err) => {
          this.setState({ loading: false });
          this.messageNotification("fail", this.props.t("Failed!"));
          console.log(err.response.data.message);
        });
    }
  };

  showPlacementSettingModal = async (e, elementIndex) => {
    await this.setState({
      //placementSettingModalContent: this.state.embedCodes[elementIndex]
      placementSettingModalContentIndex: elementIndex,
    });

    this.setState({ showPlacementSettingModal: true });
  };

  hidePlamentSettingModal = async (e) => {
    await this.setState({ showPlacementSettingModal: false });
  };

  fetchLatestDebate = async () => {
    await this.setState({
      loading: true,
    });

    await axios
      .get(
        API_URL +
          "/debate/latest?deviceId=" +
          fingerprint +
          "&emailEmbedDebateLink=false",
      )
      .then((res) => {
        var debateData = res.data.data;
        this.setState({
          latestDebateData: debateData,
          loading: false,
        });
      })
      .catch((err) => {
        this.setState({ loading: false });
        console.log(err);
      });
  };

  render(props) {
    const {
      embedCodes,
      accessToken,
      loading,
      addPlacementName,
      displayAddPlacementField,
      SSOAccessToken,
      showPlacementSettingModal,
      placementSettingModalContentIndex,
      latestDebateData,
    } = this.state;
    var placementSettingModalContent = "";
    if (embedCodes && placementSettingModalContentIndex !== null) {
      placementSettingModalContent =
        embedCodes[placementSettingModalContentIndex];
    }
    const kTrans = this.props.t ? this.props.t : "";

    /* Difference in startedAt and endedAt to display in preview section */
    if (latestDebateData !== null) {
      var finalDifference = null;
      var startedAt = latestDebateData.startedAt;
      var endedAt = latestDebateData.endedAt;
    }

    return (
      <>
        {this.state.successMsg ? (
          <div className="sucessMsg">{this.state.successMsg}</div>
        ) : null}
        <div
          className={`integrationSettingsTab ${loading === true ? "loading" : null}`}
        >
          <h3>{kTrans("API KEY")}</h3>
          {accessToken !== null ? (
            <>
              <Form.Control
                className="apiKey"
                readOnly="readonly"
                type="text"
                value={accessToken}
                ref={(copyapikey) => (this.copyAPIKey = copyapikey)}
              />
              <div className="d-flex apiKeyAnchors mt-2">
                <span className="customAnchor" onClick={this.deleteAccessToken}>
                  {kTrans("Deactivate API key")}
                </span>
                <span
                  className="customAnchor"
                  onClick={this.generateAccessToken}
                >
                  {kTrans("Generate new API key")}
                </span>
                <span
                  className="customAnchor"
                  onClick={(e) => {
                    this.copyAdjacentCode(e, "copyAPIKey", kTrans("API Key"));
                  }}
                >
                  <i className="fa fa-copy me-2"></i>
                  {kTrans("Copy API Key")}
                </span>
              </div>
            </>
          ) : (
            <span
              className="btn btn-primary"
              onClick={this.generateAccessToken}
            >
              {kTrans("Generate API Key")}
            </span>
          )}
          <hr />
          <h3>{kTrans("SSO Access Token")}</h3>
          {SSOAccessToken !== null ? (
            <>
              <Form.Control
                className="apiKey"
                readOnly="readonly"
                type="text"
                value={SSOAccessToken}
                ref={(copyssoaccestoken) =>
                  (this.copySSOAccessToken = copyssoaccestoken)
                }
              />
              <div className="d-flex apiKeyAnchors mt-2">
                <span
                  className="customAnchor"
                  onClick={this.deleteSSOAccessToken}
                >
                  {kTrans("Deactivate SSO Access Token")}
                </span>
                <span
                  className="customAnchor"
                  onClick={this.generateSSOAccessToken}
                >
                  {kTrans("Generate new SSO Access Token")}
                </span>
                <span
                  className="customAnchor"
                  onClick={(e) => {
                    this.copyAdjacentCode(
                      e,
                      "copySSOAccessToken",
                      kTrans("SSO Access Token"),
                    );
                  }}
                >
                  <i className="fa fa-copy me-2"></i>
                  {kTrans("Copy SSO Access Token")}
                </span>
              </div>
            </>
          ) : (
            <span
              className="btn btn-primary"
              onClick={this.generateSSOAccessToken}
            >
              {kTrans("Generate SSO Access Token")}
            </span>
          )}
          <hr />
          <h3>{kTrans("HEADER CODE")}</h3>
          <p>
            {kTrans("Add this code to your &lt;HEAD&gt; tag on your website")}
          </p>
          <Form.Group className="embedHeaderTag">
            {embedCodes && embedCodes.length ? (
              <Form.Control
                className="mb-2"
                as="textarea"
                rows="3"
                ref={(copyheadercode) => (this.copyHeaderCode = copyheadercode)}
                value={embedCodes[0].embedCodeObject.header}
              />
            ) : null}
            <span
              className="customAnchor"
              title={kTrans("Copy header code")}
              onClick={(e) => {
                this.copyAdjacentCode(
                  e,
                  "copyHeaderCode",
                  kTrans("Header code"),
                );
              }}
            >
              <i className="fa fa-copy me-2"></i>
              {kTrans("Copy header code")}
            </span>
          </Form.Group>
          <hr />
          <h3>{kTrans("POLL FEED CODE")}</h3>
          <p>
            {kTrans("Add this code to any page to display a feed of polls")}
          </p>
          <Form.Group className="embedHeaderTag">
            {this.props.embedCodeObject.html &&
            this.props.embedCodeObject.html.length ? (
              <Form.Control
                className="mb-2"
                as="textarea"
                rows="3"
                ref={(copydebatefeedcode) =>
                  (this.copyDebateFeedCode = copydebatefeedcode)
                }
                value={this.props.embedCodeObject.html}
              />
            ) : null}
            <span
              className="customAnchor"
              title={kTrans("Copy header code")}
              onClick={(e) => {
                this.copyAdjacentCode(
                  e,
                  "copyDebateFeedCode",
                  kTrans("Poll Feed Code"),
                );
              }}
            >
              <i className="fa fa-copy me-2"></i>
              {kTrans("Copy poll feed code")}
            </span>
          </Form.Group>
          <hr />
          <h3>{kTrans("EMBED PLACEMENTS")}</h3>
          <p>
            {kTrans(
              'Define where your embeds show on your website. Use the default "in-article" placement, or create a new one and add the iframe code(s) below to your website template',
            )}
          </p>
          <div className="addPlacementSection mt-4 mb-4">
            <span
              className="customAnchor customAnchor pull-right"
              title={kTrans("Copy embed code")}
              onClick={this.addEmbedPlacement}
            >
              + {kTrans("Add Placement")}
            </span>
            <br clear="all" />
            {displayAddPlacementField === true ? (
              <div className="placementNameWrap">
                <Form.Control
                  placeholder={kTrans("Enter new Placement")}
                  onChange={this.addPlacementChangeHandler}
                  className="placementName"
                  type="text"
                  name="addPlacementName"
                  value={addPlacementName}
                />
                <span onClick={this.saveEmbedPlacement}>{kTrans("Save")}</span>
              </div>
            ) : null}
          </div>
          <div className="table-wrapper">
            <Table className="embedPlacement tableCommon">
              <thead>
                <tr>
                  <th>{kTrans("PLACEMENT NAME")}</th>
                  <th>{kTrans("COMMENTS")}</th>
                  <th>{kTrans("ADS")}</th>
                  <th>{kTrans("TOP COMMENT")}</th>
                  <th>{kTrans("DEFAULT")}</th>
                  <th>{kTrans("EMBED")}</th>
                  <th>{kTrans("SETTINGS")}</th>
                </tr>
              </thead>
              <tbody>
                {embedCodes && embedCodes.length
                  ? embedCodes.map((el, i) => (
                      <tr key={i}>
                        <td>
                          <strong>
                            {this.state["placementName" + el.id] !== undefined
                              ? this.state["placementName" + el.id]
                              : el.placementName}
                          </strong>
                        </td>
                        <td
                          className={
                            el.embedType.indexOf("conversation") === -1
                              ? null
                              : "active"
                          }
                        >
                          <FontAwesomeIcon icon={faCheckCircle} />
                        </td>
                        <td className={el.ads === "false" ? null : "active"}>
                          <FontAwesomeIcon icon={faCheckCircle} />
                        </td>
                        <td className={el.topComment ? "active" : null}>
                          <FontAwesomeIcon icon={faCheckCircle} />
                        </td>
                        <td className={el.isDefault ? "active" : null}>
                          <FontAwesomeIcon icon={faCheckCircle} />
                        </td>
                        <td>
                          <Form.Control
                            className="hiddenElement mb-2"
                            rows="4"
                            as="textarea"
                            ref={(copyembedcode) =>
                              (this[`${i}_copyEmbedCode`] = copyembedcode)
                            }
                            value={
                              this.state["placementCode-" + el.id] !== undefined
                                ? this.state["placementCode-" + el.id]
                                : el.embedCodeObject.html
                            }
                          />
                          <span
                            className="customAnchor"
                            title="Copy embed code"
                            onClick={(e) => {
                              this.copyAdjacentCode(
                                e,
                                `${i}_copyEmbedCode`,
                                kTrans("Embed code"),
                              );
                            }}
                          >
                            {kTrans("Copy Code")}
                          </span>
                        </td>
                        <td>
                          <span>
                            <FontAwesomeIcon
                              icon={faCog}
                              className="svg settings"
                              onClick={(e) =>
                                this.showPlacementSettingModal(e, i)
                              }
                            />
                          </span>
                        </td>
                      </tr>
                    ))
                  : null}
              </tbody>
            </Table>
          </div>

          <Modal
            className="integrationSettingsModal"
            size="lg"
            show={showPlacementSettingModal}
          >
            <Modal.Body
              className={`integrationSettingsTab m-0 ps-md-4 pe-md-4 ${loading === true ? "loading" : null}`}
            >
              <div className="inline-content">
                <h3>{kTrans("Placement Settings")}</h3>
                <span className="modal-close-btn">
                  <b className="" onClick={this.hidePlamentSettingModal}>
                    &#x2715;
                  </b>
                </span>
              </div>
              {placementSettingModalContent ? (
                <div className="placementCols row">
                  <Col md={6} className="">
                    <Form.Group className="embedPlacementName placementNameWrap">
                      <Form.Label>{kTrans("Placement Name")}</Form.Label>
                      <Form.Control
                        className="pe-5"
                        data-pid={placementSettingModalContent.id}
                        type="text"
                        name="placementName"
                        value={
                          this.state[
                            "placementName" + placementSettingModalContent.id
                          ] !== undefined
                            ? this.state[
                                "placementName" +
                                  placementSettingModalContent.id
                              ]
                            : placementSettingModalContent.placementName
                        }
                        onChange={this.placementNameChangeHandler}
                      />
                      <span
                        className={
                          this.state[
                            "placementName" + placementSettingModalContent.id
                          ] !== undefined
                            ? "enable"
                            : "disable"
                        }
                        onClick={
                          this.state[
                            "placementName" + placementSettingModalContent.id
                          ] !== undefined
                            ? (e) =>
                                this.updateEmbedPlacement(
                                  e,
                                  placementSettingModalContent.id,
                                )
                            : null
                        }
                      >
                        {kTrans("Save")}
                      </span>
                    </Form.Group>
                    <Form.Group className="embedType">
                      <Form.Label>{kTrans("Comments")}</Form.Label>
                      <Form.Control
                        defaultValue={placementSettingModalContent.embedType}
                        onChange={(e) =>
                          this.embedControlChange(
                            e,
                            placementSettingModalContent.id,
                          )
                        }
                        as="select"
                        name="embedType"
                      >
                        <option value="poll_conversation">
                          {kTrans("On")}
                        </option>
                        <option value="poll">{kTrans("Off")}</option>
                      </Form.Control>
                    </Form.Group>

                    <Form.Group className="embedType">
                      <Form.Label>
                        {kTrans("Require email to see poll results")}
                      </Form.Label>
                      <Form.Control
                        defaultValue={placementSettingModalContent.requireEmail}
                        onChange={(e) =>
                          this.embedControlChange(
                            e,
                            placementSettingModalContent.id,
                          )
                        }
                        as="select"
                        name="requireEmail"
                      >
                        <option value="0">{kTrans("No")}</option>
                        <option value="1">{kTrans("Yes, require verification code")}</option>
                        <option value="2">
                          {kTrans("Yes, verify with email link")}
                        </option>
                      </Form.Control>
                    </Form.Group>

                    <Form.Group className="embedbodyTag">
                      <Form.Label>{kTrans("Embed code")}</Form.Label>
                      <Form.Control
                        readOnly="readonly"
                        className="mb-2"
                        rows="3"
                        as="textarea"
                        ref={(copyembedcode) =>
                          (this["1001_copyEmbedCode"] = copyembedcode)
                        }
                        value={
                          this.state[
                            "placementCode-" + placementSettingModalContent.id
                          ] !== undefined
                            ? this.state[
                                "placementCode-" +
                                  placementSettingModalContent.id
                              ]
                            : placementSettingModalContent.embedCodeObject.html
                        }
                      />
                      <span
                        className="customAnchor pull-right"
                        title={kTrans("Copy embed code")}
                        onClick={(e) => {
                          this.copyAdjacentCode(
                            e,
                            "1001_copyEmbedCode",
                            kTrans("Embed code"),
                          );
                        }}
                      >
                        <i className="fa fa-copy me-2"></i>
                        {kTrans("Copy embed code")}
                      </span>
                      <br clear="all" />
                    </Form.Group>
                  </Col>
                  <Col md={6} className="">
                    <div className="inline-content justify-content-start">
                      {placementSettingModalContent.embedType ===
                        "conversion_poll_comments" ||
                      placementSettingModalContent.embedType ===
                        "poll_conversation" ? (
                        <Form.Group className="embedType pe-3">
                          <Form.Label className="d-block">
                            {kTrans("Top comments")}
                          </Form.Label>
                          <Form.Check.Input
                            className="float-none mt-3 toggle-checkbox"
                            checked={placementSettingModalContent.topComment}
                            onChange={(e) =>
                              this.embedControlChange(
                                e,
                                placementSettingModalContent.id,
                              )
                            }
                            name="topComment"
                            value={
                              placementSettingModalContent.topComment === 0
                                ? 1
                                : 0
                            }
                            data-type="topComment"
                          />
                        </Form.Group>
                      ) : null}

                      <Form.Group className="embedType pe-3">
                        <Form.Label className="d-block">
                          {kTrans("Placement default")}
                        </Form.Label>
                        <Form.Check.Input
                          className="float-none mt-3 toggle-checkbox"
                          checked={placementSettingModalContent.isDefault}
                          onChange={(e) =>
                            this.embedControlChange(
                              e,
                              placementSettingModalContent.id,
                            )
                          }
                          name="isDefault"
                          value={
                            placementSettingModalContent.isDefault === 0 ? 1 : 0
                          }
                        />
                      </Form.Group>

                      <Form.Group className="showads pe-3">
                        <Form.Label>{kTrans("Show Ads")}</Form.Label>
                        <form>
                          <div className="d-flex">
                            {/*<Form.Check defaultChecked={placementSettingModalContent.ads === "true"} className="square me-3" type="radio" name="showAds" value="true" label="Yes" onChange={(e) => this.embedControlChange(e , placementSettingModalContent.id)} />
											<Form.Check defaultChecked={placementSettingModalContent.ads === "false"} className="square" type="radio" name="showAds" value="false" label="No" onChange={(e) => this.embedControlChange(e , placementSettingModalContent.id)} />*/}

                            <Form.Check.Input
                              className="float-none mt-3 toggle-checkbox"
                              checked={
                                placementSettingModalContent.ads === "false"
                                  ? false
                                  : true
                              }
                              onChange={(e) =>
                                this.embedControlChange(
                                  e,
                                  placementSettingModalContent.id,
                                )
                              }
                              name="showAds"
                              value={
                                placementSettingModalContent.ads === "false"
                                  ? true
                                  : false
                              }
                            />
                          </div>
                        </form>
                      </Form.Group>

                      <Form.Group>
                        <Form.Label>{kTrans("Ads in Side Panel")}</Form.Label>
                        <form>
                          <div className="d-flex">
                            <Form.Check.Input
                              className="float-none mt-3 toggle-checkbox"
                              checked={placementSettingModalContent.sidebarAds}
                              onChange={async (e) => {
                                await this.updateEmbedControls(
                                  placementSettingModalContent.id,
                                  "sidebarAds",
                                  !placementSettingModalContent.sidebarAds,
                                );
                              }}
                              name="sidebarAds"
                              value={placementSettingModalContent.sidebarAds}
                            />
                          </div>
                        </form>
                      </Form.Group>
                    </div>
                    {placementSettingModalContent.ads === "false" ? null : (
                      <>
                        <div className="">
                          <Form.Group className="adPosition">
                            <Form.Label>{kTrans("Ad Position")}</Form.Label>
                            <Form.Control
                              defaultValue={
                                placementSettingModalContent.adPosition
                              }
                              onChange={(e) =>
                                this.embedControlChange(
                                  e,
                                  placementSettingModalContent.id,
                                )
                              }
                              name="adPosition"
                              as="select"
                            >
                              <option value="left">{kTrans("Left")}</option>
                              <option value="bottom">{kTrans("Bottom")}</option>
                              <option value="right">{kTrans("Right")}</option>
                              <option value="top">{kTrans("Top")}</option>
                              <option value="wide_format_left">{kTrans("Wide format left")}</option>
                              <option value="wide_format_right">{kTrans("Wide format right")}</option>
                            </Form.Control>
                          </Form.Group>
                          <Form.Group className="displayAds">
                            <Form.Label>{kTrans("Display")}</Form.Label>
                            <Form.Control
                              defaultValue={
                                placementSettingModalContent.adDisplay
                              }
                              onChange={(e) =>
                                this.embedControlChange(
                                  e,
                                  placementSettingModalContent.id,
                                )
                              }
                              name="adDisplay"
                              as="select"
                            >
                              <option value="all">{kTrans("All")}</option>
                              <option value="side_panel_only">
                                {kTrans("Side panel only")}
                              </option>
                            </Form.Control>
                          </Form.Group>
                        </div>
                        <Form.Group className="placementNameWrap">
                          <Form.Label>{kTrans("Ad Code")}</Form.Label>
                          <Form.Control
                            data-pid={placementSettingModalContent.id}
                            className="mb-2 pe-5"
                            rows="3"
                            as="textarea"
                            name="adCode"
                            value={
                              this.state[
                                "adCode" + placementSettingModalContent.id
                              ] !== undefined
                                ? this.state[
                                    "adCode" + placementSettingModalContent.id
                                  ]
                                : placementSettingModalContent.adCode
                            }
                            onChange={this.placementAdCodeChangeHandler}
                          />
                          <span
                            className={
                              this.state[
                                "adCode" + placementSettingModalContent.id
                              ] !== undefined
                                ? "enable"
                                : "disable"
                            }
                            onClick={
                              this.state[
                                "adCode" + placementSettingModalContent.id
                              ] !== undefined
                                ? (e) =>
                                    this.placementAdCodeUpdate(
                                      e,
                                      placementSettingModalContent.id,
                                    )
                                : null
                            }
                          >
                            {kTrans("Save")}
                          </span>
                        </Form.Group>
                        <Form.Group className="placementNameWrap">
                          <Form.Label>{kTrans("Ad Code Mobile")}</Form.Label>
                          <Form.Control
                            data-pid={placementSettingModalContent.id}
                            className="mb-2 pe-5"
                            rows="3"
                            as="textarea"
                            name="adCodeMobile"
                            value={
                              this.state[
                                "adCodeMobile" + placementSettingModalContent.id
                              ] !== undefined
                                ? this.state[
                                    "adCodeMobile" +
                                      placementSettingModalContent.id
                                  ]
                                : placementSettingModalContent.adCodeMobile
                            }
                            onChange={this.placementAdCodeMobileChangeHandler}
                          />
                          <span
                            className={
                              this.state[
                                "adCodeMobile" + placementSettingModalContent.id
                              ] !== undefined
                                ? "enable"
                                : "disable"
                            }
                            onClick={
                              this.state[
                                "adCodeMobile" + placementSettingModalContent.id
                              ] !== undefined
                                ? (e) =>
                                    this.placementAdCodeMobileUpdate(
                                      e,
                                      placementSettingModalContent.id,
                                    )
                                : null
                            }
                          >
                            {kTrans("Save")}
                          </span>
                        </Form.Group>
                      </>
                    )}
                  </Col>
                </div>
              ) : null}
              {placementSettingModalContent.ads === "false" ? null : (
                <>
                  <Form.Group className="placementNameWrap">
                    <Form.Label>{kTrans("Placement Preview")}</Form.Label>
                  </Form.Group>
                  <div className="table-wrapper">
                    <div
                      className={`placementPreviewSection ${placementSettingModalContent.adPosition}`}
                    >
                      <div className="adSection">
                        <span className="right-left">
                          AD:{" "}
                          {placementSettingModalContent.adPosition === "top" ||
                          placementSettingModalContent.adPosition === "bottom"
                            ? "300 x 250"
                            : "400 x 300"}
                        </span>
                      </div>
                      <div className="debateSection common-form">
                        {latestDebateData ? (
                          <div className="debatePreviewInner">
                            <div className="debatePreviewHeader">
                              <div className="author">
                                <a
                                  target="_blank"
                                  rel="noreferrer"
                                  className="small-dp user-color-green"
                                  href={"/" + latestDebateData.user.username}
                                  tabIndex="0"
                                >
                                  <img
                                    style={{
                                      borderColor:
                                        latestDebateData.user.statusColor,
                                    }}
                                    data-src=""
                                    src={
                                      latestDebateData.user.avatarObject
                                        ? latestDebateData.user.avatarObject
                                            .small.location
                                        : DEFAULT_USER_IMG_URL
                                    }
                                    alt={latestDebateData.user.name}
                                    className="sidedLazyLoad img-circle avatar"
                                    onError={(e) => {
                                      e.target.src = DEFAULT_USER_IMG_URL;
                                    }}
                                  />

                                  {latestDebateData.user.roles.length > 0 &&
                                  latestDebateData.user.roles.find(
                                    (role) => role.name === "VERIFIED",
                                  ) ? (
                                    <FontAwesomeIcon
                                      style={{
                                        color:
                                          latestDebateData.user.checkmarkColor,
                                      }}
                                      icon={faCheckCircle}
                                      className="procheckmark"
                                    />
                                  ) : null}
                                </a>
                                <div className="copy-wrap">
                                  <a
                                    target="_blank"
                                    rel="noreferrer"
                                    className="authorname"
                                    href={"/" + latestDebateData.user.username}
                                    tabIndex="0"
                                  >
                                    <h6>{latestDebateData.user.name}</h6>
                                  </a>
                                  <span className="authorhandle">
                                    <span className="handler">
                                      Posted{" "}
                                      {moment(startedAt).format("MMM DD, YYYY")}
                                    </span>
                                  </span>
                                </div>
                              </div>
                            </div>
                            {latestDebateData.backgroundImageObject ? (
                              <div className="debatePreviewBgImg mt-3">
                                <img
                                  alt="backgroundImage"
                                  src={
                                    latestDebateData.backgroundImageObject
                                      .medium.location
                                  }
                                />
                              </div>
                            ) : null}
                            <div className="debatePreviewTitle mt-3">
                              {latestDebateData.thesis !== "" ? (
                                <h5>{latestDebateData.thesis}</h5>
                              ) : (
                                <>
                                  <p></p>
                                  <p></p>
                                  <p></p>
                                </>
                              )}
                            </div>
                            {!parseInt(latestDebateData.noEndDate) ? (
                              <>
                                {this.state.latestDebateData.isClosed ? (
                                  <span className="lightColorSpan mt-2 d-block">
                                    {kTrans("Poll ended")}{" "}
                                    {moment(endedAt).format("MMM DD, YYYY")}
                                  </span>
                                ) : (
                                  <span className="lightColorSpan mt-2 d-block">
                                    {kTrans("Poll ends in")}{" "}
                                    {finalDifference ? finalDifference : ""} •{" "}
                                    {kTrans("Vote below")}
                                  </span>
                                )}
                              </>
                            ) : (
                              <span className="lightColorSpan mt-2 d-block">
                                {kTrans("Vote below")}
                              </span>
                            )}

                            <div className="debatePreviewSides mt-2">
                              {latestDebateData.sides &&
                              latestDebateData.sides.length
                                ? latestDebateData.sides.map((el, i) => (
                                    <div className="" key={i}>
                                      <label>
                                        {el.text !== ""
                                          ? el.text
                                          : `Side ${i + 1}`}
                                      </label>
                                    </div>
                                  ))
                                : null}
                            </div>

                            <span className="lightColorSpan mt-2 d-block">
                              {latestDebateData.votes} Vote
                              {latestDebateData.votes !== 1 ? "s" : ""} •{" "}
                              {latestDebateData.comments} Comment
                              {latestDebateData.comments !== 1 ? "s" : ""}{" "}
                            </span>
                            <div className="debatePreviewFooter mt-2">
                              <span className="customAnchor">
                                {kTrans("Share")}
                              </span>
                              <img alt="Logo" src={logo} className="logo" />
                            </div>
                          </div>
                        ) : (
                          <div className="debatePreviewInner">
                            <div className="debatePreviewHeader">
                              <div className="author">
                                <span
                                  className="small-dp user-color-green"
                                  tabIndex="0"
                                >
                                  <img
                                    data-src=""
                                    src="https://cdn.sided.co/stage/sided/users/d74bee50-6747-11ed-b6db-dfc3031a4e35-small"
                                    alt="Super Admin"
                                    className="sidedLazyLoad img-circle avatar"
                                  />
                                  <svg
                                    aria-hidden="true"
                                    focusable="false"
                                    data-prefix="fas"
                                    data-icon="check-circle"
                                    className="svg-inline--fa fa-check-circle fa-w-16 procheckmark"
                                    role="img"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 512 512"
                                  >
                                    <path
                                      fill="currentColor"
                                      d="M504 256c0 136.967-111.033 248-248 248S8 392.967 8 256 119.033 8 256 8s248 111.033 248 248zM227.314 387.314l184-184c6.248-6.248 6.248-16.379 0-22.627l-22.627-22.627c-6.248-6.249-16.379-6.249-22.628 0L216 308.118l-70.059-70.059c-6.248-6.248-16.379-6.248-22.628 0l-22.627 22.627c-6.248 6.248-6.248 16.379 0 22.627l104 104c6.249 6.249 16.379 6.249 22.628.001z"
                                    ></path>
                                  </svg>
                                </span>
                                <div className="copy-wrap">
                                  <a
                                    target="_blank"
                                    rel="noreferrer"
                                    className="authorname"
                                    href="/superadmin"
                                    tabIndex="0"
                                  >
                                    <h6>{kTrans("Your Name")}</h6>
                                  </a>
                                  <span className="authorhandle">
                                    <span className="handler">
                                      Posted {moment().format("MMM DD, YYYY")}
                                    </span>
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div className="debatePreviewTitle mt-3">
                              <h5>{kTrans("Your poll title")}</h5>
                            </div>
                            <span className="lightColorSpan mt-2 d-block">
                              {kTrans("Poll ends in")} 20 hour(s) •{" "}
                              {kTrans("Vote below")}
                            </span>
                            <div className="debatePreviewSides mt-2">
                              <div>
                                <label>{kTrans("Your poll side")} 1</label>
                              </div>
                              <div>
                                <label>{kTrans("Your poll side")} 2</label>
                              </div>
                              <div>
                                <label>{kTrans("Your poll side")} 3</label>
                              </div>
                            </div>
                            <span className="lightColorSpan mt-2 d-block">
                              0 {kTrans("Votes")} • 0 {kTrans("Comments")}{" "}
                            </span>
                            <div className="debatePreviewFooter mt-2">
                              <span className="customAnchor">
                                {kTrans("Share")}
                              </span>
                              <img alt="Logo" src={logo} className="logo" />
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </>
              )}
            </Modal.Body>
          </Modal>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  loggedInUser: state.login.user,
});

const Extended = withTranslation()(IntegrationSettingsTab);
Extended.static = IntegrationSettingsTab.static;

export default connect(mapStateToProps, {})(Extended);
