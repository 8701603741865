import axios from "axios";
import { API_URL, USERS_PER_PAGE } from "../../constants/Globals";

export const loadNetworkUsers =
  (clientId = "", page = 1, searchText = "", filter = "") =>
  (dispatch) => {
    console.log("redux action loadNetworkUsers");
    dispatch({
      type: "NETWORKUSERS_LOADING",
    });

    console.log(
      "clientId",
      clientId,
      "page",
      page,
      "searchText",
      searchText,
      "filter",
      filter,
    );

    if (!!clientId) {
      let queryParams = "?perPage=" + USERS_PER_PAGE + "&pageNumber=" + page;
      if (!!clientId) {
        queryParams = queryParams + "&clientId=" + clientId;
      }
      if (searchText.trim() !== "") {
        queryParams = queryParams + "&searchText=" + searchText.trim();
      }
      if (filter.trim() !== "") {
        queryParams = queryParams + "&filter=" + filter.trim();
      }
      axios
        .get(API_URL + "/admin/user/getUsers" + queryParams)
        .then((res) =>
          dispatch({
            type: "NETWORKUSERS_LOADED",
            payload: res.data,
          }),
        )
        .catch((err) => {
          console.log("err", err);
        });
      console.log("redux action loadNetworkUsers" + queryParams);
    }
  };
