import React from "react";
import update from "react-addons-update";
import { forgotPassword } from "../../redux/action/login.action";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import { SIDED_FAVICON, SIDED_LOGO } from "../../constants/Globals";

export class ForgotPassword extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      formData: {
        field: "",
      },
      validationErrors: [
        {
          fieldErr: "",
        },
      ],
      successMsg: "",
      loaded: false,
    };
  }

  handleChange = async (e) => {
    const { name, value } = e.target;
    await this.setState({
      formData: { [name]: value },
    });
  };

  formValidation = async (e) => {
    e.preventDefault();
    const { field } = this.state.formData;
    if (field.trim() === "") {
      await this.setState(
        update(this.state, {
          validationErrors: {
            fieldErr: {
              $set: "Please enter your email or username.",
            },
          },
        }),
      );
    } else {
      await this.setState(
        update(this.state, {
          validationErrors: {
            fieldErr: {
              $set: "",
            },
          },
        }),
      );
    }
    const { fieldErr } = this.state.validationErrors;
    if (fieldErr === "") {
      this.handleSubmit(e);
    }
  };

  handleSubmit = (e) => {
    this.props.forgotPassword(this.state.formData);
  };

  componentDidMount() {
    this.setState({
      loaded: true,
    });
  }

  render() {
    const { field } = this.state.formData;
    const { fieldErr } = this.state.validationErrors;
    return (
      <div className={`login-div text-center loaded-${this.state.loaded}`}>
        <div className="login-container">
          <div className="logo-section">
            <img className="authTopFav" src={SIDED_FAVICON} alt="Sided" />
            <img className="authTopLogo" src={SIDED_LOGO} alt="Sided" />
          </div>
          <h4>Password Reset</h4>
          <p>
            Enter your username or email and we'll send
            <br />
            you a link to get back into your account.
          </p>
          <form onSubmit={this.formValidation}>
            <fieldset>
              <input
                type="text"
                placeholder="Email or Username"
                name="field"
                onChange={this.handleChange}
                value={field}
                className="form-control"
              />
              <i class="fa fa-user"></i>
            </fieldset>
            <button className="btn btn-primary">Send</button>
            <NavLink className="mt-3" exact to="/">
              Cancel
            </NavLink>

            <div className="validation-error">
              {fieldErr ? fieldErr : this.props.forgotPasswordErr}
            </div>
          </form>
        </div>
        {this.props.forgotPasswordSuc ? (
          <div className="sucessMsg">{this.props.forgotPasswordSuc}</div>
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  forgotPasswordErr: state.login.forgotPasswordErr,
  forgotPasswordSuc: state.login.forgotPasswordSuc,
});

export default connect(mapStateToProps, { forgotPassword })(ForgotPassword);
