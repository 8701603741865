import axios from "axios";
import { connect } from "react-redux";
import { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { ReactSortable } from "react-sortablejs";
import useAlertMessage from "../../hooks/useAlertMessage";
import { capitalizeFirstLetter } from "../../helpers/String";
import { API_URL } from "../../constants/Globals";
import { BarsIcon } from "../../icons";

function NewForm({ selNetworkId, match }) {
  const { params } = match;
  const history = useHistory();
  const [activeTab, setActiveTab] = useState(0);
  const [loading, setLoading] = useState(false);
  const [dataFetched, setDataFetched] = useState(false);
  const [formName, setFormName] = useState("");
  const [formTitle, setFormTitle] = useState("");
  const [formFields, setFormFields] = useState([]);
  const [formDescription, setFormDescription] = useState("");
  const [formButtonText, setFormButtonText] = useState("");
  const [formRedirectLink, setFormRedirectLink] = useState("");
  const [formEntries, setFormEntries] = useState([]);
  const [formImage, setFormImage] = useState(null);
  const [notificationEmail, setNotificationEmail] = useState("");
  const [setAlertMessage, getAlertMessage] = useAlertMessage();
  const fieldTypes = ["name", "email", "phone", "custom"];

  useEffect(() => {
    if (params.id === "new") {
      setFormName("");
      setFormTitle("");
      setFormDescription("");
      setFormButtonText("");
      setFormRedirectLink("");
      setFormFields([]);
      setDataFetched(false);
    } else if (!dataFetched) {
      setDataFetched(true);

      axios.get(`${API_URL}/forms/${params.id}`).then(({ data }) => {
        const {
          name,
          title,
          description,
          buttonText,
          redirectLink,
          fields,
          image,
          notificationEmail,
        } = data.data;

        setFormName(name);
        setFormTitle(title);
        setFormDescription(description);
        setFormButtonText(buttonText);
        setFormRedirectLink(redirectLink);
        setNotificationEmail(notificationEmail);
        setFormFields(fields);

        if (image) {
          setFormImage(`https://cdn.sided.co/prod/sided/forms/${image}-small`);
        }
      });

      axios
        .get(`${API_URL}/forms/${params.id}/form-entries`)
        .then(({ data }) => setFormEntries(data.data));
    }
  }, [params, dataFetched]);

  return (
    <section>
      {getAlertMessage()}

      <div className="table-filters">
        <Link to="/forms" className="text-button">
          <svg
            width="9"
            height="14"
            viewBox="0 0 9 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M8.56 11.8135L6.68 13.6668L0 7.00016L6.68 0.333496L8.56 2.18683L3.81333 7.00016L8.56 11.8135Z"
              fill="#2F80ED"
            />
          </svg>
          All Forms
        </Link>
      </div>

      <h1 className="page-title">
        {params.id === "new" ? "Add New Form" : `Edit Form: ${formName}`}
      </h1>

      <div className="form-tabs">
        <div
          onClick={() => setActiveTab(0)}
          className={activeTab === 0 ? "active" : ""}
        >
          Form Details
        </div>
        <div
          onClick={() => setActiveTab(1)}
          className={activeTab === 1 ? "active" : ""}
        >
          Entries
        </div>
      </div>

      <div className="table-action-button">
        <span
          className="text-button"
          onClick={async () => {
            await axios({
              method: "GET",
              url: `${API_URL}/forms/${params.id}/form-entries-csv`,
              responseType: "blob",
            }).then((response) => {
              const blob = new Blob([response.data], { type: "text/csv" });
              const downloadUrl = window.URL.createObjectURL(blob);
              const link = document.createElement("a");
              link.href = downloadUrl;
              link.setAttribute("download", "form-entries.csv");
              document.body.appendChild(link);
              link.click();
              link.remove();
            });
          }}
        >
          <svg
            width="17"
            height="17"
            viewBox="0 0 17 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clipPath="url(#clip0_20787_2891)">
              <path
                d="M9.29688 0.796875C9.29688 0.355273 8.9416 0 8.5 0C8.0584 0 7.70312 0.355273 7.70312 0.796875V9.76504L4.54883 6.61074C4.23672 6.29863 3.73203 6.29863 3.42324 6.61074C3.11445 6.92285 3.11113 7.42754 3.42324 7.73633L7.93555 12.252C8.24766 12.5641 8.75234 12.5641 9.06113 12.252L13.5801 7.73633C13.8922 7.42422 13.8922 6.91953 13.5801 6.61074C13.268 6.30195 12.7633 6.29863 12.4545 6.61074L9.3002 9.76504V0.796875H9.29688ZM4.27656 10.0938H2.125C0.95293 10.0938 0 11.0467 0 12.2188V14.875C0 16.0471 0.95293 17 2.125 17H14.875C16.0471 17 17 16.0471 17 14.875V12.2188C17 11.0467 16.0471 10.0938 14.875 10.0938H12.7234L11.1297 11.6875H14.875C15.1672 11.6875 15.4062 11.9266 15.4062 12.2188V14.875C15.4062 15.1672 15.1672 15.4062 14.875 15.4062H2.125C1.83281 15.4062 1.59375 15.1672 1.59375 14.875V12.2188C1.59375 11.9266 1.83281 11.6875 2.125 11.6875H5.87031L4.27656 10.0938ZM14.3438 13.5469C14.3438 13.3355 14.2598 13.1328 14.1104 12.9834C13.9609 12.834 13.7582 12.75 13.5469 12.75C13.3355 12.75 13.1328 12.834 12.9834 12.9834C12.834 13.1328 12.75 13.3355 12.75 13.5469C12.75 13.7582 12.834 13.9609 12.9834 14.1104C13.1328 14.2598 13.3355 14.3438 13.5469 14.3438C13.7582 14.3438 13.9609 14.2598 14.1104 14.1104C14.2598 13.9609 14.3438 13.7582 14.3438 13.5469Z"
                fill="#2F80ED"
              />
            </g>
            <defs>
              <clipPath id="clip0_20787_2891">
                <rect width="17" height="17" fill="white" />
              </clipPath>
            </defs>
          </svg>
          Download Entries
        </span>
      </div>

      <div className="segment-form after-tabs">
        <div className="segment-form-body padding-s">
          {activeTab === 0 ? (
            <>
              <div className="segment-form-field">
                <label className="type-2">Form Name</label>
                <input
                  type="text"
                  value={formName}
                  className="full-width"
                  placeholder="Enter name here"
                  onChange={({ target }) => setFormName(target.value)}
                />
              </div>

              <div className="segment-form-field">
                <label className="type-2">
                  Form Title
                  <span className="info-note">Shows on the embedded form</span>
                </label>
                <input
                  type="text"
                  value={formTitle}
                  className="full-width"
                  placeholder="Enter title here"
                  onChange={({ target }) => setFormTitle(target.value)}
                />
              </div>

              <div className="segment-form-field">
                <label className="type-2">Form Description</label>
                <input
                  type="text"
                  value={formDescription}
                  className="full-width"
                  placeholder="Enter description here"
                  onChange={({ target }) => setFormDescription(target.value)}
                />
              </div>

              <div className="segment-form-field">
                <label className="type-2">
                  Image
                  <span className="info-note">Optional</span>
                </label>
                <div className="segment-form-image">
                  {formImage ? (
                    <figure>
                      <img src={formImage} alt="Form graphic" />
                    </figure>
                  ) : null}

                  <div className="segment-form-picker">
                    {formImage ? (
                      <span className="text-button">Replace Image</span>
                    ) : (
                      <span className="blue-button bordered inline">
                        <svg
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M17.5 3.125C17.8438 3.125 18.125 3.40625 18.125 3.75V16.2422L17.9297 15.9883L12.6172 9.11328C12.4414 8.88281 12.1641 8.75 11.875 8.75C11.5859 8.75 11.3125 8.88281 11.1328 9.11328L7.89062 13.3086L6.69922 11.6406C6.52344 11.3945 6.24219 11.25 5.9375 11.25C5.63281 11.25 5.35156 11.3945 5.17578 11.6445L2.05078 16.0195L1.875 16.2617V16.25V3.75C1.875 3.40625 2.15625 3.125 2.5 3.125H17.5ZM2.5 1.25C1.12109 1.25 0 2.37109 0 3.75V16.25C0 17.6289 1.12109 18.75 2.5 18.75H17.5C18.8789 18.75 20 17.6289 20 16.25V3.75C20 2.37109 18.8789 1.25 17.5 1.25H2.5ZM5.625 8.75C5.87123 8.75 6.11505 8.7015 6.34253 8.60727C6.57002 8.51305 6.77672 8.37494 6.95083 8.20083C7.12494 8.02672 7.26305 7.82002 7.35727 7.59253C7.4515 7.36505 7.5 7.12123 7.5 6.875C7.5 6.62877 7.4515 6.38495 7.35727 6.15747C7.26305 5.92998 7.12494 5.72328 6.95083 5.54917C6.77672 5.37506 6.57002 5.23695 6.34253 5.14273C6.11505 5.0485 5.87123 5 5.625 5C5.37877 5 5.13495 5.0485 4.90747 5.14273C4.67998 5.23695 4.47328 5.37506 4.29917 5.54917C4.12506 5.72328 3.98695 5.92998 3.89273 6.15747C3.7985 6.38495 3.75 6.62877 3.75 6.875C3.75 7.12123 3.7985 7.36505 3.89273 7.59253C3.98695 7.82002 4.12506 8.02672 4.29917 8.20083C4.47328 8.37494 4.67998 8.51305 4.90747 8.60727C5.13495 8.7015 5.37877 8.75 5.625 8.75Z"
                            fill="#2F80ED"
                          />
                        </svg>
                        Upload
                      </span>
                    )}

                    <input
                      type="file"
                      accept="image/*"
                      onChange={({ target }) => {
                        if (target.value && target.files[0]) {
                          const reader = new FileReader();
                          reader.onloadend = () => {
                            setFormImage(reader.result);
                          };
                          reader.readAsDataURL(target.files[0]);
                        }
                      }}
                    />
                  </div>
                </div>
              </div>

              <div className="segment-form-field">
                <label className="type-2">Form Fields</label>
                <div className="inner-dropdown">
                  <svg
                    className="dropdown-arrow"
                    width="8"
                    height="5"
                    viewBox="0 0 8 5"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M0.94 0L4 3.06L7.06 0L8 0.946667L4 4.94667L0 0.946667L0.94 0Z"
                      fill="#4F4F4F"
                    />
                  </svg>
                  <input
                    type="text"
                    readOnly={true}
                    className="full-width"
                    placeholder="Select a form field"
                    onFocus={({ target }) =>
                      (target.parentNode.querySelector(
                        ".auto-complete",
                      ).style.display = "block")
                    }
                    onBlur={({ target }) =>
                      setTimeout(
                        () =>
                          (target.parentNode.querySelector(
                            ".auto-complete",
                          ).style.display = "none"),
                        250,
                      )
                    }
                  />
                  <div className="auto-complete" style={{ display: "none" }}>
                    {fieldTypes.map((fieldType) => (
                      <div key={fieldType}>
                        <div className="is-capitalized">{fieldType}</div>
                        <div
                          className="text-button"
                          onClick={() =>
                            setFormFields(
                              formFields.concat([
                                {
                                  fieldType,
                                  label:
                                    fieldType.charAt(0).toUpperCase() +
                                    fieldType.slice(1),
                                  placeholderText: `Enter ${fieldType === "custom" ? `${fieldType} placeholder text` : `your ${fieldType}`}`,
                                  isRequired: true,
                                },
                              ]),
                            )
                          }
                        >
                          Add
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>

              {formFields.length ? (
                <div className="summary-table">
                  <div className="summary-table-row summary-table-head">
                    <div className="summary-cell summary-cell-w1 visibility-l">
                      #
                    </div>
                    <div className="summary-cell summary-cell-w3 visibility-l">
                      Field
                    </div>
                    <div className="summary-cell summary-cell-fluid visibility-l">
                      Placeholder text
                    </div>
                    <div className="summary-cell summary-cell-w2 visibility-l center-x">
                      Required
                    </div>
                    <div className="summary-cell summary-cell-w2 visibility-l"></div>
                    <div className="summary-cell summary-cell-w1 visibility-l"></div>
                  </div>

                  <ReactSortable list={formFields} setList={setFormFields}>
                    {formFields.map(
                      (
                        { fieldType, label, placeholderText, isRequired },
                        index,
                      ) => (
                        <div className="summary-table-row" key={index}>
                          <div className="summary-cell summary-cell-w1 visibility-l">
                            {index + 1}
                          </div>
                          <div className="summary-cell summary-cell-w3">
                            {label}
                          </div>
                          <div className="summary-cell summary-cell-fluid">
                            <input
                              type="text"
                              value={placeholderText}
                              onChange={({ target }) =>
                                setFormFields(
                                  [].concat(formFields).map((field, idx) => {
                                    return {
                                      ...field,
                                      placeholderText:
                                        idx === index
                                          ? target.value
                                          : field.placeholderText,
                                    };
                                  }),
                                )
                              }
                            />
                          </div>
                          <div className="summary-cell summary-cell-w2 center-x">
                            <input
                              type="checkbox"
                              checked={isRequired}
                              onChange={() => {}}
                              onClick={() =>
                                setFormFields(
                                  [].concat(formFields).map((field, idx) => {
                                    return {
                                      ...field,
                                      isRequired:
                                        idx === index
                                          ? !isRequired
                                          : field.isRequired,
                                    };
                                  }),
                                )
                              }
                            />
                          </div>
                          <div className="summary-cell summary-cell-w2">
                            <div
                              className="text-button"
                              onClick={() =>
                                setFormFields(
                                  []
                                    .concat(formFields)
                                    .filter((formField, idx) => idx !== index),
                                )
                              }
                            >
                              Remove
                            </div>
                          </div>
                          <div className="summary-cell summary-cell-w1 center-x">
                            <BarsIcon />
                          </div>
                        </div>
                      ),
                    )}
                  </ReactSortable>
                </div>
              ) : null}

              <div className="segment-form-field">
                <label className="type-2">Button Text</label>
                <input
                  type="text"
                  value={formButtonText}
                  className="full-width"
                  placeholder="Enter button text"
                  onChange={({ target }) => setFormButtonText(target.value)}
                />
              </div>

              <div className="segment-form-field mb-l">
                <label className="type-2">
                  Redirect Link
                  <span className="info-note">Optional</span>
                </label>
                <input
                  type="text"
                  value={formRedirectLink}
                  className="full-width"
                  placeholder="Enter link to redirect user to after submission"
                  onChange={({ target }) => setFormRedirectLink(target.value)}
                />
              </div>

              <div className="segment-form-field mb-l">
                <label className="type-2">Notification Email</label>
                <input
                  type="text"
                  value={notificationEmail}
                  className="full-width"
                  onChange={({ target }) => setNotificationEmail(target.value)}
                />
              </div>

              <div className="segment-form-buttons align-right">
                <Link to="/forms" className="text-button">
                  Cancel
                </Link>
                <button
                  className="blue-button"
                  onClick={async () => {
                    setLoading(true);

                    let image = undefined;
                    const imageInput = document.querySelector(
                      ".segment-form-image input[type=file]",
                    );

                    if (imageInput && imageInput.value) {
                      const formData = new FormData();
                      formData.append(
                        "backgroundImage",
                        imageInput.files[0],
                        imageInput.files[0].name,
                      );

                      const imageData = await axios.post(
                        `${API_URL}/forms/imageUpload?type=FORMS&clientId=${selNetworkId}`,
                        formData,
                      );

                      if (imageData && imageData.data && imageData.data.data) {
                        image = imageData.data.data;
                      }
                    }

                    axios({
                      method: params.id === "new" ? "POST" : "PUT",
                      url: `${API_URL}/forms${params.id !== "new" ? `/${params.id}` : ""}`,
                      data: {
                        clientId: parseInt(selNetworkId),
                        name: formName,
                        title: formTitle,
                        description: formDescription,
                        buttonText: formButtonText,
                        redirectLink: formRedirectLink,
                        notificationEmail,
                        formFields,
                        image,
                      },
                    })
                      .then(({ data }) => {
                        setLoading(false);
                        setAlertMessage(data.message);
                        history.push(`/forms/${data.data.id}`);
                      })
                      .catch(({ response }) => {
                        const { data } = response;
                        const { message } = data;

                        setLoading(false);
                        setAlertMessage(
                          message.map((error) => error.message).join("\n"),
                        );
                      });
                  }}
                  disabled={loading}
                >
                  Save Form
                </button>
              </div>
            </>
          ) : (
            <table cellPadding="0" cellSpacing="0" className="summary-table">
              <thead>
                <tr className="summary-table-row-tr summary-table-head">
                  {formFields.map(({ fieldType }, index) => (
                    <td
                      key={`${fieldType}_${index}`}
                      className="summary-cell visibility-l"
                    >
                      {capitalizeFirstLetter(fieldType)}
                    </td>
                  ))}
                </tr>
              </thead>
              <tbody>
                {formEntries.map((formEntry) => (
                  <tr key={formEntry.id} className="summary-table-row-tr">
                    {formEntry.entryData.map((entry) => (
                      <td
                        key={`$formEntry.id}_${entry.id}`}
                        className="summary-cell"
                      >
                        {entry.value}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      </div>
    </section>
  );
}

const mapStateToProps = (state) => ({
  selNetworkId: state.networks.selNetworkId,
});

export default connect(mapStateToProps)(NewForm);
