import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import axios from "axios";
import {
  API_URL,
  DEFAULT_USER_IMG_URL,
  DEFAULT_USER_COVER_URL,
  MAIN_WEBSITE_URL,
} from "../../../constants/Globals";

class ReportedUser extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      reportedContentData: {},
    };
  }

  fetchReportedContentData = async (e) => {
    await axios
      .get(API_URL + "/user/" + this.props.reportableId + "?showDeleted=1")
      .then((res) => {
        this.setState({ reportedContentData: res.data.data });
      })
      .catch((err) => {
        console.log("Axios Error for userId " + this.props.reportableId);
        console.log(err);
      });
  };

  componentDidMount = () => {
    this.fetchReportedContentData();
  };

  render() {
    const { reportedContentData } = this.state;

    return (
      <div className="reprted-profile">
        {reportedContentData ? (
          <>
            <div className="user-profile-cover">
              <img
                onError={(e) => {
                  e.target.src = DEFAULT_USER_COVER_URL;
                }}
                src={
                  reportedContentData["coverImageObject"]
                    ? reportedContentData["coverImageObject"]["small"][
                        "location"
                      ]
                    : null
                }
                alt="User profile cover"
              />
            </div>
            <div className="d-md-flex">
              <div className="me-2 ms-4 reprted-profile-author-wrapper">
                <div className="reprted-profile-author">
                  <a
                    target="_blank"
                    rel="noreferrer"
                    className="small-dp user-color-green"
                    href={`${MAIN_WEBSITE_URL}/${reportedContentData.username}`}
                    tabindex="0"
                  >
                    <img
                      src={
                        reportedContentData["avatarObject"]
                          ? reportedContentData["avatarObject"]["small"][
                              "location"
                            ]
                          : null
                      }
                      className="img-circle avatar"
                      alt="User avatar"
                      onError={(e) => {
                        e.target.src = DEFAULT_USER_IMG_URL;
                      }}
                    />
                    {reportedContentData.roles &&
                    reportedContentData.roles.length > 0 &&
                    reportedContentData.roles.find(
                      (role) => role.name === "VERIFIED",
                    ) ? (
                      <FontAwesomeIcon
                        style={{ color: reportedContentData.checkmarkColor }}
                        icon={faCheckCircle}
                        className="procheckmark"
                      />
                    ) : null}
                  </a>
                  <h6 className="ms-2">
                    {reportedContentData.name}
                    <small>
                      <a
                        href={`${MAIN_WEBSITE_URL}/${reportedContentData.username}`}
                        target="_blank"
                        rel="noreferrer"
                        className="username"
                      >
                        @{reportedContentData.username}
                      </a>
                    </small>
                  </h6>
                </div>
              </div>
              <div className="ms-4 ms-md-5">
                <div className="user-profile-info">
                  <span className="bolder d-block">
                    <b>{reportedContentData.followersCount}</b> Followers
                    <b className="ms-3">
                      {reportedContentData.followingCount}
                    </b>{" "}
                    Following
                  </span>
                  {reportedContentData.bio &&
                  reportedContentData.bio.length > 120 ? (
                    <div>
                      {reportedContentData.bio.substring(0, 120)} ...{" "}
                      <span className="customAnchor d-inline">read more</span>
                    </div>
                  ) : (
                    reportedContentData.bio
                  )}
                </div>
              </div>
            </div>
          </>
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  networks: state.networks.networks,
  loggedInUser: state.login.user,
  selNetworkId: state.networks.selNetworkId,
});

const Extended = withTranslation()(ReportedUser);
Extended.static = ReportedUser.static;

export default connect(mapStateToProps, {})(Extended);
