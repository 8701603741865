export async function readApiKey() {
  return await localStorage.getItem("apiKey");
}

export async function readApiKeyDate() {
  return await localStorage.getItem("apiKeyExpireAt");
}

export async function storeApiKey(token, tokenDate) {
  console.log("func helper session storeApiKey");
  try {
    await localStorage.setItem("apiKey", token);
    await localStorage.setItem("apiKeyExpireAt", tokenDate);

    return true;
  } catch (error) {
    // Error saving data
  }
  return false;
}

export async function deleteApiKey() {
  console.log("func helper session deleteApiKey");
  await localStorage.removeItem("apiKey");
  await localStorage.removeItem("apiKeyExpireAt");

  return true;
}

export async function readUserId() {
  return await localStorage.getItem("userId");
}

export async function readUserToken() {
  return await localStorage.getItem("token");
}

export async function readUserTokenDate() {
  return await localStorage.getItem("tokenExpireAt");
}

export async function readUserRefreshToken() {
  return await localStorage.getItem("refreshToken");
}

export async function readUserRefreshTokenDate() {
  return await localStorage.getItem("refreshTokenExpireAt");
}

export async function storeUserId(
  id,
  token,
  tokenDate,
  refreshToken,
  refreshTokenDate,
) {
  console.log("func helper session storeUserId");
  try {
    await localStorage.setItem("userId", id);
    await localStorage.setItem("token", token);
    await localStorage.setItem("tokenExpireAt", tokenDate);
    await localStorage.setItem("refreshToken", refreshToken);
    await localStorage.setItem("refreshTokenExpireAt", refreshTokenDate);

    return true;
  } catch (error) {
    // Error saving data
  }
  return false;
}

export async function deleteUserId() {
  console.log("func helper session deleteUserId");
  await localStorage.removeItem("userId");
  await localStorage.removeItem("token");
  await localStorage.removeItem("tokenExpireAt");
  await localStorage.removeItem("refreshToken");
  await localStorage.removeItem("refreshTokenExpireAt");

  return true;
}
