import axios from "axios";
import { connect } from "react-redux";
import { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { API_URL } from "../../constants/Globals";

function NewSegment({ selNetworkId, match }) {
  const [tags, setTags] = useState([]);
  const [andOr, setAndOr] = useState("and");
  const [loading, setLoading] = useState(false);
  const [conditions, setConditions] = useState([]);
  const [segmentName, setSegmentName] = useState("");
  const { params } = match;
  const history = useHistory();

  useEffect(() => {
    if (selNetworkId) {
      axios
        .get(`${API_URL}/admin/tags?clientId=${selNetworkId}`)
        .then(({ data }) => setTags(data.data));

      if (params.id && params.id !== "new") {
        axios
          .get(`${API_URL}/admin/segments?clientId=${selNetworkId}`)
          .then(({ data }) => {
            const editedSegment = data.data.find(
              (segment) => segment.id === parseInt(params.id),
            );

            if (editedSegment) {
              setSegmentName(editedSegment.name);

              const newConditions = [];

              for (let key in editedSegment.conditions) {
                const condition = editedSegment.conditions[key];

                if (key === "andOr" && condition !== "and") {
                  setAndOr("or");
                } else if (key === "dateAdded") {
                  const newCondition = {
                    type: "date",
                    comparator: condition[0] === "lt" ? "before" : "after",
                    selectedDate: condition[1],
                    selectedTag: 0,
                    amountComparator: "any",
                    tagsCount: 2,
                    votesCount: 10,
                  };

                  newConditions.push(newCondition);
                } else if (key === "votes") {
                  const newCondition = {
                    type: "votes",
                    comparator: condition[0] === "lt" ? "smaller" : "greater",
                    selectedDate: "",
                    selectedTag: 0,
                    amountComparator: "any",
                    tagsCount: 2,
                    votesCount: condition[1],
                  };

                  newConditions.push(newCondition);
                } else if (key === "tags") {
                  condition.forEach((tagCondition) => {
                    const newCondition = {
                      type: "tags",
                      comparator:
                        tagCondition.is === "y" ? "tagged" : "untagged",
                      selectedDate: "",
                      selectedTag: tagCondition.id,
                      amountComparator:
                        tagCondition.compare === "gt"
                          ? "greater"
                          : tagCondition.compare === "lt"
                            ? "less"
                            : tagCondition.compare,
                      tagsCount: tagCondition.count,
                      votesCount: 10,
                    };

                    newConditions.push(newCondition);
                  });
                }
              }

              setConditions(newConditions);
            }
          });
      }
    }
  }, [selNetworkId, params]);

  return (
    <section>
      <h1 className="page-title uppercase">Audience</h1>

      <div className="table-filters">
        <Link to="/audience/segments" className="text-button">
          <svg
            width="9"
            height="14"
            viewBox="0 0 9 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M8.56 11.8135L6.68 13.6668L0 7.00016L6.68 0.333496L8.56 2.18683L3.81333 7.00016L8.56 11.8135Z"
              fill="#2F80ED"
            />
          </svg>
          Back to All Segments
        </Link>
      </div>

      <div className="segment-form">
        <header>
          <div className="blue-button">Create a segment</div>
          <button className="blue-button">
            <svg
              width="12"
              height="17"
              viewBox="0 0 12 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6 0L3 3H5.25V9.75H6.75V3H9M10.5 16.5H1.5C0.6675 16.5 0 15.825 0 15V6C0 5.60218 0.158035 5.22064 0.43934 4.93934C0.720644 4.65804 1.10218 4.5 1.5 4.5H3.75V6H1.5V15H10.5V6H8.25V4.5H10.5C10.8978 4.5 11.2794 4.65804 11.5607 4.93934C11.842 5.22064 12 5.60218 12 6V15C12 15.3978 11.842 15.7794 11.5607 16.0607C11.2794 16.342 10.8978 16.5 10.5 16.5Z"
                fill="white"
              />
            </svg>
            &nbsp; Export Audience
          </button>
          <Link to="/audience/segments" className="blue-button">
            Cancel
          </Link>
        </header>

        <div className="segment-form-body">
          <div className="segment-form-field">
            <label>Name</label>
            <input
              type="text"
              value={segmentName}
              onChange={({ target }) => setSegmentName(target.value)}
            />
          </div>
          <div className="segment-form-info">
            <div>
              <label>Contacts match</label>
              <select
                className="select-box"
                value={andOr}
                onChange={({ target }) => setAndOr(target.value)}
              >
                <option value="and">All</option>
                <option value="or">Any</option>
              </select>
              <label>of the following conditions:</label>
            </div>
            <div>Users in this Segment: 0</div>
          </div>

          {conditions.map((condition, index) => (
            <div className="segment-form-condition" key={index}>
              <div className="segment-form-match">{andOr.toUpperCase()}</div>

              <div className="d-flex flex-wrap align-items-center">
                <select
                  className="select-box"
                  value={condition.type}
                  onChange={({ target }) => {
                    const currentConditions = [].concat(conditions);
                    const { value } = target;
                    currentConditions[index].type = value;
                    currentConditions[index].comparator =
                      value === "votes"
                        ? "greater"
                        : value === "tags"
                          ? "tagged"
                          : "before";
                    setConditions(currentConditions);
                  }}
                >
                  <option value="date">Date added</option>
                  <option value="tags">Tags</option>
                  <option value="votes">Vote count</option>
                </select>

                <select
                  className="select-box"
                  value={condition.comparator}
                  onChange={({ target }) => {
                    const currentConditions = [].concat(conditions);
                    currentConditions[index].comparator = target.value;
                    setConditions(currentConditions);
                  }}
                >
                  {condition.type === "date" ? (
                    <option value="before">is before</option>
                  ) : null}
                  {condition.type === "date" ? (
                    <option value="after">is after</option>
                  ) : null}
                  {condition.type === "tags" ? (
                    <option value="tagged">contact is tagged</option>
                  ) : null}
                  {condition.type === "tags" ? (
                    <option value="untagged">contact is not tagged</option>
                  ) : null}
                  {condition.type === "votes" ? (
                    <option value="greater">greater than</option>
                  ) : null}
                  {condition.type === "votes" ? (
                    <option value="smaller">less than</option>
                  ) : null}
                </select>

                {condition.type === "date" ? (
                  <input
                    className="input-box"
                    type="date"
                    value={
                      condition.selectedDate
                        ? condition.selectedDate
                        : "dd-mm-yyyy"
                    }
                    onChange={({ target }) => {
                      const currentConditions = [].concat(conditions);
                      currentConditions[index].selectedDate = target.value;
                      setConditions(currentConditions);
                    }}
                  />
                ) : null}

                {condition.type === "tags" ? (
                  <>
                    <select
                      className="select-box"
                      value={condition.selectedTag}
                      onChange={({ target }) => {
                        const currentConditions = [].concat(conditions);
                        currentConditions[index].selectedTag = target.value;
                        setConditions(currentConditions);
                      }}
                    >
                      <option value="0">-</option>
                      {tags.map(({ id, text }) => (
                        <option key={id} value={id}>
                          {text}
                        </option>
                      ))}
                    </select>

                    {condition.comparator === "tagged" &&
                    condition.selectedTag > 0 ? (
                      <>
                        <select
                          className="select-box"
                          value={condition.amountComparator}
                          onChange={({ target }) => {
                            const currentConditions = [].concat(conditions);
                            currentConditions[index].amountComparator =
                              target.value;
                            setConditions(currentConditions);
                          }}
                        >
                          <option value="any">any amount</option>
                          <option value="greater">greater than</option>
                          <option value="less">less than</option>
                          <option value="equal">equal to</option>
                        </select>
                        {condition.amountComparator === "any" ? null : (
                          <select
                            className="select-box"
                            value={condition.tagsCount}
                            onChange={({ target }) => {
                              const currentConditions = [].concat(conditions);
                              currentConditions[index].tagsCount = target.value;
                              setConditions(currentConditions);
                            }}
                          >
                            <option value="2">2 times</option>
                            <option value="5">5 times</option>
                            <option value="10">10 times</option>
                            <option value="15">15 times</option>
                            <option value="20">20 times</option>
                          </select>
                        )}
                      </>
                    ) : null}
                  </>
                ) : null}

                {condition.type === "votes" ? (
                  <select
                    className="select-box"
                    value={condition.votesCount}
                    onChange={({ target }) => {
                      const currentConditions = [].concat(conditions);
                      currentConditions[index].votesCount = target.value;
                      setConditions(currentConditions);
                    }}
                  >
                    <option>10</option>
                    <option>50</option>
                    <option>100</option>
                    <option>250</option>
                    <option>500</option>
                  </select>
                ) : null}

                {index > 0 ? (
                  <span
                    className="text-button underlined"
                    onClick={() => {
                      const currentConditions = [].concat(conditions);
                      currentConditions.splice(index, 1);
                      setConditions(currentConditions);
                    }}
                  >
                    Remove
                  </span>
                ) : null}
              </div>
            </div>
          ))}

          <div className="segment-form-plus">
            <button
              className="blue-button bordered"
              onClick={() => {
                const currentConditions = [].concat(conditions);
                currentConditions.push({
                  type: "date",
                  comparator: "before",
                  selectedDate: "",
                  selectedTag: 0,
                  amountComparator: "any",
                  tagsCount: 2,
                  votesCount: 10,
                });
                setConditions(currentConditions);
              }}
            >
              + Add Condition
            </button>
          </div>

          <div className="segment-form-buttons">
            <button
              className="blue-button"
              onClick={() => {
                setLoading(true);

                const conditionsPayload = {
                  andOr,
                  tags: [],
                };

                conditions.forEach((condition) => {
                  const {
                    type,
                    comparator,
                    selectedDate,
                    votesCount,
                    selectedTag,
                    tagsCount,
                    amountComparator,
                  } = condition;

                  if (type === "date") {
                    conditionsPayload.dateAdded = [
                      comparator === "before" ? "lt" : "gt",
                      selectedDate,
                    ];
                  } else if (type === "votes") {
                    conditionsPayload.votes = [
                      comparator === "smaller" ? "lt" : "gt",
                      votesCount,
                    ];
                  } else {
                    conditionsPayload.tags.push({
                      id: selectedTag,
                      is: comparator === "tagged" ? "y" : "n",
                      count: tagsCount,
                      compare:
                        amountComparator === "greater"
                          ? "gt"
                          : amountComparator === "less"
                            ? "lt"
                            : amountComparator,
                    });
                  }
                });

                axios
                  .post(
                    `${API_URL}/admin/segments/upsert${params.id && params.id !== "new" ? `/${params.id}` : ""}?clientId=${selNetworkId}`,
                    {
                      name: segmentName,
                      conditions: conditionsPayload,
                    },
                  )
                  .then(({ data }) => {
                    setLoading(false);
                    alert(data.message);
                    history.push("/audience/segments");
                  })
                  .catch(({ response }) => {
                    const { data } = response;
                    const { message } = data;
                    const error = message[0];

                    setLoading(false);
                    alert(error[Object.keys(error)[0]]);
                  });
              }}
              disabled={loading}
            >
              Save Segment
            </button>
            <Link to="/audience/segments" className="text-button underlined">
              Cancel
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
}

const mapStateToProps = (state) => ({
  selNetworkId: state.networks.selNetworkId,
});

export default connect(mapStateToProps)(NewSegment);
