import React from "react";
import Aside from "./Aside";
import Section from "./Section";
import { Container, Row, Col } from "react-bootstrap";

class Main extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      sideBar:
        window.innerWidth > 1200 || window.innerWidth < 768
          ? "expanded"
          : "collapsed",
    };
  }

  handleCallback = (childData) => {
    this.setState({
      sideBar: childData,
    });
  };

  render() {
    return (
      <main className={`body ${this.state.sideBar}`}>
        <Container>
          <Row>
            <Col sm={3} className="main-left-col">
              <Aside parentCallback={this.handleCallback} />
            </Col>
            <Col sm={9} className="main-right-col">
              <Section />
            </Col>
          </Row>
        </Container>
      </main>
    );
  }
}

export default Main;
