import React from "react";
import { Row, Col, Button, Form } from "react-bootstrap";
import axios from "axios";
import { connect } from "react-redux";
import { API_URL, BASENAME } from "../../constants/Globals";

class EditNetwork extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      notificationType: null,
      successMsg: null,
      loading: true,
      validationErrors: {},
      passwordValidationErrors: {},
      formData: {
        name: "",
        username: "",
        email: "",
        bio: "",
        website: "",
        phone: "",
        country: "",
        avatar: "Please enter user avatar Id received from imageUpload api.",
        avatarTempData: "",
        coverImage:
          "Please enter user coverImage Id received from imageUpload api.",
        coverImageTempData: "",
        location: "",
        notifications: 0,
        embedAds: 1,
        currentPassword: "",
        password: null,
        confirmationPassword: null,
      },
    };
  }

  changeHandler = async (e) => {
    var formData = { ...this.state.formData };
    formData[e.target.name] = e.target.value;
    await this.setState({ formData });
    this.profileFormValidation(e);
    this.passwordFormValidation(e);
  };

  messageNotification = async (type, message) => {
    this.setState({
      notificationType: type,
      successMsg: message,
    });

    setTimeout(() => {
      this.setState({
        successMsg: "",
      });
    }, 5000);
  };

  componentDidUpdate(prevProps, prevState) {}

  componentDidMount() {
    this.fetchUserData();
  }

  fetchUserData = async (e) => {
    var formData = { ...this.state.formData };
    formData["name"] = this.props.loggedInUser["name"];
    formData["username"] = this.props.loggedInUser["username"];
    formData["email"] = this.props.loggedInUser["email"];
    formData["bio"] = this.props.loggedInUser["bio"];
    formData["website"] = this.props.loggedInUser["website"];
    formData["phone"] = this.props.loggedInUser["phone"];
    formData["country"] = this.props.loggedInUser["country"];
    formData["avatar"] = this.props.loggedInUser["avatar"];
    this.setState({
      avatarDisplay:
        this.props.loggedInUser["avatarObject"] !== null
          ? this.props.loggedInUser["avatarObject"]["thumb"]["location"]
          : "",
    });
    formData["coverImage"] = this.props.loggedInUser["coverImage"];
    this.setState({
      coverImageDisplay:
        this.props.loggedInUser["coverImageObject"] !== null
          ? this.props.loggedInUser["coverImageObject"]["small"]["location"]
          : "",
    });
    formData["location"] = this.props.loggedInUser["location"];

    await this.setState({ formData });
    this.setState({
      loading: false,
    });
  };

  fileChangeHandler = async (e) => {
    if (e.target.type === "file" && e.target.files[0]) {
      const dataAccept = e.target.getAttribute("data-accept")
        ? e.target.getAttribute("data-accept")
        : "jpg|jpeg|gif|png";
      const dataAcceptMsg = e.target.getAttribute("data-accept_msg")
        ? e.target.getAttribute("data-accept_msg")
        : "Image is invalid. Please upload only PNG, JPG or GIF image.";
      var regex = new RegExp("(.*?).(" + dataAccept + ")$");
      if (!regex.test(e.target.files[0].type)) {
        alert(dataAcceptMsg);
        return false;
      }
      if (e.target.files[0].size > 1024 * 20 * 1024) {
        alert("Image size should not be greater than 20mb");
        return false;
      }
      var file = e.target.files[0];
      var thisEl = e;
      var reader = new FileReader();
      reader.onloadend = function (e) {
        var formData = { ...this.state.formData };
        var uploadedImageObj = {
          type: thisEl.target.dataset.imgtype,
          image: thisEl.target.files[0],
        };
        if (thisEl.target.name === "avatar") {
          formData.avatarTempData = uploadedImageObj;
          this.setState({ avatarDisplay: e.target.result });
        } else {
          formData.coverImageTempData = uploadedImageObj;
          this.setState({ coverImageDisplay: e.target.result });
        }

        formData[thisEl.target.name] = e.target.result;

        this.setState({ formData });
        //console.log(this.state);
      }.bind(this);
      reader.readAsDataURL(file);
    }
  };

  fileRemoveHandler = async (name) => {
    var formData = { ...this.state.formData };

    if (name === "avatar") {
      formData.avatarTempData = "";
      this.setState({ avatarDisplay: "" });
    } else {
      formData.coverImageTempData = "";
      this.setState({ coverImageDisplay: "" });
    }
    formData[name] = "";
    await this.setState({ formData });
  };

  profileFormValidation = async (e = "", action = "") => {
    var validationErrors = { ...this.state.validationErrors };
    if (e.target.name === "name" || action === "onSubmit") {
      if (this.state.formData.name === "") {
        validationErrors["name"] = "The name field is required.";
        await this.setState({ validationErrors: validationErrors });
      } else if (this.state.formData.name.length > 50) {
        validationErrors["name"] = "Name must be max 50 characters.";
        await this.setState({ validationErrors: validationErrors });
      } else {
        delete validationErrors["name"];
        await this.setState({ validationErrors: validationErrors });
      }
    }

    if (e.target.name === "username" || action === "onSubmit") {
      if (this.state.formData.username === "") {
        validationErrors["username"] = "The username field is required.";
        await this.setState({ validationErrors: validationErrors });
      } else if (this.state.formData.username.length < 3) {
        validationErrors["username"] =
          "Username must be at least 3 characters.";
        await this.setState({ validationErrors: validationErrors });
      } else if (this.state.formData.username.length > 20) {
        validationErrors["username"] =
          "Username must be between 3 to 20 characters.";
        await this.setState({ validationErrors: validationErrors });
      } else if (
        this.state.formData.username !== null &&
        this.state.formData.username !== "" &&
        !/^[a-zA-Z0-9_]+$/i.test(this.state.formData.username)
      ) {
        validationErrors["username"] = "Use only letter, number and _.";
        await this.setState({ validationErrors: validationErrors });
      } else {
        delete validationErrors["username"];
        await this.setState({ validationErrors: validationErrors });
      }
    }

    if (e.target.name === "email" || action === "onSubmit") {
      if (this.state.formData.email === "") {
        validationErrors["email"] = "The email field is required.";
        await this.setState({ validationErrors: validationErrors });
      } else if (
        this.state.formData.email !== null &&
        this.state.formData.email !== "" &&
        !/\S+@\S+\.\S+/.test(this.state.formData.email)
      ) {
        validationErrors["email"] = "The email must be a valid email address.";
        await this.setState({ validationErrors: validationErrors });
      } else {
        delete validationErrors["email"];
        await this.setState({ validationErrors: validationErrors });
      }
    }

    if (e.target.name === "website" || action === "onSubmit") {
      if (
        this.state.formData.website !== null &&
        this.state.formData.website !== "" &&
        !/^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-zA-Z0-9]+([-.]{1}[a-zA-Z0-9]+)*\.[a-zA-Z]{2,5}(:[0-9]{1,5})?(\/.*)?$/i.test(
          this.state.formData.website,
        )
      ) {
        validationErrors["website"] = "Please enter valid website URL.";
        await this.setState({ validationErrors: validationErrors });
      } else {
        delete validationErrors["website"];
        await this.setState({ validationErrors: validationErrors });
      }
    }

    if (e.target.name === "phone" || action === "onSubmit") {
      if (
        this.state.formData.phone !== null &&
        this.state.formData.phone !== "" &&
        !/^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/.test(
          this.state.formData.phone,
        )
      ) {
        validationErrors["phone"] = "Please enter valid Phone Number.";
        await this.setState({ validationErrors: validationErrors });
      } else {
        delete validationErrors["phone"];
        await this.setState({ validationErrors: validationErrors });
      }
    }

    if (e.target.name === "bio" || action === "onSubmit") {
      if (this.state.formData.bio.length > 140) {
        validationErrors["bio"] = "Bio must be between 1 to 140 characters.";
        await this.setState({ validationErrors: validationErrors });
      } else {
        delete validationErrors["bio"];
        await this.setState({ validationErrors: validationErrors });
      }
    }

    if (
      Object.keys(this.state.validationErrors).length === 0 &&
      action === "onSubmit"
    ) {
      this.uploadUserImages();
    } else {
      this.setState({
        loading: false,
      });
    }
  };
  passwordFormValidation = async (e = "", action = "") => {
    var passwordValidationErrors = { ...this.state.passwordValidationErrors };

    if (e.target.name === "currentPassword" || action === "onSubmit") {
      if (
        this.state.formData.currentPassword === null ||
        this.state.formData.currentPassword === ""
      ) {
        passwordValidationErrors["currentPassword"] = "This field is required.";
        await this.setState({
          passwordValidationErrors: passwordValidationErrors,
        });
      } else if (this.state.formData.currentPassword.length < 6) {
        passwordValidationErrors["currentPassword"] =
          "Password must be at least 6 characters long.";
        await this.setState({
          passwordValidationErrors: passwordValidationErrors,
        });
      } else {
        delete passwordValidationErrors["currentPassword"];
        await this.setState({
          passwordValidationErrors: passwordValidationErrors,
        });
      }
    }

    if (e.target.name === "password" || action === "onSubmit") {
      if (this.state.formData.password === null) {
        passwordValidationErrors["password"] = "This field is required.";
        await this.setState({
          passwordValidationErrors: passwordValidationErrors,
        });
      } else if (
        this.state.formData.password !== null &&
        this.state.formData.password.length < 6
      ) {
        passwordValidationErrors["password"] =
          "Password must be at least 6 characters long.";
        await this.setState({
          passwordValidationErrors: passwordValidationErrors,
        });
      } else {
        delete passwordValidationErrors["password"];
        await this.setState({
          passwordValidationErrors: passwordValidationErrors,
        });
      }
    }

    if (e.target.name === "confirmationPassword" || action === "onSubmit") {
      if (this.state.formData.confirmationPassword === null) {
        passwordValidationErrors["confirmationPassword"] =
          "This field is required.";
        await this.setState({
          passwordValidationErrors: passwordValidationErrors,
        });
      } else if (
        this.state.formData.confirmationPassword !== null &&
        this.state.formData.confirmationPassword.length < 6
      ) {
        passwordValidationErrors["confirmationPassword"] =
          "Password must be at least 6 characters long.";
        await this.setState({
          passwordValidationErrors: passwordValidationErrors,
        });
      } else if (
        this.state.formData.confirmationPassword !== null &&
        this.state.formData.confirmationPassword !==
          this.state.formData.password
      ) {
        passwordValidationErrors["confirmationPassword"] =
          "Please enter the same password as above.";
        await this.setState({
          passwordValidationErrors: passwordValidationErrors,
        });
      } else {
        delete passwordValidationErrors["confirmationPassword"];
        await this.setState({
          passwordValidationErrors: passwordValidationErrors,
        });
      }
    }

    if (
      Object.keys(this.state.passwordValidationErrors).length === 0 &&
      action === "onSubmit"
    ) {
      this.passwordFormDataHandle();
    } else {
      this.setState({
        loading: false,
      });
    }
  };

  uploadUserImages = async (e) => {
    let clientId = this.props.selNetworkId;
    const { avatarTempData, coverImageTempData } = this.state.formData;
    let failure = false;
    var imagesArr = [];

    if (avatarTempData !== "") {
      const formData = new FormData();
      formData.append(
        "backgroundImage",
        avatarTempData.image,
        avatarTempData.image.name,
      );
      imagesArr.push({ image_type: avatarTempData.type, formData: formData });
    }

    if (coverImageTempData !== "") {
      const formData = new FormData();
      formData.append(
        "backgroundImage",
        coverImageTempData.image,
        coverImageTempData.image.name,
      );
      imagesArr.push({
        image_type: coverImageTempData.type,
        formData: formData,
      });
    }

    for (let i = 0; i < imagesArr.length; i++) {
      await axios
        .post(
          API_URL +
            "/debate/imageUpload?type=" +
            imagesArr[i].image_type +
            "&clientId=" +
            clientId,
          imagesArr[i].formData,
        )
        .then((res) => {
          var formData = { ...this.state.formData };

          if (imagesArr[i].image_type === "USERS") {
            formData.avatar = res.data.data;
          } else {
            formData.coverImage = res.data.data;
          }
          this.setState({ formData });
        });
    }

    if (failure === false) {
      this.profileFormDataHandle();
    }
  };

  profileFormDataHandle = async (e) => {
    var formDataBody = { ...this.state.formData };
    await axios
      .post(API_URL + "/userSetting/postSettings/", formDataBody)
      .then((res) => {
        this.messageNotification("success", res.data.message);
        window.location.reload();
      })
      .catch((err) => {
        if (err.response !== undefined) {
          console.log(err.response.data.message[0].error);
          alert(err.response.data.message[0].error);
        }
        this.setState({
          loading: false,
        });
      });
  };

  passwordFormDataHandle = async (e) => {
    var formDataBody = { ...this.state.formData };
    await axios
      .post(API_URL + "/user/updatePassword", formDataBody)
      .then((res) => {
        this.messageNotification("success", res.data.message);
        window.location.reload();
      })
      .catch((err) => {
        if (err.response !== undefined) {
          console.log(err.response.data.message);
          alert(err.response.data.message[0].currentPassword);
        }
        this.setState({
          loading: false,
        });
      });
  };

  submitProfileDataHandle = async (e) => {
    await this.setState({
      loading: true,
    });
    this.profileFormValidation(e, "onSubmit");
    e.preventDefault();
  };

  submitChangePasswordDataHandle = async (e) => {
    await this.setState({
      loading: true,
    });
    this.passwordFormValidation(e, "onSubmit");
    e.preventDefault();
  };

  deleteUserAccount = async (e) => {
    if (!window.confirm("Are you sure you want to delete this account?")) {
      return false;
    }

    var formDataBody = { userId: this.props.loggedInUser["id"] };
    await axios
      .post(API_URL + "/user/deleteAccount", formDataBody)
      .then((res) => {
        this.messageNotification("success", "Account deleted successfully");
        window.location.href = BASENAME + "/";
      })
      .catch((err) => {
        if (err.response !== undefined) {
          console.log(err.response.data.message[0].error);
          alert(err.response.data.message[0].error);
        }
        this.setState({
          loading: false,
        });
      });
  };

  cancelBtnClick = async (e) => {
    this.props.history.goBack();
  };

  render(props) {
    const { loading } = this.state;
    const {
      name,
      username,
      email,
      bio,
      website,
      phone,
      location,
      currentPassword,
      password,
      confirmationPassword,
    } = this.state.formData;
    const {
      avatarDisplay,
      coverImageDisplay,
      validationErrors,
      passwordValidationErrors,
    } = this.state;
    return (
      <section>
        {this.state.successMsg ? (
          <div className="sucessMsg">{this.state.successMsg}</div>
        ) : null}
        <h2 className="pb-1">My Profile</h2>
        <hr />
        <Form
          className="profileForm common-form row"
          onSubmit={this.submitProfileDataHandle}
        >
          <Row
            className={
              loading === true
                ? "loading justify-content-center"
                : "justify-content-center"
            }
          >
            <Col md={6} className="pe-3">
              <h3 className="pt-3 pb-3 lightColor">PERSONAL INFO</h3>
              <Form.Group controlId="name">
                <Form.Label>NAME</Form.Label>
                <Form.Control
                  type="text"
                  onChange={this.changeHandler}
                  name="name"
                  value={name}
                />
                {validationErrors && validationErrors.name !== undefined ? (
                  <span className="validation-error">
                    {validationErrors.name}
                  </span>
                ) : null}
              </Form.Group>
              <Form.Group controlId="username">
                <Form.Label>USERNAME</Form.Label>
                <Form.Control
                  type="text"
                  onChange={this.changeHandler}
                  name="username"
                  value={username}
                />
                {validationErrors && validationErrors.username !== undefined ? (
                  <span className="validation-error">
                    {validationErrors.username}
                  </span>
                ) : null}
              </Form.Group>
              <Form.Group controlId="email">
                <Form.Label>EMAIL</Form.Label>
                <Form.Control
                  type="text"
                  onChange={this.changeHandler}
                  name="email"
                  value={email}
                  placeholder="you@email.com"
                />
                {validationErrors && validationErrors.email !== undefined ? (
                  <span className="validation-error">
                    {validationErrors.email}
                  </span>
                ) : null}
              </Form.Group>
              <Form.Group controlId="phone">
                <Form.Label>PHONE</Form.Label>
                <Form.Control
                  type="text"
                  onChange={this.changeHandler}
                  name="phone"
                  value={phone}
                  placeholder="555-555-5555"
                />
                {validationErrors && validationErrors.phone !== undefined ? (
                  <span className="validation-error">
                    {validationErrors.phone}
                  </span>
                ) : null}
              </Form.Group>
              <Form.Group controlId="bio">
                <Form.Label>BIO</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  onChange={this.changeHandler}
                  name="bio"
                  value={bio}
                />
                {validationErrors && validationErrors.bio !== undefined ? (
                  <span className="validation-error">
                    {validationErrors.bio}
                  </span>
                ) : null}
              </Form.Group>
              <Form.Group controlId="website">
                <Form.Label>WEBSITE</Form.Label>
                <Form.Control
                  type="text"
                  onChange={this.changeHandler}
                  name="website"
                  value={website}
                  placeholder="website.com"
                />
                {validationErrors && validationErrors.website !== undefined ? (
                  <span className="validation-error">
                    {validationErrors.website}
                  </span>
                ) : null}
              </Form.Group>
              <Form.Group controlId="location">
                <Form.Label>LOCATION</Form.Label>
                <Form.Control
                  type="text"
                  onChange={this.changeHandler}
                  name="location"
                  value={location}
                  placeholder="Los Angeles, CA"
                />
              </Form.Group>
              <div class="inline-content middle mt-3">
                <span
                  onClick={this.deleteUserAccount}
                  className="customAnchor red"
                >
                  Delete Account
                </span>
                <div className="buttons">
                  <span
                    onClick={this.cancelBtnClick}
                    className="mt-0 me-0 btn btn-secondary"
                  >
                    Cancel
                  </span>
                  <Button variant="primary" className="mt-0 me-0" type="submit">
                    Save
                  </Button>
                </div>
              </div>
            </Col>
            <Col md={6} className="ps-3">
              <h3 className="pt-3 pb-3 lightColor">PROFILE IMAGE</h3>
              <div className="userAvatarWrapper d-inline-block position-relative hasIcon">
                {avatarDisplay ? (
                  <img
                    className="userAvatar"
                    src={avatarDisplay}
                    alt="User avatar"
                  />
                ) : (
                  <span className="userAvatarPlaceholder"></span>
                )}
                <i className="fa fa-camera upload-label" aria-hidden="true"></i>
                <Form.Control
                  data-index="0"
                  data-imgtype="USERS"
                  type="file"
                  name="avatar"
                  onChange={this.fileChangeHandler}
                  data-accept="jpg|jpeg|png"
                  data-accept_msg="Image is invalid. Please upload only PNG or JPG image."
                />
                {avatarDisplay ? (
                  <span
                    onClick={() => this.fileRemoveHandler("avatar")}
                    className="customAnchor text-center mt-2"
                  >
                    Remove
                    <svg
                      className="ms-1"
                      width="12"
                      height="14"
                      viewBox="0 0 12 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M3.75 0V0.75H0V2.25H0.75V12C0.75 12.3978 0.908035 12.7794 1.18934 13.0607C1.47064 13.342 1.85218 13.5 2.25 13.5H9.75C10.1478 13.5 10.5294 13.342 10.8107 13.0607C11.092 12.7794 11.25 12.3978 11.25 12V2.25H12V0.75H8.25V0H3.75ZM2.25 2.25H9.75V12H2.25V2.25ZM3.75 3.75V10.5H5.25V3.75H3.75ZM6.75 3.75V10.5H8.25V3.75H6.75Z"
                        fill="#BDBDBD"
                      />
                    </svg>
                  </span>
                ) : null}
              </div>

              <h3 className="pt-3 lightColor">BACKGROUND IMAGE</h3>
              <div className="userCoverImageWrapper  position-relative hasIcon">
                {coverImageDisplay ? (
                  <img
                    className="userCoverImage"
                    src={coverImageDisplay}
                    alt="User cover"
                  />
                ) : (
                  <span className="userCoverImagePlaceholder"></span>
                )}
                <i className="fa fa-camera upload-label" aria-hidden="true"></i>
                <Form.Control
                  data-index="0"
                  data-imgtype="COVERS"
                  type="file"
                  name="coverImage"
                  onChange={this.fileChangeHandler}
                  data-accept="jpg|jpeg|png"
                  data-accept_msg="Image is invalid. Please upload only PNG or JPG image."
                />
              </div>
              {coverImageDisplay ? (
                <span
                  onClick={() => this.fileRemoveHandler("coverImage")}
                  className="customAnchor ms-1"
                >
                  Remove
                  <svg
                    className="ms-1"
                    width="12"
                    height="14"
                    viewBox="0 0 12 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M3.75 0V0.75H0V2.25H0.75V12C0.75 12.3978 0.908035 12.7794 1.18934 13.0607C1.47064 13.342 1.85218 13.5 2.25 13.5H9.75C10.1478 13.5 10.5294 13.342 10.8107 13.0607C11.092 12.7794 11.25 12.3978 11.25 12V2.25H12V0.75H8.25V0H3.75ZM2.25 2.25H9.75V12H2.25V2.25ZM3.75 3.75V10.5H5.25V3.75H3.75ZM6.75 3.75V10.5H8.25V3.75H6.75Z"
                      fill="#BDBDBD"
                    />
                  </svg>
                </span>
              ) : null}
            </Col>
          </Row>
        </Form>
        <hr className="customHr" />
        <Form
          className="profileForm common-form row"
          onSubmit={this.submitChangePasswordDataHandle}
        >
          <Row
            className={
              loading === true
                ? "loading justify-content-center"
                : "justify-content-center"
            }
          >
            <Col md={6} className="ps-0 pe-3">
              <h3 className="pt-3 pb-3 lightColor">PASSWORD</h3>

              <Form.Group controlId="currentPassword">
                <Form.Label>OLD PASSWORD</Form.Label>
                <Form.Control
                  type="text"
                  onChange={this.changeHandler}
                  name="currentPassword"
                  value={currentPassword}
                />
                {passwordValidationErrors &&
                passwordValidationErrors.currentPassword !== undefined ? (
                  <span className="validation-error">
                    {passwordValidationErrors.currentPassword}
                  </span>
                ) : null}
              </Form.Group>

              <Form.Group controlId="password">
                <Form.Label>NEW PASSWORD</Form.Label>
                <Form.Control
                  type="text"
                  onChange={this.changeHandler}
                  name="password"
                  value={password}
                />
                {passwordValidationErrors &&
                passwordValidationErrors.password !== undefined ? (
                  <span className="validation-error">
                    {passwordValidationErrors.password}
                  </span>
                ) : null}
              </Form.Group>
              <Form.Group controlId="confirmationPassword">
                <Form.Label>CONFIRM NEW PASSWORD</Form.Label>
                <Form.Control
                  type="text"
                  onChange={this.changeHandler}
                  name="confirmationPassword"
                  value={confirmationPassword}
                />
                {passwordValidationErrors &&
                passwordValidationErrors.confirmationPassword !== undefined ? (
                  <span className="validation-error">
                    {passwordValidationErrors.confirmationPassword}
                  </span>
                ) : null}
              </Form.Group>
              <div class="inline-content middle mt-3">
                <span className="customAnchor danger"></span>
                <div className="buttons">
                  <span
                    onClick={this.cancelBtnClick}
                    className="mt-0 me-0 btn btn-secondary"
                  >
                    Cancel
                  </span>
                  <Button variant="primary" className="mt-0 me-0" type="submit">
                    Update
                  </Button>
                </div>
              </div>
            </Col>
            <Col md={6} className="ps-0 pe-3"></Col>
          </Row>
        </Form>
      </section>
    );
  }
}

const mapStateToProps = (state) => ({
  loggedInUser: state.login.user,
  selNetworkId: state.networks.selNetworkId,
});

export default connect(mapStateToProps)(EditNetwork);
