import React from "react";
import update from "react-addons-update";
import { Row, Col, Button, Form } from "react-bootstrap";
import axios from "axios";
import {
  API_URL,
  BASENAME,
  DEFAULT_DEBATE_DURATION,
  MAX_DEBATE_DURATION,
} from "../../constants/Globals";
import { connect } from "react-redux";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { NavLink } from "react-router-dom";
import { withTranslation } from "react-i18next";

class AddNetwork extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      catFieldHidden: true,
      newCatVal: "",
      placementFieldHidden: true,
      newPlacementVal: "",
      cats: "",
      validationErrors: {
        name: "",
        subDomain: "",
        customDomain: "",
        primaryDomain: "",
        title: "",
        categories: "",
        fbLink: "",
        instagramLink: "",
        twitterLink: "",
        linkedinLink: "",
        supportEmail: "",
        maxDebateDuration: "",
        affiliateLink: "",
      },

      validation: true,

      name: "",
      subDomain: "",
      customDomain: "",
      type: "publisher",
      isPrivate: 1,
      advertising: 1,
      isPointActive: 1,
      isDefault: 0,
      apiAccess: 1,
      isAutomated: 0,
      isMultiple: 0,
      isAllDebateCreator: 1,
      defaultDebateDuration: DEFAULT_DEBATE_DURATION,
      maxDebateDuration: MAX_DEBATE_DURATION,
      title: "",
      logo: "",
      logoWidth: "",
      logoHeight: "",
      favicon: "",
      emailLogo: "",
      fbClientId: "",
      fbClientSecret: "",
      twitterClientId: "",
      twitterClientSecret: "",
      fbLink: "",
      twitterLink: "",
      instagramLink: "",
      linkedinLink: "",
      advertisingSource: "publisher_desk",
      webAdvertisingSource: "publisher_desk",
      googleAnalyticsId: "",
      gamId: "",
      fbPixelId: "",
      supportName: "",
      supportEmail: "",
      categories: "",
      embedPlacements: [
        {
          placementName: "End of Content",
        },
        {
          placementName: "Sidebar",
          adPosition: "bottom",
        },
        {
          placementName: "In Content",
        },
      ],
      images: [
        {
          image_url: "",
          image_type: "",
        },
        {
          image_url: "",
          image_type: "",
        },
        {
          image_url: "",
          image_type: "",
        },
      ],
      affiliateLink: "",
      affiliateButtonText: "",
      primaryDomain: "",
    };
  }

  uploadCatImages = async (e) => {
    let catArray = this.state.categories;
    var image_type = "CATEGORY_IMAGE";

    for (var i = 0; i < catArray.length; i++) {
      if (catArray[i].imageUrl !== "") {
        const formData = new FormData();
        formData.append(
          "image",
          catArray[i].imageUrl,
          catArray[i].imageUrl.name,
        );

        await axios
          .post(
            API_URL +
              "/admin/client/imageUpload?type=" +
              image_type +
              "&clientName=" +
              this.state.name,
            formData,
          )
          .then((res) => {
            // this.setState(update(this.state, {
            // 	categories: {
            // 		[i]: {
            // 			'imageUrl' : {
            // 				$set: res.data.data.id
            // 			}
            // 		}
            // 	}
            // }))
          })
          .catch((err) => {
            alert("Image upload for category failed!");
          });
      }
    }

    this.uploadLogoImages();
  };

  uploadLogoImages = async (e) => {
    let imageArray = this.state.images;

    for (var i = 0; i < imageArray.length; i++) {
      if (imageArray[i].image_url !== "") {
        var image_type = imageArray[i].image_type;
        const formData = new FormData();
        formData.append(
          "image",
          imageArray[i].image_url,
          imageArray[i].image_url.name,
        );

        var imgUploadUrl =
          API_URL +
          "/admin/client/imageUpload?type=" +
          image_type +
          "&clientName=" +
          this.state.name;
        if (image_type === "FAVICON") {
          imgUploadUrl =
            API_URL +
            "/admin/client/faviconUpload?clientName=" +
            this.state.name;
        }

        const res = await axios.post(imgUploadUrl, formData);
        if (image_type === "LOGO") {
          this.setState({
            logo: res.data.data.id,
            logoWidth: res.data.data.width,
            logoHeight: res.data.data.height,
          });
        } else if (image_type === "FAVICON") {
          this.setState({
            favicon: res.data.data.id,
          });
        } else {
          this.setState({
            emailLogo: res.data.data.id,
          });
        }
      }
    }

    this.submitFormData();
  };

  submitFormData = async (e) => {
    const formData = this.state;
    var failure = false;

    await axios
      .post(API_URL + "/admin/client/create", formData)
      .then((res) => {
        console.log("New network added!");
      })
      .catch((err) => {
        console.log(err.response.data.message);
        failure = true;
      });

    if (failure === false) {
      this.messageNotification("success", "New network added!");
      setTimeout(() => {
        window.location.replace(BASENAME + "/networks");
      }, 3000);
    } else {
      alert("Form submission failed. Please try again.");
    }
  };

  formValidation = (e, action) => {
    if (e.target.name === "name" || action === "onSubmit") {
      if (this.state.name.trim() === "") {
        this.setState(
          update(this.state, {
            validationErrors: {
              name: {
                $set: "This field is required.",
              },
            },
          }),
        );
      } else if (!/^[a-zA-Z0-9_]+$/.test(this.state.name)) {
        this.setState(
          update(this.state, {
            validationErrors: {
              name: {
                $set: "Use only letter, number and _.",
              },
            },
          }),
        );
      } else {
        this.checkIfExist(e);
      }
    }

    if (e.target.name === "subDomain" || action === "onSubmit") {
      var subDomainTrim = this.state.subDomain.replace(".sided.co", "");
      if (subDomainTrim.trim() === "") {
        this.setState(
          update(this.state, {
            validationErrors: {
              subDomain: {
                $set: "This field is required.",
              },
            },
          }),
        );
      } else if (!/^([a-z0-9-]+)?$/i.test(subDomainTrim)) {
        this.setState(
          update(this.state, {
            validationErrors: {
              subDomain: {
                $set: "Use only letter, number and -.",
              },
            },
          }),
        );
      } else {
        this.setState(
          update(this.state, {
            validationErrors: {
              subDomain: {
                $set: "",
              },
            },
          }),
        );
      }
    }

    if (e.target.name === "customDomain" || action === "onSubmit") {
      if (
        this.state.customDomain !== "" &&
        !/^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-zA-Z0-9]+([-.]{1}[a-zA-Z0-9]+)*\.[a-zA-Z]{2,5}(:[0-9]{1,5})?(\/.*)?$/i.test(
          this.state.customDomain,
        )
      ) {
        this.setState(
          update(this.state, {
            validationErrors: {
              customDomain: {
                $set: "The Domain Url is invalid.",
              },
            },
          }),
        );
      } else {
        this.setState(
          update(this.state, {
            validationErrors: {
              customDomain: {
                $set: "",
              },
            },
          }),
        );
      }
    }

    if (e.target.name === "primaryDomain" || action === "onSubmit") {
      if (this.state.primaryDomain === "") {
        this.setState(
          update(this.state, {
            validationErrors: {
              primaryDomain: {
                $set: "This field is required.",
              },
            },
          }),
        );
      } //else if(this.state.primaryDomain !== "" && !(/^(?!www)[a-z0-9]+([\-\.]{1})*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/gm.test(this.state.primaryDomain))){
      else if (
        this.state.primaryDomain !== "" &&
        !/^((?!www|!http)[a-z0-9-]+\.(?:com|co|live|in|net|org|ca|edu|gov|edu|info|xyz|ly|site|me|mil|int|us|co\.uk|co\.us|com\.ar))(?:\/|$)/gm.test(
          this.state.primaryDomain,
        )
      ) {
        this.setState(
          update(this.state, {
            validationErrors: {
              primaryDomain: {
                $set: "The Domain Url is invalid.",
              },
            },
          }),
        );
      } else {
        this.setState(
          update(this.state, {
            validationErrors: {
              primaryDomain: {
                $set: "",
              },
            },
          }),
        );
      }
    }

    if (e.target.name === "title" || action === "onSubmit") {
      if (this.state.title.trim() === "") {
        this.setState(
          update(this.state, {
            validationErrors: {
              title: {
                $set: "This field is required.",
              },
            },
          }),
        );
      } else {
        this.setState(
          update(this.state, {
            validationErrors: {
              title: {
                $set: "",
              },
            },
          }),
        );
      }
    }

    if (e.target.name === "text" || action === "onSubmit") {
      /*if(this.state.categories.length < 1){
				this.setState(update(this.state, {
					validationErrors: {
						categories: {
							$set: "Please add at least one category."
						}
					}
				}));
			} else */ if (
        this.state.categories.length > 1 &&
        this.state.categories.find((element) => element.text.trim() === "")
      ) {
        this.setState(
          update(this.state, {
            validationErrors: {
              categories: {
                $set: "Category name is required.",
              },
            },
          }),
        );
      } else {
        this.setState(
          update(this.state, {
            validationErrors: {
              categories: {
                $set: "",
              },
            },
          }),
        );
      }
    }

    if (
      e.target.name === "fbLink" ||
      e.target.name === "instagramLink" ||
      e.target.name === "twitterLink" ||
      e.target.name === "linkedinLink" ||
      action === "onSubmit"
    ) {
      if (
        e.target.value !== "" &&
        !/^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-zA-Z0-9]+([-.]{1}[a-zA-Z0-9]+)*\.[a-zA-Z]{2,5}(:[0-9]{1,5})?(\/.*)?$/i.test(
          e.target.value,
        )
      ) {
        this.setState(
          update(this.state, {
            validationErrors: {
              [e.target.name]: {
                $set: "Invalid.",
              },
            },
          }),
        );
      } else {
        this.setState(
          update(this.state, {
            validationErrors: {
              [e.target.name]: {
                $set: "",
              },
            },
          }),
        );
      }
    }

    if (e.target.name === "supportEmail") {
      if (
        e.target.value !== "" &&
        !/^(([^<>!#$%^&*={}?/|'`~+()[\]\\.,;:\s@"]+(\.[^<>!#$%^&*={}?/|'`~+()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          e.target.value,
        )
      ) {
        this.setState(
          update(this.state, {
            validationErrors: {
              [e.target.name]: {
                $set: "The email must be a valid email address.",
              },
            },
          }),
        );
      } else {
        this.setState(
          update(this.state, {
            validationErrors: {
              [e.target.name]: {
                $set: "",
              },
            },
          }),
        );
      }
    }
    if (
      e.target.name === "maxDebateDuration" ||
      e.target.name === "defaultDebateDuration"
    ) {
      if (
        e.target.value !== "" &&
        parseInt(this.state.maxDebateDuration) <
          parseInt(this.state.defaultDebateDuration)
      ) {
        this.setState(
          update(this.state, {
            validationErrors: {
              maxDebateDuration: {
                $set: "Max Poll Duration must be greater than or equal to Default Poll Duration.",
              },
            },
          }),
        );
      } else {
        this.setState(
          update(this.state, {
            validationErrors: {
              [e.target.name]: {
                $set: "",
              },
            },
          }),
        );
      }
    }

    if (e.target.name === "affiliateLink" || action === "onSubmit") {
      if (
        this.state.affiliateLink !== "" &&
        !/^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-zA-Z0-9]+([-.]{1}[a-zA-Z0-9]+)*\.[a-zA-Z]{2,5}(:[0-9]{1,5})?(\/.*)?$/i.test(
          this.state.affiliateLink,
        )
      ) {
        this.setState(
          update(this.state, {
            validationErrors: {
              affiliateLink: {
                $set: "The affiliate link is invalid.",
              },
            },
          }),
        );
      } else {
        this.setState(
          update(this.state, {
            validationErrors: {
              affiliateLink: {
                $set: "",
              },
            },
          }),
        );
      }
    }

    if (
      this.state.validationErrors.name === "" &&
      this.state.validationErrors.subDomain === "" &&
      this.state.validationErrors.customDomain === "" &&
      this.state.validationErrors.primaryDomain === "" &&
      this.state.validationErrors.title === "" &&
      this.state.validationErrors.categories === "" &&
      this.state.validationErrors.fbLink === "" &&
      this.state.validationErrors.instagramLink === "" &&
      this.state.validationErrors.twitterLink === "" &&
      this.state.validationErrors.linkedinLink === "" &&
      this.state.validationErrors.supportEmail === "" &&
      this.state.validationErrors.affiliateLink === "" &&
      this.state.validationErrors.maxDebateDuration === ""
    ) {
      if (action === "onSubmit") {
        this.uploadCatImages();
      }
    }
  };

  submitWhiteLabelHandle = (e) => {
    setTimeout(
      function () {
        this.formValidation(e, "onSubmit");
      }.bind(this),
      100,
    );

    e.preventDefault();
  };

  checkIfExist = async (e) => {
    if (e.target.value === "") {
      return false;
    }
    const formData = {
      field: e.target.name,
      value: e.target.value,
    };

    await axios
      .post(API_URL + "/admin/client/exists", formData)
      .then((res) => {
        if (res.data.data === false) {
          this.setState(
            update(this.state, {
              validationErrors: {
                name: {
                  $set: "This client name has already been taken.",
                },
              },
            }),
          );
        } else {
          this.setState(
            update(this.state, {
              validationErrors: {
                name: {
                  $set: "",
                },
              },
            }),
          );
        }
      })
      .catch((err) => {
        console.log("WLName exists");
      });
  };

  changeHandler = (e) => {
    if (e.target.type === "checkbox") {
      if (e.target.name === "isAllDebateCreator") {
        this.setState({
          [e.target.name]: e.target.checked === true ? 0 : 1,
        });
      } else {
        this.setState({
          [e.target.name]: e.target.checked === true ? 1 : 0,
        });
      }
    } else {
      this.setState({
        [e.target.name]: e.target.value,
      });
    }

    setTimeout(
      function () {
        this.formValidation(e);
      }.bind(this),
      100,
    );
  };

  subdomainChangeHandler = (e) => {
    this.setState({
      [e.target.name]: e.target.value + ".sided.co",
    });
    setTimeout(
      function () {
        this.formValidation(e);
      }.bind(this),
      100,
    );
  };

  catChangeHandler = (e) => {
    let categories = [...this.state.categories];
    let category = "";

    if (e.target.type === "checkbox") {
      category = {
        ...categories[e.target.dataset.index],
        [e.target.name]: e.target.checked === true ? 1 : 0,
      };
      categories[e.target.dataset.index] = category;
      this.setState({ categories });
    } else if (e.target.type === "file" && e.target.files[0]) {
      var regex = new RegExp("(.*?).(jpg|jpeg|gif|png)$");
      if (!regex.test(e.target.files[0].type)) {
        alert("Image is invalid. Please upload only PNG, JPG or GIF image.");
        return false;
      }
      if (e.target.files[0].size > 100 * 1024) {
        alert("Category image size should not be greater than 100kb");
        return false;
      }
      var file = e.target.files[0];
      var thisEl = e;
      var reader = new FileReader();
      reader.onloadend = function (e) {
        category = {
          ...categories[thisEl.target.dataset.index],
          [thisEl.target.name]: thisEl.target.files[0],
          tempPath: e.target.result,
        };
        categories[thisEl.target.dataset.index] = category;
        this.setState({ categories });
        console.log(this.state);
      }.bind(this);
      reader.readAsDataURL(file);
    } else {
      category = {
        ...categories[e.target.dataset.index],
        [e.target.name]: e.target.value,
      };
      categories[e.target.dataset.index] = category;
      this.setState({ categories });
    }
  };

  catImgRemoveHandler = (name, index) => {
    let categories = [...this.state.categories];
    let category = "";
    category = {
      ...categories[index],
      [name]: "",
      tempPath: "",
    };
    categories[index] = category;
    this.setState({ categories });
  };

  fileChangeHandler = (e) => {
    if (e.target.type === "file" && e.target.files[0]) {
      const dataAccept = e.target.getAttribute("data-accept")
        ? e.target.getAttribute("data-accept")
        : "jpg|jpeg|gif|png";
      const dataAcceptMsg = e.target.getAttribute("data-accept_msg")
        ? e.target.getAttribute("data-accept_msg")
        : "Image is invalid. Please upload only PNG, JPG or GIF image.";
      var regex = new RegExp("(.*?).(" + dataAccept + ")$");
      if (!regex.test(e.target.files[0].type)) {
        alert(dataAcceptMsg);
        return false;
      }
      if (e.target.name === "logo" && e.target.files[0].size > 500 * 1024) {
        alert("Logo size should not be greater than 500kb");
        return false;
      } else if (
        e.target.name === "favicon" &&
        e.target.files[0].size > 100 * 1024
      ) {
        alert("Favicon size should not be greater than 100kb");
        return false;
      } else if (
        e.target.name === "emailLogo" &&
        e.target.files[0].size > 250 * 1024
      ) {
        alert("Email Logo size should not be greater than 250kb");
        return false;
      }
      var file = e.target.files[0];
      var thisEl = e;
      var reader = new FileReader();
      reader.onloadend = function (e) {
        this.setState(
          update(this.state, {
            images: {
              [thisEl.target.dataset.index]: {
                image_url: {
                  $set: thisEl.target.files[0],
                },
                image_type: {
                  $set: thisEl.target.dataset.imgtype,
                },
              },
            },
          }),
        );
        this.setState({
          [thisEl.target.name]: e.target.result,
        });
      }.bind(this);
      reader.readAsDataURL(file);
    }
  };

  fileRemoveHandler = (name, index) => {
    this.setState(
      update(this.state, {
        images: {
          [index]: {
            image_url: {
              $set: "",
            },
            image_type: {
              $set: "",
            },
          },
        },
      }),
    );
    this.setState({
      [name]: "",
    });
  };

  addPlacementClickHandle = ($) => {
    this.setState({
      placementFieldHidden: false,
    });
  };
  addPlacementFieldChange = (e) => {
    this.setState({
      newPlacementVal: e.target.value,
    });
  };
  submitPlacementField = (e) => {
    if (this.state.newPlacementVal === "") {
      alert("Placement name cannot be blank.");
    } else {
      this.setState((prevState) => ({
        embedPlacements: [
          ...prevState.embedPlacements,
          {
            placementName: this.state.newPlacementVal,
          },
        ],
      }));
      this.setState({
        placementFieldHidden: true,
        newPlacementVal: "",
      });
    }
    e.preventDefault();
  };

  placementChangeHandler = async (e) => {
    let embedPlacements = [...this.state.embedPlacements];
    let embedPlacement = "";
    embedPlacement = {
      ...embedPlacements[e.target.dataset.index],
      [e.target.name]: e.target.value,
    };
    embedPlacements[e.target.dataset.index] = embedPlacement;
    await this.setState({ embedPlacements });
  };

  addCatClickHandle = ($) => {
    this.setState({
      catFieldHidden: false,
    });
  };
  addCatFieldChange = (e) => {
    this.setState({
      newCatVal: e.target.value,
    });
  };
  submitCatField = (e) => {
    if (this.state.newCatVal === "") {
      alert("Category name cannot be blank.");
    } else {
      this.setState((prevState) => ({
        categories: [
          {
            text: this.state.newCatVal,
            imageUrl: "",
            tempPath: "",
            featured: 0,
            archive: 0,
            adminOnly: 0,
          },
          ...prevState.categories,
        ],
      }));
      this.setState({
        catFieldHidden: true,
        newCatVal: "",
      });
    }
    e.preventDefault();
  };

  reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  onDragEnd = async (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const categories = await this.reorder(
      this.state.categories,
      result.source.index,
      result.destination.index,
    );
    await this.setState({
      categories: categories,
    });
  };

  messageNotification = async (type, message) => {
    this.setState({
      successMsg: message,
    });

    setTimeout(() => {
      this.setState({
        successMsg: "",
      });
    }, 5000);
  };

  render() {
    const {
      catFieldHidden,
      name,
      customDomain,
      isPrivate,
      advertising,
      isPointActive,
      apiAccess,
      isAutomated,
      isMultiple,
      isAllDebateCreator,
      defaultDebateDuration,
      maxDebateDuration,
      title,
      logo,
      favicon,
      emailLogo,
      advertisingSource,
      webAdvertisingSource,
      googleAnalyticsId,
      gamId,
      fbPixelId,
      supportName,
      supportEmail,
      categories,
      affiliateLink,
      affiliateButtonText,
      primaryDomain,
    } = this.state;

    let subDomain = this.state.subDomain;
    let loggedInUser = this.props.loggedInUser;
    subDomain = subDomain.replace(".sided.co", "");
    const kTrans = this.props.t ? this.props.t : "";

    return (
      <section>
        {this.state.successMsg ? (
          <div className="sucessMsg">{this.state.successMsg}</div>
        ) : null}
        <h2 className="title-section">{kTrans("Add Network")}</h2>
        <Row className="justify-content-center">
          <Form
            className="WLSettings addNetwork common-form row position-relative"
            onSubmit={this.submitWhiteLabelHandle}
          >
            <Col md={6} className="pe-4">
              <Form.Group controlId="clientName" className="pt-0">
                <Form.Label>{kTrans("NETWORK NAME")}</Form.Label>
                <Form.Control
                  type="text"
                  onChange={(e) => {
                    this.changeHandler(e);
                  }}
                  name="name"
                  value={name}
                  placeholder={kTrans("Enter your network name")}
                />
                <span className="validation-error">
                  {this.state.validationErrors["name"]}
                </span>
              </Form.Group>
              <Form.Group>
                <Form.Label>{kTrans("SUBDOMAIN")}</Form.Label>
                <div className="inline-content bottom">
                  <Form.Control
                    type="text"
                    onChange={(e) => {
                      this.subdomainChangeHandler(e);
                    }}
                    name="subDomain"
                    value={subDomain}
                    id="subDomain"
                    placeholder={kTrans("Type domain name")}
                  />
                  <span>&nbsp;&nbsp;.sided.co</span>
                </div>
                <span className="validation-error">
                  {this.state.validationErrors["subDomain"]}
                </span>
              </Form.Group>
              <Form.Group>
                <Form.Label>{kTrans("CUSTOM DOMAIN")}</Form.Label>
                <div className="inline-content middle">
                  <Form.Control
                    type="text"
                    onChange={this.changeHandler}
                    name="customDomain"
                    value={customDomain}
                    id="customDomain"
                    placeholder={kTrans("Type domain name")}
                  />
                </div>
                <span className="validation-error">
                  {this.state.validationErrors["customDomain"]}
                </span>
              </Form.Group>

              <Form.Group>
                <Form.Label>{kTrans("PRIMARY DOMAIN")}</Form.Label>
                <div className="inline-content middle">
                  <Form.Control
                    type="text"
                    onChange={this.changeHandler}
                    name="primaryDomain"
                    value={primaryDomain}
                    id="primaryDomain"
                    placeholder={kTrans("Example: domain.com")}
                  />
                </div>
                <span className="validation-error">
                  {this.state.validationErrors["primaryDomain"]}
                </span>
              </Form.Group>

              {loggedInUser &&
              loggedInUser.roles.length > 0 &&
              loggedInUser.roles.find((role) => role.name === "SUPERADMIN") ? (
                <Form.Group>
                  <Form.Label>{kTrans("ADVERTISING SOURCE")}</Form.Label>
                  <select
                    name="advertisingSource"
                    value={advertisingSource}
                    id="advertisingSource"
                    onChange={this.changeHandler}
                  >
                    <option value="publisher">Publisher</option>
                    <option value="adsense">Adsense</option>
                    <option value="publisher_desk">Publisher Desk</option>
                    <option value="publisher_desk_in_network">
                      Publisher Desk (in-network)
                    </option>
                    <option value="rev_content">RevContent</option>
                    <option value="outbrain">Outbrain</option>
                    <option value="taboola">Taboola</option>
                    <option value="datablocks">DataBlocks</option>
                  </select>
                </Form.Group>
              ) : null}

              <Form.Group>
                <Form.Label>
                  {kTrans("Web Advertising Source").toUpperCase()}
                </Form.Label>
                <select
                  name="webAdvertisingSource"
                  value={webAdvertisingSource}
                  id="webAdvertisingSource"
                  onChange={this.changeHandler}
                >
                  <option value="">{kTrans("Publisher Desk")}</option>
                  <option value="outbrain">Outbrain</option>
                </select>
              </Form.Group>

              <div className="inline-toggles mb-4 mt-4">
                {loggedInUser.roles.length > 0 &&
                loggedInUser.roles.find(
                  (role) => role.name === "SUPERADMIN",
                ) ? (
                  <Form.Group>
                    <Form.Check.Label className="mb-3">
                      {kTrans("ADVERTISING")}
                    </Form.Check.Label>
                    <Form.Check.Input
                      className="toggle-checkbox"
                      defaultChecked={true}
                      onChange={this.changeHandler}
                      name="advertising"
                      value={advertising}
                      data-type="ADVERTISING"
                    />
                  </Form.Group>
                ) : null}
                <Form.Group>
                  <Form.Check.Label className="mb-3">
                    {kTrans("PRIVATE NETWORK")}
                  </Form.Check.Label>
                  <Form.Check.Input
                    className="toggle-checkbox"
                    defaultChecked={true}
                    onChange={this.changeHandler}
                    name="isPrivate"
                    value={isPrivate}
                    data-type="PRIVATE NETWORK"
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Check.Label className="mb-3">
                    {kTrans("POINTS & LEADERBOARD")}
                  </Form.Check.Label>
                  <Form.Check.Input
                    className="toggle-checkbox"
                    defaultChecked={true}
                    onChange={this.changeHandler}
                    name="isPointActive"
                    value={isPointActive}
                    data-type="POINTS & LEADERBOARD"
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Check.Label className="mb-3">
                    {kTrans("CONTRIBUTORS ONLY")}
                  </Form.Check.Label>
                  <Form.Check.Input
                    className="toggle-checkbox"
                    defaultChecked={true}
                    onChange={this.changeHandler}
                    name="isAllDebateCreator"
                    value={isAllDebateCreator}
                    data-id="CONTRIBUTORS ONLY"
                  />
                </Form.Group>

                {loggedInUser.roles.length > 0 &&
                loggedInUser.roles.find(
                  (role) => role.name === "SUPERADMIN",
                ) ? (
                  <Form.Group>
                    <Form.Check.Label className="mb-3">
                      {kTrans("API ACCESS")}
                    </Form.Check.Label>
                    <Form.Check.Input
                      className="toggle-checkbox"
                      defaultChecked={true}
                      onChange={this.changeHandler}
                      name="apiAccess"
                      value={apiAccess}
                      data-type="API Access"
                    />
                  </Form.Group>
                ) : null}

                <Form.Group>
                  <Form.Check.Label className="mb-3">
                    {kTrans("AUTOMATION")}
                  </Form.Check.Label>
                  <Form.Check.Input
                    className="toggle-checkbox"
                    defaultChecked={false}
                    onChange={this.changeHandler}
                    name="isAutomated"
                    value={isAutomated}
                    data-id="AUTOMATION"
                  />
                </Form.Group>

                <Form.Group>
                  <Form.Check.Label className="mb-3">
                    {kTrans("SHOW MULTIPLE POLLS")}
                  </Form.Check.Label>
                  <Form.Check.Input
                    className="toggle-checkbox"
                    defaultChecked={false}
                    onChange={this.changeHandler}
                    name="isMultiple"
                    value={isMultiple}
                    data-id="SHOW MULTIPLE POLLS"
                  />
                </Form.Group>
              </div>

              <div className="inline-content top">
                <Form.Group>
                  <Form.Check.Label>
                    {kTrans("DEFAULT POLL DURATION")}
                  </Form.Check.Label>
                  <div className="d-flex align-items-center">
                    <input
                      onChange={this.changeHandler}
                      id="default-debate-duration"
                      type="number"
                      name="defaultDebateDuration"
                      value={defaultDebateDuration}
                      className="form-control w-50"
                      min="1"
                    />
                    &nbsp;&nbsp;days
                  </div>
                </Form.Group>

                <Form.Group>
                  <Form.Check.Label>
                    {kTrans("MAX POLL DURATION")}
                  </Form.Check.Label>
                  <div className="d-flex align-items-center">
                    <input
                      onChange={this.changeHandler}
                      id="max-debate-duration"
                      type="number"
                      name="maxDebateDuration"
                      value={maxDebateDuration}
                      className="form-control w-50"
                      min="1"
                    />
                    &nbsp;&nbsp;days
                  </div>
                </Form.Group>
              </div>
              <span className="validation-error">
                {this.state.validationErrors["maxDebateDuration"]}
              </span>

              <h3 className="mt-5 mb-2">{kTrans("META")}</h3>
              <Form.Group>
                <Form.Label>{kTrans("TITLE")}</Form.Label>
                <Form.Control
                  type="text"
                  onChange={(e) => {
                    this.changeHandler(e);
                  }}
                  name="title"
                  placeholder={kTrans("Enter title")}
                  value={title}
                  id="title"
                />
                <span className="validation-error">
                  {this.state.validationErrors["title"]}
                </span>
              </Form.Group>
              <Form.Group className="">
                <Form.Label>{kTrans("LOGO")}</Form.Label>
                <div className="field-logo img-field-wrap">
                  {logo !== "" ? (
                    <>
                      <img alt="Logo" src={logo} />
                      <span
                        className="close"
                        onClick={() => this.fileRemoveHandler("logo", "0")}
                      >
                        <i className="fa fa-times"></i>
                      </span>
                    </>
                  ) : (
                    <div className="img-field-placeholder">
                      <div>
                        <i className="fa fa-upload" aria-hidden="true"></i>
                        <span className="upload-label">
                          {kTrans("Upload logo")}
                        </span>
                        <span>{kTrans("Large")}</span>
                      </div>
                    </div>
                  )}
                  <Form.Control
                    data-index="0"
                    data-imgtype="LOGO"
                    type="file"
                    name="logo"
                    onChange={this.fileChangeHandler}
                    data-accept="jpg|jpeg|gif|png"
                    data-accept_msg={kTrans(
                      "Image is invalid. Please upload only PNG, JPG or GIF image.",
                    )}
                  />
                </div>
              </Form.Group>

              <div className="row">
                <Form.Group className="col-4">
                  <Form.Label>{kTrans("FAVICON")}</Form.Label>
                  <div className="field-favicon img-field-wrap">
                    {favicon !== "" ? (
                      <>
                        <img alt="Favicon" src={favicon} />
                        <span
                          className="close"
                          onClick={() => this.fileRemoveHandler("favicon", "1")}
                        >
                          <i className="fa fa-times"></i>
                        </span>
                      </>
                    ) : (
                      <div className="img-field-placeholder">
                        <div>
                          <i className="fa fa-upload" aria-hidden="true"></i>
                          <span className="upload-label">
                            {kTrans("Upload icon")}
                          </span>
                          <span>{kTrans("Square")}</span>
                        </div>
                      </div>
                    )}
                    <Form.Control
                      data-index="1"
                      data-imgtype="FAVICON"
                      type="file"
                      name="favicon"
                      onChange={this.fileChangeHandler}
                      data-accept="jpg|jpeg|gif|png|icon"
                      data-accept_msg={kTrans(
                        "Image is invalid. Please upload only PNG, JPG, GIF or ICO image.",
                      )}
                    />
                  </div>
                </Form.Group>

                <Form.Group className="col-8">
                  <Form.Label>{kTrans("EMAIL LOGO")}</Form.Label>
                  <div className="field-emaillogo img-field-wrap">
                    {emailLogo !== "" ? (
                      <>
                        <img alt="Email Logo" src={emailLogo} />
                        <span
                          className="close"
                          onClick={() =>
                            this.fileRemoveHandler("emailLogo", "2")
                          }
                        >
                          <i className="fa fa-times"></i>
                        </span>
                      </>
                    ) : (
                      <div className="img-field-placeholder">
                        <div>
                          <i className="fa fa-upload" aria-hidden="true"></i>
                          <span className="upload-label">
                            {kTrans("Upload logo")}
                          </span>
                          <span>{kTrans("Medium")}</span>
                        </div>
                      </div>
                    )}
                    <Form.Control
                      data-index="2"
                      data-imgtype="EMAIL_LOGO"
                      type="file"
                      name="emailLogo"
                      onChange={this.fileChangeHandler}
                      data-accept="jpg|jpeg|gif|png"
                      data-accept_msg={kTrans(
                        "Image is invalid. Please upload only PNG, JPG or GIF image.",
                      )}
                    />
                  </div>
                </Form.Group>
              </div>

              {/*<h3 className="mt-5 mb-2">SOCIAL LOGIN</h3>
							<Form.Group>
								<Form.Label>FACEBOOK CLIENT ID</Form.Label>
								<Form.Control type="text" onChange={this.changeHandler} name="fbClientId" value={fbClientId} id="fbClientId" placeholder="Facebook Client ID" />
							</Form.Group>
							<Form.Group>
								<Form.Label>FACEBOOK CLIENT SECRET</Form.Label>
								<Form.Control type="text" onChange={this.changeHandler} name="fbClientSecret" value={fbClientSecret} id="fbClientSecret" placeholder="Facebook Client Secret" />
							</Form.Group>
							<Form.Group>
								<Form.Label>TWITTER CLIENT ID</Form.Label>
								<Form.Control type="text" onChange={this.changeHandler} name="twitterClientId" value={twitterClientId} id="twitterClientId" placeholder="Twitter Client ID" />
							</Form.Group>
							<Form.Group>
								<Form.Label>TWITTER CLIENT SECRET</Form.Label>
								<Form.Control type="text" onChange={this.changeHandler} name="twitterClientSecret" value={twitterClientSecret} id="twitterClientSecret" placeholder="Twitter Client Secret" />
							</Form.Group>

							<h3>SOCIAL LINKS</h3>
							<Form.Group>
								<Form.Label>FACEBOOK</Form.Label>
								<Form.Control type="text" name="fbLink" value={fbLink} id="fbLink" placeholder="https://www.facebook.com/"  onChange={this.changeHandler}/>
								<span className="validation-error">{this.state.validationErrors["fbLink"]}</span>
							</Form.Group>

							<Form.Group>
								<Form.Label>INSTAGRAM</Form.Label>
								<Form.Control type="text" name="instagramLink" value={instagramLink} id="instagramLink" placeholder="https://www.instagram.com/"  onChange={this.changeHandler}/>
								<span className="validation-error">{this.state.validationErrors["instagramLink"]}</span>
							</Form.Group>

							<Form.Group>
								<Form.Label>TWITTER</Form.Label>
								<Form.Control type="text" name="twitterLink" value={twitterLink} id="twitterLink" placeholder="https;//twitter.com/"  onChange={this.changeHandler}/>
								<span className="validation-error">{this.state.validationErrors["twitterLink"]}</span>
							</Form.Group>

							<Form.Group>
								<Form.Label>LINKEDIN</Form.Label>
								<Form.Control type="text" name="linkedinLink" value={linkedinLink} id="linkedinLink" placeholder="https;//linkedin.com/company/"  onChange={this.changeHandler}/>
								<span className="validation-error">{this.state.validationErrors["linkedinLink"]}</span>
							</Form.Group>*/}

              <h3 className="mt-5 mb-2">{kTrans("AFFILIATE LINK")}</h3>
              <Form.Group>
                <Form.Label>{kTrans("LINK")}</Form.Label>
                <Form.Control
                  type="text"
                  name="affiliateLink"
                  value={affiliateLink}
                  id="affiliateLink"
                  placeholder={kTrans("Enter affiliate link")}
                  onChange={this.changeHandler}
                />
                <span className="validation-error">
                  {this.state.validationErrors["affiliateLink"]}
                </span>
              </Form.Group>
              <Form.Group>
                <Form.Label>{kTrans("BUTTON TEXT")}</Form.Label>
                <Form.Control
                  type="text"
                  name="affiliateButtonText"
                  value={affiliateButtonText}
                  id="affiliateButtonText"
                  placeholder={kTrans("Enter button text")}
                  onChange={this.changeHandler}
                />
              </Form.Group>

              <Form.Group>
                <Form.Label>{kTrans("SUPPORT NAME")}</Form.Label>
                <Form.Control
                  type="text"
                  name="supportName"
                  value={supportName}
                  id="supportName"
                  placeholder={kTrans("Enter Support Name")}
                  onChange={this.changeHandler}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>{kTrans("SUPPORT EMAIL")}</Form.Label>
                <Form.Control
                  type="text"
                  name="supportEmail"
                  value={supportEmail}
                  id="supportEmail"
                  placeholder={kTrans("Enter email address")}
                  onChange={this.changeHandler}
                />
                <span className="validation-error">
                  {this.state.validationErrors["supportEmail"]}
                </span>
              </Form.Group>

              <h3 className="mt-5 mb-2">{kTrans("EXTRA")}</h3>
              <Form.Group>
                <Form.Label>{kTrans("GOOGLE ANALYTICS ID")}</Form.Label>
                <Form.Control
                  type="text"
                  name="googleAnalyticsId"
                  value={googleAnalyticsId}
                  id="googleAnalyticsId"
                  placeholder={kTrans("Google Analytic ID")}
                  onChange={this.changeHandler}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>{kTrans("GAM ID")}</Form.Label>
                <Form.Control
                  type="text"
                  name="gamId"
                  value={gamId}
                  id="gamId"
                  placeholder={kTrans("GAM ID")}
                  onChange={this.changeHandler}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>{kTrans("FACEBOOK PIXEL ID")}</Form.Label>
                <Form.Control
                  type="text"
                  name="fbPixelId"
                  value={fbPixelId}
                  id="fbPixelId"
                  placeholder={kTrans("Facebook Pixel ID")}
                  onChange={this.changeHandler}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>{kTrans("SUPPORT NAME")}</Form.Label>
                <Form.Control
                  type="text"
                  name="supportName"
                  value={supportName}
                  id="supportName"
                  placeholder={kTrans("Enter Support Name")}
                  onChange={this.changeHandler}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>{kTrans("SUPPORT EMAIL")}</Form.Label>
                <Form.Control
                  type="text"
                  name="supportEmail"
                  value={supportEmail}
                  id="supportEmail"
                  placeholder={kTrans("Enter email address")}
                  onChange={this.changeHandler}
                />
                <span className="validation-error">
                  {this.state.validationErrors["supportEmail"]}
                </span>
              </Form.Group>
            </Col>
            <Col md={6} className="ps-3">
              {/*<div className="placementsSection pb-5">
								<div className="inline-content bottom">
									<h3>EMBED PLACEMENTS</h3>
									<span className="customAnchor" role="button" onClick={this.addPlacementClickHandle}>Add Placement +</span>
								</div>
								{ (placementFieldHidden === false) ?
									<div className="addPlacementField inline-content middle mt-1">
										<input type="text" className="form-control" onChange={this.addPlacementFieldChange} placeholder="Enter new placement" value={this.state.newPlacementVal} />
										<button className="btn btn-secondary" onClick={this.submitPlacementField}>Add</button>
									</div>
									 : null
								}
								<hr className="mb-1"/>

								{
									embedPlacements && embedPlacements.length ? embedPlacements.map((el, i) => (
											<div className="placementCols inline-content middle" key={i}>
												<input data-index={i} type="text" onChange={e => { this.placementChangeHandler(e);  }} placeholder="Enter new Placement" name="placementName" value={el.placementName} />
												<i class="fa fa-code"></i>
											</div>
										))
									: null
								}
							</div>*/}

              <div className="catsSection">
                <div className="inline-content bottom">
                  <h3>{kTrans("CATEGORIES")} *</h3>
                  <span
                    className="customAnchor"
                    role="button"
                    onClick={this.addCatClickHandle}
                  >
                    {kTrans("Add Category")} +
                  </span>
                </div>
                {catFieldHidden === false ? (
                  <div className="addCatField inline-content middle mt-1">
                    <input
                      type="text"
                      className="form-control"
                      onChange={this.addCatFieldChange}
                      placeholder={kTrans("Enter new category")}
                      value={this.state.newCatVal}
                    />
                    <button
                      className="btn btn-secondary"
                      onClick={this.submitCatField}
                    >
                      {kTrans("Add")}
                    </button>
                  </div>
                ) : null}
                <hr className="mb-1" />

                {categories && categories.length ? (
                  <DragDropContext onDragEnd={this.onDragEnd}>
                    <Droppable droppableId="characters">
                      {(provided) => (
                        <div
                          className="characters"
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                        >
                          {categories.map((el, i) => (
                            <Draggable
                              key={i}
                              draggableId={`dragCat-${i}`}
                              index={i}
                            >
                              {(provided) => (
                                <div
                                  className="catCols inline-content middle"
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                >
                                  <span className="catImageCol img-field-wrap">
                                    {el.tempPath ? (
                                      <>
                                        <span
                                          className="close"
                                          onClick={() =>
                                            this.catImgRemoveHandler(
                                              "imageUrl",
                                              i,
                                            )
                                          }
                                        >
                                          <i className="fa fa-times"></i>
                                        </span>
                                        <img alt={el.text} src={el.tempPath} />
                                      </>
                                    ) : (
                                      <i
                                        className="fa fa-upload"
                                        aria-hidden="true"
                                      ></i>
                                    )}
                                    <Form.Control
                                      data-index={i}
                                      type="file"
                                      onChange={this.catChangeHandler}
                                      name="imageUrl"
                                    />
                                  </span>
                                  <span className="catTextCol">
                                    <input
                                      data-index={i}
                                      type="text"
                                      onChange={(e) => {
                                        this.catChangeHandler(e);
                                      }}
                                      placeholder={kTrans("Enter new category")}
                                      name="text"
                                      value={el.text}
                                    />
                                  </span>
                                  <Form.Group>
                                    <>
                                      {i === 0 ? (
                                        <span>{kTrans("Featured")}</span>
                                      ) : (
                                        ""
                                      )}
                                      <Form.Check.Input
                                        className="toggle-checkbox small"
                                        data-index={i}
                                        checked={el.featured}
                                        onChange={this.catChangeHandler}
                                        name="featured"
                                        value="1"
                                      />
                                    </>
                                  </Form.Group>
                                  <Form.Group>
                                    <>
                                      {i === 0 ? (
                                        <span>{kTrans("Admin")}</span>
                                      ) : (
                                        ""
                                      )}
                                      <Form.Check.Input
                                        className="toggle-checkbox small"
                                        data-index={i}
                                        checked={el.adminOnly}
                                        onChange={this.catChangeHandler}
                                        name="adminOnly"
                                        value=""
                                      />
                                    </>
                                  </Form.Group>
                                  <Form.Group>
                                    <>
                                      {i === 0 ? (
                                        <span>{kTrans("Archive")}</span>
                                      ) : (
                                        ""
                                      )}
                                      <Form.Check.Input
                                        className="toggle-checkbox small"
                                        data-index={i}
                                        checked={el.archive}
                                        onChange={this.catChangeHandler}
                                        name="archive"
                                        value=""
                                      />
                                    </>
                                  </Form.Group>
                                  <span className="catReorderCol">
                                    <i className="fa fa-bars"></i>
                                  </span>
                                </div>
                              )}
                            </Draggable>
                          ))}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>
                ) : null}

                <span className="validation-error">
                  {this.state.validationErrors["categories"]}
                </span>
              </div>
            </Col>
            <div className="buttons">
              <NavLink
                className="btn btn-secondary"
                exact
                activeClassName="active"
                to="/networks"
              >
                {kTrans("Cancel")}
              </NavLink>
              <Button variant="primary" type="submit">
                {kTrans("Save")}
              </Button>
            </div>
          </Form>
        </Row>
      </section>
    );
  }
}

const mapStateToProps = (state) => ({
  loggedInUser: state.login.user,
});

const Extended = withTranslation()(AddNetwork);
Extended.static = AddNetwork.static;

export default connect(mapStateToProps, {})(Extended);
