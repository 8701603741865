export default function TableSearch({
  value = "",
  placeholder = "",
  onChange = () => {},
}) {
  return (
    <div className="table-search">
      <svg
        width="18"
        height="19"
        viewBox="0 0 18 19"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6.5 0.658691C8.22391 0.658691 9.87721 1.34351 11.0962 2.5625C12.3152 3.78148 13 5.43478 13 7.15869C13 8.76869 12.41 10.2487 11.44 11.3887L11.71 11.6587H12.5L17.5 16.6587L16 18.1587L11 13.1587V12.3687L10.73 12.0987C9.59 13.0687 8.11 13.6587 6.5 13.6587C4.77609 13.6587 3.12279 12.9739 1.90381 11.7549C0.684819 10.5359 0 8.8826 0 7.15869C0 5.43478 0.684819 3.78148 1.90381 2.5625C3.12279 1.34351 4.77609 0.658691 6.5 0.658691ZM6.5 2.65869C4 2.65869 2 4.65869 2 7.15869C2 9.65869 4 11.6587 6.5 11.6587C9 11.6587 11 9.65869 11 7.15869C11 4.65869 9 2.65869 6.5 2.65869Z"
          fill="#E0E0E0"
        />
      </svg>

      <input
        type="text"
        value={value}
        placeholder={placeholder}
        onChange={({ target }) => onChange(target.value)}
      />
    </div>
  );
}
