import React from "react";
import { Row, Button, Form } from "react-bootstrap";
import axios from "axios";
import { connect } from "react-redux";
import { API_URL } from "../../constants/Globals";
import { withTranslation } from "react-i18next";

class EditBrandAccount extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      notificationType: null,
      successMsg: null,
      loading: true,
      validationErrors: {},
      passwordValidationErrors: {},
      formData: {
        userId: this.props.brandUser["id"],
        name: "",
        username: "",
        email: "",
        bio: "",
        website: "",
        phone: "",
        country: "",
        avatar: "Please enter user avatar Id received from imageUpload api.",
        avatarTempData: "",
        coverImage:
          "Please enter user coverImage Id received from imageUpload api.",
        coverImageTempData: "",
        location: "",
        notifications: 0,
        onboardingSeen: 0,
        embedAds: 1,
        currentPassword: "",
        password: null,
        confirmationPassword: null,
      },
    };
  }

  changeHandler = async (e) => {
    var formData = { ...this.state.formData };
    formData[e.target.name] = e.target.value;
    await this.setState({ formData });
    this.profileFormValidation(e);
    this.passwordFormValidation(e);
  };

  messageNotification = async (type, message) => {
    this.setState({
      notificationType: type,
      successMsg: message,
    });

    setTimeout(() => {
      this.setState({
        successMsg: "",
      });
    }, 5000);
  };

  componentDidMount() {
    this.fetchUserData();
  }

  fetchUserData = async (e) => {
    var formData = { ...this.state.formData };
    formData["name"] = this.props.brandUser["name"];
    formData["username"] = this.props.brandUser["username"];
    formData["email"] = this.props.brandUser["email"];
    formData["bio"] = this.props.brandUser["bio"];
    formData["website"] = this.props.brandUser["website"];
    formData["phone"] = this.props.brandUser["phone"];
    formData["country"] = this.props.brandUser["country"];
    formData["avatar"] = this.props.brandUser["avatar"];
    this.setState({
      avatarDisplay:
        this.props.brandUser["avatarObject"] !== null
          ? this.props.brandUser["avatarObject"]["thumb"]["location"]
          : "",
    });
    formData["coverImage"] = this.props.brandUser["coverImage"];
    this.setState({
      coverImageDisplay:
        this.props.brandUser["coverImageObject"] !== null
          ? this.props.brandUser["coverImageObject"]["small"]["location"]
          : "",
    });
    formData["location"] = this.props.brandUser["location"];

    await this.setState({ formData });
    this.setState({
      loading: false,
    });
  };

  fileChangeHandler = async (e) => {
    if (e.target.type === "file" && e.target.files[0]) {
      const dataAccept = e.target.getAttribute("data-accept")
        ? e.target.getAttribute("data-accept")
        : "jpg|jpeg|gif|png";
      const dataAcceptMsg = e.target.getAttribute("data-accept_msg")
        ? e.target.getAttribute("data-accept_msg")
        : "Image is invalid. Please upload only PNG, JPG or GIF image.";
      var regex = new RegExp("(.*?).(" + dataAccept + ")$");
      if (!regex.test(e.target.files[0].type)) {
        alert(dataAcceptMsg);
        return false;
      }
      if (e.target.files[0].size > 1024 * 20 * 1024) {
        var alertMessage = this.props.t(
          "Image size should not be greater than 20mb",
        );
        alert(alertMessage);
        return false;
      }
      var file = e.target.files[0];
      var thisEl = e;
      var reader = new FileReader();
      reader.onloadend = function (e) {
        var formData = { ...this.state.formData };
        var uploadedImageObj = {
          type: thisEl.target.dataset.imgtype,
          image: thisEl.target.files[0],
        };
        if (thisEl.target.name === "avatar") {
          formData.avatarTempData = uploadedImageObj;
          this.setState({ avatarDisplay: e.target.result });
        } else {
          formData.coverImageTempData = uploadedImageObj;
          this.setState({ coverImageDisplay: e.target.result });
        }

        formData[thisEl.target.name] = e.target.result;

        this.setState({ formData });
      }.bind(this);
      reader.readAsDataURL(file);
    }
  };

  fileRemoveHandler = async (name) => {
    var formData = { ...this.state.formData };

    if (name === "avatar") {
      formData.avatarTempData = "";
      this.setState({ avatarDisplay: "" });
    } else {
      formData.coverImageTempData = "";
      this.setState({ coverImageDisplay: "" });
    }
    formData[name] = "";
    await this.setState({ formData });
  };

  profileFormValidation = async (e = "", action = "") => {
    var validationErrors = { ...this.state.validationErrors };
    if (e.target.name === "name" || action === "onSubmit") {
      if (this.state.formData.name === "") {
        validationErrors["name"] = this.props.t("The name field is required.");
        await this.setState({ validationErrors: validationErrors });
      } else if (this.state.formData.name.length > 50) {
        validationErrors["name"] = this.props.t(
          "Name must be max 50 characters.",
        );
        await this.setState({ validationErrors: validationErrors });
      } else {
        delete validationErrors["name"];
        await this.setState({ validationErrors: validationErrors });
      }
    }

    if (e.target.name === "username" || action === "onSubmit") {
      if (this.state.formData.username === "") {
        validationErrors["username"] = this.props.t(
          "The username field is required.",
        );
        await this.setState({ validationErrors: validationErrors });
      } else if (this.state.formData.username.length < 3) {
        validationErrors["username"] = this.props.t(
          "Username must be at least 3 characters.",
        );
        await this.setState({ validationErrors: validationErrors });
      } else if (this.state.formData.username.length > 20) {
        validationErrors["username"] = this.props.t(
          "Username must be between 3 to 20 characters.",
        );
        await this.setState({ validationErrors: validationErrors });
      } else if (
        this.state.formData.username !== null &&
        this.state.formData.username !== "" &&
        !/^[a-zA-Z0-9_]+$/i.test(this.state.formData.username)
      ) {
        validationErrors["username"] = this.props.t(
          "Use only letter, number and _.",
        );
        await this.setState({ validationErrors: validationErrors });
      } else {
        delete validationErrors["username"];
        await this.setState({ validationErrors: validationErrors });
      }
    }

    if (e.target.name === "email" || action === "onSubmit") {
      if (this.state.formData.email === "") {
        validationErrors["email"] = this.props.t(
          "The email field is required.",
        );
        await this.setState({ validationErrors: validationErrors });
      } else if (
        this.state.formData.email !== null &&
        this.state.formData.email !== "" &&
        !/\S+@\S+\.\S+/.test(this.state.formData.email)
      ) {
        validationErrors["email"] = this.props.t(
          "The email must be a valid email address.",
        );
        await this.setState({ validationErrors: validationErrors });
      } else {
        delete validationErrors["email"];
        await this.setState({ validationErrors: validationErrors });
      }
    }

    if (e.target.name === "website" || action === "onSubmit") {
      if (
        this.state.formData.website !== null &&
        this.state.formData.website !== "" &&
        !/(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-/]))?/.test(
          this.state.formData.website,
        )
      ) {
        validationErrors["website"] = this.props.t(
          "Please enter valid website URL.",
        );
        await this.setState({ validationErrors: validationErrors });
      } else {
        delete validationErrors["website"];
        await this.setState({ validationErrors: validationErrors });
      }
    }

    if (e.target.name === "phone" || action === "onSubmit") {
      if (
        this.state.formData.phone !== null &&
        this.state.formData.phone !== "" &&
        !/^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im.test(
          this.state.formData.phone,
        )
      ) {
        validationErrors["phone"] = this.props.t(
          "Please enter valid Phone Number.",
        );
        await this.setState({ validationErrors: validationErrors });
      } else {
        delete validationErrors["phone"];
        await this.setState({ validationErrors: validationErrors });
      }
    }

    if (e.target.name === "bio" || action === "onSubmit") {
      if (
        this.state.formData.bio !== null &&
        this.state.formData.bio !== "" &&
        this.state.formData.bio.length > 140
      ) {
        validationErrors["bio"] = "Bio must be between 1 to 140 characters.";
        await this.setState({ validationErrors: validationErrors });
      } else {
        delete validationErrors["bio"];
        await this.setState({ validationErrors: validationErrors });
      }
    }

    if (
      Object.keys(this.state.validationErrors).length === 0 &&
      action === "onSubmit"
    ) {
      this.uploadUserImages();
    } else {
      this.setState({
        loading: false,
      });
    }
  };
  passwordFormValidation = async (e = "", action = "") => {
    var passwordValidationErrors = { ...this.state.passwordValidationErrors };

    if (e.target.name === "currentPassword" || action === "onSubmit") {
      if (
        this.state.formData.currentPassword === null ||
        this.state.formData.currentPassword === ""
      ) {
        passwordValidationErrors["currentPassword"] = this.props.t(
          "This field is required.",
        );
        await this.setState({
          passwordValidationErrors: passwordValidationErrors,
        });
      } else if (this.state.formData.currentPassword.length < 6) {
        passwordValidationErrors["currentPassword"] = this.props.t(
          "Password must be at least 6 characters long.",
        );
        await this.setState({
          passwordValidationErrors: passwordValidationErrors,
        });
      } else {
        delete passwordValidationErrors["currentPassword"];
        await this.setState({
          passwordValidationErrors: passwordValidationErrors,
        });
      }
    }

    if (e.target.name === "password" || action === "onSubmit") {
      if (this.state.formData.password === null) {
        passwordValidationErrors["password"] = this.props.t(
          "This field is required.",
        );
        await this.setState({
          passwordValidationErrors: passwordValidationErrors,
        });
      } else if (
        this.state.formData.password !== null &&
        this.state.formData.password.length < 6
      ) {
        passwordValidationErrors["password"] = this.props.t(
          "Password must be at least 6 characters long.",
        );
        await this.setState({
          passwordValidationErrors: passwordValidationErrors,
        });
      } else {
        delete passwordValidationErrors["password"];
        await this.setState({
          passwordValidationErrors: passwordValidationErrors,
        });
      }
    }

    if (e.target.name === "confirmationPassword" || action === "onSubmit") {
      if (this.state.formData.confirmationPassword === null) {
        passwordValidationErrors["confirmationPassword"] = this.props.t(
          "This field is required.",
        );
        await this.setState({
          passwordValidationErrors: passwordValidationErrors,
        });
      } else if (
        this.state.formData.confirmationPassword !== null &&
        this.state.formData.confirmationPassword.length < 6
      ) {
        passwordValidationErrors["confirmationPassword"] = this.props.t(
          "Password must be at least 6 characters long.",
        );
        await this.setState({
          passwordValidationErrors: passwordValidationErrors,
        });
      } else if (
        this.state.formData.confirmationPassword !== null &&
        this.state.formData.confirmationPassword !==
          this.state.formData.password
      ) {
        passwordValidationErrors["confirmationPassword"] = this.props.t(
          "Please enter the same password as above.",
        );
        await this.setState({
          passwordValidationErrors: passwordValidationErrors,
        });
      } else {
        delete passwordValidationErrors["confirmationPassword"];
        await this.setState({
          passwordValidationErrors: passwordValidationErrors,
        });
      }
    }

    if (
      Object.keys(this.state.passwordValidationErrors).length === 0 &&
      action === "onSubmit"
    ) {
      this.passwordFormDataHandle();
    } else {
      this.setState({
        loading: false,
      });
    }
  };

  uploadUserImages = async (e) => {
    let clientId = this.props.selNetworkId;
    const { avatarTempData, coverImageTempData } = this.state.formData;
    var imagesArr = [];
    if (avatarTempData !== "") {
      const formData = new FormData();
      formData.append(
        "backgroundImage",
        avatarTempData.image,
        avatarTempData.image.name,
      );
      imagesArr.push({ image_type: avatarTempData.type, formData: formData });
    }

    if (coverImageTempData !== "") {
      const formData = new FormData();
      formData.append(
        "backgroundImage",
        coverImageTempData.image,
        coverImageTempData.image.name,
      );
      imagesArr.push({
        image_type: coverImageTempData.type,
        formData: formData,
      });
    }

    for (var i = 0; i < imagesArr.length; i++) {
      const arrayImage = imagesArr[i];

      await axios
        .post(
          API_URL +
            "/debate/imageUpload?type=" +
            arrayImage.image_type +
            "&clientId=" +
            clientId,
          arrayImage.formData,
        )
        .then((res) => {
          var formData = { ...this.state.formData };

          if (arrayImage.image_type === "USERS") {
            formData.avatar = res.data.data;
          } else {
            formData.coverImage = res.data.data;
          }
          this.setState({ formData });
        })
        .catch((err) => {
          alert("Image upload for " + arrayImage.image_type + " failed!");
        });
    }

    this.profileFormDataHandle();
  };

  profileFormDataHandle = async (e) => {
    var formDataBody = { ...this.state.formData };

    await axios
      .post(API_URL + "/userSetting/postSettings/", formDataBody)
      .then((res) => {
        this.messageNotification("success", res.data.message);
        setTimeout(window.location.reload(), 500);
      })
      .catch((err) => {
        if (err.response !== undefined) {
          console.log(err.response.data.message[0].error);
          alert(err.response.data.message[0].error);
        }
        this.setState({
          loading: false,
        });
      });
  };

  passwordFormDataHandle = async (e) => {
    var formDataBody = { ...this.state.formData };
    await axios
      .post(API_URL + "/user/updatePassword", formDataBody)
      .then((res) => {
        this.messageNotification("success", res.data.message);
        window.location.reload();
      })
      .catch((err) => {
        if (err.response !== undefined) {
          console.log(err.response.data.message);
          alert(err.response.data.message[0].currentPassword);
        }
        this.setState({
          loading: false,
        });
      });
  };

  submitProfileDataHandle = async (e) => {
    await this.setState({
      loading: true,
    });
    this.profileFormValidation(e, "onSubmit");
    e.preventDefault();
  };

  submitChangePasswordDataHandle = async (e) => {
    await this.setState({
      loading: true,
    });
    this.passwordFormValidation(e, "onSubmit");
    e.preventDefault();
  };

  deleteUserAccount = async (e) => {
    var alertMessage = this.props.t(
      "Are you sure you want to delete this account?",
    );
    if (!window.confirm(alertMessage)) {
      return false;
    }

    var formDataBody = {
      userId: this.props.brandUser["id"],
      clientId: this.props.selNetworkId,
    };

    await axios
      .post(API_URL + "/user/deleteAccount", formDataBody)
      .then((res) => {
        this.messageNotification(
          "success",
          this.props.t("Account deleted successfully"),
        );
        setTimeout(window.location.reload(), 500);
      })
      .catch((err) => {
        if (err.response !== undefined) {
          console.log(err.response.data.message[0].error);
          alert(err.response.data.message[0].error);
        }
        this.setState({
          loading: false,
        });
      });
  };

  cancelBtnClick = async (e) => {
    this.props.parentFunction();
  };

  render(props) {
    const { loading } = this.state;
    const {
      name,
      username,
      email,
      bio,
      website,
      phone,
      location,
      currentPassword,
      password,
      confirmationPassword,
    } = this.state.formData;
    const {
      avatarDisplay,
      coverImageDisplay,
      validationErrors,
      passwordValidationErrors,
    } = this.state;
    const kTrans = this.props.t ? this.props.t : "";
    return (
      <section className="ps-lg-5 pe-lg-5 ms-lg-5 me-lg-5">
        {this.state.successMsg ? (
          <div className="sucessMsg">{this.state.successMsg}</div>
        ) : null}
        <Form
          className="m-0 profileForm common-form row"
          onSubmit={this.submitProfileDataHandle}
        >
          <div
            className={
              loading === true
                ? "loading justify-content-center"
                : "justify-content-center"
            }
          >
            <div className="">
              <Form.Group controlId="name">
                <Form.Label>{kTrans("NAME")}</Form.Label>
                <Form.Control
                  type="text"
                  onChange={this.changeHandler}
                  name="name"
                  value={name}
                />
                {validationErrors && validationErrors.name !== undefined ? (
                  <span className="validation-error">
                    {validationErrors.name}
                  </span>
                ) : null}
              </Form.Group>
              <Form.Group controlId="username">
                <Form.Label>{kTrans("USERNAME")}</Form.Label>
                <Form.Control
                  type="text"
                  onChange={this.changeHandler}
                  name="username"
                  value={username}
                />
                {validationErrors && validationErrors.username !== undefined ? (
                  <span className="validation-error">
                    {validationErrors.username}
                  </span>
                ) : null}
              </Form.Group>
              <Form.Group controlId="email">
                <Form.Label>{kTrans("EMAIL")}</Form.Label>
                <Form.Control
                  type="text"
                  onChange={this.changeHandler}
                  name="email"
                  value={email}
                  placeholder="you@email.com"
                />
                {validationErrors && validationErrors.email !== undefined ? (
                  <span className="validation-error">
                    {validationErrors.email}
                  </span>
                ) : null}
              </Form.Group>
              <Form.Group controlId="phone">
                <Form.Label>{kTrans("PHONE")}</Form.Label>
                <Form.Control
                  type="text"
                  onChange={this.changeHandler}
                  name="phone"
                  value={phone}
                  placeholder="555-555-5555"
                />
                {validationErrors && validationErrors.phone !== undefined ? (
                  <span className="validation-error">
                    {validationErrors.phone}
                  </span>
                ) : null}
              </Form.Group>
              <Form.Group controlId="bio">
                <Form.Label>{kTrans("BIO")}</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  onChange={this.changeHandler}
                  name="bio"
                  value={bio}
                />
                {validationErrors && validationErrors.bio !== undefined ? (
                  <span className="validation-error">
                    {validationErrors.bio}
                  </span>
                ) : null}
              </Form.Group>
              <Form.Group controlId="website">
                <Form.Label>{kTrans("WEBSITE")}</Form.Label>
                <Form.Control
                  type="text"
                  onChange={this.changeHandler}
                  name="website"
                  value={website}
                  placeholder="website.com"
                />
                {validationErrors && validationErrors.website !== undefined ? (
                  <span className="validation-error">
                    {validationErrors.website}
                  </span>
                ) : null}
              </Form.Group>
              <Form.Group controlId="location">
                <Form.Label>{kTrans("LOCATION")}</Form.Label>
                <Form.Control
                  type="text"
                  onChange={this.changeHandler}
                  name="location"
                  value={location}
                  placeholder="Los Angeles, CA"
                />
              </Form.Group>
              <Row className="profileForm">
                <div className="col-sm-4">
                  <Form.Check.Label className="mb-3 mt-2">
                    {kTrans("PROFILE IMAGE")}
                  </Form.Check.Label>
                  <div className="userAvatarWrapper d-inline-block position-relative hasIcon">
                    {avatarDisplay ? (
                      <img
                        className="userAvatar"
                        src={avatarDisplay}
                        alt="User avatar"
                      />
                    ) : (
                      <span className="userAvatarPlaceholder"></span>
                    )}
                    <i
                      className="fa fa-camera upload-label"
                      aria-hidden="true"
                    ></i>
                    <Form.Control
                      data-index="0"
                      data-imgtype="USERS"
                      type="file"
                      name="avatar"
                      onChange={this.fileChangeHandler}
                      data-accept="jpg|jpeg|png"
                      data-accept_msg={kTrans(
                        "Image is invalid. Please upload only PNG or JPG image.",
                      )}
                    />
                    {avatarDisplay ? (
                      <span
                        onClick={() => this.fileRemoveHandler("avatar")}
                        className="customAnchor text-center mt-2"
                      >
                        Remove
                        <svg
                          className="ms-1"
                          width="12"
                          height="14"
                          viewBox="0 0 12 14"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M3.75 0V0.75H0V2.25H0.75V12C0.75 12.3978 0.908035 12.7794 1.18934 13.0607C1.47064 13.342 1.85218 13.5 2.25 13.5H9.75C10.1478 13.5 10.5294 13.342 10.8107 13.0607C11.092 12.7794 11.25 12.3978 11.25 12V2.25H12V0.75H8.25V0H3.75ZM2.25 2.25H9.75V12H2.25V2.25ZM3.75 3.75V10.5H5.25V3.75H3.75ZM6.75 3.75V10.5H8.25V3.75H6.75Z"
                            fill="#BDBDBD"
                          />
                        </svg>
                      </span>
                    ) : null}
                  </div>
                </div>
                <div className="col-sm-8">
                  <Form.Check.Label className="mb-3 mt-2">
                    {kTrans("BACKGROUND IMAGE")}
                  </Form.Check.Label>
                  <div className="userCoverImageWrapper  position-relative hasIcon">
                    {coverImageDisplay ? (
                      <img
                        className="userCoverImage"
                        src={coverImageDisplay}
                        alt="User cover"
                      />
                    ) : (
                      <span className="userCoverImagePlaceholder"></span>
                    )}
                    <i
                      className="fa fa-camera upload-label"
                      aria-hidden="true"
                    ></i>
                    <Form.Control
                      data-index="0"
                      data-imgtype="COVERS"
                      type="file"
                      name="coverImage"
                      onChange={this.fileChangeHandler}
                      data-accept="jpg|jpeg|png"
                      data-accept_msg={kTrans(
                        "Image is invalid. Please upload only PNG or JPG image.",
                      )}
                    />
                  </div>
                  {coverImageDisplay ? (
                    <span
                      onClick={() => this.fileRemoveHandler("coverImage")}
                      className="customAnchor ms-1"
                    >
                      Remove
                      <svg
                        className="ms-1"
                        width="12"
                        height="14"
                        viewBox="0 0 12 14"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M3.75 0V0.75H0V2.25H0.75V12C0.75 12.3978 0.908035 12.7794 1.18934 13.0607C1.47064 13.342 1.85218 13.5 2.25 13.5H9.75C10.1478 13.5 10.5294 13.342 10.8107 13.0607C11.092 12.7794 11.25 12.3978 11.25 12V2.25H12V0.75H8.25V0H3.75ZM2.25 2.25H9.75V12H2.25V2.25ZM3.75 3.75V10.5H5.25V3.75H3.75ZM6.75 3.75V10.5H8.25V3.75H6.75Z"
                          fill="#BDBDBD"
                        />
                      </svg>
                    </span>
                  ) : null}
                </div>
              </Row>
              <div class="inline-content middle mt-3">
                <span
                  onClick={this.deleteUserAccount}
                  className="customAnchor red"
                >
                  {kTrans("Delete Account")}
                </span>
                <div className="buttons">
                  <span
                    onClick={this.cancelBtnClick}
                    className="mt-0 me-0 btn btn-secondary"
                  >
                    {kTrans("Cancel")}
                  </span>
                  <Button variant="primary" className="mt-0 me-0" type="submit">
                    {kTrans("Save")}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </Form>
        <hr className="customHr" />
        <Form
          className="profileForm common-form"
          onSubmit={this.submitChangePasswordDataHandle}
        >
          <div
            className={
              loading === true
                ? "loading justify-content-center"
                : "justify-content-center"
            }
          >
            <h3 className="pt-3 pb-3 lightColor">{kTrans("PASSWORD")}</h3>

            <Form.Group controlId="currentPassword">
              <Form.Label>{kTrans("OLD PASSWORD")}</Form.Label>
              <Form.Control
                type="text"
                onChange={this.changeHandler}
                name="currentPassword"
                value={currentPassword}
              />
              {passwordValidationErrors &&
              passwordValidationErrors.currentPassword !== undefined ? (
                <span className="validation-error">
                  {passwordValidationErrors.currentPassword}
                </span>
              ) : null}
            </Form.Group>

            <Form.Group controlId="password">
              <Form.Label>{kTrans("NEW PASSWORD")}</Form.Label>
              <Form.Control
                type="text"
                onChange={this.changeHandler}
                name="password"
                value={password}
              />
              {passwordValidationErrors &&
              passwordValidationErrors.password !== undefined ? (
                <span className="validation-error">
                  {passwordValidationErrors.password}
                </span>
              ) : null}
            </Form.Group>
            <Form.Group controlId="confirmationPassword">
              <Form.Label>{kTrans("CONFIRM NEW PASSWORD")}</Form.Label>
              <Form.Control
                type="text"
                onChange={this.changeHandler}
                name="confirmationPassword"
                value={confirmationPassword}
              />
              {passwordValidationErrors &&
              passwordValidationErrors.confirmationPassword !== undefined ? (
                <span className="validation-error">
                  {passwordValidationErrors.confirmationPassword}
                </span>
              ) : null}
            </Form.Group>
            <div class="inline-content middle mt-3">
              <span className="customAnchor danger"></span>
              <div className="buttons">
                <span
                  onClick={this.cancelBtnClick}
                  className="mt-0 me-0 btn btn-secondary"
                >
                  {kTrans("Cancel")}
                </span>
                <Button variant="primary" className="mt-0 me-0" type="submit">
                  {kTrans("Update")}
                </Button>
              </div>
            </div>
          </div>
        </Form>
      </section>
    );
  }
}

const mapStateToProps = (state) => ({
  loggedInUser: state.login.user,
  selNetworkId: state.networks.selNetworkId,
});

const Extended = withTranslation()(EditBrandAccount);
Extended.static = EditBrandAccount.static;

export default connect(mapStateToProps)(Extended);
