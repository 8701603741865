import React from "react";
import { Table } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCog } from "@fortawesome/free-solid-svg-icons";
import { connect } from "react-redux";
import { DEFAULT_USER_IMG_URL } from "../../constants/Globals";
import { loadNetworks } from "../../redux/action/networks.action";
import { withTranslation } from "react-i18next";

class Networks extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      pageNumber: 1,
      searchText: "",
      order: "",
      orderBy: "title",
      filter: props.location.state ? props.location.state.filter : "ACTIVE",
    };
  }

  getNetworks = async () => {
    await this.props.loadNetworks(
      this.state.searchText,
      this.state.pageNumber,
      this.state.order,
      this.state.orderBy,
      this.state.filter,
    );
  };

  componentDidMount = () => {
    this.getNetworks();
  };

  handleNetworksFilter = async (e) => {
    await this.setState({
      filter: e.target.value,
      pageNumber: 1,
    });
    this.getNetworks();
    e.preventDefault();
  };

  render() {
    const { networks } = this.props;
    const loggedInUser = this.props.loggedInUser;
    const kTrans = this.props.t ? this.props.t : "";

    return (
      <section>
        <div className="inline-content top title-section">
          <h2>{kTrans("Sided Networks")}</h2>
          {loggedInUser &&
          loggedInUser.roles &&
          loggedInUser.roles.length > 0 &&
          loggedInUser.roles.find((role) => role.name === "SUPERADMIN") ? (
            <NavLink className="btn btn-primary" to="/networks/add-network">
              {kTrans("ADD NETWORK")}
            </NavLink>
          ) : null}
        </div>
        {loggedInUser &&
        loggedInUser.roles &&
        loggedInUser.roles.length > 0 &&
        loggedInUser.roles.find((role) => role.name === "SUPERADMIN") ? (
          <div className="pull-right-lg mb-3">
            <span className="me-2">{kTrans("Filter networks")}: </span>
            <select
              className="p-1"
              defaultValue={this.state.filter}
              id="network-filter"
              onChange={this.handleNetworksFilter}
            >
              <option value="ACTIVE">{kTrans("Active networks")}</option>
              <option value="ARCHIVED">{kTrans("Archived networks")}</option>
            </select>
          </div>
        ) : null}
        <Table className="networks tableCommon">
          <thead>
            <tr>
              <th>{kTrans("NETWORK")}</th>
              <th>{kTrans("Users")}</th>
              <th>{kTrans("POLLS")}</th>
              <th>{kTrans("SETTINGS")}</th>
            </tr>
          </thead>
          <tbody>
            {networks && networks.length ? (
              networks.map((network) => (
                <tr key={network.id}>
                  <td>
                    <div className="author">
                      <img
                        data-src=""
                        src={network.header.favicon}
                        alt={network.name}
                        className="sidedLazyLoad network-favicon"
                        onError={(e) => {
                          e.target.src = DEFAULT_USER_IMG_URL;
                        }}
                      />
                      <div className="copy-wrap">
                        <span className="networkname">{network.name}</span>
                        <span className="networkslug">
                          {network.siteDomain}
                        </span>
                      </div>
                    </div>
                  </td>
                  <td>{network.followersCount}</td>
                  <td>{network.debatesCount}</td>
                  <td>
                    <NavLink to={`/networks/edit-network/${network.id}`}>
                      <FontAwesomeIcon icon={faCog} className="svg settings" />
                    </NavLink>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="4">
                  <h3 className="text-center">{kTrans("No Network")}</h3>
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </section>
    );
  }
}

const mapStateToProps = (state) => ({
  networks: state.networks.networks,
  loggedInUser: state.login.user,
});

const Extended = withTranslation()(Networks);
Extended.static = Networks.static;

export default connect(mapStateToProps, { loadNetworks })(Extended);
