import { useState, useEffect } from "react";
import axios from "axios";
import moment from "moment";
import Loader from "../Loader";
import logo from "../../logo.png";
import AppVersion from "../AppVersion";
import { Row, Col } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { storeUserId } from "../../helpers/Session";
import DateRangePicker from "react-bootstrap-daterangepicker";
import { API_URL, SIDED_FAVICON, BASENAME } from "../../constants/Globals";

function isPhoneValid(phone) {
  if (phone !== "") {
    return /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/.test(phone);
  }

  return true;
}

class LoginForm {
  constructor() {
    this.username = "";
    this.password = "";
  }
}

class StepOneForm {
  constructor() {
    this.name = "";
    this.email = "";
    this.phone = "";
    this.verify = "email";
    this.username = "";
    this.password = "";
    this.terms = false;
  }
}

class StepTwoForm {
  constructor() {
    this.digit1 = "";
    this.digit2 = "";
    this.digit3 = "";
    this.digit4 = "";
    this.digit5 = "";
    this.digit6 = "";
  }
}

class CreateNetworkForm {
  constructor() {
    this.companyName = "";
    this.primaryDomain = "";
    this.sidedSubdomain = "";
  }
}

class CreatePollForm {
  constructor() {
    this.categoryId = 0;
    this.thesis = "";
    this.sides = ["", ""];
    this.startedAt = moment();
    this.endedAt = moment().add(3, "days");
    this.noEndDate = false;
  }
}

const formSteps = {
  JOIN_ACCEPTANCE: "join_acceptance",
  ACCOUNT_INFO: "account_info",
  CODE_VERIFICATION: "code_verification",
  CREATE_NETWORK: "create_nework",
  CREATE_POLL: "create_poll",
  EMBED_POLL: "embed_poll",
  LOGIN: "login",
  INVITE_EXPIRED: "invite_expired",
  NOT_ADMIN_MESSAGE: "not_admin_message",
};

export default function Onboarding({
  user,
  token,
  isLogin,
  networks,
  recipient,
  isVerified,
  initialStep,
  isInvitation,
  hideStepInfo,
  hideLoginAlt,
  currentGroup,
  currentNetwork,
  refreshClients,
  invitationSender,
}) {
  const [loading, setLoading] = useState(false);
  const [loadingIntegration, setLoadingIntegration] = useState(false);
  const [currentStep, setCurrentStep] = useState(
    initialStep
      ? initialStep
      : isLogin
        ? isVerified
          ? window.location.href.indexOf("not-admin") !== -1
            ? formSteps.NOT_ADMIN_MESSAGE
            : formSteps.CREATE_POLL
          : formSteps.CODE_VERIFICATION
        : formSteps.ACCOUNT_INFO,
  );
  const [loginForm, setLoginForm] = useState(new LoginForm());
  const [stepOneForm, setStepOneForm] = useState(new StepOneForm());
  const [stepTwoForm, setStepTwoForm] = useState(new StepTwoForm());
  const [createNetworkForm, setCreateNetworkForm] = useState(
    new CreateNetworkForm(),
  );
  const [createPollForm, setCreatePollForm] = useState(new CreatePollForm());
  const [validationErrors, setValidationErrors] = useState({});
  const [createdDebate, setCreatedDebate] = useState({});
  const [companyLogo, setCompanyLogo] = useState("");
  const [newClientId, setNewClientId] = useState(0);

  const heedHelpAction =
    "mailto:support@sided.co?subject=Sided support request";

  const domain = currentNetwork
    ? currentNetwork.embedDomains && currentNetwork.embedDomains[0]
      ? currentNetwork.embedDomains[0].domain
      : currentNetwork.name
    : "Sided";
  const senderEmail = invitationSender ? invitationSender.email : "";
  const senderName = invitationSender ? invitationSender.username : "";
  const networkLogo = currentNetwork
    ? currentNetwork.header.favicon
    : SIDED_FAVICON;

  function updateModel(obj, data = {}) {
    return Object.assign({}, obj, data);
  }

  useEffect(() => {
    setStepOneForm((stepOneForm) =>
      updateModel(stepOneForm, { email: recipient }),
    );
    setLoginForm((loginForm) =>
      updateModel(loginForm, { username: recipient }),
    );
  }, [recipient]);

  useEffect(() => {
    if (
      networks &&
      networks.length &&
      currentStep === formSteps.CREATE_NETWORK
    ) {
      window.location.href = '/';
    }
  }, [networks, currentStep]);

  return (
    <>
      <header className="sticky-header">
        <NavLink exact to="/" className="navbar-brand">
          <img alt="Logo" src={logo} className="logo" />
        </NavLink>
      </header>

      {loading ? <Loader transparent={true} /> : null}

      <main className="body for-stickies">
        {currentStep === formSteps.ACCOUNT_INFO && isInvitation ? (
          <div className="pre-auth-box">
            <strong>{senderName}</strong> ({senderEmail}) has invited you to use
            Sided for the domain
            <br />
            <strong>{domain}</strong>. Create your account below to join:
          </div>
        ) : (
          false
        )}

        {currentStep === formSteps.LOGIN && isInvitation ? (
          <div className="pre-auth-box">
            <strong>{senderName}</strong> ({senderEmail}) has invited you to use
            Sided for the domain
            <br />
            <strong>{domain}</strong>. Login below to join:
          </div>
        ) : (
          false
        )}

        <div className="auth-box">
          {currentStep === formSteps.NOT_ADMIN_MESSAGE ? (
            <div className="auth-box-inner">
              <div className="auth-box-header">
                <span></span>
                <a className="text-button" href={heedHelpAction}>
                  Need Help?
                </a>
              </div>
              <figure className="auth-box-logo">
                <img
                  src={networkLogo}
                  alt="Sided"
                  onError={(e) => {
                    e.target.src = SIDED_FAVICON;
                  }}
                />
              </figure>
              <div className="auth-box-title">Welcome Back {user.username}</div>
              <div className="auth-box-subtitle">
                Your account doesn't have admin access to any networks.{" "}
                <span
                  className="text-button inherit-font"
                  onClick={() =>
                    (window.location.href = BASENAME + "/onboarding")
                  }
                >
                  Create a network
                </span>{" "}
                or contact support@sided.co if you think this is an error.
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
              </div>
            </div>
          ) : null}

          {currentStep === formSteps.INVITE_EXPIRED ? (
            <div className="auth-box-inner">
              <figure className="auth-box-logo">
                <img
                  src={networkLogo}
                  alt="Sided"
                  onError={(e) => {
                    e.target.src = SIDED_FAVICON;
                  }}
                />
              </figure>
              <div className="auth-box-title">This invite has expired</div>
              <div className="auth-box-subtitle">
                Please contact ({senderEmail}) to request a new invite
              </div>
              <div className="end-option">
                <a className="text-button" href={heedHelpAction}>
                  Need Help?
                </a>
              </div>
            </div>
          ) : null}

          {currentStep === formSteps.JOIN_ACCEPTANCE ? (
            <div className="auth-box-inner">
              <figure className="auth-box-logo">
                <img
                  src={networkLogo}
                  alt="Sided"
                  onError={(e) => {
                    e.target.src = SIDED_FAVICON;
                  }}
                />
              </figure>
              <div className="auth-box-title">
                Join {currentGroup ? currentGroup.name : domain}
              </div>
              <div className="auth-box-subtitle">
                {senderName} ({senderEmail}) has invited you to use Sided for
                the
                {currentGroup
                  ? ` group ${currentGroup.name}`
                  : ` domain ${domain}`}
              </div>
              <div className="button-holder">
                <button
                  className="btn"
                  onClick={() => {
                    setLoading(true);

                    axios
                      .post(
                        API_URL +
                          `/${currentGroup ? "admin/groups" : "user"}/acceptInvite/${token}`,
                      )
                      .then(({ data }) => {
                        alert(data.message);
                        window.location.href = '/';
                      })
                      .catch(({ response }) => {
                        const errors = {};
                        response.data.message.forEach(
                          (err) =>
                            (errors[Object.keys(err)[0]] =
                              Object.values(err)[0]),
                        );

                        setLoading(false);
                        setValidationErrors(errors);
                      });
                  }}
                >
                  Join Now
                </button>
              </div>
              {validationErrors.error ? (
                <div className="validation-error text-center">
                  {validationErrors.error}
                </div>
              ) : null}
              <div className="end-option">
                <a className="text-button" href={heedHelpAction}>
                  Need Help?
                </a>
              </div>
            </div>
          ) : null}

          {currentStep === formSteps.ACCOUNT_INFO ? (
            <div className="auth-box-inner">
              {hideStepInfo ? null : (
                <div className="auth-box-header">
                  <div className="auth-box-step">Step 1 of 2</div>
                </div>
              )}
              <figure className="auth-box-logo">
                <img
                  src={networkLogo}
                  alt="Sided"
                  onError={(e) => {
                    e.target.src = SIDED_FAVICON;
                  }}
                />
              </figure>
              <div className="auth-box-title">Create Your Account</div>
              <div className="auth-box-subtitle">
                Your Sided account gives you access to polls and conversations
                from your favorite publishers all around the world. Take a side
                and be heard!
              </div>

              <form
                onSubmit={(event) => {
                  event.preventDefault();
                  setValidationErrors({});

                  if (!isPhoneValid(stepOneForm.phone)) {
                    setValidationErrors({
                      phone: "Please enter valid phone number",
                    });
                  } else if (
                    stepOneForm.verify === "phone" &&
                    stepOneForm.phone.trim() === ""
                  ) {
                    setValidationErrors({
                      phone:
                        "Please enter your phone number or select email for verification",
                    });
                  } else {
                    setLoading(true);

                    axios
                      .post(API_URL + "/user/signup", {
                        name: stepOneForm.name,
                        email: stepOneForm.email,
                        phone: stepOneForm.phone,
                        username: stepOneForm.username,
                        password: stepOneForm.password,
                        confirmationPassword: stepOneForm.password,
                        agree: stepOneForm.terms,
                        verifyAccount: stepOneForm.verify,
                        deviceId: "deviceId",
                      })
                      .then(async ({ data }) => {
                        await storeUserId(
                          data.data.user.id,
                          data.token,
                          data.tokenExpireAt,
                          data.refreshToken,
                          data.refreshTokenExpireAt,
                        );
                        setLoading(false);
                        setCurrentStep(formSteps.CODE_VERIFICATION);
                      })
                      .catch(({ response }) => {
                        const errors = {};
                        response.data.message.forEach(
                          (err) =>
                            (errors[
                              Object.keys(err)[0].replace(
                                "username-length",
                                "username",
                              )
                            ] = Object.values(err)[0]),
                        );

                        setLoading(false);
                        setValidationErrors(errors);
                      });
                  }
                }}
              >
                <fieldset>
                  <input
                    type="text"
                    placeholder="Name"
                    onChange={({ target }) => {
                      setStepOneForm(
                        updateModel(stepOneForm, { name: target.value }),
                      );
                      setValidationErrors({});
                    }}
                    value={stepOneForm.name}
                    className="form-control"
                  />
                  {validationErrors.name ? (
                    <div className="validation-error">
                      {validationErrors.name}
                    </div>
                  ) : null}
                  <input
                    type="email"
                    placeholder="Email"
                    onChange={({ target }) => {
                      setStepOneForm(
                        updateModel(stepOneForm, { email: target.value }),
                      );
                      setValidationErrors({});
                    }}
                    value={stepOneForm.email}
                    className="form-control"
                  />
                  {validationErrors.email ? (
                    <div className="validation-error">
                      {validationErrors.email}
                    </div>
                  ) : null}
                  <input
                    type="tel"
                    placeholder="Phone"
                    onChange={({ target }) => {
                      setStepOneForm(
                        updateModel(stepOneForm, { phone: target.value }),
                      );

                      if (!isPhoneValid(target.value)) {
                        setValidationErrors({
                          phone: "Please enter valid phone number",
                        });
                      } else {
                        setValidationErrors({});
                      }
                    }}
                    value={stepOneForm.phone}
                    className="form-control"
                  />
                  {validationErrors.phone ? (
                    <div className="validation-error">
                      {validationErrors.phone}
                    </div>
                  ) : null}
                  <div className="multi-check">
                    Verify by
                    <label>
                      <input
                        type="checkbox"
                        checked={stepOneForm.verify === "phone"}
                        onChange={() => {
                          setStepOneForm(
                            updateModel(stepOneForm, { verify: "phone" }),
                          );
                          setValidationErrors({});
                        }}
                      />
                      SMS
                    </label>
                    <label>
                      <input
                        type="checkbox"
                        checked={stepOneForm.verify === "email"}
                        onChange={() => {
                          setStepOneForm(
                            updateModel(stepOneForm, { verify: "email" }),
                          );
                          setValidationErrors({});
                        }}
                      />
                      Email
                    </label>
                  </div>
                  <input
                    type="text"
                    placeholder="Username"
                    onChange={({ target }) => {
                      setStepOneForm(
                        updateModel(stepOneForm, { username: target.value }),
                      );
                      setValidationErrors({});
                    }}
                    value={stepOneForm.username}
                    className="form-control"
                  />
                  {validationErrors.username ? (
                    <div className="validation-error">
                      {validationErrors.username}
                    </div>
                  ) : null}
                  <input
                    type="password"
                    placeholder="Password"
                    onChange={({ target }) => {
                      setStepOneForm(
                        updateModel(stepOneForm, { password: target.value }),
                      );
                      setValidationErrors({});
                    }}
                    value={stepOneForm.password}
                    className="form-control"
                  />
                  {validationErrors.password ? (
                    <div className="validation-error">
                      {validationErrors.password}
                    </div>
                  ) : null}
                  <label className="text-line">
                    <input
                      type="checkbox"
                      checked={stepOneForm.terms}
                      onChange={() => {
                        setStepOneForm(
                          updateModel(stepOneForm, {
                            terms: !stepOneForm.terms,
                          }),
                        );
                        setValidationErrors({});
                      }}
                    />
                    I have read and agree to Sided's
                    <a href="/terms-of-service" target="_blank">
                      Terms of Service
                    </a>
                    and
                    <a href="/privacy-policy" target="_blank">
                      Privacy Policy
                    </a>
                  </label>
                  {validationErrors.agree ? (
                    <div className="validation-error">
                      {validationErrors.agree}
                    </div>
                  ) : null}
                </fieldset>
                {validationErrors.error ? (
                  <div className="validation-error">
                    {validationErrors.error}
                  </div>
                ) : null}
                <div className="button-holder">
                  <button className="btn">Sign Up</button>
                </div>
                {hideLoginAlt ? null : (
                  <div className="text-line">
                    Already have an account? Login
                    <span
                      className="text-button"
                      onClick={() => setCurrentStep(formSteps.LOGIN)}
                    >
                      here
                    </span>
                  </div>
                )}
              </form>
            </div>
          ) : null}

          {currentStep === formSteps.CODE_VERIFICATION ? (
            <div className="auth-box-inner">
              <div className="auth-box-header">
                {hideStepInfo ? (
                  <span></span>
                ) : (
                  <div className="auth-box-step">Step 2 of 2</div>
                )}

                <a className="text-button" href={heedHelpAction}>
                  Need Help?
                </a>
              </div>
              <figure className="auth-box-logo">
                <img
                  src={networkLogo}
                  alt="Sided"
                  onError={(e) => {
                    e.target.src = SIDED_FAVICON;
                  }}
                />
              </figure>
              <div className="auth-box-title">Enter Verification Code</div>
              <div className="auth-box-subtitle">
                We just sent you a{" "}
                {stepOneForm.verify === "phone" ? "text" : "email"}.
                <br />
                Enter the code below to verify your account.
              </div>

              <form
                onSubmit={(event) => {
                  event.preventDefault();
                  setValidationErrors({});
                  setLoading(true);

                  axios
                    .post(
                      API_URL +
                        "/user/" +
                        (stepOneForm.verify === "phone"
                          ? "verifyUserPhone"
                          : "verifyUserEmail"),
                      {
                        verificationCode: [
                          stepTwoForm.digit1,
                          stepTwoForm.digit2,
                          stepTwoForm.digit3,
                          stepTwoForm.digit4,
                          stepTwoForm.digit5,
                          stepTwoForm.digit6,
                        ].join(""),
                      },
                    )
                    .then(({ data }) => {
                      setLoading(false);

                      if (data.status === "success") {
                        if (isInvitation) {
                          setCurrentStep(formSteps.JOIN_ACCEPTANCE);
                        } else {
                          window.location.href = '/';
                        }
                      } else {
                        alert(data.message);
                      }
                    })
                    .catch(({ response }) => {
                      const errors = {};
                      response.data.message.forEach(
                        (err) => (errors.error = Object.values(err)[0]),
                      );

                      setLoading(false);
                      setValidationErrors(errors);
                    });
                }}
              >
                <fieldset className="code-fields">
                  <input
                    type="text"
                    onChange={({ target }) =>
                      setStepTwoForm(
                        updateModel(stepTwoForm, { digit1: target.value }),
                      )
                    }
                    onInput={({ target }) => {
                      if (target.value.trim().length > 0)
                        target.nextSibling.focus();
                    }}
                    value={stepTwoForm.digit1}
                    className="form-control"
                    maxLength="1"
                  />
                  <input
                    type="text"
                    onChange={({ target }) =>
                      setStepTwoForm(
                        updateModel(stepTwoForm, { digit2: target.value }),
                      )
                    }
                    onInput={({ target }) => {
                      if (target.value.trim().length > 0)
                        target.nextSibling.focus();
                    }}
                    value={stepTwoForm.digit2}
                    className="form-control"
                    maxLength="1"
                  />
                  <input
                    type="text"
                    onChange={({ target }) =>
                      setStepTwoForm(
                        updateModel(stepTwoForm, { digit3: target.value }),
                      )
                    }
                    onInput={({ target }) => {
                      if (target.value.trim().length > 0)
                        target.nextSibling.focus();
                    }}
                    value={stepTwoForm.digit3}
                    className="form-control"
                    maxLength="1"
                  />
                  <input
                    type="text"
                    onChange={({ target }) =>
                      setStepTwoForm(
                        updateModel(stepTwoForm, { digit4: target.value }),
                      )
                    }
                    onInput={({ target }) => {
                      if (target.value.trim().length > 0)
                        target.nextSibling.focus();
                    }}
                    value={stepTwoForm.digit4}
                    className="form-control"
                    maxLength="1"
                  />
                  <input
                    type="text"
                    onChange={({ target }) =>
                      setStepTwoForm(
                        updateModel(stepTwoForm, { digit5: target.value }),
                      )
                    }
                    onInput={({ target }) => {
                      if (target.value.trim().length > 0)
                        target.nextSibling.focus();
                    }}
                    value={stepTwoForm.digit5}
                    className="form-control"
                    maxLength="1"
                  />
                  <input
                    type="text"
                    onChange={({ target }) =>
                      setStepTwoForm(
                        updateModel(stepTwoForm, { digit6: target.value }),
                      )
                    }
                    value={stepTwoForm.digit6}
                    className="form-control"
                    maxLength="1"
                  />
                </fieldset>
                <div className="end-option">
                  {user && user.phone ? (
                    <>
                      <span
                        className="text-button"
                        onClick={() => {
                          setLoading(true);

                          axios
                            .get(API_URL + "/user/resendVerifyUserPhone")
                            .then(({ data }) => {
                              if (data.status === "success") {
                                alert(data.message);
                              } else {
                                alert(JSON.stringify(data.data));
                              }

                              setLoading(false);
                            })
                            .catch(({ response }) => {
                              const errors = {};
                              response.data.message.forEach(
                                (err) =>
                                  (errors[Object.keys(err)[0]] =
                                    Object.values(err)[0]),
                              );

                              setLoading(false);
                              setValidationErrors(errors);
                            });
                        }}
                      >
                        Resend code by phone
                      </span>
                      <br />
                      <br />
                    </>
                  ) : null}
                  <span
                    className="text-button"
                    onClick={() => {
                      setLoading(true);

                      axios
                        .get(API_URL + "/user/resendVerifyUserEmail")
                        .then(({ data }) => {
                          if (data.status === "success") {
                            alert(data.message);
                          } else {
                            alert(JSON.stringify(data.data));
                          }

                          setLoading(false);
                        })
                        .catch(({ response }) => {
                          const errors = {};
                          response.data.message.forEach(
                            (err) =>
                              (errors[Object.keys(err)[0]] =
                                Object.values(err)[0]),
                          );

                          setLoading(false);
                          setValidationErrors(errors);
                        });
                    }}
                  >
                    Resend code by email
                  </span>
                </div>
                {validationErrors.error ? (
                  <div className="validation-error text-center">
                    {validationErrors.error}
                  </div>
                ) : null}
                <div className="button-holder">
                  <button className="btn">Submit</button>
                </div>
              </form>
            </div>
          ) : null}

          {currentStep === formSteps.CREATE_NETWORK && false ? (
            <div className="auth-box-inner">
              <div className="auth-box-header">
                <div className="auth-box-step">Step 3 of 4</div>

                <a className="text-button" href={heedHelpAction}>
                  Need Help?
                </a>
              </div>

              <figure className="auth-box-logo">
                <img
                  src={networkLogo}
                  alt="Sided"
                  onError={(e) => {
                    e.target.src = SIDED_FAVICON;
                  }}
                />
              </figure>
              <div className="auth-box-title">Add Your Domain</div>
              <div className="auth-box-subtitle">
                Add your company/brand name here, this is how other Sided users
                will see your profile, you can change this later if needed.
              </div>

              <form
                onSubmit={(event) => {
                  event.preventDefault();
                  setValidationErrors({});

                  if (createNetworkForm.companyName.trim() === "") {
                    setValidationErrors({
                      name: "The company name is required",
                    });
                  } else if (
                    createNetworkForm.primaryDomain.trim().indexOf(".") === -1
                  ) {
                    setValidationErrors({
                      primaryDomain: "The domain entered is invalid",
                    });
                  } else if (createNetworkForm.sidedSubdomain.trim() === "") {
                    setValidationErrors({
                      subDomain: "The subdomain is required",
                    });
                  } else {
                    setLoading(true);

                    axios
                      .post(API_URL + "/admin/client/create", {
                        name: createNetworkForm.companyName.replaceAll(" ", ""),
                        title: createNetworkForm.companyName,
                        primaryDomain: createNetworkForm.primaryDomain,
                        subDomain:
                          createNetworkForm.sidedSubdomain + ".sided.co",
                        advertisingSource: "publisher_desk",
                        embedPlacements: [
                          { placementName: "End of Content" },
                          { placementName: "Sidebar", adPosition: "bottom" },
                          { placementName: "In Content" },
                        ],
                        linkAdmin: true,
                      })
                      .then(({ data }) => {
                        setLoading(false);

                        if (data.status === "success") {
                          setCurrentStep(formSteps.CREATE_POLL);
                          setNewClientId(data.data.id);
                          refreshClients();
                        } else {
                          alert(data.message);
                        }
                      })
                      .catch(({ response }) => {
                        const errors = {};
                        response.data.message.forEach(
                          (err) =>
                            (errors[Object.keys(err)[0]] =
                              Object.values(err)[0]),
                        );
                        setLoading(false);
                        setValidationErrors(errors);
                      });
                  }
                }}
              >
                <fieldset>
                  <label>COMPANY NAME</label>
                  <input
                    type="text"
                    placeholder="Name"
                    onChange={({ target }) =>
                      setCreateNetworkForm(
                        updateModel(createNetworkForm, {
                          companyName: target.value,
                        }),
                      )
                    }
                    value={createNetworkForm.companyName}
                    className="form-control mb2x"
                  />
                  {validationErrors.name ? (
                    <div className="validation-error">
                      {validationErrors.name}
                    </div>
                  ) : null}
                  <label>PRIMARY DOMAIN</label>
                  <input
                    type="text"
                    placeholder="yourdomain.com"
                    onChange={({ target }) =>
                      setCreateNetworkForm(
                        updateModel(createNetworkForm, {
                          primaryDomain: target.value,
                        }),
                      )
                    }
                    value={createNetworkForm.primaryDomain}
                    className="form-control mb2x"
                  />
                  {validationErrors.primaryDomain ? (
                    <div className="validation-error">
                      {validationErrors.primaryDomain}
                    </div>
                  ) : null}
                  <label>SIDED SUBDOMAIN</label>
                  <div className="input-group">
                    <input
                      type="text"
                      placeholder="yoursubdomain"
                      onChange={({ target }) =>
                        setCreateNetworkForm(
                          updateModel(createNetworkForm, {
                            sidedSubdomain: target.value,
                          }),
                        )
                      }
                      value={createNetworkForm.sidedSubdomain}
                      className="form-control mb2x"
                    />
                    <div className="input-group-prepend">
                      <div className="input-group-text">.sided.co</div>
                    </div>
                  </div>
                  <div className="input-info after-mb2x">
                    This is used if comments are directed to sided.co
                  </div>
                  {validationErrors.subDomain ? (
                    <div className="validation-error">
                      {validationErrors.subDomain}
                    </div>
                  ) : null}
                  <label>ADD COMPANY LOGO</label>
                  <div className="logo-uploader">
                    <div className="logo-uploader-inner">
                      <input
                        type="file"
                        onChange={({ target }) => {
                          const file = target.files[0];

                          if (file) {
                            const reader = new FileReader();
                            reader.readAsDataURL(file);
                            reader.onload = () => setCompanyLogo(reader.result);
                          }
                        }}
                      />
                      {companyLogo ? (
                        <img src={companyLogo} alt="Company logo" />
                      ) : null}
                      <svg
                        width="28"
                        height="27"
                        viewBox="0 0 28 27"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g filter="url(#filter0_d_8513_16919)">
                          <path
                            d="M22 2.11111H18.83L17 0H11L9.17 2.11111H6C5.46957 2.11111 4.96086 2.33353 4.58579 2.72944C4.21071 3.12535 4 3.66232 4 4.22222V16.8889C4 17.4488 4.21071 17.9858 4.58579 18.3817C4.96086 18.7776 5.46957 19 6 19H22C22.5304 19 23.0391 18.7776 23.4142 18.3817C23.7893 17.9858 24 17.4488 24 16.8889V4.22222C24 3.66232 23.7893 3.12535 23.4142 2.72944C23.0391 2.33353 22.5304 2.11111 22 2.11111ZM22 16.8889H6V4.22222H10.05L11.88 2.11111H16.12L17.95 4.22222H22V16.8889ZM14 5.27778C12.6739 5.27778 11.4021 5.83383 10.4645 6.8236C9.52678 7.81338 9 9.1558 9 10.5556C9 11.9553 9.52678 13.2977 10.4645 14.2875C11.4021 15.2773 12.6739 15.8333 14 15.8333C15.3261 15.8333 16.5979 15.2773 17.5355 14.2875C18.4732 13.2977 19 11.9553 19 10.5556C19 9.1558 18.4732 7.81338 17.5355 6.8236C16.5979 5.83383 15.3261 5.27778 14 5.27778ZM14 13.7222C13.2044 13.7222 12.4413 13.3886 11.8787 12.7947C11.3161 12.2009 11 11.3954 11 10.5556C11 9.7157 11.3161 8.91025 11.8787 8.31638C12.4413 7.72252 13.2044 7.38889 14 7.38889C14.7956 7.38889 15.5587 7.72252 16.1213 8.31638C16.6839 8.91025 17 9.7157 17 10.5556C17 11.3954 16.6839 12.2009 16.1213 12.7947C15.5587 13.3886 14.7956 13.7222 14 13.7222Z"
                            fill="white"
                          />
                        </g>
                        <defs>
                          <filter
                            id="filter0_d_8513_16919"
                            x="0"
                            y="0"
                            width="28"
                            height="27"
                            filterUnits="userSpaceOnUse"
                            colorInterpolationFilters="sRGB"
                          >
                            <feFlood
                              floodOpacity="0"
                              result="BackgroundImageFix"
                            />
                            <feColorMatrix
                              in="SourceAlpha"
                              type="matrix"
                              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                              result="hardAlpha"
                            />
                            <feOffset dy="4" />
                            <feGaussianBlur stdDeviation="2" />
                            <feComposite in2="hardAlpha" operator="out" />
                            <feColorMatrix
                              type="matrix"
                              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                            />
                            <feBlend
                              mode="normal"
                              in2="BackgroundImageFix"
                              result="effect1_dropShadow_8513_16919"
                            />
                            <feBlend
                              mode="normal"
                              in="SourceGraphic"
                              in2="effect1_dropShadow_8513_16919"
                              result="shape"
                            />
                          </filter>
                        </defs>
                      </svg>
                    </div>
                  </div>
                  <div className="logo-uploader-note">[Optional]</div>
                </fieldset>
                {validationErrors.error ? (
                  <div className="validation-error text-center">
                    {validationErrors.error}
                  </div>
                ) : null}
                <div className="button-holder has-two">
                  <button
                    type="button"
                    className="btn grayed"
                    onClick={() => setCurrentStep(formSteps.CODE_VERIFICATION)}
                  >
                    Back
                  </button>
                  <button type="submit" className="btn">
                    Next
                  </button>
                </div>
              </form>
            </div>
          ) : null}

          {currentStep === formSteps.CREATE_POLL ? (
            <div className="auth-box-inner">
              <figure className="auth-box-logo">
                <img
                  src={networkLogo}
                  alt="Sided"
                  onError={(e) => {
                    e.target.src = SIDED_FAVICON;
                  }}
                />
              </figure>
              <div className="auth-box-title">Create Your First Poll</div>
              <div className="auth-box-subtitle">
                Now it’s time to create your first poll and find out how people
                actually think about it.
                <br />
                Pro tip: the best polls are usually informative and relevant to
                current events and topics.
              </div>
              <form
                onSubmit={(event) => {
                  event.preventDefault();
                  setValidationErrors({});
                  setLoading(true);

                  axios
                    .post(API_URL + "/debate/create", {
                      ...createPollForm,
                      sides: createPollForm.sides.map((side) => {
                        return {
                          text: side,
                        };
                      }),
                      clientId: newClientId || (networks ? networks[0].id : 0),
                    })
                    .then(({ data }) => {
                      setLoading(false);

                      if (data.status === "success") {
                        setCreatedDebate(data.data);
                        setCurrentStep(formSteps.EMBED_POLL);
                      } else {
                        alert(data.message);
                      }
                    })
                    .catch(({ response }) => {
                      const errors = {};
                      response.data.message.forEach(
                        (err) =>
                          (errors[Object.keys(err)[0]] = Object.values(err)[0]),
                      );
                      setValidationErrors(errors);
                      setLoading(false);
                    });
                }}
              >
                <fieldset>
                  <label>Poll Title</label>
                  <input
                    type="text"
                    placeholder="Type statement or questions here..."
                    onChange={({ target }) =>
                      setCreatePollForm(
                        updateModel(createPollForm, { thesis: target.value }),
                      )
                    }
                    value={createPollForm.thesis}
                    className="form-control mb2x"
                  />
                  {validationErrors.thesis ? (
                    <div className="validation-error">
                      {validationErrors.thesis}
                    </div>
                  ) : null}
                  <div className="two-fields">
                    <label>Your Sides</label>
                    {createPollForm.sides.length < 6 ? (
                      <span
                        className="text-button"
                        onClick={() => {
                          const sides = [].concat(createPollForm.sides);
                          sides.push("");
                          setCreatePollForm(
                            updateModel(createPollForm, { sides }),
                          );
                        }}
                      >
                        + Add Side
                      </span>
                    ) : null}
                  </div>
                  {createPollForm.sides.map((side, index) => (
                    <div
                      className={
                        "multi-field" +
                        (createPollForm.sides.length - 1 === index
                          ? " mb2x"
                          : "")
                      }
                      key={index}
                    >
                      <input
                        type="text"
                        placeholder={"Side " + (index + 1)}
                        onChange={({ target }) => {
                          const sides = [].concat(createPollForm.sides);
                          sides[index] = target.value;
                          setCreatePollForm(
                            updateModel(createPollForm, { sides }),
                          );
                        }}
                        value={side}
                        className="form-control"
                      />
                      {index > 1 ? (
                        <span
                          onClick={() => {
                            const sides = [].concat(createPollForm.sides);
                            sides.splice(index, 1);
                            setCreatePollForm(
                              updateModel(createPollForm, { sides }),
                            );
                          }}
                        >
                          <svg
                            width="14"
                            height="14"
                            viewBox="0 0 14 14"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M14 1.41L12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41Z"
                              fill="#BDBDBD"
                            />
                          </svg>
                        </span>
                      ) : null}
                    </div>
                  ))}

                  {validationErrors.side ? (
                    <div className="validation-error">
                      {validationErrors.side}
                    </div>
                  ) : null}

                  <div className="two-fields">
                    <div>
                      <label>Start Date</label>
                      <div className="datePickerWrapper">
                        <DateRangePicker
                          onCallback={(value) =>
                            setCreatePollForm(
                              updateModel(createPollForm, { startedAt: value }),
                            )
                          }
                          initialSettings={{
                            singleDatePicker: true,
                            minDate: moment(),
                            timePicker: true,
                            startDate: createPollForm.startedAt,
                            locale: { format: "MM/DD/YY @ hh:mmA" },
                          }}
                        >
                          <input
                            type="text"
                            className="form-control datepickerField"
                          />
                        </DateRangePicker>
                        <i className="fa fa-calendar"></i>
                      </div>
                      {validationErrors.startedAt ? (
                        <div className="validation-error">
                          {validationErrors.startedAt}
                        </div>
                      ) : null}
                    </div>
                    <div>
                      <label>End Date</label>
                      <div className="datePickerWrapper">
                        <DateRangePicker
                          onCallback={(value) =>
                            setCreatePollForm(
                              updateModel(createPollForm, { endedAt: value }),
                            )
                          }
                          initialSettings={{
                            singleDatePicker: true,
                            minDate: moment(),
                            timePicker: true,
                            startDate: createPollForm.endedAt,
                            locale: { format: "MM/DD/YY @ hh:mmA" },
                          }}
                        >
                          <input
                            type="text"
                            className="form-control datepickerField"
                          />
                        </DateRangePicker>
                        <i className="fa fa-calendar"></i>
                      </div>
                      <label className="text-line text-start mt0">
                        <input
                          type="checkbox"
                          checked={createPollForm.noEndDate}
                          onChange={() => {
                            setCreatePollForm(
                              updateModel(createPollForm, {
                                noEndDate: !createPollForm.noEndDate,
                              }),
                            );
                            setValidationErrors({});
                          }}
                        />
                        No end date
                      </label>
                      {validationErrors.endedAt ? (
                        <div className="validation-error">
                          {validationErrors.endedAt}
                        </div>
                      ) : null}
                    </div>
                  </div>
                </fieldset>
                {validationErrors.error ? (
                  <div className="validation-error text-center">
                    {validationErrors.error}
                  </div>
                ) : null}
                <div className="button-holder has-two">
                  {/*
                  <button
                    type="button"
                    className="btn grayed"
                    onClick={() => setCurrentStep(formSteps.CREATE_NETWORK)}
                  >
                    Back
                  </button>
                  */}
                  <button type="submit" className="btn">
                    Publish
                  </button>
                </div>
              </form>
            </div>
          ) : null}

          {currentStep === formSteps.EMBED_POLL ? (
            <div className="auth-box-inner">
              <figure className="auth-box-logo">
                <img
                  src={networkLogo}
                  alt="Sided"
                  onError={(e) => {
                    e.target.src = SIDED_FAVICON;
                  }}
                />
              </figure>
              <div className="auth-box-title">Congratulations!</div>
              <div className="auth-box-subtitle">
                Success! You just published your first poll.
                <br />
                Next, add it to your website.
              </div>

              <form>
                <fieldset>
                  <label>ADD TO YOUR WEBSITE</label>
                  <div className="input-info mt0 mb2x">
                    Follow the instructions below to add this poll to your
                    website.
                  </div>

                  <label>STEP 1</label>
                  <div className="input-info mt0">
                    Add this code to your &lt;HEAD&gt; tag on your{" "}
                    <strong>
                      <em>website</em>
                    </strong>
                  </div>
                  <textarea
                    className="form-control"
                    id="header-code"
                    readOnly={true}
                    value='&lt;script type="text/javascript" src="https://cdn.sided.co/embed-assets/load.min.js" async&gt;&lt;/script&gt;'
                  ></textarea>
                  <div className="input-button">
                    <span
                      className="text-button"
                      onClick={() => {
                        document.querySelector("#header-code").select();
                        document.execCommand("copy");
                        alert(
                          "The header code was successfully copied to clipboard",
                        );
                      }}
                    >
                      Copy header code
                    </span>
                  </div>

                  <label>STEP 2</label>
                  <div className="input-info mt0">
                    Insert poll snippet where you want the poll to display.
                  </div>
                  <textarea
                    className="form-control"
                    id="debate-code"
                    readOnly={true}
                    value={`<div class="sided-widget" debateId="${createdDebate.id}"></div>`}
                  ></textarea>
                  <div className="input-button">
                    <span
                      className="text-button"
                      onClick={() => {
                        document.querySelector("#debate-code").select();
                        document.execCommand("copy");
                        alert(
                          "The poll code was successfully copied to clipboard",
                        );
                      }}
                    >
                      Copy poll code
                    </span>
                  </div>

                  <label>CONFIRM INTEGRATION</label>
                  <div className="input-info mt0">
                    Check to see if you have successfully integrated your code
                    snippets with your website.
                  </div>
                  <div className="button-holder has-halfs mt0">
                    <button
                      type="button"
                      className="btn bordered"
                      onClick={(e) => {
                        if (loadingIntegration) return false;

                        setLoadingIntegration(true);
                        axios
                          .get(
                            API_URL +
                              "/user/checkIntegration?clientId=" +
                              (newClientId || (networks ? networks[0].id : 0)),
                          )
                          .then(({ data }) => {
                            setLoadingIntegration(false);
                            alert(data.message);
                          })
                          .catch(({ response }) => {
                            setLoadingIntegration(false);
                            alert(Object.values(response.data.message[0])[0]);
                          });
                      }}
                    >
                      {loadingIntegration
                        ? "Please wait..."
                        : "Check integration"}
                    </button>
                  </div>

                  <div className="button-holder has-halfs mtl">
                    <button
                      type="button"
                      className="button fw-bold"
                      onClick={() => window.location.href = '/'}
                    >
                      Go to Dashboard
                    </button>
                    <button
                      type="submit"
                      className="btn green fw-bold"
                      onClick={(e) => {
                        e.preventDefault();

                        setLoading(true);
                        axios
                          .get(
                            API_URL +
                              "/user/sendApplyMonetizationEmail?clientId=" +
                              (newClientId || (networks ? networks[0].id : 0)),
                          )
                          .then(({ data }) => {
                            setLoading(false);
                            alert(data.message);
                          })
                          .catch(({ response }) => {
                            setLoading(false);
                            alert(Object.values(response.data.message[0])[0]);
                          });
                      }}
                    >
                      {loading ? "Please wait..." : "Apply for Monetization"}
                    </button>
                  </div>
                </fieldset>
              </form>
            </div>
          ) : null}

          {currentStep === formSteps.LOGIN ? (
            <div className="auth-box-inner">
              <figure className="auth-box-logo">
                <img
                  src={networkLogo}
                  alt="Sided"
                  onError={(e) => {
                    e.target.src = SIDED_FAVICON;
                  }}
                />
              </figure>
              <div className="auth-box-title">Login to {domain}</div>
              <div className="auth-box-subtitle">
                Your Sided account gives you access to polls and
                <br />
                conversations from your favorite publishers.
              </div>

              <form
                onSubmit={(event) => {
                  event.preventDefault();
                  setValidationErrors({});

                  if (
                    stepOneForm.verify === "phone" &&
                    stepOneForm.phone.trim() === ""
                  ) {
                    setValidationErrors({
                      phone:
                        "Please enter your phone number or select email for verification",
                    });
                  } else {
                    setLoading(true);

                    axios
                      .post(API_URL + "/auth/signin", {
                        field: loginForm.username,
                        password: loginForm.password,
                        deviceId: "deviceId",
                      })
                      .then(async ({ data }) => {
                        localStorage.setItem("token", data.token);
                        localStorage.setItem(
                          "tokenExpireAt",
                          data.tokenExpireAt,
                        );
                        localStorage.setItem("refreshToken", data.refreshToken);
                        localStorage.setItem(
                          "refreshTokenExpireAt",
                          data.refreshTokenExpireAt,
                        );
                        localStorage.setItem("userId", data.data.user.id);
                        if (isInvitation) {
                          window.location.reload();
                        } else {
                          window.location.href = '/';
                        }
                      })
                      .catch(({ response }) => {
                        const errors = {};
                        response.data.message.forEach(
                          (err) =>
                            (errors[Object.keys(err)[0]] =
                              Object.values(err)[0]),
                        );

                        setLoading(false);
                        setValidationErrors(errors);
                      });
                  }
                }}
              >
                <fieldset>
                  <input
                    type="text"
                    placeholder="Username"
                    onChange={({ target }) =>
                      setLoginForm(
                        updateModel(loginForm, { username: target.value }),
                      )
                    }
                    value={loginForm.username}
                    className="form-control"
                  />
                  <input
                    type="password"
                    placeholder="Password"
                    onChange={({ target }) =>
                      setLoginForm(
                        updateModel(loginForm, { password: target.value }),
                      )
                    }
                    value={loginForm.password}
                    className="form-control"
                  />
                  <div className="alt-option">
                    <span
                      className="text-button"
                      onClick={() => setCurrentStep(formSteps.ACCOUNT_INFO)}
                    >
                      Create New Account
                    </span>
                    {/*
                      <span className="text-button" onClick={() => setCurrentStep(formSteps.ACCOUNT_INFO)}>
                        Forgot password?
                      </span>
                      */}
                  </div>
                </fieldset>
                {validationErrors.error ? (
                  <div className="validation-error">
                    {validationErrors.error}
                  </div>
                ) : null}
                <div className="button-holder">
                  <button className="btn">Submit</button>
                </div>
                <div className="end-option">
                  <a className="text-button" href={heedHelpAction}>
                    Need Help?
                  </a>
                </div>
              </form>
            </div>
          ) : null}
        </div>
      </main>

      <footer className="sticky-footer">
        <Row>
          <Col sm={4} className="footer-left-col">
            <span>
              &#169; Sided 2024 <AppVersion />
            </span>
          </Col>
          <Col sm={8} className="footer-right-col">
            <ul className="inline-content middle">
              <li>
                <a href="/privacy-policy">Privacy Policy</a>
              </li>
              <li>&nbsp;|&nbsp;</li>
              <li>
                <a href="/terms-of-service">Terms of Service</a>
              </li>
            </ul>
          </Col>
        </Row>
      </footer>
    </>
  );
}
