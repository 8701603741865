import axios from "axios";
import moment from "moment";
import { connect } from "react-redux";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { API_URL } from "../../constants/Globals";

function Forms({ selNetworkId }) {
  const [state, setState] = useState(1);
  const [loading, setLoading] = useState(true);
  const [forms, setForms] = useState([]);

  useEffect(() => {
    if (selNetworkId) {
      axios
        .get(`${API_URL}/forms?clientId=${selNetworkId}`)
        .then(({ data }) => {
          setLoading(false);
          setForms(data.data);
        });
    }
  }, [selNetworkId, state]);

  return (
    <section className="segments">
      <div className="title-bar">
        <h1 className="page-title">Forms</h1>
        <Link to="/forms/new" className="blue-button size-l">
          Create Form
        </Link>
      </div>

      <div className="table-filters">
        <div className="table-pagination">{forms.length} Forms</div>
      </div>

      <div className="table-wrapper">
        <table
          className="table-body top-border"
          cellSpacing="0"
          cellPadding="0"
        >
          <tbody>
            {loading ? (
              <tr className="table-row">
                <td className="table-cell table-cell-fluid">
                  <em>Loading...</em>
                </td>
              </tr>
            ) : null}

            {!loading && !forms.length ? (
              <tr className="table-row">
                <td className="table-cell table-cell-fluid">
                  <em>No forms yet...</em>
                </td>
              </tr>
            ) : null}

            {forms.map(({ id, name, entriesCount, createdAt }) => (
              <tr key={id} className="table-row">
                <td className="table-cell table-cell-fluid">
                  <Link
                    to={`/forms/${id}`}
                    className="text-button inherit-font"
                  >
                    {name}
                  </Link>
                </td>
                <td className="table-cell table-cell-labelled" width="150">
                  <div>
                    Created
                    <br />
                    <strong>{moment(createdAt).format("MMM DD, Y")}</strong>
                  </div>
                </td>
                <td className="table-cell table-cell-labelled" width="120">
                  <div>
                    Entries
                    <br />
                    <strong>{entriesCount}</strong>
                  </div>
                </td>
                <td className="table-cell table-action" width="280">
                  <div className="inline-buttons">
                    <Link to={`/forms/${id}`} className="blue-button bordered">
                      Edit
                    </Link>
                    <button
                      className="blue-button bordered"
                      onClick={() => {
                        if (window.confirm("Are you sure?")) {
                          axios
                            .delete(`${API_URL}/forms/${id}`)
                            .then(() => setState(state * 2))
                            .then(() => {})
                            .catch(({ response }) => {
                              if (response.status === 500) {
                                alert(JSON.stringify(response.data.message));
                              }
                            });
                        }
                      }}
                    >
                      Delete
                    </button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </section>
  );
}

const mapStateToProps = (state) => ({
  selNetworkId: state.networks.selNetworkId,
});

export default connect(mapStateToProps)(Forms);
