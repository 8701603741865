import { useEffect, useState } from "react";

export default function useAlertMessage(message = "") {
  const [alertMessage, setAlertMessage] = useState(message);
  const getAlertMessage = () => {
    if (alertMessage) {
      return <div className="sucessMsg">{alertMessage}</div>;
    }

    return null;
  };

  useEffect(() => {
    if (alertMessage) {
      setTimeout(() => setAlertMessage(""), 2000);
    }
  }, [alertMessage]);

  return [setAlertMessage, getAlertMessage];
}
