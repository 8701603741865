import { useState } from "react";
import TableSearch from "./TableSearch";

export default function TagsFilter({ tags, isActive, onSelect }) {
  const [search, setSearch] = useState("");
  const [expanded, setExpanded] = useState(false);

  return (
    <div className={`table-control ${expanded || isActive ? "active" : ""}`}>
      <div
        className="table-control-value"
        onClick={() => setExpanded(!expanded)}
      >
        Filter by Tags
        <svg
          className="table-control-arrow"
          width="8"
          height="5"
          viewBox="0 0 8 5"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0.94 0L4 3.06L7.06 0L8 0.946667L4 4.94667L0 0.946667L0.94 0Z"
            fill="#828282"
          />
        </svg>
      </div>

      {expanded ? (
        <div className="table-control-dropdown">
          <TableSearch
            value={search}
            placeholder="Find a tag"
            onChange={(value) => setSearch(value)}
          />
          <ul>
            {tags
              .filter(
                ({ text }) =>
                  !search ||
                  !text ||
                  text.toLowerCase().indexOf(search.toLowerCase()) !== -1,
              )
              .map(({ id, text }) => (
                <li
                  key={id}
                  onClick={() => {
                    onSelect(id);
                    setExpanded(false);
                  }}
                >
                  {text}
                </li>
              ))}
          </ul>
        </div>
      ) : null}
    </div>
  );
}
