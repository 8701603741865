import React from "react";
import { Row, Col, Button, Form, Modal } from "react-bootstrap";
import axios from "axios";
import { API_URL, DEFAULT_USER_IMG_URL } from "../../constants/Globals";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import EditBrandAccount from "./EditBrandAccount";
import { withTranslation } from "react-i18next";

export class AddBrandAccount extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showCreateBrandAccountModal: false,
      showEditBrandAccountModal: false,
      validationErrors: "",
      modalSubmitting: false,
      createBrandAccountFormData: {
        clientId: this.props.clientId,
        name: "",
        username: "",
        email: "",
        bio: "",
        website: "",
        password: "",
        avatar: "",
        avatarTempData: "",
        coverImage: "",
        coverImageTempData: "",
      },
    };
  }

  hideCreateBrandAccountModal = async (e) => {
    this.setState({
      showCreateBrandAccountModal: false,
      validationErrors: "",
    });
  };

  showCreateBrandAccountModal = async (e) => {
    this.setState({
      showCreateBrandAccountModal: true,
    });
  };

  hideEditBrandAccountModal = async (e) => {
    this.setState({
      showEditBrandAccountModal: false,
      validationErrors: "",
    });
  };

  showEditBrandAccountModal = async (e) => {
    this.setState({
      showEditBrandAccountModal: true,
    });
  };

  changeHandler = async (e) => {
    var createBrandAccountFormData = {
      ...this.state.createBrandAccountFormData,
    };
    createBrandAccountFormData[e.target.name] = e.target.value;
    await this.setState({ createBrandAccountFormData });
    this.brandAccountFormValidation(e);
  };

  componentDidMount(props) {
    this.generateEmail();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.clientName !== this.props.clientName) {
      this.generateEmail();
    }
  }

  generateEmail = async () => {
    var createBrandAccountFormData = {
      ...this.state.createBrandAccountFormData,
    };
    createBrandAccountFormData["email"] = this.props.clientName
      ? this.props.clientName + "@sided.co"
      : "";
    createBrandAccountFormData["clientId"] = this.props.clientId
      ? this.props.clientId
      : "";
    this.setState({ createBrandAccountFormData });
  };

  brandAccountFormValidation = async (e = "", action = "") => {
    var validationErrors = { ...this.state.validationErrors };
    if (e.target.name === "name" || action === "onSubmit") {
      if (this.state.createBrandAccountFormData.name === "") {
        validationErrors["name"] = this.props.t("The name field is required.");
        await this.setState({ validationErrors: validationErrors });
      } else if (this.state.createBrandAccountFormData.name.length > 50) {
        validationErrors["name"] = this.props.t(
          "Name must be max 50 characters.",
        );
        await this.setState({ validationErrors: validationErrors });
      } else {
        delete validationErrors["name"];
        await this.setState({ validationErrors: validationErrors });
      }
    }

    if (e.target.name === "username" || action === "onSubmit") {
      if (this.state.createBrandAccountFormData.username === "") {
        validationErrors["username"] = this.props.t(
          "The username field is required.",
        );
        await this.setState({ validationErrors: validationErrors });
      } else if (this.state.createBrandAccountFormData.username.length < 3) {
        validationErrors["username"] = this.props.t(
          "Username must be at least 3 characters.",
        );
        await this.setState({ validationErrors: validationErrors });
      } else if (this.state.createBrandAccountFormData.username.length > 20) {
        validationErrors["username"] = this.props.t(
          "Username must be between 3 to 20 characters.",
        );
        await this.setState({ validationErrors: validationErrors });
      } else if (
        this.state.createBrandAccountFormData.username !== null &&
        this.state.createBrandAccountFormData.username !== "" &&
        !/^[a-zA-Z0-9_]+$/i.test(this.state.createBrandAccountFormData.username)
      ) {
        validationErrors["username"] = this.props.t(
          "Use only letter, number and _.",
        );
        await this.setState({ validationErrors: validationErrors });
      } else {
        delete validationErrors["username"];
        await this.setState({ validationErrors: validationErrors });
      }
    }

    if (e.target.name === "email" || action === "onSubmit") {
      if (this.state.createBrandAccountFormData.email === "") {
        validationErrors["email"] = this.props.t(
          "The email field is required.",
        );
        await this.setState({ validationErrors: validationErrors });
      } else if (
        this.state.createBrandAccountFormData.email !== null &&
        this.state.createBrandAccountFormData.email !== "" &&
        !/\S+@\S+\.\S+/.test(this.state.createBrandAccountFormData.email)
      ) {
        validationErrors["email"] = this.props.t(
          "The email must be a valid email address.",
        );
        await this.setState({ validationErrors: validationErrors });
      } else {
        delete validationErrors["email"];
        await this.setState({ validationErrors: validationErrors });
      }
    }

    if (e.target.name === "website" || action === "onSubmit") {
      if (
        this.state.createBrandAccountFormData.website !== null &&
        this.state.createBrandAccountFormData.website !== "" &&
        !/^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-zA-Z0-9]+([-.]{1}[a-zA-Z0-9]+)*\.[a-zA-Z]{2,5}(:[0-9]{1,5})?(\/.*)?$/i.test(
          this.state.createBrandAccountFormData.website,
        )
      ) {
        validationErrors["website"] = this.props.t(
          "Please enter valid website URL.",
        );
        await this.setState({ validationErrors: validationErrors });
      } else {
        delete validationErrors["website"];
        await this.setState({ validationErrors: validationErrors });
      }
    }

    if (e.target.name === "bio" || action === "onSubmit") {
      if (
        this.state.createBrandAccountFormData.bio !== null &&
        this.state.createBrandAccountFormData.bio !== "" &&
        this.state.createBrandAccountFormData.bio.length > 140
      ) {
        validationErrors["bio"] = "Bio must be between 1 to 140 characters.";
        await this.setState({ validationErrors: validationErrors });
      } else {
        delete validationErrors["bio"];
        await this.setState({ validationErrors: validationErrors });
      }
    }

    if (e.target.name === "password" || action === "onSubmit") {
      if (this.state.createBrandAccountFormData.password === "") {
        validationErrors["password"] = this.props.t("This field is required.");
        await this.setState({ validationErrors: validationErrors });
      } else if (this.state.createBrandAccountFormData.password.length < 6) {
        validationErrors["password"] = this.props.t(
          "Password must be at least 6 characters long.",
        );
        await this.setState({ validationErrors: validationErrors });
      } else {
        delete validationErrors["password"];
        await this.setState({ validationErrors: validationErrors });
      }
    }

    if (
      Object.keys(this.state.validationErrors).length === 0 &&
      action === "onSubmit"
    ) {
      this.uploadUserImages();
    } else {
      this.setState({
        modalSubmitting: false,
      });
    }
  };

  submitCreateBrandAccountFormData = async (e) => {
    axios
      .post(
        API_URL + "/admin/brandAccount/create",
        this.state.createBrandAccountFormData,
      )
      .then((res) => {
        this.setState({
          modalSubmitting: false,
        });
        this.messageNotification(
          "success",
          this.props.t("Brand Account created successfully!"),
        );
        this.hideCreateBrandAccountModal();
        setTimeout(window.location.reload(), 500);
      })
      .catch((err) => {
        this.setState({
          modalSubmitting: false,
        });
        var errors = "";
        var errorsArr = err.response.data.message;
        var validationErrors = { ...this.state.validationErrors };
        for (let i = 0; i < errorsArr.length; i++) {
          if (errorsArr[i].username !== undefined) {
            validationErrors["username"] = this.props.t(
              "The username has already been taken.",
            );
            this.setState({ validationErrors: validationErrors });
          }
          if (errorsArr[i].email !== undefined) {
            validationErrors["email"] = this.props.t(
              "The email has already been taken.",
            );
            this.setState({ validationErrors: validationErrors });
          }
        }
        console.log(errors);
      });
  };

  submitCreateBrandAccountDataHandle = async (e) => {
    this.setState({
      modalSubmitting: true,
    });
    this.brandAccountFormValidation(e, "onSubmit");
    e.preventDefault();
  };

  generatePassword = async (e) => {
    var length = 16,
      charset =
        "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
      retVal = "";
    for (var i = 0, n = charset.length; i < length; ++i) {
      retVal += charset.charAt(Math.floor(Math.random() * n));
    }
    var pwd = retVal;
    var createBrandAccountFormData = {
      ...this.state.createBrandAccountFormData,
    };
    createBrandAccountFormData["password"] = pwd;
    this.setState({ createBrandAccountFormData });
  };

  copyPassword = async (e) => {
    var passField = document.getElementById("password");
    if (passField.value !== "") {
      passField.select();
      await document.execCommand("copy");
      this.messageNotification(
        "success",
        this.props.t("Password copied to clipboard!"),
      );
    } else {
      this.messageNotification(
        "fail",
        this.props.t("The password field is empty."),
      );
    }
  };

  messageNotification = async (type, message) => {
    this.setState({
      successMsg: message,
      msgType: type,
    });

    setTimeout(() => {
      this.setState({
        successMsg: "",
        msgType: "",
      });
    }, 2500);
  };

  fileChangeHandler = async (e) => {
    if (e.target.type === "file" && e.target.files[0]) {
      const dataAccept = e.target.getAttribute("data-accept")
        ? e.target.getAttribute("data-accept")
        : "jpg|jpeg|gif|png";
      const dataAcceptMsg = e.target.getAttribute("data-accept_msg")
        ? e.target.getAttribute("data-accept_msg")
        : this.props.t(
            "Image is invalid. Please upload only PNG, JPG or GIF image.",
          );
      var regex = new RegExp("(.*?).(" + dataAccept + ")$");
      if (!regex.test(e.target.files[0].type)) {
        alert(dataAcceptMsg);
        return false;
      }
      if (e.target.files[0].size > 1024 * 20 * 1024) {
        var alertMessage = this.props.t(
          "Image size should not be greater than 20mb",
        );
        alert(alertMessage);
        return false;
      }
      var file = e.target.files[0];
      var thisEl = e;
      var reader = new FileReader();
      reader.onloadend = function (e) {
        var createBrandAccountFormData = {
          ...this.state.createBrandAccountFormData,
        };
        var uploadedImageObj = {
          type: thisEl.target.dataset.imgtype,
          image: thisEl.target.files[0],
        };
        if (thisEl.target.name === "avatar") {
          createBrandAccountFormData.avatarTempData = uploadedImageObj;
          this.setState({ avatarDisplay: e.target.result });
        } else {
          createBrandAccountFormData.coverImageTempData = uploadedImageObj;
          this.setState({ coverImageDisplay: e.target.result });
        }

        createBrandAccountFormData[thisEl.target.name] = e.target.result;

        this.setState({ createBrandAccountFormData });
      }.bind(this);
      reader.readAsDataURL(file);
    }
  };

  fileRemoveHandler = async (name) => {
    var createBrandAccountFormData = {
      ...this.state.createBrandAccountFormData,
    };

    if (name === "avatar") {
      createBrandAccountFormData.avatarTempData = "";
      this.setState({ avatarDisplay: "" });
    } else {
      createBrandAccountFormData.coverImageTempData = "";
      this.setState({ coverImageDisplay: "" });
    }
    createBrandAccountFormData[name] = "";
    await this.setState({ createBrandAccountFormData });
  };

  uploadUserImages = async (e) => {
    const { avatarTempData, coverImageTempData, clientId } =
      this.state.createBrandAccountFormData;
    var failure = false;
    var imagesArr = [];
    if (avatarTempData !== "") {
      const formData = new FormData();
      formData.append(
        "backgroundImage",
        avatarTempData.image,
        avatarTempData.image.name,
      );
      imagesArr.push({ image_type: avatarTempData.type, formData: formData });
    }

    if (coverImageTempData !== "") {
      const formData = new FormData();
      formData.append(
        "backgroundImage",
        coverImageTempData.image,
        coverImageTempData.image.name,
      );
      imagesArr.push({
        image_type: coverImageTempData.type,
        formData: formData,
      });
    }

    for (let i = 0; i < imagesArr.length; i++) {
      await axios
        .post(
          API_URL +
            "/debate/imageUpload?type=" +
            imagesArr[i].image_type +
            "&clientId=" +
            clientId,
          imagesArr[i].formData,
        )
        .then((res) => {
          var createBrandAccountFormData = {
            ...this.state.createBrandAccountFormData,
          };

          if (imagesArr[i].image_type === "USERS") {
            createBrandAccountFormData.avatar = res.data.data;
          } else {
            createBrandAccountFormData.coverImage = res.data.data;
          }
          this.setState({ createBrandAccountFormData });
        });
    }

    if (failure === false) {
      this.submitCreateBrandAccountFormData();
    } else {
      this.setState({ modalSubmitting: false });
    }
  };

  render() {
    var brandAccount = this.props.brandAccount;
    const {
      validationErrors,
      coverImageDisplay,
      avatarDisplay,
      modalSubmitting,
    } = this.state;
    const { name, username, email, bio, website, password } =
      this.state.createBrandAccountFormData;
    const kTrans = this.props.t ? this.props.t : "";

    return (
      <>
        {this.state.successMsg ? (
          <div className={this.state.msgType}>{this.state.successMsg}</div>
        ) : null}
        <h3 className="mb-3">&nbsp;</h3>
        <Form.Group controlId="brandAccount" className="pt-0">
          <Form.Label>{kTrans("BRAND ACCOUNT")}</Form.Label>
          {Object.keys(brandAccount).length ? (
            <div className="brandAccountWrap">
              <div className="author">
                <span role="button" className="user-avatar">
                  {brandAccount.avatarObject ? (
                    <img
                      style={{ borderColor: brandAccount.statusColor }}
                      alt={this.props.brandAccount.name}
                      src={brandAccount.avatarObject.small.location}
                      className="img-circle avatar avatar-lg"
                      onError={(e) => {
                        e.target.src = DEFAULT_USER_IMG_URL;
                      }}
                    />
                  ) : (
                    <img
                      style={{ borderColor: brandAccount.statusColor }}
                      alt={this.props.brandAccount.name}
                      src={DEFAULT_USER_IMG_URL}
                      className="img-circle avatar"
                    />
                  )}
                  {brandAccount.roles &&
                  brandAccount.roles.length > 0 &&
                  brandAccount.roles.find(
                    (role) => role.name === "VERIFIED",
                  ) ? (
                    <FontAwesomeIcon
                      style={{ color: brandAccount.checkmarkColor }}
                      icon={faCheckCircle}
                      className="procheckmark"
                    />
                  ) : null}
                </span>
                <div class="copy-wrap">
                  <h5 class="mb-0">{brandAccount.name}</h5>
                  <a
                    class="user-handle customAnchor"
                    target="_blank"
                    rel="noreferrer"
                    href={`/${brandAccount.username}`}
                    tabindex="0"
                  >
                    @{brandAccount.username}
                  </a>
                </div>
              </div>
              <hr />
              <a
                target="_blank"
                rel="noreferrer"
                className="customAnchor mb-2"
                href={`/${brandAccount.username}`}
              >
                {kTrans("View brand account")}
              </a>
              <span
                onClick={this.showEditBrandAccountModal}
                className="customAnchor"
                href=""
              >
                {kTrans("Edit brand account")}
              </span>
            </div>
          ) : (
            <span
              onClick={this.showCreateBrandAccountModal}
              className="customAnchor"
            >
              {kTrans("Create a brand account")}
            </span>
          )}
        </Form.Group>
        <Modal
          className="ceateBrandAccountModal"
          size="lg"
          show={this.state.showCreateBrandAccountModal}
        >
          <Modal.Header>
            <Modal.Title>{kTrans("Add Brand Account")}</Modal.Title>
            <span className="modal-close-btn">
              <b className="" onClick={this.hideCreateBrandAccountModal}>
                &#x2715;
              </b>
            </span>
          </Modal.Header>
          <Modal.Body className={modalSubmitting ? "formSubmitting" : ""}>
            <div className="modalContentWrapper createUserContent">
              <Form
                className="profileForm common-form mt-0"
                onSubmit={this.submitCreateBrandAccountDataHandle}
              >
                <Form.Group>
                  <Form.Check.Label>{kTrans("NAME")}</Form.Check.Label>
                  <Form.Control
                    type="text"
                    name="name"
                    value={name}
                    id="name"
                    placeholder={kTrans("Enter the user's name")}
                    onChange={this.changeHandler}
                  />
                  {validationErrors && validationErrors.name !== undefined ? (
                    <span className="validation-error">
                      {validationErrors.name}
                    </span>
                  ) : null}
                </Form.Group>
                <Form.Group>
                  <Form.Check.Label>{kTrans("USERNAME")}</Form.Check.Label>
                  <Form.Control
                    type="text"
                    name="username"
                    value={username}
                    id="username"
                    placeholder={kTrans("Choose a username")}
                    onChange={this.changeHandler}
                  />
                  {validationErrors &&
                  validationErrors.username !== undefined ? (
                    <span className="validation-error">
                      {validationErrors.username}
                    </span>
                  ) : null}
                </Form.Group>
                <Form.Group>
                  <Form.Check.Label>
                    {kTrans("EMAIL")} ({kTrans("Optional")})
                  </Form.Check.Label>
                  <Form.Control
                    type="text"
                    name="email"
                    value={email}
                    id="email"
                    placeholder={kTrans("Enter the user’s email")}
                    onChange={this.changeHandler}
                  />
                  {validationErrors && validationErrors.email !== undefined ? (
                    <span className="validation-error">
                      {validationErrors.email}
                    </span>
                  ) : null}
                </Form.Group>
                <Form.Group>
                  <Form.Check.Label>{kTrans("BIO")}</Form.Check.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    onChange={this.changeHandler}
                    name="bio"
                    value={bio}
                  />
                  {validationErrors && validationErrors.bio !== undefined ? (
                    <span className="validation-error">
                      {validationErrors.bio}
                    </span>
                  ) : null}
                </Form.Group>
                <Form.Group>
                  <Form.Check.Label>{kTrans("WEBSITE")}</Form.Check.Label>
                  <Form.Control
                    type="text"
                    name="website"
                    value={website}
                    id="website"
                    placeholder="website.com"
                    onChange={this.changeHandler}
                  />
                  {validationErrors &&
                  validationErrors.website !== undefined ? (
                    <span className="validation-error">
                      {validationErrors.website}
                    </span>
                  ) : null}
                </Form.Group>

                <Form.Group>
                  <Form.Check.Label>{kTrans("PASSWORD")}</Form.Check.Label>
                  <div className="position-relative">
                    <Form.Control
                      type="text"
                      name="password"
                      value={password}
                      id="password"
                      placeholder={kTrans("Enter a password")}
                      onChange={this.changeHandler}
                    />
                    <span
                      onClick={this.generatePassword}
                      className="customAnchor generatePassword"
                    >
                      {kTrans("Generate password")}
                    </span>
                  </div>
                  {validationErrors &&
                  validationErrors.password !== undefined ? (
                    <span className="validation-error">
                      {validationErrors.password}
                    </span>
                  ) : null}
                  {password ? (
                    <span
                      onClick={this.copyPassword}
                      className="customAnchor d-block"
                    >
                      {kTrans("Copy password")}
                    </span>
                  ) : null}
                </Form.Group>

                <Row className="profileForm">
                  <Col md={4}>
                    <Form.Group>
                      <Form.Check.Label>
                        {kTrans("PROFILE IMAGE")}
                      </Form.Check.Label>
                      <div className="userAvatarWrapper d-inline-block position-relative hasIcon mt-2 mt-md-4">
                        {avatarDisplay ? (
                          <img
                            className="userAvatar"
                            src={avatarDisplay}
                            alt="User avatar"
                          />
                        ) : (
                          <span className="userAvatarPlaceholder"></span>
                        )}
                        <i
                          className="fa fa-camera upload-label"
                          aria-hidden="true"
                        ></i>
                        <Form.Control
                          data-index="0"
                          data-imgtype="USERS"
                          type="file"
                          name="avatar"
                          onChange={this.fileChangeHandler}
                          data-accept="jpg|jpeg|png"
                          data-accept_msg={kTrans(
                            "Image is invalid. Please upload only PNG or JPG image.",
                          )}
                        />
                        {avatarDisplay ? (
                          <span
                            onClick={() => this.fileRemoveHandler("avatar")}
                            className="customAnchor text-center mt-2"
                          >
                            Remove
                            <svg
                              className="ms-1"
                              width="12"
                              height="14"
                              viewBox="0 0 12 14"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M3.75 0V0.75H0V2.25H0.75V12C0.75 12.3978 0.908035 12.7794 1.18934 13.0607C1.47064 13.342 1.85218 13.5 2.25 13.5H9.75C10.1478 13.5 10.5294 13.342 10.8107 13.0607C11.092 12.7794 11.25 12.3978 11.25 12V2.25H12V0.75H8.25V0H3.75ZM2.25 2.25H9.75V12H2.25V2.25ZM3.75 3.75V10.5H5.25V3.75H3.75ZM6.75 3.75V10.5H8.25V3.75H6.75Z"
                                fill="#BDBDBD"
                              />
                            </svg>
                          </span>
                        ) : null}
                      </div>
                    </Form.Group>
                  </Col>
                  <Col md={8}>
                    <Form.Group>
                      <Form.Check.Label className="mt-3 mt-md-0">
                        {kTrans("BACKGROUND IMAGE")}
                      </Form.Check.Label>
                      <div className="userCoverImageWrapper  position-relative hasIcon mt-1 mt-md-3">
                        {coverImageDisplay ? (
                          <img
                            className="userCoverImage"
                            src={coverImageDisplay}
                            alt="User cover"
                          />
                        ) : (
                          <span className="userCoverImagePlaceholder"></span>
                        )}
                        <i
                          className="fa fa-camera upload-label"
                          aria-hidden="true"
                        ></i>
                        <Form.Control
                          data-index="0"
                          data-imgtype="COVERS"
                          type="file"
                          name="coverImage"
                          onChange={this.fileChangeHandler}
                          data-accept="jpg|jpeg|png"
                          data-accept_msg={kTrans(
                            "Image is invalid. Please upload only PNG or JPG image.",
                          )}
                        />
                      </div>
                      {coverImageDisplay ? (
                        <span
                          onClick={() => this.fileRemoveHandler("coverImage")}
                          className="customAnchor ms-1"
                        >
                          Remove
                          <svg
                            className="ms-1"
                            width="12"
                            height="14"
                            viewBox="0 0 12 14"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M3.75 0V0.75H0V2.25H0.75V12C0.75 12.3978 0.908035 12.7794 1.18934 13.0607C1.47064 13.342 1.85218 13.5 2.25 13.5H9.75C10.1478 13.5 10.5294 13.342 10.8107 13.0607C11.092 12.7794 11.25 12.3978 11.25 12V2.25H12V0.75H8.25V0H3.75ZM2.25 2.25H9.75V12H2.25V2.25ZM3.75 3.75V10.5H5.25V3.75H3.75ZM6.75 3.75V10.5H8.25V3.75H6.75Z"
                              fill="#BDBDBD"
                            />
                          </svg>
                        </span>
                      ) : null}
                    </Form.Group>
                  </Col>
                </Row>

                <div class="mt-3 mb-5 form-group">
                  <div className="buttons position-static">
                    <span
                      onClick={this.hideCreateBrandAccountModal}
                      className="m-0 btn btn-secondary"
                    >
                      {kTrans("Cancel")}
                    </span>
                    <Button
                      variant="primary"
                      className="mt-0 me-0"
                      type="submit"
                    >
                      {kTrans("Create Brand Account")}
                    </Button>
                  </div>
                </div>
              </Form>
            </div>
          </Modal.Body>
        </Modal>

        <Modal
          className="editBrandAccountModal"
          size="lg"
          show={this.state.showEditBrandAccountModal}
        >
          <Modal.Header>
            <Modal.Title>Edit Brand Account</Modal.Title>
            <span className="modal-close-btn">
              <b onClick={this.hideEditBrandAccountModal}>&#x2715;</b>
            </span>
          </Modal.Header>
          <Modal.Body className={modalSubmitting ? "formSubmitting" : ""}>
            <EditBrandAccount
              brandUser={this.props.brandAccount}
              parentFunction={this.hideEditBrandAccountModal}
            />
          </Modal.Body>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  loggedInUser: state.login.user,
  selNetworkId: state.networks.selNetworkId,
});

const Extended = withTranslation()(AddBrandAccount);
Extended.static = AddBrandAccount.static;

export default connect(mapStateToProps)(Extended);
