import axios from "axios";
import { connect } from "react-redux";
import { useState, useEffect } from "react";
import TableSearch from "./TableSearch";
import { API_URL } from "../constants/Globals";

function SegmentsFilter({ selNetworkId, selectedSegment, onSelect }) {
  const [segments, setSegments] = useState([]);
  const [search, setSearch] = useState("");
  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    if (selNetworkId) {
      axios
        .get(`${API_URL}/admin/segments?clientId=${selNetworkId}`)
        .then(({ data }) => setSegments(data.data));
    }
  }, [selNetworkId]);

  const selectedSegmentObject = segments.find(
    (segment) => segment.id === selectedSegment,
  );

  return (
    <div
      className={`table-control ${expanded || selectedSegment ? "active" : ""}`}
    >
      <div
        className="table-control-value"
        onClick={() => setExpanded(!expanded)}
      >
        {selectedSegmentObject ? selectedSegmentObject.name : "View Segment"}
        <svg
          className="table-control-arrow"
          width="8"
          height="5"
          viewBox="0 0 8 5"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0.94 0L4 3.06L7.06 0L8 0.946667L4 4.94667L0 0.946667L0.94 0Z"
            fill="#828282"
          />
        </svg>
      </div>

      {expanded ? (
        <div className="table-control-dropdown">
          <TableSearch
            value={search}
            placeholder="Find a segment"
            onChange={(value) => setSearch(value)}
          />
          <ul>
            {segments
              .filter(
                ({ name }) =>
                  !search ||
                  !name ||
                  name.toLowerCase().indexOf(search.toLowerCase()) !== -1,
              )
              .map(({ id, name }) => (
                <li
                  key={id}
                  onClick={() => {
                    onSelect(id);
                    setExpanded(false);
                  }}
                >
                  {name}
                </li>
              ))}
          </ul>
        </div>
      ) : null}
    </div>
  );
}

const mapStateToProps = (state) => ({
  selNetworkId: state.networks.selNetworkId,
});

export default connect(mapStateToProps)(SegmentsFilter);
