import React from "react";
import { withTranslation } from "react-i18next";

class SearchForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      q: "",
    };
  }

  handleQueryChange = (event) => {
    this.setState({
      q: event.target.value,
    });
  };

  render() {
    const kTrans = this.props.t ? this.props.t : "";
    return (
      <>
        <form
          id="search"
          autoComplete="off"
          className="navbar-form navbar-left open"
          method="get"
          onSubmit={(e) => this.props.handleSearchSubmit(e, this.state.q)}
        >
          <input
            onChange={this.handleQueryChange}
            type="text"
            className="form-control"
            value={this.state.q}
            name="q"
            placeholder={kTrans("Search by name, username or email")}
          />
          <button type="submit">
            <i className="fa fa-search"></i>
          </button>
        </form>
      </>
    );
  }
}

export default withTranslation()(SearchForm);
