const init = {
  embedDomains: null,
  isLoaded: false,
  selDomainId: null,
};

export const embedDomainsReducer = (state = init, action) => {
  switch (action.type) {
    case "EMBED_DOMAINS_LOADED":
      var selDomainId = action.payload.data ? action.payload.data[0].id : 0;
      var embedDomains = action.payload.data;
      if (embedDomains && embedDomains.length > 0) {
        let selDomainIdLocal = localStorage.getItem("selDomainId");
        if (selDomainIdLocal) {
          if (
            embedDomains.find(
              (domain) => parseInt(domain.id) === parseInt(selDomainIdLocal),
            )
          ) {
            selDomainId = selDomainIdLocal;
          }
        }
      }
      return {
        ...state,
        embedDomains: embedDomains,
        selDomainId: selDomainId,
        isLoaded: true,
      };
    case "SAVE_CURRENT_DOMAIN":
      localStorage.setItem("selDomainId", action.payload);
      return {
        ...state,
        isLoading: false,
        selDomainId: action.payload,
      };
    default:
      return state;
  }
};
