import axios from "axios";
import moment from "moment";
import {
  readApiKey,
  readApiKeyDate,
  storeApiKey,
  deleteApiKey,
  readUserId,
  readUserToken,
  readUserTokenDate,
  readUserRefreshToken,
  readUserRefreshTokenDate,
  storeUserId,
  deleteUserId,
} from "./Session";
import { API_URL } from "../constants/Globals";

// Setup config/headers and token
export const loggedInTokenConfig = async (config) => {
  const { url } = config;
  if (url.indexOf("auth/") === -1 && url.indexOf("refreshToken") === -1) {
    await checkTokenConfig();
  }

  //Get token from local storage
  let token = await readUserToken();
  let apiKey = await readApiKey();

  //console.log('token',token);
  //console.log('apiKey',apiKey);

  config.headers["x-access-token"] = token ? token.toString() : "";
  config.headers["x-auth-token"] = apiKey;
  config.headers["x-source-type"] = "admin_panel";

  //console.log('config',config);

  return config;
};

export async function checkTokenConfig() {
  //Get token from local storage
  let token = await readUserToken();
  const tokenExpireAt = await readUserTokenDate();
  if (token && tokenExpireAt) {
    const refreshToken = await readUserRefreshToken();
    const refreshTokenExpireAt = await readUserRefreshTokenDate();
    const tokenExpDiff = moment
      .duration(moment(tokenExpireAt).diff(moment.utc()))
      .asMinutes();
    //console.log('tokenExpDiff', tokenExpDiff);

    const refreshTokenExpDiff = moment
      .duration(moment(refreshTokenExpireAt).diff(moment.utc()))
      .asMinutes();
    //console.log('refreshTokenExpDiff', refreshTokenExpDiff);

    if (tokenExpDiff < 1 || refreshTokenExpDiff < 1) {
      console.log("token refresh here");
      try {
        const { data } = await axios.post(
          API_URL + "/user/refreshToken",
          {},
          { headers: { "x-refresh-token": refreshToken } },
        );
        //console.log('token refreshed', data);
        const userId = await readUserId();
        await storeUserId(
          userId,
          data.token,
          data.tokenExpireAt,
          data.refreshToken,
          data.refreshTokenExpireAt,
        );
      } catch (err) {
        console.log("token refresh err", err);
        await deleteUserId();
      }
    }
  }

  let apiKey = await readApiKey();
  const apiKeyExpireAt = await readApiKeyDate();
  var apiKeyLoad = false;
  if (apiKey && apiKeyExpireAt) {
    const apiKeyExpDiff = moment
      .duration(moment(apiKeyExpireAt).diff(moment.utc()))
      .asMinutes();
    //console.log('apiKeyExpDiff', apiKeyExpDiff);

    if (apiKeyExpDiff < 1) {
      apiKeyLoad = true;
    }
  }
  if (!apiKey) {
    apiKeyLoad = true;
  }
  if (apiKeyLoad) {
    //console.log('apiKey Load here');
    try {
      const { data } = await axios.post(API_URL + "/auth/authToken", {}, {});
      //console.log('apiKey refreshed', data);
      await storeApiKey(data.token, data.tokenExpireAt);
    } catch (err) {
      //console.log('apiKey refresh err', err);
      await deleteApiKey();
    }
  }
}
