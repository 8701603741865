import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import AppVersion from "./AppVersion";
import { useTranslation } from "react-i18next";

function Footer() {
  const { t } = useTranslation();
  const kTrans = t ? t : "";
  return (
    <footer>
      <Container>
        <Row>
          <Col sm={4} className="footer-left-col">
            <span>
              &#169; Sided 2024 <AppVersion />
            </span>
          </Col>
          <Col sm={8} className="footer-right-col">
            <ul className="inline-content middle p-0">
              <li>
                <a href="/privacy-policy">{kTrans("Privacy Policy")}</a>
              </li>
              <li>&nbsp;|&nbsp;</li>
              <li>
                <a href="/terms-of-service">{kTrans("Terms of Service")}</a>
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </footer>
  );
}

export default Footer;
