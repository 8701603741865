import { createStore, applyMiddleware, combineReducers } from "redux";
import { loginReducer } from "./reducer/login.reducer";
import { debatesReducer } from "./reducer/debates.reducer";
import { embedDomainsReducer } from "./reducer/embedDomains.reducer";
import { networksReducer } from "./reducer/networks.reducer";
import { networkUsersReducer } from "./reducer/networkUsers.reducer";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
// import { fetchFeedsReducer } from "./reducer/fetchFeeds.reducer";
// import { fetchSuggestionsReducer } from "./reducer/fetchSuggestions.reducer";
// import { rootSaga } from "./rootSaga";

const rootReducer = combineReducers({
  login: loginReducer,
  debates: debatesReducer,
  networks: networksReducer,
  embedDomains: embedDomainsReducer,
  networkUsers: networkUsersReducer,
});

const middleWare = [thunk];

export const store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(...middleWare)),
);
