import React from "react";
import { Table, Modal, Form, Button } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
import {
  BASENAME,
  SIDED_FAVICON,
  NETWORKS_PER_PAGE,
} from "../../constants/Globals";
import axios from "axios";
import { API_URL } from "../../constants/Globals";
import InviteModal from "../../components/InviteModal";
import { withTranslation } from "react-i18next";

class GroupNetworks extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      groupSlug: this.props.match.params.groupSlug,
      loading: false,
      searchText: "",
      order: "",
      orderBy: "title",
      showAddNetworkToGroupModal: false,
      groupNetworks: "",
      addNetworkToGroupFormData: {
        groupId: "",
        networkId: "",
        groupName: "",
        networkTitle: "",
      },
      addNewNetworkFormData: {
        name: "",
        logo: "",
        logoWidth: "",
        logoHeight: "",
        title: "",
        primaryDomain: "",
        subDomain: "",
        images: [],
      },
      showAddNetworkToGroupForm: true,
      showAddNewNetworkForm: false,
      showSuccessMsg: false,
      validationErrors: {},
      showInviteModal: false,
      availableNetworks: "",
    };
  }

  getGroupDetail = async (e) => {
    await axios
      .get(
        API_URL +
          "/admin/groups/groupDetailBySlug?groupSlug=" +
          this.state.groupSlug,
      )
      .then((res) => {
        var addNetworkToGroupFormData = {
          ...this.state.addNetworkToGroupFormData,
        };
        addNetworkToGroupFormData["groupId"] = res.data.data.id;
        addNetworkToGroupFormData["groupName"] = res.data.data.name;
        this.setState({ addNetworkToGroupFormData });
      })
      .catch((err) => {
        alert("There is a problem in fetching group detail. Please try again!");
        console.log(err);
      });
  };

  fetchGroupNetworks = async (e) => {
    if (this.state.addNetworkToGroupFormData.groupId === "") {
      await this.getGroupDetail();
    }
    await this.setState({
      loading: true,
    });

    await axios
      .get(
        API_URL +
          "/admin/groups/getGroupNetworks?searchText=" +
          this.state.searchText +
          "&perPage=" +
          NETWORKS_PER_PAGE +
          "&pageNumber=1&groupId=" +
          this.state.addNetworkToGroupFormData.groupId,
      )
      .then((res) => {
        this.setState({
          groupNetworks: res.data.data,
          loading: false,
        });
      })
      .catch((err) => {
        alert("Failed. Please try again!");
        console.log(err);
      });

    this.getAvailableNetworks();
  };

  getAvailableNetworks = async () => {
    var currGroupId = this.state.addNetworkToGroupFormData.groupId;
    await axios
      .get(
        API_URL +
          "/admin/groups/getAvailableGroupNetworks?order=asc&orderBy=title&groupId=" +
          currGroupId,
      )
      .then((res) => {
        this.setState({ availableNetworks: res.data.data });
      })
      .catch((err) => {
        alert(
          "There is a problem in fetching available networks. Please try again!",
        );
        console.log(err);
        this.setState({ loading: false });
      });
  };

  componentDidMount = () => {
    this.fetchGroupNetworks();
  };

  componentDidUpdate = async (prevProps, prevState) => {
    if (
      prevProps.selGroupSlug !== null &&
      prevProps.selGroupSlug !== this.props.selGroupSlug
    ) {
      window.history.pushState(
        "",
        "",
        BASENAME + "/groups/" + this.props.selGroupSlug + "/networks",
      );
      var addNetworkToGroupFormData = {
        ...this.state.addNetworkToGroupFormData,
      };
      addNetworkToGroupFormData["groupId"] = "";
      await this.setState({ addNetworkToGroupFormData });
      await this.setState({ groupSlug: this.props.selGroupSlug });
      await this.fetchGroupNetworks();
    }
  };

  handleSearchSubmit = async (e) => {
    this.fetchGroupNetworks();
    e.preventDefault();
  };
  handleQueryChange = async (e) => {
    this.setState({ searchText: e.target.value });
  };

  showAddNetworkToGroupModal = async () => {
    this.setState({
      showAddNetworkToGroupModal: true,
    });
  };

  hideAddNetworkToGroupModal = async (e) => {
    this.setState({
      showAddNetworkToGroupModal: false,
      showSuccessMsg: false,
      showAddNewNetworkForm: false,
      showAddNetworkToGroupForm: true,
    });
    var addNetworkToGroupFormData = { ...this.state.addNetworkToGroupFormData };
    addNetworkToGroupFormData["networkId"] = "";
    await this.setState({ addNetworkToGroupFormData });
  };
  networkFormChangeHandler = async (e) => {
    const idx = e.target.selectedIndex;
    const option = e.target.querySelectorAll("option")[idx];
    const networkname = option.getAttribute("data-networkname");

    var addNetworkToGroupFormData = { ...this.state.addNetworkToGroupFormData };
    addNetworkToGroupFormData[e.target.name] = e.target.value;
    addNetworkToGroupFormData["networkTitle"] = networkname;
    await this.setState({ addNetworkToGroupFormData });
  };

  submitAddNetworkToGroupHandler = async (e) => {
    if (e !== undefined) {
      e.preventDefault();
    }
    await this.setState({
      loading: true,
    });

    await axios
      .post(
        API_URL + "/admin/groups/addNetwork",
        this.state.addNetworkToGroupFormData,
      )
      .then((res) => {
        this.fetchGroupNetworks();
        this.messageNotification(
          "success",
          this.props.t("Network added to group successfully!"),
        );
        this.setState({ loading: false, showSuccessMsg: true });
      })
      .catch((err) => {
        alert(
          "There is a problem in adding network to group. Please try again!",
        );
        console.log(err);
        this.setState({ loading: false });
      });
  };

  messageNotification = async (type, message) => {
    this.setState({
      successMsg: message,
    });

    setTimeout(() => {
      this.setState({
        successMsg: "",
      });
    }, 5000);
  };

  removeNetworkFromGroup = async (e, networkId) => {
    await this.setState({
      loading: true,
    });

    var addNetworkToGroupFormData = { ...this.state.addNetworkToGroupFormData };
    addNetworkToGroupFormData["networkId"] = networkId;
    await this.setState({ addNetworkToGroupFormData });

    await axios
      .post(
        API_URL + "/admin/groups/removeNetwork",
        this.state.addNetworkToGroupFormData,
      )
      .then((res) => {
        this.fetchGroupNetworks();
        this.messageNotification(
          "success",
          this.props.t("Network removed from group successfully!"),
        );
        this.setState({ loading: false });

        var addNetworkToGroupFormData = {
          ...this.state.addNetworkToGroupFormData,
        };
        addNetworkToGroupFormData["networkId"] = "";
        this.setState({ addNetworkToGroupFormData });
      })
      .catch((err) => {
        alert(
          "There is a problem in removing network from group. Please try again!",
        );
        console.log(err);
        this.setState({ loading: false });
      });
  };

  showAddNewNetworkForm = async (e) => {
    this.setState({
      showAddNewNetworkForm: true,
      showAddNetworkToGroupForm: false,
    });
  };
  hideAddNewNetworkForm = async (e) => {
    this.setState({
      showAddNewNetworkForm: false,
      showAddNetworkToGroupForm: true,
    });
  };

  addNewNetworkFormChangeHandler = async (e) => {
    var addNewNetworkFormData = { ...this.state.addNewNetworkFormData };
    addNewNetworkFormData[e.target.name] = e.target.value;

    if (e.target.name === "title") {
      addNewNetworkFormData[e.target.name] = e.target.value;
      addNewNetworkFormData["name"] = e.target.value.replace(/ /g, "");
    }

    await this.setState({ addNewNetworkFormData });

    setTimeout(
      function () {
        this.addNewNetworkFormValidation(e);
      }.bind(this),
      100,
    );
  };

  addNewNetworkFormSubDomainChangeHandler = (e) => {
    var addNewNetworkFormData = { ...this.state.addNewNetworkFormData };
    addNewNetworkFormData[e.target.name] = e.target.value + ".sided.co";
    this.setState({ addNewNetworkFormData });

    setTimeout(
      function () {
        this.addNewNetworkFormValidation(e);
      }.bind(this),
      100,
    );
  };

  addNewNetworkFormValidation = async (e = "", action = "") => {
    var validationErrors = { ...this.state.validationErrors };

    if (e.target.name === "subDomain" || action === "onSubmit") {
      var subDomainTrim = this.state.addNewNetworkFormData.subDomain.replace(
        ".sided.co",
        "",
      );
      if (subDomainTrim.trim() === "") {
        validationErrors["subDomain"] = "This field is required.";
        await this.setState({ validationErrors: validationErrors });
      } else if (!/^([a-z0-9-]+)?$/i.test(subDomainTrim)) {
        validationErrors["subDomain"] = "Use only letter, number and -.";
        await this.setState({ validationErrors: validationErrors });
      } else {
        delete validationErrors["subDomain"];
        await this.setState({ validationErrors: validationErrors });
      }
    }

    if (e.target.name === "primaryDomain" || action === "onSubmit") {
      if (this.state.addNewNetworkFormData.primaryDomain === "") {
        validationErrors["primaryDomain"] = "This field is required.";
        await this.setState({ validationErrors: validationErrors });
      } else if (
        this.state.addNewNetworkFormData.primaryDomain !== "" &&
        !/^((?!www|!http)[a-z0-9-]+\.(?:com|co|in|net|org|ca|edu|gov|mil|int|co\.uk|co\.us))(?:\/|$)/gm.test(
          this.state.addNewNetworkFormData.primaryDomain,
        )
      ) {
        validationErrors["primaryDomain"] = "The Domain Url is invalid.";
        await this.setState({ validationErrors: validationErrors });
      } else {
        delete validationErrors["primaryDomain"];
        await this.setState({ validationErrors: validationErrors });
      }
    }

    if (e.target.name === "title" || action === "onSubmit") {
      if (this.state.addNewNetworkFormData.title.trim() === "") {
        validationErrors["title"] = "This field is required.";
        await this.setState({ validationErrors: validationErrors });
      } else {
        delete validationErrors["title"];
        await this.setState({ validationErrors: validationErrors });
      }
    }

    if (
      Object.keys(this.state.validationErrors).length === 0 &&
      action === "onSubmit"
    ) {
      this.uploadLogoImages();
    } else {
      this.setState({
        loading: false,
      });
    }
  };

  uploadLogoImages = async (e) => {
    let imageArray = this.state.addNewNetworkFormData.images;
    let failure = false;

    for (var i = 0; i < imageArray.length; i++) {
      if (imageArray[i].image_url !== "") {
        const image_type = imageArray[i].image_type;
        const formData = new FormData();
        formData.append(
          "image",
          imageArray[i].image_url,
          imageArray[i].image_url.name,
        );

        var imgUploadUrl =
          API_URL +
          "/admin/client/imageUpload?type=" +
          image_type +
          "&clientName=" +
          this.state.name;
        if (image_type === "FAVICON") {
          imgUploadUrl =
            API_URL +
            "/admin/client/faviconUpload?clientName=" +
            this.state.name;
        }
        await axios.post(imgUploadUrl, formData).then((res) => {
          if (image_type === "LOGO") {
            var addNewNetworkFormData = { ...this.state.addNewNetworkFormData };
            addNewNetworkFormData["logo"] = res.data.data.id;
            addNewNetworkFormData["logoWidth"] = res.data.data.width;
            addNewNetworkFormData["logoHeight"] = res.data.data.height;
            this.setState({ addNewNetworkFormData });
          }
        });
      }
    }

    if (failure === false) {
      this.submitAddNewNetworkFormData();
    }
  };

  submitAddNewNetworkFormHandle = (e) => {
    this.setState({
      loading: true,
    });
    setTimeout(
      function () {
        this.addNewNetworkFormValidation(e, "onSubmit");
      }.bind(this),
      100,
    );

    e.preventDefault();
  };

  submitAddNewNetworkFormData = async (e) => {
    const formData = this.state.addNewNetworkFormData;
    formData["groupId"] = this.state.addNetworkToGroupFormData.groupId || "";
    formData["advertisingSource"] = "publisher_desk";
    formData["embedPlacements"] = [
      { placementName: "End of Content" },
      { placementName: "Sidebar", adPosition: "bottom" },
      { placementName: "In Content" },
    ];

    await axios
      .post(API_URL + "/admin/client/create", formData)
      .then(async (res) => {
        const addNetworkToGroupFormData = {
          ...this.state.addNetworkToGroupFormData,
        };
        addNetworkToGroupFormData["networkId"] = res.data.data.id;
        addNetworkToGroupFormData["networkTitle"] =
          this.state.addNewNetworkFormData.title;

        await this.setState({ addNetworkToGroupFormData });
        this.submitAddNetworkToGroupHandler();
        this.setState({ loading: false });
      })
      .catch((err) => {
        if (err.response.data.message && err.response.data.message[0]) {
          alert(err.response.data.message[0].name);
        }
        console.log(err);
        this.setState({ loading: false });
      });
  };

  render() {
    const { networks, selNetworkId } = this.props;
    const {
      loading,
      groupNetworks,
      showSuccessMsg,
      showAddNetworkToGroupForm,
      validationErrors,
      showInviteModal,
      availableNetworks,
    } = this.state;
    const { groupId, networkId, groupName, networkTitle } =
      this.state.addNetworkToGroupFormData;
    const { name, title, primaryDomain } = this.state.addNewNetworkFormData;
    const kTrans = this.props.t ? this.props.t : "";

    let subDomain = this.state.addNewNetworkFormData.subDomain;
    subDomain = subDomain.replace(".sided.co", "");

    var enableAddNewNetworkFormButton =
      Object.keys(this.state.validationErrors).length < 1 &&
      (name !== "" || title !== "" || primaryDomain !== "" || subDomain !== "")
        ? true
        : false;

    return (
      <section className={loading === true ? "loading" : ""}>
        {this.state.successMsg ? (
          <div className="sucessMsg">{this.state.successMsg}</div>
        ) : null}
        <div className="inline-content top title-section mb-2">
          <h2>{kTrans("Your Networks")}</h2>
          <div>
            <span
              onClick={() => this.setState({ showInviteModal: true })}
              className="btn btn-primary"
            >
              + {kTrans("Invite User")}
            </span>
            <span
              onClick={this.showAddNetworkToGroupModal}
              className="btn btn-primary ms-2"
            >
              + {kTrans("Add Network")}
            </span>
          </div>
        </div>
        <h3 className="mb-3 mb-md-4">
          <span className="lightColorSpan large">
            <NavLink
              className="d-inline-block"
              exact
              activeClassName="active"
              to="/groups"
            >
              {kTrans("All Groups")}
            </NavLink>
            &nbsp;/&nbsp;
          </span>
          {groupName}
        </h3>
        <div className="mb-3">
          <form
            id="search"
            autoComplete="off"
            className="navbar-form navbar-left w-25"
            method="get"
            onSubmit={this.handleSearchSubmit}
          >
            <input
              onChange={this.handleQueryChange}
              type="text"
              className="form-control"
              value={this.state.searchText}
              name="searchText"
              placeholder={kTrans("Search")}
            />
            <button type="submit">
              <i className="fa fa-search"></i>
            </button>
          </form>
        </div>

        <p>
          <small>
            <strong>
              {groupNetworks.length === 0 || groupNetworks.length > 1
                ? kTrans("Results")
                : kTrans("Result")}
              : {groupNetworks.length}
            </strong>
          </small>
          <NavLink
            className="d-inline-block customAnchor ms-4 ps-2"
            exact
            activeClassName="active"
            to={`/groups/${this.state.groupSlug}/users`}
          >
            {kTrans("Manage Group Users")}
          </NavLink>
        </p>
        <Table className="networks tableCommon">
          <thead>
            <tr>
              <th>{kTrans("NETWORKS")}</th>
              <th>{kTrans("NETWORK USERS")}</th>
              {/*<th>AUDIENCE</th>*/}
              <th></th>
            </tr>
          </thead>
          <tbody>
            {groupNetworks && groupNetworks.length ? (
              groupNetworks.map((el, i) => (
                <tr key={i}>
                  <td className="">
                    <strong>{el.header.title}</strong>
                    <span className="lightColorSpan">
                      {el.embedDomains.length > 0
                        ? el.embedDomains[0].domain
                        : ""}
                    </span>
                  </td>
                  <td>
                    {el.usersCount}{" "}
                    <NavLink
                      className="customAnchor d-inline-block"
                      to={`/groups/${this.state.groupSlug}/networks/${el.id}/users`}
                    >
                      &nbsp;{kTrans("View")}
                    </NavLink>
                  </td>
                  {/*<td>-- <NavLink className="customAnchor d-inline-block" to={`/groups/networks/${el.id}/users`}>&nbsp;View</NavLink></td>*/}
                  <td>
                    <span
                      className="customAnchor"
                      onClick={(e) => {
                        if (
                          window.confirm(
                            kTrans(
                              "Are you sure you want to remove this network from group?",
                            ),
                          )
                        ) {
                          this.removeNetworkFromGroup(e, el.id);
                        }
                      }}
                    >
                      {kTrans("Remove")}
                    </span>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="4">
                  <h3 className="text-center">{kTrans("No Network")}</h3>
                </td>
              </tr>
            )}
          </tbody>
        </Table>

        <Modal
          className="addGroupModal"
          size="lg"
          show={this.state.showAddNetworkToGroupModal}
        >
          <Modal.Body className={loading === true ? "loading" : ""}>
            <div className="modalContentWrapper">
              <img
                className="sidedFavicon"
                src={SIDED_FAVICON}
                alt="Sided favicon"
              />
              <h5 className="txtCenter mb-3">{kTrans("Add Network")}</h5>
              <p className="txtCenter">
                {kTrans(
                  'Select from available networks or select "Add New Network" to add a new network',
                )}
                .
              </p>
              {showAddNetworkToGroupForm === true ? (
                <Form
                  className="AddNetworkToGroupForm common-form pt-4"
                  onSubmit={this.submitAddNetworkToGroupHandler}
                >
                  {showSuccessMsg ? (
                    <div className="txtCenter pt-5 pb-5">
                      <p>
                        <strong>{kTrans("Success")}!</strong>
                      </p>
                      <p>
                        {kTrans("You have just added the following network")}:
                      </p>
                      <h6>{networkTitle}</h6>
                      {/*<span onClick={this.showAddNewNetworkForm} className="d-block customAnchor mt-5 mb-5 pt-3">+ {kTrans('Add New Network')}</span>*/}
                    </div>
                  ) : (
                    <>
                      <Form.Group controlId="networkId" className="p-0">
                        <Form.Label>{kTrans("Available networks")}</Form.Label>
                        {availableNetworks && availableNetworks.length > 0 ? (
                          <Form.Control
                            className="networkId"
                            as="select"
                            data-required="true"
                            onChange={this.networkFormChangeHandler}
                            name="networkId"
                            defaultValue={networkId}
                            htmlSize="5"
                          >
                            <option value="">
                              {kTrans("Select a Network")}
                            </option>
                            {availableNetworks.map((el, i) => (
                              <option
                                key={el.id}
                                value={el.id}
                                data-networkName={el.header.title}
                                data-id={el.id}
                              >
                                {el.header.title}
                              </option>
                            ))}
                          </Form.Control>
                        ) : (
                          kTrans("No network available!")
                        )}
                      </Form.Group>
                      <span
                        onClick={this.showAddNewNetworkForm}
                        className="d-block customAnchor mt-4 mb-4"
                      >
                        + {kTrans("Add New Network")}
                      </span>
                    </>
                  )}
                  <div className="buttons text-center inline-content mt-5 pt-4">
                    <span
                      className={`m-0 btn btn-secondary ${showSuccessMsg ? "disabled" : ""}`}
                      onClick={this.hideAddNetworkToGroupModal}
                    >
                      {kTrans("Cancel")}
                    </span>
                    {showSuccessMsg ? (
                      <span
                        className="m-0 btn btn-primary"
                        onClick={this.hideAddNetworkToGroupModal}
                      >
                        {kTrans("Done")}
                      </span>
                    ) : (
                      <Button
                        className={`m-0 ${networkId ? "" : "disabled"}`}
                        variant={networkId ? "primary" : "secondary"}
                        type="submit"
                      >
                        {kTrans("Add")}
                      </Button>
                    )}
                  </div>
                </Form>
              ) : (
                <Form
                  className="AddNewNetworkForm common-form pt-4"
                  onSubmit={this.submitAddNewNetworkFormHandle}
                >
                  {showSuccessMsg ? (
                    <div className="txtCenter pt-5 pb-5">
                      <p>
                        <strong>{kTrans("Success")}!</strong>
                      </p>
                      <p>
                        {kTrans("You have just added the following network")}:
                      </p>
                      <h6>{networkTitle}</h6>
                      {/*<span onClick={this.showAddNewNetworkForm} className="d-block customAnchor mt-5 mb-5 pt-3">+ Add New Network</span>*/}
                    </div>
                  ) : (
                    <>
                      <Form.Group>
                        <Form.Label>{kTrans("BUSINESS NAME")}</Form.Label>
                        <Form.Control
                          type="text"
                          onChange={this.addNewNetworkFormChangeHandler}
                          name="title"
                          placeholder={kTrans("Name")}
                          value={title}
                          id="title"
                        />
                        {validationErrors &&
                        validationErrors.title !== undefined ? (
                          <span className="validation-error">
                            {validationErrors.title}
                          </span>
                        ) : null}
                      </Form.Group>
                      <Form.Group>
                        <Form.Label>{kTrans("BUSINESS WEBSITE")}</Form.Label>
                        <div className="inline-content middle">
                          <Form.Control
                            type="text"
                            onChange={this.addNewNetworkFormChangeHandler}
                            name="primaryDomain"
                            value={primaryDomain}
                            id="primaryDomain"
                            placeholder={kTrans("yourdomain.com")}
                          />
                        </div>
                        {validationErrors &&
                        validationErrors.primaryDomain !== undefined ? (
                          <span className="validation-error">
                            {validationErrors.primaryDomain}
                          </span>
                        ) : null}
                      </Form.Group>
                      <Form.Group>
                        <Form.Label>{kTrans("SIDED SUBDOMAIN")}</Form.Label>
                        <div className="inline-content bottom">
                          <Form.Control
                            type="text"
                            onChange={
                              this.addNewNetworkFormSubDomainChangeHandler
                            }
                            name="subDomain"
                            value={subDomain}
                            id="subDomain"
                            placeholder={kTrans("yoursubdomain")}
                          />
                          <Form.Control
                            type="text"
                            disabled
                            value=".sided.co"
                            id="subDomainPostFix"
                          />
                        </div>
                        {validationErrors &&
                        validationErrors.subDomain !== undefined ? (
                          <span className="validation-error">
                            {validationErrors.subDomain}
                          </span>
                        ) : null}
                      </Form.Group>
                    </>
                  )}
                  <div className="buttons text-center inline-content mt-5 pt-4">
                    <span
                      className={`m-0 btn btn-secondary ${showSuccessMsg ? "disabled" : ""}`}
                      onClick={this.hideAddNewNetworkForm}
                    >
                      {kTrans("Back")}
                    </span>
                    {showSuccessMsg ? (
                      <span
                        className="m-0 btn btn-primary"
                        onClick={this.hideAddNetworkToGroupModal}
                      >
                        {kTrans("Done")}
                      </span>
                    ) : (
                      <Button
                        className={`m-0 ${enableAddNewNetworkFormButton ? "" : "disabled"}`}
                        variant={
                          enableAddNewNetworkFormButton
                            ? "primary"
                            : "secondary"
                        }
                        type="submit"
                      >
                        {kTrans("Add")}
                      </Button>
                    )}
                  </div>
                </Form>
              )}
            </div>
          </Modal.Body>
        </Modal>

        {showInviteModal ? (
          <InviteModal
            networksList={networks}
            selNetworkId={selNetworkId}
            onClose={() => this.setState({ showInviteModal: false })}
            isForGroup={true}
            groupId={groupId}
            groupName={groupName}
          />
        ) : null}
      </section>
    );
  }
}

const mapStateToProps = (state) => ({
  loggedInUser: state.login.user,
  networks: state.networks.networksList,
  selNetworkId: state.networks.selNetworkId,
  selGroupSlug: state.networks.selGroupSlug,
});

const Extended = withTranslation()(GroupNetworks);
Extended.static = GroupNetworks.static;

export default connect(mapStateToProps, {})(Extended);
