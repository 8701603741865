import axios from "axios";
import moment from "moment";
import { connect } from "react-redux";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import useAlertMessage from "../../hooks/useAlertMessage";
import { API_URL } from "../../constants/Globals";

function Collections({ selNetworkId }) {
  const [state, setState] = useState(1);
  const [loading, setLoading] = useState(true);
  const [collections, setCollections] = useState([]);
  const [setAlertMessage, getAlertMessage] = useAlertMessage();

  useEffect(() => {
    if (selNetworkId) {
      axios
        .get(`${API_URL}/collections?clientId=${selNetworkId}`)
        .then(({ data }) => {
          setLoading(false);
          setCollections(data.data);
        });
    }
  }, [selNetworkId, state]);

  return (
    <section className="segments">
      {getAlertMessage()}

      <div className="title-bar">
        <h1 className="page-title">Collections</h1>
        <Link to="/collections/new" className="blue-button size-l">
          Create Collection
        </Link>
      </div>

      <div className="table-filters">
        <div className="table-pagination">{collections.length} Collections</div>
      </div>

      <div className="table-wrapper">
        <table
          className="table-body top-border"
          cellSpacing="0"
          cellPadding="0"
        >
          <tbody>
            {loading ? (
              <tr className="table-row">
                <td className="table-cell table-cell-fluid">
                  <em>Loading...</em>
                </td>
              </tr>
            ) : null}

            {!loading && !collections.length ? (
              <tr className="table-row">
                <td className="table-cell table-cell-fluid">
                  <em>No collections yet...</em>
                </td>
              </tr>
            ) : null}

            {collections.map(({ id, name, createdAt }, index) => (
              <tr key={id} className="table-row">
                <td className="table-cell table-cell-fluid">
                  <Link
                    to={`/collections/${id}`}
                    className="text-button inherit-font"
                  >
                    {name}
                  </Link>
                </td>
                <td className="table-cell table-cell-labelled" width="150">
                  <div>
                    Created
                    <br />
                    <strong>{moment(createdAt).format("MMM DD, Y")}</strong>
                  </div>
                </td>
                <td className="table-cell table-action" width="280">
                  <div className="inline-buttons">
                    <Link
                      to={`/collections/${id}`}
                      className="blue-button bordered"
                    >
                      Edit
                    </Link>
                    <button
                      className="blue-button bordered"
                      onClick={() => {
                        if (window.confirm("Are you sure?")) {
                          const existingCollections = [].concat(collections);
                          existingCollections.splice(index, 1);
                          setCollections(existingCollections);
                          setAlertMessage(
                            "The collection was successfully deleted",
                          );

                          axios
                            .delete(`${API_URL}/collections/${id}`)
                            .then(() => setState(state * 2));
                        }
                      }}
                    >
                      Delete
                    </button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </section>
  );
}

const mapStateToProps = (state) => ({
  selNetworkId: state.networks.selNetworkId,
});

export default connect(mapStateToProps)(Collections);
