const init = {
  isLoading: false,
  networkUsers: null,
};

export const networkUsersReducer = (state = init, action) => {
  switch (action.type) {
    case "NETWORKUSERS_LOADING":
      return {
        ...state,
        //isLoading: true
      };
    case "NETWORKUSERS_LOADED":
      return {
        ...state,
        // isLoading: false,
        networkUsers: action.payload.data,
      };
    default:
      return state;
  }
};
