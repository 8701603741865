import React, { Component } from "react";
import { connect } from "react-redux";
import axios from "axios";
import moment from "moment";
import { NavLink } from "react-router-dom";
import { Row, Col, Tab, Tabs, Table } from "react-bootstrap";
import DateRangePicker from "react-bootstrap-daterangepicker";
import { Line } from "react-chartjs-2";
import { API_URL } from "../../constants/Globals";
import { BASENAME } from "../../constants/Globals";
import { withTranslation } from "react-i18next";

// const DocumentIcon = () => <svg width="13" height="17" viewBox="0 0 13 17" fill="none" xmlns="http://www.w3.org/2000/svg">
// 	<path d="M8.125 0L13 4.875V14.625C13 15.056 12.8288 15.4693 12.524 15.774C12.2193 16.0788 11.806 16.25 11.375 16.25H1.625C1.19402 16.25 0.780698 16.0788 0.475951 15.774C0.171205 15.4693 0 15.056 0 14.625V1.625C0 1.19402 0.171205 0.780698 0.475951 0.475951C0.780698 0.171205 1.19402 0 1.625 0H8.125ZM11.375 14.625V5.6875H7.3125V1.625H1.625V14.625H11.375ZM6.5 13.8125L3.25 10.5625H5.28125V8.125H7.71875V10.5625H9.75L6.5 13.8125Z" fill="#BDBDBD"/>
// </svg>

class GroupAdvertising extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isManaged: false,
      activeCategory: "impressions",
      activeTab: "overall",
      advertisingStats: {},
      advertisingChartValues: null,
      durStartTime: moment().subtract(14, "days").format("YYYY-MM-DD"),
      durEndTime: moment().subtract(1, "days").format("YYYY-MM-DD"),
      groupSlug: this.props.match.params.groupSlug,
      groupData: {},
      networkStats: null,
      searchText: "",
    };
  }

  getGroupDetail = async (e) => {
    await axios
      .get(
        API_URL +
          "/admin/groups/groupDetailBySlug?groupSlug=" +
          this.state.groupSlug,
      )
      .then((res) => {
        this.setState({ groupData: res.data.data });
      })
      .catch((err) => {
        alert("There is a problem in fetching group detail. Please try again!");
        console.log(err);
      });
  };

  componentDidMount = async () => {
    await this.getGroupDetail();
    this.loadData();
    this.loadChartsData();
  };

  componentDidUpdate = async (prevProps, prevState) => {
    if (
      prevProps.selGroupSlug !== null &&
      prevProps.selGroupSlug !== this.props.selGroupSlug
    ) {
      window.history.pushState(
        "",
        "",
        BASENAME + "/groups/" + this.props.selGroupSlug + "/advertising",
      );
      await this.setState({
        groupSlug: this.props.selGroupSlug,
        groupData: {},
      });
      await this.getGroupDetail();
      this.loadData();
      this.loadChartsData();
    }
  };

  loadData = async () => {
    await this.setState({ advertisingStats: [] });

    const { durStartTime, durEndTime, searchText } = this.state;

    /*if (networks) {
			const network = networks.find(network => network.id.toString() === selNetworkId.toString())
			const primaryDomain = network.embedDomains.find(domain => domain.isPrimary)

			if (primaryDomain) {*/
    axios
      .get(
        API_URL +
          `/admin/analytics/getAdvertisingStats?groupId=${this.state.groupData.id}&durStartTime=${durStartTime}T00:00:00.000Z&durEndTime=${durEndTime}T00:00:00.000Z&searchText=${searchText}`,
      )
      .then(({ data }) => {
        this.setState({ advertisingStats: data.data });
        if (data.networkStats) {
          this.setState({
            networkStats: data.networkStats.sort(
              (a, b) =>
                b.viewableImpressions.overall - a.viewableImpressions.overall,
            ),
          });
        }
      });
    /*}
		}*/
  };

  loadChartsData = async () => {
    await this.setState({ advertisingChartValues: null });

    const { activeCategory, durStartTime, durEndTime } = this.state;

    /*if (networks) {
			const network = networks.find(network => network.id.toString() === selNetworkId.toString())
			const primaryDomain = network.embedDomains.find(domain => domain.isPrimary)

			if (primaryDomain) {*/
    axios
      .get(
        API_URL +
          `/admin/analytics/getAdvertisingChartValues?groupId=${this.state.groupData.id}&durStartTime=${durStartTime}&durEndTime=${durEndTime}&chartType1=overall&chartType2=${activeCategory === "ecpm" ? "eCPM" : activeCategory === "revenue" ? "netRevenue" : "impressions"}`,
      )
      .then(({ data }) => {
        this.setState({ advertisingChartValues: data.data });
      });
    /*}
		}*/
  };

  handleSearchSubmit = async (e) => {
    this.loadData();
    e.preventDefault();
  };
  handleQueryChange = async (e) => {
    this.setState({ searchText: e.target.value });
  };

  sortNetworkBreakDown = async (e, orderBY) => {
    if (this.state.orderBY === orderBY) {
      this.setState({
        networkStats: this.state.networkStats.sort(
          (a, b) => b[orderBY].overall - a[orderBY].overall,
        ),
        orderBY: null,
        order: "DESC",
      });
    } else {
      this.setState({
        networkStats: this.state.networkStats.sort(
          (a, b) => a[orderBY].overall - b[orderBY].overall,
        ),
        orderBY: orderBY,
        order: "ASC",
      });
    }
  };

  render() {
    const {
      activeTab,
      activeCategory,
      advertisingStats,
      advertisingChartValues,
      networkStats,
    } = this.state;
    const { selGroupSlug } = this.props;
    const formatter = Intl.NumberFormat("en-US");
    const chartData = {};
    const kTrans = this.props.t ? this.props.t : "";

    if (advertisingChartValues) {
      advertisingChartValues.labels.forEach((label, index) => {
        chartData[label] = advertisingChartValues.values[index];
      });
    }

    return (
      <section>
        <Row className="">
          <Col md={12} className="inline-content top title-section">
            <h2>{kTrans("Advertising")}</h2>
            <div className="d-flex">
              <div className="datepickerSec">
                <i className="fa fa-calendar"></i>
                <DateRangePicker
                  onCallback={async (start, end, label) => {
                    await this.setState({
                      durStartTime: moment(start).format("YYYY-MM-DD"),
                      durEndTime: moment(end).format("YYYY-MM-DD"),
                    });
                    this.loadData();
                    this.loadChartsData();
                  }}
                  initialSettings={{
                    startDate: moment().subtract(14, "days"),
                    endDate: moment().subtract(1, "days"),
                    ranges: {
                      [this.props.t("Yesterday")]: [
                        moment().subtract(1, "days"),
                        moment().subtract(1, "days"),
                      ],
                      [this.props.t("Last 2 weeks")]: [
                        moment().subtract(14, "days"),
                        moment().subtract(1, "days"),
                      ],
                      [this.props.t("Last 30 Days")]: [
                        moment().subtract(30, "days"),
                        moment().subtract(1, "days"),
                      ],
                      [this.props.t("This Month")]: [
                        moment().startOf("month"),
                        moment().subtract(1, "days"),
                      ],
                      [this.props.t("Last Month")]: [
                        moment().subtract(1, "month").startOf("month"),
                        moment().subtract(1, "month").endOf("month"),
                      ],
                      [this.props.t("Last 90 Days")]: [
                        moment().subtract(90, "days"),
                        moment().subtract(1, "days"),
                      ],
                      [this.props.t("This year")]: [
                        moment().startOf("year"),
                        moment().subtract(1, "days"),
                      ],
                      [this.props.t("Last year")]: [
                        moment().subtract(1, "year").startOf("year"),
                        moment().subtract(1, "year").endOf("year"),
                      ],
                    },
                  }}
                >
                  <input type="text" className="datepickerField" />
                </DateRangePicker>
              </div>
            </div>
          </Col>
        </Row>
        {/*
			<Row className="mb-4">
				<Col md={12}>
					<span 
						className={ 'toggle-side ' + (isManaged ? '' : 'active') }
						onClick={() => this.setState({ isManaged: false })}
					>
						Programmatic
					</span>
					<Form.Check.Input 
						className="toggle-checkbox small sided-id" 
						style={{ float: 'none' }}
						checked={isManaged} 
						onChange={() => this.setState({ isManaged: !isManaged })}
						name="featured" 
						value="1" 
					/>
					<span 
						className={ 'toggle-side ' + (isManaged ? 'active' : '') }
						onClick={() => this.setState({ isManaged: true })}
					>
						Managed Campaigns
					</span>
				</Col>
			</Row>
			*/}
        <Row className="mb-4 mb-md-5  advertising-stats">
          <Col md={4}>
            <div
              className={
                "card widget-flat main-category " +
                (activeCategory === "impressions" ? "active" : "")
              }
              onClick={async () => {
                await this.setState({ activeCategory: "impressions" });
                this.loadChartsData();
              }}
            >
              <div className="card-body">
                <div className="d-flex align-items-center justify-content-between mb-2">
                  <h1>
                    {advertisingStats.viewableImpressions
                      ? formatter.format(
                          advertisingStats.viewableImpressions.overall,
                        )
                      : 0}
                  </h1>
                </div>
                <div className="d-flex align-items-center justify-content-between">
                  <strong>{kTrans("Impressions")}</strong>
                  {/*
								<div>
									14,231 <small>Previous</small>
								</div>
								*/}
                </div>
              </div>
            </div>
          </Col>
          <Col md={4}>
            <div
              className={
                "card widget-flat main-category " +
                (activeCategory === "revenue" ? "active" : "")
              }
              onClick={async () => {
                await this.setState({ activeCategory: "revenue" });
                this.loadChartsData();
              }}
            >
              <div className="card-body">
                <div className="d-flex align-items-center justify-content-between mb-2">
                  <h1>
                    $
                    {advertisingStats.netRevenue
                      ? formatter.format(
                          Math.floor(advertisingStats.netRevenue.overall),
                        )
                      : 0}
                  </h1>
                </div>
                <div className="d-flex align-items-center justify-content-between">
                  <strong>{kTrans("Revenue")}</strong>
                  {/*
								<div>
									$.065 <small>Previous</small>
								</div>
								*/}
                </div>
              </div>
            </div>
          </Col>
          <Col md={4}>
            <div
              className={
                "card widget-flat main-category " +
                (activeCategory === "ecpm" ? "active" : "")
              }
              onClick={async () => {
                await this.setState({ activeCategory: "ecpm" });
                this.loadChartsData();
              }}
            >
              <div className="card-body">
                <div className="d-flex align-items-center justify-content-between mb-2">
                  <h1>
                    $
                    {advertisingStats.eCPM && advertisingStats.eCPM.overall
                      ? advertisingStats.eCPM.overall.toFixed(2)
                      : 0}
                  </h1>
                </div>
                <div className="d-flex align-items-center justify-content-between">
                  <strong>{kTrans("eCPM")}</strong>
                  {/*
								<div>
									$.065 <small>Previous</small>
								</div>
								*/}
                </div>
              </div>
            </div>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col md={6}>
            <h3>
              {activeCategory === "ecpm"
                ? kTrans("eCPM")
                : activeCategory === "revenue"
                  ? kTrans("REVENUE")
                  : kTrans("IMPRESSIONS")}
            </h3>
          </Col>
          {/*
				<Col md={6} className="text-end">
					<span className="text-button">
						<DocumentIcon /> Download CSV
					</span>
				</Col>
				*/}
        </Row>
        <Row className="mb-3 mb-md-5">
          <Col md={12}>
            {advertisingChartValues ? (
              <Line
                options={{
                  responsive: true,
                  plugins: { legend: { display: false } },
                  scales: {
                    y: {
                      ticks: {
                        callback: (label, index, labels) =>
                          activeCategory === "impressions"
                            ? (label.toString().indexOf("000") === -1 &&
                              label !== 0
                                ? (label / 1000).toFixed(2)
                                : (label / 1000).toFixed(2)) + "k"
                            : "$" + label.toFixed(2),
                      },
                    },
                  },
                }}
                data={{
                  labels: advertisingChartValues.labels,
                  datasets: [
                    {
                      label: "",
                      data: chartData,
                      borderColor: "rgb(53, 162, 235)",
                      backgroundColor: "rgba(53, 162, 235, 0.5)",
                    },
                  ],
                }}
              />
            ) : null}
          </Col>
        </Row>
        <Row className="mb-2 pt-4">
          <h3 className="overlappedH3">{kTrans("DEVICE BREAKDOWN")}</h3>
          <Col className="sided-tabs pb-3">
            <Tabs
              variant="pills"
              activeKey={activeTab}
              defaultActiveKey={activeTab}
              onSelect={(activeTab) => this.setState({ activeTab })}
              className="d-flex justify-content-center"
            >
              <Tab eventKey="overall" title={kTrans("Overall")}></Tab>
              <Tab eventKey="mobile" title={kTrans("Mobile")}></Tab>
              <Tab eventKey="desktop" title={kTrans("Desktop")}></Tab>
            </Tabs>
          </Col>
        </Row>
        {/*
			<Row className="mb-2">
				<Col md={12} className="text-end">
					<span className="text-button">
						<DocumentIcon /> Download CSV
					</span>
				</Col>
			</Row>
			*/}
        <Row className="mb-3 mb-md-5 advertising-stats">
          <Col md={3}>
            <div className="card">
              <div className="card-header text-center">
                <strong>{kTrans("Impressions")}</strong>
              </div>
              <div className="card-body text-center">
                <h2>
                  {advertisingStats.viewableImpressions
                    ? formatter.format(
                        advertisingStats.viewableImpressions[activeTab],
                      )
                    : 0}
                </h2>
                <div>{kTrans("Impressions")}</div>
              </div>
            </div>
          </Col>
          <Col md={3}>
            <div className="card">
              <div className="card-header text-center">
                <strong>{kTrans("Revenue")}</strong>
              </div>
              <div className="card-body text-center">
                <h2>
                  $
                  {advertisingStats.netRevenue
                    ? Math.floor(advertisingStats.netRevenue[activeTab])
                    : 0}
                </h2>
                <div>{kTrans("Revenue")}</div>
              </div>
            </div>
          </Col>
          <Col md={3}>
            <div className="card">
              <div className="card-header text-center">
                <strong>{kTrans("Clicks")}</strong>
              </div>
              <div className="card-body text-center">
                <h2>
                  {advertisingStats.clicks
                    ? formatter.format(advertisingStats.clicks[activeTab])
                    : 0}
                </h2>
                <div>{kTrans("Clicks")}</div>
              </div>
            </div>
          </Col>
          <Col md={3}>
            <div className="card">
              <div className="card-header text-center">
                <strong>{kTrans("eCPM")}</strong>
              </div>
              <div className="card-body text-center">
                <h2>
                  $
                  {advertisingStats.eCPM && advertisingStats.eCPM[activeTab]
                    ? advertisingStats.eCPM[activeTab].toFixed(2)
                    : 0}
                </h2>
                <div>{kTrans("eCPM")}</div>
              </div>
            </div>
          </Col>
        </Row>
        {/*
			<Row>
				<Col className="sided-tabs">
					<Tabs
						variant="pills"
						activeKey={activeTab}
			      defaultActiveKey={activeTab}
			      onSelect={activeTab => this.setState({ activeTab })}
			      className="d-flex justify-content-center"
			    >
			      <Tab eventKey="overall" title="Overall"></Tab>
			      <Tab eventKey="mobile" title="Mobile"></Tab>
			      <Tab eventKey="desktop" title="Desktop"></Tab>
			    </Tabs>
				</Col>
			</Row>
			<Row>
				<Col md={12}>
					<Bar 
						options={{
						  indexAxis: 'y',
						  responsive: true,
						  plugins: { legend: { display: false } },
						  scales: { x: { min: 0, max: 20 } }
						}} 
						data={{
						  labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
						  datasets: [{
						    label: '',
						    data: [3,6,9,12,15,18,18,15,12,9,6,3],
						    borderColor: 'rgb(53, 162, 235)',
						    backgroundColor: 'rgba(53, 162, 235, 0.5)'
						  }]
						}} 
					/>
				</Col>
			</Row>
			*/}

        <Row className="pt-4">
          <h3 className="pb-3">{kTrans("NETWORK BREAKDOWN")}</h3>
          <div className="mb-3 mb-md-5 inline-content mobile-block">
            <form
              id="search"
              autoComplete="off"
              className="navbar-form navbar-left w-25"
              method="get"
              onSubmit={this.handleSearchSubmit}
            >
              <input
                onChange={this.handleQueryChange}
                type="text"
                className="form-control"
                value={this.state.searchText}
                name="searchText"
                placeholder={kTrans("Search for a network")}
              />
              <button type="submit">
                <i className="fa fa-search"></i>
              </button>
            </form>
            <NavLink
              className="btn btn-outline-primary"
              activeClassName="active"
              to={`/groups/${selGroupSlug}/networks`}
            >
              {kTrans("Manage Networks")}
            </NavLink>
          </div>
          <Col className="table-wrapper">
            <Table className="networksAdBreakdown tableCommon">
              <thead>
                <tr>
                  <th className="w-50">{kTrans("Network Name")}</th>
                  <th
                    className={
                      this.state.orderBY === "viewableImpressions" &&
                      this.state.order === "ASC"
                        ? "ASC"
                        : "DESC"
                    }
                  >
                    <span
                      role="button"
                      onClick={(e) =>
                        this.sortNetworkBreakDown(e, "viewableImpressions")
                      }
                    >
                      {kTrans("Impressions")}{" "}
                      <svg
                        width="11"
                        height="8"
                        viewBox="0 0 11 7"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M8.79776 0.000546548L10.2181 1.44129L5.10904 6.56055L-2.2377e-07 1.44129L1.42031 0.00054687L5.10904 3.63818L8.79776 0.000546548Z"
                          fill="#828282"
                        />
                      </svg>
                    </span>
                  </th>
                  <th
                    className={
                      this.state.orderBY === "netRevenue" &&
                      this.state.order === "ASC"
                        ? "ASC"
                        : "DESC"
                    }
                  >
                    <span
                      role="button"
                      onClick={(e) =>
                        this.sortNetworkBreakDown(e, "netRevenue")
                      }
                    >
                      {kTrans("Revenue")}{" "}
                      <svg
                        width="11"
                        height="8"
                        viewBox="0 0 11 7"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M8.79776 0.000546548L10.2181 1.44129L5.10904 6.56055L-2.2377e-07 1.44129L1.42031 0.00054687L5.10904 3.63818L8.79776 0.000546548Z"
                          fill="#828282"
                        />
                      </svg>
                    </span>
                  </th>
                  <th
                    className={
                      this.state.orderBY === "clicks" &&
                      this.state.order === "ASC"
                        ? "ASC"
                        : "DESC"
                    }
                  >
                    <span
                      role="button"
                      onClick={(e) => this.sortNetworkBreakDown(e, "clicks")}
                    >
                      {kTrans("Clicks")}{" "}
                      <svg
                        width="11"
                        height="8"
                        viewBox="0 0 11 7"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M8.79776 0.000546548L10.2181 1.44129L5.10904 6.56055L-2.2377e-07 1.44129L1.42031 0.00054687L5.10904 3.63818L8.79776 0.000546548Z"
                          fill="#828282"
                        />
                      </svg>
                    </span>
                  </th>
                  <th
                    className={
                      this.state.orderBY === "eCPM" &&
                      this.state.order === "ASC"
                        ? "ASC"
                        : "DESC"
                    }
                  >
                    <span
                      role="button"
                      onClick={(e) => this.sortNetworkBreakDown(e, "eCPM")}
                    >
                      {kTrans("eCPM")}{" "}
                      <svg
                        width="11"
                        height="8"
                        viewBox="0 0 11 7"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M8.79776 0.000546548L10.2181 1.44129L5.10904 6.56055L-2.2377e-07 1.44129L1.42031 0.00054687L5.10904 3.63818L8.79776 0.000546548Z"
                          fill="#828282"
                        />
                      </svg>
                    </span>
                  </th>
                </tr>
              </thead>
              <tbody>
                {networkStats && networkStats.length ? (
                  networkStats.map((el, i) => (
                    <tr key={i}>
                      <td class="">
                        {el.network ? (
                          <>
                            <strong>{el.network.header.title}</strong>
                            <span class="lightColorSpan">
                              {el.network.primaryDomain.length > 0
                                ? el.network.primaryDomain[0].domain
                                : ""}
                            </span>{" "}
                          </>
                        ) : null}
                      </td>
                      <td>
                        {el.viewableImpressions
                          ? formatter.format(
                              Math.floor(el.viewableImpressions.overall),
                            )
                          : 0}
                      </td>
                      <td>
                        $
                        {el.netRevenue
                          ? formatter.format(Math.floor(el.netRevenue.overall))
                          : 0}
                      </td>
                      <td>
                        {el.clicks
                          ? formatter.format(Math.floor(el.clicks.overall))
                          : 0}
                      </td>
                      <td>
                        $
                        {el.eCPM
                          ? formatter.format(el.eCPM.overall.toFixed(2))
                          : 0}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="4">
                      <h3 className="text-center">{kTrans("No Network")}</h3>
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </Col>
        </Row>
      </section>
    );
  }
}

const mapStateToProps = (state) => ({
  networks: state.networks.networksList,
  selNetworkId: state.networks.selNetworkId,
  selDomainId: state.embedDomains.selDomainId,
  selGroupSlug: state.networks.selGroupSlug,
});

const Extended = withTranslation()(GroupAdvertising);
Extended.static = GroupAdvertising.static;

export default connect(mapStateToProps)(Extended);
