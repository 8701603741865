import React from "react";
import { Route } from "react-router-dom";
import NetworkUsers from "./pages/NetworkUsers";
import Debates from "./pages/Debates";
import Analytics from "./pages/Analytics";
import Collections from "./pages/Collections";
import NewCollection from "./pages/NewCollection";
import Forms from "./pages/Forms";
import NewForm from "./pages/NewForm";
import Audience from "./pages/Audience";
import Segments from "./pages/Segments";
import NewSegment from "./pages/NewSegment";
import Networks from "./pages/Networks";
import AddNetwork from "./pages/AddNetwork";
import EditNetwork from "./pages/EditNetwork";
import Advertising from "./pages/Advertising";
import StartDebate from "./pages/StartDebate";
import EditDebate from "./pages/EditDebate";
import DraftDebate from "./pages/DraftDebate";
import EditProfile from "./pages/EditProfile";
import Groups from "./pages/Groups";
import GroupNetworks from "./pages/GroupNetworks";
import GroupNetworkUsers from "./pages/GroupNetworkUsers";
import GroupUsers from "./pages/GroupUsers";
import { connect } from "react-redux";
import GroupAdvertising from "./pages/GroupAdvertising";
import Moderation from "./pages/Moderation";

class Section extends React.Component {
  componentDidUpdate(prevProps, prevState) {
    const { selNetworkId, loggedInUser } = this.props;
    if (
      loggedInUser.roles.find(
        (role) =>
          role.name === "ADMIN" &&
          role.roleUser.clientId === parseInt(selNetworkId),
      ) ||
      loggedInUser.roles.find(
        (role) =>
          role.name === "MODERATOR" &&
          role.roleUser.clientId === parseInt(selNetworkId),
      )
    ) {
    } else {
      if (
        this.props.loggedInUser.roles.find(
          (role) =>
            role.name === "EDITOR" &&
            role.name !== "ADMIN" &&
            role.roleUser.clientId === parseInt(this.props.selNetworkId),
        )
      ) {
        // window.location.href=BASENAME+"/";
      }
    }
  }

  render() {
    return (
      <>
        <Route exact path="/network-users" component={NetworkUsers} />
        <Route exact path="/" render={(props) => <Debates {...props} />} />
        <Route
          exact
          path="/quizzes"
          render={(props) => <Debates {...props} type="quiz" />}
        />
        <Route exact path="/collections" component={Collections} />
        <Route exact path="/collections/:id" component={NewCollection} />
        <Route exact path="/forms" component={Forms} />
        <Route exact path="/forms/:id" component={NewForm} />
        <Route exact path="/analytics" component={Analytics} />
        <Route exact path="/audience" component={Audience} />
        <Route exact path="/audience/segments" component={Segments} />
        <Route exact path="/audience/segments/:id" component={NewSegment} />
        <Route exact path="/advertising" component={Advertising} />
        <Route exact path="/networks" component={Networks} />
        <Route exact path="/networks/add-network" component={AddNetwork} />
        <Route
          exact
          path="/networks/edit-network/:id"
          render={(props) => <EditNetwork {...props} />}
        />
        <Route exact path="/debates/start-debate" component={StartDebate} />
        <Route
          exact
          path="/debates/debate/:id"
          render={(props) => <EditDebate {...props} />}
        />
        <Route exact path="/debates/draft/:id" component={DraftDebate} />
        <Route exact path="/profile/edit" component={EditProfile} />
        <Route exact path="/groups" component={Groups} />
        <Route
          exact
          path="/groups/:groupSlug/networks/"
          component={GroupNetworks}
        />
        <Route
          exact
          path="/groups/:groupSlug/networks/:clientId/users"
          component={GroupNetworkUsers}
        />
        <Route exact path="/groups/:groupSlug/users" component={GroupUsers} />
        <Route
          exact
          path="/groups/:groupSlug/analytics/"
          component={Analytics}
        />
        <Route
          exact
          path="/groups/:groupSlug/advertising/"
          component={GroupAdvertising}
        />
        <Route exact path="/moderation" component={Moderation} />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  loggedInUser: state.login.user,
  selNetworkId: state.networks.selNetworkId,
});

export default connect(mapStateToProps, {})(Section);
