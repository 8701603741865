import React from "react";
import logo from "../logo.png";
import { Container, Row, Col, Form, Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { connect } from "react-redux";
import { logout } from "../redux/action/login.action";
import { saveSelectedNetwork } from "../redux/action/networks.action";
import { MAIN_WEBSITE_URL, DEFAULT_USER_IMG_URL } from "../constants/Globals";
import { NavLink } from "react-router-dom";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { withTranslation } from "react-i18next";

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userDropDown: false,
      showNoAccessModal: false,
      dropdownCollapse: true,
    };
    this.networkDropdownWrapperRef = React.createRef();
    this.avatarSectionWrapperRef = React.createRef();
  }

  userAvatarClick = () => {
    this.setState({
      userDropDown: this.state.userDropDown ? false : true,
    });
  };
  logOut = async () => {
    await this.props.logout();
  };

  handleNetworkChange = async (e, networkId) => {
    await this.props.saveSelectedNetwork(networkId);
    window.location.reload();
    this.setState({ dropdownCollapse: true });
  };

  hideNoAccessModal = async (e) => {
    this.setState({
      showNoAccessModal: false,
    });
  };

  componentDidMount = async (e) => {
    function checkScrollbar() {
      const aside = document.getElementsByTagName("aside")[0];
      if (aside.clientHeight < aside.scrollHeight) {
        aside.classList.add("scrollPresent");
      }
      document.getElementsByTagName("aside")[0].style.height =
        window.innerHeight - 82 + "px";
    }
    let timerId = setInterval(() => checkScrollbar(), 500);
    setTimeout(() => {
      clearInterval(timerId);
    }, 3000);

    document.addEventListener("mousedown", this.handleClickOutside);

    if (window.location.href.indexOf("client-id") !== -1) {
      const clientId = Object.fromEntries(
        new URLSearchParams(window.location.search).entries(),
      )["client-id"];

      await this.props.saveSelectedNetwork(clientId);
      window.location.href = window.location.href.replace(
        `&client-id=${clientId}`,
        "",
      );
    }

    this.props.i18n.changeLanguage(localStorage.getItem("selLanguage"));
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.networks !== this.props.networks) {
      this.setState({
        showNoAccessModal:
          this.props.networks && this.props.networks.length === 0
            ? true
            : false,
      });
    }
    if (prevProps.selNetworkId !== this.props.selNetworkId) {
      this.props.i18n.changeLanguage(localStorage.getItem("selLanguage"));
    }
  }

  toggleDropdown = async (e) => {
    this.setState({
      dropdownCollapse: this.state.dropdownCollapse ? false : true,
    });
  };

  handleClickOutside = async (e) => {
    if (
      this.networkDropdownWrapperRef &&
      this.networkDropdownWrapperRef.current &&
      !this.networkDropdownWrapperRef.current.contains(e.target)
    ) {
      this.setState({
        dropdownCollapse: true,
      });
    }

    if (
      this.avatarSectionWrapperRef &&
      this.avatarSectionWrapperRef.current &&
      !this.avatarSectionWrapperRef.current.contains(e.target)
    ) {
      this.setState({
        userDropDown: false,
      });
    }
    if (e.target.parentNode.className === "userDropDown") {
      setTimeout(
        function () {
          this.setState({
            userDropDown: false,
          });
        }.bind(this),
        300,
      );
    }
  };
  toggleSidebarVisibility = async (e) => {
    document.body.classList.toggle("menuVisible");
  };

  render() {
    const { userDropDown } = this.state;
    const loggedInUser = this.props.loggedInUser;
    const kTrans = this.props.t ? this.props.t : "";
    return (
      <header>
        <Container>
          <Row>
            <Col xs={8} className="d-flex align-item-center">
              <div className="toggleSidebarVisibility">
                <svg
                  id="toggleSidebarVisibility"
                  onClick={this.toggleSidebarVisibility}
                  className="menuToggle fa-flip-horizontal"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="16"
                  viewBox="0 0 24 16"
                  fill="none"
                >
                  <path
                    d="M24 12.8133L22.12 14.6667L15.44 8L22.12 1.33333L24 3.18667L19.2533 8L24 12.8133ZM0 0H17.3333V2.66667H0V0ZM0 9.33333V6.66667H13.3333V9.33333H0ZM0 16V13.3333H17.3333V16H0Z"
                    fill="#BDBDBD"
                  />
                </svg>
              </div>
              <NavLink exact to="/" className="navbar-brand">
                <img alt="Logo" src={logo} className="logo" />
              </NavLink>
            </Col>
            <Col xs={4} className="header-right-col">
              <div className="avatarSection" ref={this.avatarSectionWrapperRef}>
                {userDropDown ? (
                  <div className="userDropDown">
                    <h5 className="mb-0">{this.props.loggedInUser.name}</h5>
                    <a
                      className="user-handle customAnchor"
                      target="_blank"
                      rel="noreferrer"
                      href={"/" + this.props.loggedInUser.username}
                      tabIndex="0"
                    >
                      @{this.props.loggedInUser.username}
                    </a>
                    <hr />
                    <a
                      target="_blank"
                      rel="noreferrer"
                      className="mb-3 mt-2"
                      href={`${MAIN_WEBSITE_URL}/${this.props.loggedInUser.username}`}
                      tabIndex="0"
                    >
                      {kTrans("View Profile")}
                    </a>
                    <NavLink exact className="mb-3 mt-2" to="/profile/edit">
                      {kTrans("Edit My Profile")}
                    </NavLink>
                    <a
                      className="mb-3 mt-2"
                      href="mailto:support@sided.co?subject=Sided support request"
                    >
                      {kTrans("Help")}
                    </a>
                    <button
                      onClick={this.logOut}
                      className="btn btn-outline-primary pt-1 pb-1"
                    >
                      {kTrans("Logout")}
                    </button>
                  </div>
                ) : (
                  ""
                )}
                <span
                  role="button"
                  onClick={this.userAvatarClick}
                  className="user-avatar"
                >
                  {loggedInUser.avatarObject ? (
                    <img
                      style={{ borderColor: loggedInUser.statusColor }}
                      alt={this.props.loggedInUser.name}
                      src={loggedInUser.avatarObject.small.location}
                      className="img-circle avatar"
                      onError={(e) => {
                        e.target.src = DEFAULT_USER_IMG_URL;
                      }}
                    />
                  ) : (
                    <img
                      style={{ borderColor: loggedInUser.statusColor }}
                      alt={this.props.loggedInUser.name}
                      src={DEFAULT_USER_IMG_URL}
                      className="img-circle avatar"
                    />
                  )}
                  {loggedInUser.roles.length > 0 &&
                  loggedInUser.roles.find(
                    (role) => role.name === "VERIFIED",
                  ) ? (
                    <FontAwesomeIcon
                      style={{ color: loggedInUser.checkmarkColor }}
                      icon={faCheckCircle}
                      className="procheckmark"
                    />
                  ) : null}
                </span>
              </div>
              <a
                className="me-4 supportLink"
                target="_blank"
                rel="noreferrer"
                href="https://support.sided.co/support/home"
              >
                Support
              </a>
            </Col>
          </Row>
        </Container>

        <Modal
          className="removeDebModal"
          size="md"
          show={this.state.showNoAccessModal}
        >
          <Modal.Body>
            <div className="modalContentWrapper">
              <Form className="toggleArchive common-form">
                <div className="form-group txtCenter">
                  <b>
                    {kTrans("You don't currently have access to any domains")}.{" "}
                    {kTrans("Contact")}{" "}
                    <a
                      className="d-inline"
                      href="mailto:support@sided.co"
                      title="Sided support"
                    >
                      <u>support@sided.co</u>
                    </a>{" "}
                    {kTrans("if you believe this is an error")}
                  </b>
                </div>
                <div className="buttons text-center">
                  <span
                    className="btn btn-primary"
                    onClick={this.hideNoAccessModal}
                  >
                    {kTrans("Close")}
                  </span>
                </div>
              </Form>
            </div>
          </Modal.Body>
        </Modal>
      </header>
    );
  }
}

const mapStateToProps = (state) => ({
  networks: state.networks.networksList,
  selNetworkId: state.networks.selNetworkId,
  loggedInUser: state.login.user,
  isLogin: state.login.isLogin,
  selLanguage: state.networks.selLanguage,
});

const Extended = withTranslation()(Header);
Extended.static = Header.static;

export default connect(mapStateToProps, { logout, saveSelectedNetwork })(
  Extended,
);
