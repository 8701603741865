import React from "react";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import {
  loadEmbedDomains,
  saveSelectedDomain,
} from "../../redux/action/embedDomains.action";
import { Row, Col, Table } from "react-bootstrap";
import { API_URL, BASENAME } from "../../constants/Globals";
import { numFormatter } from "../../helpers/Number";
import axios from "axios";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "chart.js/auto";
import { Chart } from "react-chartjs-2";
import {
  Chart as ChartJS,
  LineController,
  LineElement,
  PointElement,
  LinearScale,
  Title,
} from "chart.js";
import { withTranslation } from "react-i18next";
ChartJS.register(LineController, LineElement, PointElement, LinearScale, Title);

const moment = require("moment-timezone");
const momentDurationFormatSetup = require("moment-duration-format");

momentDurationFormatSetup(moment);

const SortIcon = () => (
  <svg
    className="ms-1"
    width="11"
    height="8"
    viewBox="0 0 11 7"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.79776 0.000546548L10.2181 1.44129L5.10904 6.56055L-2.2377e-07 1.44129L1.42031 0.00054687L5.10904 3.63818L8.79776 0.000546548Z"
      fill="#828282"
    />
  </svg>
);

class Analytics extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      durStartTime: moment().subtract(14, "days").format("YYYY-MM-DD"),
      durEndTime: moment().subtract(1, "days").format("YYYY-MM-DD"),
      domainChartType: "pageViews",
      domainStatData: null,
      pagesAllLabels: [],
      pagesAllData: [],
      groupImpressions: [],
      debateStatData: null,
      activeCategory: "impressions",
      searchText: "",
      orderBy: "impressions",
      order: -1,
    };
  }

  datePickerCallback = async (start, end, label) => {
    await this.setState({
      durStartTime: moment(start).format("YYYY-MM-DD"),
      durEndTime: moment(end).format("YYYY-MM-DD"),
    });
    this.getDebateStats();
    this.getDomainChartValues();
  };

  getEmbedDomains = async (forceLoad) => {
    if (!this.props.embedDomainsLoaded || forceLoad) {
      await this.props.loadEmbedDomains(this.props.selNetworkId);
    }
  };

  getDebateStats = async () => {
    const { selNetworkId, selDomainId, match } = this.props;
    const { groupSlug } = match.params;
    const { domainChartType, durStartTime, durEndTime } = this.state;

    if ((selNetworkId !== null || groupSlug) && selDomainId !== null) {
      this.setState({ loading: true });

      const clientOrGroup = groupSlug
        ? `groupSlug=${groupSlug}`
        : `clientId=${selNetworkId}`;
      const queryParams = `?domainId=${selDomainId}&${clientOrGroup}&domainChartType=${domainChartType}&durStartTime=${durStartTime}&durEndTime=${durEndTime}`;

      axios
        .get(`${API_URL}/admin/analytics/getDebateStats${queryParams}`)
        .then((res) =>
          this.setState({
            debateStatData: res.data.data,
            loading: false,
          }),
        )
        .catch((err) => console.log("err", err));
    }
  };

  getDomainChartValues = () => {
    const { selNetworkId, selDomainId, match } = this.props;
    const { groupSlug } = match.params;
    const { domainChartType, durStartTime, durEndTime } = this.state;

    if ((selNetworkId !== null || groupSlug) && selDomainId !== null) {
      this.setState({ loading: true });

      const clientOrGroup = groupSlug
        ? `groupSlug=${groupSlug}`
        : `clientId=${selNetworkId}`;
      const queryParams = `?domainId=${selDomainId}&${clientOrGroup}&domainChartType=${domainChartType}&durStartTime=${durStartTime}&durEndTime=${durEndTime}`;

      axios
        .get(`${API_URL}/admin/analytics/getDomainChartValues${queryParams}`)
        .then(({ data }) =>
          this.setState({
            pagesAllLabels: data.data.labels,
            pagesAllData: data.data.values.all,
            groupImpressions: data.data.networks,
            loading: false,
          }),
        )
        .catch((err) => console.log("err", err));
    }
  };

  componentDidMount = () => {
    this.getEmbedDomains(false);
    this.getDebateStats();
    this.getDomainChartValues();
  };

  componentDidUpdate = async (prevProps, prevState) => {
    if (prevProps.selNetworkId !== this.props.selNetworkId) {
      this.getEmbedDomains(true);
      this.getDebateStats();
      this.getDomainChartValues();
    }

    if (prevProps.selDomainId !== this.props.selDomainId) {
      this.getDebateStats();
      this.getDomainChartValues();
    }

    if (
      prevProps.selGroupSlug !== null &&
      prevProps.selGroupSlug !== this.props.selGroupSlug
    ) {
      window.history.pushState(
        "",
        "",
        BASENAME + "/groups/" + this.props.selGroupSlug + "/analytics",
      );
      window.location.reload();
    }
  };

  getNetworkDomain = (network) => {
    const { networks } = this.props;
    const matchedNetwork = networks.find((n) => n.id === network.id);

    if (
      matchedNetwork &&
      matchedNetwork.embedDomains &&
      matchedNetwork.embedDomains.length
    ) {
      return matchedNetwork.embedDomains[0].domain;
    }

    return "";
  };

  render() {
    const {
      domainChartType,
      debateStatData,
      domainStatData,
      pagesAllLabels,
      pagesAllData,
      groupImpressions,
      activeCategory,
      searchText,
      orderBy,
      order,
    } = this.state;
    const { selGroupSlug, match } = this.props;
    const { groupSlug } = match.params;
    const kTrans = this.props.t ? this.props.t : "";
    var domainChartYAxisTitle = this.props.t("Count");
    if (
      domainChartType === "avgSessionDuration" ||
      domainChartType === "avgTimeOnPage"
    ) {
      domainChartYAxisTitle = "Minutes";
    }

    const isImpressionsSelected = activeCategory === "impressions";
    const selectedTabData =
      debateStatData && debateStatData[activeCategory]
        ? debateStatData[activeCategory].days
        : {};
    const formatter = Intl.NumberFormat("en-US");

    const networkBreakdown =
      debateStatData && debateStatData.networks
        ? debateStatData.networks.filter((network) => network)
        : [];
    networkBreakdown.forEach((n, index) => {
      networkBreakdown[index].impressions =
        typeof groupImpressions[n.id] !== "undefined" && groupImpressions[n.id]
          ? parseInt(groupImpressions[n.id])
          : 0;
    });

    const domainChartAllPages = {
      options: {
        responsive: true,
        plugins: {
          legend: {
            display: false,
            align: "start",
            position: "bottom",
          },
        },
        scales: {
          x: {
            display: true,
            scaleLabel: {
              display: false,
            },
          },
          y: {
            display: true,
            title: {
              display: true,
              text: domainChartYAxisTitle,
            },
          },
        },
      },
      data: {
        labels: isImpressionsSelected
          ? pagesAllLabels
          : Object.keys(selectedTabData || {}),
        datasets: [
          {
            label: "All Pages",
            data: isImpressionsSelected
              ? pagesAllData
              : Object.values(selectedTabData || {}),
            fill: false,
            borderColor: "rgb(47 128 237)",
            tension: 0.1,
          },
        ],
      },
    };

    networkBreakdown.sort((a, b) => {
      if (orderBy === "name") {
        if (order === 1) {
          return a.header.title < b.header.title ? -1 : 1;
        } else if (order === -1) {
          return a.header.title < b.header.title ? 1 : -1;
        }
      } else if (
        ["impressions", "votes", "engagements", "audience"].indexOf(orderBy) !==
        -1
      ) {
        if (order === 1) {
          return parseInt(a[orderBy]) < parseInt(b[orderBy]) ? -1 : 1;
        } else if (order === -1) {
          return parseInt(a[orderBy]) < parseInt(b[orderBy]) ? 1 : -1;
        }
      }

      return 0;
    });

    return (
      <section>
        <div className="mb-3 mb-md-5 inline-content top title-section">
          <h2>{kTrans("Poll Analytics")}</h2>
          <div className="d-flex">
            <div className="datepickerSec">
              <i className="fa fa-calendar"></i>
              <DateRangePicker
                onCallback={this.datePickerCallback}
                initialSettings={{
                  startDate: moment().subtract(14, "days"),
                  endDate: moment().subtract(1, "days"),
                  ranges: {
                    [this.props.t("Yesterday")]: [
                      moment().subtract(1, "days"),
                      moment().subtract(1, "days"),
                    ],
                    [this.props.t("Last 2 weeks")]: [
                      moment().subtract(14, "days"),
                      moment().subtract(1, "days"),
                    ],
                    [this.props.t("Last 30 Days")]: [
                      moment().subtract(30, "days"),
                      moment().subtract(1, "days"),
                    ],
                    [this.props.t("This Month")]: [
                      moment().startOf("month"),
                      moment().subtract(1, "days"),
                    ],
                    [this.props.t("Last Month")]: [
                      moment().subtract(1, "month").startOf("month"),
                      moment().subtract(1, "month").endOf("month"),
                    ],
                    [this.props.t("Last 90 Days")]: [
                      moment().subtract(90, "days"),
                      moment().subtract(1, "days"),
                    ],
                    [this.props.t("This year")]: [
                      moment().startOf("year"),
                      moment().subtract(1, "days"),
                    ],
                    [this.props.t("Last year")]: [
                      moment().subtract(1, "year").startOf("year"),
                      moment().subtract(1, "year").endOf("year"),
                    ],
                  },
                }}
              >
                <input type="text" className="datepickerField" />
              </DateRangePicker>
            </div>
          </div>
        </div>

        {debateStatData ? (
          <Row className="mb-3 mb-md-5 analytic-stats">
            <Col lg={3}>
              <div
                className={
                  "card widget-flat main-category " +
                  (activeCategory === "impressions" ? "active" : "")
                }
                onClick={() => this.setState({ activeCategory: "impressions" })}
              >
                <div className="card-body">
                  <div className="d-flex align-items-center justify-content-between mb-2">
                    <h1>
                      {numFormatter(
                        pagesAllData.reduce(
                          (partialSum, a) => partialSum + a,
                          0,
                        ),
                      )}
                    </h1>
                  </div>
                  <div className="d-flex align-items-center justify-content-between">
                    <strong>{kTrans("Poll Impressions")}</strong>
                  </div>
                </div>
              </div>
            </Col>
            <Col lg={3}>
              <div
                className={
                  "card widget-flat main-category " +
                  (activeCategory === "votes" ? "active" : "")
                }
                onClick={() => this.setState({ activeCategory: "votes" })}
              >
                <div className="card-body">
                  <div className="d-flex align-items-center justify-content-between mb-2">
                    <h1>
                      {numFormatter(
                        debateStatData.votes && debateStatData.votes.count
                          ? debateStatData.votes.count
                          : 0,
                      )}
                    </h1>
                  </div>
                  <div className="d-flex align-items-center justify-content-between">
                    <strong>{kTrans("Votes")}</strong>
                  </div>
                </div>
              </div>
            </Col>
            <Col lg={3}>
              <div
                className={
                  "card widget-flat main-category " +
                  (activeCategory === "engagements" ? "active" : "")
                }
                onClick={() => this.setState({ activeCategory: "engagements" })}
              >
                <div className="card-body">
                  <div className="d-flex align-items-center justify-content-between mb-2">
                    <h1>
                      {numFormatter(
                        debateStatData.engagements &&
                          debateStatData.engagements.count
                          ? debateStatData.engagements.count
                          : 0,
                      )}
                    </h1>
                  </div>
                  <div className="d-flex align-items-center justify-content-between">
                    <strong>{kTrans("Engagements")}</strong>
                  </div>
                </div>
              </div>
            </Col>
            <Col lg={3}>
              <div
                className={
                  "card widget-flat main-category " +
                  (activeCategory === "audience" ? "active" : "")
                }
                onClick={() => this.setState({ activeCategory: "audience" })}
              >
                <div className="card-body">
                  <div className="d-flex align-items-center justify-content-between mb-2">
                    <h1>
                      {numFormatter(
                        debateStatData.audience && debateStatData.audience.count
                          ? debateStatData.audience.count
                          : 0,
                      )}
                    </h1>
                  </div>
                  <div className="d-flex align-items-center justify-content-between">
                    <strong>{kTrans("Audience")}</strong>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        ) : null}

        <div className="mb-2 mb-md-5">
          {domainStatData ? (
            <Row>
              <Col lg={3}>
                <div className="card widget-flat">
                  <div className="card-body">
                    <h5 className="analyTitle1"> PAGEVIEWS</h5>
                    <h3 className="analyNum1">
                      {numFormatter(domainStatData.pageViews.current)}
                    </h3>
                    <p className="analyNumInf"> </p>
                    <p className="analyTabInf">
                      <span className="analyTabInfPrev">
                        Previous {numFormatter(domainStatData.pageViews.last)}
                      </span>
                      <span className="analyTabInfPer float-end">
                        {domainStatData.pageViews.stat}%{" "}
                        <i
                          className={
                            "fa " +
                            (domainStatData.pageViews.stat >= 0
                              ? "fa-caret-up"
                              : "fa-caret-down")
                          }
                          aria-hidden="true"
                        ></i>
                      </span>
                    </p>
                  </div>
                </div>
              </Col>
              <Col lg={3}>
                <div className="card widget-flat">
                  <div className="card-body">
                    <h5 className="analyTitle1">Sessions</h5>
                    <h3 className="analyNum1">
                      {numFormatter(domainStatData.sessions.current)}
                    </h3>
                    <p className="analyNumInf"> </p>
                    <p className="analyTabInf">
                      <span className="analyTabInfPrev">
                        Previous {numFormatter(domainStatData.sessions.last)}
                      </span>
                      <span className="analyTabInfPer float-end">
                        {domainStatData.sessions.stat}%{" "}
                        <i
                          className={
                            "fa " +
                            (domainStatData.sessions.stat >= 0
                              ? "fa-caret-up"
                              : "fa-caret-down")
                          }
                          aria-hidden="true"
                        ></i>
                      </span>
                    </p>
                  </div>
                </div>
              </Col>
              <Col lg={3}>
                <div className="card widget-flat">
                  <div className="card-body">
                    <h5 className="analyTitle1"> AVG SESSION DURATION</h5>
                    <h3 className="analyNum1">
                      {moment
                        .duration(
                          domainStatData.avgSessionDuration.current,
                          "second",
                        )
                        .format("mm:ss")}
                    </h3>
                    <p className="analyNumInf">min</p>
                    <p className="analyTabInf">
                      <span className="analyTabInfPrev">
                        Previous{" "}
                        {moment
                          .duration(
                            domainStatData.avgSessionDuration.last,
                            "second",
                          )
                          .format("mm:ss")}
                      </span>
                      <span className="analyTabInfPer float-end">
                        {domainStatData.avgSessionDuration.stat}%{" "}
                        <i
                          className={
                            "fa " +
                            (domainStatData.avgSessionDuration.stat >= 0
                              ? "fa-caret-up"
                              : "fa-caret-down")
                          }
                          aria-hidden="true"
                        ></i>
                      </span>
                    </p>
                  </div>
                </div>
              </Col>
              <Col lg={3}>
                <div className="card widget-flat">
                  <div className="card-body">
                    <h5 className="analyTitle1"> AVG TIME ON PAGE</h5>
                    <h3 className="analyNum1">
                      {moment
                        .duration(
                          domainStatData.avgTimeOnPage.current,
                          "second",
                        )
                        .format("mm:ss")}
                    </h3>
                    <p className="analyNumInf">min</p>
                    <p className="analyTabInf">
                      <span className="analyTabInfPrev">
                        Previous{" "}
                        {moment
                          .duration(domainStatData.avgTimeOnPage.last, "second")
                          .format("mm:ss")}
                      </span>
                      <span className="analyTabInfPer float-end">
                        {domainStatData.avgTimeOnPage.stat}%{" "}
                        <i
                          className={
                            "fa " +
                            (domainStatData.avgTimeOnPage.stat >= 0
                              ? "fa-caret-up"
                              : "fa-caret-down")
                          }
                          aria-hidden="true"
                        ></i>
                      </span>
                    </p>
                  </div>
                </div>
              </Col>
            </Row>
          ) : null}
        </div>

        <div className="mb-3 mb-md-5">
          {pagesAllLabels && pagesAllData ? (
            <Chart
              type="line"
              data={domainChartAllPages.data}
              options={domainChartAllPages.options}
            />
          ) : null}
        </div>

        {groupSlug ? (
          <Row className="pt-4">
            <h3 className="pb-2 pb-md-4">{kTrans("NETWORK BREAKDOWN")}</h3>
            <div className="mb-3 mb-md-5 inline-content mobile-block">
              <form
                id="search"
                autoComplete="off"
                className="navbar-form navbar-left w-25"
                method="get"
                onSubmit={this.handleSearchSubmit}
              >
                <input
                  onChange={({ target }) =>
                    this.setState({ searchText: target.value })
                  }
                  type="text"
                  className="form-control"
                  value={searchText}
                  name="searchText"
                  placeholder={kTrans("Search for a network")}
                />
                <button type="submit">
                  <i className="fa fa-search"></i>
                </button>
              </form>
              <NavLink
                className="btn btn-outline-primary"
                activeClassName="active"
                to={`/groups/${selGroupSlug}/networks`}
              >
                {kTrans("Manage Networks")}
              </NavLink>
            </div>
            <Col className="table-wrapper">
              <Table className="networksAdBreakdown tableCommon">
                <thead>
                  <tr>
                    <th
                      className={`w-50 ${orderBy === "name" && order === -1 ? "ASC" : ""}`}
                    >
                      <span
                        role="button"
                        onClick={() =>
                          this.setState({
                            orderBy: "name",
                            order: orderBy === "name" ? order * -1 : 1,
                          })
                        }
                      >
                        {kTrans("Network Name")}
                        {orderBy === "name" ? <SortIcon /> : null}
                      </span>
                    </th>
                    <th
                      className={
                        orderBy === "impressions" && order === -1 ? "ASC" : ""
                      }
                    >
                      <span
                        role="button"
                        onClick={() =>
                          this.setState({
                            orderBy: "impressions",
                            order: orderBy === "impressions" ? order * -1 : 1,
                          })
                        }
                      >
                        {kTrans("Poll Impressions")}
                        {orderBy === "impressions" ? <SortIcon /> : null}
                      </span>
                    </th>
                    <th
                      className={
                        orderBy === "votes" && order === -1 ? "ASC" : ""
                      }
                    >
                      <span
                        role="button"
                        onClick={() =>
                          this.setState({
                            orderBy: "votes",
                            order: orderBy === "votes" ? order * -1 : 1,
                          })
                        }
                      >
                        {kTrans("Votes")}
                        {orderBy === "votes" ? <SortIcon /> : null}
                      </span>
                    </th>
                    <th
                      className={
                        orderBy === "engagements" && order === -1 ? "ASC" : ""
                      }
                    >
                      <span
                        role="button"
                        onClick={() =>
                          this.setState({
                            orderBy: "engagements",
                            order: orderBy === "engagements" ? order * -1 : 1,
                          })
                        }
                      >
                        {kTrans("Engagements")}
                        {orderBy === "engagements" ? <SortIcon /> : null}
                      </span>
                    </th>
                    <th
                      className={
                        orderBy === "audience" && order === -1 ? "ASC" : ""
                      }
                    >
                      <span
                        role="button"
                        onClick={() =>
                          this.setState({
                            orderBy: "audience",
                            order: orderBy === "audience" ? order * -1 : 1,
                          })
                        }
                      >
                        {kTrans("Audience")}
                        {orderBy === "audience" ? <SortIcon /> : null}
                      </span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {networkBreakdown
                    .filter((network) => network.impressions)
                    .filter(
                      (network) =>
                        !searchText ||
                        network.header.title
                          .toLowerCase()
                          .indexOf(searchText.toLowerCase()) !== -1,
                    )
                    .map((network) => (
                      <tr key={network.id}>
                        <td>
                          <strong>{network.header.title}</strong>
                          <span className="lightColorSpan">
                            {this.getNetworkDomain(network)}
                          </span>
                        </td>
                        <td>{formatter.format(network.impressions)}</td>
                        <td>{formatter.format(network.votes)}</td>
                        <td>{formatter.format(network.engagements)}</td>
                        <td>{formatter.format(network.audience)}</td>
                      </tr>
                    ))}
                </tbody>
              </Table>
            </Col>
          </Row>
        ) : null}
      </section>
    );
  }
}

const mapStateToProps = (state) => ({
  networks: state.networks.networksList,
  selNetworkId: state.networks.selNetworkId,
  embedDomains: state.embedDomains.embedDomains,
  selDomainId: state.embedDomains.selDomainId,
  embedDomainsLoaded: state.embedDomains.isLoaded,
  selGroupSlug: state.networks.selGroupSlug,
});

const Extended = withTranslation()(Analytics);
Extended.static = Analytics.static;

export default connect(mapStateToProps, {
  loadEmbedDomains,
  saveSelectedDomain,
})(Extended);
