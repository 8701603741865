import React from "react";
import {
  Row,
  Col,
  Button,
  Form,
  Tabs,
  Tab,
  Modal,
  DropdownButton,
  Dropdown,
} from "react-bootstrap";
import axios from "axios";
import {
  API_URL,
  DEFAULT_USER_IMG_URL,
  BASENAME,
  MAIN_WEBSITE_URL,
} from "../../constants/Globals";
import { NavLink } from "react-router-dom";
import DateRangePicker from "react-bootstrap-daterangepicker";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faLink,
  faTag,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import logo from "../../logo.png";
import SyndicationSelector from "../SyndicationSelector";
import DeployDebate from "./DeployDebate";
import { withTranslation } from "react-i18next";

import { ClientJS } from "clientjs";
const client = new ClientJS();
const fingerprint = client.getFingerprint();

var moment = require("moment-timezone");
var momentDurationFormatSetup = require("moment-duration-format");
momentDurationFormatSetup(moment);

var defaultStartDate = null;
var defaultEndDate = null;

export class EditDebate extends React.Component {
  constructor(props) {
    super(props);

    const search = this.props.location.search;
    const action = new URLSearchParams(search).get("action");

    this.state = {
      removeSideLink: false,
      resetEndDate: false,
      networksList: null,
      categoriesList: null,
      allCategories: [],
      authorsList: null,
      defaultDebateDuration: null,
      maxDebateDuration: null,
      networkDomain: null,
      embedCode: null,
      validationErrors: {},
      loading: false,
      syndication: [],
      formData: {
        id: this.props.match.params.id,
        clientId: "",
        userId: "",
        thesis: "",
        sides: "",
        categoryId: "",
        oldCategoryId: "",
        background: "",
        backgroundImage: "",
        backgroundTempData: "",
        startedAt: defaultStartDate,
        endedAt: defaultEndDate,
        type: "create",
        affiliateLink: "",
        affiliateButtonText: "",
        noEndDate: 0,
        isSyndicated: false,
        backgroundTemp: "",
        requireEmail: 0,
        sourcePageUrl: ""
      },
      currentDebateData: "",
      //pageTypeFilter: 'DEBATE_NOT_DEPLOYED'
      pageTypeFilter: action === "active-pages" ? "DEBATE_DEPLOYED" : "ALL",
      showAdditionaInfoModal: false,
      toggleSideLinkField: {},
      toggleSideTagField: {},
      linkTempVar: null,
      tagTempVar: null,
      disableSubmitBtn: true,
      isQuiz: false,
      categoryFilter: "",
    };
  }

  componentDidMount() {
    this.networksList();
    this.fetchCurreDebate();

    axios
      .get(`${API_URL}/categories?getAll=true`)
      .then(({ data }) =>
        this.setState({
          allCategories: data.data.sort((a, b) => (a.text > b.text ? 1 : -1)),
        }),
      );
  }

  componentDidUpdate(prevProps, prevState) {
    setTimeout(() => {
      if (
        parseInt(prevProps.selNetworkId) !==
          parseInt(this.props.selNetworkId) &&
        this.state.formData.clientId !== "" &&
        parseInt(this.state.formData.clientId) !==
          parseInt(this.props.selNetworkId)
      ) {
        window.location.href = BASENAME + "/";
      }
    }, 500);
    /*if(prevState.formData.thesis !== this.state.formData.thesis && this.state.formData.thesis !== ''){
			const search = this.props.location.search;
	  		const action = new URLSearchParams(search).get('action');
	  		if(action && document.getElementById("publish-debate-section")){
				document.getElementById("publish-debate-section").scrollIntoView();
	  		}
		}*/
  }

  fetchCurreDebate = async () => {
    await this.setState({
      loading: true,
    });

    await axios
      .get(
        API_URL +
          "/debate/" +
          this.state.formData.id +
          "?deviceId=" +
          fingerprint +
          "&emailEmbedDebateLink=true",
      )
      .then((res) => {
        var debateData = res.data.data;
        this.setState({ currentDebateData: debateData });
        var formData = { ...this.state.formData };

        //formData['id'] = debateData.id !== undefined ? debateData.id : '';
        formData["clientId"] =
          debateData.clientId !== undefined ? debateData.clientId : "";
        //formData['userId'] = debateData.userId !== undefined ? debateData.userId : '';
        formData["thesis"] =
          debateData.thesis !== undefined ? debateData.thesis : "";
        formData["userId"] =
          debateData.userId !== undefined ? debateData.userId : "";
        formData["sides"] =
          debateData.sides !== undefined ? debateData.sides : "";
        formData["oldCategoryId"] =
          debateData.categories !== undefined &&
          debateData.categories.length > 0
            ? debateData.categories[0]["categoryDebate"]["categoryId"]
            : "";
        formData["categoryId"] =
          debateData.categories !== undefined &&
          debateData.categories.length > 0
            ? debateData.categories[0]["categoryDebate"]["categoryId"]
            : "";

        //this.setState({'categoriesList' : debateData.categories});

        formData["background"] =
          debateData.background !== undefined ? debateData.background : "";
        //formData['backgroundTemp'] = debateData.background !== undefined ? debateData.background : '';
        formData["backgroundImage"] = debateData.backgroundImage
          ? debateData.backgroundImage
          : "";
        this.setState({
          backgroundImageDisplay:
            debateData.backgroundImageObject !== null
              ? debateData.backgroundImageObject["small"]["location"]
              : "",
        });

        formData["affiliateButtonText"] =
          debateData.affiliateButtonText !== undefined
            ? debateData.affiliateButtonText
            : "";
        formData["affiliateLink"] =
          debateData.affiliateLink !== undefined
            ? debateData.affiliateLink
            : "";

        formData["startedAt"] =
          debateData.startedAt !== undefined ? debateData.startedAt : "";
        formData["endedAt"] =
          debateData.endedAt !== undefined ? debateData.endedAt : "";
        formData["noEndDate"] = debateData.endedAt === null ? 1 : 0;
        formData["isSyndicated"] = !!debateData.isSyndicated;
        formData["requireEmail"] = parseInt(debateData.debateRequireEmail);

        if (debateData.clientArticleSource && debateData.clientArticleSource.url) {
          formData["sourcePageUrl"] = debateData.clientArticleSource.url
        }

        this.setState({
          formData,
          syndication: debateData.syndication,
          isQuiz: debateData.isQuiz,
        });

        this.copyEmbedCode();

        this.setState({
          loading: false,
        });
      })
      .catch((err) => {
        alert("Failed. Please try again!");

        console.log(err);
      });

    setTimeout(() => {
      if (this.selectedInputElement !== undefined) {
        this.selectedInputElement.click();
      }
    }, 500);
  };

  networksList = async (e) => {
    await axios
      .get(
        API_URL +
          "/admin/client/getAllClients?perPage=9999999&pageNumber=1&order=asc&orderBy=title",
      )
      .then((res) => {
        this.setState({
          networksList: res.data.data,
        });
        this.setState({ loading: false });
        //console.log(this.state.networksList);
      })
      .catch((err) => {
        console.log(err.response.data.message);
      });
  };

  fetchCats = async (
    e,
    defaultDebateDuration = "",
    maxDebateDuration = "",
    networkDomain = "",
  ) => {
    this.fetchAuthors(e);

    var formData = { ...this.state.formData };
    formData[e.target.name] = e.target.value;

    await this.setState({
      defaultDebateDuration: defaultDebateDuration,
      maxDebateDuration: maxDebateDuration,
      networkDomain: networkDomain,
    });

    await this.setState({ resetEndDate: true });
    await this.setState({ resetEndDate: false });
    await this.setState({ formData });

    await axios
      .get(API_URL + "/categories?type=START_DEBATE&clientId=" + e.target.value)
      .then((res) => {
        this.setState({
          categoriesList: res.data.data.sort((a, b) =>
            a.text > b.text ? 1 : -1,
          ),
        });
      })
      .catch((err) => {
        console.log(err.response.data.message);
      });
  };

  fetchAuthors = async (e) => {
    await axios
      .get(
        API_URL +
          "/user/list?type=DEBATE_AUTHORS&clientId=" +
          parseInt(e.target.value),
      )
      .then((res) => {
        this.setState({
          authorsList: res.data.data,
        });
      })
      .catch((err) => {
        console.log(err.response.data.message);
      });
  };

  formValidation = async (e = "", action = "") => {
    this.setState((previousState, currentProps) => {
      if (this.state !== currentProps) {
        return { disableSubmitBtn: false };
      } else {
        return { disableSubmitBtn: true };
      }
    });
    var validationErrors = { ...this.state.validationErrors };
    if (e.target.name === "clientId" || action === "onSubmit") {
      if (this.state.formData.clientId === "") {
        validationErrors["clientId"] = this.props.t("This field is required.");
        await this.setState({ validationErrors: validationErrors });
      } else {
        delete validationErrors["clientId"];
        await this.setState({ validationErrors: validationErrors });
      }
    }
    if (e.target.name === "thesis" || action === "onSubmit") {
      if (this.state.formData.thesis === "") {
        validationErrors["thesis"] = this.props.t(
          "The Statement or Question field is required.",
        );
        await this.setState({ validationErrors: validationErrors });
      } else if (this.state.formData.thesis.length > 140) {
        validationErrors["thesis"] = this.props.t(
          "Please do not enter more than 140 characters.",
        );
        await this.setState({ validationErrors: validationErrors });
      } else {
        delete validationErrors["thesis"];
        await this.setState({ validationErrors: validationErrors });
      }
    }

    // if(e.target.name === 'affiliateLink' || action === 'onSubmit'){

    // 	if((this.state.formData.affiliateLink !== null && this.state.formData.affiliateLink !== "") && !(/^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-zA-Z0-9]+([\-\.]{1}[a-zA-Z0-9]+)*\.[a-zA-Z]{2,5}(:[0-9]{1,5})?(\/.*)?$/i.test(this.state.formData.affiliateLink))){
    //  		validationErrors['affiliateLink'] = this.props.t('The affiliate link is invalid.');
    // 		await this.setState({validationErrors : validationErrors})
    //  	} else {
    //  		delete validationErrors['affiliateLink'];
    // 		await this.setState({validationErrors : validationErrors})
    //  	}
    // }

    if (
      Object.keys(this.state.validationErrors).length === 0 &&
      action === "onSubmit"
    ) {
      this.uploadDebateImages();
    } else {
      this.setState({
        loading: false,
      });
    }
  };

  changeHandler = async (e) => {
    var formData = { ...this.state.formData };
    formData[e.target.name] = e.target.value;
    await this.setState({ formData });
    this.formValidation(e);
  };

  datePickerCallback = async (e, name) => {
    var formData = { ...this.state.formData };
    formData[name] = e._d;
    await this.setState({ formData });
    if (
      moment(this.state.formData.startedAt).isAfter(this.state.formData.endedAt)
    ) {
      await this.setState({ resetEndDate: true });
      await this.setState({ resetEndDate: false });
    } else {
      await this.setState({ resetEndDate: false });
    }
    this.formValidation(e);
  };

  fileChangeHandler = async (e) => {
    if (e.target.type === "file" && e.target.files[0]) {
      // const dataAccept = e.target.getAttribute('data-accept') ? e.target.getAttribute('data-accept') : 'jpg|jpeg|gif|png';
      // const dataAcceptMsg = e.target.getAttribute('data-accept_msg') ? e.target.getAttribute('data-accept_msg') : this.props.t('Image is invalid. Please upload only PNG, JPG or GIF image.');
      // var regex = new RegExp("(.*?)\.("+dataAccept+")$");
      // 	if(!regex.test(e.target.files[0].type)){
      // 		alert(dataAcceptMsg);
      // 		return false;
      // 	}

      if (
        e.target.name === "backgroundImage" &&
        e.target.files[0].size > 1024 * 10 * 1024
      ) {
        var alertMessage = this.props.t(
          "The image size should not be greater than 10mb",
        );
        alert(alertMessage);
        return false;
      }

      var file = e.target.files[0];
      var thisEl = e;
      var reader = new FileReader();
      reader.onloadend = function (e) {
        var formData = { ...this.state.formData };
        var bgImageObj = {
          type: thisEl.target.dataset.imgtype,
          backgroundImage: thisEl.target.files[0],
        };

        formData.backgroundTempData = bgImageObj;
        formData[thisEl.target.name] = e.target.result;
        this.setState({ backgroundImageDisplay: e.target.result });
        this.setState({ formData });
      }.bind(this);
      reader.readAsDataURL(file);
    }
    this.formValidation();
  };

  fileRemoveHandler = async (name, index) => {
    var formData = { ...this.state.formData };

    formData.backgroundTempData = "";
    formData[name] = "";
    this.setState({ backgroundImageDisplay: "" });
    await this.setState({ formData });
    this.formValidation();
  };

  uploadDebateImages = async (e) => {
    let backgroundTempData = this.state.formData.backgroundTempData;
    var failure = false;

    if (backgroundTempData !== "") {
      var image_type = "DEBATES";
      const formData = new FormData();
      formData.append(
        "backgroundImage",
        backgroundTempData.backgroundImage,
        backgroundTempData.backgroundImage.name,
      );
      await axios
        .post(
          API_URL +
            "/debate/imageUpload?type=" +
            image_type +
            "&clientId=" +
            this.state.formData.clientId,
          formData,
        )
        .then((res) => {
          var formData = { ...this.state.formData };
          formData.backgroundImage = res.data.data;
          this.setState({ formData });
        })
        .catch((err) => {
          alert("Image upload for " + image_type + " Failed!");
          failure = true;
        });
    }

    if (failure === false) {
      this.formDataHandle();
    }
  };

  formDataHandle = async (e) => {
    const { syndication, isQuiz, formData } = this.state;
    const { selNetworkId } = this.props

    const formDataBody = {
      ...this.state.formData,
    };

    formDataBody["endedAt"] =
      formDataBody["endedAt"] == null
        ? moment().add(5, "minutes")._d
        : formDataBody["endedAt"];
    formDataBody["syndication"] = syndication;

    await axios
      .put(API_URL + "/debate/update/" + this.state.formData.id, formDataBody)
      .then(async (res) => {
        if (formData.sourcePageUrl) {
          await axios.put(
            `${API_URL}/admin/debate/publishAndDeploy/${formData.id}?clientId=${selNetworkId}`,
          );
        }

        this.networksList();
        this.fetchCurreDebate();
        this.messageNotification(
          "success",
          this.props.t(`${isQuiz ? "Quiz" : "Poll"} updated successfully.`),
        );
        this.setState({ loading: false });
      })
      .catch((err) => {
        if (err.response !== undefined) {
          this.messageNotification(
            "success",
            (Array.isArray(err.response.data.message) ? err.response.data.message[0].error : err.response.data.message).replace("Embed domain page not found.", "Poll updated, however, it couldn't be deployed to the Page Source because that page doesn't exist in the system.")
          );
        }
        this.setState({ loading: false });
      });
  };

  publishDebate = (clientId, categoryId, userId = 0) => {
    return new Promise((resolve, reject) => {
      const { formData, syndication, isQuiz } = this.state;

      axios
        .post(`${API_URL}/debate/create`, {
          ...formData,
          syndication,
          userId,
          clientId,
          categoryId,
          relatedDebateId: formData.id,
          endedAt:
            formData.endedAt === null
              ? moment().add(5, "minutes")._d
              : formData.endedAt,
        })
        .then(() => {
          this.messageNotification(
            "success",
            this.props.t(`${isQuiz ? "Quiz" : "Poll"} published successfully`),
          );
          resolve(true);
        })
        .catch((err) => {
          if (err.response !== undefined) {
            alert(err.response.data.message[0].error);
          }
          resolve(false);
        });
    });
  };

  submitDetateDataHandle = async (e) => {
    await this.setState({
      loading: true,
    });
    this.formValidation(e, "onSubmit");
    e.preventDefault();
  };

  messageNotification = async (type, message) => {
    this.setState({
      successMsg: `${type}||${message}`,
    });

    setTimeout(() => {
      this.setState({
        successMsg: "",
      });
    }, 5000);
  };

  copyEmbedCode = async () => {
    await axios
      .get(API_URL + "/debate/getEmbed/" + this.props.match.params.id)
      .then((res) => {
        this.setState({ embedCode: res.data.data.html });
      })
      .catch((err) => {
        console.log(err.response.data.message);
      });
  };

  copyToClipboard = async (e) => {
    if (this.textArea.value !== "") {
      this.textArea.select();
      await document.execCommand("copy");
      e.target.focus();
      this.messageNotification(
        "success",
        this.props.t("Embed code copied to clipboard!"),
      );
    } else {
      this.messageNotification(
        "fail",
        this.props.t(
          "There is some problem in copying embed code. Please reload the page and try again!",
        ),
      );
    }
  };

  copyEmailEmbedToClipboard = async (e) => {
    if (this.textAreaEmailEmbed.value !== "") {
      this.textAreaEmailEmbed.select();
      await document.execCommand("copy");
      e.target.focus();
      this.messageNotification(
        "success",
        this.props.t("Email Embed code copied to clipboard!"),
      );
    } else {
      this.messageNotification(
        "fail",
        this.props.t(
          "There is some problem in copying email embed code. Please reload the page and try again!",
        ),
      );
    }
  };

  handleKeyDown = async (e) => {
    e.target.style.height = "inherit";
    e.target.style.height = `${e.target.scrollHeight}px`;
  };

  showAdditionaInfoModal = async (e) => {
    var formData = { ...this.state.formData };
    formData["backgroundTemp"] = formData["background"];
    await this.setState({ formData });
    this.setState({ showAdditionaInfoModal: true });
  };
  hideAdditionaInfoModal = async (e) => {
    this.setState({ showAdditionaInfoModal: false });
    var formData = { ...this.state.formData };
    formData["backgroundTemp"] = "";
    await this.setState({ formData });
  };
  commitAdditionalInfo = async () => {
    this.setState({ showAdditionaInfoModal: false });
    var formData = { ...this.state.formData };
    formData["background"] = formData["backgroundTemp"];
    formData["backgroundTemp"] = "";
    await this.setState({ formData });
  };
  removeBackgroundText = async () => {
    var formData = { ...this.state.formData };
    formData["background"] = "";
    await this.setState({ formData });
    this.formValidation();
  };
  removeCustomCTA = async () => {
    var formData = { ...this.state.formData };
    formData["affiliateLink"] = "";
    formData["affiliateButtonText"] = "";
    await this.setState({ formData, CTAClicked: false });
    this.formValidation();
  };
  toggleSideElField = async (e = "", target = "", sideIndex = "") => {
    await this.setState({ linkTempVar: null, tagTempVar: null });

    var toggleSideLinkField = { ...this.state.toggleSideLinkField };
    var toggleSideTagField = { ...this.state.toggleSideTagField };
    toggleSideLinkField = {
      [sideIndex]:
        target === "link" &&
        (toggleSideLinkField[sideIndex] === undefined ||
          toggleSideLinkField[sideIndex] === false)
          ? true
          : false,
    };
    toggleSideTagField = {
      [sideIndex]:
        target === "tag" &&
        (toggleSideTagField[sideIndex] === undefined ||
          toggleSideTagField[sideIndex] === false)
          ? true
          : false,
    };
    await this.setState({ toggleSideLinkField, toggleSideTagField });
    console.log(this.state);
  };

  handleSideElFieldChange = async (e, target) => {
    if (target === "link") {
      await this.setState({ linkTempVar: e.target.value });
    } else if (target === "tag") {
      await this.setState({ tagTempVar: e.target.value });
    } else {
    }
    console.log(this.state);
  };

  sidesLinkCommitHandler = async (e) => {
    var sideIndex = e.target.dataset.index;

    // if(this.state.linkTempVar.trim() !== "" && !(/^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-zA-Z0-9]+([\-\.]{1}[a-zA-Z0-9]+)*\.[a-zA-Z]{2,5}(:[0-9]{1,5})?(\/.*)?$/i.test(this.state.linkTempVar.trim()))){
    // 	var alertMessage = this.props.t('Please enter valid URL.')
    // 	alert(alertMessage);
    // 	return false
    // }

    var formData = { ...this.state.formData };
    var sidesArray = formData.sides;
    sidesArray[sideIndex - 1] = {
      ...sidesArray[sideIndex - 1],
      link: this.state.linkTempVar.trim(),
    };
    formData.sides = sidesArray;
    await this.setState({ formData });
    this.toggleSideElField(e, "link");
    this.formValidation(e);
  };

  sidesTagCommitHandler = async (e) => {
    var sideIndex = e.target.dataset.index;

    // if(this.state.tagTempVar && this.state.tagTempVar.trim() === null || this.state.tagTempVar.trim() === '' ){
    // 	alert('This field is required.')
    // 	return false
    // }

    var formData = { ...this.state.formData };
    var sidesArray = formData.sides;
    if (sidesArray[sideIndex - 1].tags === undefined) {
      sidesArray[sideIndex - 1] = {
        ...sidesArray[sideIndex - 1],
        tags: [],
      };
    }

    var tagsObj = {
      text: this.state.tagTempVar.trim(),
    };
    sidesArray[sideIndex - 1].tags.push(tagsObj);

    formData.sides = sidesArray;
    await this.setState({ formData, tagTempVar: null });
    this.formValidation(e);
  };

  removeSideElField = async (
    e = "",
    target = "",
    sideIndex = "",
    tagIndex = "",
  ) => {
    var formData = { ...this.state.formData };
    console.log(sideIndex);
    console.log(formData);
    if (target === "link") {
      delete formData.sides[sideIndex].link;
      await await this.setState({ formData });
    }
    if (target === "tag") {
      delete formData.sides[sideIndex].tags.splice(tagIndex, 1);
      await await this.setState({ formData });
    }
    this.formValidation(e);
  };

  triggerImageInputClick = async (e) => {
    if (this.hiddenImageInput !== undefined) {
      this.hiddenImageInput.click();
    }
  };

  handleCustomDropdownChange = async (e, authorId) => {
    e.preventDefault();
    var formData = { ...this.state.formData };
    formData[e.target.name] = authorId;
    await this.setState({ formData });
    this.formValidation(e);
  };

  handleSelect = async (key) => {
    if (window.history.pushState) {
      var newurl =
        window.location.protocol +
        "//" +
        window.location.host +
        window.location.pathname +
        "?action=" +
        key;
      window.history.pushState({ path: newurl }, "", newurl);
    }
  };

  unpublishDebate = async (e) => {
    const { isQuiz } = this.state;

    await axios
      .put(
        API_URL +
          "/debate/update/" +
          this.state.formData.id +
          "/draft?clientId=" +
          this.state.formData.clientId,
      )
      .then((res) => {
        this.messageNotification(
          "success",
          this.props.t(`${isQuiz ? "Quiz" : "Poll"} unpublished successfully!`),
        );
        window.location.replace(
          BASENAME + "/debates/draft/" + this.state.formData.id,
        );
      })
      .catch((err) => {
        if (err.response !== undefined) {
          console.log(err.response.data.message[0].error);
          alert(err.response.data.message[0].error);
        }
        this.setState({
          loading: false,
        });
      });
  };

  renderSyndicationSelector() {
    const { loggedInUser } = this.props;
    const { syndication, formData, allCategories, isQuiz } = this.state;
    const { isSyndicated } = formData;

    if (
      loggedInUser &&
      loggedInUser.roles.length > 0 &&
      loggedInUser.roles.find((role) => role.name === "SUPERADMIN")
    ) {
      return (
        <SyndicationSelector
          isQuiz={isQuiz}
          selection={syndication}
          isSyndicated={isSyndicated}
          setSelection={(newSelection) =>
            this.setState({ syndication: newSelection })
          }
          categoriesList={allCategories}
          setIsSyndicated={(value) => {
            formData.isSyndicated = value;
            this.setState({ formData });
          }}
          onNotify={(clientId = 0) => {
            axios.post(
              `${API_URL}/debate/notify/${formData.id}?clientId=${clientId}`,
            );
          }}
          onPublish={async (clientId = 0, categoryId = 0, userId = 0) => {
            const { syndication } = this.state;

            if (clientId && syndication.indexOf(clientId) === -1) {
              await this.setState({
                syndication: syndication.concat([clientId]),
              });
            }

            await this.publishDebate(clientId, categoryId, userId);
          }}
          onSave={() => this.formDataHandle()}
        />
      );
    }

    return null;
  }

  render() {
    const search = this.props.location.search;
    const action = new URLSearchParams(search).get("action");
    const kTrans = this.props.t ? this.props.t : "";
    const {
      currentDebateData,
      networksList,
      authorsList,
      categoriesList,
      validationErrors,
      loading,
      backgroundImageDisplay,
      embedCode,
      formData,
      toggleSideLinkField,
      toggleSideTagField,
      linkTempVar,
      tagTempVar,
      isQuiz,
      categoryFilter,
    } = this.state;
    const {
      id,
      thesis,
      userId,
      sides,
      categoryId,
      background,
      startedAt,
      endedAt,
      clientId,
      affiliateLink,
      affiliateButtonText,
      noEndDate,
      backgroundTemp,
      requireEmail,
      sourcePageUrl
    } = formData;
    var endedAtMinDate = moment(startedAt);

    if (this.state.currentDebateData.isDraft === 1) {
      return (
        <section>
          <NavLink
            exact
            className="mb-3 customAnchor d-inline-block"
            to={`/${isQuiz ? "quizzes" : ""}`}
          >
            <i className="fa fa-angle-left"></i>
            &nbsp;{kTrans(`Your ${isQuiz ? "Quizzes" : "Polls"}`)}
          </NavLink>
          <h3 className="text-center">
            {kTrans("This is not published yet.")}
          </h3>
        </section>
      );
    }

    /* Difference in startedAt and endedAt to display in preview section */
    var finalDifference = null;
    if (startedAt !== null && endedAt !== null) {
      var currDate = moment();
      var endedAtDate = moment(endedAt);
      var differenceInDates = endedAtDate.diff(currDate, "minutes");
      var numdays = Math.floor(differenceInDates / 1440);
      var numhours = Math.floor((differenceInDates % 1440) / 60);
      var numminutes = Math.floor((differenceInDates % 1440) % 60);
      finalDifference =
        numdays > 0
          ? numdays + " day(s) "
          : numhours > 0
            ? numhours + " hour(s) "
            : numminutes > 0
              ? numminutes + " minute(s)"
              : "< 1 minute";
    }

    var emailEmbedSides = "";
    var sidesLink = currentDebateData
      ? "https://" +
        currentDebateData.siteDomain +
        "/debate/" +
        currentDebateData.id +
        "/log-vote/"
      : "";
    var redirectLink = currentDebateData.embedDebateUrl
      ? "&redirectLink=" + currentDebateData.embedDebateUrl
      : "";

    if (currentDebateData.isClosed) {
      if (sides && sides.length) {
        for (let i = 0; i < sides.length; i++) {
          var voteResult = !isNaN(
            ((sides[i].votes * 100) / currentDebateData.votes).toFixed(2),
          )
            ? ((sides[i].votes * 100) / currentDebateData.votes).toFixed(2) +
              "%"
            : "";
          emailEmbedSides =
            emailEmbedSides +
            '<tr><td style="padding-bottom:9px" colspan="2"><a href="' +
            currentDebateData.debateUrl +
            '" style="position:relative;color:#000;text-decoration:none;border-radius: 3px;display:block;font-size:14px;padding:7px;text-align:left;font-weight:600;line-height:18px; background: ' +
            sides[i].sideColor +
            "10; border-left: 5px solid " +
            sides[i].sideColor +
            '"><span style="position:relative; z-index: 1;">' +
            sides[i].text +
            '<span style="float: right;">' +
            voteResult +
            '</span></span><span style="position: absolute;width: ' +
            voteResult +
            ";top: 0;bottom: 0;left: 0;background: " +
            sides[i].sideColor +
            '26;"></span></a></td></tr>';
        }
      }
    } else {
      if (sides && sides.length) {
        for (let i = 0; i < sides.length; i++) {
          emailEmbedSides =
            emailEmbedSides +
            '<tr><td style="padding-bottom:9px" colspan="2"><a href="' +
            sidesLink +
            "?sid=" +
            sides[i].id +
            redirectLink +
            '" style="color:#000;text-decoration:none;display:block;font-size:14px;border:1px solid #60a4e9;border-radius:10px;padding:7px;text-align:center;font-weight:600;line-height:18px">' +
            sides[i].text +
            "</a></td></tr>";
        }
      }
    }

    var userImage =
      currentDebateData.user && currentDebateData.user.avatarObject
        ? currentDebateData.user.avatarObject.thumb.location
        : DEFAULT_USER_IMG_URL;
    var endedOrEnds = currentDebateData.isClosed
      ? kTrans(`${isQuiz ? "Quiz" : "Poll"} ended`)
      : kTrans(`${isQuiz ? "Quiz" : "Poll"} ends`);
    var endedAtForMailEmbed = endedAt
      ? endedOrEnds + " " + moment(endedAt).format("MMM DD, YYYY")
      : "";
    var emailEmbedCode = currentDebateData
      ? "<table style=\"color:#4a4a4a;font-family:-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;font-size:16px;margin:18px;width: 94%;\"><tr><td><table><tr>    <td width=\"38px\" style=\"padding-right: 10px\"><img width=\"38px\" height=\"38px\" src=\"" +
        userImage +
        '" alt="' +
        currentDebateData.user.name +
        '" style="vertical-align:middle;border:2px solid ' +
        currentDebateData.user.statusColor +
        ';border-radius:50%;display:block;height:38px;min-width:38px;object-fit:cover;padding:0;width:38px;outline:0;text-decoration:none" class="CToWUd" /></td>    <td><a rel="noreferrer" href="' +
        MAIN_WEBSITE_URL +
        "/" +
        currentDebateData.user.username +
        '" style="color:inherit;text-decoration-line:none;display:block" target="_blank"><h6 style="line-height:19px;margin:0;font-size:14px;padding:0;font-weight:500;display:block">' +
        currentDebateData.user.name +
        '</h6></a> <a rel="noreferrer" style="color:#9b9b9b;display:block;font-size:11px;line-height:15px">' +
        kTrans("Posted") +
        " " +
        moment(startedAt).format("MMM DD, YYYY") +
        '</a></td>  </tr></table></td></tr>		<tr><td colspan="2" style="padding-top:18px"><h5 style="margin:0px;line-height:19px;font-size:16px;color:#000;font-weight:500;display:block">' +
        thesis +
        '</h5></td></tr><tr><td colspan="2" style="padding:12px 0"><span style="color:#9b9b9b;font-size:13px;line-height:16px;display:block">' +
        endedAtForMailEmbed +
        "</span></td></tr>" +
        emailEmbedSides +
        "</table>"
      : null;

    return (
      <section
        className={this.state.currentDebateData.isClosed ? "closed" : null}
      >
        {this.state.successMsg ? (
          <div className={this.state.successMsg.split('||')[0]}>
            {this.state.successMsg.replace('fail||', '').replace('success||', '')}
          </div>
        ) : null}

        {loading === false ? (
          <NavLink
            exact
            className="mb-4 customAnchor d-inline-block"
            to={`/${isQuiz ? "quizzes" : ""}`}
          >
            <i className="fa fa-angle-left"></i>
            &nbsp;{kTrans(`Your ${isQuiz ? "Quizzes" : "Polls"}`)}
          </NavLink>
        ) : (
          <>
            <NavLink exact className="mb-4 customAnchor d-inline-block" to="/">
              {" "}
              &nbsp;
            </NavLink>
            <div className="loadingBarWrap">
              <div className="loadingBar"></div>
            </div>
          </>
        )}

        <h3>{kTrans(`EDIT ${isQuiz ? "QUIZ" : "POLL"}`)}</h3>

        <Tabs
          defaultActiveKey={
            action === "embed-settings-all" || action === "active-pages"
              ? "embed-settings"
              : "poll-detail"
          }
          id="uncontrolled-tab-example"
          className="mt-4 mb-3 CreateDebateTabs pe-3 col-md-7"
          onSelect={this.handleSelect}
        >
          <Tab
            eventKey="poll-detail"
            title={kTrans(`${isQuiz ? "Quiz" : "Poll"} Details`)}
          >
            <div
              className={
                loading === true
                  ? "loading justify-content-center"
                  : "justify-content-center"
              }
            >
              <Form
                className="startDebate common-form row"
                onSubmit={this.submitDetateDataHandle}
              >
                <Col md={7} className="pe-3">
                  {/*<h2 className="pb-3">VIEW / EDIT DEBATE</h2>	
									<h3 className="pb-3">DEBATE INFO & SETTINGS</h3>*/}

                  <Form.Group
                    controlId="clientId"
                    className="pt-0 hiddenElement"
                  >
                    <Form.Label>{kTrans("Network")}</Form.Label>
                    <div className="message joinedNetworksWrap">
                      {networksList && networksList.length
                        ? networksList.map((el, i) =>
                            el.id === parseInt(clientId) ? (
                              <label key={i} className="sub-label">
                                <input
                                  ref={(input) =>
                                    (this.selectedInputElement = input)
                                  }
                                  data-required="true"
                                  onChange={(e) => {
                                    this.fetchCats(
                                      e,
                                      el.extra
                                        ? el.extra.defaultDebateDuration
                                        : null,
                                      el.extra
                                        ? el.extra.maxDebateDuration
                                        : null,
                                      el.siteDomain,
                                    );
                                  }}
                                  type="radio"
                                  name="clientId"
                                  id="clientId"
                                  value={el.id}
                                />
                                <span
                                  key={i}
                                  className=" joinedNetworks"
                                  data-clientid={`client-${el.id}`}
                                >
                                  <img
                                    src={el.header.favicon}
                                    alt={el.name}
                                    onError={(e) => {
                                      e.target.src = DEFAULT_USER_IMG_URL;
                                    }}
                                  />
                                  {el.header.title}
                                </span>
                              </label>
                            ) : null,
                          )
                        : null}

                      {networksList && networksList.length
                        ? networksList.map((el, i) =>
                            el.id !== parseInt(clientId) ? (
                              <label key={i} className="sub-label">
                                <input
                                  disabled
                                  data-required="true"
                                  onChange={(e) => {
                                    this.fetchCats(
                                      e,
                                      el.extra
                                        ? el.extra.defaultDebateDuration
                                        : null,
                                      el.extra
                                        ? el.extra.maxDebateDuration
                                        : null,
                                      el.siteDomain,
                                    );
                                  }}
                                  type="radio"
                                  name="clientId"
                                  id="clientId"
                                  value={el.id}
                                />
                                <span
                                  key={i}
                                  className=" joinedNetworks"
                                  data-clientid={`client-${el.id}`}
                                >
                                  <img
                                    src={el.header.favicon}
                                    alt={el.name}
                                    onError={(e) => {
                                      e.target.src = DEFAULT_USER_IMG_URL;
                                    }}
                                  />
                                  {el.header.title}
                                </span>
                              </label>
                            ) : null,
                          )
                        : null}
                    </div>
                    {validationErrors &&
                    validationErrors.clientId !== undefined ? (
                      <span className="validation-error">
                        {validationErrors.clientId}
                      </span>
                    ) : null}
                  </Form.Group>

                  <Form.Group controlId="copy" className="pt-0">
                    <Form.Label>
                      {kTrans(`${isQuiz ? "Quiz" : "Poll"} Embed Code`)}
                    </Form.Label>
                    <span
                      className="customBtn btn btn-secondary p-2 ps-4 pe-4"
                      onClick={this.copyToClipboard}
                    >
                      {kTrans("Website Embed")}
                    </span>
                    <textarea
                      className="hiddenElement"
                      ref={(textarea) => (this.textArea = textarea)}
                      value={embedCode}
                    />
                    &nbsp;&nbsp;
                    <span
                      className="customBtn btn btn-secondary p-2 ps-4 pe-4"
                      onClick={this.copyEmailEmbedToClipboard}
                    >
                      {kTrans("Email Embed")}
                    </span>
                  </Form.Group>

                  <Form.Group controlId="thesis" className="pt-0">
                    <Form.Label>
                      {kTrans(
                        isQuiz ? "Quiz Question" : "Poll Question or Statement",
                      )}
                    </Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={1}
                      data-required="true"
                      onChange={this.changeHandler}
                      onKeyDown={this.handleKeyDown}
                      name="thesis"
                      value={thesis}
                      placeholder={kTrans("Type question or statement here...")}
                      tabIndex={1}
                    />
                    {validationErrors &&
                    validationErrors.thesis !== undefined ? (
                      <span className="validation-error">
                        {validationErrors.thesis}
                      </span>
                    ) : null}
                  </Form.Group>

                  <Form.Group className="pt-0">
                    <div className="inline-content middle">
                      <Form.Label>
                        {kTrans(
                          isQuiz
                            ? "Type your answers and select the correct option(s) using the checkbox(s) to the left"
                            : "Your Sides",
                        )}
                      </Form.Label>
                    </div>

                    {sides && sides.length
                      ? sides.map((el, i) => (
                          <div className="side" key={i}>
                            <div className="message position-relative">
                              <div className="row-1 d-flex align-items-center">
                                <div
                                  className={`position-relative w-100 ${isQuiz ? "quiz-side" : ""}`}
                                >
                                  {/*{
																	!isNaN((el.votes * 100 / currentDebateData.votes).toFixed(2)) ?
																		<div className="percentageBar" style={{ width: (el.votes * 100 / currentDebateData.votes)+'%', background: el.sideColor+'26' }}></div>
																	: null
																}
																<p className="form-control mb-0 sideP" style={{ borderColor: el.sideColor, background: el.sideColor+'10' }}>{el.text}</p>
																{
																	!isNaN((el.votes * 100 / currentDebateData.votes).toFixed(2)) ?
																		<span className="votesPercentage">{(el.votes * 100 / currentDebateData.votes).toFixed(2)}%</span>
																	: null
																}*/}
                                  {isQuiz ? (
                                    <input
                                      type="checkbox"
                                      checked={el.isCorrect}
                                      onChange={({ target }) => {
                                        formData.sides[i].isCorrect =
                                          target.checked;
                                        this.setState({
                                          formData,
                                          disableSubmitBtn: false,
                                        });
                                      }}
                                    />
                                  ) : null}
                                  <p
                                    className="form-control mb-0 sideP"
                                    style={{ borderColor: el.sideColor }}
                                  >
                                    {el.text}
                                  </p>
                                  <i className="fa fa-lock"></i>
                                </div>
                                <Button
                                  disabled={el.link ? true : false}
                                  onClick={(e) =>
                                    this.toggleSideElField(e, "link", i)
                                  }
                                  size="sm"
                                  variant="secondary-light"
                                  className="side-link ms-2"
                                >
                                  <FontAwesomeIcon
                                    icon={faLink}
                                    className="procheckmark"
                                  />
                                </Button>
                                <Button
                                  onClick={(e) =>
                                    this.toggleSideElField(e, "tag", i)
                                  }
                                  size="sm"
                                  variant="secondary-light"
                                  className="side-tag ms-2"
                                >
                                  <FontAwesomeIcon
                                    icon={faTag}
                                    className="procheckmark flip"
                                  />
                                  +
                                </Button>
                              </div>
                              {isQuiz && el.isCorrect ? (
                                <div className="answer-label">
                                  Correct answer
                                </div>
                              ) : null}
                              {el.link ? (
                                <div className="row-2 mt-2">
                                  <span className="sideLink">
                                    <FontAwesomeIcon
                                      icon={faLink}
                                      className="procheckmark"
                                    />
                                    <a
                                      className="d-inline"
                                      rel="noreferrer"
                                      href={
                                        el.link.includes("http")
                                          ? el.link
                                          : "//" + el.link
                                      }
                                      target="_blank"
                                    >
                                      {el.link}
                                    </a>
                                    <FontAwesomeIcon
                                      onClick={(e) =>
                                        this.removeSideElField(e, "link", i)
                                      }
                                      role="button"
                                      icon={faTimesCircle}
                                      className="procheckmark flip"
                                    />
                                  </span>
                                </div>
                              ) : null}
                              {el.tags && el.tags.length ? (
                                <div className="row-2 mt-2">
                                  {el.tags.map((elt, tagIndex) => (
                                    <span key={tagIndex} className="sideTags">
                                      <FontAwesomeIcon
                                        icon={faTag}
                                        className="procheckmark flip"
                                      />
                                      {elt.text}
                                      <FontAwesomeIcon
                                        onClick={(e) =>
                                          this.removeSideElField(
                                            e,
                                            "tag",
                                            i,
                                            tagIndex,
                                          )
                                        }
                                        role="button"
                                        icon={faTimesCircle}
                                        className="procheckmark flip"
                                      />
                                    </span>
                                  ))}
                                </div>
                              ) : null}
                              {toggleSideLinkField[i] ||
                              toggleSideTagField[i] ? (
                                <div className="row-3 mt-2" test={i}>
                                  {toggleSideLinkField[i] ? (
                                    <div className="add-link-tag-wrapper position-relative">
                                      <Form.Control
                                        value={linkTempVar ? linkTempVar : ""}
                                        onChange={(e) =>
                                          this.handleSideElFieldChange(
                                            e,
                                            "link",
                                          )
                                        }
                                        type="text"
                                        name=""
                                      />
                                      <div className="sideElementsButtons">
                                        <Button
                                          onClick={(e) =>
                                            this.toggleSideElField(e, "link", i)
                                          }
                                          className="me-1"
                                          size="sm"
                                          variant="secondary-light"
                                        >
                                          {kTrans("Cancel")}
                                        </Button>
                                        <Button
                                          data-index={i + 1}
                                          onClick={this.sidesLinkCommitHandler}
                                          className=""
                                          size="sm"
                                          variant="primary"
                                        >
                                          {kTrans("Add")}
                                        </Button>
                                      </div>
                                      {linkTempVar ? null : (
                                        <FontAwesomeIcon
                                          icon={faLink}
                                          className="procheckmark"
                                        />
                                      )}
                                    </div>
                                  ) : null}

                                  {toggleSideTagField[i] ? (
                                    <div className="add-link-tag-wrapper position-relative">
                                      <Form.Control
                                        value={tagTempVar ? tagTempVar : ""}
                                        onChange={(e) =>
                                          this.handleSideElFieldChange(e, "tag")
                                        }
                                        type="text"
                                        name=""
                                      />
                                      <div className="sideElementsButtons">
                                        <Button
                                          onClick={(e) =>
                                            this.toggleSideElField(e, "tag", i)
                                          }
                                          className="me-1"
                                          size="sm"
                                          variant="secondary-light"
                                        >
                                          {kTrans("Cancel")}
                                        </Button>
                                        <Button
                                          data-index={i + 1}
                                          onClick={this.sidesTagCommitHandler}
                                          className=""
                                          size="sm"
                                          variant="primary"
                                        >
                                          {kTrans("Add")}
                                        </Button>
                                      </div>
                                      {tagTempVar ? null : (
                                        <FontAwesomeIcon
                                          icon={faTag}
                                          className="flip procheckmark"
                                        />
                                      )}
                                    </div>
                                  ) : null}
                                </div>
                              ) : null}
                            </div>
                          </div>
                        ))
                      : null}
                  </Form.Group>

                  <Form.Group controlId="sourcePageUrl" className="mt-md-4">
                    <Form.Label>Source Page</Form.Label>
                    <Form.Control
                      data-required="true"
                      type="text"
                      onChange={this.changeHandler}
                      name="sourcePageUrl"
                      value={sourcePageUrl}
                      placeholder="Enter the url of the page this poll matches"
                      tabIndex={1}
                    />
                    {validationErrors &&
                    validationErrors.sourcePageUrl !== undefined ? (
                      <span className="validation-error">
                        {validationErrors.sourcePageUrl}
                      </span>
                    ) : null}
                  </Form.Group>

                  <Row className="mt-md-4">
                    <Col md={4} className="">
                      <Form.Group>
                        <Form.Label>
                          {kTrans(`Include ${isQuiz ? "Quiz" : "Poll"} Image`)}{" "}
                          <span className="lightColorSpan">
                            ({kTrans("Optional")})
                          </span>
                        </Form.Label>
                        <span className="lightColorSpan d-block mb-2 small">
                          {kTrans("Recommended 270 x 134 px")}
                        </span>
                        <div className="img-field-wrap">
                          {backgroundImageDisplay !== "" ? (
                            <div className="field-backgroundImage">
                              <img
                                alt="backgroundImage"
                                src={backgroundImageDisplay}
                              />
                            </div>
                          ) : (
                            <Button
                              onClick={this.triggerImageInputClick}
                              className="position-absolute customBtn p-2 ps-4 pe-4"
                              variant="outline-primary"
                            >
                              {kTrans("Add Image")}
                            </Button>
                          )}
                          <Form.Control
                            ref={(input) => (this.hiddenImageInput = input)}
                            className="d-none h-50"
                            data-index="0"
                            data-imgtype="DEBATES"
                            type="file"
                            name="backgroundImage"
                            onChange={this.fileChangeHandler}
                            data-accept="jpg|jpeg|png"
                            data-accept_msg={kTrans(
                              "Image is invalid. Please upload only PNG or JPG image",
                            )}
                          />
                        </div>
                        {backgroundImageDisplay !== "" ? (
                          <div className="d-flex mt-2">
                            <Button
                              onClick={this.triggerImageInputClick}
                              className="w-50 me-1"
                              size="sm"
                              variant="outline-primary"
                            >
                              {kTrans("Change")}
                            </Button>
                            <Button
                              onClick={() =>
                                this.fileRemoveHandler("backgroundImage", "0")
                              }
                              className="w-50"
                              size="sm"
                              variant="outline-primary"
                            >
                              {kTrans("Remove")}
                            </Button>
                          </div>
                        ) : null}
                      </Form.Group>
                    </Col>
                    <Col md={4} className="">
                      <Form.Group controlId="" className="">
                        <Form.Label>
                          {kTrans("Additional Info")}{" "}
                          <span className="lightColorSpan">
                            ({kTrans("Optional")})
                          </span>
                        </Form.Label>
                        <span className="lightColorSpan d-block mb-2 small">
                          {kTrans(
                            `Add more info about your ${isQuiz ? "quiz" : "poll"}`,
                          )}
                          .
                        </span>
                        {background ? (
                          <>
                            <textarea
                              rows="4"
                              className="form-control"
                              disabled
                              value={background}
                            ></textarea>
                            <div className="d-flex mt-2">
                              <Button
                                onClick={this.showAdditionaInfoModal}
                                className="w-50 me-1"
                                size="sm"
                                variant="outline-primary"
                              >
                                {kTrans("Change")}
                              </Button>
                              <Button
                                onClick={this.removeBackgroundText}
                                className="w-50"
                                size="sm"
                                variant="outline-primary"
                              >
                                {kTrans("Remove")}
                              </Button>
                            </div>
                          </>
                        ) : (
                          <Button
                            onClick={this.showAdditionaInfoModal}
                            className="customBtn p-2 ps-4 pe-4"
                            variant="outline-primary"
                          >
                            {kTrans("Add Info")}
                          </Button>
                        )}
                      </Form.Group>
                    </Col>
                    <Col md={4} className="">
                      <Form.Group controlId="" className="">
                        <Form.Label>
                          {kTrans("Custom CTA")}{" "}
                          <span className="lightColorSpan">
                            ({kTrans("Optional")})
                          </span>
                        </Form.Label>
                        <span className="lightColorSpan d-block mb-2 small">
                          {kTrans("Customize CTA shown with results")}.
                        </span>
                        {this.state.CTAClicked || affiliateLink ? (
                          <Form.Group controlId="affiliate" className="pt-0">
                            <Form.Control
                              className=""
                              data-required="true"
                              type="text"
                              onChange={this.changeHandler}
                              name="affiliateLink"
                              value={affiliateLink}
                              placeholder={kTrans("Enter affiliate link here")}
                            />
                            {validationErrors &&
                            validationErrors.affiliateLink !== undefined ? (
                              <span className="validation-error">
                                {validationErrors.affiliateLink}
                              </span>
                            ) : null}
                            <Form.Control
                              className="mt-2"
                              data-required="true"
                              type="text"
                              onChange={this.changeHandler}
                              name="affiliateButtonText"
                              value={affiliateButtonText}
                              placeholder={kTrans("Enter button text")}
                            />
                            <div className="txtCenter mt-2">
                              <Button
                                onClick={this.removeCustomCTA}
                                className="w-50"
                                size="sm"
                                variant="outline-primary"
                              >
                                {kTrans("Remove")}
                              </Button>
                            </div>
                          </Form.Group>
                        ) : (
                          <Button
                            className="customBtn p-2 ps-4 pe-4"
                            variant="outline-primary"
                            onClick={() => this.setState({ CTAClicked: true })}
                          >
                            {kTrans("Add CTA")}
                          </Button>
                        )}
                      </Form.Group>
                    </Col>
                  </Row>
                </Col>
                <Col md={5} className="ps-md-5 rightColCustomPos">
                  {this.state.currentDebateData.isClosed ? null : (
                    <div className="buttons mb-4 mt-5 d-none d-md-flex justify-content-end">
                      <NavLink
                        className="btn btn-secondary mt-0 ms-0"
                        exact
                        activeClassName="active"
                        to="/"
                      >
                        {kTrans("Cancel")}
                      </NavLink>
                      <Button
                        disabled={this.state.disableSubmitBtn}
                        variant="primary"
                        className="mt-0 me-0"
                        type="submit"
                      >
                        {kTrans("Save")}
                      </Button>
                    </div>
                  )}

                  <Form.Label className="pt-0">
                    {kTrans("Published on")}{" "}
                    <span className="lightColorSpan">
                      {startedAt
                        ? moment(startedAt).format("M/DD/YY @ hh:mm A")
                        : null}
                    </span>
                    {this.state.currentDebateData.isClosed ? null : (
                      <>
                        &nbsp;&nbsp;•&nbsp;&nbsp;
                        <span
                          className="lightColorSpan"
                          onClick={this.unpublishDebate}
                        >
                          <u role="button">Unpublish</u>
                        </span>
                      </>
                    )}
                  </Form.Label>
                  <div className="inline-content top">
                    <Form.Group controlId="startedAt" className="me-3">
                      <Form.Label>
                        {kTrans("Start Date")} <i className="fa fa-lock"></i>
                      </Form.Label>
                      <div className="datePickerWrapper">
                        {startedAt !== null ? (
                          <>
                            <DateRangePicker /*onCallback={(e) => this.datePickerCallback(e,'startedAt')}*/
                              initialSettings={{
                                singleDatePicker: true,
                                startDate: moment(startedAt)["_d"],
                                timePicker: true,
                                locale: {
                                  format: "MM/DD/YY @ hh:mmA",
                                },
                              }}
                            >
                              <input
                                disabled
                                type="text"
                                name="startedAt"
                                className="form-control datepickerField"
                              />
                            </DateRangePicker>
                            <i className="fa fa-calendar"></i>
                          </>
                        ) : null}
                      </div>
                    </Form.Group>

                    {this.state.resetEndDate !== true ? (
                      <Form.Group controlId="endedAt" className="">
                        <Form.Label>{kTrans("End Date")}</Form.Label>
                        <div className="datePickerWrapper">
                          {startedAt !== null ? (
                            <>
                              <DateRangePicker
                                onCallback={(e) =>
                                  this.datePickerCallback(e, "endedAt")
                                }
                                initialSettings={{
                                  singleDatePicker: true,
                                  minDate: endedAtMinDate,
                                  startDate:
                                    endedAt !== null
                                      ? moment(endedAt)["_d"]
                                      : moment().add(5, "minutes"),
                                  /*maxDate: endedAtMaxDate,*/
                                  timePicker: true,
                                  locale: {
                                    format: "MM/DD/YY @ hh:mmA",
                                  },
                                }}
                              >
                                <input
                                  type="text"
                                  name="endedAt"
                                  className={`form-control datepickerField ${parseInt(noEndDate) === 1 ? "disabledInput" : ""}`}
                                />
                              </DateRangePicker>
                              <i className="fa fa-calendar"></i>
                            </>
                          ) : null}
                        </div>

                        {!this.state.currentDebateData.isClosed ? (
                          <div className="noEndDate">
                            <Form.Check.Label className="mb-0 mt-2">
                              <Form.Check.Input
                                className="m-0 me-2 "
                                checked={parseInt(noEndDate)}
                                onChange={this.changeHandler}
                                name="noEndDate"
                                value={parseInt(noEndDate) === 1 ? 0 : 1}
                              />
                              {kTrans("No end date")}
                            </Form.Check.Label>
                          </div>
                        ) : null}
                      </Form.Group>
                    ) : null}
                  </div>

                  <div className="row">
                    <Form.Group
                      controlId="PollAuthor"
                      className="col-md-6 pe-2"
                    >
                      <Form.Label>
                        {kTrans(`${isQuiz ? "Quiz" : "Poll"} Author`)}
                      </Form.Label>
                      {/*<Form.Control className="userId" as="select" data-required="true" onChange={this.changeHandler } name="userId" value={userId}>
												<option value="" data-id="">-- Select --</option>
												{
													authorsList && authorsList.length ? 
														authorsList.map((el, i) => (
								                  				<option key={i} data-index={i} value={el.id} data-id={el.id}>{el.name}</option>              
														))
														: <option value="">No author available</option>
												}
											</Form.Control>*/}

                      <DropdownButton
                        className="customDropdown"
                        id="dropdown-item-button"
                        title={
                          authorsList &&
                          authorsList.find((item) => item.id === userId)
                            ? authorsList.find((item) => item.id === userId)
                                .name
                            : currentDebateData
                              ? currentDebateData.user.name
                              : kTrans("Select Author")
                        }
                      >
                        {authorsList && authorsList.length ? (
                          authorsList.map((el, i) => (
                            <Dropdown.Item
                              key={i}
                              name="userId"
                              onClick={(e) =>
                                this.handleCustomDropdownChange(e, el.id)
                              }
                              as="button"
                            >
                              {el.name}
                            </Dropdown.Item>
                          ))
                        ) : (
                          <Dropdown.Item>
                            {kTrans("No author available")}
                          </Dropdown.Item>
                        )}
                      </DropdownButton>
                    </Form.Group>

                    <Form.Group controlId="PollCat" className="col-md-6 ps-2">
                      <Form.Label>
                        {kTrans(`${isQuiz ? "Quiz" : "Poll"} Category`)} (
                        {kTrans("Optional")})
                      </Form.Label>
                      <DropdownButton
                        className="standard-dropdown"
                        onClick={({ target }) =>
                          setTimeout(() => {
                            if (
                              target.nextElementSibling &&
                              target.nextElementSibling.firstElementChild &&
                              target.nextElementSibling.firstElementChild
                                .firstElementChild
                            ) {
                              target.nextElementSibling.firstElementChild.firstElementChild.focus();
                            }
                          }, 200)
                        }
                        title={
                          categoriesList &&
                          categoriesList.find((item) => item.id === categoryId)
                            ? categoriesList.find(
                                (item) => item.id === categoryId,
                              ).text
                            : kTrans("Select Category")
                        }
                      >
                        <Form.Group>
                          <Form.Control
                            value={categoryFilter}
                            placeholder="Enter category name"
                            onChange={(e) =>
                              this.setState({ categoryFilter: e.target.value })
                            }
                          />
                        </Form.Group>
                        {categoryFilter ? (
                          <>
                            {(categoriesList || [])
                              .filter(
                                (item) =>
                                  item.text
                                    .toUpperCase()
                                    .indexOf(categoryFilter.toUpperCase()) > -1,
                              )
                              .map((item) => (
                                <Dropdown.Item
                                  key={item.id}
                                  name="categoryId"
                                  onClick={(e) =>
                                    this.handleCustomDropdownChange(e, item.id)
                                  }
                                  as="button"
                                >
                                  {item.text}
                                </Dropdown.Item>
                              ))}
                          </>
                        ) : (
                          <>
                            {(categoriesList || []).map((item) => (
                              <Dropdown.Item
                                key={item.id}
                                name="categoryId"
                                onClick={(e) =>
                                  this.handleCustomDropdownChange(e, item.id)
                                }
                                as="button"
                              >
                                {item.text}
                              </Dropdown.Item>
                            ))}
                          </>
                        )}
                        {!(categoriesList || []).length ? (
                          <Dropdown.Item>
                            {kTrans("No category available")}
                          </Dropdown.Item>
                        ) : null}
                      </DropdownButton>
                    </Form.Group>
                  </div>

                  <div className="row">
                    <Form.Group
                      controlId="PollAuthor"
                      className="col-md-6 pe-2"
                    >
                      <Form.Label>{kTrans("Require Email")}</Form.Label>
                      <DropdownButton
                        className="customDropdown"
                        title={kTrans(
                          requireEmail < 0
                            ? "No"
                            : requireEmail > 1
                              ? "Yes, verify with email link"
                              : requireEmail > 0
                                ? "Yes, require verification code"
                                : "Use client/placement default",
                        )}
                      >
                        <Dropdown.Item
                          onClick={() =>
                            this.setState({
                              formData: Object.assign({}, formData, {
                                requireEmail: 0,
                              }),
                            })
                          }
                          as="button"
                        >
                          {kTrans("Use client/placement default")}
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() =>
                            this.setState({
                              formData: Object.assign({}, formData, {
                                requireEmail: -1,
                              }),
                            })
                          }
                          as="button"
                        >
                          {kTrans("No")}
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() =>
                            this.setState({
                              formData: Object.assign({}, formData, {
                                requireEmail: 1,
                              }),
                            })
                          }
                          as="button"
                        >
                          {kTrans("Yes, require verification code")}
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() =>
                            this.setState({
                              formData: Object.assign({}, formData, {
                                requireEmail: 2,
                              }),
                            })
                          }
                          as="button"
                        >
                          {kTrans("Yes, verify with email link")}
                        </Dropdown.Item>
                      </DropdownButton>
                    </Form.Group>
                  </div>

                  {this.renderSyndicationSelector()}

                  <Form.Group controlId="" className="pb-2">
                    <div className="inline-content middle">
                      <Form.Label className="m-0">
                        {kTrans(`${isQuiz ? "Quiz" : "Poll"} Preview`)}
                      </Form.Label>
                      {currentDebateData ? (
                        <a
                          className="customAnchor"
                          rel="noreferrer"
                          href={currentDebateData.debateUrl}
                          target="_blank"
                        >
                          {kTrans("View on Sided")}
                        </a>
                      ) : null}
                    </div>
                  </Form.Group>

                  <div className="debatePreviewSection">
                    <div className="debatePreviewInner">
                      <div className="debatePreviewHeader">
                        {currentDebateData ? (
                          <div className="author">
                            <a
                              target="_blank"
                              rel="noreferrer"
                              className="small-dp user-color-green"
                              href={"/" + currentDebateData.user.username}
                            >
                              <img
                                style={{
                                  borderColor:
                                    currentDebateData.user.statusColor,
                                }}
                                data-src=""
                                src={
                                  currentDebateData.user.avatarObject
                                    ? currentDebateData.user.avatarObject.small
                                        .location
                                    : DEFAULT_USER_IMG_URL
                                }
                                alt={currentDebateData.user.name}
                                className="sidedLazyLoad img-circle avatar"
                                onError={(e) => {
                                  e.target.src = DEFAULT_USER_IMG_URL;
                                }}
                              />

                              {currentDebateData.user.roles.length > 0 &&
                              currentDebateData.user.roles.find(
                                (role) => role.name === "VERIFIED",
                              ) ? (
                                <FontAwesomeIcon
                                  style={{
                                    color:
                                      currentDebateData.user.checkmarkColor,
                                  }}
                                  icon={faCheckCircle}
                                  className="procheckmark"
                                />
                              ) : null}
                            </a>
                            <div className="copy-wrap">
                              <a
                                target="_blank"
                                rel="noreferrer"
                                className="authorname"
                                href={"/" + currentDebateData.user.username}
                              >
                                <h6>{currentDebateData.user.name}</h6>
                              </a>
                              <span className="authorhandle">
                                <span className="handler">
                                  {kTrans("Posted")}{" "}
                                  {moment(startedAt).format("MMM DD, YYYY")}
                                </span>
                              </span>
                            </div>
                          </div>
                        ) : null}
                      </div>
                      {backgroundImageDisplay !== "" ? (
                        <div className="debatePreviewBgImg mt-3">
                          <img
                            alt="backgroundImage"
                            src={backgroundImageDisplay}
                          />
                        </div>
                      ) : null}
                      <div className="debatePreviewTitle mt-3">
                        {thesis !== "" ? (
                          <h5>{thesis}</h5>
                        ) : (
                          <>
                            <p></p>
                            <p></p>
                            <p></p>
                          </>
                        )}
                      </div>
                      {!parseInt(noEndDate) ? (
                        <>
                          {this.state.currentDebateData.isClosed ? (
                            <span className="lightColorSpan mt-2 d-block">
                              {kTrans(`${isQuiz ? "Quiz" : "Poll"} ended`)}{" "}
                              {moment(endedAt).format("MMM DD, YYYY")}
                            </span>
                          ) : (
                            <span className="lightColorSpan mt-2 d-block">
                              {kTrans(`${isQuiz ? "Quiz" : "Poll"} ends in`)}{" "}
                              {finalDifference ? finalDifference : ""} •{" "}
                              {kTrans("Vote below")}
                            </span>
                          )}
                        </>
                      ) : (
                        <span className="lightColorSpan mt-2 d-block">
                          Vote below
                        </span>
                      )}

                      <div className="debatePreviewSides mt-2">
                        {sides && sides.length
                          ? sides.map((elSides, i) => (
                              <div className="side pb-1" key={elSides.id}>
                                <div className="message1 position-relative">
                                  {!isNaN(
                                    (
                                      (elSides.votes * 100) /
                                      currentDebateData.votes
                                    ).toFixed(2),
                                  ) ? (
                                    <div
                                      className="percentageBar"
                                      style={{
                                        width:
                                          (elSides.votes * 100) /
                                            currentDebateData.votes +
                                          "%",
                                        background: elSides.sideColor + "26",
                                      }}
                                    ></div>
                                  ) : null}
                                  <div
                                    className="topDebateSides"
                                    style={{
                                      borderColor: elSides.sideColor,
                                      background: elSides.sideColor + "10",
                                    }}
                                  >
                                    {elSides.text}
                                  </div>
                                  {!isNaN(
                                    (
                                      (elSides.votes * 100) /
                                      currentDebateData.votes
                                    ).toFixed(2),
                                  ) ? (
                                    <span className="votesPercentage">
                                      {(
                                        (elSides.votes * 100) /
                                        currentDebateData.votes
                                      ).toFixed(2)}
                                      %
                                      {elSides.link ? (
                                        <a
                                          target="_blank"
                                          rel="noreferrer"
                                          href={
                                            elSides.link.includes("http")
                                              ? elSides.link
                                              : "//" + elSides.link
                                          }
                                        >
                                          <svg
                                            className="ms-1"
                                            width="15"
                                            height="15"
                                            viewBox="0 0 15 15"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <path
                                              d="M15 7.5C15 9.48912 14.2098 11.3968 12.8033 12.8033C11.3968 14.2098 9.48912 15 7.5 15C6.51509 15 5.53982 14.806 4.62987 14.4291C3.71993 14.0522 2.89314 13.4997 2.1967 12.8033C0.790176 11.3968 0 9.48912 0 7.5C0 5.51088 0.790176 3.60322 2.1967 2.1967C3.60322 0.790176 5.51088 0 7.5 0C8.48491 0 9.46018 0.193993 10.3701 0.570904C11.2801 0.947814 12.1069 1.50026 12.8033 2.1967C13.4997 2.89314 14.0522 3.71993 14.4291 4.62987C14.806 5.53982 15 6.51509 15 7.5ZM3 8.25H9L6.375 10.875L7.44 11.94L11.88 7.5L7.44 3.06L6.375 4.125L9 6.75H3V8.25Z"
                                              fill="#2F80ED"
                                            />
                                          </svg>
                                        </a>
                                      ) : null}
                                    </span>
                                  ) : (
                                    <span className="votesPercentage">
                                      {elSides.link ? (
                                        <a
                                          target="_blank"
                                          rel="noreferrer"
                                          href={
                                            elSides.link.includes("http")
                                              ? elSides.link
                                              : "//" + elSides.link
                                          }
                                        >
                                          <svg
                                            className="ms-1"
                                            width="15"
                                            height="15"
                                            viewBox="0 0 15 15"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <path
                                              d="M15 7.5C15 9.48912 14.2098 11.3968 12.8033 12.8033C11.3968 14.2098 9.48912 15 7.5 15C6.51509 15 5.53982 14.806 4.62987 14.4291C3.71993 14.0522 2.89314 13.4997 2.1967 12.8033C0.790176 11.3968 0 9.48912 0 7.5C0 5.51088 0.790176 3.60322 2.1967 2.1967C3.60322 0.790176 5.51088 0 7.5 0C8.48491 0 9.46018 0.193993 10.3701 0.570904C11.2801 0.947814 12.1069 1.50026 12.8033 2.1967C13.4997 2.89314 14.0522 3.71993 14.4291 4.62987C14.806 5.53982 15 6.51509 15 7.5ZM3 8.25H9L6.375 10.875L7.44 11.94L11.88 7.5L7.44 3.06L6.375 4.125L9 6.75H3V8.25Z"
                                              fill="#2F80ED"
                                            />
                                          </svg>
                                        </a>
                                      ) : null}
                                    </span>
                                  )}
                                </div>
                              </div>
                            ))
                          : null}
                      </div>

                      {affiliateLink ? (
                        <div className="debateAffiliateBtn mt-2">
                          <a
                            target="_blank"
                            rel="noreferrer"
                            className="btn btn-outline-primary btn-sm w-100"
                            href={
                              affiliateLink.includes("http")
                                ? affiliateLink
                                : "//" + affiliateLink
                            }
                          >
                            {affiliateButtonText}
                          </a>
                        </div>
                      ) : null}

                      <div class="inline-content middle mt-3">
                        <span className="lightColorSpan d-block">
                          {currentDebateData.votes} Vote
                          {currentDebateData.votes !== 1 ? "s" : ""} •{" "}
                          {currentDebateData.comments} Comment
                          {currentDebateData.comments !== 1 ? "s" : ""}{" "}
                        </span>
                        <span className="customAnchor">{kTrans("Share")}</span>
                      </div>
                      <div className="debatePreviewFooter mt-3">
                        <span className="customAnchor">
                          {kTrans(`Embed ${isQuiz ? "Quiz" : "Poll"}`)}
                        </span>
                        <img alt="Logo" src={logo} className="logo" />
                      </div>
                    </div>
                  </div>
                  <span
                    className="customAnchor txtCenter mt-3"
                    onClick={this.copyEmailEmbedToClipboard}
                  >
                    {kTrans(
                      `Copy ${isQuiz ? "Quiz" : "Poll"} Email Embed Code`,
                    )}
                  </span>
                  {this.state.currentDebateData.isClosed ? null : (
                    <div className="buttons mb-4 mt-5 d-md-none d-flex justify-content-end">
                      <NavLink
                        className="btn btn-secondary mt-0 ms-0"
                        exact
                        activeClassName="active"
                        to="/"
                      >
                        {kTrans("Cancel")}
                      </NavLink>
                      <Button
                        disabled={this.state.disableSubmitBtn}
                        onClick={this.submitDetateDataHandle}
                        variant="primary"
                        className="mt-0 me-0"
                        type="submit"
                      >
                        {kTrans("Save")}
                      </Button>
                    </div>
                  )}
                </Col>
              </Form>
            </div>
          </Tab>
          <Tab
            eventKey="embed-settings"
            title={kTrans(`Deploy ${isQuiz ? "Quiz" : "Poll"}`)}
          >
            <Row id="publish-debate-section" className="">
              <Col md={7} className="pe-3">
                <span className="lightColorSpan">
                  {kTrans(
                    `Deploy this ${isQuiz ? "quiz" : "poll"} to your website by selecting page(s) below and clicking "Deploy to Selected" then selecting the appropriate "Placement". You can also "Deploy to All Pages" or "Deploy to Category"`,
                  )}
                  .
                </span>
                <DeployDebate
                  isQuiz={isQuiz}
                  id="deploy-debate"
                  clientIdFromParent={clientId}
                  debateIdFromParent={id}
                  actionFromParent={action}
                  currentDebateData={currentDebateData}
                />
              </Col>
              <Col
                md={5}
                className="ps-md-5 rightColCustomPos startDebate common-form"
              >
                {this.state.currentDebateData.isClosed ? null : (
                  <div className="buttons mb-4 mt-5 d-none d-md-flex justify-content-end">
                    <NavLink
                      className="btn btn-secondary mt-0 ms-0"
                      exact
                      activeClassName="active"
                      to="/"
                    >
                      {kTrans("Cancel")}
                    </NavLink>
                    <Button
                      disabled={this.state.disableSubmitBtn}
                      onClick={this.submitDetateDataHandle}
                      variant="primary"
                      className="mt-0 me-0"
                      type="submit"
                    >
                      {kTrans("Save")}
                    </Button>
                  </div>
                )}

                <Form.Label className="pt-0">
                  {kTrans("Published on")}{" "}
                  <span className="lightColorSpan">
                    {startedAt
                      ? moment(startedAt).format("M/DD/YY @ hh:mm A")
                      : null}
                  </span>
                  {this.state.currentDebateData.isClosed ? null : (
                    <>
                      &nbsp;&nbsp;•&nbsp;&nbsp;
                      <span
                        className="lightColorSpan"
                        onClick={this.unpublishDebate}
                      >
                        <u role="button">Unpublish</u>
                      </span>
                    </>
                  )}
                </Form.Label>
                <div className="inline-content top">
                  <Form.Group controlId="startedAt" className="me-3">
                    <Form.Label>
                      {kTrans("Start Date")} <i className="fa fa-lock"></i>
                    </Form.Label>
                    <div className="datePickerWrapper">
                      {startedAt !== null ? (
                        <>
                          <DateRangePicker /*onCallback={(e) => this.datePickerCallback(e,'startedAt')}*/
                            initialSettings={{
                              singleDatePicker: true,
                              startDate: moment(startedAt)["_d"],
                              timePicker: true,
                              locale: {
                                format: "MM/DD/YY @ hh:mmA",
                              },
                            }}
                          >
                            <input
                              disabled
                              type="text"
                              name="startedAt"
                              className="form-control datepickerField"
                            />
                          </DateRangePicker>
                          <i className="fa fa-calendar"></i>
                        </>
                      ) : null}
                    </div>
                  </Form.Group>

                  {this.state.resetEndDate !== true ? (
                    <Form.Group controlId="endedAt" className="">
                      <Form.Label>{kTrans("End Date")}</Form.Label>
                      <div className="datePickerWrapper">
                        {startedAt !== null ? (
                          <>
                            <DateRangePicker
                              onCallback={(e) =>
                                this.datePickerCallback(e, "endedAt")
                              }
                              initialSettings={{
                                singleDatePicker: true,
                                minDate: endedAtMinDate,
                                startDate:
                                  endedAt !== null
                                    ? moment(endedAt)["_d"]
                                    : moment().add(5, "minutes"),
                                /*maxDate: endedAtMaxDate,*/
                                timePicker: true,
                                locale: {
                                  format: "MM/DD/YY @ hh:mmA",
                                },
                              }}
                            >
                              <input
                                type="text"
                                name="endedAt"
                                className={`form-control datepickerField ${parseInt(noEndDate) === 1 ? "disabledInput" : ""}`}
                              />
                            </DateRangePicker>
                            <i className="fa fa-calendar"></i>
                          </>
                        ) : null}
                      </div>

                      {!this.state.currentDebateData.isClosed ? (
                        <div className="noEndDate">
                          <Form.Check.Label className="mb-0 mt-2">
                            <Form.Check.Input
                              className="m-0 me-2 "
                              checked={parseInt(noEndDate)}
                              onChange={this.changeHandler}
                              name="noEndDate"
                              value={parseInt(noEndDate) === 1 ? 0 : 1}
                            />
                            {kTrans("No end date")}
                          </Form.Check.Label>
                        </div>
                      ) : null}
                    </Form.Group>
                  ) : null}
                </div>

                <div className="row">
                  <Form.Group controlId="PollAuthor" className="col-md-6 pe-2">
                    <Form.Label>
                      {kTrans(`${isQuiz ? "Quiz" : "Poll"} Author`)}
                    </Form.Label>
                    {/*<Form.Control className="userId" as="select" data-required="true" onChange={this.changeHandler } name="userId" value={userId}>
											<option value="" data-id="">-- Select --</option>
											{
												authorsList && authorsList.length ? 
													authorsList.map((el, i) => (
							                  				<option key={i} data-index={i} value={el.id} data-id={el.id}>{el.name}</option>              
													))
													: <option value="">No author available</option>
											}
										</Form.Control>*/}

                    <DropdownButton
                      className="customDropdown"
                      id="dropdown-item-button"
                      title={
                        authorsList &&
                        authorsList.find((item) => item.id === userId)
                          ? authorsList.find((item) => item.id === userId).name
                          : currentDebateData
                            ? currentDebateData.user.name
                            : kTrans("Select Author")
                      }
                    >
                      {authorsList && authorsList.length ? (
                        authorsList.map((el, i) => (
                          <Dropdown.Item
                            key={i}
                            name="userId"
                            onClick={(e) =>
                              this.handleCustomDropdownChange(e, el.id)
                            }
                            as="button"
                          >
                            {el.name}
                          </Dropdown.Item>
                        ))
                      ) : (
                        <Dropdown.Item>
                          {kTrans("No author available")}
                        </Dropdown.Item>
                      )}
                    </DropdownButton>
                  </Form.Group>

                  <Form.Group controlId="PollCat" className="col-md-6 ps-2">
                    <Form.Label>
                      {kTrans(`${isQuiz ? "Quiz" : "Poll"} Category`)} (
                      {kTrans("Optional")})
                    </Form.Label>
                    {/*<Form.Control className="categoryId" as="select" data-required="true" onChange={this.changeHandler} name="categoryId" value={categoryId}>
											<option value="" data-id="">-- Select --</option>
											{
												categoriesList && categoriesList.length ? 
													categoriesList.map((el, i) => (
							                  				<option key={i} value={el.id} data-id={el.id}>{el.text}</option>              
													))
													: 
													<option value="">Select a Network first</option>
											}
										</Form.Control>*/}

                    <DropdownButton
                      className="customDropdown"
                      id="dropdown-item-button"
                      title={
                        categoriesList &&
                        categoriesList.find((item) => item.id === categoryId)
                          ? categoriesList.find(
                              (item) => item.id === categoryId,
                            ).text
                          : kTrans("Select Category")
                      }
                    >
                      {categoriesList && categoriesList.length ? (
                        categoriesList.map((el, i) => (
                          <Dropdown.Item
                            key={i}
                            name="categoryId"
                            onClick={(e) =>
                              this.handleCustomDropdownChange(e, el.id)
                            }
                            as="button"
                          >
                            {el.text}
                          </Dropdown.Item>
                        ))
                      ) : (
                        <Dropdown.Item>
                          {kTrans("No category available")}
                        </Dropdown.Item>
                      )}
                    </DropdownButton>
                  </Form.Group>
                </div>

                {this.renderSyndicationSelector()}

                <Form.Group controlId="" className="pb-2">
                  <div className="inline-content middle">
                    <Form.Label className="m-0">
                      {kTrans(`${isQuiz ? "Quiz" : "Poll"} Preview`)}
                    </Form.Label>
                    {currentDebateData ? (
                      <a
                        className="customAnchor"
                        rel="noreferrer"
                        href={currentDebateData.debateUrl}
                        target="_blank"
                      >
                        {kTrans("View on Sided")}
                      </a>
                    ) : null}
                  </div>
                </Form.Group>
                <div className="debatePreviewSection">
                  <div className="debatePreviewInner">
                    <div className="debatePreviewHeader">
                      {currentDebateData ? (
                        <div className="author">
                          <a
                            target="_blank"
                            rel="noreferrer"
                            className="small-dp user-color-green"
                            href={"/" + currentDebateData.user.username}
                          >
                            <img
                              style={{
                                borderColor: currentDebateData.user.statusColor,
                              }}
                              data-src=""
                              src={
                                currentDebateData.user.avatarObject
                                  ? currentDebateData.user.avatarObject.small
                                      .location
                                  : DEFAULT_USER_IMG_URL
                              }
                              alt={currentDebateData.user.name}
                              className="sidedLazyLoad img-circle avatar"
                              onError={(e) => {
                                e.target.src = DEFAULT_USER_IMG_URL;
                              }}
                            />

                            {currentDebateData.user.roles.length > 0 &&
                            currentDebateData.user.roles.find(
                              (role) => role.name === "VERIFIED",
                            ) ? (
                              <FontAwesomeIcon
                                style={{
                                  color: currentDebateData.user.checkmarkColor,
                                }}
                                icon={faCheckCircle}
                                className="procheckmark"
                              />
                            ) : null}
                          </a>
                          <div className="copy-wrap">
                            <a
                              target="_blank"
                              rel="noreferrer"
                              className="authorname"
                              href={"/" + currentDebateData.user.username}
                            >
                              <h6>{currentDebateData.user.name}</h6>
                            </a>
                            <span className="authorhandle">
                              <span className="handler">
                                {kTrans("Posted")}{" "}
                                {moment(startedAt).format("MMM DD, YYYY")}
                              </span>
                            </span>
                          </div>
                        </div>
                      ) : null}
                    </div>
                    {backgroundImageDisplay !== "" ? (
                      <div className="debatePreviewBgImg mt-3">
                        <img
                          alt="backgroundImage"
                          src={backgroundImageDisplay}
                        />
                      </div>
                    ) : null}
                    <div className="debatePreviewTitle mt-3">
                      {thesis !== "" ? (
                        <h5>{thesis}</h5>
                      ) : (
                        <>
                          <p></p>
                          <p></p>
                          <p></p>
                        </>
                      )}
                    </div>
                    {!parseInt(noEndDate) ? (
                      <>
                        {this.state.currentDebateData.isClosed ? (
                          <span className="lightColorSpan mt-2 d-block">
                            {kTrans(`${isQuiz ? "Quiz" : "Poll"} has ended`)}
                          </span>
                        ) : (
                          <span className="lightColorSpan mt-2 d-block">
                            {kTrans(`${isQuiz ? "Quiz" : "Poll"} ends in`)}{" "}
                            {finalDifference ? finalDifference : ""} &bull;{" "}
                            {kTrans("Vote below")}
                          </span>
                        )}
                      </>
                    ) : (
                      <span className="lightColorSpan mt-2 d-block">
                        {kTrans("Vote below")}
                      </span>
                    )}

                    <div className="debatePreviewSides mt-2">
                      {sides && sides.length
                        ? sides.map((elSides, i) => (
                            <div className="side pb-1" key={elSides.id}>
                              <div className="message1 position-relative">
                                {!isNaN(
                                  (
                                    (elSides.votes * 100) /
                                    currentDebateData.votes
                                  ).toFixed(2),
                                ) ? (
                                  <div
                                    className="percentageBar"
                                    style={{
                                      width:
                                        (elSides.votes * 100) /
                                          currentDebateData.votes +
                                        "%",
                                      background: elSides.sideColor + "26",
                                    }}
                                  ></div>
                                ) : null}
                                <div
                                  className="topDebateSides"
                                  style={{
                                    borderColor: elSides.sideColor,
                                    background: elSides.sideColor + "10",
                                  }}
                                >
                                  {elSides.text}
                                </div>
                                {!isNaN(
                                  (
                                    (elSides.votes * 100) /
                                    currentDebateData.votes
                                  ).toFixed(2),
                                ) ? (
                                  <span className="votesPercentage">
                                    {(
                                      (elSides.votes * 100) /
                                      currentDebateData.votes
                                    ).toFixed(2)}
                                    %
                                    {elSides.link ? (
                                      <a
                                        target="_blank"
                                        rel="noreferrer"
                                        href={
                                          elSides.link.includes("http")
                                            ? elSides.link
                                            : "//" + elSides.link
                                        }
                                      >
                                        <svg
                                          className="ms-1"
                                          width="15"
                                          height="15"
                                          viewBox="0 0 15 15"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path
                                            d="M15 7.5C15 9.48912 14.2098 11.3968 12.8033 12.8033C11.3968 14.2098 9.48912 15 7.5 15C6.51509 15 5.53982 14.806 4.62987 14.4291C3.71993 14.0522 2.89314 13.4997 2.1967 12.8033C0.790176 11.3968 0 9.48912 0 7.5C0 5.51088 0.790176 3.60322 2.1967 2.1967C3.60322 0.790176 5.51088 0 7.5 0C8.48491 0 9.46018 0.193993 10.3701 0.570904C11.2801 0.947814 12.1069 1.50026 12.8033 2.1967C13.4997 2.89314 14.0522 3.71993 14.4291 4.62987C14.806 5.53982 15 6.51509 15 7.5ZM3 8.25H9L6.375 10.875L7.44 11.94L11.88 7.5L7.44 3.06L6.375 4.125L9 6.75H3V8.25Z"
                                            fill="#2F80ED"
                                          />
                                        </svg>
                                      </a>
                                    ) : null}
                                  </span>
                                ) : (
                                  <span className="votesPercentage">
                                    {elSides.link ? (
                                      <a
                                        target="_blank"
                                        rel="noreferrer"
                                        href={
                                          elSides.link.includes("http")
                                            ? elSides.link
                                            : "//" + elSides.link
                                        }
                                      >
                                        <svg
                                          className="ms-1"
                                          width="15"
                                          height="15"
                                          viewBox="0 0 15 15"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path
                                            d="M15 7.5C15 9.48912 14.2098 11.3968 12.8033 12.8033C11.3968 14.2098 9.48912 15 7.5 15C6.51509 15 5.53982 14.806 4.62987 14.4291C3.71993 14.0522 2.89314 13.4997 2.1967 12.8033C0.790176 11.3968 0 9.48912 0 7.5C0 5.51088 0.790176 3.60322 2.1967 2.1967C3.60322 0.790176 5.51088 0 7.5 0C8.48491 0 9.46018 0.193993 10.3701 0.570904C11.2801 0.947814 12.1069 1.50026 12.8033 2.1967C13.4997 2.89314 14.0522 3.71993 14.4291 4.62987C14.806 5.53982 15 6.51509 15 7.5ZM3 8.25H9L6.375 10.875L7.44 11.94L11.88 7.5L7.44 3.06L6.375 4.125L9 6.75H3V8.25Z"
                                            fill="#2F80ED"
                                          />
                                        </svg>
                                      </a>
                                    ) : null}
                                  </span>
                                )}
                              </div>
                            </div>
                          ))
                        : null}
                    </div>

                    {affiliateLink ? (
                      <div className="debateAffiliateBtn mt-2">
                        <a
                          target="_blank"
                          rel="noreferrer"
                          className="btn btn-outline-primary btn-sm w-100"
                          href={
                            affiliateLink.includes("http")
                              ? affiliateLink
                              : "//" + affiliateLink
                          }
                        >
                          {affiliateButtonText}
                        </a>
                      </div>
                    ) : null}

                    <div class="inline-content middle mt-3">
                      <span className="lightColorSpan d-block">
                        {currentDebateData.votes} Vote
                        {currentDebateData.votes !== 1 ? "s" : ""} •{" "}
                        {currentDebateData.comments} Comment
                        {currentDebateData.comments !== 1 ? "s" : ""}{" "}
                      </span>
                      <span className="customAnchor">{kTrans("Share")}</span>
                    </div>
                    <div className="debatePreviewFooter mt-3">
                      <span className="customAnchor">
                        {kTrans(`Embed ${isQuiz ? "Quiz" : "Poll"}`)}
                      </span>
                      <img alt="Logo" src={logo} className="logo" />
                    </div>
                  </div>
                </div>
                <span
                  className="customAnchor txtCenter mt-3"
                  onClick={this.copyEmailEmbedToClipboard}
                >
                  {kTrans(`Copy ${isQuiz ? "Quiz" : "Poll"} Email Embed Code`)}
                </span>
                {this.state.currentDebateData.isClosed ? null : (
                  <div className="buttons mb-4 mt-5 d-md-none d-flex justify-content-end">
                    <NavLink
                      className="btn btn-secondary mt-0 ms-0"
                      exact
                      activeClassName="active"
                      to="/"
                    >
                      {kTrans("Cancel")}
                    </NavLink>
                    <Button
                      disabled={this.state.disableSubmitBtn}
                      onClick={this.submitDetateDataHandle}
                      variant="primary"
                      className="mt-0 me-0"
                      type="submit"
                    >
                      {kTrans("Save")}
                    </Button>
                  </div>
                )}
              </Col>
            </Row>
            <Modal
              className="debateAdditionalInfo"
              size="md"
              show={this.state.showAdditionaInfoModal}
            >
              <Modal.Body className="m-0 p-4 common-form">
                <h3>
                  <b>{kTrans("Additional Info")}</b>
                </h3>
                <span className="lightColorSpan">
                  {kTrans(
                    `Add more info about your ${isQuiz ? "quiz" : "poll"}`,
                  )}
                  . ({kTrans("Optional")})
                </span>
                <div className="modalContentWrapper">
                  <Form.Group className="startDebate">
                    <textarea
                      rows="3"
                      className="mt-5 mb-5 form-control"
                      onChange={(e) => {
                        this.changeHandler(e);
                      }}
                      name="backgroundTemp"
                      value={backgroundTemp ? backgroundTemp : ""}
                      placeholder={kTrans(
                        "Share any information that might help people choose a side",
                      )}
                    ></textarea>
                  </Form.Group>
                  <div className="buttons text-center">
                    <Button
                      className="mt-2"
                      onClick={this.hideAdditionaInfoModal}
                      variant="secondary"
                    >
                      {kTrans("Cancel")}
                    </Button>
                    <Button
                      onClick={this.commitAdditionalInfo}
                      className={`mt-2 ${backgroundTemp === "" && background === "" ? "disabled" : ""}`}
                      variant={
                        backgroundTemp === "" && background === ""
                          ? "secondary"
                          : "primary"
                      }
                      type="submit"
                    >
                      {kTrans("Save")}
                    </Button>
                  </div>
                </div>
              </Modal.Body>
            </Modal>
          </Tab>
        </Tabs>
        <textarea
          className="hiddenElement"
          ref={(textarea) => (this.textAreaEmailEmbed = textarea)}
          value={emailEmbedCode}
        />
      </section>
    );
  }
}

const mapStateToProps = (state) => ({
  loggedInUser: state.login.user,
  selNetworkId: state.networks.selNetworkId,
});

const Extended = withTranslation()(EditDebate);
Extended.static = EditDebate.static;

export default connect(mapStateToProps, {})(Extended);
