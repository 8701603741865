import axios from "axios";
import moment from "moment";
import { connect } from "react-redux";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { API_URL, MAIN_WEBSITE_URL } from "../../constants/Globals";
import TagsFilter from "../TagsFilter";
import SegmentsFilter from "../SegmentsFilter";
import TableSearch from "../TableSearch";
import InviteModal from "../InviteModal";
import ReactPaginate from "react-paginate";
import useDebounce from "../../hooks/useDebounce";

function Audience({ selNetworkId, networksList, location }) {
  const defaultSegment =
    location.search && location.search.indexOf("?segment=") !== -1
      ? parseInt(location.search.replace("?segment=", ""))
      : 0;

  const [tagList, setTagList] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [paginationParams, setPaginationParams] = useState({
    currentPage: 1,
    searchQuery: "",
  });
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const [entries, setEntries] = useState([]);
  const [fingerprints, setFingerprints] = useState([]);
  const [selection, setSelection] = useState([]);
  const [reportData, setReportData] = useState(null);
  const [showVerified, setShowVerified] = useState(false);
  const [showAnonymous, setShowAnonymous] = useState(false);
  const [selectedTags, setSelectedTags] = useState([]);
  const [exportedSegment, setExportedSegment] = useState(0);
  const [selectedSegment, setSelectedSegment] = useState(defaultSegment);
  const [showInviteModal, setShowInviteModal] = useState(false);
  const allEntriesChecked = selection.length === 1 && selection[0] === "all";
  const onSearchDebounced = useDebounce(searchText, 500);
  const itemsPerPage = 25;

  useEffect(() => {
    setPaginationParams({
      currentPage: 1,
      searchQuery: onSearchDebounced,
    });
  }, [onSearchDebounced]);

  useEffect(() => {
    if (selNetworkId) {
      const { currentPage, searchQuery } = paginationParams;

      if (selectedTags.length) {
        setIsDataLoading(true);
        axios
          .get(
            `${API_URL}/admin/tags/users?clientId=${selNetworkId}&pageNumber=${currentPage}&perPage=${itemsPerPage}&tags=${selectedTags.join(",")}&search=${searchQuery.length > 2 ? searchQuery : ""}`,
          )
          .then(({ data }) => {
            setIsDataLoading(false);
            const { users, fingerprints, count } = data.data;
            setEntries(users);
            setFingerprints(fingerprints);
            setTotal(count);
          })
          .catch(({ response }) => {
            alert("There is some problem is fetching Audience data");
            setIsDataLoading(false);
          });
      } else if (selectedSegment) {
        setIsDataLoading(true);
        axios
          .get(
            `${API_URL}/admin/segments/${selectedSegment}?clientId=${selNetworkId}&pageNumber=${currentPage}&perPage=${itemsPerPage}&search=${searchQuery.length > 2 ? searchQuery : ""}`,
          )
          .then(({ data }) => {
            setIsDataLoading(false);
            const { users, count } = data.data;
            setEntries(Array.isArray(users) ? users : []);
            setTotal(count);
          })
          .catch(({ response }) => {
            alert("There is some problem is fetching Audience data");
            setIsDataLoading(false);
            console.log(response);
          });
      } else {
        setIsDataLoading(true);
        axios
          .get(
            `${API_URL}/admin/tags/users?clientId=${selNetworkId}&pageNumber=${currentPage}&perPage=${itemsPerPage}${showVerified ? "&filter=ROLEVERIFIED" : ""}&search=${searchQuery.length > 2 ? searchQuery : ""}`,
          )
          .then(({ data }) => {
            setIsDataLoading(false);
            const { users, fingerprints, count } = data.data;
            setEntries(users);
            setFingerprints(fingerprints);
            setTotal(count);
          })
          .catch(({ response }) => {
            alert("There is some problem is fetching Audience data");
            setIsDataLoading(false);
            console.log(response);
          });
      }
    }
  }, [
    selNetworkId,
    paginationParams,
    itemsPerPage,
    showVerified,
    location,
    selectedSegment,
    selectedTags,
  ]);

  useEffect(() => {
    if (selNetworkId) {
      axios
        .get(`${API_URL}/admin/tags?clientId=${selNetworkId}`)
        .then(({ data }) => setTagList(data.data));
    }
  }, [selNetworkId]);

  const { currentPage } = paginationParams;

  return (
    <section className={isDataLoading ? "loading" : null}>
      <div className="title-bar">
        <h1 className="page-title uppercase">Audience</h1>
        <button
          className="blue-button size-l"
          onClick={() => setShowInviteModal(true)}
        >
          Invite User
        </button>
      </div>
      <h2 className="section-title">Overview / Performance</h2>
      <div className="general-stats">
        <div>
          <div className="general-stats-label">Total Users</div>
          <div className="general-stats-value">{total}</div>
        </div>
        <div>
          <div className="general-stats-label">Total Votes</div>
          <div className="general-stats-value">-</div>
        </div>
        <div>
          <div className="general-stats-label">Audience Growth</div>
          <div className="general-stats-value">-</div>
        </div>
        <div>
          <div className="general-stats-label">Avg. Engagement Rate</div>
          <div className="general-stats-value">-</div>
        </div>
      </div>

      <div className="table-filters">
        <TableSearch
          value={searchText}
          onChange={(value) => {
            setSearchText(value);
          }}
          placeholder="Search email, name, or username"
        />
        {total >= itemsPerPage ? (
          <div className="table-pagination">
            {currentPage > 1 ? (
              <span
                className="table-pagination-nav table-pagination-prev"
                onClick={() =>
                  setPaginationParams({
                    ...paginationParams,
                    currentPage: currentPage - 1,
                  })
                }
              >
                <svg
                  width="7"
                  height="11"
                  viewBox="0 0 7 11"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M-5.6497e-08 9.7075L4.2075 5.5L-4.24328e-07 1.2925L1.30167 -5.68977e-08L6.80167 5.5L1.30167 11L-5.6497e-08 9.7075Z"
                    fill="#828282"
                  />
                </svg>
              </span>
            ) : null}
            <span>
              {(currentPage - 1) * itemsPerPage + 1} -{" "}
              {currentPage * itemsPerPage >= total
                ? total
                : currentPage * itemsPerPage}{" "}
              of {total}
            </span>
            {currentPage * itemsPerPage >= total ? null : (
              <span
                className="table-pagination-nav"
                onClick={() =>
                  setPaginationParams({
                    ...paginationParams,
                    currentPage: currentPage + 1,
                  })
                }
              >
                <svg
                  width="7"
                  height="11"
                  viewBox="0 0 7 11"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M-5.6497e-08 9.7075L4.2075 5.5L-4.24328e-07 1.2925L1.30167 -5.68977e-08L6.80167 5.5L1.30167 11L-5.6497e-08 9.7075Z"
                    fill="#828282"
                  />
                </svg>
              </span>
            )}
          </div>
        ) : (
          <div className="table-pagination">1 - {total}</div>
        )}
      </div>

      <div className="table-wrapper">
        <div className="table-holder">
          <div className="table-selectors">
            {selectedSegment ? null : (
              <TagsFilter
                tags={tagList}
                isActive={selectedTags.length}
                onSelect={(tag) => {
                  const newSelectedTags = selectedTags.concat([tag]);
                  setSelectedTags(newSelectedTags);
                }}
              />
            )}

            {selectedTags.length ? (
              <>
                <span className="table-control active growing">
                  <div className="table-control-value">
                    {total} contacts contain
                    {selectedTags
                      .map(
                        (selectedTag) =>
                          tagList.find(
                            (tagItem) => tagItem.id === selectedTag,
                          ) || null,
                      )
                      .filter((selectedTag) => selectedTag)
                      .map((selectedTag, index) => (
                        <span
                          key={selectedTag.id}
                          className="selected-value"
                          onClick={() => {
                            const newSelectedTags = [].concat(selectedTags);
                            newSelectedTags.splice(index, 1);
                            setSelectedTags(newSelectedTags);
                          }}
                        >
                          {selectedTag.text}
                          <svg
                            width="9"
                            height="9"
                            viewBox="0 0 9 9"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M8 1.705L7.295 1L4.5 3.795L1.705 1L1 1.705L3.795 4.5L1 7.295L1.705 8L4.5 5.205L7.295 8L8 7.295L5.205 4.5L8 1.705Z"
                              fill="black"
                              stroke="#828282"
                            />
                          </svg>
                        </span>
                      ))}
                  </div>
                </span>
                <Link
                  to="/audience/segments/new"
                  className="table-control active"
                >
                  <div className="table-control-value">Create Segment</div>
                </Link>
                <span
                  className="table-control active"
                  onClick={() => setSelectedTags([])}
                >
                  <div className="table-control-value">Clear</div>
                </span>
              </>
            ) : (
              <SegmentsFilter
                onSelect={setSelectedSegment}
                selectedSegment={selectedSegment}
              />
            )}

            {selectedSegment ? (
              <>
                <span className="table-control active growing">
                  <div className="table-control-value">
                    {total} contacts in this segment
                    <Link
                      className="quick-link"
                      to={`/audience/segments/${selectedSegment}`}
                    >
                      Edit Segment
                    </Link>
                  </div>
                </span>
                <span
                  className="table-control active"
                  onClick={() => {
                    if (!exportedSegment) {
                      setExportedSegment(selectedSegment);
                      axios
                        .get(
                          `${API_URL}/admin/segments/${selectedSegment}?clientId=${selNetworkId}&csv=1`,
                        )
                        .then(({ data }) => {
                          const { users } = data.data;
                          const csvContent =
                            "data:text/csv;charset=utf-8," +
                            Object.keys(users[0]).join(",") +
                            "\n" +
                            users
                              .map((row) => {
                                const tags = row.tags
                                  .map((tag) => tag.text)
                                  .join(", ");

                                return {
                                  ...row,
                                  tags,
                                };
                              })
                              .map((row) => Object.values(row).join("||"))
                              .join("\n")
                              .replaceAll(",", ".")
                              .replaceAll("||", ",");
                          setReportData(csvContent);
                          setExportedSegment(0);
                        });
                    }
                  }}
                >
                  <div className="table-control-value">
                    <svg
                      width="12"
                      height="17"
                      viewBox="0 0 12 17"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M6 0L3 3H5.25V9.75H6.75V3H9M10.5 16.5H1.5C0.6675 16.5 0 15.825 0 15V6C0 5.60218 0.158035 5.22064 0.43934 4.93934C0.720644 4.65804 1.10218 4.5 1.5 4.5H3.75V6H1.5V15H10.5V6H8.25V4.5H10.5C10.8978 4.5 11.2794 4.65804 11.5607 4.93934C11.842 5.22064 12 5.60218 12 6V15C12 15.3978 11.842 15.7794 11.5607 16.0607C11.2794 16.342 10.8978 16.5 10.5 16.5Z"
                        fill="white"
                      />
                    </svg>
                    &nbsp;&nbsp;
                    {exportedSegment === selectedSegment
                      ? "Please wait..."
                      : "Export Audience"}
                  </div>
                </span>
                <span
                  className="table-control active"
                  onClick={() => setSelectedSegment(0)}
                >
                  <div className="table-control-value">Clear</div>
                </span>
              </>
            ) : null}

            {!selectedTags.length && !selectedSegment ? (
              <>
                <div
                  className={`table-control ${showVerified ? "active" : ""}`}
                  onClick={() => setShowVerified(!showVerified)}
                >
                  <div className="table-control-value">Show Verified</div>
                </div>
                <div
                  className={`table-control ${showAnonymous ? "active" : ""}`}
                  onClick={() => setShowAnonymous(!showAnonymous)}
                >
                  <div className="table-control-value">Show Anonymous</div>
                </div>
                <Link to="/audience/segments/new" className="table-control">
                  <div className="table-control-value">+ New Segment</div>
                </Link>
              </>
            ) : null}
          </div>
          <table
            className="table-body audience-table"
            cellSpacing="0"
            cellPadding="0"
          >
            <tbody>
              <tr className="table-row font-size-x">
                <td
                  className="table-cell x-center border-right padding-r-0"
                  width="30"
                  align="center"
                >
                  <input
                    type="checkbox"
                    checked={allEntriesChecked}
                    onChange={() =>
                      setSelection(
                        selection.indexOf("all") !== -1 ? [] : ["all"],
                      )
                    }
                  />
                </td>
                <td className="table-cell border-right">
                  <strong>Email Address / ID</strong>
                </td>
                <td className="table-cell">Name</td>
                <td className="table-cell">Username</td>
                <td className="table-cell">Votes</td>
                <td className="table-cell">Tags</td>
                <td className="table-cell table-cell-fluid">Segments</td>
                {showAnonymous ? null : (
                  <td className="table-cell" width="90">
                    Joined Date
                  </td>
                )}
                <td className="table-cell" width="50"></td>
              </tr>
              {(showAnonymous ? fingerprints : entries).map(
                ({
                  id,
                  email,
                  name,
                  username,
                  roles,
                  tags,
                  segments,
                  votes,
                  createdAt,
                }) => (
                  <tr key={id} className="table-row font-size-x">
                    <td
                      className="table-cell x-center border-right padding-r-0"
                      align="center"
                    >
                      <input
                        type="checkbox"
                        className="table-row-checkbox"
                        checked={
                          allEntriesChecked || selection.indexOf(id) !== -1
                        }
                        onChange={() => {
                          const newSelection = [].concat(selection);
                          const currentIndex = newSelection.indexOf(id);
                          const allIndex = newSelection.indexOf("all");

                          if (allIndex !== -1) {
                            newSelection.splice(allIndex, 1);
                          }

                          if (currentIndex !== -1) {
                            newSelection.splice(currentIndex, 1);
                          } else {
                            newSelection.push(id);
                          }

                          setSelection(newSelection);
                        }}
                      />
                    </td>
                    <td className="table-cell border-right">
                      <div className="table-cell-text">
                        {showAnonymous ? id : email}
                      </div>
                      {roles &&
                      roles.length > 0 &&
                      roles.find((role) => role.name === "VERIFIED") ? (
                        <svg
                          width="14"
                          height="14"
                          viewBox="0 0 14 14"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M7 0C3.15 0 0 3.15 0 7C0 10.85 3.15 14 7 14C10.85 14 14 10.85 14 7C14 3.15 10.85 0 7 0ZM5.6 10.5L2.1 7L3.087 6.013L5.6 8.519L10.913 3.206L11.9 4.2L5.6 10.5Z"
                            fill="#2AA974"
                          />
                        </svg>
                      ) : null}
                    </td>
                    <td className="table-cell">{name || "n/a"}</td>
                    <td className="table-cell">
                      <div className="table-cell-text">
                        {username ? `@${username}` : "n/a"}
                      </div>
                    </td>
                    <td className="table-cell">
                      {isNaN(votes) ? "n/a" : votes}
                    </td>
                    <td className="table-cell">
                      {tags && tags.length ? (
                        <>
                          {tags
                            .map((tag) => {
                              if (!tag.text) {
                                const matchedTag = tagList.find(
                                  (t) => t.id === tag,
                                );

                                if (matchedTag) {
                                  return matchedTag.text;
                                }
                              }

                              return tag.text;
                            })
                            .join(", ")}
                        </>
                      ) : (
                        "n/a"
                      )}
                    </td>
                    <td className="table-cell table-cell-fluid">
                      {segments && segments.length
                        ? segments.map((segment) => segment.name).join(", ")
                        : "n/a"}
                    </td>
                    {showAnonymous ? null : (
                      <td className="table-cell">
                        {moment(createdAt).format("MM/DD/Y")}
                      </td>
                    )}
                    <td className="table-cell" align="center">
                      <a
                        href={`${MAIN_WEBSITE_URL}/${username}`}
                        rel="noreferrer"
                        target="_blank"
                        className="table-pagination-nav"
                      >
                        <svg
                          width="7"
                          height="11"
                          viewBox="0 0 7 11"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M-5.6497e-08 9.7075L4.2075 5.5L-4.24328e-07 1.2925L1.30167 -5.68977e-08L6.80167 5.5L1.30167 11L-5.6497e-08 9.7075Z"
                            fill="#828282"
                          />
                        </svg>
                      </a>
                    </td>
                  </tr>
                ),
              )}
            </tbody>
          </table>
        </div>
        {total >= itemsPerPage ? (
          <ReactPaginate
            className="pagination mt-4"
            previousLabel="&laquo;"
            nextLabel="&raquo;"
            breakLabel="..."
            breakClassName="break-me"
            pageCount={Math.ceil(total / itemsPerPage)}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={(data) =>
              setPaginationParams({
                ...paginationParams,
                currentPage: data.selected + 1,
              })
            }
            containerClassName="pagination"
            activeClassName="active"
            forcePage={currentPage - 1}
          />
        ) : null}
      </div>

      {showInviteModal ? (
        <InviteModal
          networksList={networksList}
          selNetworkId={selNetworkId}
          onClose={() => setShowInviteModal(false)}
        />
      ) : null}

      {reportData ? (
        <div className="modal-cta">
          <div className="modal-cta-inner">
            <h3>The export was successfully generated</h3>
            <div className="modal-cta-buttons">
              <button
                className="blue-button bordered"
                onClick={() => setReportData(null)}
              >
                Close
              </button>
              <a
                href={encodeURI(reportData)}
                className="blue-button"
                onClick={() => setReportData(null)}
              >
                Download
              </a>
            </div>
          </div>
        </div>
      ) : null}
    </section>
  );
}

const mapStateToProps = (state) => ({
  selNetworkId: state.networks.selNetworkId,
  networksList: state.networks.networksList,
});

export default connect(mapStateToProps)(Audience);
